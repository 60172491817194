import { useApp } from '@carbonmaps/ui/hooks/useApp';

import AchatsTable from '../../../../components/supplier/AchatsTable';
import SupplierCard from '../../../../components/supplier/SupplierCard';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useSupplierSetup } from '../../supplier.hooks';

const Achats = () => {
	const { indicator } = useApp();
	const { ingredientsSupplier } = useSupplierSetup();
	const { t } = useTranslation();

	return (
		<div css={{ marginTop: '32px' }}>
			<SupplierCard title={t('Achats de ce fournisseur')} center={false} sx={{ width: '100%' }}>
				<AchatsTable data={ingredientsSupplier} indicator={indicator} withPercent />
			</SupplierCard>
		</div>
	);
};

export default Achats;
