import { css, cx } from '@emotion/css';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../lib/colors';
import Typography from '../Typography';

const styles = {
	line: css({
		width: 1,
		height: 20,
		background: siteColors.grey700,
	}),

	min: css({
		'.legend .content': {
			alignItems: 'flex-start',
		},
	}),
	q1: css({
		'.legend': {
			left: -56,
		},
	}),
	avg: css({
		'.legend': {
			left: -80,
			' .content': {
				minWidth: 165,
			},
		},
	}),

	q3: css({
		'.legend': {
			right: -55,
		},
	}),
	max: css({
		'.legend': {
			right: -3,
			'.content': {
				alignItems: 'flex-end',
			},
		},
		'.line': {
			position: 'absolute',
			top: '-20px',
			right: '4px',
		},
	}),
};

type CandlestickProps = {
	data: {
		impact: number;
		min: number;
		q1: number;
		q3: number;
		mean: number;
		median: number;
		max: number;
	};
};

const Candlestick = ({ data }: CandlestickProps) => {
	return (
		<div className="width100 flexRow flex1">
			<ItemDisplay
				background={siteColors.carbon100}
				width="calc(25%)"
				legend="Minimum"
				value={data.min}
				className={styles.min}
			/>
			<ItemDisplay
				background="#b393ec80"
				width="calc(24.5%)"
				legend="1er quartile"
				value={data.q1}
				isCenter
				className={styles.q1}
			/>
			<ItemDisplay
				background="#b393eccc"
				width="5px"
				legend="Médiane"
				value={data.median}
				className={styles.avg}
				isCenter
			/>
			<ItemDisplay
				background="#b393ec80"
				width="calc(24.5%)"
				legend="3ème quartile"
				value={data.q3}
				className={styles.q3}
				isCenter
			/>
			<ItemDisplay
				background={siteColors.carbon100}
				legend="Maximum"
				value={data.max}
				className={cx('flex1', styles.max)}
			/>
		</div>
	);
};

const itemStyles = {
	container: css({
		height: 53,
		position: 'relative',
		background: 'var(--background)',
		width: 'var(--width)',
		opacity: 'var(--opacity)',
	}),
	legend: css({
		bottom: -87,
	}),
	left: css({
		left: -31,
	}),

	value: css({
		fontSize: '16px !important',
		fontWeight: '600 !important',
	}),
};

type ItemDisplayProps = {
	background: string;
	value: number;
	width?: any;
	opacity?: number;
	legend: string;
	className?: string;
	isCenter?: boolean;
};

export const ItemDisplay = ({
	background,
	value,
	width,
	legend,
	className,
	opacity = 1,
	isCenter = false,
}: ItemDisplayProps) => {
	return (
		<div
			className={cx(itemStyles.container, className)}
			style={{ ['--background' as any]: background, ['--width' as any]: width, ['--opacity' as any]: opacity }}
		>
			<div className={cx('absolute legend', itemStyles.legend, isCenter && 'flexCenter')}>
				<div className={cx('line', styles.line)} />
				<div className="flexCenter content">
					<Typography variant="caption">{legend}</Typography>
					<Typography className={itemStyles.value}>{formatNumber(value, undefined, 2)} </Typography>
					<Typography variant="caption" className={'grey800'}>
						kg ₑCO₂ / kg FPCM
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default Candlestick;
