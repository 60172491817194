import { IUser } from '../types/user.types';

type GetUserByIdOptions = {
	sessionToken: string;
	json?: boolean;
	include?: string[];
}

export function getUserById(userId: string, options: { json?: false, sessionToken: string, include?: string[] }): Promise<Parse.User>
export function getUserById(userId: string, options: { json: true, sessionToken: string, include?: string[] }): Promise<IUser>

export async function getUserById(userId: string, { sessionToken, json = false, include = [] }: GetUserByIdOptions) {
	const _included = [...new Set(['companies.company'].concat(include ?? []))];
	const result: any = await new Parse.Query(Parse.User)
		.include(_included)
		.get(userId, {
			sessionToken,
			json,
		});
	return result;
}
