import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';
import { getIngredientSheetAction, getIngredientSheetSegmentTimePeriodAction, getIngredientSheetTimePeriodAction } from '../actions/ingredient.actions';



export const useGetIngredientSheet = ({ objectId }: { objectId: string }) => {
	const queryResult = useQuery({
		queryKey: ['getIngredientSheet', { objectId }],
		queryFn: getIngredientSheetAction,
	});

	return queryResult;
};

//--------------------------------------------------------------------------------------//
//       HOOK FOR get data for one ingredient CMAPS with time period                    //
//--------------------------------------------------------------------------------------//

export const useGetIngredientSheetTimePeriod = ({
	objectId,
	onError,
}: {
	objectId: string;
	// period: string | number;
	onError?: (err: unknown) => void;
}) => {

	// get the selected year stored with zustand
	const { selectedYear } = useYearSelection();
	const [searchParams] = useSearchParams();
	const paramType = searchParams.get('paramType');

	const queryResult = useQuery({
		queryKey: ['getIngredientSheetTimePeriod', { objectId, period: selectedYear, paramType }] as const,
		queryFn: getIngredientSheetTimePeriodAction,
		onError,
		retry: false,
	});

	return queryResult;
};




//--------------------------------------------------------------------------------------//
//    HOOK FOR get data segment for one ingredient CMAPS with time period               //
//--------------------------------------------------------------------------------------//

export const useGetOneIngredientSegmentSheet = ({
	objectId,
	onError,
}: {
	objectId: string;
	onError?: (err: unknown) => void;
}) => {

	// get the selected year stored with zustand
	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: ['getIngredientSheetSegmentTimePeriod', { objectId, period: selectedYear }],
		queryFn: getIngredientSheetSegmentTimePeriodAction,
		onError,
		retry: false,
	});

	return queryResult;
};


/*
export const useGetIngredientFamilySheet = ({ objectId }: { objectId: string }) => {
	const queryResult = useQuery({
		queryKey: ['getIngredientFamilySheet', { objectId }],
		queryFn: getIngredientFamilySheetAction,
	});

	return queryResult;
};
*/
