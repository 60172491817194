import { useMemo } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { Box as BoxIcon, Carrot, HelpCircle } from 'lucide-react';

import { ITEM_TYPE_INGREDIENT, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import ProductModel from '../../models/Product.model';
import { orderBy } from '../../utils/array.utils';
import TableHeaderCell from '../table/TableHeaderCell';
import ImpactCarbonePopover from '../table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../table/popover/WaterImpactPopover';

type ApprovisionnementListProps = {
	data?: any;
	indicator?: 'carbon' | 'water';
	pagination?: boolean;
	withPercent?: boolean;
};

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const SupplyTable = ({
	data = [],
	indicator = CARBON_INDICATOR,
	pagination = true,
	withPercent = false,
}: ApprovisionnementListProps) => {
	const theme = useTheme();

	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();

	const columns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} valueType="string" label={t('achat-supplier-column')} />;
				},
				accessor: 'label',
				component: 'TagLabel',
				editable: true,
				sortDescFirst: true,
				type: 'string',
				props: {
					gap: 8,
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
						maxWidth: 250,
					},
				},
				icon: <BoxIcon color={theme.palette.grey[700]} />,
			},
			...(indicator === CARBON_INDICATOR
				? [
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })}
										variant="measure"
										measure="carbonImpactTon"
									/>
								);
							},
							accessor: 'carbonImpactPercent',
							editable: true,
							sortDescFirst: true,
							component: 'PercentField',
							type: 'percent',
							props: {
								popoverComponent: ImpactCarbonePopover,
								// style: { justifyContent: 'flex-end' },
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,

								emptyValue: <HelpCircle color={theme.palette.indicator[indicator].main} />,
								color: { primary: theme.palette.indicator[indicator].main },
							},
						},
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.INTENSITY)}
										variant="measure"
										measure="carbonIntensityKg"
									/>
								);
							},
							accessor: 'carbonIntensity', // accessor is the "key" in the data
							editable: true,
							sortDescFirst: true,
							component: 'IntensityField',
							type: 'number',
							props: {
								color: theme.palette.indicator[indicator].main,
							},
						},
				  ]
				: [
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })}
										variant="measure"
										measure="waterImpact"
									/>
								);
							},
							accessor: 'waterImpactPercent',
							editable: true,
							sortDescFirst: true,
							component: 'PercentField',
							type: 'percent',
							props: {
								popoverComponent: WaterImpactPopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={theme.palette.indicator[indicator].main} />,
								color: { primary: theme.palette.indicator[indicator].main },
							},
						},
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.INTENSITY)}
										variant="measure"
										measure="waterIntensity"
									/>
								);
							},
							accessor: 'waterIntensity', // accessor is the "key" in the data
							editable: true,
							sortDescFirst: true,
							component: 'IntensityField',
							type: 'number',
							props: {
								color: theme.palette.indicator[indicator].main,
							},
						},
				  ]),

			...(withPercent
				? [
						{
							// Header: 'PRESENCE',
							Header: (props: any) => {
								return <TableHeaderCell column={props.column} label={t('uses')} />;
							},
							accessor: 'utilisation', // accessor is the "key" in the data
							editable: true,
							sortDescFirst: true,
							type: 'number',
							component: 'Ingredient',
							// disableSortBy: true,
							props: {
								precision: 0,
								sx: { fonSize: '16px', fontWeight: 700, color: theme.palette.common.black },
							},
						},
				  ]
				: []),
		];
	}, [indicator, withPercent, selectedYear, t]);

	return (
		<Box paddingLeft="16px" paddingRight="16px" className="width100">
			<TableComponent
				pageCount={10}
				noDataMessage="Aucun achat"
				pageSize={100}
				columns={columns}
				data={orderBy(
					data.map((item: any) => {
						const object = new ProductModel(item);

						return {
							...item,
							isChecked: object.isN2ModelizationType(),
							icon:
								item.itemType === ITEM_TYPE_INGREDIENT ? (
									<Carrot color={theme.palette.grey[700]} />
								) : (
									<BoxIcon color={theme.palette.grey[700]} />
								),
						};
					}),
					indicator === 'carbon' ? 'carbonImpact' : 'waterImpact',
				)}
				manualPagination={false}
				addStyles={{
					table: {
						border: 'none!important',
					},
					'.bordered td, .bordered th': {
						borderLeft: 'none!important',
						background: `${theme.palette.common.white} !important`,
						borderBottom: `1px solid ${theme.palette.grey[400]}`,
					},
					'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div ,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button':
						{
							justifyContent: 'flex-end!important',
							paddingRight: '0!important',
						},
				}}
				fetchData={(q: any) => {
					//
				}}
				loading={false}
				pagination={pagination}
				transFunc={t}
			/>
		</Box>
	);
};

export default SupplyTable;
