import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';
// import _ from 'lodash';

type GetGlobalIndicatorParams = {
	year: number;
};

/**
 * getGlobalIndicatorAction
 * @param context
 * @returns
 */
export const getGlobalIndicatorAction = async (
	context: QueryFunctionContext<readonly ['globalIndicatorData', GetGlobalIndicatorParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { year },
			},
		} = context;
		const result = await Parse.Cloud.run(functionName.apbo.globalIndictorData, { year });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCountResultAction = async (context: QueryFunctionContext<readonly ['getCountResult']>) => {
	try {
		const result = await Parse.Cloud.run(functionName.apbo.getResultCount);
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCarbonTrajectoryGraphAction = async (
	context: QueryFunctionContext<readonly ['getCarbonTrajectoryGraph']>,
) => {
	try {
		const result = await Parse.Cloud.run(functionName.apbo.getCarbonTrajectoryGraph);
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

type GetTooltipParams = {
	year: number;
	field: string;
};

export const getTooltipGraphAction = async (
	context: QueryFunctionContext<readonly ['getTooltipGraph', GetTooltipParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { year, field },
			},
		} = context;

		if (!year || !field) return null;

		const result = await Parse.Cloud.run(functionName.apbo.getDataTooltip, { year, field });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

type GetDataTableParams = {
	year: number;
};

export const getDataTableAction = async (
	context: QueryFunctionContext<readonly ['getDataTable', GetDataTableParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { year },
			},
		} = context;

		if (!year) return null;

		const result = await Parse.Cloud.run(functionName.apbo.getDataTable, { year });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getIndicatorStatAction = async (context: QueryFunctionContext<readonly ['getIndicatorStat']>) => {
	try {
		const result = await Parse.Cloud.run(functionName.apbo.getIndicatorStat);
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const AddCommentAction = async ({ message, key }: any) => {
	try {
		return await Parse.Cloud.run(functionName.apbo.addComment, { message, key });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const removeCommentAction = async ({ key }: any) => {
	try {
		return await Parse.Cloud.run(functionName.apbo.removeComment, { key });
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCommentsAction = async (context: QueryFunctionContext<readonly ['getComments']>) => {
	try {
		const result = await Parse.Cloud.run(functionName.apbo.getComments);

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getStatDiagAction = async (context: QueryFunctionContext<readonly ['getStatDiag']>) => {
	try {
		const result = await Parse.Cloud.run(functionName.apbo.getStatDiag);

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};
