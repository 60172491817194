import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../../../../apps/front/src/lib/colors';
import { translation } from '../../../i18n/translation';
import BPopover from '../BPopover';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	content: css({
		gap: 8,
		color: siteColors.text,
		fontSize: 14,
		fontFamily: 'Inter',
	}),
	innerContent: css({
		width: 16,
		height: 16,
		borderRadius: 16,
		background: 'var(--background-round)',
	}),
	popoverContainer: css({
		padding: 24,
	}),
	popoverContent: css({
		'& p': {
			color: siteColors.text,
			fontSize: 14,
			fontFamily: 'Inter',
			lineHeight: '25px',
			margin: 0,
		},
	}),
	iconContainer: css({
		gap: 8,
		// display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		width: 40,
	}),
	width10: css({
		width: 10,
	}),
	width20: css({
		width: 20,
	}),
	nowrap: css({
		whiteSpace: 'nowrap',
	}),
};

const VSReference = ({
	value,
	row,
	column,
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const theme = useTheme();

	const t = column?.props?.t || translation;

	// return null;

	return (
		<div className="flexRow width100 justifyCenter">
			<BPopover
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				modeHover
				content={(onClose) => {
					return (
						<div className={styles.popoverContainer}>
							<div className={styles.popoverContent}>
								<p className="fontWeight700">{t('reference')}</p>
								<p>{t(row.original.labelPortfolioCmaps)}</p>
								<p className="fontWeight700">Source </p>
								<p>{row.original.bddRefPortfolio}</p>
							</div>
						</div>
					);
				}}
			>
				<div className={cx('flexRow alignCenter justifyCenter fontWeight400', styles.content)}>
					<div
						className={styles.innerContent}
						style={{
							['--background-round' as any]:
								value < 0 ? siteColors.green500 : value == 0 ? theme.palette.grey[700] : '#C74600',
						}}
					></div>
					<div className={cx(styles.iconContainer, styles.nowrap)}>
						{/* <span className={styles.width10}>{value > 0 ? '+' : value == 0 ? '=' : '-'}</span>
						<span className={cx(styles.width20, 'number')}>
							{value != 0 && formatNumber(Math.abs(value), undefined, 2)}
						</span> */}
						{displayValue(value, 2)}
					</div>
				</div>
			</BPopover>
		</div>
	);
};

export default VSReference;
