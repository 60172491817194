import { Fragment, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getCompanySheetFacetAction } from '@carbonmaps/ui/actions/company.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../../components/layout/list/Container';
import { useQueryParams } from '../../../../hooks/useSearchParams';
import { useTranslation } from '../../../../hooks/useTranslation';

import CreateField from './CreateField';
import FieldHeader from './FieldHeader';
import FieldsTable from './FieldsTable';

const Fields = (/* props: Props */) => {
	const { setBreadcrumbs } = useApp();
	const { t } = useTranslation();
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';
	const indexId = queryParams?.indexId ? queryParams?.indexId[0] : '';
	const collection = queryParams?.collection ? queryParams?.collection[0] : '';
	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([
			{ text: t('company-breadcrumbs'), link: `${FRONT_PATH_NAMES.superAdminSettings}/companies` },
			{ text: t('facettes'), link: `${FRONT_PATH_NAMES.superAdminSettings}/facets?companyId=${companyId}` },
			{ text: t('facet-add-field-title') },
		]);
	}, [companyId, setBreadcrumbs, t]);

	// ---- fetch data --- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getCompanySheetFacet',
			{
				companyId,
			} as any,
		],
		queryFn: getCompanySheetFacetAction,
	});

	return (
		<Container
			header={<FieldHeader subtitle={`${data?.name} - ${collection}`} />}
			seoText={`${t('facet-add-field-title')} - Carbon Maps`}
		>
			{companyId && companyId !== '' && indexId && indexId !== '' && (
				<Fragment key={'fields'}>
					<CreateField />
					<FieldsTable manualSortBy={false} />
				</Fragment>
			)}
		</Container>
	);
};

export default Fields;
