import { useEffect, useState } from 'react';

import { throttle } from 'lodash';

type UseStickyProps = {
	offset?: number;
	throttleDelay?: number;
};

const useSticky = ({ offset = 0, throttleDelay = 100 }: UseStickyProps = {}) => {
	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const isElementSticky = window.scrollY >= offset;
			setIsSticky(isElementSticky);
		};

		const throttledScroll = throttle(handleScroll, throttleDelay) as unknown as typeof handleScroll;

		// Attach the event listener when the component mounts
		window.addEventListener('scroll', throttledScroll);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('scroll', throttledScroll);
		};
	}, [throttleDelay, offset]);

	return isSticky;
};

export default useSticky;
