import Chip from '@mui/material/Chip';
import useTheme from '@mui/material/styles/useTheme';

import { roleLabels } from '@carbonmaps/shared/utils/constants';
import { siteColors } from '../../../../../apps/front/src/lib/colors';

type Props = {
	role: any;
};

const ChipsRole = ({ role }: Props) => {
	const theme = useTheme();
	let bgcolor = theme.palette.grey[300];

	switch (role.label) {
		case roleLabels.ADMIN:
			bgcolor = '#FEF7F1';
			break;
		case roleLabels.EDITOR:
			bgcolor = '#F5F9EC';
			break;
		case roleLabels.CONTRIBUTOR:
			bgcolor = '#F4FAFF';
			break;
		case roleLabels.USER:
			bgcolor = siteColors.grey300;
			break;
		default:
			break;
	}

	return (
		<Chip
			label={role.label}
			css={{
				textTransform: 'capitalize',
				background: bgcolor,
				uppercase: 'none',
				borderRadius: '8px',
				border: `solid 1px ${theme.palette.grey[300]}`,
				span: {
					color: theme.palette.grey[900],
					fontSize: '1rem',
					padding: '8px 8px',
				},
			}}
		/>
	);
};

export default ChipsRole;
