import { ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import { /* Navigate, */ Outlet /* , useLocation */ } from 'react-router-dom';

import BaseLayout from '../_common/BaseLayout';
import TopBar from '../_common/TopBar';

import BoSideBar from './BoSideBar';

type Props = {
	children?: ReactNode;
};

const BoLayout = ({ children }: Props) => {
	const [openMenu, setOpenMenu] = useState(true);

	const handleToggleSidebar = () => {
		return setOpenMenu(!openMenu);
	};

	return (
		<BaseLayout sidebar={<BoSideBar open={openMenu} />}>
			<TopBar open={openMenu} onToggle={handleToggleSidebar} disableIndicatorSwitch disableYearSelection />

			{/* The actual content */}
			<Box component="main" flexGrow={1}>
				{children ? children : <Outlet />}
			</Box>
		</BaseLayout>
	);
};

export default BoLayout;
