import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { MOD_CAP2ER } from '@carbonmaps/shared/utils/module';

import BButton from '../BButton';

type Props = {
	value?: any;
	row: any;
	column?: any;
	onDataUpdate: any;
	withConfirm?: boolean;
	confirmMessage?: string;
	page?: string;
	dependance?: boolean;
	disabled?: boolean;
};

const styles = {
	container: css({
		padding: 24,
		gap: 16,
	}),
	popoverTitle: css({
		textAlign: 'left !important',
		fontSize: '16px !important',
		fontWeight: '600 !important',
	} as any),

	button: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',
		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			// color: '#C74600',
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),
	ctButton: css({
		'&:hover': { background: 'unset !important' },
	}),
};

const SwitchCell = ({
	row,
	value,
	onDataUpdate,
	withConfirm = false,
	confirmMessage,
	page,
	dependance,
	disabled = false,
}: Props) => {
	const [switchState, setSwitchState] = useState(value || false);

	// ---- popover controller ---- //
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);

	const handleSwitchChange = (event: any) => {
		//---- when with modal confirm ---- //
		if (
			(withConfirm && !switchState) ||
			(page === 'boModule' && row.original.objectId === MOD_CAP2ER && value === false)
		) {
			setAnchorEl(event?.target);
			return;
		}

		onConfirmSelected();
	};

	const onConfirmSelected = () => {
		const newValue = !switchState;
		setSwitchState(newValue);
		onDataUpdate(row, newValue);
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// force parsed value to boolean for switch
	useEffect(() => {
		setSwitchState(value || false);
	}, [value]);

	if (dependance && row?.original?.isActive === true) {
		return <></>;
	}

	return (
		<>
			<Switch
				checked={switchState}
				onChange={handleSwitchChange}
				color="primary"
				name={`toggleSwitch-${row.id}`}
				inputProps={{ 'aria-label': `toggleSwitch-${row.id}` }}
				disabled={disabled}
			/>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<div className={cx('flexColumn', styles.container)}>
					<div>
						<Typography className={styles.popoverTitle}>Confirmation</Typography>
						<Typography>{confirmMessage ?? 'Voulez-vous utiliser cette version'}? </Typography>
					</div>
					<BButton
						className={styles.button}
						variant="tertiary"
						label={'Confirmer'}
						onClick={() => {
							onConfirmSelected();
						}}
					/>
				</div>
			</Popover>
		</>
	);
};

export default SwitchCell;
