import { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import _ from 'lodash';

import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Unit from '../../units/Unit';

import Popover from './Popover';
import PopoverItem from './PopoverItem';

const WaterImpactPopover = ({ item }: any) => {
	const displayValue = useMemo(() => {
		const value = item?.waterImpact;
		let toDisplay = '-';

		if (!_.isNumber(value)) {
			return toDisplay;
		}

		if (value >= 1) {
			toDisplay = formatNumber(value, undefined, 0);
		}

		if (value >= 0.01 && value < 1) {
			toDisplay = formatNumber(value, undefined, 2);
		}

		if (value < 0.01) {
			toDisplay = '< 0,01';
		}

		return toDisplay;
	}, [item?.waterImpact]);

	const label = useMemo(() => {
		// eslint-disable-next-line quotes
		if (!item?.waterUse) return "facteur d'émission non renseigné";

		// eslint-disable-next-line quotes
		if (!item?.volume) {
			if (item?.volume === 0) {
				return 'Volume incorrect';
			}

			return 'Volume non renseigné';
		}

		return WORDING_TERMS.IMPACT;
	}, [item]);

	return (
		<Popover>
			{item?.waterImpact < 0 ? (
				<Typography fontWeight="600">Volume incorrect</Typography>
			) : (
				<PopoverItem
					label={
						// item?.volume ? WORDING_TERMS.IMPACT : 'Volume non renseigné'
						label
					}
					value={
						item?.volume ? (
							<>
								{displayValue}
								{' '}
								<Unit
									measure="waterImpact"
								// sx={{ textTransform: 'uppercase' }} // don't need uppercase for unit
								/>
								{/* {formatNumber(item?.waterImpact || 0, undefined, 0)} m<sup>3</sup> */}
							</>
						) : (
							''
						)
					}
				/>
			)}
		</Popover>
	);
};

export default WaterImpactPopover;
