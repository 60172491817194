import { orderBy as orderByFunc, sortBy as sortByKey } from 'lodash';

export const sortBy = (data: [Record<string, string | number>], field: string) => {
	return sortByKey(data, (item) => {
		return item[field];
	});
};

export const orderBy = (data: Record<string, string | number>[], field: string, order: 'desc' | 'asc' = 'desc') => {
	return orderByFunc(
		data?.map((item) => {
			return {
				...item,
				waterImpactPercent: parseFloat(((item?.waterImpactPercent || 0) as any)?.toFixed(4)),
				carbonImpactPercent: parseFloat(((item?.carbonImpactPercent || 0) as any)?.toFixed(4)),
				[field]: item[field] || 0,
			};
		}),
		[field],
		[order],
	);
};

export const orderByMultipleKeys = (data: Record<string, string | number>[], fields: any, orders: any) => {
	return orderByFunc(data, fields, orders);
};
