import { create } from 'zustand';

/**
 * use zustand
 * hooks to store rows table selected
 */
export const useQuoteFormToDuplicate = create((set: any) => {
	return {
		toDuplicateQuote: null as any,
		setToDuplicateQuote: (quote: any) => {
			return set(() => {
				return {
					toDuplicateQuote: quote,
				};
			});
		},
		// selectedRows: [] as any,
		// onSelectionChange: (selection: any) => {
		// 	return set(() => {
		// 		return {
		// 			selectedRows: selection,
		// 		};
		// 	});
		// },
	};
});


/**
 * use zustand
 * hooks to store rows table selected
 */
export const useQuoteFormStep = create((set: any) => {
	return {
		step: 1,
		setStep: (newStep: number) => {
			return set(() => {
				return {
					step: newStep,
				};
			});
		},
		// selectedRows: [] as any,
		// onSelectionChange: (selection: any) => {
		// 	return set(() => {
		// 		return {
		// 			selectedRows: selection,
		// 		};
		// 	});
		// },
	};
});
