import { css, cx } from '@emotion/css';
import { siteColors } from '../../../../../apps/front/src/lib/colors';

const styles = {
	container: css({
		width: '100%',
		color: siteColors.grey500,
		fontSize: '16px',
		fontWeight: '600',
		justifyContent: 'center !important',
	}),
};

type EmptyValueProps = {
	className?: string;
};

const EmptyValue = ({ className }: EmptyValueProps) => {
	return <div className={cx('flexRow', styles.container, className)}>-</div>;
};

export default EmptyValue;
