import '@mdxeditor/editor/style.css';

import { RefObject, type ReactNode } from 'react';

import {
	AdmonitionDirectiveDescriptor,
	BlockTypeSelect,
	BoldItalicUnderlineToggles,
	codeBlockPlugin,
	codeMirrorPlugin,
	CreateLink,
	diffSourcePlugin,
	// DirectiveDescriptor,
	directivesPlugin,
	frontmatterPlugin,
	headingsPlugin,
	imagePlugin,
	InsertImage,
	InsertTable,
	KitchenSinkToolbar,
	linkDialogPlugin,
	linkPlugin,
	listsPlugin,
	ListsToggle,
	markdownShortcutPlugin,
	MDXEditor,
	quotePlugin,
	// tablePlugin,
	// thematicBreakPlugin,
	// toolbarPlugin,
	// sandpackPlugin,
	tablePlugin,
	thematicBreakPlugin,
	toolbarPlugin,
	UndoRedo,
	type MDXEditorMethods,
	type MDXEditorProps,
} from '@mdxeditor/editor';
import { alpha, Box, type SxProps, type Theme } from '@mui/material';
import _ from 'lodash';

import { getServerUrl } from '../utils/utils';

// import { ALL_PLUGINS } from '../lib/mdxEditor/boilerplate';
export const ALL_PLUGINS = [
	toolbarPlugin({
		toolbarContents: () => {
			// return <KitchenSinkToolbar />;
			return (
				<>
					<UndoRedo />
					<BoldItalicUnderlineToggles />
					<BlockTypeSelect />
					<CreateLink />
					<ListsToggle />
					<InsertTable />
					<InsertImage />
				</>
			);
		},
	}),
	listsPlugin(),
	quotePlugin(),
	headingsPlugin({ allowedHeadingLevels: [2, 3] }),
	linkPlugin(),
	linkDialogPlugin(),
	imagePlugin({
		// imageAutocompleteSuggestions: ['https://via.placeholder.com/150', 'https://via.placeholder.com/150']
		imageUploadHandler,
	}),
	tablePlugin(),
	thematicBreakPlugin(),
	// frontmatterPlugin(),
	// codeBlockPlugin({ defaultCodeBlockLanguage: 'txt' }),
	// sandpackPlugin({ sandpackConfig: virtuosoSampleSandpackConfig }),
	// codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS', txt: 'text', tsx: 'TypeScript' } }),
	// directivesPlugin({ directiveDescriptors: [/* YoutubeDirectiveDescriptor, */ AdmonitionDirectiveDescriptor] }),
	// diffSourcePlugin({ viewMode: 'rich-text', diffMarkdown: 'boo' }),
	// markdownShortcutPlugin(),
];

export type MdxEditorProps = MDXEditorProps & {
	id: string;
	helperText?: ReactNode;
	disabled?: boolean;
	error?: boolean;
	sx?: SxProps<Theme>;
	editorRef: RefObject<MDXEditorMethods>;
};

const MdxEditor = ({
	id,
	helperText,
	error,
	disabled = false,
	sx /* = {} */,
	markdown = '',
	plugins = ALL_PLUGINS,
	editorRef,
	...other
}: MdxEditorProps) => {
	return (
		<Box
			id={`#${id}`}
			sx={[
				(theme) => {
					const radius = theme.spacing(1);

					return {
						border: `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
						borderRadius: radius,
						'& [role="textbox"]': {
							backgroundColor: alpha(theme.palette.grey[500], 0.08),
						},
						'& [role="toolbar"]': {
							borderRadius: `${radius} ${radius} 0 0`,
							...(disabled && {
								backgroundColor: alpha(theme.palette.grey[500], 0.24),
								color: theme.palette.text.disabled,
							}),
						},
						...(error && {
							border: `solid 1px ${theme.palette.error.main}`,
							'& [role="textbox"]': {
								bgcolor: alpha(theme.palette.error.main, 0.08),
							},
						}),
						...(disabled && {
							pointerEvents: 'none',
							'& [role="textbox"]': {
								bgcolor: alpha(theme.palette.grey[500], 0.24),
								color: theme.palette.text.disabled,
							},
						}),
					};
				},
				...(_.isArray(sx) ? sx : [sx]),
			]}
		>
			<MDXEditor ref={editorRef} markdown={markdown} plugins={plugins} {...other} />
			{helperText && helperText}
		</Box>
	);
};

export default MdxEditor;

async function imageUploadHandler(image: File) {
	const formData = new FormData();
	formData.append('file', image);

	const result = await fetch(`${getServerUrl()}uploadFile`, {
		method: 'POST',
		body: formData,
	});

	const { file: filename } = await result.json();
	const url: string = await Parse.Cloud.run('getImgProxy', { filename });
	return url;
	// send the file to your server and return
	// the URL of the uploaded image in the response
	// const response = await fetch('/uploads/new', {
	//   method: 'POST',
	//   body: formData
	// })
	// const json = (await response.json()) as { url: string }
	// return json.url
	// return filename;
}
