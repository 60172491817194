import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';

import { AUDIT_CONFIG_ITEMS_MAP } from '../../../../../apps/front/src/utils/audit.utils';

const styles = {
	container: css({
		gap: 5,
	}),
	circle: css({
		width: '16px',
		height: '16px',
		borderRadius: '50%',
		background: 'var(--circle-color)',
	}),

	text: css({
		fontWeight: '600 !important',
		fontSize: '14px !important',
		overflow: 'hidden !important',
		textOverflow: 'ellipsis !important',
		whiteSpace: 'nowrap !important',
	} as any),
};

const defaultTrans = (v: any) => {
	return v;
};

const ErrorTypeField = (props: any) => {
	const { value, row } = props;

	const config = AUDIT_CONFIG_ITEMS_MAP.get(row.original.errorKey);
	const t = props?.column?.props?.trans || defaultTrans;

	return (
		<div className={cx('flexRow alignCenter nowrap', styles.container)}>
			{/* <div className={styles.circle} style={{ ['--circle-color' as any]: row?.original?.color }} /> */}
			{config?.icon}
			<Typography className={styles.text}>{t(value)}</Typography>
		</div>
	);
};

export default ErrorTypeField;
