import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Typography, useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Pencil, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { getCompagniesTranslationAction, saveTranslationAction } from '@carbonmaps/ui/actions/translations.action';
import { applyVersionAction, deleteVersionAction, getDataVersionAction } from '@carbonmaps/ui/actions/version.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import Modal from '../../../components/bo/Modal';
import SelectField from '../../../components/form/fields/SelectField';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';

import TranslationForm from './TranslationForm';

const styles = {
	container: css({
		paddingTop: 16,
		gap: 10,
	}),
	selectContainer: css({
		minWidth: '150px',
		minHeight: '42px',
		backgroundColor: 'rgb(236, 233, 242) !important',
		'.MuiInputBase-root': {
			width: '250px',
			minHeight: '42px',
			backgroundColor: 'rgb(236, 233, 242) !important',
		},
	}),
	text: css({
		fontSize: '16px !important',
		fontWeight: '500 !important',
	}),
};

const PAGE_OPTIONS = [
	{ value: '-', label: 'Tous' },
	{
		value: 'global',
		label: 'global',
	},
	{
		value: 'product',
		label: 'product',
	},
	{
		value: 'ingredient',
		label: 'ingredient',
	},
	{
		value: 'eco-design',
		label: 'eco-design',
	},
	{
		value: 'dataset',
		label: 'dataset',
	},
	{
		value: 'Specific LCAs',
		label: 'Specific LCAs',
	},
];

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
			padding: '5px!important',
		},

		'.bordered tr td:first-of-type': {
			width: '80px !important',
		},
		'.bordered tr td': {
			width: '20%',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

const TranslationsTable = () => {
	// ---- theme ---- //
	const theme = useTheme();
	// ---- translation ---- //
	const { t } = useTranslation();
	// ---- styles ---- //
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);
	// ---- fetch data ref ---- //
	const fetchIdRef = useRef(0);
	// ---- company selected ---- //
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState({
		input: '',
		page: 1,
		size: 100,
		companyId,
	});

	const [dataTable, setDataTable] = useState<any>([]);

	const [selectedPage, setSelectedPage] = useState<any>('-');

	// ---- save translation ---- //
	const { mutate: saveTranslation } = useMutation({
		mutationKey: ['saveTranslation'],
		mutationFn: saveTranslationAction,
		onSuccess: async () => {
			// handleCloseModal();
			// queryClient.invalidateQueries({ queryKey: ['getCompagniesTranslation'] });
		},
		onError: (error: any) => {
			console.log('error', error);
		},
	});

	const handleSaveTranslation = useCallback(
		(data: any) => {
			saveTranslation({ ...data, companyId });
		},
		[saveTranslation],
	);

	const updateMyData = (rowIndex: number, columnId: string, value: any) => {
		const newData = dataTable.map((row: any, index: number) => {
			if (index === rowIndex) {
				const newValue = {
					...dataTable[rowIndex],
					[columnId]: value,
				};

				handleSaveTranslation({
					translationId: newValue?.translationId,
					text_fr: newValue?.text_fr,
					text_en: newValue?.text_en,
					companyId,
					[columnId]: value,
				});

				return newValue;
			}

			return row;
		});
		// // We also turn on the flag to not reset the page
		setDataTable(newData);
	};

	// ---- columns ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label="Module" />;
				},
				accessor: 'page',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('fr_text_per_default')} />;
				},
				accessor: 'default_fr',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('en_text_per_default')} />;
				},
				accessor: 'default_en',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('fr_text')} />;
				},
				accessor: 'text_fr',
				editable: true,
				sortDescFirst: true,
				component: 'EditableInput',
				type: 'string',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('en_text')} />;
				},
				accessor: 'text_en',
				editable: true,
				sortDescFirst: true,
				component: 'EditableInput',
				type: 'string',
			},
		];
	}, [t]);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- fetch data version ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getCompagniesTranslation',
			{
				...paramsTable,
				module: selectedPage,
			},
		],
		queryFn: getCompagniesTranslationAction,
	} as any);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
			});
		},
		[updateTableParams],
	);

	useEffect(() => {
		if (!(data as any)?.data) return;

		setDataTable((data as any)?.data);
	}, [data]);

	return (
		<div className={cx('flexColumn', styles.container)}>
			<div className="flexRow alignCenter gap8">
				<Typography className="fontWeight600">Module</Typography>
				<SelectField
					value={selectedPage}
					items={PAGE_OPTIONS?.map((item: any) => {
						return {
							...item,
							label: t(item?.label),
						};
					})}
					renderValue={(item: any) => {
						return <Typography className={styles.text}>{t(item?.label)}</Typography>;
					}}
					onChange={setSelectedPage}
					className={styles.selectContainer}
					paddingRight={24}
				/>
			</div>
			<TableComponent
				tableName={t('version-title')}
				skeleton={isLoading}
				loading={false}
				fetchData={updateTableParams}
				pageCount={(data as any)?.meta?.last_page || 0}
				noDataMessage={t('empty-data-table-version')}
				pageSize={paramsTable.size}
				columns={tableColumns}
				data={dataTable}
				addStyles={stylesTable}
				manualSortBy
				onSortingColumn={handleSortingTable}
				isSelectable={false}
				updateMyData={updateMyData}
			/>
		</div>
	);
};

export default TranslationsTable;
