import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';

import { pxToRem } from '@carbonmaps/ui/utils/styles';

import LightTooltipGlossary from '../LightTooltipGlossary';

type SupplierCardProps = {
	title: string;
	children: React.ReactNode;
	icon?: React.ReactNode;
	withBorder?: boolean;
	sx?: SxProps;
	center?: boolean;
	marginBottom?: number | string;
	glossary?: string;
};

const SupplierCard = ({
	title,
	children,
	icon,
	withBorder = true,
	center = true,
	sx = {},
	marginBottom = 0,
	glossary,
}: SupplierCardProps) => {
	const theme = useTheme();
	return (
		<Box className="flexColumn" sx={{ gap: '16px', width: '100%' }} marginBottom={marginBottom}>
			<Box className="flexRow alignCenter" sx={{ gap: '8px' }}>
				{icon}
				<Typography variant="h3" color={theme.palette.common.black} className={glossary ? 'underline' : ''}>
					{glossary ? <LightTooltipGlossary title={glossary}>
						<div>{title}</div>
					</LightTooltipGlossary> : <div>{title}</div>
					}
				</Typography>
			</Box>
			<Box
				className="flexRow"
				sx={{
					width: '100%',
					...(withBorder && { border: `1px solid ${theme.palette.grey[500]}`, borderRadius: '16px' }),
					...(center && { alignItems: 'center', justifyContent: 'center' }),
				}}
			>
				<Box className="flexRow" sx={{ padding: pxToRem(24), gap: '16px', alignItems: 'stretch', ...sx }}>
					{children}
				</Box>
			</Box>
		</Box>
	);
};

export default SupplierCard;
