import React from 'react';

import CommentCard from '../CommentCard';

type ApboCardProps = {
	children: React.ReactNode;
	messageKey: string;
};

const ApboCard = ({ children, messageKey }: ApboCardProps) => {
	return (
		<div className="flexColumn width100 gap16">
			{children}
			<CommentCard messageKey={messageKey} />
		</div>
	);
};

export default ApboCard;
