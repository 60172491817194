import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { use } from 'i18next';
import { Plus, Quote } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { classNames } from '@carbonmaps/shared/utils/constants';

import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';

import { cn, siteColors } from '../../../../../../lib/colors';
import CreateButton from './CreateButton';
import DevisTable from './DevisTable';
import QuoteGraph from './QuoteGraph';
import QuoteSearch from './QuoteSearch';

const styles = {
	btn1: css({
		borderRadius: '8px!important',
		padding: '8px 16px!important',
		// height: '100%!important',
		// maxHeight: 'unset!important',
		'&:hover': {
			backgroundColor: cn(siteColors.primary, '!important'),
		},
	}),
	a: css({
		marginBottom: '24px',
	}),

	graphContainer: css({
		marginBottom: '16px',
	}),
};

const DevisList = () => {
	const { t } = useTranslation();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();

	const params = useParams();
	const clientId = params.clientId!;

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);

	const { data: clientData, refetch } = useQuery({
		queryKey: ['getClientSheetById', { clientId }] as const,
		queryFn: async (ctx) => {
			const {
				queryKey: {
					1: { clientId },
				},
			} = ctx;

			const client = await new Parse.Query(classNames.CLIENT).equalTo('objectId', clientId).first();

			console.log('client', client);

			return client?.toJSON();
		},
		// enabled: false,
	});

	return (
		<div>
			<div className={cx('flexRow justifyBetween w100 alignCenter', styles.a)}>
				{/* <SearchAutocomplete placeholder={t('search')} /> */}
				<QuoteSearch />

				<CreateButton setTableParams={setTableParams} paramsTable={paramsTable} initialSelectedOption={clientData} />
				{/* <Button
					disableElevation
					disableRipple
					disableFocusRipple
					disableTouchRipple
					variant="contained"
					className={cx('', styles.btn1)}
					startIcon={<Plus size={20} color="#FFFFFF" />}
				>
					{t('form-create-quote-button')}
				</Button> */}
			</div>

			<div className={cx('', styles.graphContainer)}>
				<QuoteGraph />
			</div>

			<div>
				<DevisTable />
			</div>
		</div>
	);
};

export default DevisList;
