import React from 'react';

import { css, cx } from '@emotion/css';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { cn, siteColors } from '../../../lib/colors';
import Typography from '../../Typography';

const styles = {
	container: css({
		border: cn('1px solid', siteColors.grey500),
		borderRadius: 16,
		padding: '24px',
	}),
	value: css({
		fontSize: '24px !important',
		color: cn(siteColors.primary, '#!important'),
	}),
};

type BigIndicatorCardProps = {
	icon: React.ReactNode;
	label: string;
	value: number;
	className?: string;
};

const BigIndicatorCard = ({ icon, label, value, className }: BigIndicatorCardProps) => {
	return (
		<div className={cx('flexRow alignCenter gap16', styles.container, className)}>
			{icon}
			<div className="flexColumn">
				<Typography>{label}</Typography>
				<Typography className={cx('number fontWeight500', styles.value)}>
					{formatNumber(value, undefined, 0)}
				</Typography>
			</div>
		</div>
	);
};

export default BigIndicatorCard;
