import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';
import { getAllDataAudit } from '../actions/audit.actions';

export const useDataAudit = ({
	onError,
	onSuccess,
}: {
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getAllDataAudit', { period: selectedYear }],
		queryFn: getAllDataAudit,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};
