import { ISession } from '@carbonmaps/shared/types/session.types';

type GetSessionByTokenOptions = {
	include?: string[];
	json?: boolean;
};

export function getSessionByToken(
	sessionToken: string,
	options?: { json?: false; include?: string[] },
): Promise<Parse.Session>;
export function getSessionByToken(sessionToken: string, options: { json: true; include?: string[] }): Promise<ISession>;

export async function getSessionByToken(
	sessionToken: string,
	{ json, include }: GetSessionByTokenOptions = { json: false, include: [] },
) {
	const _included = [...new Set(['company', 'loginFrom'].concat(include ?? []))];
	const result: any = new Parse.Query(Parse.Session)
		.equalTo('sessionToken', sessionToken)
		.include(_included)
		.first({ sessionToken, json });
	return result;
}
