import React, { ReactNode, useRef } from 'react';

import { MutationCache, QueryClientProvider as Provider, QueryCache, QueryClient } from '@tanstack/react-query';

type Props = {
	children: ReactNode;
};

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

// eslint-disable-next-line react-refresh/only-export-components
export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
			refetchOnReconnect: true,
			// retry: false,
			staleTime: twentyFourHoursInMs,
		},
	},
	mutationCache: new MutationCache({
		onError: async (error) => {
			// https://github.com/parse-community/parse-server/issues/7277#issuecomment-801959853
			// https://github.com/parse-community/parse-server/issues/7277#issuecomment-801963227
			try {
				if (error instanceof Parse.Error) {
					if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
						await Parse.User.logOut();
						// ? idea: send an custom event and clear the cache in the event handler
						// syncUserState();
					}
				}
			} catch (error) {
				if (error instanceof Parse.Error) {
					if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
						// ? idea: send an custom event and clear the cache in the event handler
						// syncUserState();
					}
				}
			}
		},
	}),
	queryCache: new QueryCache({
		onError: async (error) => {
			// https://github.com/parse-community/parse-server/issues/7277#issuecomment-801959853
			// https://github.com/parse-community/parse-server/issues/7277#issuecomment-801963227
			try {
				if (error instanceof Parse.Error) {
					if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
						await Parse.User.logOut();
						// ? idea: send an custom event and clear the cache in the event handler
						// syncUserState();
					}
				}
			} catch (error) {
				if (error instanceof Parse.Error) {
					if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
						// ? idea: send an custom event and clear the cache in the event handler
						// syncUserState();
					}
				}
			}
		},
	}),
});

const QueryClientProvider = ({ children }: Props) => {
	return <Provider client={queryClient}>{children}</Provider>;
};

export default QueryClientProvider;
