import { siteColors } from '../../../../apps/front/src/lib/colors';

type Props = {
	percentage?: number;
	diff?: number;
	color?: string;
	secondary?: string;
};

const IntensityChart = ({
	percentage = 100,
	color = siteColors.primary,
	secondary = siteColors.grey500,
	diff = 0,
}: Props) => {
	const maxBars = 16;

	let numberOfBars = percentage ? Math.ceil(((percentage - Math.abs(diff)) * maxBars) / 100) : 0;

	if ((numberOfBars === 16 || numberOfBars === 15) && diff) {
		numberOfBars = 14;
	}

	const numberOfBarsDiff = Math.ceil((Math.abs(diff) * maxBars) / 100);
	const isPositiveValue = diff > 0;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={117} height={14} fill="none">
			{Array.from({ length: numberOfBars }).map((_, index) => {
				return <rect key={index} width={5} height={14} x={index * 8} fill={color} rx={2.5} />;
			})}
			{Array.from({ length: numberOfBarsDiff }).map((_, index) => {
				return isPositiveValue ? (
					<rect key={index} width={5} height={14} x={(index + numberOfBars) * 8} fill={siteColors.grey900} rx={2.5} />
				) : (
					<rect
						key={index}
						width={5}
						height={14}
						x={(index + numberOfBars) * 8}
						stroke-dasharray="2 2"
						fill={siteColors.grey400}
						stroke={color}
						rx={2.5}
					/>
				);
			})}

			{Array.from({ length: maxBars - (numberOfBars + numberOfBarsDiff) }).map((_, index) => {
				return (
					<rect
						key={index}
						width={5}
						height={14}
						x={(index + numberOfBars + numberOfBarsDiff) * 8}
						fill={secondary}
						rx={2.5}
					/>
				);
			})}
		</svg>
	);
};

export default IntensityChart;
