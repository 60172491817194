import { css, cx } from '@emotion/css';

import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier-chart-icon-blue.svg';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DonutChart from '../../../../components/charts/DonutChart';
import Skeleton from '../../../../components/Skeleton';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { DEFAULT_GRAPH_INDICATOR_OPTIONS, getDefaultGraphIndicator } from '../../../../utils/graph';
import { useSupplierDonut } from '../../analyse.hooks';
import AnalysisDonutContent from '../component/graph/AnalysisDonutContent';
const styles = {
	container: css({ gap: '24px' }),
	skeleton: css({
		width: '100% !important',
		height: '40px !important',
	}),
};

const toDataIndicatorGraph = (
	data: Record<string, string | number>[],
	category: string,
	indicatorField: string,
	isDisabled?: boolean,
	viewMode?: string,
) => {
	if (!data) {
		return [
			{
				y: 1,
			},
		];
	}

	const result = data.map((item) => {
		return {
			...item,
			category,
			categoryValue: item?.name,
			volume: item?.tonnage,

			y: isDisabled ? 1 : item[`${viewMode}Impact`] || item[indicatorField],
		};
	});

	return result;
};

const SupplierDonut = () => {
	const { t } = useTranslation();
	// ---- popover ---- //
	const { indicator: viewMode } = useApp();
	// ---- fetch data ---- //
	const { data, isLoading } = useSupplierDonut({ viewMode });

	const defaultIndicator = useMemo(() => {
		return getDefaultGraphIndicator(t);
	}, [t]);

	const [indicatorOptions, setIndicatorOptions] = useState(defaultIndicator);

	const [indicatorGraphValue, setIndicatorGraphValue] = useState(DEFAULT_GRAPH_INDICATOR_OPTIONS[viewMode][0]);

	const navigate = useNavigate();
	const params = useParams();
	const handleClick = (value: string) => {
		navigate(`${FRONT_PATH_NAMES.analyse}/${params.segment}/${params.value}/${value}`);
	};

	useEffect(() => {
		setIndicatorGraphValue((indicatorOptions as any)[viewMode][0]);
	}, [viewMode, indicatorOptions]);

	return (
		<div className={cx('flexColumn alignCenter', styles.container)}>
			<DonutChart
				data={toDataIndicatorGraph(data?.suppliers || [], t('suppliers'), `${viewMode}Impact`, false, viewMode) as any}
				color={isLoading ? siteColors.grey400 : siteColors.blueGray500}
				content={
					isLoading ? (
						<Skeleton className={styles.skeleton} />
					) : (
						<AnalysisDonutContent icon={<SupplierIcon />} label={t('suppliers')} value={data?.count || 0} />
					)
				}
				width={250}
				innerRadius={32}
				indicator={indicatorGraphValue}
				onClick={(item: any) => {
					if (item.objectId === 'other') return;
					window.open(`${FRONT_PATH_NAMES.suppliers.details}/${item?.objectId}/synthesis`);
				}}
				// disabled={!path}
				// renderTooltip={renderTooltip}
				// renderContent={renderContent}
				// tooltipPosition={tooltipPosition}
				// sx={{ ...(isDisabled && { pointerEvents: 'none', opacity: 0.8 }), ...sx }}
				// radiusDonnut={radiusDonnut}
			/>

			<BButton
				variant="secondary"
				label={t('see-all-suppliers')}
				onClick={() => {
					handleClick('supplier');
					return;
				}}
				addStyles={{
					height: 'auto',
					minHeight: 42,
					// ...(!button?.link && {
					// 	pointerEvents: 'none',
					// 	color: 'rgba(0, 0, 0, 0.38)',
					// }),
				}}
			/>
		</div>
	);
};

export default SupplierDonut;
