import { useQuery } from '@tanstack/react-query';

export const useFindSectorSheetGroups = ({ fromBo }: { fromBo?: boolean } = {}) => {
	const key = ['findSectorSheetGroups'] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async (ctx) => {
			try {
				return Parse.Cloud.run('findSectorSheetGroups', { fromBo });
			} catch (error) {
				console.log('---- findSectorSheetGroups error', error);
				return Promise.reject(error);
			}
		},
	});

	return {
		key, result,
	};
};
