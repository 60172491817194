import { css, cx } from '@emotion/css';
import { Button, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Eye, EyeOff, RotateCcw } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { resetScenarioAction } from '@carbonmaps/ui/actions/simulation.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import Popover from '../../../components/Popover';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useShowDeleted } from '../scenario.hooks';

const styles = {
	width300: css({
		width: 1000,
		// width: '400px !important',
		// whiteSpace: 'nowrap',
	}),
	dropItemButton: css({
		gap: 8,
		fontSize: '14px',
		fontWeight: 400,
		cursor: 'pointer',
		padding: '8px 16px 8px 16px',
		// background: 'transparent!important',
		color: cn(siteColors.text, '!important'),
		paddingInline: 0,
		whiteSpace: 'nowrap!important',
		// width: '400px',
		// display: 'flex!important',
	} as any),
};

const HideOrShowBlock = () => {
	// const [isResetDialogOpen, toggleResetDialog] = useToggle(true);
	// const params = useParams();
	// const queryClient = useQueryClient();

	// translation
	const { t } = useTranslation();

	const { setShowDeleted, showDeleted } = useShowDeleted();

	const resetData = () => {
		setShowDeleted(!showDeleted);
	};

	return (
		<BButton
			className={styles.dropItemButton}
			variant="secondary"
			iconLeft={!showDeleted ? <Eye className="grey700" size={20} /> : <EyeOff className="grey700" size={20} />}
			label={!showDeleted ? t('show-deleted-element-button') : t('hide-deleted-element-button')}
			// startIcon={<Eye className="grey700" size={20} />}
			onClick={resetData}
		/>
		//Afficher les éléments supprimés
		//</BButton>
		// <div /* className={styles.width300} */>
		// </div>
	);
};

export default HideOrShowBlock;
