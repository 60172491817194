import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import ImpactKpi from '../../components/ImpactKpi';
import RecycledKpi from '../../components/RecycledKpi';
import TonnageKpi from '../../components/TonnageKpi';
import { useGetImpact, useTonnage } from '../../packagings.hooks';

const PackagingKPIs = ({ filterOptions }: { filterOptions: any }) => {
	const { indicator } = useApp();
	// ---- fetch data impact total --- //

	const [searchQueryParams] = useSearchQueryParams();

	const { data, isLoading } = useGetImpact({
		inputSearch: searchQueryParams.input,
		facetFilters: searchQueryParams?.facetFilters || [],

	});
	// ---- fetch data tonnage --- //

	const { data: DataTonnage, isLoading: isLoadingTonnage } = useTonnage({
		inputSearch: searchQueryParams.input,
		facetFilters: searchQueryParams?.facetFilters || [],
	});

	return (
		<div className="flexRow  width100 stretch gap16">
			<ImpactKpi data={data} isLoading={isLoading} />
			<TonnageKpi tonnage={DataTonnage?.tonnage} isLoading={isLoadingTonnage} />
			<RecycledKpi />
		</div>
	);
};

export default PackagingKPIs;
