import { useCallback } from 'react';

import { css } from '@emotion/css';
import { Plus as PlusIcon } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import Skeleton from '../../../components/Skeleton';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';

const styles = {
	title: css({
		fontSize: '16px !important',
	}),
	button: css({
		width: '240px !important',
	}),
};

type CreateDevisCarboneProps = {
	isLoading: boolean;
	setOpenModal: any;
	setSelectedOption: any;
	setIsNew: any;
	setSearch: any;
	initialSelectedOption?: any;
};

const CreateDevisCarbone = ({
	setSearch,
	isLoading,
	setOpenModal,
	setSelectedOption,
	setIsNew,
	initialSelectedOption,
}: CreateDevisCarboneProps) => {
	const { t } = useTranslation();

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);
	const elem = (
		<>
			<BButton
				variant="primary"
				label={t('form-create-quote-button')}
				onClick={() => {
					trackEvent('Simulate Carbon Quote Add Item');
					setSearch({ input: '', page: 1 });
					setIsNew(true);
					setSelectedOption(initialSelectedOption);
					return setOpenModal(true);
				}}
				iconLeft={<PlusIcon color="white" />}
				className={styles.button}
			/>
		</>
	);

	return isLoading ? <Skeleton>{elem}</Skeleton> : elem;
};

export default CreateDevisCarbone;
