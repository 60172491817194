import { css, cx } from '@emotion/css';
import _ from 'lodash';
import GenericEmptyBlock from '../../../../../components/GenericEmptyBlock';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';
import { useFindQuestionFormsTabs } from '../hooks/rse.hooks';
import MasterCategorySelect from './MasterCategorySelect';
import SectionRepartition from './SectionRepartition';
import SupplierRepartition from './SupplierRepartition';
import SupplierRSETable from './SupplierRSETable';

const styles = {
	gridContainer: css({
		maxWidth: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridColumnGap: 32,
		gridRowGap: 32,
		paddingBottom: 20,
		marginBottom: 20,
	}),
	container: css({
		marginTop: '32px',
	}),
	emptyBlock: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFF',

		padding: 24,
		gap: '16px',
	}),
};

const ContentRse = ({ isGeneralView = false }) => {
	const { data: tabsData, isLoading: isLoadingTabsData } = useFindQuestionFormsTabs();

	if (!isLoadingTabsData && _.isEmpty(tabsData)) {
		return <EmptyBlock />;
	}

	return (
		<div className={cx('width100')}>
			<MasterCategorySelect />
			<div className={cx(styles.gridContainer, 'width100')}>
				<SupplierRepartition isLoading={isLoadingTabsData} />
				<SectionRepartition isGeneralView={isGeneralView} />
			</div>
			<div>
				<SupplierRSETable />
			</div>
		</div>
	);
};

export default ContentRse;

const EmptyBlock = () => {
	const { t } = useTranslation();
	// return <div className={cx('width100 flexColumn alignCenter', styles.emptyBlock)}>Aucun questionnaire</div>;
	return <GenericEmptyBlock text1={t('empty-rse-general-text1')} text2={t('empty-res-general-text2')} />;
};
