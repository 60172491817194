import { useMemo, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ROLES_HIERARCHY } from '@carbonmaps/shared/utils/constants';
import {
	UserAccountFormSuperAdminInput,
	userAccountFormSuperAdminValidationSchema,
} from '@carbonmaps/shared/validations/user.validations';
import { createUserRowAction } from '@carbonmaps/ui/actions/userRow';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import BSelectNative from '@carbonmaps/ui/components/saas/BSelectNative';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import AlertStatic from '../../../components/Toast/AlertStatic';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

const UserForm = ({ toggle, company }: any) => {
	const [message, setMessage] = useState('');
	const queryParams = useQueryParams();
	const queryClient = useQueryClient();

	const { t, i18n } = useTranslation();

	const languageCode = getIsoCountryCode(i18n.language);

	const optionsRoles = useMemo(() => {
		return ROLES_HIERARCHY.map((role: any, index: any) => {
			return { value: role.suffixName, label: t(role.label) };
		});
	}, [t]);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isDirty, isValid },
		// watch,
		reset,
	} = useForm<UserAccountFormSuperAdminInput>({
		resolver: zodResolver(userAccountFormSuperAdminValidationSchema),
		defaultValues: {
			companyId: queryParams?.companyId ? queryParams?.companyId[0] : '',
		},
		// defaultValues: async () => {
		// 	const { data } = await refetch();
		// 	return {
		// 		name: data?.company.name || '',
		// 		objectId: data?.company.objectId || '',
		// 		code: data?.company.code || '',
		// 			code: data?.company.code || '',
		// 	};
		// },
		mode: 'onChange',
	});

	const {
		mutate: createUserFromSuperAdmin,
		isError,
		isLoading,
		isSuccess,
	} = useMutation({
		mutationKey: ['createUserFromSuperAdmin'],
		mutationFn: createUserRowAction,
		onSuccess: async () => {
			setMessage(t('add-user-success-message'));
			queryClient.invalidateQueries({ queryKey: ['findUsers'] });
			toggle();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
				// setTimeout(() => {
				// 	setMessage('');
				// }, 3000);
			}
		},
	});

	const onSubmit: SubmitHandler<UserAccountFormSuperAdminInput> = async (values) => {
		createUserFromSuperAdmin({
			...values,
			languageCode,
			// file
		} as any);
	};

	return (
		<>
			<div css={{ marginBottom: 24 }}>
				<FormContainer
					loading={isLoading}
					title=""
					subtitle={t('form-user-title')}
					onSubmit={handleSubmit(onSubmit)}
					isSuccess={isSuccess}
					isError={isError}
					withSave
					// withCancel
					onCancel={() => {
						toggle();
					}}
					buttonTitle={t('save-user-button')}
					alertSuccess={
						<AlertStatic text={message} type={'success'} icon={<CheckIcon css={{ color: siteColors.green500 }} />} />
					}
					alertError={<AlertStatic text={message} type={'error'} icon={<ErrorIcon css={{ color: '#C74600' }} />} />}
					addStyles={{
						maxWidth: 528,
						// gap: 24,
						margin: 'auto',
					}}
				>
					<div
						className="flexColumn width100"
						css={{
							maxWidth: 400,
							gap: 24,
							'.input-container': {
								width: '100%',
							},
						}}
					>
						<BInput
							required
							id="email"
							label={t('input-email-label')}
							fullWidth
							margin="dense"
							{...register('email')}
							name="email"
							error={errors.email ? true : false}
							errorMessage={errors?.email?.message}
							autoComplete="off"
						/>
					</div>

					<div
						className="flexColumn width100"
						css={{
							maxWidth: 400,
							gap: 24,
							'.input-container': {
								width: '100%',
							},
						}}
					>
						<BInput
							required
							id="lastName"
							label={t('last-name-label')}
							fullWidth
							margin="dense"
							{...register('lastName')}
							name="lastName"
							error={errors.lastName ? true : false}
							errorMessage={errors?.lastName?.message}
							// onChange={handleNameChange}
							autoComplete="off"
						/>
					</div>
					<div
						className="flexColumn width100"
						css={{
							maxWidth: 400,
							gap: 24,
							'.input-container': {
								width: '100%',
							},
						}}
					>
						<BInput
							required
							id="firstName"
							label={t('first-name-label')}
							fullWidth
							margin="dense"
							{...register('firstName')}
							name="firstName"
							// disabled={true}
							error={errors.firstName ? true : false}
							errorMessage={errors?.firstName?.message}
							autoComplete="off"
						/>
					</div>

					<div
						className="flexColumn width100"
						css={{
							maxWidth: 400,
							marginTop: '12px',
							gap: 24,
						}}
					>
						<BSelectNative
							{...register('position')}
							required
							id="position"
							label={t('user-position-label')}
							options={[
								{ value: 'Qualité', label: t('position-quality') },
								{ value: 'R&D', label: t('rd-label') },
								{ value: 'Achat', label: t('purchase-position') },
								{ value: 'Direction', label: t('direction-position') },
								{ value: 'Responsable RSE', label: t('rse-position') },
								{ value: 'Conseiller technique', label: t('ct-position') },
								{ value: 'Autre', label: t('other-text') },
							]}
							name="position"
						/>
					</div>
					<div
						className="flexColumn width100"
						css={{
							maxWidth: 400,
							marginTop: '12px',
							gap: 24,
						}}
					>
						<BSelectNative
							{...register('roleCode')}
							required
							id="roleCode"
							label={t('role-label')}
							options={optionsRoles}
							name="roleCode"
						/>
					</div>
				</FormContainer>
			</div>
		</>
	);
};

export default UserForm;
