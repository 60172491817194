import { css, cx } from '@emotion/css';

import { useGlossary } from '../../hooks/useGlossary';
import LightTooltipGlossary from '../LightTooltipGlossary';

type PointListLabelProps = {
	color?: string;
	backgroundColor?: string;
	borderColor?: string;
	value?: string;
	keyGlossary?: string;
};

const styles = {
	container: css({
		alignContent: 'middle',
		gap: '8px',
		alignItems: 'center',
		justifyContent: 'center',
		// width: 'fit-content',

	}),
	label: css({
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '150%',
		color: 'var(--color)',
	}),
	puce: css({
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		background: 'var(--backgroundColor)',
		marginTop: '8px',
		border: '1px solid var(--borderColor)',
	}),
	link: css({
		textDecoration: 'none !important',
	}),


};

const PointListLabel = ({
	color,
	backgroundColor,
	borderColor,
	value,
	keyGlossary,
}: PointListLabelProps) => {

	const { g } = useGlossary();


	return (
		<LightTooltipGlossary title={g(keyGlossary || '')}>
			<div className={cx('flexRow nowrap ', styles.container)}>
				<div className={cx(styles.puce)} style={{ ['--backgroundColor' as any]: backgroundColor, ['--borderColor' as any]: borderColor ?? 'white' }}></div>
				<div className={cx(styles.label)} style={{ ['--color' as any]: color ?? 'white' }}>{value}</div>
			</div>
		</LightTooltipGlossary>
	);
};

export default PointListLabel;
