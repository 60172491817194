import { useEffect } from 'react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import EmbedNotion from '../../components/EmbedNotion';
import SEO from '../../components/SEO';

const Documentation = () => {
	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([{ text: 'Documentation' }]);
	}, [setBreadcrumbs]);

	return (
		<>
			<SEO title="Documentation - Carbon Maps" />
			<EmbedNotion url="https://v2-embednotion.com/Documentation-78025314ffac42ea983e4636bbcc383c" />
		</>
	);
};

export default Documentation;
