import Dialog from '../../components/dialogs/Dialog';
import { useTranslation } from '../../hooks/useTranslation';

import ScenarioForm from './content/ScenarioForm';
type ScenarioEditModalProps = {
	simulation: any;
	onSaveData: (data: any) => void;
	open?: boolean;
	onlyNetWeight?: boolean;
	onClose: (e?: any) => void;
	isLoading?: boolean;
	options?: any;
	selectedOption?: any;
	setSelectedOption?: (v: any) => void;
	setSearch?: (v: any) => void;
	title?: string;
};

const ScenarioEditModal = ({
	simulation,
	open = false,
	onlyNetWeight = false,
	onClose,
	options,
	isLoading,
	selectedOption,
	setSelectedOption,
	onSaveData,
	setSearch,
	title,
}: ScenarioEditModalProps) => {
	const { t } = useTranslation();
	return (
		<Dialog
			title={''}
			open={open}
			toggle={onClose}
			isLoading={isLoading}
			paperProps={{
				width: '707px',
			}}
			withCancelButton={false}
		>
			<ScenarioForm
				title={title ?? t('simulation-details-modal-title')}
				onClose={onClose}
				simulation={simulation}
				options={options}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				onSaveData={onSaveData}
				setSearch={setSearch}
				onlyNetWeight={onlyNetWeight}
			/>
		</Dialog>
	);
};

export default ScenarioEditModal;
