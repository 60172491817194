import { z } from 'zod';

export const SimulationFormValidationSchema = z.object({
	name: z
		.string({ required_error: 'Nom du scénario obligatoire' })
		.min(1, 'Nom du scénario obligatoire')
		.max(255, 'Libelle doit avoir 255 caractères au maximum'),
	productId: z.string({ required_error: 'produit obligatoire' }),
});

export type SimulationFormInput = z.infer<typeof SimulationFormValidationSchema>;

export const ScenarioFormValidationSchema = z.object({
	name: z
		.string({ required_error: 'Nom du scénario obligatoire' })
		.min(1, 'Nom du scénario obligatoire')
		.max(255, 'Libelle doit avoir 255 caractères au maximum'),
	productId: z.string({ required_error: 'produit obligatoire' }),
	netWeight: z.any(),
	status: z.string({ required_error: 'Le statut est obligatoire' }),
	comment: z.string().optional(),
});

export type ScenarioFormInput = z.infer<typeof ScenarioFormValidationSchema>;
