import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Checkbox, useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AlertCircle, Check } from 'lucide-react';
import { SubmitHandler, useForm } from 'react-hook-form';

import defaultLogo from '@carbonmaps/media/images/default-logo.png';
import {
	FacetFieldFormInput,
	FacetFieldFormValidationSchema,
} from '@carbonmaps/shared/validations/facetField.validations';
import { createUpdateFacetFieldAction } from '@carbonmaps/ui/actions/company.actions';
import BCheckbox from '@carbonmaps/ui/components/saas/BCheckbox';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import BSelectNative from '@carbonmaps/ui/components/saas/BSelectNative';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { classes } from '@carbonmaps/ui/components/saas/Form/FormStyle';

import AlertStatic from '../../../../components/Toast/AlertStatic';
import { useQueryParams } from '../../../../hooks/useSearchParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../../../lib/react-query/features/img/img.hooks';

const FieldForm = ({ toggle, companyId, indexId, currentField }: any) => {
	const theme = useTheme();
	const [message, setMessage] = useState('');
	const [isVisibleInFront, setIsVisibleInFront] = useState(false);
	const [global, setGlobal] = useState(false);
	const styles = classes(theme);
	const queryClient = useQueryClient();
	// query params
	const queryParams = useQueryParams();

	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<FacetFieldFormInput>({
		resolver: zodResolver(FacetFieldFormValidationSchema),
		defaultValues: async () => {
			setIsVisibleInFront(currentField?.isVisibleInFront === true /*  ?? isVisibleInFront */);
			setGlobal(currentField?.global === true /*  ?? false */);
			return {
				type: currentField?.type || 'string',
				companyId: companyId,
				indexId: indexId,
				isVisibleInFront,
				label: currentField?.label || '',
				field: currentField?.field || '',
				isNew: currentField ? 0 : 1,
				global: currentField?.global /*  ?? false */,
				collectionName: queryParams?.collection[0] || 'Product',
			};
		},
		mode: 'onChange',
	});

	const {
		mutate: createUpdateFacetField,
		isError,
		isSuccess,
		isLoading,
	} = useMutation({
		mutationKey: ['createUpdateFacetField'],
		mutationFn: createUpdateFacetFieldAction,
		onSuccess: async () => {
			setMessage(t('save-facet-message'));
			queryClient.invalidateQueries({ queryKey: ['getCompanySheetFacet'] });
			toggle();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<FacetFieldFormInput> = async (values) => {
		createUpdateFacetField({
			...values,
		} as any);
	};

	const handleChangeIsVisibleInFront = (event: any) => {
		setIsVisibleInFront((current) => {
			return !current;
		});
		setValue('isVisibleInFront', event.target.checked);
	};

	// const handleChangeGlobal = (event: any) => {
	// 	setGlobal(current => { return !current; });
	// 	setValue('global', event.target.checked);
	// };

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: authData?.session.company?.logo || '' },
	});

	return (
		<div css={{ marginBottom: 24 }}>
			<FormContainer
				loading={isLoading}
				title=""
				buttonTitle={t('save-facet-button')}
				defaultLogo={authData?.session.company?.logo ? logoUrl : defaultLogo}
				// defaultLogo={isSuccessUserLoading && currentUser?.company?.logoUrl}
				onSubmit={handleSubmit(onSubmit)}
				isSuccess={isSuccess}
				isError={isError}
				withSave
				onCancel={() => {
					toggle();
				}}
				alertSuccess={<AlertStatic text={message} type={'success'} icon={<Check color={siteColors.green500} />} />}
				alertError={<AlertStatic text={message} type={'error'} icon={<AlertCircle color="#C74600" />} />}
				addStyles={{
					maxWidth: 528,
					// gap: 24,
					margin: 'auto',
				}}
			>
				<input type="hidden" {...register('collectionName')} value={queryParams?.collection[0]} />
				<input type="hidden" {...register('companyId')} value={queryParams?.companyId[0]} />
				<input type="hidden" {...register('indexId')} value={queryParams?.indexId[0]} />
				<input type="hidden" {...register('isNew')} value={currentField ? 0 : 1} />
				<p css={styles.subtitle}>
					{currentField?.field
						? // eslint-disable-next-line quotes
						  t('form-update-field-title')
						: t('form-add-field-title')}
				</p>

				<div
					className="flexColumn width100"
					css={{
						maxWidth: 350,
						marginTop: '12px',
						marginBottom: '12px',
						gap: 24,
					}}
				>
					{!currentField ? (
						<BSelectNative
							{...register('type')}
							id="type"
							label={t('field-type')}
							options={[
								{ value: 'string', label: 'String' },
								{ value: 'number', label: 'Number' },
							]}
							name="type"
							error={errors.type ? true : false}
							errorMessage={errors?.type?.message}
						/>
					) : (
						<BInput
							required
							id="type"
							label={t('field-type')}
							fullWidth
							margin="dense"
							{...register('type')}
							name="type"
							disabled={currentField?.field ? true : false}
							error={errors.field ? true : false}
							errorMessage={errors?.field?.message}
							autoComplete="off"
						/>
					)}
				</div>
				<div
					className="flexColumn width100"
					css={{
						maxWidth: 350,
						gap: 24,
						'.input-container': {
							width: '100%',
						},
					}}
				>
					<BInput
						required
						id="label"
						label={t('field-label')}
						fullWidth
						margin="dense"
						{...register('label')}
						name="label"
						error={errors.label ? true : false}
						errorMessage={errors?.label?.message}
						autoComplete="off"
					/>
				</div>
				<div
					className="flexColumn width100"
					css={{
						maxWidth: 350,
						gap: 24,
						'.input-container': {
							width: '100%',
						},
					}}
				>
					<BInput
						required
						id="field"
						label={t('field-code')}
						fullWidth
						margin="dense"
						{...register('field')}
						name="field"
						disabled={currentField?.field ? true : false}
						error={errors.field ? true : false}
						errorMessage={errors?.field?.message}
						autoComplete="off"
					/>
				</div>

				<div
					className="flexColumn width100"
					css={{
						maxWidth: 450,
						//	gap: 24,
						'.input-container': {
							width: '100%',
						},
					}}
				>
					<BCheckbox
						id="isVisibleInFront"
						label={t('field-checkbox-is-front')}
						{...register('isVisibleInFront')}
						name="isVisibleInFront"
						errorMessage={errors?.isVisibleInFront?.message}
						control={<Checkbox checked={isVisibleInFront} onChange={handleChangeIsVisibleInFront} />}
					/>

					{/* remove facet global and put it static in code */}

					{/* <BCheckbox
						id="global"
						label="Facet global ?"
						{...register('global')}
						name="global"
						errorMessage={errors?.global?.message}
						control={
							<Checkbox
								checked={global}
								onChange={handleChangeGlobal}
							/>}
					/> */}
				</div>
			</FormContainer>
		</div>
	);
};

export default FieldForm;
