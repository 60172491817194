import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { PackageOpen } from 'lucide-react';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetPackagingCount } from '../../../lib/react-query/features/packaging/packaging.hooks';

const styles = {
	headerBg: css({
		background: cn(siteColors.brown10, '!important'),
	}),
};

const PackagingsHeader = () => {
	const theme = useTheme();

	const { t } = useTranslation();

	// ---- fetch count packaging ---- //
	const {
		result: { data: packagingCount },
	} = useGetPackagingCount();

	return (
		<ListHeader
			title={t('Emballages')}
			subtitle={`${packagingCount ?? 0} ${t('product-reference')}`}
			icon={
				<IconContainer
					color={siteColors.brown500}
					element={<PackageOpen color={theme.palette.common.white} size={32} />}
				/>
			}
			className={styles.headerBg}
		/>
	);
};

// rename
export default PackagingsHeader;
