import { css, cx } from '@emotion/css';

import DonutChart from '../../../../components/charts/DonutChart';
import Legend from '../../../../components/dashboard/Legend';
import Typography from '../../../../components/Typography';
import { cn, siteColors } from '../../../../lib/colors';

const styles = {
	outerContainer: css({
		gap: 30,
	}),
	container: css({
		width: 120,
	}),
	legend: css({
		color: cn(siteColors.grey800, '!important'),
	}),
	link: css({
		cursor: 'default !important',
	}),
};

type EcoDesignGraphProps = {
	data: any;
};

const EcoDesignGraph = ({ data }: EcoDesignGraphProps) => {
	return (
		<div className={cx('flexRow alignCenter', styles.outerContainer)}>
			<div className={styles.container}>
				<DonutChart
					width={120}
					innerRadius={42}
					data={data}
					indicator={'undefined'}
					onClick={function (item: any): void {
						// throw new Error('Function not implemented.');
					}}
					topPosition={-55}
					withTooltip={false}
					disabled={true}
				/>
			</div>
			<div className="flexRow gap8">
				<div className="flexColumn gap12">
					{data.map((item: any, index: number) => {
						return (
							<Legend
								key={index}
								color={item.color}
								label={item?.label}
								display="inline"
								withValue={false}
								className={styles.link}
							/>
						);
					})}
				</div>
				<div className="flexColumn gap12">
					{data.map((item: any, index: number) => {
						return (
							<Typography className={cx('lineHeight1', styles.legend)} key={index}>
								{item?.y}
							</Typography>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default EcoDesignGraph;
