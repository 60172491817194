import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { ReactComponent as IngredientIcon } from '@carbonmaps/media/lifeCycle/ingredients.svg';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetProductSheetTimePeriod, useModelizationProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Unit from '../../../components/units/Unit';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ProductModel from '../../../models/Product.model';
import { CARBONE_INDICATOR, WATER_INDICATOR } from '../../../utils/constants';

const TopOverView = () => {
	const theme = useTheme();
	const params = useParams();
	const { t } = useTranslation();
	const { indicator, setIndicator } = useApp();
	const [intensity, setIntensity] = useState(0);
	const [percentage, setPercentage] = useState(0);

	const indicatorConfigItems = new Map();

	indicatorConfigItems.set(CARBONE_INDICATOR, {
		intensityUnity: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
		color: theme.palette.primary.main,
	});
	indicatorConfigItems.set(WATER_INDICATOR, {
		color: siteColors.water500,
		intensityUnity: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
	});

	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBONE_INDICATOR);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setIndicator]);

	useEffect(() => {
		if (indicator === CARBONE_INDICATOR) {
			setPercentage(carbonIntensityPercent() ?? 0);
			setIntensity(carbonIntensity() ?? 0);
		} else {
			setPercentage(waterIntensityPercent() ?? 0);
			setIntensity(waterIntensity() ?? 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [indicator]);

	// const { data } = useGetProductSheetTimePeriod({
	// 	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	// 	objectId: params.productId!,
	// 	//period: 2023,
	// });

	const { data } = useModelizationProduct({
		productId: params.productId!,
	});

	const carbonIntensityPercent = () => {
		if (!data) return;
		const productObject = new ProductModel(data);
		const isRecetteType = ['n1_semantic', 'n1_typical'].includes(
			_.toLower(productObject.product.typeModelizationIngredient),
		);

		return (
			(((isRecetteType ? productObject.getGesIngredient(false) : productObject.getGesIngredientV2()) ?? 0) * 100) /
			(productObject.getIntensity('gesTotal') || 1)
		);
	};

	const waterIntensityPercent = () => {
		if (!data) return;
		const productObject = new ProductModel(data);
		const isRecetteType = ['n1_semantic', 'n1_typical'].includes(
			_.toLower(productObject.product.typeModelizationIngredient),
		);

		return (
			(((isRecetteType ? productObject.getWaterIngredient(false) : productObject.getWaterIngredientV2()) ?? 0) * 100) /
			(productObject.getIntensity('waterUseTotal') || 1)
		);
	};

	const carbonIntensity = () => {
		if (!data) return;
		const productObject = new ProductModel(data);
		const isRecetteType = ['n1_semantic', 'n1_typical', 'Typical_Ingredients'].includes(
			_.toLower(productObject.product.typeModelizationIngredient),
		);

		return (isRecetteType ? productObject.getIntensity('gesAgriculture') : productObject.getGesIngredientV2()) ?? 0;
	};

	const waterIntensity = () => {
		if (!data) return;
		const productObject = new ProductModel(data);
		const isRecetteType = ['n1_semantic', 'n1_typical', 'Typical_Ingredients'].includes(
			_.toLower(productObject.product.typeModelizationIngredient),
		);

		return (
			(isRecetteType ? productObject.getIntensity('waterUseAgriculture') : productObject.getWaterIngredientV2()) ?? 0
		);
	};

	const _productObject = new ProductModel(data);
	const _isRecetteType = ['n1_semantic', 'n1_typical', 'Typical_Ingredients'].includes(
		_.toLower(_productObject.product.typeModelizationIngredient),
	);

	return (
		<Box
			className="flexRow alignCenter"
			paddingBottom="28px"
			borderBottom={`2px solid ${theme.palette.grey[300]}`}
			marginBottom="40px"
		>
			<IngredientIcon width={64} height={64} css={{ '& path': { fill: siteColors.text } }} />
			<Typography fontSize="20px" fontWeight={600} ml="28px">
				{t('agriculture-transformation-tab')}
			</Typography>
			<Divider
				orientation="vertical"
				flexItem
				sx={{ border: `1px solid ${theme.palette.grey[500]}`, marginX: '24px' }}
			/>
			<Box>
				<Typography fontSize="23px" color={indicatorConfigItems.get(indicator).color} className="number">
					{_isRecetteType || intensity === 0
						? '-'
						: // : formatNumber(intensity || 0, undefined, 2)
						  displayValue(intensity)}
				</Typography>
				<Typography color={theme.palette.grey[700]} fontSize="12px">
					{indicatorConfigItems.get(indicator).intensityUnity}
				</Typography>
			</Box>
			<Divider
				orientation="vertical"
				flexItem
				sx={{ border: `1px solid ${theme.palette.grey[500]}`, marginX: '24px' }}
			/>
			<Box>
				<Typography fontSize="23px" color={indicatorConfigItems.get(indicator).color} className="number">
					{_isRecetteType || intensity === 0
						? '-'
						: // : formatNumber(percentage || 0, undefined, 1)
						  displayValue(percentage, undefined, 1)}{' '}
					%
				</Typography>
				<Typography textTransform="uppercase" color={theme.palette.grey[700]} fontSize="12px">
					{t('agriculture-transformation-amont-lifecycle')}
				</Typography>
			</Box>
		</Box>
	);
};

export default TopOverView;
