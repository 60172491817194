import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getTotalImpactByPeriod } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Difference from '../../../containers/scenario/Difference';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import Skeleton from '../../Skeleton';
import Typography from '../../Typography';

const styles = {
	container: css({
		minHeight: 42,
	}),
	value: css({
		color: 'var(--color) !important',
		fontSize: '32px !important',
	}),
	skeleton: css({
		height: '42px !important',
		width: '100% !important',
	} as any),
};

const TotalImpact = () => {
	const { indicator } = useApp();
	const theme = useTheme();
	const [searchQueryParams] = useSearchQueryParams();

	// period selected
	const { selectedYear } = useYearSelection();
	// ---- fetch count product by period ---- //
	const { data, isLoading } = useQuery({
		queryKey: ['getTotalImpactByPeriod', { period: selectedYear, facetFilters: searchQueryParams?.facetFilters || [] }],
		queryFn: getTotalImpactByPeriod,
	});

	return isLoading ? (
		<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
	) : data?.[`${indicator}Impact`] ? (
		<div className={cx('flexRow alignCenter gap16 nowrap', styles.container)}>
			<Typography
				className={cx('number', styles.value)}
				style={{ ['--color' as any]: theme.palette.indicator[indicator].main }}
			>
				{data?.[`${indicator}Impact`] ? formatNumber(data?.[`${indicator}Impact`], undefined, 0) : null}
			</Typography>
			{/* {data?.[`${indicator}Impact`] && data?.[`${indicator}ImpactDiffPercent`] ? (
				<Difference decimal={1} value={data?.[`${indicator}ImpactDiffPercent`]} />
			) : null} */}
		</div>
	) : null;
};

export default TotalImpact;
