import { css, cx } from '@emotion/css';
type PopoverProps = {
	children: React.ReactNode;
};

const styles = {
	container: css({
		padding: '24px',
		gap: 12,
	}),
};

const Popover = ({ children }: PopoverProps) => {
	return <div className={cx('flexColumn', styles.container)}>{children}</div>;
};

export default Popover;
