import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { Cloud } from 'lucide-react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

type TagProps = {
	icon?: ReactNode;
	value?: number;
	mainClassName?: string;
	textClassName?: string;
	iconContainClass?: string;
	precision?: number;
	abbreviatedNumbers?: boolean;
	showValue?: string;
};

const styles = {
	top: css({
		color: 'var(--color)',
		marginBottom: '8px',
	}),
	value: css({
		fontSize: '24px!important',
		marginLeft: '7px',
	}),
	unit: css({
		marginBottom: '12px',
	}),
};

const IndicatorTag = ({
	icon,
	value = 0,
	iconContainClass = '',
	mainClassName = '',
	textClassName = '',
	precision = 2,
	abbreviatedNumbers = false,
	showValue,
}: TagProps) => {
	const { indicator } = useApp();
	const theme = useTheme();
	return (
		<div
			className={cx('flexRow alignCenter nowrap', styles.top, mainClassName)}
			style={{ ['--color' as any]: theme.palette.indicator[indicator].main }}
		>
			{/* <div className={cx(iconContainClass)}>{icon || <Cloud size={32} />}</div> */}
			{icon || <Cloud size={32} />}
			<div className={cx(styles.value, textClassName, 'number')}>
				{showValue ?? formatNumber(value, '', precision, true, '-', abbreviatedNumbers)}
			</div>
		</div>
	);
};

export default IndicatorTag;
