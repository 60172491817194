import { /*  useEffect,  */ useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { Cloud, Cloudy, Droplet, Droplets, Map, ShoppingCart } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { Indicators } from '@carbonmaps/ui/components/saas/charts/ScatterChart';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TradeOffChart from '../../../components/TradeOffChart';
import Unit from '../../../components/units/Unit';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useFindIngredientFamilyTradeoff } from '../../../lib/react-query/features/ingredient/ingredient.hooks';
import { CARBONE_INDICATOR, WATER_INDICATOR } from '../../../utils/constants';

const classes = {
	text: {
		color: siteColors.primary,
		fontWeight: 600,
	},
};

const getIndicators = (theme: Theme, t: any, period: number) => {
	return [
		{
			label: t(WORDING_TERMS.INTENSITY),
			value: 'carbonIntensity',
			unity: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'carbonImpact',
					label: t(WORDING_TERMS.IMPACT, { period }),
					icon: <Cloudy color={theme.palette.primary.main} />,
					unit: <Unit measure="carbonImpact" color={theme.palette.grey[700]} fontWeight={500} />,
					style: classes.text,
					precision: 0,
				},
				{
					key: 'carbonIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Cloud color={theme.palette.primary.main} />,
					unit: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
					style: classes.text,
					precision: 2,
				},
			],
		},
		{
			label: t('water'),
			value: 'waterIntensity',
			unity: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'waterImpact',
					label: t(WORDING_TERMS.IMPACT, { period }),
					icon: <Droplets color={theme.palette.jobs.water.main} />,
					unit: <Unit measure="waterImpact" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: theme.palette.jobs.water.main },
					precision: 0,
				},
				{
					key: 'waterIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Droplet color={theme.palette.jobs.water.main} />,
					unit: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: theme.palette.jobs.water.main },
					precision: 2,
				},
			],
		},
		{
			label: t('volume'),
			value: 'tonnage',
			precision: 0,
			unity: <Unit measure="massTon" color={theme.palette.grey[700]} fontWeight={500} />,
			popoverKeys: [
				{
					key: 'tonnage',
					label: t('volume'),
					icon: <ShoppingCart />,
					unit: <Unit measure="massTon" color={theme.palette.grey[700]} fontWeight={500} />,
					precision: 0,
				},
			],
		},
	];
};

const IngredientTradeOffBlock = () => {
	const theme = useTheme();
	const params = useParams();
	const navigate = useNavigate();
	const [datas, setDatas] = useState<any>({});
	const { indicator, setIndicator } = useApp();

	const [searchQueryParams] = useSearchQueryParams();

	const [SHOW_SKELETON, setSkeleton] = useState<boolean>(true);
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	// fetch data
	const {
		result: { data, isLoading },
	} = useFindIngredientFamilyTradeoff({
		params: {
			ingredientId: params.ingredientId,
			viewMode: indicator,
			groupField: 'uid',
		},
	});

	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBONE_INDICATOR);
	}, [setIndicator]);

	useEffect(() => {
		if (!isLoading) {
			setTimeout(() => {
				setSkeleton(false);
			}, 1000);
		}
	}, [data, isLoading]);

	const indicators = useMemo(() => {
		return getIndicators(theme, t, selectedYear);
	}, [theme, t]);
	const [useValue, setUseValue] = useState<{ x: Indicators; y: Indicators }>({
		x: indicators[2],
		y: indicators[0],
	});

	useEffect(() => {
		if (data?.length) {
			setDatas({
				data: data.map((d: any, index: any) => {
					return {
						...d,
						isChecked: d.isN2ModelizationType,
						y: d[useValue.y.value] || 0,
						x: d[useValue.x.value] || 0,
						name: d.label ?? d.objectId,
						value: d[useValue.x.value],
						[useValue.x.value]: d[useValue.x.value] || 0,
						[useValue.y.value]: d[useValue.y.value] || 0,
						key: d.objectId,
						uid: d.uid,
						color: indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
					};
				}),
			});
		} else {
			setDatas([]);
		}
	}, [data, useValue, indicator]);

	useEffect(() => {
		if (indicator === CARBONE_INDICATOR) {
			setUseValue({
				x: indicators[2],
				y: indicators[0],
			});
		} else {
			setUseValue({
				x: indicators[2],
				y: indicators[1],
			});
		}
	}, [indicator, indicators]);

	return (
		<Box sx={{ padding: 0, marginBottom: pxToRem(40) }}>
			<Box className="flexRow alignCenter" marginBottom={'16px'} sx={{ gap: '8px' }}>
				<Map color={siteColors.grey700} size={20} />
				<Typography variant="h3" color={theme.palette.common.black}>
					{t('family_ingredient_mapping', {
						ingredients: toLowerCase(t('ingredients')),
						ingredient: toLowerCase(t('ingredient')),
						an_ingredient: t('an_ingredient'),
					})}
				</Typography>
			</Box>

			<TradeOffChart
				datas={datas}
				displayLine={''}
				indicators={indicators}
				setUseValue={setUseValue}
				useValue={useValue}
				loading={isLoading}
				skeleton={SHOW_SKELETON}
				enableFullscreen={false}
				toggleImpact={false}
				viewAllLink={
					<BButton
						onClick={() => {
							return navigate(`${FRONT_PATH_NAMES.ingredients}/${params.originalId}/family`);
						}}
						addStyles={{ borderRadius: '8px', padding: ' 8px 12px', marginRight: '5px' }}
						label={t('see-all-ingredient-button', { ingredients: toLowerCase(t('ingredients')) })}
						variant="secondary"
					/>
				}
			/>
		</Box>
	);
};

export default IngredientTradeOffBlock;
