import { useEffect, useState } from 'react';

import { BarChart2 } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetSupplierSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetSupplierSheetTimePeriod';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import _ from 'lodash';
import IndicatorsBlockLight, { indicatorConfigItems } from '../../../../components/synthese/IndicatorsBlockLight';
import Card from '../../../../components/widget/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import SupplierModel from '../../../../models/Supplier.model';
import { useRseQFormTabQueryParam } from '../../../suppliers/tabs/rse/hooks/rse.hooks';
import { useFindOneSupplierRSERadarChartData } from '../../supplier.hooks';

const SupplierIndicatorsBlock = () => {
	const { indicator } = useApp();
	const { t } = useTranslation();
	const params = useParams();
	const [supplierObject, setSupplierObject] = useState<SupplierModel>();

	const { data, isLoading, isError, error } = useGetSupplierSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.supplierId!,
		// period: 2023,
	});

	useEffect(() => {
		if (!data) return;
		const { supplier } = data;
		setSupplierObject(new SupplierModel(supplier));
	}, [data]);

	const [qFormId, setQFormId] = useRseQFormTabQueryParam();
	const { data: dataPerformanceRse, isLoading: isLoadingDataPerformanceRse } = useFindOneSupplierRSERadarChartData({
		supplierId: params.supplierId || '',
		questionFormId: _.toString(qFormId!),
	});


	return (
		<Card
			title={
				indicatorConfigItems.get(indicator)?.indicatorTitle
					? t(indicatorConfigItems.get(indicator)?.indicatorTitle as any)
					: t('Indicateurs carbone')
			}
			icon={<BarChart2 color={siteColors.grey700} size={20} />}
			sx={{ padding: 0, marginBottom: pxToRem(40), flexWrap: 'nowrap', width: '100%' }}
			withBorder={false}
			center={false}
		>
			<IndicatorsBlockLight
				withPopover={false}
				indicator={indicator}
				indicatorDatas={{
					impact: {
						// value: 2340,
						value: supplierObject?.getImpactByIndicator(indicator),
					},
					intensity: {
						value: supplierObject?.getIntensityByIndicator(indicator),
					},
					scoring: {
						//	value: supplierObject?.getScoreText(),
						value: dataPerformanceRse?.note ?? 'default',
						withLabel: true,
					},
				}}
			/>
		</Card>
	);
};

export default SupplierIndicatorsBlock;
