import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import fr from 'dayjs/locale/fr';
import { ArrowDownSquare, Calendar, Database, Pencil, UserRound } from 'lucide-react';

import { useTranslation } from '../../hooks/useTranslation';

type MetaDataCardProps = {
	importedOn?: string | Date;
	updatedAt?: string | Date;
	bdd?: string;
	createdBy?: string | null;
	createdAt?: string | Date;
};

const styles = {
	mb2: css({
		marginBottom: 2,
	}),
	mb40: css({
		marginBottom: 40,
	}),
};

const MetaDataCard = ({ importedOn, createdAt, updatedAt, bdd, createdBy }: MetaDataCardProps) => {
	const theme = useTheme();
	const { t, i18n } = useTranslation();
	return (
		<div className={styles.mb40}>
			{createdBy && createdBy !== ' ' && (
				<div className={cx('flexRow nowrap alignCenter  width100', styles.mb2)}>
					<UserRound size={16} color={theme.palette.grey[900]} />
					<Typography color={theme.palette.grey[900]} fontSize="14px" ml="8px" sx={{ flex: 1 }}>
						{t('meta-data-created-by')} {createdBy}
					</Typography>
				</div>
			)}

			{createdAt && (
				<div className={cx('flexRow nowrap alignCenter  width100', styles.mb2)}>
					<Calendar size={16} color={theme.palette.grey[900]} />
					<Typography color={theme.palette.grey[900]} fontSize="14px" ml="8px" sx={{ flex: 1 }}>
						{t('meta-data-created-at')} {dayjs(createdAt).locale(i18n?.language).format('D MMMM YYYY')}
					</Typography>
				</div>
			)}

			{updatedAt && (
				<div className={cx('flexRow nowrap alignCenter  width100', styles.mb2)}>
					<Pencil size={16} color={theme.palette.grey[900]} />
					<Typography color={theme.palette.grey[900]} fontSize="14px" ml="8px" sx={{ flex: 1 }}>
						{t('meta-data-update-date')} {dayjs(updatedAt).locale(i18n?.language).format('D MMMM YYYY')}
					</Typography>
				</div>
			)}
			{importedOn && (
				<div className="flexRow nowrap alignCenter">
					<ArrowDownSquare size={16} color={theme.palette.grey[900]} />
					<Typography color={theme.palette.grey[900]} fontSize="14px" ml="8px" sx={{ flex: 1 }}>
						{t('meta-data-import-date')} {dayjs(importedOn).locale(i18n?.language).format('D MMMM YYYY')}{' '}
					</Typography>
				</div>
			)}
			{bdd && (
				<div className="flexRow nowrap alignCenter">
					<Database size={16} color={theme.palette.grey[900]} />
					<Typography color={theme.palette.grey[900]} fontSize="14px" ml="8px" sx={{ flex: 1 }}>
						{t('BDD de référence')} : {bdd}
					</Typography>
				</div>
			)}
		</div>
	);
};

export default MetaDataCard;
