import { useQuery } from '@tanstack/react-query';


import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';
import { getReportClientInfoAction } from '../actions/sales.actions';



export const useReportClientInfo = ({
	objectId,
	// period,
	fromPath,
	onError,
	onSuccess,
	viewMode,
	sortingBy,
}: {
	objectId: string;
	viewMode?: string;
	// period?: string | number;
	sortingBy?: any;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getReportClientInfo', {
			objectId,
			period: selectedYear,
			// sortingBy,
			// viewMode
		}],
		queryFn: getReportClientInfoAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};
