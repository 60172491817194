import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Globe } from 'lucide-react';

import { getCompanySheetFacetAction } from '@carbonmaps/ui/actions/company.actions';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';

const TranslationsHeader = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';

	// ---- fetch company info ---- //
	const { data } = useQuery({
		queryKey: [
			'getCompanySheetFacet',
			{
				companyId,
			} as any,
		],
		queryFn: getCompanySheetFacetAction,
	});

	return (
		<ListHeader
			title={t('Translations')}
			subtitle={data?.name}
			icon={
				<IconContainer
					element={<Globe size={40} color={theme.palette.common.white} />}
					color={theme.palette.grey[900]}
				/>
			}
			className="bgGrey500"
		/>
	);
};

export default TranslationsHeader;
