import { css, cx } from '@emotion/css';
import { Weight } from 'lucide-react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';

import Unit from '../../../components/units/Unit';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useTonnage } from '../packagings.hooks';

import KPICard from './KPICard';
import KpiValue from './KpiValue';

type TonnageKpiProps = {
	tonnage?: number;
	isLoading: boolean;
	withHelp?: boolean;
};

const styles = {
	text: css({
		fontSize: '24px !important',
		lineHeight: '1.2 !important',
		color: `${siteColors.grey8} !important`,
	}),
	impactUnit: css({ paddingLeft: 2 }),
};

const TonnageKpi = ({ tonnage, isLoading, withHelp = false }: TonnageKpiProps) => {
	// ---- fetch data ---- //
	const { selectedYear } = useYearSelection();
	const { t } = useTranslation();
	const { indicator } = useApp();
	return (
		<KPICard
			label={t('Tonnes_period', { period: selectedYear })}
			value={<div className='flexRow nowrap'><KpiValue value={tonnage} withHelp={withHelp}
				unit={
					<Unit
						measure={'massTon'}
						color={siteColors.grey7}
						className={styles.impactUnit}
					/>
				}

			/>

			</div>}
			className="flex1"
			icon={<Weight size={30} color={siteColors.grey7} />}
			isLoading={isLoading}


		/>
	);
};

export default TonnageKpi;
