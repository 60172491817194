import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, Eye, FilePen, FilePlus, MoreHorizontal } from 'lucide-react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES, roleLabels } from '@carbonmaps/shared/utils/constants';
import { MOD_ECO_CONCEPTION } from '@carbonmaps/shared/utils/module';
import { SimulationFormInput } from '@carbonmaps/shared/validations/simulation.validations';
import { getScenarioData } from '@carbonmaps/ui/actions/dashboard.actions';
import { createSimulationAction } from '@carbonmaps/ui/actions/simulation.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import _ from 'lodash';
import CardContent from '../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../components/dashboard/v2/CardTitle';
import DashboardButton from '../../../../components/dashboard/v2/DashboardButton';
import DashboardCard from '../../../../components/dashboard/v2/DashboardCard';
import DashboardTable from '../../../../components/dashboard/v2/DashboardTable';
import DashboardTooltip from '../../../../components/dashboard/v2/DashboardTooltip';
import Icon from '../../../../components/dashboard/v2/Icon';
import MoreButton from '../../../../components/dashboard/v2/MoreButton';
import TagLabel from '../../../../components/dashboard/v2/TagLabel';
import VsGammeField from '../../../../components/dashboard/v2/VsGammeField';
import CreateScenarioModal from '../../../../components/simulation/CreateScenarioModal';
import useHasRoles from '../../../../hooks/useHasRoles';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import Lock from '../../../../layouts/_common/Lock';
import { siteColors } from '../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import LockPopover from '../../LockPopover';

const styles = {
	content: css({
		minHeight: 396,
	}),
};

const Scenario = () => {
	const [searchQueryParams] = useSearchQueryParams();
	const [isModuleActive, setIsModuleActive] = useState<any>(true);
	const [openModal, setOpenModal] = useState(false);
	const [selectedOption, setSelectedOption] = useState({}) as any;
	const [message, setMessage] = useState('');
	const [options, setOptions] = useState([]);
	const [search, setSearch] = useState<any>();
	const { indicator } = useApp();

	const hasRoles = useHasRoles();
	const { t } = useTranslation();
	const navigate = useNavigate();
	// ---- fetch products eco design ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getScenarioData',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				viewMode: indicator,
			},
		],
		queryFn: getScenarioData,
	});

	const {
		mutate: createSimulation,
		isError,
		isSuccess,
		isLoading: isLoadingCreateSimulation,
	} = useMutation({
		mutationKey: ['createSimulation'],
		mutationFn: createSimulationAction,
		onSuccess: async (value: any) => {
			// setMessage('Informations mises à jour');
			// queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			setOpenModal(false);
			navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<SimulationFormInput> = async (values) => {
		createSimulation({
			...values,
			productId: selectedOption?._id,
			selectedSimulationId: null,
		} as any);
	};

	const columns = useMemo(() => {
		return [
			{
				header: t('Les plus émissifs au kilo'),
				component: TagLabel,
			},
			{
				header: (
					<DashboardTooltip
						label={t('vs-reference-original', {
							product: toLowerCase(t('product')),
						})}
						content={'original-versus-gamme'}
					/>
				),
				component: VsGammeField,
			},
			{
				header: '',
				component: ({ item }: any) => {
					const tooltipOptions = [
						{
							title: t('Voir le scénario'),
							value: '',
							icon: Eye,
							customComponent: true,
							onClick: () => {
								if (!hasRoles([roleLabels.EDITOR, roleLabels.ADMIN])) return;

								if (isModuleActive) {
									navigate(`${FRONT_PATH_NAMES.simulation}/${item.simulationId}`);
								}
							},
						},
						...(isModuleActive
							? [
									{
										title: t('Nouveau scénario d’éco-conception'),
										value: '',
										icon: FilePlus,
										customComponent: true,
										onClick: () => {
											setOpenModal(true);
											setSelectedOption(item);
										},
									},
							  ]
							: []),
					];
					return <MoreButton options={tooltipOptions} />;
				},
			},
		];
	}, [t]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	useEffect(() => {
		setIsModuleActive(
			(_.get(authData, 'session.company.plugins', []) as any[]).some((e) => {
				return e.code === MOD_ECO_CONCEPTION;
			}),
		);
	}, [authData?.session.company]);

	return (
		<DashboardCard
			title={
				<CardTitle
					title={t('Derniers scénarios')}
					subtitle={`${data?.metaData[0]?.count || ' '} ${t('scénarios')}`}
					icon={<Icon element={FilePen} color={siteColors.teal500} background="#E2F1F3" />}
					isLock={!isModuleActive}
				/>
			}
			className="flex1"
		>
			<CardContent
				className={cx('flex1', styles.content)}
				button={
					<DashboardButton
						label={t('Tous les scénarios')}
						path={FRONT_PATH_NAMES.simulation}
						eventName="Dashboard All scenarios"
					/>
				}
				loading={isLoading}
				withButton={isModuleActive}
			>
				{isModuleActive ? (
					<DashboardTable columns={columns} data={data?.data || []} emptyText={t('simulation-table-empty-data')} />
				) : null}
				<CreateScenarioModal
					title={t('create-scenario-form-title')}
					open={openModal}
					onClose={() => {
						return setOpenModal(false);
					}}
					isLoading={isLoadingCreateSimulation}
					onSubmit={onSubmit}
					options={options}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					message={message}
					isError={isError}
					setSearch={setSearch}
					isSuccess={isSuccess}
				/>
			</CardContent>
		</DashboardCard>
	);
};

export default Scenario;
