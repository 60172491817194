import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SupplierModel from '../../models/Supplier.model';
import BlueCheck from '../BlueCheck';

type SupplierLineDisplayProps = {
	supplier: SupplierModel;
};

const SupplierLineDisplay = ({ supplier }: SupplierLineDisplayProps) => {
	return (
		<Box display="flex" sx={{ gap: '16px' }} className="alignCenter">
			<Box display="flex" sx={{ gap: '8px' }} className="alignCenter">
				<BlueCheck withIcon ok={supplier.isBlueCheck()} />
				<Typography>{supplier.getName()}</Typography>
			</Box>
			{/* <ScoringCard
				value={supplier.getScoreText()}
				fontSize={12}
				sx={{ width: 36, height: 26, justifySelf: 'flex-end' }}
			/> */}
		</Box>
	);
};

export default SupplierLineDisplay;
