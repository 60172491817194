import { useMemo, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { classNames } from '@carbonmaps/shared/utils/constants';

import { useTranslation } from '../../../../../../hooks/useTranslation';
import ClientForm from '../../../ClientForm';

const ClientInfos = () => {
	const params = useParams();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const [message, setMessage] = useState<string>();

	const clientId = params.clientId!;

	const { data: clientData, refetch } = useQuery({
		queryKey: ['getClientSheetById', { clientId }] as const,
		queryFn: async (ctx) => {
			const {
				queryKey: {
					1: { clientId },
				},
			} = ctx;

			const client = await new Parse.Query(classNames.CLIENT).equalTo('objectId', clientId).first();

			console.log('client', client);

			return client?.toJSON();
		},
		// enabled: false,
	});

	const {
		mutateAsync: updateClient,
		isLoading,
		isSuccess,
		isError,
	} = useMutation({
		mutationKey: ['updateClient', { clientId }] as const,
		mutationFn: async (payload: any) => {
			try {
				const client = await new Parse.Query(classNames.CLIENT).get(clientId);

				const finalPayload = _.omitBy(payload, _.isNil);
				await client.save(finalPayload);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			setMessage(t('Informations enregistrées'));
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });
		},
		onError(error, variables, context) {
			let msg = 'Unknown error';

			if (error instanceof Error) {
				msg = error.message;
			}

			setMessage(msg);
		},
	});

	return (
		<>
			<ClientForm
				withCancel={false}
				isAsync
				getDefaultValuesAsync={
					async (/* payload */) => {
						const { data } = await refetch();
						return data;
					}
				}
				buttonTitle={t('button-save-company')}
				onSubmit={async (payload: any) => {
					updateClient(payload);
				}}
				isLoading={isLoading}
				isSuccess={isSuccess}
				isError={isError}
				message={message}
			/>
		</>
	);
};

export default ClientInfos;
