import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../components/layout/list/Container';
import { useTranslation } from '../../hooks/useTranslation';
import { BREADCRUMBS_MAP } from '../../utils/audit.utils';
import AuditHeader from '../audit/AuditHeader';

import AuditFilter from './AuditFilter';
import AuditTable from './AuditTable';
import HeaderPage from './HeaderPage';

const styles = {
	container: css({
		paddingTop: 27,
		gap: 8,

		'& .filterContainer-wrapper': {
			paddingLeft: 0,
			paddingRight: 0,
		},
	}),
};

const AuditSheet = () => {
	const { setBreadcrumbs } = useApp();
	const params = useParams();

	// translation
	const { t } = useTranslation();

	// ---- set breadcrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([
			// { text: 'Auditer' },
			{ text: t('audit-breadcrumbs'), link: FRONT_PATH_NAMES.audit },
			{ text: t(BREADCRUMBS_MAP.get(params.itemType), { ingredient: t('ingredient'), ingredients: t('ingredients') }) },
		]);
	}, [params.itemType, setBreadcrumbs, t]);

	return (
		<Container
			header={
				<AuditHeader
					subtitle={t(BREADCRUMBS_MAP.get(params.itemType), {
						ingredient: t('ingredient'),
						ingredients: t('ingredients'),
						an_ingredient: t('an_ingredient'),
						product: t('product'),
						of_product: t('of_product'),
						products: t('products'),
					})}
				/>
			}
			seoText={`${t('audit-breadcrumbs')} - Carbon Maps`}
		>
			<div className={cx('flexColumn width100 stretch', styles.container)}>
				<HeaderPage />
				<AuditFilter />
				<AuditTable />
			</div>
		</Container>
	);
};

export default AuditSheet;
