import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import { pxToRem } from '@carbonmaps/ui/utils/styles';

type AnalysisDonutContentProps = {
	icon: ReactNode;
	value: number | string;
	label: string;
};

const AnalysisDonutContent = ({ icon, value, label }: AnalysisDonutContentProps) => {
	const theme = useTheme();

	return (
		<Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
			<Box>
				<Box display="flex" alignItems="center" marginBottom={pxToRem(4)}>
					{icon}
					<Typography variant="h1" marginLeft={pxToRem(6)} fontSize={'28px'}>
						{value}
					</Typography>
				</Box>
				<Typography color={theme.palette.grey[700]} textAlign="center" fontWeight={500}>
					{label}
				</Typography>
			</Box>
		</Box>
	);
};

export default AnalysisDonutContent;
