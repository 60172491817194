import { css, cx } from '@emotion/css';

import Product from './Product';
import SbtiFlag from './SbtiFlag';
import Supplier from './Supplier';

// const styles = {
// 	container: css({ minHeight: 650 }),
// };

const styles = {
	container: css({
		maxWidth: '100%',
		overflow: 'auto',
		paddingBottom: 20,
	}),
	emptyCard: css({
		flex: '1 1 50%',
	}),

	gridContainer: css({
		maxWidth: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridColumnGap: 32,
		gridRowGap: 32,
		paddingBottom: 20,
	}),
};

const ImpactTab = () => {
	return (
		<div className={cx(styles.gridContainer, 'width100')}>
			<Product />
			<Supplier />
			<SbtiFlag />
		</div>
	);
};

export default ImpactTab;
