import { css, cx } from '@emotion/css';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Typography from '../../Typography';
import Unit from '../../units/Unit';
import Card from '../../widget/Card';
import Candlestick from '../Candlestick';
import Comment from '../Comment';

import { cn, siteColors } from '../../../lib/colors';
import ApboCard from './ApboCard';

const styles = {
	globalValue: css({
		fontSize: '24px !important',
		color: cn(siteColors.primary, '#!important'),
	}),
};

type GraphIndicatorCardProps = {
	title: string;
	messageKey: string;
	labelImpact: string;
	indicator: {
		impact: number;
		min: number;
		q1: number;
		q3: number;
		mean: number;
		max: number;
		median: number;
	};
	icon: any;
};

const GraphIndicatorCard = ({ title, indicator, messageKey, labelImpact, icon }: GraphIndicatorCardProps) => {
	const Icon = icon;
	return (
		<ApboCard messageKey={messageKey}>
			<Card
				title={title}
				icon={<Icon />}
				center={false}
				sx={{
					padding: '24px !important',
					minHeight: '262px !important',
					width: '100%',
					gap: 0,
				}}
				rightContent={<Comment messageKey={messageKey} />}
			>
				<div className="flexColumn width100 gap24">
					<div className="flexRow  gap8">
						<Icon size={30} color={siteColors.primary} />
						<div className="flexRow  alignCenter gap16">
							<div className="flexColumn gap8">
								<Typography className={cx(styles.globalValue, 'fontWeight500 lineHeight1')}>
									{formatNumber(indicator.impact, undefined, 0)} <Unit measure="carbonImpactTon" className="grey700" />
								</Typography>
								<Typography>{labelImpact}</Typography>
							</div>
							<div className="flexColumn gap8">
								<Typography className={cx(styles.globalValue, 'fontWeight500 lineHeight1')}>
									{formatNumber(indicator.mean, undefined, 2)} <Unit measure="gesFPCM" className="grey700" />
								</Typography>
								<Typography>Moyenne pondérée</Typography>
							</div>
						</div>
					</div>
					<Candlestick data={indicator} />
				</div>
			</Card>
		</ApboCard>
	);
};

export default GraphIndicatorCard;
