import { css, cx } from '@emotion/css';

type IconProps = {
	element: any;
	color: string;
	background: string;
	className?: string;
	iconSize?: number;
	size?: number;
	borderColor?: string;
};

const styles = {
	container: css({
		width: 'var(--width)',
		height: 'var(--height)',
		background: 'var(--background)',
		borderColor: 'var(--borderColor)',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderRadius: 8,
	}),
};

const Icon = ({
	element,
	color,
	background,
	className,
	iconSize = 20,
	size = 36,
	borderColor = 'transparent',
}: IconProps) => {
	const IconComponent = element;
	return (
		<div
			className={cx('flexCenter', className, styles.container)}
			style={{
				['--background' as any]: background,
				['--width' as any]: `${size}px`,
				['--height' as any]: `${size}px`,
				['--borderColor' as any]: borderColor,
			}}
		>
			<IconComponent color={color} size={iconSize} />
		</div>
	);
};

export default Icon;
