import React from 'react';

import { css, cx } from '@emotion/css';
import MUILink from '@mui/material/Link';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { cn, siteColors } from '../../lib/colors';
import Typography from '../Typography';

type LegendProps = {
	color?: string;
	label: string;
	value?: any;
	path?: string;
	icon?: React.ReactNode;
	display?: 'column' | 'inline';
	unit?: string;
	precision?: number;
	withValue?: boolean;
	className?: string;
};

const styles = {
	container: css({
		cursor: 'pointer',
		textDecoration: 'none !important',
		fontSize: '14px !important',
	}),
	circle: css({
		width: 8,
		height: 8,
		borderRadius: '50%',
		background: 'var(--backgroundColor)',
		marginTop: 4,
	}),
	label: css({
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: 101,
		textWrap: 'nowrap',
		lineHeight: '1 !important',
		color: cn(siteColors.grey900, '!important'),
	}),
	inlineLabel: css({
		maxWidth: '100%',
	}),
	value: css({
		color: cn(siteColors.grey800, '!important'),
		lineHeight: '1 !important',
	}),
} as any;

const Legend = ({
	color,
	label,
	path,
	value = 0,
	icon,
	display = 'column',
	unit = '%',
	precision = 1,
	withValue = true,
	className,
}: LegendProps) => {
	const ComponentLink = path ? MUILink : 'div';
	return (
		<ComponentLink
			component={RouterLink}
			to={path as any}
			className={cx('flexRow gap10 nowrap', styles.container, className)}
		>
			{icon || <div className={styles.circle} style={{ ['--backgroundColor' as any]: color }} />}

			<div className={cx(display === 'column' ? 'flexColumn' : 'flexRow', ' flex1 gap6')}>
				<Typography className={cx(styles.label, styles[`${display}Label`])}>{label}</Typography>
				{withValue && (
					<Typography variant="caption" className={cx(styles.value, 'number')}>
						{_.isString(value) || React.isValidElement(value)
							? value
							: `${formatNumber(value, undefined, precision)} ${unit}`}
					</Typography>
				)}
			</div>
		</ComponentLink>
	);
};

export default Legend;
