import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Unit from '../components/units/Unit';
import { useTranslation } from '../hooks/useTranslation';

import { siteColors } from '../lib/colors';
import { WATER_INDICATOR } from './constants';
import { FULFILLED_MODELIZATION_TYPE, PARTIAL_DATA_MODELIZATION_TYPE } from './supplier';

const keyIndicatorMap = new Map();

export const GesUnit = <Unit measure="carbonIntensityKg" color={siteColors.text} lineHeight={1} />;

export const WaterUnit = <Unit measure="waterImpact" color={siteColors.text} lineHeight={1} />;

keyIndicatorMap.set(CARBON_INDICATOR, {
	impact: 'carbonImpact',
	ges: 'carbonIntensity',
});

keyIndicatorMap.set(WATER_INDICATOR, {
	impact: 'waterImpact',
	ges: 'waterIntensity',
});

const getConfigItems = (t: any, period: number) => {
	const popoverIndicatorConfigItems = new Map();
	popoverIndicatorConfigItems.set(CARBON_INDICATOR, {
		value: 'carbonImpact',
		label: t('carbon'),
		unit: (
			<Unit
				measure="carbonImpactTon"
				fontWeight={500}
				fontSize="12px"
				color={siteColors.grey700}
				lineHeight={1}
				// sx={{ textTransform: 'uppercase' }} //don't need uppercase for unity
			/>
		),
		popover: [
			{
				label: t(WORDING_TERMS.IMPACT, { period }),
				key: 'carbonImpact',
				unit: (
					<Unit
						measure="carbonImpactTon"
						fontWeight={600}
						lineHeight={'16.8px'}
						fontSize="14px"
						color={siteColors.text}
						//	sx={{ textTransform: 'capitalize' }} //don't need uppercase for unity
					/>
				),
				precision: 0,
			},
			{
				label: 'Volume',
				key: 'tonnage',
				unit: (
					<Unit
						measure="massTon"
						fontWeight={600}
						lineHeight={'16.8px'}
						fontSize="14px"
						color={siteColors.text}
						//	sx={{ textTransform: 'capitalize' }} //don't need uppercase for unity
					/>
				),
				precision: 0,
			},
		],
	});

	popoverIndicatorConfigItems.set(WATER_INDICATOR, {
		value: 'waterImpact',
		label: t('water'),
		unit: (
			<Unit
				measure="waterImpact"
				fontWeight={500}
				fontSize="12px"
				color={siteColors.grey700}
				lineHeight={1}
				//sx={{ textTransform: 'uppercase' }} //don't need uppercase for unity
			/>
		),
		popover: [
			{
				label: t(WORDING_TERMS.IMPACT, { period }),
				key: 'waterImpact',
				unit: (
					<Unit
						measure="waterImpact"
						fontWeight={600}
						lineHeight={'16.8px'}
						fontSize="14px"
						color={siteColors.text}
						//	sx={{ textTransform: 'capitalize' }} //don't need uppercase for unity
					/>
				),
				precision: 2,
			},
			{
				label: t('Volume'),
				key: 'tonnage',
				unit: (
					<Unit
						measure="massTon"
						fontWeight={600}
						lineHeight={'16.8px'}
						fontSize="14px"
						color={siteColors.text}
						//	sx={{ textTransform: 'capitalize' }} //don't need uppercase for unity
					/>
				),
				precision: 0,
			},
		],
	});
	return popoverIndicatorConfigItems;
};

const getUvcIndicator = (t: any) => {
	const uvcIndicator = {
		label: t('soldItems'),
		key: 'volume',
		unit: (
			<Unit
				measure="uvc"
				fontWeight={600}
				lineHeight={'16.8px'}
				fontSize="14px"
				color={siteColors.text}
				//	sx={{ textTransform: 'capitalize' }} //don't need uppercase for unity
			/>
		),
		precision: 0,
	};

	return uvcIndicator;
};

export const getPopoverBySwitchIndicator = (
	indicator: 'water' | 'carbon' = CARBON_INDICATOR,
	useUvc = false,
	t = (key: any) => {
		return key;
	},
	period = 2024,
) => {
	const popoverIndicatorConfigItems = getConfigItems(t, period);
	const popoverIndicator = popoverIndicatorConfigItems.get(indicator);

	// when we want display soldItems in popover instead tonnage product
	if (useUvc) {
		const mainIndicator = popoverIndicator.popover.filter((p: any) => {
			return p.key !== 'tonnage';
		});

		return {
			...popoverIndicator,
			popover: [...mainIndicator, getUvcIndicator(t)],
		};
	}

	return popoverIndicator;
};

export const getDataKeysByIndicator = (indicator: 'carbon' | 'water' = CARBON_INDICATOR) => {
	return keyIndicatorMap.get(indicator);
};

export const getSuppliersFullFilledPercent = (suppliers: any, indicator: 'water' | 'carbon' = 'carbon') => {
	let impactTotal = 0;
	let impactSupplierTotal = 0;
	const impactKeyField = indicator === 'carbon' ? 'carbonImpact' : 'waterImpact';

	suppliers.forEach((e: any) => {
		impactTotal = impactTotal + e[impactKeyField];

		if (e.onboarding === FULFILLED_MODELIZATION_TYPE || e.onboarding === PARTIAL_DATA_MODELIZATION_TYPE) {
			impactSupplierTotal = impactSupplierTotal + e[impactKeyField];
		}
	});
	const percent = (impactSupplierTotal * 100) / impactTotal;
	return `${formatNumber(percent, undefined, 0)}%`;
};

export const getCountSuppliersFullFilled = (suppliers: any) => {
	const suppliersFullFilled = suppliers.filter((e: any) => {
		return e.onboarding === FULFILLED_MODELIZATION_TYPE || e.onboarding === PARTIAL_DATA_MODELIZATION_TYPE;
	});

	return suppliersFullFilled.length;
};

export const getSuppliersPercent = (suppliers: any) => {
	const countFullFilled = getCountSuppliersFullFilled(suppliers);

	return `${formatNumber((countFullFilled * 100) / suppliers.length, undefined, 0)}%`;
};
