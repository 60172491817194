import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { ClientFormInput, ClientFormValidationSchema } from '@carbonmaps/shared/validations/client.validations';
//--------------------------------------------------------------------------------------//
//                             data autocomplete                                       //
//--------------------------------------------------------------------------------------//


type GetQuoteOptionsAutocompleteClientQueryParams = {
	input: string;
};

export const getQuoteOptionsAutocompleteClientAction = async (
	context: QueryFunctionContext<readonly ['getQuoteOptionsAutocompleteClient', GetQuoteOptionsAutocompleteClientQueryParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { input },
			},
		} = context;

		const { data } = await Parse.Cloud.run('findClient', {
			input,
			limit: 100,
			skip: 0,
			isOptionAutocomplete: true,
		});

		return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                                      Mutations create client                          //
//--------------------------------------------------------------------------------------//
type createClientInput = ClientFormInput
// {
// 	name: string;
// 	clientId: string;
// };

export const createClientAction = async (input: createClientInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run(functionName.createClient, input);
		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};
