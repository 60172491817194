import _, { forEach } from 'lodash';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { FULFILLED_MODELIZATION_TYPE, PARTIAL_DATA_MODELIZATION_TYPE } from '../utils/supplier';
export default class IngredientModel {
	ingredient: any;

	constructor(ingredient: any) {
		this.ingredient = ingredient || {};
	}

	isN2ModelizationType() {
		return this.ingredient?.tagAdvanced === 'yes';
	}

	getLabel() {
		return this.ingredient?.label;
	}

	getCarboneIntensity() {
		if (this.isN2ModelizationType()) {
			return (
				(this.ingredient?.gesWeightedAgricultureN2 ?? 0) + (this.ingredient?.gesWeightedTransformationDownstreamN2 ?? 0)
			);
		}

		return (
			(this.ingredient?.gesWeightedAgricultureN1 ?? 0) + (this.ingredient?.gesWeightedTransformationDownstream ?? 0)
		);
	}

	getCarboneWeightedIntensity() {
		if (this.isN2ModelizationType()) {
			return (
				(this.ingredient?.gesWeightedAgricultureN2 ?? (this.ingredient?.gesWeightedAgriculture || 0)) +
				(this.ingredient?.gesWeightedTransformationUpstreamN2 ||
					this.ingredient?.gesWeightedUpstreamTransformationN2 ||
					this.ingredient?.gesWeightedTransformationUpstream ||
					0)
			);
		}

		return (
			(this.ingredient?.gesWeightedAgricultureN1 ?? (this.ingredient?.gesWeightedAgriculture || 0)) +
			(this.ingredient?.gesWeightedTransformationUpstreamN1 ||
				this.ingredient?.gesWeightedUpstreamTransformationN1 ||
				this.ingredient?.gesWeightedTransformationUpstream ||
				0)
		);
	}

	getWaterWeightedIntensity() {
		if (this.isN2ModelizationType()) {
			return (
				(this.ingredient?.waterUseWeightedTransformationUpstreamN2 ??
					(this.ingredient?.waterUseWeightedAgriculture || 0)) +
				(this.ingredient?.waterUseWeightedTransformationUpstreamN2 ||
					this.ingredient?.waterUseWeightedUpstreamTransformationN2 ||
					this.ingredient?.waterUseWeightedTransformationUpstream ||
					0)
			);
		}

		return (
			(this.ingredient?.waterUseWeightedAgricultureN1 ?? (this.ingredient?.waterUseWeightedAgriculture || 0)) +
			(this.ingredient?.waterUseWeightedTransformationUpstreamN1 ||
				this.ingredient?.waterUseWeightedUpstreamTransformationN1 ||
				this.ingredient?.waterUseWeightedTransformationUpstream ||
				0)
		);
	}

	getWaterIntensity() {
		if (this.isN2ModelizationType()) {
			return (
				(this.ingredient?.waterUseWeightedAgricultureN2 ?? 0) +
				(this.ingredient?.waterUseWeightedTransformationUpstreamN2 ?? 0)
			);
		}

		return (
			(this.ingredient?.waterUseWeightedAgriculture ?? 0) +
			(this.ingredient?.waterUseWeightedTransformationUpstream ?? 0)
		);
	}

	getIntensity(field = 'gesWeightedAgriculture') {
		return this?.ingredient[`${field}`];
	}

	getIntensityN2(field = 'gesWeightedAgriculture') {
		return this.isN2ModelizationType()
			? this.ingredient[`${field}N2`] || this.ingredient[`${field}`]
			: this.ingredient[`${field}N1`] || this.ingredient[`${field}`];
	}

	getCarboneImpact(returnAsNumber = false): string | number | undefined {
		// if (!this.ingredient.carbonImpact || this.ingredient.carbonImpact < 0) return this.ingredient.carbonImpact;
		if (returnAsNumber) {
			return this.ingredient.carbonImpact;
		}

		return formatNumber(this.ingredient.carbonImpact, undefined, 0);
	}

	getWaterImpact(returnAsNumber = false): string | number | undefined {
		// if (!this.ingredient.waterImpact || this.ingredient.waterImpact < 0) return this.ingredient.waterImpact;
		if (returnAsNumber) {
			return this.ingredient.waterImpact;
		}

		return formatNumber(this.ingredient.waterImpact, undefined, 0);
	}

	getImpactByIndicator(indicator: 'carbon' | 'water', returnAsNumber = false): string | number | undefined {
		if (indicator === 'carbon') {
			return this.getCarboneImpact(returnAsNumber);
		}

		return this.getWaterImpact(returnAsNumber);
	}

	getIntensityByIndicator(indicator: 'carbon' | 'water', returnAsNumber = false): string | number | undefined {
		let intensity;

		if (indicator === 'carbon') {
			intensity = parseFloat(this.ingredient.carbonIntensity);

			if (!returnAsNumber) {
				intensity = formatNumber(intensity, undefined, 2);
			}
		} else if (indicator === 'water') {
			intensity = parseFloat(this.ingredient.waterIntensity);

			if (!returnAsNumber) {
				intensity = formatNumber(intensity, undefined, 2);
			}
		}

		return intensity;
	}

	getVolume(unity = 1) {
		// default T
		return this.ingredient.volume * unity;
	}

	getSuppliersCount(onboarding = 0) {
		if (onboarding === FULFILLED_MODELIZATION_TYPE) {
			const suppliersFullfilled = this.ingredient.suppliers.filter((e: any) => {
				return e.onboarding === FULFILLED_MODELIZATION_TYPE || e.onboarding === PARTIAL_DATA_MODELIZATION_TYPE;
			});
			return suppliersFullfilled.length;
		}

		return this.ingredient.suppliers.length;
	}

	getSupplierImpactFullFilledPercent(indicator: 'water' | 'carbon' = 'carbon') {
		let impactTotal = 0;
		let impactSupplierTotal = 0;
		const impactKeyField = indicator === 'carbon' ? 'carbonImpact' : 'waterImpact';

		this.ingredient.suppliers.forEach((e: any) => {
			impactTotal = impactTotal + e[impactKeyField];

			if (e.onboarding === FULFILLED_MODELIZATION_TYPE || e.onboarding === PARTIAL_DATA_MODELIZATION_TYPE) {
				impactSupplierTotal = impactSupplierTotal + e[impactKeyField];
			}
		});
		const percent = (impactSupplierTotal * 100) / impactTotal;
		return `${formatNumber(percent, undefined, 0)}%`;
	}

	getSupplierFullFilledPercent() {
		const totalSupplier = this.ingredient.suppliers?.length;
		let fullFilledCount = 0;
		this.ingredient.suppliers.forEach((e: any) => {
			if (e.onboarding === FULFILLED_MODELIZATION_TYPE || e.onboarding === PARTIAL_DATA_MODELIZATION_TYPE) {
				fullFilledCount = fullFilledCount + 1;
			}
		});
		const percent = (fullFilledCount * 100) / totalSupplier;
		return `${formatNumber(percent, undefined, 0)}%`;
	}

	getRowValueExport(prefix = 'ges', key: string, isWeighted = false, isTransformation = false, type = 'N1') {
		let field = prefix + key;
		let field2,
			field3,
			field4 = field;

		if (isWeighted === true && isTransformation === true) {
			field = `${prefix}WeightedTransformationUpstream`;
			field2 = `${prefix}TransformationUpstreamWeighted`;
			field3 = `${prefix}UpstreamTransformationWeighted`;
			field4 = `${prefix}WeightedUpstreamTransformation`;
		}

		if (isWeighted === true && isTransformation === false) {
			field = `${prefix}Weighted${key}`;
			field2 = `${prefix + key}Weighted`;
			field3 = field;
			field4 = field2;
		}

		if (isTransformation === true) {
			field2 = `${prefix}UpstreamTransformation`;
		}

		if (type === 'N1') {
			// return (
			// 	((this.ingredient?.[`${field}N2`] && this.ingredient?.[`${field}N2`] === this.ingredient?.[`${field}N1`]) ||
			// 	this.ingredient?.[`${field}N2`] === this.ingredient?.[`${field2}`]
			// 		? ''
			// 		: this.ingredient?.[`${field}N1`]) ||
			// 	(this.ingredient?.[`${field2}N2`] && this.ingredient?.[`${field2}N2`] === this.ingredient?.[`${field2}N1`]
			// 		? ''
			// 		: this.ingredient?.[`${field2}N1`]) ||
			// 	(this.ingredient?.[`${field3}N2`] && this.ingredient?.[`${field3}N2`] === this.ingredient?.[`${field3}N1`]
			// 		? ''
			// 		: this.ingredient?.[`${field3}N1`]) ||
			// 	(this.ingredient?.[`${field4}N2`] && this.ingredient?.[`${field4}N2`] === this.ingredient?.[`${field4}N1`]
			// 		? ''
			// 		: this.ingredient?.[`${field4}N1`])
			// );

			if (this.isN2ModelizationType() && this?.ingredient?.customN1) {
				return (
					this.ingredient?.customN1[`${field}N1`] ||
					this.ingredient?.customN1[`${field2}N1`] ||
					this.ingredient?.customN1[`${field3}N1`] ||
					this.ingredient?.customN1[`${field4}N1`] ||
					this.ingredient?.customN1[`${field}`] ||
					this.ingredient?.customN1[`${field2}`] ||
					this.ingredient?.customN1[`${field3}`] ||
					this.ingredient?.customN1[`${field4}`] ||
					''
				);
			}

			return (
				this.ingredient?.[`${field}N1`] ||
				this.ingredient?.[`${field2}N1`] ||
				this.ingredient?.[`${field3}N1`] ||
				this.ingredient?.[`${field4}N1`] ||
				this.ingredient?.[`${field}`] ||
				this.ingredient?.[`${field2}`] ||
				this.ingredient?.[`${field3}`] ||
				this.ingredient?.[`${field4}`] ||
				''
			);
		}

		return (
			this.ingredient?.[`${field}N2`] ||
			this.ingredient?.[`${field2}N2`] ||
			this.ingredient?.[`${field3}N2`] ||
			this.ingredient?.[`${field4}N2`] ||
			this.ingredient?.[`${field}`] ||
			this.ingredient?.[`${field2}`] ||
			this.ingredient?.[`${field3}`] ||
			this.ingredient?.[`${field4}`] ||
			''
		);
	}
}
