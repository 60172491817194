import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { useMutation } from '@tanstack/react-query';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import { ResetPasswordInput, resetPasswordSchema } from '@carbonmaps/shared/validations/auth.validations';
import { updateCurrentUserPasswordAction } from '@carbonmaps/ui/actions/auth.actions';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import AlertStatic from '../../components/Toast/AlertStatic';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useLogInMutation } from '../../lib/react-query/features/auth/auth.hooks';

const SecurityForm = () => {
	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);
	const [message, setMessage] = useState('');

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		getValues,
	} = useForm<ResetPasswordInput>({
		resolver: zodResolver(resetPasswordSchema),
	});

	const {
		result: { mutate: logIn },
	} = useLogInMutation();

	const {
		mutate: updateCurrentUserPassword,
		isSuccess,
		isError,
	} = useMutation({
		mutationKey: ['updatePassword'],
		mutationFn: updateCurrentUserPasswordAction,
		onSuccess: (data) => {
			const password = getValues('password');
			reset({ password: '', confirmPassword: '' });
			setMessage(languageCode === 'fr' ? 'Mot de passe mis à jour' : 'Password updated');
			logIn({ email: data?.email, password });
		},
	});

	const onSubmit: SubmitHandler<ResetPasswordInput> = async (values) => {
		updateCurrentUserPassword({ ...values, languageCode });
	};

	const onFormError: SubmitErrorHandler<ResetPasswordInput> = (error) => {
		if (error.password) {
			setMessage(error.password.message || '');
		} else if (error.confirmPassword) {
			setMessage(error.confirmPassword.message || '');
		}
	};

	return (
		<FormContainer
			title={t('user-security-form-title')}
			subtitle={t('user-security-form-subtitle')}
			onSubmit={handleSubmit(onSubmit, onFormError)}
			buttonTitle={t('user-security-form-button')}
			isSuccess={isSuccess}
			isError={isError}
			withSave
			alertSuccess={
				<AlertStatic text={message} type={'success'} icon={<CheckIcon css={{ color: siteColors.green500 }} />} />
			}
			alertError={<AlertStatic text={message} type={'error'} icon={<ErrorIcon css={{ color: '#C74600' }} />} />}
			t={t}
		>
			<div
				className="flexColumn width100"
				css={{
					maxWidth: 428,
					gap: 24,
					'.input-container': {
						width: '100%',
					},
				}}
			>
				<BInput
					required
					id="password"
					label={t('password')}
					type="password"
					fullWidth
					margin="dense"
					{...register('password')}
					name="password"
					error={errors.password ? true : false}
					autoComplete="on"
				/>
				<BInput
					required
					id="confirmPassword"
					label={t('confirm-password')}
					type="password"
					fullWidth
					margin="dense"
					{...register('confirmPassword')}
					name="confirmPassword"
					error={errors.confirmPassword ? true : false}
					autoComplete="on"
				/>
			</div>
		</FormContainer>
	);
};

export default SecurityForm;
