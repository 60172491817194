import { type QueryFunctionContext } from '@tanstack/react-query';

import { productData } from './_mockData';

type getProductAcvDataType = {
	input?: string;
	facetFilters?: string;
	page: number;
	size: number;
};

export const getProductAcvDataAction = async (
	context: QueryFunctionContext<readonly ['getProductAcvDataAction', getProductAcvDataType]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, ...rest },
			},
		} = context;

		// return {
		// 	data: productData,
		// 	meta: {
		// 		last_page: 1,
		// 		count: 1,
		// 	},
		// };

		const { results, count } = await Parse.Cloud.run('getProductAcvData', {
			limit: size,
			skip: (page - 1) * size,
			...rest,
		});

		return {
			data: results,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

export const checkAcvPermission = async (
	context: QueryFunctionContext<readonly ['checkAcvPermission', getProductAcvDataType]>,
) => {
	try {
		const result = await Parse.Cloud.run('checkAcvPermission');

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getIngredientAcvDataAction = async (
	context: QueryFunctionContext<readonly ['getIngredientAcvDataAction', getProductAcvDataType]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, ...rest },
			},
		} = context;

		const { results, count } = await Parse.Cloud.run('getIngredientAcvData', {
			limit: size,
			skip: (page - 1) * size,
			...rest,
		});

		return {
			data: results,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
};
