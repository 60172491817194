import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useTranslation } from '../../hooks/useTranslation';
import Typography from '../Typography';

import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { cn, siteColors } from '../../lib/colors';
import SupplierScore from './SupplierScore';

const styles = {
	container: css({
		width: 'var(--width)',
		height: 'var(--height)',
		position: 'relative',
		// cursor: 'pointer',
		'&:hover .custom-tooltip': { display: 'block !important' },
		alignContent: 'center!important',
		alignItems: 'center!important',
	}),

	carbonBar: css({
		// background: siteColors.carbon100,
		background:
			'linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), var(--backgroundColor)',
		borderTopWidth: 1,
		borderTopStyle: 'solid',
		width: '100%',
		height: 'var(--height)',
		// minWidth: '55px',
		borderTopColor: 'var(--backgroundColor)',
	}),

	noBorder: css({
		border: 'none',
	}),

	cardNew: css({
		border: cn('1px dashed', siteColors.grey600, '!important'),
		background: '#FFF !important',
	}),

	label: css({
		width: '100%',
		maxWidth: '100%',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	}),

	note: css({
		// marginTop: '200px'
	}),

	carbonLabel: css({
		color: cx(siteColors.grey900, '!important'),
	}),
	cardValue: css({
		zIndex: 'var(--zIndex)',
		fontSize: '12px',
	}),

	tooltipContainer: css({
		display: 'none',
		position: 'absolute',

		left: '50%',
		zIndex: 100,
	} as any),
} as any;

type BarItemProps = {
	item: any;
	width?: string;
	isFilter?: boolean;
	color?: string;
	withLabel?: boolean;
	maxPercent?: number;
	carbonBarClassName?: string;
};

const percent_for_value = 15;

// format compact number
const formatCompactNumber = (number: number, unit = '') => {
	if (!number) return '';
	const options = {
		notation: 'compact',
		compactDisplay: 'short',
	} as any;
	const format = Intl.NumberFormat('fr-FR', options);
	return `${format.format(number)}${unit}`;
};

const BarItem = ({
	item,
	width,
	isFilter = false,
	color,
	withLabel = false,
	maxPercent,
	carbonBarClassName,
}: BarItemProps) => {
	const { indicator } = useApp();
	// const { t } = useTranslation();
	const theme = useTheme();

	return (
		<div
			className={cx('flexColumn  relative flexEnd justifyEnd gap8', styles.container)}
			style={{
				['--width' as any]: width,
				['--height' as any]: `100%`,
			}}
		>
			<div className={cx(' width100 height100', styles.cardValue)} style={{ ['zIndex' as any]: 10 }}>
				<div className="flexColumn gap8 width100 height100 justifyEnd">
					{/* {item?.percentValue ? (
						<Typography
							className={cx('number', styles.label, styles.carbonLabel)}
							style={{ ['--color' as any]: theme.palette.indicator[indicator].main }}
							variant="caption"
						>
							{displayValue(item?.percentValue, undefined, 0)}%
						</Typography>
					) : null} */}
					<Typography
						className={cx('number', styles.label, styles.carbonLabel)}
						style={{ ['--color' as any]: theme.palette.indicator[indicator].main }}
						variant="caption"
					>
						{displayValue(item?.percentValue, undefined, 0)}%
					</Typography>

					<div
						className={cx(styles.carbonBar, styles[indicator], carbonBarClassName || '')}
						style={{
							['--height' as any]: `calc(${(item.percentValue * 100) / (maxPercent || 1)}% ) `,
							['--backgroundColor' as any]: color,
						}}
					></div>
				</div>
			</div>
			{withLabel && (
				<div className={cx(styles.note)}>
					<SupplierScore score={item?.label} />
				</div>
			)}
		</div>
	);
};

export default BarItem;
