import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import _ from 'lodash';

import { CARBONE_INDICATOR, FRONT_PATH_NAMES, ITEM_TYPE_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import { getFacetLabel } from '@carbonmaps/shared/utils/utils';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useBreakDownCategories } from '../../containers/analyse/analyse.hooks';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { orderBy } from '../../utils/array.utils';
import { CARBONE_IMPACT_VIEW } from '../../utils/constants';
import { CATEGORIES_GRAPH_INDICATOR_OPTIONS } from '../../utils/graph';
import Skeleton from '../Skeleton';

import AnalysisHorizontalGraph from './AnalysisHorizontalGraph';
import ChartContainer from './ChartContainer';

type CategoriesGraphProps = {
	data: any;
	facets?: { [x: string]: Array<any> };
	title?: string;
	isDisabled?: boolean;
	isLoading?: boolean;
	showToggle?: boolean;
	defaultIndicator?: any;
	color?: string;
	icon?: React.ReactNode;
	t?: any;
};

const toDataIndicatorGraph = (
	data: any,
	category: string,
	indicatorField: string,
	isDisabled?: boolean,
	company?: any,
	t?: any,
	viewMode?: string,
) => {
	if (data.length === 0) {
		return [
			{
				y: 1,
			},
		];
	}

	const sortingData = orderBy(data, indicatorField, 'desc');

	const firstData = sortingData.slice(0, 20);
	const othersData = sortingData?.length > 20 ? sortingData.slice(20 + 1, sortingData?.length) : [];

	const result = firstData.map((item) => {
		return {
			...item,
			category: getFacetLabel(category, company),
			categoryValue: item._id,
			y: isDisabled ? 1 : item[indicatorField] || 1,
		};
	}) as any;

	if (othersData?.length) {
		const carbonImpact = _.sumBy(othersData, 'carbonImpact');
		const waterImpact = _.sumBy(othersData, 'waterImpact');
		const otherElement = {
			category: getFacetLabel(category, company),
			categoryValue: t('Autres'),

			y: viewMode === CARBONE_INDICATOR ? carbonImpact : waterImpact,
			carbonImpact,
			waterImpact,
			tonnage: _.sumBy(othersData, 'tonnage'),
			volume: _.sumBy(othersData, 'volume'),
		};
		result.push(otherElement as any);
	}

	return result;
};

const styles = {
	skeleton: css({
		width: '100% !important',
		height: '200px!important',
	}),
};

const CategoriesGraph = ({
	data,
	isLoading,
	showToggle = true,
	isDisabled,
	title = 'Répartition dans mes catégories',
	defaultIndicator,
	color,
	icon,
}: CategoriesGraphProps) => {
	const { viewMode } = useApp();

	// translation
	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const [currentViewMode, setCurrentViewMode] = useState(viewMode || CARBONE_IMPACT_VIEW);
	const [indicatorGraph, setIndicatorGraph] = useState<any>(defaultIndicator);

	useEffect(() => {
		if (!viewMode) return;
		setCurrentViewMode(viewMode);
		setIndicatorGraph(CATEGORIES_GRAPH_INDICATOR_OPTIONS[viewMode][0]);
	}, [viewMode]);

	useEffect(() => {
		if (!defaultIndicator) return;

		setIndicatorGraph(defaultIndicator);
	}, [defaultIndicator]);

	const isEmptyData =
		_.isEmpty(data) ||
		!_.isObject(data) ||
		Object.values(data).every((v) => {
			return _.isEmpty(v);
		});

	return (
		<ChartContainer
			title={t(title)}
			showToggle={showToggle}
			unity={
				indicatorGraph?.unit || (
					<>
						T <sub>EQ</sub> CO<sub>2</sub>
					</>
				)
			}
			onChangeGraphIndicator={setIndicatorGraph}
			indicator={indicatorGraph}
			indicatorOptions={CATEGORIES_GRAPH_INDICATOR_OPTIONS[currentViewMode]}
			sx={{ ...(isDisabled && { pointerEvents: 'none', opacity: 0.8 }) }}
			icon={icon}
		>
			{isLoading ? (
				<Skeleton className={styles.skeleton} />
			) : isEmptyData ? (
				<div>{t('Pas de catégorie renseigné')}</div>
			) : (
				<Box className="flexColumn alignCenter justifyCenter nowrap" sx={{ gap: '24px' }}>
					{Object.keys(_.isObject(data) ? data : {})
						.filter((item: any) => {
							return (data as any)[item]?.length;
						})
						.map((item: string) => {
							return (
								<AnalysisHorizontalGraph
									title={getFacetLabel(item, authData?.session.company)}
									data={
										toDataIndicatorGraph(
											(data as any)[item] || [],
											item,
											indicatorGraph?.value || 'carbonImpact',
											isDisabled,
											authData?.session.company,
											t,
											viewMode,
										) as any
									}
									indicator={indicatorGraph}
									onClick={(g) => {
										//disable ingredient category
										if ((data as any)[item]?.length && (data as any)[item][0]['itemType'] === ITEM_TYPE_INGREDIENT) {
											return;
										}

										window.open(
											`${FRONT_PATH_NAMES.analyse}/${item}/${g.categoryValue}/synthesis?itemType=${
												(data as any)[item]?.length ? (data as any)[item][0]['itemType'] : ''
											}`,
										);
									}}
									isDisabledLink={
										((data as any)[item]?.length && (data as any)[item][0]['itemType'] === ITEM_TYPE_INGREDIENT) ||
										false
									}
									isDisabled={isDisabled || (data as any)[item]?.length === 0}
									color={color}
									count={(data as any)[item]?.length || 0}
								/>
							);
						})}
				</Box>
			)}
		</ChartContainer>
	);
};

export default CategoriesGraph;
