import { useEffect } from 'react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import EmbedNotion from '../../components/EmbedNotion';
import SEO from '../../components/SEO';

const LandingPage = () => {
	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([{ text: 'Carbon Maps' }]);
	}, [setBreadcrumbs]);

	return (
		<>
			<SEO title="Carbon Maps" />
			<iframe
				title={'Carbon Maps'}
				src={'https://www.carbonmaps.io/'}
				css={{ width: '100%', height: '11023px', flex: 1, overflow: 'hidden', border: 'none', borderRadius: 'none', padding: 'none' }}
			></iframe>

		</>
	);
};

export default LandingPage;
