import { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { LogIn } from 'lucide-react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import defaultLogo from '@carbonmaps/media/images/default-logo.png';
import { ReactComponent as CarbonMapsLogo } from '@carbonmaps/media/other/carbonmaps-logo.svg';
import carbonmapsIconLogo from '@carbonmaps/media/other/favicon-512w.png';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import useHasRoles from '../../hooks/useHasRoles';
import DefaultLayout from '../../layouts/_common/BaseLayout';
import {
	useGetClientAuthQuery,
	useLogOutMutation,
	useUpdateSessionCompanyMutation,
} from '../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../lib/react-query/features/img/img.hooks';

const ChooseCompanyAuth = () => {
	const theme = useTheme();

	const navigate = useNavigate();
	const location = useLocation();
	const hasRoles = useHasRoles();

	const {
		result: { mutate: logOut, isLoading: isLogOutLoading },
	} = useLogOutMutation({
		onSuccess(data, variables, context) {
			navigate(FRONT_PATH_NAMES.logIn);
		},
	});

	const {
		result: { data: authData /* , isLoading: isAuthDataLoading */ },
	} = useGetClientAuthQuery();

	const [chosenCompanyId, setChosenCompanyId] = useState<string>();

	const {
		result: { mutate: updateSessionCompany, isLoading: isUpdateSessionCompanyLoading },
	} = useUpdateSessionCompanyMutation({
		onSuccess(data, variables, context) {
			navigate(FRONT_PATH_NAMES.home);
		},
		onError(error, variables, context) {
			setChosenCompanyId(undefined);
		},
	});

	if (hasRoles(['super_admin'])) {
		return <Navigate to={FRONT_PATH_NAMES.superAdminSettings} state={{ from: location }} replace />;
	}

	if (authData?.session.company) {
		return <Navigate to={FRONT_PATH_NAMES.home} state={{ from: location }} replace />;
	}

	return (
		<DefaultLayout>
			<Container maxWidth="sm">
				<div className="flexRow" css={{ justifyContent: 'center', alignItems: 'center', marginTop: '32px' }}>
					<img src={carbonmapsIconLogo} css={{ height: '48px' }} alt="" />
					<CarbonMapsLogo css={{ marginLeft: '12px' }} width={150} /* viewBox="0 0 150 100" */ />
				</div>

				<div>
					<Typography variant="h1" my="32px" textAlign="center">
						Choisissez une organization
					</Typography>
				</div>

				<div
					css={{
						boxShadow: theme.shadows[3],
						borderRadius: '4px',
						overflow: 'hidden',
						marginBottom: '40px',
					}}
				>
					<div css={{ padding: '12px 16px', borderBottom: `solid 1px ${theme.palette.grey[500]}` }}>
						<Typography variant="h4" color={theme.palette.grey[800]} fontWeight={500}>
							Les organisations de <span css={{ color: theme.palette.common.black }}>{authData?.user.email}</span>
						</Typography>
					</div>

					{(authData?.user.companies || [])
						.filter((e) => {
							return _.has(e, 'company');
						})
						.map(({ company }, index, array) => {
							const isChosen = chosenCompanyId === company.objectId;

							return (
								<div key={index} css={{ padding: '0 16px' }}>
									<div
										className="flexRow spaceBetween"
										css={{
											// background: 'red',
											padding: '16px 0',
											alignItems: 'center',
										}}
									>
										<div
											className="flexRow"
											css={{
												alignItems: 'center',
											}}
										>
											<CompanyLogo logo={company.logo || ''} />
											<Typography marginLeft="12px" marginTop="-8px" variant="h3">
												{company?.name || '(Sans nom)'}
											</Typography>
										</div>

										<IconButton
											css={{
												borderRadius: '4px',
												width: '64px',
											}}
											onClick={() => {
												setChosenCompanyId(company.objectId);
												updateSessionCompany({ companyId: company.objectId });
											}}
											disabled={!!chosenCompanyId || company.deleted === true}
										>
											{isChosen && isUpdateSessionCompanyLoading ? <CircularProgress size="24px" /> : <LogIn />}
										</IconButton>
									</div>
									{index + 1 < array.length ? <Divider /* css={{ width: '95%', margin: '0 auto' }} */ /> : null}
								</div>
							);
						})}
				</div>
				<div>
					<MuiLink
						sx={{ cursor: 'pointer' }}
						onClick={() => {
							logOut();
						}}
					>
						se déconnecter
					</MuiLink>{' '}
					ou{' '}
					<MuiLink sx={{ cursor: 'pointer' }} href="mailto:support@carbonmaps.io">
						demander de l'aide
					</MuiLink>
				</div>
			</Container>
		</DefaultLayout>
	);
};

export default ChooseCompanyAuth;

const CompanyLogo = ({ logo }: { logo: string }) => {
	const theme = useTheme();

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: logo || '' },
	});

	return (
		<img
			// src={defaultLogo}
			src={logo ? logoUrl : defaultLogo}
			css={{
				width: '64px',
				height: '64px',
				borderRadius: '4px',
				border: `1px solid ${theme.palette.grey[500]}`,
				objectFit: 'contain',
			}}
			alt=""
		/>
	);
};
