import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { css, cx } from '@emotion/css';
import Typography from '../../../components/Typography';
import { siteColors } from '../../../lib/colors';

type DonutChartLegendProps = {
	items: any[];
};
const DonutChartLegend = ({ items }: DonutChartLegendProps) => {
	return (
		<div className="flexColumn gap16">
			{items.map((item) => (
				<DisplayItem
					key={item.label}
					label={item.label}
					color={item.color}
					tonnage={item?.tonnage}
					value={item.value}
					percent={item.value}
				/>
			))}
		</div>
	);
};

type DisplayItemProps = {
	label: string;
	color: string;
	value: number;
	percent: number;
	tonnage: number;
};

const styles = {
	square: css({
		height: 8,
		width: 8,
		borderRadius: '50%',
		background: 'var(--background)',
	}),
	container: css({
		minWidth: 125,
	}),
	tonnage: css({
		color: `${siteColors.grey7} !important`,
		minWidth: 80,
		textAlign: 'right',
	}),
	percent: css({
		color: `${siteColors.grey7} !important`,
		minWidth: 55,
		textAlign: 'right',
	}),
};

export const DisplayItem = ({ label, color, value, tonnage, percent }: DisplayItemProps) => {
	return (
		<div className="flexRow nowrap gap8">
			<div className={cx('flexRow alignCenter gap8', styles.container)}>
				<div className={styles.square} style={{ ['--background' as any]: color }} />
				<Typography className="lineHeight1">{label}</Typography>
			</div>
			<Typography className={cx('lineHeight1', styles.tonnage)}>{`${formatNumber(
				tonnage,
				undefined,
				0,
			)} T`}</Typography>
			<Typography className={cx('lineHeight1', styles.percent)}>{`${formatNumber(value, undefined, 0)}%`}</Typography>
		</div>
	);
};

export default DonutChartLegend;
