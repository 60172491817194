import { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import _ from 'lodash';

import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Unit from '../../units/Unit';

import Popover from './Popover';
import PopoverItem from './PopoverItem';

type Props = {
	item: any;
	intensityKey: string;
};

const ImpactCarbonePopover = ({ item, intensityKey }: Props) => {
	const displayValue = useMemo(() => {
		const value = item?.carbonImpact;
		let toDisplay = '-';

		if (!_.isNumber(value)) {
			return toDisplay;
		}

		if (value >= 1) {
			toDisplay = formatNumber(value, undefined, 0);
		}

		if (value >= 0.01 && value < 1) {
			toDisplay = formatNumber(value, undefined, 2);
		}

		if (value < 0.01) {
			toDisplay = '< 0,01';
		}

		return toDisplay;
	}, [item?.carbonImpact]);

	const label = useMemo(() => {
		// eslint-disable-next-line quotes
		if (!item?.ges) return "facteur d'émission non renseigné";

		// eslint-disable-next-line quotes
		if (!item?.volume) {
			if (item?.volume === 0) {
				return 'Volume incorrect';
			}

			return 'Volume non renseigné';
		}

		return WORDING_TERMS.IMPACT;
	}, [item]);

	return (
		<Popover>
			{item?.carbonImpact < 0 ? (
				<Typography className="fontWeight600">Volume incorrect</Typography>
			) : (
				<PopoverItem
					label={
						label
						// item?.volume
						// 	? WORDING_TERMS.IMPACT
						// 	: 'Volume non renseigné'
					}
					value={
						item?.volume ? (
							<>
								{displayValue} {' '}
								<Unit
									measure="carbonImpactTon"
								// sx={{ textTransform: 'uppercase' }} // don't need uppercase for unit
								/>
							</>
						) : (
							''
						)
					}
				/>
			)}
		</Popover>
	);
};

export default ImpactCarbonePopover;
