import { css, cx } from '@emotion/css';

const styles = {
	container: css({
		gap: 2,
	}),
	line: css({
		width: 'var(--width)',
		height: 8,
		borderRadius: 2,
		background: 'var(--background)',
	}),
};

type Props = {
	value: Array<any>;
	row: any;
	column: any;
};

const MaterialLabel = ({ value, row, column }: Props) => {
	console.log('MaterialLabel', row);
	return <div>{value}</div>;
};

export default MaterialLabel;
