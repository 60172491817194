import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';
import _ from 'lodash';

type CardProps = {
	title: React.ReactNode;
	subtitle?: React.ReactNode;
	children: React.ReactNode;
	sx?: SxProps;
	icon?: React.ReactNode;
	sxBoxProps?: SxProps;
	className?: string;
};

/**
 * @deprecated
 * Use BlockCard instead
 */
const Card = ({ title, subtitle, icon, children, sx = {}, className, sxBoxProps }: CardProps) => {
	const theme = useTheme();

	return (
		<Box mb={title ? '32px' : 0}>
			{title && (
				<Box mb="16px">
					<Box className="flexRow alignCenter" sx={{ gap: '8px' }}>
						{icon}
						<Typography variant="h3" color={theme.palette.common.black} fontSize={'16px'}>
							{title}
						</Typography>
						<Typography fontSize="12px" color={theme.palette.grey[900]} fontWeight={500} display="inline-block">
							{subtitle}
						</Typography>
					</Box>
				</Box>
			)}
			<Box
				border={`solid 1px ${theme.palette.grey[500]}`}
				borderRadius="16px"
				sx={_.merge({}, sx)}
				className={className}
			>
				{children}
			</Box>
		</Box>
	);
};

export default Card;
