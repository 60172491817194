import Box from '@mui/material/Box';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ArrowUpRight } from 'lucide-react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier.svg';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import Scoring from '@carbonmaps/ui/components/saas/Table/Scoring';
import { useGetIngredientSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetIngredientSheet';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import BlueCheck from '../../../components/BlueCheck';
import { useTranslation } from '../../../hooks/useTranslation';
import SupplierModel from '../../../models/Supplier.model';
import { orderBy } from '../../../utils/array.utils';

const IngredientSuppliersBlock = () => {
	const params = useParams();
	const theme = useTheme();

	const { t } = useTranslation();

	const { data, isLoading, isError, error } = useGetIngredientSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.ingredientId!,
		// period: 2023,
	});

	return (
		<>
			<Box className="flexRow" gap={'8px'}>
				<SupplierIcon css={{ '& path': { fill: theme.palette.grey[700] } }} height={20} width={20} />
				<BlockTitle variant="h3" color={theme.palette.common.black}>
					{t('suppliers')}
				</BlockTitle>
			</Box>
			<BlockCard>
				{(data?.ingredient as any)?.suppliers?.length > 0 ? (
					orderBy(
						((data?.ingredient as any)?.suppliers as any[]).map((item: any) => {
							return { ...item, isCheckedValue: new SupplierModel(item).isBlueCheck() ? 1 : 0 };
						}),
						'isCheckedValue',
						'desc',
					)?.map((supplier, index) => {
						const supplierObject = new SupplierModel(supplier);
						// return supplier.name;
						return (
							<Box key={index} mb="6px" display="flex" alignItems="center" justifyContent="space-between">
								<Box display="flex" alignItems="center" css={{ gap: '4px' }}>
									<BlueCheck
										withIcon
										ok={supplierObject.isBlueCheck()}
										sx={{ mr: '6px' }}
										/*  title={supplier.name} */
									/>
									<Typography mr="6px">{supplier.name}</Typography>
								</Box>

								<Box display="flex" alignItems="center" css={{ gap: '8px' }}>
									<Scoring
										value={supplier.scoring}
										sx={{
											mr: '6px',
											padding: '6px',
											width: '36px',
											borderRadius: '100px',
											'& .MuiTypography': {
												fontSize: '11px',
											},
										}}
									/>

									<MUILink
										component={RouterLink}
										to={`${FRONT_PATH_NAMES.suppliers.details}/${supplier.objectId}/synthesis`}
										// sx={{ '&:hover': { color: theme.palette.primary.main } }}
									>
										<ArrowUpRight
											size={20}
											color={theme.palette.grey[700]}
											css={{ '&:hover': { stroke: theme.palette.primary.main } }}
										/>
									</MUILink>
								</Box>
							</Box>
						);
					})
				) : (
					<Typography>{t('product-suppliers-card-empty-data')}</Typography>
				)}
			</BlockCard>
		</>
	);
};

export default IngredientSuppliersBlock;
