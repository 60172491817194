import { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { eachOfLimit } from 'async';
import { useParams } from 'react-router-dom';

import { getConfigFacets, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getCategoriesProduct } from '@carbonmaps/ui/actions/product.actions';

import { IFilterFolder } from '../../components/Filter';
import { Option } from '../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../components/layout/list/FilterContainer';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { getLabelNodeFacet } from '../../utils/utils';

import AuditExport from './AuditExport';

const styles = {
	container: css({
		marginBottom: '-4px ',
	}),
	searchContainer: css({
		'& svg': {
			marginLeft: '16px !important',
		},
		'& .MuiOutlinedInput-root': {
			width: '365px !important',
			'& .MuiAutocomplete-input': {
				paddingLeft: '0px !important',
			},
		},
	}),
};

const AuditFilter = () => {
	// translation
	const { t } = useTranslation();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const params = useParams();
	const searchParams = searchQueryParams?.facetFilters ?? [];
	// custom facets suppliers
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);
	const { selectedYear } = useYearSelection();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const dataFacets = useMemo(() => {
		return [
			...getConfigFacets(authData?.session.company, true, 'Product'),
			{
				field: 'type_error',
				label: t('type_error_audit'),
				type: 'string',
				width: 310,
			},
		];
	}, [authData?.session.company, t]);


	// filters values
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	const { data: facets, isLoading: isFacetsLoading } = useQuery({
		queryKey: [
			'getCategory',
			{
				input: searchQueryParams.input,

				facetFilters: (searchQueryParams?.facetFilters || []).filter((item: any) => {
					return item?.path !== 'type_errorv';
				}), //searchQueryParams.input

				itemType: params?.itemType,
				path: 'audit',
				period: selectedYear,
			},
		],
		queryFn: getCategoriesProduct,
	});

	useEffect(() => {
		//	if there are filters in the url
		if (searchParams && searchParams.length) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			searchParams.forEach((item: any) => {
				const config = dataFacets.find((f: any) => {
					return f.field === item.path;
				});

				const arrayValues = item.values.map((v: any) => {
					const value = {
						facetPath: item.path,
						value: v,
						folderLabel: item?.folderLabel ?? item.path,
						label: getLabelValue(item.path, v),
						isFacetBucket: true,
						type: typeof v,
						global: item.global === true ? true : false,
					};

					currentSelectedValues.push(value);
					return value;
				});

				currentAppliedValuesFolders.push({
					isFacet: true,
					label: config?.label ?? item.path,
					options: arrayValues,
				});
			});

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchParams]);

	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facets) return;

			const facetFilterFolders: IFilterFolder[] = [];

			for await (const entry of Object.entries(facets.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;

				const config = dataFacets.find((f: any) => {
					return f.field === key;
				});

				const facetLabel = config?.label || key;
				const currentFacetFolder: IFilterFolder = {
					label: facetLabel,
					options: [],
					isFacet: true,
					path: key,
					showCount: true,
				};

				await eachOfLimit(buckets as any[], 100, async (bucket, k) => {
					if (!bucket.count) {
						return;
					}

					const config = dataFacets.find((f: any) => {
						return f.field === key;
					});

					currentFacetFolder.options.push({
						value: bucket._id,
						label: getLabelValue(key, bucket._id),
						labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
							? getLabelNodeFacet(key, bucket._id, t)
							: undefined,
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: config?.type ?? 'hardcoded-undefined-facet-type',
						global: config.global,
					});

					return;
				});
				facetFilterFolders.push(currentFacetFolder);
			}

			setFilterFolders((folders) => {
				const notFacetFolders = folders.filter((e) => {
					return !e.isFacet;
				});

				return [...notFacetFolders, ...facetFilterFolders];
			});
		};

		asyncWrapper();
	}, [facets, authData?.session.company, dataFacets]);

	return (
		<>
			<FilterContainer
				skeleton={isFacetsLoading}
				isLoading={false}
				appliedValuesByFolders={appliedValuesByFolders}
				setAppliedValuesByFolders={setAppliedValuesByFolders}
				selectedValues={selectedValues}
				setSelectedValues={setSelectedValues}
				folders={filterFolders}
				setSearchQueryParams={setSearchQueryParams}
				rightContent={<AuditExport isLoading={false} />}
				suppliersFolderLabel="random"
				isTableLoading={false}
				isExportLoading={false}
				searchPlaceholder={t('audit-search-placeholder')}
				searchClassName={styles.searchContainer}
				className={styles.container}
				withMore={false}
			/></>
	);
};

export default AuditFilter;
