import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { saveEstimatedImpact } from '@carbonmaps/ui/actions/dashboard.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { usePeriodOptions } from '../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../hooks/useTranslation';
import Dialog from '../../../dialogs/Dialog';
import Unit from '../../../units/Unit';

import { cn, siteColors } from '../../../../lib/colors';
import DisplayLineTable from './DisplayLineTable';

type TemporalityModalProps = {
	open: boolean;
	onClose?: () => void;
	currentSelected?: any;
};

const styles = {
	container: css({
		'& .MuiDialogTitle-root, .MuiDialogContent-root': {
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'& .MuiDialogTitle-root': {
			paddingTop: '0px !important',
			color: siteColors.primary,
		},

		'& .MuiDialogContent-root': {
			paddingBottom: '32px !important',
		},
		'& .MuiDialogContentText-root': {
			color: cn(siteColors.grey900, '!important'),
		},
	}),

	tableContainer: css({
		paddingTop: '32px',
		maxHeight: '45vh',
		overflowY: 'auto',
		paddingRight: 10,
		table: {
			borderSpacing: '1px!important',
			background: siteColors.grey500,
			borderRadius: '16px',
			overflow: 'hidden',
		},

		th: {
			background: siteColors.grey400,
			padding: '12px 16px',
			fontSize: '14px !important',
			fontWeight: '600',
		},

		'th:first-child': {
			borderTopLeftRadius: '16px',
		},
		'th:last-child': {
			borderTopRightRadius: '16px',
		},
		td: {
			// padding: '12px 16px',
			background: siteColors.common.white,
			overflow: 'hidden',
		},
		'tr:last-child td:first-child': {
			borderBottomLeftRadius: '16px',
		},
		'tr:last-child td:last-child': {
			borderBottomRightRadius: '16px',
		},
		'.MuiFormControl-root': {
			width: '100% !important',
		},
	}),

	selectedContainer: css({
		width: '100%',
		background: '#fff !important',
		color: cn(siteColors.grey700, '!important'),
		'.MuiInputBase-input': {
			paddingLeft: '0px !important',
			paddingTop: '0px !important',
			paddingBottom: '0px !important',
		},
		svg: {
			width: '20px',
			height: '20px',
			color: cn(siteColors.grey700, '!important'),
		},
	}),
};

const TemporalityModal = ({ open, currentSelected, onClose }: TemporalityModalProps) => {
	const { options: yearOptions, estimatedOptions, addEstimatedImpactOption } = usePeriodOptions();
	const { t } = useTranslation();

	const { indicator } = useApp();

	const currentYear: any = useMemo(() => {
		return yearOptions?.find((item: any) => {
			return item?.value === new Date()?.getFullYear();
		});
	}, [yearOptions]);

	// ---- handle add year ---- //
	const addYear = () => {
		const lastYear = Math.max(
			...estimatedOptions.map((item: any) => {
				return item.value;
			}),
			new Date()?.getFullYear(),
		);

		const newYear = lastYear + 1;

		const option = {
			value: newYear,
			label: `${newYear}`,
			data: {
				value: 0,
				estimatedValue: 0,
			},
			isNew: true,
		};
		addEstimatedImpactOption(option);
	};

	return (
		<Dialog
			title={t('Ajouter des objectifs')}
			description={t('Ajoutez des objectifs futurs pour visualiser votre trajectoire carbone dans le temps')}
			open={open}
			actions={
				<div className="flexRow alignCenter gap8">
					<BButton label={t('Ajouter une année')} variant="secondary" onClick={addYear} />
					<BButton label={t('Fermer')} variant="secondary" onClick={onClose} />
				</div>
			}
			paperProps={{
				padding: 42,
				paddingRight: 30,
				maxWidth: 700,
				width: 635,
			}}
			className={styles.container}
		>
			<div className={cx(styles.tableContainer, 'width100')}>
				<table className="width100">
					<thead>
						<tr>
							<th>{t('Année')}</th>
							<th>
								{t('Objectif d’impact')}{' '}
								<Unit
									measure={indicator === CARBONE_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
									className={'grey800'}
								/>
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<DisplayLineTable
							item={{
								value: new Date().getFullYear(),
								label: `${new Date().getFullYear()} ${t('année courante')}`,
								data: currentYear?.data || {},
							}}
							currentYear
						/>

						{estimatedOptions
							?.filter((item: any) => {
								return item.value !== new Date().getFullYear();
							})
							.map((item: any, index: number) => {
								return <DisplayLineTable item={item} />;
							})}
					</tbody>
				</table>
			</div>
		</Dialog>
	);
};

export default TemporalityModal;
