import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { AlertCircle, Check, Pencil } from 'lucide-react';
import { useForm } from 'react-hook-form';

import {
	SimulationFormInput,
	SimulationFormValidationSchema,
} from '@carbonmaps/shared/validations/simulation.validations';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';

import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import SearchAutocomplete from '../SearchAutocompleteV2';
import AlertStatic from '../Toast/AlertStatic';
import SelectYear from '../layout/SelectYear';

const styles = {
	title: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
		color: siteColors.primary,
		margin: 0,
	}) as any,
	subTitle: css({
		marginTop: '0px',
		fontSize: '14px !important',
		fontWeight: '400 !important',
		width: '400px',
	}) as any,

	formContainer: css({
		maxWidth: 500,
		gap: '16px',
		'.input-container': {
			width: '100%',
		},
	}) as any,

	header: css({
		gap: '16px',
	}) as any,
	label1: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
		marginBottom: '8px',
	}) as any,
	label: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
	}) as any,
	searchClassName: css({
		width: '400px',
		'& .MuiOutlinedInput-root': {
			width: '100% !important',
			height: '42px!important',
			borderRadius: '11px !important',
		},
	}) as any,
	renderOptionRoot: css({
		padding: '8px',
		cursor: 'pointer',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
	renderOptionUid: css({
		fontFamily: '"Fira Mono" !important',
	}),

	titleAutocomplete: css({
		fontSize: '14px',
		color: siteColors.grey900,
		fontWeight: 600,
		border: 'none',
	}),
	editAutocomplete: css({
		cursor: 'pointer',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey700),
		padding: '10.5px 10px',
		background: siteColors.common.white,
	}),
};

const SimulationForm = ({
	selectedOption,
	setSelectedOption,
	isLoading,
	isSuccess,
	isError,
	onSubmit,
	toggle,
	title,
	subTitle,
	options,
	message,
	setSearch,
	isLoadingOption,
}: any) => {
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<SimulationFormInput>({
		resolver: zodResolver(SimulationFormValidationSchema),
		defaultValues: async () => {
			return {
				name: '',
				productId: selectedOption?.objectId,
			};
		},
		mode: 'onChange',
	});

	// translation
	const { t } = useTranslation();

	useEffect(() => {
		if (selectedOption) {
			setValue('productId', selectedOption?.objectId);
		}
	}, [selectedOption, setSelectedOption, setValue]);

	return (
		<div>
			<FormContainer
				loading={isLoading}
				title=""
				buttonTitle="scenario-submit-button"
				onSubmit={handleSubmit(onSubmit)}
				isSuccess={isSuccess}
				isError={isError}
				withSave
				withCancel
				onCancel={() => {
					toggle();
				}}
				alertSuccess={<AlertStatic text={message} type={'success'} icon={<Check color={siteColors.green500} />} />}
				alertError={<AlertStatic text={message} type={'error'} icon={<AlertCircle color="#C74600" />} />}
				addStyles={{
					maxWidth: 528,
					margin: 'auto',
				}}
				t={t}
			>
				<div className={cx('flexColumn width100', styles.formContainer)}>
					<div className={cx('flexColumn width100', styles.header)}>
						<p className={cx(styles.title)}>{title}</p>
						<p className={cx(styles.subTitle)}>{subTitle}</p>
					</div>

					<SelectYear />

					<div className="width100">
						<div className={cx(styles.label1)}>{t('scenario-product-source-label')}</div>
						{errors?.productId?.message && !selectedOption && (
							<Typography variant="inherit" color={'red'}>
								{errors?.productId?.message}
							</Typography>
						)}

						{!_.isEmpty(selectedOption) ? (
							<div
								onClick={() => {
									// return setStep(2);
									// toggleAutoComplete();
									setSelectedOption({});
								}}
								className={cx('flexRow width100 justifyBetween alignCenter  ', styles.editAutocomplete)}
							>
								<div>
									<div className={cx(styles.titleAutocomplete)}>{selectedOption?.label}</div>
									{selectedOption?.uid}
									{/* <br />
									{selectedOption?.address}
									<br />
									{selectedOption?.postalCode} {selectedOption?.city}
									<br /> */}
								</div>
								<Pencil color={siteColors.grey700} size={20} />
							</div>
						) : (
							<SearchAutocomplete
								// remove skeleton in input search
								//skeleton={skeleton}
								// size={20}
								setParamsAutocomplete={setSearch && _.debounce(setSearch, 500)}
								// setParamsAutocomplete={() => {  }}
								// isLoading={false}
								// placeholder={''}
								options={options}
								open={true}
								setSelectedOption={setSelectedOption}
								selectedOption={selectedOption}
								// isClearInputValue={isClearInputValue}
								// setIsClearInputValue={setIsClearInputValue}
								className={cx(styles.searchClassName)}
								isLoading={isLoadingOption}
								renderOption={(props, option: any) => {
									return (
										<div
											{...(props as any)}
											onClick={() => {
												setSelectedOption(option);
											}}
											className={styles.renderOptionRoot}
										>
											<Typography>{option?.label}</Typography>
											<Typography className={styles.renderOptionUid}>{option?.uid}</Typography>
										</div>
									);
								}}
							/>
						)}
					</div>

					<BInput
						required
						id="name"
						label={<div className={cx(styles.label)}>{t('scenario-name-field')}</div>}
						fullWidth
						margin="dense"
						{...register('name')}
						name="name"
						error={errors.name ? true : false}
						errorMessage={errors?.name?.message}
						autoComplete="off"
					/>
				</div>
			</FormContainer>
		</div>
	);
};

export default SimulationForm;
