import { css } from '@emotion/css';
import _ from 'lodash';
import { HelpCircle } from 'lucide-react';

import Lining from '../../../../../apps/front/src/components/sales/Lining';
import Popover from '../../../../../apps/front/src/components/table/popover/Popover';
import BPopover from '../BPopover';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	width30: css({
		width: 30,
		// textAlign: 'right',
	}),
};

const Ingredient = ({ value, row, column }: Props) => {

	if (row.original.isFromQuote && column.props?.page === 'report-client') {
		return <Lining />
	}

	return <span className={styles.width30}>{value}</span>;
};

export default Ingredient;
