import { css } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

import {
	CommentFormInput,
	CommentFormValidationSchema,
} from '@carbonmaps/shared/validations/quoteClientEdit.validations';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import Dialog from '../dialogs/Dialog';

const styles = {
	container: css({
		'& .MuiDialogTitle-root, .MuiDialogContent-root': {
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'& .MuiDialogTitle-root': {
			paddingTop: '0px !important',
			color: siteColors.primary,
		},

		'& .MuiDialogContent-root': {
			paddingBottom: '32px !important',
		},
		'& .MuiDialogContentText-root': {
			color: cn(siteColors.grey900, '!important'),
		},
	}),

	textField: css({
		width: '100%',
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: cn(siteColors.grey500, '!important'),
			borderRadius: '8px',
		},
	}),
};

type CommentFormModalProps = {
	open: boolean;
	value?: string;
	onSubmit: (v: { message: string }) => void;
	onClose: () => void;
};

const CommentFormModal = ({ value, open, onSubmit, onClose }: CommentFormModalProps) => {
	const {
		register,

		getValues,
		formState: { errors },
	} = useForm<CommentFormInput>({
		resolver: zodResolver(CommentFormValidationSchema),
		defaultValues: async () => {
			return {
				message: value || '',
			};
		},
		mode: 'onChange',
	});

	const onSaveData = () => {
		const values = getValues();
		onSubmit(values);
	};

	const { t } = useTranslation();
	return (
		<Dialog
			title={t('Ajout commentaire')}
			open={open}
			actions={
				<div>
					<BButton label={'Enregistrer'} variant="secondary" onClick={onSaveData} />{' '}
					<BButton label={'Fermer'} variant="secondary" onClick={onClose} />
				</div>
			}
			paperProps={{
				padding: 42,
				paddingRight: 30,
				maxWidth: 783,
				width: 783,
			}}
			className={styles.container}
		>
			<div className="width100">
				<form>
					<TextField multiline rows={6} defaultValue="" className={styles.textField} {...register('message')} />
				</form>
			</div>
		</Dialog>
	);
};

export default CommentFormModal;
