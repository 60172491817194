import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import { ArrowLeftRight, PieChart } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { CARBON_INDICATOR, WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import CategoriesGraph from '../../../../components/charts/CategoriesGraph';
import CardContent from '../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../components/dashboard/v2/CardTitle';
import DashboardCard from '../../../../components/dashboard/v2/DashboardCard';
import MetaDataCard from '../../../../components/supplier/MetaDataCard';
import SupplyLineBar from '../../../../components/supplier/SupplyLineBar';
import SupplyTable from '../../../../components/supplier/SupplyTable';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/widget/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../lib/colors';
import { orderBy } from '../../../../utils/array.utils';
import { useFindOneSupplierRSERadarChartData, useSupplierSetup } from '../../supplier.hooks';

import _ from 'lodash';
import { useRseQFormTabQueryParam } from '../../../suppliers/tabs/rse/hooks/rse.hooks';
import SupplierCommitments from './SupplierCommitments';
import SupplierDataEntered from './SupplierDataEntered';
import SupplierIndicatorsBlock from './SupplierIndicatorsBlock';
import SupplierMaturity from './SupplierMaturity';
import SupplierQuantityBlock from './SupplierQuantityBlock';
import SupplyRsePerformance from './SupplyRsePerformance';

const popoverIndicatorConfigItems = new Map([
	[
		CARBON_INDICATOR,
		{
			value: 'carbonImpact',
			label: 'Carbone',
			unit: (
				<Unit
					measure="carbonImpactTon"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'carbonImpact',
					unit: (
						<Unit
							measure="carbonImpactTon"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 0,
				},
				{
					label: 'Volume',
					key: 'tonnage',
					unit: <span>Tonne</span>,
					precision: 0,
				},
			],
		},
	],
	[
		WATER_INDICATOR,
		{
			value: 'waterImpact',
			label: 'Eau',
			unit: (
				<Unit
					measure="waterImpact"
					fontWeight={500}
					fontSize="12px"
					color={siteColors.grey700}
					lineHeight={1}
					sx={{ textTransform: 'uppercase' }}
				/>
			),
			popover: [
				{
					label: WORDING_TERMS.IMPACT,
					key: 'waterImpact',
					unit: (
						<Unit
							measure="waterImpact"
							fontWeight={600}
							lineHeight={'16.8px'}
							fontSize="14px"
							color={siteColors.text}
							sx={{ textTransform: 'capitalize' }}
						/>
					),
					precision: 2,
				},
				{
					label: 'Volume',
					key: 'tonnage',
					unit: <span>Tonne</span>,
					precision: 0,
				},
			],
		},
	],
]);

const styles = {
	cardContainer: css({
		// padding: '24px!important',
		// background: 'green'
	}),
	content: css({
		// minHeight: 321,
		marginBottom: pxToRem(40),
		// background: 'red',
		padding: '24px!important',
	}),
	content2: css({
		// minHeight: 321,
		marginBottom: pxToRem(40),
		// background: 'red',
		padding: '24px!important',
		// background: 'red',
		alignItems: 'center!important',
		justifyContent: 'center!important',
	}),
	emptyText: css({
		color: cn(siteColors.grey700, '!important'),
		textAlign: 'center',
		width: '100%',
		height: '200px',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	}),
};

const Synthesis = () => {
	const { indicator } = useApp();
	const theme = useTheme();
	const { t } = useTranslation();
	const params = useParams();
	const {
		supplierObject: supplier,
		dataCategories: segmentationData,
		productsSupplier,
		handleTabChange: onTabChange,
		isLoading,
	} = useSupplierSetup();

	const products = orderBy(productsSupplier, indicator === CARBON_INDICATOR ? 'carbonImpact' : 'waterImpact');

	const dataTable = useMemo(() => {
		const data = orderBy(products, 'blueCheckOrder').slice(0, 10);

		return data.map((item: any) => {
			return {
				...item,
				percentage: indicator === CARBON_INDICATOR ? item.carbonIntensityPercent : item.waterIntensityPercent,
			};
		});
	}, [products, indicator]);

	return (
		<Grid container gap="40px" sx={{ position: 'relative', flexWrap: 'nowrap', mt: '32px' }}>
			<Grid item xs={8.2}>
				<SupplierIndicatorsBlock />

				{Object.keys(segmentationData).length ? (
					<CategoriesGraph
						data={segmentationData}
						showToggle={false}
						defaultIndicator={popoverIndicatorConfigItems.get(indicator)}
						color={theme.palette.indicator[indicator].main}
						icon={<PieChart color={siteColors.grey700} size={20} />}
						isDisabled={!supplier?.isCarboneImpactValid()}
					/>
				) : null}

				<DashboardCard
					title={
						<CardTitle
							title={`${t('approvisionnements-card-title')} (${products.length})`}
							icon={<ArrowLeftRight color={siteColors.grey700} size={20} />}
						/>
					}
					className={cx(styles.cardContainer, 'flex1')}
				>
					<CardContent
						button={
							<BButton
								variant="secondary"
								label={t('see-all-approvisionnement-button')}
								onClick={() => {
									onTabChange && onTabChange('_' as any, 'approvisionnements');
								}}
								addStyles={{ width: '100%' }}
							/>
						}
						className={cx(styles.content, 'flex1')}
						loading={isLoading}
					>
						{dataTable?.length ? <SupplyLineBar data={dataTable} indicator={indicator} /> : null}
					</CardContent>
				</DashboardCard>

				<SupplyRsePerformance />
			</Grid>

			<Grid item flexGrow={1}>
				{/* <SupplierDataEntered /> */}

				{/* <SupplierMaturity /> */}

				{/* <SupplierCommitments /> */}

				<SupplierQuantityBlock supplier={supplier} />

				<MetaDataCard supplier={supplier} />
			</Grid>
		</Grid>
	);
};

export default Synthesis;
