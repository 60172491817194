import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { Checkbox, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CompanyFormInput, CompanyFormValidationSchema } from '@carbonmaps/shared/validations/company.validations';
import { createUpdateCompanyAction } from '@carbonmaps/ui/actions/company.actions';
import BCheckbox from '@carbonmaps/ui/components/saas/BCheckbox';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import { classes } from '@carbonmaps/ui/components/saas/Form/FormStyle';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import AlertStatic from '../../../components/Toast/AlertStatic';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

const CompanyForm = ({ toggle, currentCompany }: any) => {
	const theme = useTheme();
	const [message, setMessage] = useState('');
	const styles = classes(theme);
	const queryClient = useQueryClient();

	const [hasSupplierProduct, setHasSupplierProduct] = useState(true);
	const [hasSupplierIng, setHasSupplierIng] = useState(true);

	// translation
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isDirty, isValid },
		// watch,
		reset,
	} = useForm<CompanyFormInput>({
		resolver: zodResolver(CompanyFormValidationSchema),
		defaultValues: async () => {
			setHasSupplierProduct(currentCompany?.hasSupplier?.product || false);
			setHasSupplierIng(currentCompany?.hasSupplier?.ingredient || false);
			return {
				name: currentCompany?.name || '',
				objectId: currentCompany?.objectId || '',
				code: currentCompany?.code || '',
				maxUsers: currentCompany?.maxUsers || 15,
				hasSupplierIng: currentCompany?.hasSupplier?.ingredient || false,
				hasSupplierProduct: currentCompany?.hasSupplier?.product || false,
				fileLogo: currentCompany?.logUrl || '',
			};
		},
		mode: 'onChange',
	});

	const {
		mutate: createUpdateCompany,
		isError,
		isSuccess,
		isLoading,
	} = useMutation({
		mutationKey: ['createUpdateCompany'],
		mutationFn: createUpdateCompanyAction,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: ['getCompanies'] });
			toggle();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<CompanyFormInput> = async (values) => {
		createUpdateCompany({
			...values,
		} as any);
	};

	const handleChangeSupplierProduct = (event: any) => {
		setHasSupplierProduct((current: any) => {
			return !current;
		});
		setValue('hasSupplierProduct', event.target.checked);
	};

	const handleChangeSupplierIng = (event: any) => {
		setHasSupplierIng((current: any) => {
			return !current;
		});
		setValue('hasSupplierIng', event.target.checked);
	};

	return (
		<div css={{ marginBottom: 24 }}>
			<FormContainer
				loading={isLoading}
				title={currentCompany?.objectId ? t('form-company-edit-title') : t('form-company-add-title')}
				buttonTitle={t('button-save-company')}
				subtitle={t('company-logo-title')}
				avatar
				defaultLogo={currentCompany?.logoUrl}
				onSubmit={handleSubmit(onSubmit)}
				setFile={(file: any) => {
					setValue('fileLogo', file);
				}}
				isSuccess={isSuccess}
				isError={isError}
				withSave
				//	withCancel
				onCancel={() => {
					toggle();
				}}
				alertSuccess={
					<AlertStatic text={message} type={'success'} icon={<CheckIcon css={{ color: siteColors.green500 }} />} />
				}
				alertError={<AlertStatic text={message} type={'error'} icon={<ErrorIcon css={{ color: '#C74600' }} />} />}
				addStyles={{
					width: 510,
					// gap: 24,
					margin: 'auto',
				}}
				t={t}
			>
				<input type="hidden" {...register('objectId')} />

				<p css={styles.subtitle}>{t('about-company')}</p>

				<div
					className="flexColumn width100"
					css={{
						maxWidth: 350,
						gap: 24,
						'.input-container': {
							// width: '100%',
							width: 350,
						},
					}}
				>
					<BInput
						required
						id="name"
						label={t('company-name')}
						fullWidth
						margin="dense"
						{...register('name')}
						name="name"
						error={errors.name ? true : false}
						errorMessage={errors?.name?.message}
						// onChange={handleNameChange}
						autoComplete="off"
					/>
				</div>
				<div
					className="flexColumn width100"
					css={{
						maxWidth: 350,
						gap: 24,
						'.input-container': {
							width: 350,
						},
					}}
				>
					<BInput
						required
						id="code"
						label={t('company-code')}
						fullWidth
						margin="dense"
						{...register('code')}
						name="code"
						disabled={currentCompany?.objectId ? true : false}
						error={errors.code ? true : false}
						errorMessage={errors?.code?.message}
						autoComplete="off"
					/>
				</div>
				<div
					className="flexColumn width100"
					css={{
						maxWidth: 350,
						gap: 24,
						'.input-container': {
							width: 350,
						},
					}}
				>
					<BInput
						required
						id="maxUsers"
						label={t('company-max-user-text')}
						fullWidth
						margin="dense"
						{...register('maxUsers', { valueAsNumber: true })}
						name="maxUsers"
						error={errors.maxUsers ? true : false}
						errorMessage={errors?.maxUsers?.message}
						type="number"
						autoComplete="off"
					/>
				</div>

				<div
					className="flexColumn width100"
					css={{
						maxWidth: 450,
						//	gap: 24,
						'.input-container': {
							width: '100%',
						},
					}}
				>
					<BCheckbox
						id="hasSupplierProduct"
						label={t('has-suppliers-product-label', { products: toLowerCase(t('products')) })}
						{...register('hasSupplierProduct')}
						name="hasSupplier"
						errorMessage={errors?.hasSupplierProduct?.message}
						control={
							<Checkbox defaultChecked={true} checked={hasSupplierProduct} onChange={handleChangeSupplierProduct} />
						}
					/>
					<BCheckbox
						id="hasSupplierIng"
						label={t('has-suppliers-ingredient-label', {
							ingredient: toLowerCase(t('ingredient')),
							ingredients: toLowerCase(t('ingredients')),
							an_ingredient: t('an_ingredient'),
						})}
						{...register('hasSupplierIng')}
						name="hasSupplierIng"
						errorMessage={errors?.hasSupplierIng?.message}
						control={<Checkbox defaultChecked={true} checked={hasSupplierIng} onChange={handleChangeSupplierIng} />}
					/>
				</div>
			</FormContainer>
		</div>
	);
};

export default CompanyForm;
