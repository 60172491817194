import Dialog from '../../components/dialogs/Dialog';
import { useTranslation } from '../../hooks/useTranslation';

import QuoteClientForm from './QuoteClientForm';
type QuoteEditModalProps = {
	quote: any;
	onSaveData: (data: any) => void;
	open?: boolean;
	onClose: (e?: any) => void;
	isLoading?: boolean;
	options?: any;
	selectedOption?: any;
	setSelectedOption?: (v: any) => void;
	setSearch?: (v: any) => void;
};

const QuoteEditModal = ({
	quote,
	open = false,
	onClose,
	options,
	isLoading,
	selectedOption,
	setSelectedOption,
	onSaveData,
	setSearch,
}: QuoteEditModalProps) => {
	// translation
	const { t } = useTranslation();
	return (
		<Dialog
			title={''}
			open={open}
			toggle={onClose}
			isLoading={isLoading}
			paperProps={{
				width: '707px',
			}}
			withCancelButton={false}
		>
			<QuoteClientForm
				title={t('quote-form-update-title')}
				onClose={onClose}
				quote={quote}
				options={options}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				onSaveData={onSaveData}
				setSearch={setSearch}
			/>
		</Dialog>
	);
};

export default QuoteEditModal;
