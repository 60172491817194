import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import { Box, Carrot, PackageOpen } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { AUDIT_CONFIG, INGREDIENT, PACKAGING, PRODUCT } from '@carbonmaps/shared/utils/audit';
import { getSheetDataAudit, getSheetDataAuditTable } from '@carbonmaps/ui/actions/audit.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TableHeaderCell from '../../components/table/TableHeaderCell';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';

const styles = {
	titleClassName: css({
		maxWidth: '195px !important',
	}),
	emptyValueContainer: css({
		justifyContent: 'flex-start !important',
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'& table tr > th:last-child, table tr > td:last-child': {
			minWidth: '240px !important',
		},
	};
};

const AuditTable = () => {
	// translation
	const { t } = useTranslation();
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const params = useParams();
	const queryParams = useQueryParams();
	const [searchQueryParams] = useSearchQueryParams();

	const typeError = queryParams?.type_error?.[0];


	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	const { selectedYear } = useYearSelection();
	const fetchIdRef = useRef(0);
	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState({
		page: 1,
		size: 100,
	});
	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	// ---- fetch data audit ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getSheetDataAuditTable',
			{
				itemType: params.itemType,
				input: searchQueryParams?.input,
				facetFilters: (searchQueryParams?.facetFilters || []).filter((item: any) => {
					return item?.path !== 'type_error';
				}), //searchQueryParams.input
				// typeErrors: (searchQueryParams?.facetFilters || []).find((item: any) => {
				// 	return item?.path === 'type_error';
				// })?.values,
				typeErrors: typeError === 'volume' ? ["Erreur volume"] :
					(searchQueryParams?.facetFilters || []).find((item: any) => {
						return item?.path === 'type_error';
					})?.values,
				ids: searchQueryParams?.productId ? [searchQueryParams?.productId] : null,
				period: selectedYear,
				...paramsTable,
			},
		],
		queryFn: getSheetDataAuditTable,
	} as any);

	useEffect(() => {
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQueryParams]);

	const itemIcon = useMemo(() => {
		const itemType = params.itemType;

		if (itemType === INGREDIENT) return <Carrot color={siteColors.grey700} />;
		if (itemType === PACKAGING) return <PackageOpen color={siteColors.grey700} />;
		return <Box color={siteColors.grey700} />;
	}, [params.itemType]);

	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label="ID" />;
				},
				accessor: 'id', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'Text',
				type: 'string',
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							valueType="string"
							column={props.column}
							label={
								params.itemType === PRODUCT
									? t('product')
									: t('product-related-title', {
										product: t('product'),
										product_lowercase: toLowerCase(t('product')),
										of_product: t('of_product'),
									})
							}
						/>
					);
				},
				accessor: 'labelProduct', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'TextAuditDisplay',
				type: 'string',
				props: {
					withLink: true,
					titleClassName: styles.titleClassName,
					// icon: params.itemType === PRODUCT ? itemIcon : null,
					// icon: itemIcon,
				},
			},

			...(params.itemType === INGREDIENT
				? [
					{
						Header: (props: any) => {
							return <TableHeaderCell valueType="string" column={props.column} label={t('ingredient')} />;
						},
						accessor: 'labelIngredient', // accessor is the "key" in the data
						editable: true,
						sortDescFirst: true,
						component: 'TextAuditDisplay',
						type: 'string',
						props: {
							labelKey: 'labelIngredient',
							uidKey: 'uidIngredient',
						},
					},
					{
						// Header: 'PRÉSENCe',
						Header: (props: any) => {
							return <TableHeaderCell column={props.column} label={t('presence')} /* measure="carbonIntensity" */ />;
						},
						accessor: 'composition',
						editable: true,
						sortDescFirst: true,
						component: 'Percentage',
						type: 'string',
						props: {
							className: styles.emptyValueContainer,
						},
					},
				]
				: []),

			...(params.itemType === PACKAGING
				? [
					{
						Header: (props: any) => {
							return <TableHeaderCell valueType="string" column={props.column} label={t('Emballage')} />;
						},
						accessor: 'labelPackaging', // accessor is the "key" in the data
						editable: true,
						sortDescFirst: true,
						component: 'TextAuditDisplay',
						type: 'string',
						props: {
							labelKey: 'labelPackaging',
							uidKey: 'uidPackaging',
						},
					},
				]
				: []),

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('type_error_audit')} />;
				},
				accessor: 'errorType',
				editable: true,
				sortDescFirst: true,
				component: 'ErrorTypeField',
				type: 'string',
				props: {
					trans: t,
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('detail_dataset')} />;
				},
				accessor: 'content',
				editable: true,
				sortDescFirst: true,
				component: 'AuditContentField',
				type: 'string',
				disableSortBy: true,
				props: {
					trans: t,
				},
			},
		];
	}, [t]);

	return (
		<TableComponent
			skeleton={isLoading}
			loading={false}
			pageCount={(data as any)?.meta?.last_page || 0}
			noDataMessage={t(AUDIT_CONFIG.get(params.itemType)?.emptyData, {
				product: toLowerCase(t('product')),
				products: toLowerCase(t('products')),
				ingredient: toLowerCase(t('ingredient')),
				ingredients: toLowerCase(t('ingredients')),
				of_product: t('of_product'),
				an_ingredient: t('an_ingredient'),
			})}
			pageSize={100}
			columns={tableColumns}
			data={((data as any)?.results || []) as any}
			addStyles={stylesTable}
			resetPage={resetPage}
			transFunc={t}
			fetchData={updateTableParams}
			onSortingColumn={handleSortingTable}
		/>
	);
};

export default AuditTable;
