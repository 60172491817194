import { FileCheck } from 'lucide-react';

import { APBO_CURRENT_YEAR, MESSAGE_KEY_APBO } from '@carbonmaps/shared/utils/constants';

import ApboCard from '../../components/apbo/card/ApboCard';
import DiagStatCard from '../../components/apbo/card/DiagStatCard';
import Comment from '../../components/apbo/Comment';
import Card from '../../components/widget/Card';

import { siteColors } from '../../lib/colors';
import { useDiagStat } from './useApbo.hook';

const DiagStatView = () => {
	// ---- fetch data ---- //
	const { data, isLoading } = useDiagStat();

	return (
		<ApboCard messageKey={MESSAGE_KEY_APBO.answer}>
			<Card
				title={'Diagnostics Cap’2ER réalisés'}
				icon={<FileCheck size={20} color={siteColors.grey700} />}
				withBorder={false}
				center={false}
				sx={{
					padding: '0px !important',
					width: '100%',
				}}
				rightContent={<Comment messageKey={MESSAGE_KEY_APBO.answer} />}
			>
				<div className="flexRow gap16 width100  nowrap ">
					<DiagStatCard
						className="flex1"
						title={'Tous les diagnostics'}
						isLoading={isLoading}
						item={data?.allDiagStat}
					/>
					<DiagStatCard
						className="flex1"
						title={'Diagnostics sur année N'}
						isLoading={isLoading}
						// item={data?.lastDiagStat}
						item={{
							firstDiag: 16,
							secondDiag: 95,
							total: 111,
						}}
					/>
				</div>
			</Card>
		</ApboCard>
	);
};

export default DiagStatView;
