import { ReactNode, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

const Page404 = ({ message }: { message?: ReactNode }) => {
	const { setBreadcrumbs } = useApp();
	const location = useLocation();

	useEffect(() => {
		setBreadcrumbs([
			{
				text: 'Page introuvable',
			},
		]);
	}, [setBreadcrumbs]);

	return (
		// <div
		// 	css={{
		// 		width: '300px',
		// 		margin: '0 auto',
		// 	}}
		// >
		// 	Example not found page
		// </div>
		<div
			css={{
				maxWidth: '750px',
				margin: '0 auto',
			}}
		>
			{message ? message : <h2>La page: '{location.pathname}' n'existe pas</h2>}
		</div>
	);
};

export default Page404;
