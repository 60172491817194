import React from 'react';

import { css, cx } from '@emotion/css';
import { use } from 'i18next';

import { useGlossary } from '../../../hooks/useGlossary';
import { cn, siteColors } from '../../../lib/colors';
import LightTooltipGlossary from '../../LightTooltipGlossary';
import Typography from '../../Typography';

const styles = {
	container: css({
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '0 8px',
		textAlign: 'right',
		width: '100%',
	}),
	label: css({
		color: cn(siteColors.grey800, '!important'),
	}),
	tooltipContainer: css({
		padding: 24,
		maxWidth: 380,
	}),
};

type DashboardTooltipProps = {
	label: any;
	content: React.ReactNode;
};

const DashboardTooltip = ({ label, content }: DashboardTooltipProps) => {
	const { g } = useGlossary();
	return (
		<Typography variant="caption" className={cx('underline ', styles.container)}>
			<LightTooltipGlossary title={g(content as any)}>{label}</LightTooltipGlossary>
		</Typography>
	);
};

export default DashboardTooltip;
