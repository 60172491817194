import { useCallback, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Widget } from '@typeform/embed-react';
import { CheckCircle2 } from 'lucide-react';

// import { ReactComponent as CircleCheck } from '@carbonmaps/media/icons/check-circle-2.svg';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import Container from '../../components/layout/list/Container';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import {
	getDataFormQuestionnaireAction,
	saveResponseQuestionnaireAction,
} from '../../lib/react-query/features/question/question.actions';

import { classNames } from '@carbonmaps/shared/utils/constants';
import _ from 'lodash';
import { siteColors } from '../../lib/colors';
import QuestionnaireHeader from './QuestionnaireHeader';

const styles = {
	root: css({
		//
		width: 700,
		margin: '0 auto',
		padding: '48px 32px',

		gap: 32,
	}),

	text1: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',

		marginLeft: 16,
	}),
};

const Questionnaire = (/* props: Props */) => {
	const { setBreadcrumbs } = useApp();
	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);
	const [showTypeForm, setShowTypeForm] = useState(true);
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Survey' },
			});
		},
		[analytics],
	);

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('quiz-breadcrumbs') }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	const {
		mutate: saveResponseQuestionnaire,
		isError,
		isLoading,
		isSuccess,
	} = useMutation({
		mutationKey: ['saveResponseQuestionnaireCampaign'],
		mutationFn: saveResponseQuestionnaireAction,
		onSuccess: async () => {
			console.log('save Response Questionnaire');
		},
		onError: (error: any) => {
			if (error.message) {
				// setTimeout(() => {
				// 	setMessage('');
				// }, 3000);
			}
		},
	});

	const onSubmit = (values: any) => {
		setShowTypeForm(false);
		saveResponseQuestionnaire({ languageCode });
		trackEvent('Supplier Survey Completed', {
			Email: authData?.user?.email,
			Survey: 'Questionnaire maturité RSE',
		});
	};

	// track event 	when the user lands on the Questionnaire listing page
	useEffect(() => {
		trackEvent('Supplier Survey Land', {
			Email: authData?.user?.email,
			Survey: 'Questionnaire maturité RSE',
		});
	}, [trackEvent]);

	// ---- fetch data ---- //
	// get emailAdmin and formId
	const { data, isLoading: isLoadingTypeFormId } = useQuery({
		queryKey: ['getDataFormQuestionnaire'],
		queryFn: getDataFormQuestionnaireAction,
	});

	const _supplierUid = (_.get(authData, 'user.supplierUids', []) as string[]).find((e) => {
		const [uid, companyId] = e.split('_$_');
		return companyId === _.get(authData, 'session.company.objectId');
	});
	const [supplierUid, companyId] = _supplierUid ? _supplierUid.split('_$_') : '';

	// const { data: supplierData } = useFindSupplierByUid({ supplierUid, companyId });

	// const supplierName = _.toString(_.get(supplierData, 'name'));
	const questionFormIdFromData = _.toString(_.get(data, 'questionFormId'));
	const supplierNameFromData = _.toString(_.get(data, 'supplierName'));
	const supplierUidFromData = _.toString(_.get(data, 'supplierUid'));

	return (
		<Container header={<QuestionnaireHeader />} graph={<></>} seoText={`${t('Questionnaire')} - Carbon Maps`}>
			{showTypeForm &&
				data?.formId &&
				!isLoadingTypeFormId &&
				(data?.published === true ? (
					<Widget
						id={data?.formId}
						hidden={{
							company_id: companyId,
							// supplier_uid: supplierUid,
							supplier_uid: supplierUidFromData,
							supplier_name: supplierNameFromData,
							questionform_id: questionFormIdFromData,
							// contact_email: _.toString(authData?.user?.email),
						}}
						style={{ width: '100%', height: '600px' }}
						className="my-form"
						onSubmit={onSubmit}
					/>
				) : (
					<div className={cx('flexColumn', styles.root)}>
						<div className={cx('flexColumn alignCenter width100 textAlignCenter gap16')}>
							<div className={cx('', styles.text1)}>{t('Cette campagne est désormais fermée')}</div>
						</div>
					</div>
				))}
			{isSuccess && (
				<div className={cx('flexColumn', styles.root)}>
					<div className={cx('alignCenter width100 textAlignCenter')}>
						<CheckCircle2 size={33} color={siteColors.green500} />
					</div>
					<div className={cx('flexColumn alignCenter width100 textAlignCenter gap16')}>
						<div className={cx('', styles.text1)}>{t('Réponse enregistrée')}</div>
						<div> {t('questionnaire-thanks')}</div>
					</div>
				</div>
			)}
		</Container>
	);
};

export default Questionnaire;

// export const useFindSupplierByUid = ({ supplierUid, companyId }: { supplierUid: string; companyId: string }) => {
// 	return useQuery({
// 		queryKey: ['FindSupplierByUid', { supplierUid, companyId }] as const,
// 		queryFn: async ({ queryKey }: any) => {
// 			const { supplierUid } = queryKey[1];

// 			const companyObj = new Parse.Object(classNames.COMPANY);
// 			companyObj.id = companyId;

// 			const query = new Parse.Query(classNames.SUPPLIER)
// 				.equalTo('uid', supplierUid)
// 				.equalTo('company', companyObj)
// 				.select(['name']);
// 			const supplier = await query.first();

// 			return supplier?.toJSON();
// 		},
// 	});
// };
