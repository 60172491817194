import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import Diff from '../../../../../apps/front/src/components/sales/Diff';
import Difference from '../../../../../apps/front/src/containers/scenario/Difference';
import { translation } from '../../../i18n/translation';

import Lining from '../../../../../apps/front/src/components/sales/Lining';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import { useApp } from '../../../hooks/useApp';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};


const VsMarket = ({
	value,
	row,
	column,
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const theme = useTheme();
	const isEqual = ['0.00', '0,00', 0.0].includes(formatNumber(Math.abs(value), '', 2)) || value === 0;
	const isPositiveValue = value >= 0;
	const t = column?.props?.t || translation;
	const { indicator } = useApp();

	if (row.original.isFromQuote && column.props?.page === 'report-client') {
		return <Lining />
	}

	if (value === 0) {
		return <div></div>;
	}

	return <Diff data={row.original} value={value} indicator={indicator} />;







};

export default VsMarket;
