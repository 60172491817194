import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';

import PopoverItem from '../../../../../apps/front/src/components/table/popover/PopoverItem';
import Unit from '../../../../../apps/front/src/components/units/Unit';
import { siteColors } from '../../../../../apps/front/src/lib/colors';
import { useApp } from '../../../hooks/useApp';
import { displayValue } from '../../../utils/numberFormat';
import BPopover from '../BPopover';
const styles = {
	acvDeltaContainer: css({
		width: 'fit-content',
	}),

	displayBarContainer: css({
		height: '6px',
		width: '202px',
		borderRadius: '32px',
		background: siteColors.grey400,
	}),
	displayBarContent: css({
		height: '100%',
		borderRadius: '32px',
	}),

	lineValue: css({
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
		width: '42px',
		marginRight: '10px',
	}),
	popoverContainer: css({
		padding: 24,
	}),
};

const AcvDelta = (props: any) => {
	const { row, column } = props;

	const { indicator } = useApp();

	const n1Value = row.original[indicator === CARBONE_INDICATOR ? 'ges' : 'water'];
	const n2Value = row.original[indicator === CARBONE_INDICATOR ? 'gesN2' : 'waterN2'];

	const percentageN1 = row?.original?.percentageN1 || 0;
	const percentageN2 = row?.original?.percentageN2 || 0;

	const transFunction = () => {
		console.log('test');
	};

	const t = column?.props?.trans || transFunction;

	return (
		<div className={cx('flexRow', styles.acvDeltaContainer)}>
			{(column?.props?.page !== 'ingredientAcv' ||
				(column?.props?.page === 'ingredientAcv' && row?.original?.level !== 2)) && (
				<LineDisplay
					value={n1Value}
					color={siteColors.carbon100}
					percentage={percentageN1}
					title={'acv-generic'}
					t={t}
				/>
			)}
			<LineDisplay
				value={n2Value}
				color={siteColors.primary}
				percentage={percentageN2}
				title={'acv-specific-title'}
				t={t}
			/>
		</div>
	);
};

export default AcvDelta;

type LineDisplayProps = {
	value: number;
	color: string;
	percentage: number;
	title?: string;
	t?: any;
};

const LineDisplay = ({ t, title, value, percentage, color }: LineDisplayProps) => {
	return (
		<BPopover
			anchorOriginHorizontal={'right'}
			transformOriginVertical={'top'}
			transformOriginHorizontal={'right'}
			anchorOriginVertical={'bottom'}
			modeHover
			content={(onClose) => {
				return (
					<div className={cx(styles.popoverContainer)}>
						<PopoverItem
							label={t(title) || ''}
							value={
								<>
									{displayValue(value)} <Unit measure="carbonIntensityKg" />
								</>
							}
						/>
					</div>
				);
			}}
		>
			<div className={cx('flexRow alignCenter nowrap')}>
				{/* <div className={cx('flexRow justifyEnd', styles.lineValue)}>{displayValue(value)}</div> */}
				<div className={cx('flexRow justifyEnd', styles.lineValue)}>
					<Typography color={color} variant="body1">
						{displayValue(value)}
					</Typography>
				</div>
				<div className={cx('flexRow', styles.displayBarContainer)}>
					<div
						className={cx('', styles.displayBarContent)}
						style={{
							width: `${percentage}%`,
							background: color,
						}}
					/>
				</div>
			</div>
		</BPopover>
	);
};
