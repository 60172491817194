import React from 'react';

import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '../../Typography';
import Unit from '../../units/Unit';

type DashboardGraphTitleProps = {
	title: string;
	measure?: any;
	rightContent?: React.ReactNode;
};

const DashboardGraphTitle = ({ title, measure, rightContent }: DashboardGraphTitleProps) => {
	const { t } = useTranslation();
	return (
		<div className="flexRow width100 spaceBetween gap16 alignCenter">
			<Typography className="fontWeight600">
				{t(title)} {measure ? <Unit measure={measure} className={'grey800'} /> : null}
			</Typography>
			{rightContent}
		</div>
	);
};

export default DashboardGraphTitle;
