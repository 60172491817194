import { ReactComponent as CarboneImpactIcon } from '@carbonmaps/media/icons/carbon-impact.svg';
import { ReactComponent as IntensityIcon } from '@carbonmaps/media/icons/carbon-intensity.svg';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

export const ACCUMULATOR_NUMBER_FACET = 25;


export const TOP_BAR_HEIGHT = pxToRem(58);
export const SIDEBAR_WIDTH = pxToRem(280);

// ----------------------------------------------------------- //
// ------------------- view mode options --------------------- //
// ----------------------------------------------------------- //

export const CARBONE_IMPACT_VIEW = 'carbonImpact';
export const INTENSITY_VIEW = 'intensity';
export const WATER_IMPACT_VIEW = 'waterImpact';

export const MODE_VIEW_OPTIONS = [
	{
		label: 'Impact total',
		extraLabel: 'Données ramenées au % du portefeuille',
		value: CARBONE_IMPACT_VIEW,
		icon: CarboneImpactIcon,
	},
	{
		label: 'Intensité',
		extraLabel: 'Données ramenées au kilo de produit',
		value: INTENSITY_VIEW,
		icon: IntensityIcon,
	},
];

export const CARBONE_INDICATOR = 'carbon';
export const WATER_INDICATOR = 'water';

export const MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY = 10_000;
export const MAX_NUMBER_OF_INGREDIENTS_TO_DISPLAY = 10_000;
export const MAX_NUMBER_OF_PACKAGING_TO_DISPLAY = 10_000;
export const MAX_NUMBER_OF_SUPPLIERS_TO_DISPLAY = 10_000;
