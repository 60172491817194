import { useMemo } from 'react';

import { Cloud, Fingerprint, Milk, PieChart, Trees } from 'lucide-react';

import { MESSAGE_KEY_APBO } from '@carbonmaps/shared/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import ApboCard from '../../components/apbo/card/ApboCard';
import Comment from '../../components/apbo/Comment';
import KpiCard from '../../components/apbo/KpiCard';
import Card from '../../components/widget/Card';
import { compactNumberFormat } from '../../utils/num.utils';

import { siteColors } from '../../lib/colors';
import { useGlobalIndicator, useYearOptions } from './useApbo.hook';

const dataConfig = [
	{
		title: 'Quantité de lait',
		unit: 'kg FPCM',
		icon: PieChart,
		formatCallback: compactNumberFormat,
		key: 'totalQuantity',
	},
	{
		title: 'Empreinte brute',
		unit: 'kg ₑCO₂ / kg FPCM',
		icon: Cloud,
		key: 'totalGrossEmissions',
		formatCallback: (v: number) => {
			return formatNumber(v, undefined, 2);
		},
	},
	{
		title: 'Empreinte nette',
		unit: 'kg ₑCO₂ / kg FPCM',
		icon: Fingerprint,
		key: 'totalGesNets',
		formatCallback: (v: number) => {
			return formatNumber(v, undefined, 2);
		},
	},
	{
		title: 'Stockage carbone',
		unit: 'kg ₑCO₂ / kg FPCM',
		icon: Trees,
		key: 'totalCarbonStorage',
		formatCallback: (v: number) => {
			return formatNumber(v, undefined, 2);
		},
	},
];

const GlobalIndicator = () => {
	// ---- current year ---- //
	const { value } = useYearOptions();
	// ---- fetch data ---- //
	const { data, isLoading } = useGlobalIndicator();

	const items = useMemo(() => {
		if (!data) return dataConfig;

		return dataConfig.map((item: any) => {
			return {
				...item,
				value: item.formatCallback(data[item.key]),
			};
		});
	}, [data]);

	return (
		<ApboCard messageKey={MESSAGE_KEY_APBO.globalIndicator}>
			<Card
				title={'Indicateurs généraux de performance'}
				icon={<Milk size={20} color={siteColors.grey700} />}
				withBorder={false}
				center={false}
				sx={{
					padding: '0px !important',
					width: '100%',
				}}
				rightContent={<Comment messageKey={MESSAGE_KEY_APBO.globalIndicator} />}
			>
				<div className="flexRow gap16 width100 stretch">
					{items.map((item: any, index: number) => {
						return (
							<KpiCard
								title={item.title}
								value={item.value}
								icon={item.icon}
								unit={item.unit}
								key={index}
								isLoading={isLoading}
							/>
						);
					})}
				</div>
			</Card>
		</ApboCard>
	);
};

export default GlobalIndicator;
