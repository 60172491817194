import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { useGetProductSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetProductSheet';

import 'dayjs/locale/fr';
import MetaDataCard from '../../../components/card/MetaDataCard';

dayjs.locale('fr');

const MetaDataBlock = (/* props: Props */) => {
	const params = useParams();

	const { data } = useGetProductSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		//period: 2023,
	});
	return <MetaDataCard importedOn={data?.product?.createdAt} updatedAt={data?.product?.updatedAt} />;
};

export default MetaDataBlock;
