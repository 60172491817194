import React from 'react';

import { cx } from '@emotion/css';

import Typography from '../../Typography';

type BlockCardProps = {
	title: string;
	children: React.ReactNode;
	className?: string;
};

const BlockCard = ({ title, children, className }: BlockCardProps) => {
	return (
		<div className="flexColumn width100 gap24">
			<Typography className={cx('fontWeight600', className)}>{title}</Typography>
			{children}
		</div>
	);
};

export default BlockCard;
