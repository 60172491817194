import _ from 'lodash';

import { Indicator } from '@carbonmaps/ui/contexts/AppProvider';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { orderBy } from '../utils/array.utils';
import { FULFILLED_MODELIZATION_TYPE, PARTIAL_DATA_MODELIZATION_TYPE } from '../utils/supplier';

import IngredientModel from './Ingredient.model';
import SupplierModel from './Supplier.model';

export default class ProductModel {
	product: any;

	constructor(product: any) {
		this.product = product || {};

		if (product && !this.product?.suppliers) {
			this.product.suppliers = [];
		}
	}

	isN2ModelizationType() {
		return this.product?.tagAdvancedModelization === 'yes' || this.product?.tagAdvanced === 'yes';
	}

	getLabel() {
		return this.product.label;
	}

	getIntensity(field = 'gesTotal') {
		return this.isN2ModelizationType()
			? this.product[`${field}N2`] || this.product[`${field}`]
			: this.product[`${field}`];
	}

	getIngredients() {
		return this.product?.ingredients || [];
	}

	getSuppliers() {
		const suppliers = (this.product?.suppliers || [])?.map((item: any) => {
			const supplier = item.supplier.toJSON();

			const isChecked =
				supplier?.onboarding === FULFILLED_MODELIZATION_TYPE || supplier?.onboarding === PARTIAL_DATA_MODELIZATION_TYPE;

			return {
				...supplier,
				checked: isChecked ? 1 : 0,
			};
		});
		return orderBy(suppliers, 'checked').map((supplier) => {
			return new SupplierModel(supplier);
		});
	}

	getGesIngredient(withTransformation = true) {
		if (this.isN2ModelizationType()) {
			return (this.product?.gesAgriculturesN2 ?? 0) + (withTransformation ? this.product?.gesTransformationN2 ?? 0 : 0);
		}

		return (this.product?.gesAgricultures ?? 0) + (withTransformation ? this.product?.gesTransformation ?? 0 : 0);
	}

	getGesIngredientV2() {
		let sumGesIngredientsUpstream = 0;
		this.product?.ingredients?.forEach((ing: any, index: number) => {
			const ingredientModel = new IngredientModel(ing?.ingredient?.toJSON());

			const isN2 = ingredientModel.isN2ModelizationType();
			const gesAgriculture = isN2
				? ing?.gesAgricultureWeightedN2 || ing?.gesWeightedAgricultureN2 || ing?.gesWeightedAgriculture || 0
				: ing?.gesAgricultureWeightedN1 || ing?.gesWeightedAgricultureN1 || ing?.gesWeightedAgriculture || 0;

			const gesTransformation = isN2
				? ing?.gesWeightedTransformationUpstreamN2 ||
				ing?.gesUpstreamTransformationWeightedN2 ||
				ing?.gesWeightedTransformationUpstream ||
				0
				: ing?.gesWeightedTransformationUpstreamN1 ||
				ing?.gesUpstreamTransformationWeightedN1 ||
				ing?.gesWeightedTransformationUpstream ||
				0;
			sumGesIngredientsUpstream += gesAgriculture + gesTransformation;
		});

		return sumGesIngredientsUpstream;
	}

	getWaterIngredientV2() {
		// const gesIngredient = this.getIntensity('waterUseAgricultures') + this?.getIntensity('waterUseTransformation');

		// return gesIngredient;
		let sumWaterIngredientsUpstream = 0;

		this.product?.ingredients?.forEach((ing: any, index: number) => {
			const ingredientModel = new IngredientModel(ing?.ingredient?.toJSON());

			const isN2 = ingredientModel.isN2ModelizationType();
			const waterUseAgriculture = isN2
				? ing?.waterUseAgricultureWeightedN2 || ing?.waterUseWeightedAgricultureN2 || ing?.waterUseAgricultureWeighted || ing?.waterUseWeightedAgriculture || 0
				: ing?.waterUseAgricultureWeightedN1 || ing?.waterUseWeightedAgricultureN1 || ing?.waterUseAgricultureWeighted || ing?.waterUseWeightedAgriculture || 0;

			const waterUseTransformation = isN2
				? ing?.waterUseWeightedTransformationUpstreamN2 || ing?.waterUseUpstreamTransformationWeightedN2 || ing?.waterUseWeightedTransformationUpstream || ing?.waterUseUpstreamTransformationWeighted || 0
				: ing?.waterUseWeightedTransformationUpstreamN1 || ing?.waterUseUpstreamTransformationWeightedN1 || ing?.waterUseWeightedTransformationUpstream || ing?.waterUseUpstreamTransformationWeighted || 0;
			sumWaterIngredientsUpstream += waterUseAgriculture + waterUseTransformation;
		});

		return sumWaterIngredientsUpstream;
	}

	getWaterIngredient(withTransformation = true) {
		if (this.isN2ModelizationType()) {
			return (
				(this.product?.waterUseAgriculturesN2 ?? 0) +
				(withTransformation ? this.product?.waterUseTransformationN2 ?? 0 : 0)
			);
		}

		return (
			(this.product?.waterUseAgricultures ?? 0) + (withTransformation ? this.product?.waterUseTransformation ?? 0 : 0)
		);
	}

	getCarbonImpactByIngredient(cmapsCode: string) {
		const tonnage = this.getTonnageProductByIngredient(cmapsCode);
		return this.getIntensity() * tonnage;
	}

	getTonnageProductByIngredient(cmapsCode: string) {
		const ingredients = this.product?.ingredients;
		let tonnage = 0;
		ingredients?.forEach((item: any) => {
			if (item.ingredient.get('codeIngCmaps') === cmapsCode) {
				tonnage = tonnage + (item.ingredient.get('tonnageProduct') || 0) / 100;
			}
		});

		return tonnage;
	}

	getPackagingWeight(packagingId: string) {
		const packaging = this.product?.emballages;
		let poids = 0;
		packaging?.forEach((item: any) => {
			if (item.packaging.id === packagingId) {
				poids = poids + (item.weight || 0);
			}
		});
		return poids;
	}

	getVsReference(indicator: 'carbon' | 'water' = 'carbon') {
		if (indicator === 'carbon') {
			return this.product.VSReference;
		}

		if (this.isN2ModelizationType()) {
			return this.product.waterUseTotalN2 - this.product.waterUseTotalRefN2;
		}

		return this.product.waterUseTotal - this.product.waterUseTotalRef;
	}

	getImpactByIndicator(indicator: Indicator, returnAsNumber = false): string | number | undefined {
		let impact;

		if (indicator === 'carbon') {
			impact = parseFloat(this.product.carbonImpact);

			if (!returnAsNumber) {
				impact = formatNumber(impact, undefined, 2);
			}
		} else if (indicator === 'water') {
			impact = parseFloat(this.product.waterImpact);

			if (!returnAsNumber) {
				impact = formatNumber(impact, undefined, 2);
			}
		}

		return impact;
	}

	getImpactPortionByIndicator(indicator: Indicator, returnAsNumber = false): string | number | undefined {
		let impactPortion;

		if (indicator === 'carbon') {
			impactPortion =
				(parseFloat(this.isN2ModelizationType() ? this.product.gesTotalN2 : this.product.gesTotal) *
					parseFloat(this.product.netWeight)) /
				1000;
		} else if (indicator === 'water') {
			impactPortion =
				(parseFloat(this.isN2ModelizationType() ? this.product.waterUseTotalN2 : this.product.waterUseTotal) *
					parseFloat(this.product.netWeight)) /
				1000;
		}

		if (returnAsNumber) {
			return impactPortion;
		}

		return formatNumber(impactPortion, '', 2);
	}

	getIntensityByIndicator(indicator: Indicator, returnAsNumber = false): string | number | undefined {
		let intensity;

		if (indicator === 'carbon') {
			intensity = parseFloat(this.product.carbonIntensity);

			if (!returnAsNumber) {
				intensity = formatNumber(intensity, undefined, 2);
			}
		} else if (indicator === 'water') {
			intensity = parseFloat(this.product.waterIntensity);

			if (!returnAsNumber) {
				intensity = formatNumber(intensity, undefined, 2);
			}
		}

		return intensity;
	}

	getSuppliersCount(onboarding = 0) {
		if (onboarding === FULFILLED_MODELIZATION_TYPE) {
			const suppliersFullFilled = this.product?.suppliers?.filter((e: any) => {
				return (
					e.supplier.get('onboarding') === FULFILLED_MODELIZATION_TYPE ||
					e.supplier.get('onboarding') === PARTIAL_DATA_MODELIZATION_TYPE
				);
			});
			return suppliersFullFilled?.length || 0;
		}

		return this.product?.suppliers?.length;
	}

	getSupplierFullFilledPercent() {
		const totalSupplier = this.product?.suppliers?.length || 0;
		let fullFilledCount = 0;
		this.product?.suppliers?.forEach((e: any) => {
			if (
				e.supplier.get('onboarding') === FULFILLED_MODELIZATION_TYPE ||
				e.supplier.get('onboarding') === PARTIAL_DATA_MODELIZATION_TYPE
			) {
				fullFilledCount = fullFilledCount + 1;
			}
		});
		const percent = (fullFilledCount * 100) / totalSupplier;
		return `${formatNumber(percent, undefined, 0)}%`;
	}

	getSupplierImpactFullFilledPercent(indicator: 'water' | 'carbon' = 'carbon') {
		// impact for product //

		let percentProduct = 0;

		let impactTotal = 0;
		let impactSupplierTotal = 0;
		const impactKeyField = indicator === 'carbon' ? 'carbonImpact' : 'waterImpact';

		this.product?.suppliers?.forEach((e: any) => {
			impactTotal = impactTotal + e[impactKeyField];

			if (
				e.supplier.get('onboarding') === FULFILLED_MODELIZATION_TYPE ||
				e.supplier.get('onboarding') === PARTIAL_DATA_MODELIZATION_TYPE
			) {
				impactSupplierTotal = impactSupplierTotal + e[impactKeyField];

				if (!e[impactKeyField]) {
					percentProduct = percentProduct + e.proportion;
				}
			}
		});

		const percent = percentProduct ? percentProduct : (impactSupplierTotal * 100) / impactTotal;
		return `${formatNumber(percent, undefined, 0)}%`;
	}

	getNetWeightProduct() {
		return this.product.netWeight;
	}
}
