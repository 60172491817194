import SupplierModel from '../../models/Supplier.model';
import MetaDataCard from '../card/MetaDataCard';

type MetaDataCardProps = {
	supplier?: SupplierModel;
};

const MetaDataCardSupplier = ({ supplier }: MetaDataCardProps) => {
	return <MetaDataCard importedOn={supplier?.getCreatedAt()} updatedAt={supplier?.getUpdatedAt()} />;
};

export default MetaDataCardSupplier;
