import { useCallback, useEffect } from 'react';

import { create } from 'zustand';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { CARBONE_INDICATOR, MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY } from '../../../utils/constants';

import { productCompanyCount } from '@carbonmaps/ui/actions/product.actions';
import { useQuery } from '@tanstack/react-query';
import { useYearSelection } from '../../../hooks/useImpactYear';
import ClientReportsFilter from './ClientReportsFilter';
import ClientReportsHeader from './ClientReportsHeader';
import ClientReportsTable from './ClientReportsTable';

// eslint-disable-next-line react-refresh/only-export-components
export const useStore = create((set: any) => {
	return {
		selectedRow: [] as any,
		onSelectionChange: (changedRows: any) => {
			return set((state: any) => {
				return {
					...state,
					selectedRow: changedRows,
				};
			});
		},
	};
});

const ClientReports = (/* props: Props */) => {
	const { indicator, setIndicator, setBreadcrumbs } = useApp();
	const { t } = useTranslation();

	const { selectedYear } = useYearSelection();

	// init filters values
	const [searchQueryParams] = useSearchQueryParams();
	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('rapports-client') }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	// ---- init switch mode with carbon ---- //
	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBONE_INDICATOR);
	}, [setIndicator, indicator]);

	// track event 	when the user lands on the ClientReports listing page
	useEffect(() => {
		trackEvent('Explore Land ClientReports');
	}, [trackEvent]);


	// get count product for a company. this count will appear in header list
	//for Remove ClientReports graph for dataset of more than 10k products
	const { data: productCount, isLoading } = useQuery({
		queryKey: ['getProductCompanyCount', { period: selectedYear }],
		queryFn: productCompanyCount,
	});

	console.log(productCount < MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY)

	return (
		<Container
			header={<ClientReportsHeader />}
			graph={null}
			actionFilter={<ClientReportsFilter withMore={false} />}
			seoText={`${t('rapports-client')} - Carbon Maps`}
		>
			<ClientReportsTable filterOptions={searchQueryParams} manualSortBy />
		</Container>
	);
};

export default ClientReports;
