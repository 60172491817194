import { useMemo } from 'react';

import { css } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { ChevronDown } from 'lucide-react';

import { useImpactYear, useYearSelection } from '../../hooks/useImpactYear';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import SelectField from '../form/fields/SelectField';

const styles = {
	container: css({
		minWidth: '100px',
		minHeight: '42px',
		backgroundColor: 'rgb(236, 233, 242) !important',
		'.MuiInputBase-root': {
			width: '150px',
			minHeight: '42px',
			backgroundColor: 'rgb(236, 233, 242) !important',
		},
	}),
	text: css({
		fontSize: '16px !important',
		fontWeight: '500 !important',
	}),
};

const SelectYear = () => {
	//const { t } = useTranslation();
	//const analytics = useJuneTrack();
	//todo add track mapiononona
	// const track = useCallback(() => {
	// 	analytics.track(
	// 		'Track year selection',

	// 		{
	// 			// Add the GROUP_ID here to track this event on behalf of a workspace
	// 			context: { category: 'General' },
	// 		},
	// 	);
	// }, [analytics]);

	// fetch data
	const { data, isLoading, isSuccess, isError, error } = useImpactYear();
	const { selectedYear, setSelectedYear } = useYearSelection();
	const currentYear = new Date();

	const {
		result: { data: dataOptions },
	} = useGetClientAuthQuery();

	const years_options = useMemo(() => {
		if (!dataOptions) return [];

		const yearOptions = (dataOptions as any)?.yearOptions;

		if (!yearOptions?.length) {
			setSelectedYear(null);
			return [];
		}

		// set current year for the last year
		setSelectedYear(Math.max(...yearOptions));
		return yearOptions;
	}, [dataOptions, setSelectedYear]);

	if (isSuccess && data?.length > 0) {
		return (
			<SelectField
				value={selectedYear || (currentYear.getFullYear() as any)}
				items={data?.map((item: any) => {
					return {
						...item,
						label: item?.toString(),
						value: item,
					};
				})}
				renderValue={(item: any) => {
					return <Typography className={styles.text}>{item?.label}</Typography>;
				}}
				onChange={(e) => {
					// track(); //todo add track
					setSelectedYear(e);
				}}
				className={styles.container}
				paddingRight={24}
				ExpandMoreRoundedIcon={ChevronDown}
				iconColor={siteColors.grey700}
				// MenuProps={{
				//   disableScrollLock: true,
				// }}
			/>
		);
	}

	return;
};

export default SelectYear;
