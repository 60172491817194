import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import _, { set } from 'lodash';
import { AlertCircle, Check, Files, Pencil } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { QuoteFormInput, QuoteFormValidationSchema } from '@carbonmaps/shared/validations/quote.validations';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';

import SearchAutocomplete from '../../../components/SearchAutocompleteV2';
import { useTranslation } from '../../../hooks/useTranslation';

import AlertStatic from '../../../components/Toast/AlertStatic';
import { cn, siteColors } from '../../../lib/colors';
import { useQuoteFormStep } from './useDevisFormStore';

const styles = {
	title: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
		color: siteColors.primary,
		margin: 0,
	}) as any,
	subTitle: css({
		marginTop: '0px',
		fontSize: '14px !important',
		fontWeight: '400 !important',
		width: '400px',
	}) as any,

	formContainer: css({
		maxWidth: 500,
		'.input-container': {
			width: '100%',
		},
		marginBottom: '16px',
	}) as any,

	header: css({
		gap: '16px',
	}) as any,
	label1: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
		marginBottom: '8px',
	}) as any,
	label: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
	}) as any,
	searchClassName: css({
		width: '400px',
		'& .MuiOutlinedInput-root': {
			width: '100% !important',
			height: '42px!important',
			borderRadius: '11px !important',
		},
	}) as any,

	renderOptionRoot: css({
		padding: '8px',
		cursor: 'pointer',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
	renderOptionLabel: css({
		fontWeight: 'bold',
	}),

	titleAutocomplete: css({
		fontSize: '14px',
		color: siteColors.grey900,
		fontWeight: 600,
		border: 'none',
	}),
	editAutocomplete: css({
		// cursor: 'pointer',
		borderRadius: '12px',
		border: cn('1px solid', siteColors.grey700),
		padding: '10.5px 10px',
		background: siteColors.common.white,
	}),
};

const DevisForm = ({
	selectedOption,
	setSelectedOption,
	isLoading,
	isSuccess,
	isError,
	onSubmit,
	toggle,
	title,
	subTitle,
	options,
	message,
	setSearch,
	isLoadingOption,
	selectedSimulation,
	isNew,
}: any) => {
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<QuoteFormInput>({
		resolver: zodResolver(QuoteFormValidationSchema),
		defaultValues: async () => {
			return {
				// name: selectedSimulation?.name || '',
				// name: selectedOption?.name || '',
				name: '',
				clientId: selectedOption?.objectId,
				toDuplicateQuoteId: selectedSimulation?.objectId || '',
			};
		},
		mode: 'onChange',
	});

	// translation
	const { t } = useTranslation();

	// const [showAutoComplete, toggleAutoComplete] = useToggle(false);

	useEffect(() => {
		if (selectedOption) {
			setValue('clientId', selectedOption?.objectId);
		}
	}, [selectedOption, setSelectedOption, setValue]);

	const { /*  step,  */ setStep } = useQuoteFormStep();

	return (
		<div css={{ marginBottom: 24 }}>
			<FormContainer
				loading={isLoading}
				title=""
				buttonTitle={t('form-create-quote-submit-button')}
				onSubmit={handleSubmit(onSubmit)}
				isSuccess={isSuccess}
				isError={isError}
				withSave
				withCancel
				onCancel={() => {
					toggle();
				}}
				alertSuccess={<AlertStatic text={message} type={'success'} icon={<Check color={siteColors.green500} />} />}
				alertError={<AlertStatic text={message} type={'error'} icon={<AlertCircle color="#C74600" />} />}
				addStyles={{
					maxWidth: 528,
					margin: 'auto',
				}}
				t={t}
			>
				<div className={cx('flexColumn width100', styles.formContainer)}>
					<div className={cx('flexColumn width100', styles.header)}>
						<p className={cx(styles.title)}>{title}</p>
						<p className={cx(styles.subTitle)}>{subTitle}</p>
					</div>

					{!isNew && !_.isEmpty(selectedSimulation) ? (
						<div className="width100">
							<div className={cx(styles.label)} css={{ marginBottom: '8px' }}>
								{t('form-create-copy-text')}
							</div>

							<div
								className="width100 flexRow alignCenter"
								css={{
									border: cn('1px solid', siteColors.grey700),
									borderRadius: '12px',
									padding: '16px 10px',
									// width: '100%',
								}}
							>
								<Files size={20} color={siteColors.grey700} />
								<div
									css={{
										marginLeft: '16px',
									}}
								>
									<Typography color="black" fontWeight={600}>
										{/* 15 décembre - 20 décembre */}
										{/* {selectedOption?.name || '??'} */}
										{/* {getValues('name')} */}
										{selectedSimulation?.name || '??'}
									</Typography>
									<Typography fontSize="12px">{t('form-create-copy-text-message')}</Typography>
								</div>
							</div>
						</div>
					) : null}

					<div className="width100 flexRow gap16">
						<BInput
							required
							id="name"
							label={<div className={cx(styles.label)}>{t('quote-name-label')}</div>}
							fullWidth
							margin="dense"
							{...register('name')}
							name="name"
							error={errors.name ? true : false}
							errorMessage={errors?.name?.message}
							autoComplete="off"
						/>
						<div className="width100 flexRow">
							<div className={cx(styles.label1)}>{t('quote-client-label')}</div>

							{errors?.clientId?.message && !selectedOption && (
								<Typography variant="inherit" color={'red'}>
									{errors?.clientId?.message}
								</Typography>
							)}

							{!_.isEmpty(selectedOption) ? (
								<div
									onClick={() => {
										// setSelectedOption({});
									}}
									onDoubleClick={() => {
										// setSelectedOption({});
									}}
									className={cx('flexRow width100 justifyBetween alignCenter', styles.editAutocomplete)}
								>
									<div>
										<div className={cx(styles.titleAutocomplete)}>{selectedOption?.label || selectedOption?.name}</div>
										{selectedOption?.siretNumber}
										<br />
										{selectedOption?.address}
										<br />
										{selectedOption?.postalCode} {selectedOption?.city}
										<br />
									</div>
									<Pencil
										color={siteColors.grey700}
										size={20}
										visibility="hidden"
										onClick={(e) => {
											// setDefaultClientFormValues(selectedOption);
											setStep(2);
											e.stopPropagation();
										}}
									/>
								</div>
							) : (
								<SearchAutocomplete
									setParamsAutocomplete={setSearch && _.debounce(setSearch, 500)}
									options={options}
									getInputFromUrl={false}
									open={true}
									setSelectedOption={setSelectedOption}
									selectedOption={selectedOption}
									className={cx(styles.searchClassName)}
									isLoading={isLoadingOption}
									renderOption={(props, option: any) => {
										return (
											<div
												{...(props as any)}
												className={cx(styles.renderOptionRoot)}
												onClick={() => {
													// setInputValue(option.label);
													setSelectedOption && setSelectedOption(option);
													// return handleButtonClick();
												}}
											>
												<div className={cx(styles.renderOptionLabel, 'grey900')}>{option.label}</div>
												<div>{option.siretNumber}</div>
												<div>{option.address}</div>
												<div>
													{option.postalCode} {option.city}
												</div>
											</div>
										);
									}}
								/>
							)}

							<BButton
								onClick={() => {
									setSelectedOption({});
									setStep(2);
								}}
								iconLeft={<AddIcon />}
								variant="tertiary"
								label={
									<Typography color={siteColors.primary} fontWeight={600}>
										{t('form-quote-client-new-button')}
									</Typography>
								}
								addStyles={{ display: 'inline-flex', alignSelf: 'center', visibility: 'hidden' }}
							/>
						</div>
					</div>
				</div>
			</FormContainer>
		</div>
	);
};

export default DevisForm;
