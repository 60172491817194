import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { FilePenLine, LineChart } from 'lucide-react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { SimulationFormInput } from '@carbonmaps/shared/validations/simulation.validations';
import { getProductOptionsAutocompleteDataAction } from '@carbonmaps/ui/actions/product.actions';
import { createSimulationAction } from '@carbonmaps/ui/actions/simulation.actions';
import { useGetProductSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetProductSheet';

import CreateScenarioModal from '../../../components/simulation/CreateScenarioModal';
import Card from '../../../components/widget/Card';
import LineDisplay from '../../../components/widget/LineDisplay';
import ScenarioItem from '../../../components/widget/ScenarioItem';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { siteColors } from '../../../lib/colors';
import CreateScenario from '../../simulation/ecoConception/CreateScenario';

const styles = {
	span: css({
		display: 'inline-block',
		marginRight: '3px',
	}),
};

const ProductSimulationsBlock = () => {
	const theme = useTheme();
	const params = useParams();
	const [message, setMessage] = useState('');
	const [searchQueryParams] = useSearchQueryParams();
	const { t } = useTranslation();
	const [options, setOptions] = useState([]);
	const [isNew, setIsNew] = useState(true);
	const [search, setSearch] = useState<any>();
	const [openModal, setOpenModal] = useState(false);
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);

	const { data, isSuccess: isSuccessProductSheet } = useGetProductSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		//period: 2023,
	});

	const [selectedOption, setSelectedOption] = useState(data?.product) as any;

	useEffect(() => {
		if (!selectedOption) {
			setSelectedOption(data?.product);
		}
	}, [data?.product, selectedOption]);

	const queryClient = useQueryClient();

	//----- fetch data for autocomplete ----//
	const { data: dataOptions, isSuccess: isSuccessOptions } = useQuery({
		queryKey: [
			'getOptionsAutocompleteData',
			{
				...paramsTable,
				input: search?.input ?? '',
			},
		] as any,
		queryFn: getProductOptionsAutocompleteDataAction,
	});

	useEffect(() => {
		const options: any = [];

		if (isSuccessOptions && dataOptions?.length) {
			options.push(...dataOptions);
		}

		if (isSuccessProductSheet) {
			options.push(data?.product);
		}

		const optionsUniq = _.uniqBy(options, 'uid');
		setOptions(optionsUniq as any);
	}, [isSuccessOptions, dataOptions, search, selectedOption, isSuccessProductSheet, data?.product]);

	//----- mutation create simulation ----//

	const {
		mutate: createSimulation,
		isError,
		isSuccess,
		isLoading: isLoadingCreateSimulation,
	} = useMutation({
		mutationKey: ['createSimulation'],
		mutationFn: createSimulationAction,
		onSuccess: async (value: any) => {
			// setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			setOpenModal(false);
			window.open(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`, '_blank');
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<SimulationFormInput> = async (values: any) => {
		createSimulation({
			...values,
			productId: selectedOption?.objectId,
		} as any);
	};

	const { data: productData } = useGetProductSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		//period: 2023,
	});

	const disabled = !(productData as any)?.product?.isEcoDesigned;
	return (
		<Card
			title={t('product-simulation-card-title')}
			icon={<LineChart color={theme.palette.grey[700]} width={20} height={20} />}
			center={false}
			marginBottom="32px"
			sx={{ width: '100%' }}
		>
			<Box className="flexColumn" sx={{ width: '100%' }}>
				<LineDisplay
					disabled={disabled}
					disabledMessage={t('product-simulation-disabled-message')}
					path={!disabled ? `${FRONT_PATH_NAMES.simulation}/?input=${data?.product?.uid ?? ''}` : ''}
					content={
						<ScenarioItem
							title={t('scenario-d-eco-conception')}
							content={''}
							basePath={`${FRONT_PATH_NAMES.simulation}/?input=${data?.product?.uid ?? ''}`}
						/>
					}
					afterContent={
						<span className={cx('fontWeight400 grey900', styles.span)}>
							{data?.simulations?.length && data?.simulations?.length > 0 ? data?.simulations?.length : 0}
						</span>
					}
				/>

				<CreateScenario
					setSearch={setSearch}
					isLoading={false}
					setOpenModal={setOpenModal}
					setSelectedOption={() => {
						return;
					}}
					setIsNew={setIsNew}
					iconLeft={<FilePenLine color={siteColors.grey700} width={20} height={20} />}
					// disabled={_.toLower(productData?.product?.typeModelizationIngredient) === 'n1_typical'}
					disabled={!(productData as any)?.product?.isEcoDesigned}
				/>

				<CreateScenarioModal
					title={t('create-scenario-form-title')}
					open={openModal}
					onClose={() => {
						return setOpenModal(false);
					}}
					isLoading={isLoadingCreateSimulation}
					onSubmit={onSubmit}
					options={options}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					message={message}
					isError={isError}
					setSearch={setSearch}
				/>
			</Box>
		</Card>
	);
};

export default ProductSimulationsBlock;
