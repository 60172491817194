import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import Diff from '../../../../../apps/front/src/components/modelization/Diff';
import Difference from '../../../../../apps/front/src/containers/scenario/Difference';
import { translation } from '../../../i18n/translation';

import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import { useApp } from '../../../hooks/useApp';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	content: css({
		gap: 0,
		color: 'var(--color)',
		fontSize: 14,
	}),
	innerContent: css({
		width: 16,
		height: 16,
		borderRadius: 16,
		background: 'var(--background-round)',
	}),
	popoverContainer: css({
		padding: 24,
		minWidth: 250,
	}),

	itemContainer: css({
		gap: 16,
	}),

	popoverContent: css({
		//	gap: 16,
		//paddingBottom: 16,
	}),
	// popoverFooter: css({
	// 	paddingTop: 16,
	// 	paddingLeft: 38,
	// }),

	width10: css({
		width: 10,
	}),
	width20: css({
		width: 50,
		fontWeight: '400 !important',
	}),

	fitContent: css({
		width: 'fit-content',
		// justifySelf: 'flex-end',
		// alignSelf: 'flex-end',
	}),

	modelizationBloc: css({
		border: '1px solid transparent!important',
		padding: '4px!important',

		'&:hover': {
			border: cn('1px solid', siteColors.grey700, '!important'),
			background: siteColors.common.white,
			borderRadius: '8px',
			padding: '4px!important',
		},
	}),
	link: css({
		textDecoration: 'none !important',
	}),
	intensityContent: css({
		alignContent: 'center',
		alignItems: 'center',
	}),
	diffContent: css({
		gap: '8px',
	}),
};

const AcvDiff = ({
	value,
	row,
	column,
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const theme = useTheme();
	const isEqual = ['0.00', '0,00', 0.0].includes(formatNumber(Math.abs(value), '', 2)) || value === 0;
	const isPositiveValue = value >= 0;
	const t = column?.props?.t || translation;
	const { indicator } = useApp();

	if (column?.props?.page === 'view-modelization') {
		if (value === 0) {
			return <div></div>;
		}

		return <Diff data={row.original} value={value} indicator={indicator} />;
	}

	if (column?.props?.page === 'productAcv' || column?.props?.page === 'ingredientAcv') {
		return (
			<div className={cx('flexRow justifyEnd')}>
				<Difference value={value} diffMainClass={cx('', styles.fitContent)} />
			</div>
		);
	}

	if (row.original?.level !== 2 && column?.props?.page === 'simulation') {
		return null;
		// return <div css={{ textAlign: 'left' }}>Au {dayjs(row.original.createdAt).format('D/MM/YYYY')}</div>;
	}

	return (
		<div className="flexRow width100 justifyEnd">
			<div
				className={cx('flexRow alignCenter justifyCenter nowrap ', styles.content)}
				style={{
					['--color' as any]:
						isEqual && column?.props?.page === 'simulation'
							? siteColors.grey700
							: isPositiveValue
							? column?.props?.page === 'simulation'
								? '#C70000'
								: siteColors.grey800
							: '#009F36',
				}}
			>
				<span className={cx(styles.width20, 'number textNoWrap')}>
					{isEqual && column?.props?.page === 'simulation' ? (
						<>{/* <Equal /> */}=</>
					) : (
						<>
							{isPositiveValue ? '+' : '-'}
							{formatNumber(Math.abs(value), undefined, 2)} %
						</>
					)}
				</span>
			</div>
		</div>
	);
};

export default AcvDiff;
