import { css, cx } from '@emotion/css';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { AlertCircle, AlertTriangle, ArrowUpRight, ClipboardCheck } from 'lucide-react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { COMPOSITION_ERROR, UID_LABEL_ERROR, VOLUME_ERROR } from '@carbonmaps/shared/utils/audit';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useGetProductSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetProductSheet';

import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';

const config = new Map();

//config.set(VOLUME_ERROR, { icon: ClipboardCheck });

config.set(VOLUME_ERROR, { icon: AlertTriangle, iconColor: '#C74600' });
config.set(UID_LABEL_ERROR, { icon: AlertCircle });
config.set(COMPOSITION_ERROR, { icon: AlertCircle });
config.set('valid', { icon: ClipboardCheck });

const styles = {
	innerContainer: css({
		gap: 8,
	}),
	title: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		marginBottom: 6,
	}),
	label: css({
		fontSize: '14px !important',
		fontWeight: '400 !important',
		color: cn(siteColors.grey900, '!important'),
		lineHeight: 'inherit !important',
	}),
	containerItem: css({
		background: '#FFE4CB',
		padding: '8px 16px',
		borderRadius: '4px',
		marginTop: 24,
		gap: 4,
	}),
	link: css({
		color: siteColors.grey800,
		textDecorationColor: siteColors.grey800,
	}),
	linkContainer: css({
		gap: 4,
		fontSize: '14px !important',
		color: cn(siteColors.grey900, '!important'),
	}),
	gap8: css({
		gap: 8,
	}),
	gap12: css({
		gap: 12,
	}),
	gap2: css({
		gap: 2,
	}),
	gap4: css({
		gap: 4,
	}),
};

const AuditBlock = () => {
	const params = useParams();
	const theme = useTheme();

	const { t } = useTranslation();

	const { data } = useGetProductSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		//period: 2023,
	});

	const goToAudit = (item: any) => {
		(item?.originErrors || []).map((path: string, index: number) => {
			// add name to open multiple frame
			window.open(`${FRONT_PATH_NAMES.audit}/${path}?input=${(data as any)?.product?.uid}`, `_wind${index}`);
		});
	};

	return (
		<div className={cx('flexColumn width100', styles.innerContainer)}>
			{/* <Typography className={cx('grey800', styles.title)}>{t('data-audit-title')}</Typography> */}
			<div className={cx('flexColumn width100', styles.gap12)}>
				{((data as any)?.dataAudit || []).map((item: any) => {
					const { icon: Icon, iconColor } = config.get(item.errorType) || {};
					return item?.errorType !== 'valid' ? (
						<div className={cx('flexRow width100 alignCenter nowrap spaceBetween', styles.containerItem)}>
							<div className={cx('flexRow alignCenter nowrap', styles.gap8)}>
								<Icon size={20} color={iconColor || siteColors.grey700} />
								<Typography className={styles.label}>{t(item?.label)}</Typography>
							</div>
							{item?.errorType !== 'valid' && (
								<MUILink
									component={RouterLink}
									to={'#'}
									sx={{ color: siteColors.grey900, textDecorationColor: siteColors.grey900 }}
									onClick={(e: any) => {
										e.preventDefault();
										e.stopPropagation();
										goToAudit(item);
									}}
								>
									<div className={cx('flexRow alignCenter', styles.linkContainer)}>
										<Typography className={styles.label}>{t('product-audit-link')}</Typography>
										<ArrowUpRight
											size={20}
											color={theme.palette.grey[700]}
											css={{ '&:hover': { stroke: theme.palette.primary.main } }}
										/>
									</div>
								</MUILink>
							)}
						</div>
					) : null;
				})}
			</div>
		</div>
	);
};

export default AuditBlock;
