import { css, cx } from '@emotion/css';
import Chip from '@mui/material/Chip';
import useTheme from '@mui/material/styles/useTheme';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	container: css({
		padding: '8px',
		fontSize: '16px',
		fontWeight: 500,
	}),
};

const RoleCell = ({
	value,
	row: { index },
	column: { id },
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const theme = useTheme();

	return (
		<div className={cx('grey900', styles.container)}>
			<Chip
				label={value}
				className={css({
					bgcolor: theme.palette.grey[300],

					borderRadius: '8px',
					border: `solid 1px ${theme.palette.grey[300]}`,
					span: {
						color: theme.palette.grey[900],
						fontSize: '1rem',
						padding: '6px 8px',
					},
				})}
			/>
		</div>
	);
};

export default RoleCell;
