import { ComponentProps, useCallback, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import Dialog from '../../components/dialogs/Dialog';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';

export type ExportFormat = 'csv' | 'xslsx';

type HandleExportParams = { exportFormat: ExportFormat };

export type ExportDialogProps = {
	title: ComponentProps<typeof Dialog>['title'];
	isLoading: boolean;
	handleExport: (params: HandleExportParams) => void;
	open: boolean;
	toggle: () => void;
	trackName?: string;
	categoryEvent?: string;
};

const ExportDialog = ({
	title,
	isLoading,
	handleExport,
	open = false,
	toggle,
	trackName,
	categoryEvent = 'Explore',
}: ExportDialogProps) => {
	const [exportFormat, setExportFormat] = useState<ExportFormat>('csv');

	const { t } = useTranslation();
	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: categoryEvent },
			});
		},
		[analytics, categoryEvent],
	);

	return (
		<Dialog
			title={title}
			description={t('form-export-subtitle')}
			open={open}
			isLoading={isLoading}
			onPrimaryButtonAction={() => {
				if (trackName) {
					trackEvent(trackName);
				}

				handleExport({ exportFormat });
				toggle();
			}}
			toggle={toggle}
			primaryButtonText={t('export-button')}
			secondaryButtonText={t('cancel-button')}
		>
			<FormControl>
				<Typography>{t('form-export-chose-type')}</Typography>
				<RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					defaultValue="female"
					name="radio-buttons-group"
					value={exportFormat}
					onChange={(_, value) => {
						setExportFormat(value as ExportFormat);
					}}
				>
					<FormControlLabel value="csv" control={<Radio /*  sx={{ paddingBottom: 0 }} */ />} label="CSV" />
					<FormControlLabel value="xlsx" control={<Radio /* sx={{ paddingBottom: 0 }} */ />} label="Excel (.xlsx)" />
				</RadioGroup>
			</FormControl>
		</Dialog>
	);
};

export default ExportDialog;
