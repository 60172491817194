import { css, cx } from '@emotion/css';

import GlobalCard from '../../../../components/audit/GlobalCard';

import AcvCard from './AcvCard';
import AuditInfoCard from './AuditInfoCard';
import ErrorVolumeCard from './ErrorVolumeCard';

const styles = {
	container: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',

		gridColumnGap: 32,
		gridRowGap: 32,
	}),
};

const AuditTab = () => {
	return (
		<div className={cx('width100', styles.container)}>
			<AuditInfoCard />
			<ErrorVolumeCard />
			{/* <AcvCard /> */}
		</div>
	);
};

export default AuditTab;
