import { useCallback, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler } from 'react-hook-form';

import { ClientFormInput } from '@carbonmaps/shared/validations/client.validations';
import { QuoteFormInput } from '@carbonmaps/shared/validations/quote.validations';
import { createQuoteAction } from '@carbonmaps/ui/actions/quote.actions';

import { useJuneTrack } from '../../../../../../hooks/useJuneTrack';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import {
	createClientAction,
	getQuoteOptionsAutocompleteClientAction,
} from '../../../../../../lib/react-query/features/client/client.actions';
import CreateDevisCarbone from '../../../CreateDevisCarbone';
import DevisModal from '../../../DevisModal';

const CreateButton = ({ setTableParams, paramsTable, initialSelectedOption }: any) => {
	const [openModal, setOpenModal] = useState(false);
	const [isNew, setIsNew] = useState(false);
	const [selectedOption, setSelectedOption] = useState(initialSelectedOption ?? {});
	const [message, setMessage] = useState('');
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const [search, setSearch] = useState<any>();

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	const {
		mutate: createQuote,
		isError,
		isSuccess,
		isLoading: isLoadingCreateQuote,
	} = useMutation({
		mutationKey: ['createQuote'],
		mutationFn: createQuoteAction,
		onSuccess: async (returnedData: any) => {
			setOpenModal(false);
			console.log('quote created');
			queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisClient'] });

			// setMessage('Informations mises à jour');
			// navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	//----- action when submit quote form ----//
	const onSubmit: SubmitHandler<QuoteFormInput> = async (values) => {
		createQuote({
			...values,
			clientId: selectedOption?.objectId,
		} as any);
	};

	//----- mutation to create the client ----//
	const {
		mutate: createClient,
		isError: isErrorClient,
		isSuccess: isSuccessClient,
		isLoading: isLoadingCreateClient,
	} = useMutation({
		mutationKey: ['createClient'],
		mutationFn: createClientAction,
		onSuccess: async (returnedData: any) => {
			// setOpenModal(false);
			// setStep(1);

			queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });

			if (returnedData?.isUpdate) {
				// setMessage('Informations mises à jour');
				setSelectedOption({
					label: returnedData?.client?.get('name'),
					...returnedData?.client?.toJSON(),
				});
			}
			// navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	//----- action when submit client form  ----//
	const onSubmitClient: SubmitHandler<ClientFormInput> = async (values) => {
		createClient({
			...values,
		} as any);

		trackEvent('Simulate Carbon Quote Create Client', {
			ClientName: values?.name,
		});
	};

	//----- fetch data for autocomplete ----//
	const {
		data: dataOptions,
		isLoading: isLoadingData,
		isSuccess: isSuccessOptions,
	} = useQuery({
		queryKey: [
			'getOptionsAutocompleteClient',
			{
				// ...paramsTable,
				input: search?.input ?? '',
			},
		] as any,
		queryFn: getQuoteOptionsAutocompleteClientAction,
	});

	// const [search, setSearch] = useState<string>('');

	const { data: clientsData } = useQuery({
		queryKey: ['findDevisClient', { input: search }] as const,
		// queryFn: findDevisClientAction,
		queryFn: async ({ queryKey }) => {
			const [key, { input }] = queryKey;

			const clients = await Parse.Cloud.run('findClient', { input });

			return clients;
		},
	});

	return (
		<>
			<CreateDevisCarbone
				setSearch={
					// setTableParams
					// 	? setTableParams
					// 	:
					() => {
						/*  */
					}
				}
				// isLoading={isLoadingDataOption}
				isLoading={false}
				setOpenModal={setOpenModal}
				setSelectedOption={setSelectedOption}
				setIsNew={setIsNew}
				initialSelectedOption={initialSelectedOption}
			/>

			<DevisModal
				// eslint-disable-next-line quotes
				title={isNew === true ? t('form-create-quote-title') : t('form-update-quote-title')}
				open={openModal}
				onClose={() => {
					// setSelectedSimulation({});
					// setMessage('');
					return setOpenModal(false);
				}}
				isLoading={isLoadingCreateQuote}
				onSubmit={onSubmit}
				onSubmitClient={onSubmitClient}
				options={/* options */ dataOptions}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				message={message}
				isError={isError}
				// setSearch={setSearch}
				setSearch={
					// setTableParams
					// 	? setTableParams
					// 	:
					// () => {
					// 	/*  */
					// }
					setSearch
				}
				// isSuccess={isSuccess}
				isLoadingClient={isLoadingCreateClient}
				// selectedSimulation={selectedSimulation}
				// setSelectedSimulation={setSelectedSimulation}
				isNew={isNew}
				selectedSimulation={setSelectedOption}
				setSelectedSimulation={selectedOption}
			/>
		</>
	);
};

export default CreateButton;
