import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import Grid from '@mui/material/Grid';
import MUILink from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import { Link as RouterLink } from 'react-router-dom';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import Unit from '../units/Unit';
import Difference from './Difference';

type Props = {
	value: any;
	data: any;
	withPercent?: boolean;
	valuePercent?: number;
	indicator: 'carbon' | 'water';
};
const styles = {
	content: css({
		gap: 0,
		color: 'var(--color)',
		fontSize: 14,
	}),
	innerContent: css({
		width: 16,
		height: 16,
		borderRadius: 16,
		background: 'var(--background-round)',
	}),
	popoverContainer: css({
		padding: 24,
		minWidth: 250,
	}),

	itemContainer: css({
		gap: 16,
	}),

	popoverContent: css({
		//	gap: 16,
		//paddingBottom: 16,
	}),
	// popoverFooter: css({
	// 	paddingTop: 16,
	// 	paddingLeft: 38,
	// }),

	width10: css({
		width: 10,
	}),
	width20: css({
		width: 50,
		fontWeight: '400 !important',
	}),

	fitContent: css({
		width: 'fit-content',
		// justifySelf: 'flex-end',
		// alignSelf: 'flex-end',
	}),

	modelizationBloc: css({
		height: pxToRem(24),
		border: '1px solid transparent!important',
		//padding: '4px!important',

		// '&:hover': {
		// 	border: cn('1px solid', siteColors.grey700, '!important'),
		// 	background: siteColors.common.white,
		// 	borderRadius: '8px',
		// 	//padding: '4px!important',
		// },
	}),
	link: css({
		textDecoration: 'none !important',
	}),
	intensityContent: css({
		alignContent: 'center',
		alignItems: 'center',
	}),
	diffContent: css({
		gap: '8px',
	}),
};

const Diff = ({ value, valuePercent, data, withPercent = true, indicator = 'carbon' }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		// <BPopover
		// 	anchorOriginHorizontal={'right'}
		// 	transformOriginVertical={'top'}
		// 	transformOriginHorizontal={'right'}
		// 	anchorOriginVertical={'bottom'}
		// 	modeHover
		// 	content={(onClose) => {
		// 		return (
		// 			<div className={styles.popoverContainer}>
		// 				<div className={styles.popoverContent}>
		// 					<div>
		// 						<div className="fontWeight700 fontSize16">{t('Differentiel')}</div>
		// 						<div className={cx('flexRow alignCenter', styles.diffContent)}>
		// 							<Difference
		// 								value={valuePercent || value}
		// 								decimal={2}
		// 								diffMainClass={cx(styles.fitContent)}
		// 								showZero={true}
		// 							/>
		// 							<div className={cx('fontWeight400 fontSize16 alignCenter', styles.intensityContent)}>
		// 								({formatNumber(data?.VSExterne, '', 2)}{' '}
		// 								{indicator === CARBONE_INDICATOR ? (
		// 									<Unit measure="carbonIntensityKg" />
		// 								) : (
		// 									<Unit measure="waterIntensity" />
		// 								)}
		// 								)
		// 							</div>
		// 						</div>
		// 					</div>

		// 				</div>
		// 			</div>
		// 		);
		// 	}}
		// >
		<div className={cx('flexRow justifyEnd nowrap', styles.modelizationBloc)}>
			<Difference
				withPercent={withPercent}
				value={value}
				decimal={2}
				diffMainClass={cx(styles.fitContent)}
				showZero={true}
			/>
		</div>
		// </BPopover>
	);
};

export default Diff;
