import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation as useTranslationNext } from 'react-i18next';

import { getIsoCountryCode, isEmptyText } from '@carbonmaps/ui/utils/utils';

import { useGetClientAuthQuery } from '../lib/react-query/features/auth/auth.hooks';

export const useGlossary = () => {
	const { t, i18n } = useTranslationNext();

	const {
		result: { data },
	} = useGetClientAuthQuery();

	const dataGlossariesMap = useMemo(() => {
		const glossariesMap = new Map();

		if (!data?.glossaries?.length) return glossariesMap;

		data?.glossaries.forEach((glossary: any) => {
			glossariesMap.set(glossary.key, glossary);
		});

		return glossariesMap;
	}, [data]);

	const customGlossary = useCallback(
		(key: string) => {
			const glossary = dataGlossariesMap.get(key);
			if (!glossary) return '';
			return !isEmptyText(glossary[`text_${getIsoCountryCode(i18n.language)}`])
				? glossary[`text_${getIsoCountryCode(i18n.language)}`]
				: t(key);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dataGlossariesMap, i18n.language, t],
	);

	const translate = useCallback(
		(key: string, options?: any) => {
			try {
				return dataGlossariesMap?.has(key) ? customGlossary(key) : (t(key, options) as any);
			} catch (error) {
				console.error('Error in glossary', error);
				return key;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[customGlossary, t],
	);

	return {
		g: translate,
		i18n,
	};
};
