import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
// import { HelpCircle } from 'lucide-react';

import { type CellContext, type RowData } from '@tanstack/react-table';
import _ from 'lodash';

import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../../lib/colors';
import PercentBar from './PercentBar';

// import BPopover from '../BPopover';

// import EmptyValue from './EmptyValue';

const styles = {
	container: css({
		width: '45%',
		// height: 16,
		// minWidth: 100,
		// borderRadius: 500,
		// overflow: 'hidden',
	}),
	// innerContainer: css({
	// 	height: '100%',
	// 	borderRadius: 6,
	// 	width: '100%',
	// }),
	// text: css({
	// 	marginRight: '8px',
	// 	fontWeight: 600,
	// 	fontSize: '16px ! important',
	// }),
	text1: css({
		marginRight: '8px !important',
	}),
};

const ImpactCell = <TData extends RowData>({ info, value }: { value: number; info: CellContext<TData, any> }) => {
	// const { value, row, column, sortedRows } = props;
	// popover component
	// const PopoverContent = column?.props?.popoverComponent || React.Fragment;

	// const theme = useTheme();
	const sortedRows = info.table.getSortedRowModel().rows;

	// return <div>{value}</div>;

	const currentIndex = sortedRows.findIndex((e) => {
		return e.index === info.row.index;
	});
	// info.column.sort;

	let start = 0;

	for (let index = 0; index < currentIndex; index++) {
		// start = start + sortedRows[index]['values'][column.id];
		start = start + _.toNumber(_.get(sortedRows[index].original, 'impactPercent'));
	}

	const impactPercent = _.toNumber(_.get(info.row.original, 'impactPercent'));

	return (
		<div className="flexRow alignCenter">
			<Typography color={siteColors.primary} className={cx('number textNoWrap', styles.text1)}>
				{displayValue(impactPercent, undefined, /* column?.props?.decimal ?? */ 1)} %
			</Typography>

			<div className={cx('', styles.container)}>
				<PercentBar percentValue={impactPercent} start={start} />
			</div>
		</div>
	);
};

export default ImpactCell;
