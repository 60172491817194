/* eslint-disable quotes */
import { useEffect, useMemo } from 'react';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import useHasRoles from '../../hooks/useHasRoles';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

const UnAuthorized = () => {
	const { setBreadcrumbs } = useApp();
	const { t } = useTranslation();
	// const { isAuthed, roles, hasRoles } = useAuth();
	const location = useLocation();
	// const navigate = useNavigate();
	// const hasRoles = useHasRoles();

	useEffect(() => {
		setBreadcrumbs([
			{
				text: 'Non Autorisé',
			},
		]);
	}, [setBreadcrumbs]);

	// const {
	// 	result: { data: authData },
	// } = useGetClientAuthQuery();

	// const roles = useMemo(() => {
	// 	return authData?.roles || [];
	// }, [authData?.roles]);

	// useEffect(() => {
	// 	// if (location.state.from.pathname === FRONT_PATH_NAMES.logInAs) {
	// 	// 	// ? should we add more check ? if has user has a certain role for example?
	// 	// 	navigate('/');
	// 	// 	return;
	// 	// }

	// 	if (roles.length > 0) {
	// 		if (roles.length && roles[0].name === 'super_admin') {
	// 			navigate(`${FRONT_PATH_NAMES.superAdminSettings}/companies`);
	// 		}

	// 		if (location.state.allowedRolesLabels && hasRoles(location.state.allowedRolesLabels)) {
	// 			navigate(location.state.from.pathname || '/');
	// 		}
	// 	}
	// }, [hasRoles, location.state.allowedRolesLabels, location.state.from.pathname, navigate, roles, roles.length]);

	return (
		<div
			css={{
				width: '500px',
				margin: '0 auto',
			}}
		>
			<h1>{t("Vous n'êtes pas autorisé à accéder cette page")}</h1>
		</div>
	);
};

export default UnAuthorized;
