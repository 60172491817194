import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';

import { ReactComponent as ArrowIcon } from '@carbonmaps/media/icons/arrow-down.svg';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useTranslation } from '../../hooks/useTranslation';
import Card from '../Card';

type ChartContainerProps = {
	children: React.ReactNode;
	title?: React.ReactNode;
	unity?: React.ReactNode;
	dataGraph?: Array<{
		value: string;
		color: string;
		data: Array<any>;
	}>;
	indicatorOptions?: Array<{ value: string; label: React.ReactNode; unit: React.ReactNode }>;
	indicator?: any;
	showToggle?: boolean;
	onChangeGraphIndicator?: (indicator: any) => any;
	sx?: SxProps;
	icon?: React.ReactNode;
	subTitle?: React.ReactNode;
	withBottom?: boolean;
	className?: string;
};

const ChartContainer = ({
	children,
	dataGraph,
	indicatorOptions,
	indicator,
	title = 'Répartition par Indicateur',
	subTitle,
	unity,
	onChangeGraphIndicator,
	showToggle = true,
	withBottom = true,
	sx = {},
	className = '',
	icon,
}: ChartContainerProps) => {
	const theme = useTheme();

	// translation
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();

	const [indicatorGraphOptions, setIndicatorGraphOptions] = useState(indicatorOptions || []);

	const handleChangeIndicatorGraph = (value: string) => {
		const currentIndicator = indicatorGraphOptions.find((item) => {
			return item.value === value;
		});
		// setIndicatorGraphValue(currentIndicator);
		onChangeGraphIndicator && onChangeGraphIndicator(currentIndicator);
	};

	const getCurrentIndicator = (value: string) => {
		return indicatorGraphOptions.find((item: any) => {
			return item.value === value;
		});
	};

	useEffect(() => {
		if (!indicatorOptions) return;
		setIndicatorGraphOptions(indicatorOptions);
	}, [indicatorOptions]);

	return (
		<Card
			title={title}
			subtitle={unity}
			sxBoxProps={{ marginBottom: 100 }}
			sx={{
				padding: '48px 40px',
				...sx,
			}}
			icon={icon}
			className={className}
		>
			{subTitle && (
				<Box padding={'14px'} paddingBottom={0}>
					{subTitle}
				</Box>
			)}
			{showToggle && (
				<Box marginBottom={'30px'}>
					<BPopover
						anchorOriginHorizontal={'left'}
						transformOriginVertical={'top'}
						transformOriginHorizontal={'left'}
						anchorOriginVertical={'bottom'}
						content={(onClose) => {
							return (
								<Box paddingY="6px">
									<FormControl>
										<RadioGroup
											aria-labelledby="demo-radio-buttons-group-label"
											defaultValue={indicator?.value}
											name="radio-buttons-group"
											onChange={(_, value) => {
												handleChangeIndicatorGraph(value);
												onClose();
											}}
										>
											{indicatorGraphOptions.map((item: any, index: number) => {
												return (
													<FormControlLabel
														value={item?.value}
														control={<Radio />}
														label={item?.label}
														sx={{ paddingX: '16px' }}
													/>
												);
											})}
										</RadioGroup>
									</FormControl>
								</Box>
							);
						}}
					>
						<Box className="flexRow" textAlign="left" alignItems="center">
							{/* <Box bgcolor="red" width="20px" height="20px"> */}
							<ArrowIcon
								css={{
									width: '14px',
									height: '14px',
									marginRight: '10px',
								}}
							/>

							<Box>
								<Typography fontSize="13px" color={theme.palette.grey[700]} fontWeight="bold">
									{t(getCurrentIndicator(indicator?.value)?.label as any, { period: selectedYear || t('period') })}
								</Typography>
								<Typography variant="caption" color={theme.palette.grey[700]}>
									{getCurrentIndicator(indicator?.value)?.unit}
								</Typography>
							</Box>
						</Box>
					</BPopover>
				</Box>
			)}

			<Box
				//  paddingTop="30px"
				paddingBottom={withBottom ? '30px' : '0px'}
			>
				{children}
			</Box>
		</Card>
	);
};

export default ChartContainer;
