import { functionName } from "@carbonmaps/shared/utils/constants";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useParams } from "react-router-dom";

export const useGetInfoNoteData = ({ questionFormId, enabled }: { questionFormId: string; enabled?: boolean }) => {
	const params = useParams();

	const supplierId = _.toString(params.supplierId);

	return useQuery({
		queryKey: ['findInfoNoteData', { supplierId, questionFormId }] as const,
		queryFn: async (ctx) => {
			try {
				const { supplierId, questionFormId } = ctx.queryKey[1];

				const results = await Parse.Cloud.run(functionName.suppliers.rse.getInfoNoteData, { supplierId, questionFormId });

				return results;
			} catch (error) {
				console.log('--- findInfoNoteData action error ---', error);
				return Promise.reject(error);
			}
		},
		enabled,
	})
}

export const useFindSupplierQuestionFormsTabs = ({ supplierId }: { supplierId: string; }) => {
	return useQuery({
		queryKey: ['findSupplierQuestionFormsTabs', { supplierId }] as const,
		queryFn: async (ctx) => {
			const { supplierId } = ctx.queryKey[1];
			try {
				const results = await Parse.Cloud.run(functionName.suppliers.rse.findSupplierQuestionFormsTabs, { supplierId });
				return results;
			} catch (error) {
				console.log('--- findSupplierQuestionFormsTabs action error ---', error);
				return Promise.reject(error);
			}
		}
	})
}
