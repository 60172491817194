import { type QueryFunctionContext } from '@tanstack/react-query';

import { classNames, functionName } from '@carbonmaps/shared/utils/constants';

import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';

export type GetParams = {
	size: number;
	page: number;
	direction?: number;
	id?: string;
	input?: string;
	facetFilters?: { values: string[]; path: string }[] | [];
	facetFiltersSelected?: { values: string[]; path: string }[] | [];
	sortingBy?: any;
};

export const getReportClientInfoAction = async (
	context: QueryFunctionContext<readonly ['getReportClientInfo', Record<string, any>]>,
) => {
	try {
		const {
			queryKey: {
				1: {
					objectId,
					viewMode,
					selectedYear, },
			},
		} = context;

		const result = await Parse.Cloud.run(functionName.sales.findOneReportClient,
			{
				objectId,
				viewMode,
				selectedYear,
			},
		);

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};
