import { useMemo } from 'react';

import { css } from '@emotion/css';
import { ChevronDown } from 'lucide-react';

import { cn, siteColors } from '../../../../lib/colors';
import SelectField from '../../../form/fields/SelectField';
import Typography from '../../../Typography';

type SelectedYearFieldProps = {
	item: any;

	value: any;
	onChange: (value: any) => void;
};

const styles = {
	container: css({
		padding: 4,
	}),
	selectedContainer: css({
		width: '100%',
		background: '#fff !important',
		color: cn(siteColors.grey700, '!important'),
		border: '1px solid rgb(228, 204, 247)',

		'.MuiInputBase-input': {
			// paddingLeft: '0px !important',
			// paddingTop: '0px !important',
			paddingBottom: '0px !important',
			minHeight: '32px !important',
		},
		svg: {
			width: '20px',
			height: '20px',
			color: cn(siteColors.grey700, '!important'),
		},
	}),
	label: css({
		padding: '12px 16px',
	}),
};

const SelectedYearField = ({ item, value, onChange }: SelectedYearFieldProps) => {
	const options = useMemo(() => {
		const currentYear = new Date().getFullYear();

		const startYear = currentYear - 3;

		const years = Array.from({ length: 30 }, (_, i) => {
			const year = startYear + i;
			return {
				value: year,
				label: year,
			};
		});

		return years;
	}, []);

	return (
		<div className={styles.container}>
			{item?.isNew ? (
				<SelectField
					items={options as any}
					value={value}
					onChange={(v: any) => {
						onChange(v);
					}}
					className={styles.selectedContainer}
					renderValue={(item: any) => {
						return <Typography>{item?.label}</Typography>;
					}}
					ExpandMoreRoundedIcon={ChevronDown}
				/>
			) : (
				<Typography className={styles.label}>{item?.label}</Typography>
			)}
		</div>
	);
};

export default SelectedYearField;
