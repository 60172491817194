import { useCallback, useEffect, useState } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { PackageOpen } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useModelizationProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import Skeleton from '../../../components/Skeleton';
import Unit from '../../../components/units/Unit';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ProductModel from '../../../models/Product.model';
import { CARBONE_INDICATOR, WATER_INDICATOR } from '../../../utils/constants';

const styles = {
	skeleton: css({
		width: '120px !important',
		height: '42px !important',
	}),
};

const TopOverView = () => {
	const params = useParams();
	const { indicator, setIndicator } = useApp();
	const [intensity, setIntensity] = useState(0);
	const [percentage, setPercentage] = useState(0);
	const { t } = useTranslation();

	const theme = useTheme();
	const indicatorConfigItems = new Map();

	indicatorConfigItems.set(CARBONE_INDICATOR, {
		intensityUnity: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
		color: theme.palette.primary.main,
	});
	indicatorConfigItems.set(WATER_INDICATOR, {
		color: siteColors.water500,
		intensityUnity: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
	});

	const { data, isLoading } = useModelizationProduct({
		productId: params.productId!,
	});

	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBONE_INDICATOR);
	}, [setIndicator]);

	useEffect(() => {
		if (indicator === CARBONE_INDICATOR) {
			setPercentage(carbonIntensityPercent() ?? 0);
			setIntensity(carbonIntensity() ?? 0);
		} else {
			setPercentage(waterIntensityPercent() ?? 0);
			setIntensity(waterIntensity() ?? 0);
		}
	}, [indicator, data]);

	const carbonIntensityPercent = useCallback(() => {
		const productObject = new ProductModel(data);

		if (!data) return 0;

		const gesPackaging = productObject.getIntensity('gesPackaging');
		const gesProduct = productObject.getIntensity('gesTotal');

		if (!gesPackaging || !gesProduct) return 0;

		return (productObject.getIntensity('gesPackaging') * 100) / productObject.getIntensity('gesTotal');
	}, [data]);

	const carbonIntensity = useCallback(() => {
		const productObject = new ProductModel(data);
		const gesPackaging = productObject.getIntensity('gesPackaging');
		if (!data) return 0;
		if (!gesPackaging) return 0;
		return gesPackaging;
	}, [data]);

	const waterIntensityPercent = useCallback(() => {
		const productObject = new ProductModel(data);

		if (!data) return 0;

		const gesPackaging = productObject.getIntensity('waterUsePackaging');
		const gesProduct = productObject.getIntensity('waterUseTotal');

		if (!gesPackaging || !gesProduct) return 0;

		return (productObject.getIntensity('waterUsePackaging') * 100) / productObject.getIntensity('waterUseTotal');
	}, [data]);

	const waterIntensity = useCallback(() => {
		const productObject = new ProductModel(data);
		const waterUsePackaging = productObject.getIntensity('waterUsePackaging');

		if (!data) return 0;
		if (!waterUsePackaging) return 0;
		return waterUsePackaging;
	}, [data]);

	return (
		<Box className="flexRow alignCenter" paddingBottom="28px" borderBottom={`2px solid ${theme.palette.grey[300]}`}>
			<PackageOpen width={60} height={60} color={siteColors.text} />
			<Typography fontSize="20px" fontWeight={600} ml="28px">
				{t('product-packaging-title')}
			</Typography>
			<Divider
				orientation="vertical"
				flexItem
				sx={{ border: `1px solid ${theme.palette.grey[500]}`, marginX: '24px' }}
			/>
			{isLoading ? (
				<Skeleton className={styles.skeleton} />
			) : (
				<Box>
					<Typography fontSize="23px" color={indicatorConfigItems.get(indicator).color} className="number">
						{displayValue(intensity, undefined, 2)}
					</Typography>
					<Typography color={theme.palette.grey[700]} fontSize="12px">
						{indicatorConfigItems.get(indicator).intensityUnity}
					</Typography>
				</Box>
			)}
			<Divider
				orientation="vertical"
				flexItem
				sx={{ border: `1px solid ${theme.palette.grey[500]}`, marginX: '24px' }}
			/>
			{isLoading ? (
				<Skeleton className={styles.skeleton} />
			) : (
				<Box>
					<Typography fontSize="23px" color={indicatorConfigItems.get(indicator).color} className="number">
						{displayValue(percentage, undefined, 1)} %
					</Typography>
					<Typography textTransform="uppercase" color={theme.palette.grey[700]} fontSize="12px">
						{t('du cycle de vie')}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default TopOverView;
