import MUISkeleton, { type SkeletonProps } from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled';

const ISkeleton = styled(MUISkeleton)(({ theme }) => {
	return {
		borderRadius: '10px',
		backgroundColor: theme.palette.grey[300],
	};
});

const Skeleton = ({ variant, ...props }: SkeletonProps) => {
	return <ISkeleton animation="wave" variant={variant || 'rounded'} {...props} />;
};

export default Skeleton;
