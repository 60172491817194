import dayjs from 'dayjs';
import { utils, writeFile } from 'xlsx';

//--------------------------------------------------------------------------------------//
//                                                                                      //
//                                      Export                                          //
//                                                                                      //
//--------------------------------------------------------------------------------------//
export type GetParamsExport = {
	ids?: string[];
	productId?: string;
	productUid?: string;
	t?: any;
};

export async function exportPackagingAction(params: GetParamsExport) {
	if (!params.productId) {
		throw new Error('Aucun produits trouvé');
	}

	const { t } = params;

	const { data } = await Parse.Cloud.run('getPackagingByProduct', {
		limit: 10000,
		skip: 0,
		productId: params.productId,
		ids: params?.ids,
	});

	try {
		// create a new workbook
		const ROWS = data.map((row: any) => {
			return {
				'Libellé emballage': row.label ?? '',
				Composition: row.composition ?? '',
				Poids: row.weight ?? '',
				Label_Emballage_CMAPS: row.labelEmbCmaps ?? '',
				Code_EMB_CMAPS: row.codeEmbCmaps ?? '',
				BDD_ICV: row.bddIcv ?? row.bddRef ?? '',
				GES: row.ges ?? '',
				GES_weighted: row.gesWeighted ?? '',
				Ozone_1: row.ozone1 ?? '',
				Ozone_1_weighted: row.ozone1Weighted ?? '',
				Rayonnements: row.rayonnements ?? '',
				Rayonnements_weighted: row.rayonnementsWeighted ?? '',
				Ozone_2: row.ozone2 ?? '',
				Ozone_2_weighted: row.ozone2Weighted ?? '',
				Particules: row.particules ?? '',
				Particules_weighted: row.particulesWeighted ?? '',
				Toxicite_1: row.toxicite1 ?? '',
				Toxicite_1_weighted: row.toxicite1Weighted ?? '',
				Toxicite_2: row.toxicite2 ?? '',
				Toxicite_2_weighted: row.toxicite2Weighted ?? '',
				Acidification: row.acidification ?? '',
				Acidification_weighted: row.acidificationWeighted ?? '',
				Eutrophisation_1: row.eutrophisation1 ?? '',
				Eutrophisation_1_weighted: row.eutrophisation1Weighted ?? '',
				Eutrophisation_2: row.eutrophisation2 ?? '',
				Eutrophisation_2_weighted: row.eutrophisation2Weighted ?? '',
				Eutrophisation_3: row.eutrophisation3 ?? '',
				Eutrophisation_3_weighted: row.eutrophisation3Weighted ?? '',
				Ecotox: row.ecotox ?? '',
				Ecotox_weighted: row.ecotoxWeighted ?? '',
				Land_use: row.landUse ?? '',
				Land_use_weighted: row.landUseWeighted ?? '',
				Water_use: row.waterUse ?? '',
				Water_use_weighted: row.waterUseWeighted ?? '',
				Fossil_use: row.fossilUse ?? '',
				Fossil_use_weighted: row.fossilUseWeighted ?? '',
				Mineral_use: row.mineralUse ?? '',
				Mineral_use_weighted: row.mineralUseWeighted ?? '',
			};
		});
		const columnNames = Object.keys(ROWS[0]).map((key) => {
			return t(key);
		});

		// generate worksheet and workbook
		const worksheet = utils.json_to_sheet(ROWS);
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Dates');

		// fix headers
		utils.sheet_add_aoa(worksheet, [columnNames], { origin: 'A1' });

		const nameSheet = `${t('Emballages')}${
			params.productUid ? `_${t('pour')}_${t('product')}_${String(params.productUid).replaceAll(' ', '_')}` : ''
		}_${dayjs(new Date())?.format('DDMMYYYY')}.csv`;
		// create an XLSX file and try to save
		writeFile(workbook, nameSheet, { compression: true });
	} catch (error) {
		return Promise.reject(error);
	}
}
