import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { BoxIcon, Carrot, Database } from 'lucide-react';
import { useParams } from 'react-router-dom';

import {
	ITypeModelizationIngredientKey,
	ITypeModelizationPackagingKey,
	TYPE_MODELIZATION_INGREDIENT_PERSONALIZED,
	// TYPE_MODELIZATION_INGREDIENT,
	TYPE_MODELIZATION_PACKAGING,
	TYPE_MODELIZATION_PACKAGING_PERSONALIZED,
} from '@carbonmaps/shared/utils/constants';
import { useGetProductSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockCard from '../../../components/BlockCard';
import BlockListItem from '../../../components/BlockListItem';
import BlockTitle from '../../../components/BlockTitle';
import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import Skeleton from '../../../components/Skeleton';
import ExternalItem from '../../../components/product/ExternalItem';
import ProductTypePopover from '../../../components/product/ProductTypePopover';
import { useGlossary } from '../../../hooks/useGlossary';
import { useTranslation } from '../../../hooks/useTranslation';

import AuditBlock from './AuditBlock';

const styles = {
	title: css({
		marginBottom: '0px !important',
	}),
	content: css({
		// marginBottom: 24,
	}),
	skeleton: css({
		width: '100% !important',
		height: '120px !important',
	}),
};

const ProductCalculsBlock = () => {
	const theme = useTheme();
	const params = useParams();
	const { t } = useTranslation();
	const { g } = useGlossary();

	const { data, isLoading } = useGetProductSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		//period: 2023,
	});

	console.log('ProductCalculsBlock data===', data?.product?.typeModelizationIngredient);

	// ---- check if ingredient is personalized or not ---- //
	// custom type is if typeModelizationIngredient in ['n1_semantic','n1_inferred','n1_standard',]
	const isPersonalizedType = useMemo(() => {
		return TYPE_MODELIZATION_INGREDIENT_PERSONALIZED?.includes(
			data?.product?.typeModelizationIngredient?.toLowerCase() as any,
		);
	}, [data]);

	// ---- check when packaging type is personalized or not ---- //
	// custom type is if typeModelizationPackaging in ['n1_standard_packaging']
	const isPersonalizedPackaging = useMemo(() => {
		return TYPE_MODELIZATION_PACKAGING_PERSONALIZED?.includes(
			data?.product?.typeModelizationPackaging?.toLowerCase() as any,
		);
	}, [data]);

	return (
		<div className="flexColumn width100 gap16">
			<BlockTitle icon={<Database size={20} color={theme.palette.grey[700]} />} className={styles.title}>
				<LightTooltipGlossary title={g('donnees-de-calcul')}>
					<span className={'underline'}>{t('product-calculation-card-title')}</span>
				</LightTooltipGlossary>
			</BlockTitle>
			{/* <Typography className="grey900">{t('product-calculation-card-subtitle')}</Typography> */}
			<BlockCard>
				{isLoading ? (
					<Skeleton className={styles.skeleton} />
				) : (
					<>
						<div className={cx('flexColumn width100 gap8', styles.content)}>
							<ExternalItem
								label="recipe"
								value={isPersonalizedType ? `${t('Personnalisée')}` : t('reference')}
								popoverContent={
									<ProductTypePopover
										title={isPersonalizedType ? t('modeling_method') : t('reference')}
										content={
											isPersonalizedType ? (
												<Typography display="flex" alignItems="center" mb="6px">
													<Carrot size={20} color={theme.palette.grey[700]} />
													<Box component="span" ml="6px" color={theme.palette.grey[900]}>
														{t(
															`${
																(data?.product.typeModelizationIngredient?.toLowerCase() as ITypeModelizationIngredientKey) ||
																'n1_inferred_ingredient'
															}`,
															{ recipe: t('recipe'), recipe_lowercase: toLowerCase(t('recipe')) },
														)}
													</Box>
												</Typography>
											) : (
												<>
													{t(data?.product?.labelPortfolioCmaps ?? data?.product?.labelPortfolio)}
													<Box component="span" fontStyle={'italic'} color={theme.palette.grey[700]}>
														{' '}
														(
														{data?.product?.bddRefPortfolio
															? data?.product?.bddRefPortfolio
															: data?.product?.ingredients?.length
															? data?.product?.ingredients[0].ingredient?.get('bddIcv') ||
															  data?.product?.ingredients[0].ingredient?.get('bddRef')
															: ''}
														)
													</Box>
												</>
											)
										}
									/>
								}
								variant={isPersonalizedType ? 'primary' : 'secondary'}
							/>
							<ExternalItem
								label="emballage"
								value={isPersonalizedPackaging ? t('Personnalisée') : t('Référence')}
								popoverContent={
									<ProductTypePopover
										title={isPersonalizedPackaging ? t('modeling_method') : t('reference')}
										content={
											isPersonalizedPackaging ? (
												<Typography display="flex" alignItems="center" mb="6px">
													<BoxIcon size={20} color={theme.palette.grey[700]} />
													<Box component="span" ml="6px" color={theme.palette.grey[900]}>
														{t(
															TYPE_MODELIZATION_PACKAGING[
																(data?.product?.typeModelizationPackaging?.toLowerCase() as ITypeModelizationPackagingKey) ||
																	'n1_portfolio_agb_packaging'
															],
															{ recipe: t('recipe'), recipe_lowercase: toLowerCase(t('recipe')) },
														)}
													</Box>
												</Typography>
											) : (
												<>
													{t(data?.product?.labelPortfolioCmaps)}
													<Box component="span" fontStyle={'italic'} color={theme.palette.grey[700]}>
														{' '}
														(
														{data?.product?.bddRefPortfolio
															? data?.product?.bddRefPortfolio
															: data?.product?.ingredients?.length
															? data?.product?.ingredients[0].ingredient?.get('bddIcv') ||
															  data?.product?.ingredients[0].ingredient?.get('bddRef')
															: ''}
														)
													</Box>
												</>
											)
										}
									/>
								}
								variant={isPersonalizedPackaging ? 'primary' : 'secondary'}
							/>
							<ExternalItem
								label="transport"
								value={t('reference')}
								variant="secondary"
								popoverContent={
									<ProductTypePopover
										title={t('reference')}
										content={
											<>
												{t(data?.product?.labelPortfolioCmaps)}
												<Box component="span" fontStyle={'italic'} color={theme.palette.grey[700]}>
													{' '}
													(
													{data?.product?.bddRefPortfolio
														? data?.product?.bddRefPortfolio
														: data?.product?.ingredients?.length
														? data?.product?.ingredients[0].ingredient?.get('bddIcv') ||
														  data?.product?.ingredients[0].ingredient?.get('bddRef')
														: ''}
													)
												</Box>
											</>
										}
									/>
								}
							/>
							<ExternalItem
								label="distribution"
								value={t('reference')}
								variant="secondary"
								popoverContent={
									<ProductTypePopover
										title={t('reference')}
										content={
											<>
												{t(data?.product?.labelPortfolioCmaps)}
												<Box component="span" fontStyle={'italic'} color={theme.palette.grey[700]}>
													{' '}
													(
													{data?.product?.bddRefPortfolio
														? data?.product?.bddRefPortfolio
														: data?.product?.ingredients?.length
														? data?.product?.ingredients[0].ingredient?.get('bddIcv') ||
														  data?.product?.ingredients[0].ingredient?.get('bddRef')
														: ''}
													)
												</Box>
											</>
										}
									/>
								}
							/>
							<ExternalItem
								label="consommation"
								value={t('reference')}
								variant="secondary"
								popoverContent={
									<ProductTypePopover
										title={t('reference')}
										content={
											<>
												{t(data?.product?.labelPortfolioCmaps)}
												<Box component="span" fontStyle={'italic'} color={theme.palette.grey[700]}>
													{' '}
													(
													{data?.product?.bddRefPortfolio
														? data?.product?.bddRefPortfolio
														: data?.product?.ingredients?.length
														? data?.product?.ingredients[0].ingredient?.get('bddIcv') ||
														  data?.product?.ingredients[0].ingredient?.get('bddRef')
														: ''}
													)
												</Box>
											</>
										}
									/>
								}
							/>
						</div>
						<AuditBlock />{' '}
					</>
				)}
			</BlockCard>
		</div>
	);
};

export default ProductCalculsBlock;
