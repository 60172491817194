import { css, cx } from '@emotion/css';
import { Typography, useTheme } from '@mui/material';
import { Plus } from 'lucide-react';
import { useToggle } from 'react-use';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import Dialog from '../../../components/dialogs/Dialog';
import { useTranslation } from '../../../hooks/useTranslation';

import UserForm from './UserForm';

const containerCss = {
	gap: css({
		gap: 32,
		marginBottom: 24,
		marginTop: 24,
	}),
};

const CreateUser = () => {
	const [isDialogOpen, toggleDialog] = useToggle(false);
	// theme
	const theme = useTheme();

	// translation
	const { t } = useTranslation();

	return (
		<div className={cx(containerCss.gap)}>
			<BButton
				onClick={toggleDialog}
				iconLeft={<Plus color={theme.palette.grey[700]} />}
				variant="primary"
				label={
					<Typography color="white" fontWeight={700}>
						{t('add-user-button')}
					</Typography>
				}
				addStyles={{ display: 'inline-flex', alignSelf: 'center' }}
			/>
			<Dialog
				title={''}
				isLoading={false}
				description={''}
				open={isDialogOpen}
				toggle={toggleDialog}
				closeButton={true}
				withCancelButton={false}
			>
				<UserForm toggle={toggleDialog} />
			</Dialog>
		</div>
	);
};

export default CreateUser;
