import { ReactNode, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { IPackaging } from '@carbonmaps/shared/types/packaging.types';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { usePackagingProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { important, pxToRem } from '@carbonmaps/ui/utils/styles';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import DonutChart from '../../../components/charts/DonutChart';
import Unit from '../../../components/units/Unit';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ProductModel from '../../../models/Product.model';
import { CARBONE_INDICATOR, WATER_INDICATOR } from '../../../utils/constants';

const CarbonImpactBlock = () => {
	const theme = useTheme();
	const params = useParams();

	// translation
	const { t } = useTranslation();

	const { indicator, setIndicator } = useApp();

	const { data: modelizationData, isLoading } = usePackagingProduct({
		productId: params.productId!,
	});

	const data = useMemo(() => {
		return modelizationData?.packaging.map((item: IPackaging) => {
			const productModel = new ProductModel(modelizationData);

			let value = 0;

			switch (indicator) {
				case CARBONE_INDICATOR:
					value = productModel.getIntensity('gesPackaging')
						? ((item?.gesWeighted || 0) / productModel.getIntensity('gesPackaging')) * 100
						: 0;
					break;
				case WATER_INDICATOR:
					value = productModel.getIntensity('waterUsePackaging')
						? ((item?.waterUseWeighted || 0) / productModel.getIntensity('waterUsePackaging')) * 100
						: 0;
					break;
				default:
					value = productModel.getIntensity('gesPackaging')
						? ((item?.gesWeighted || 0) / productModel.getIntensity('gesPackaging')) * 100
						: 0;
					break;
			}

			return {
				value,
				name: item?.packaging?.get('label'),
			};
		});
	}, [modelizationData, indicator]);

	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBONE_INDICATOR);
	}, [setIndicator]);

	const total = _.sumBy(data, 'value');

	const formattedData = useMemo(() => {
		if (!data) return [];

		return data
			.sort((a: any, b: any) => {
				return b.value - a.value;
			})
			.map((item: any, index: number) => {
				// console.log('fsdfsdfds', item);
				const colorPool = [
					siteColors.green500,
					siteColors.water500,
					'#c74600',
					'#ffbe17',
					'#8f03d1',
					siteColors.primaryHover,
					siteColors.green500,
				];

				const getColor = () => {
					switch (index) {
						case 0:
							return indicator === CARBONE_INDICATOR ? theme.palette.primary.main : siteColors.water500;

						default:
							return colorPool[(index % colorPool.length) - 1];
					}
				};

				return { category: 'idk-??', categoryValue: item.name ?? '---', y: item.value, color: getColor() };
			});
	}, [data, indicator, theme]);

	const data2 = useMemo(() => {
		let value = 0;
		let res;

		const productModel = new ProductModel(modelizationData as any);

		switch (indicator) {
			case CARBONE_INDICATOR:
				res = productModel.getIntensity('gesPackaging')?.toFixed(2);
				if (res) value = parseFloat(res);
				break;

			case WATER_INDICATOR:
				res = productModel.getIntensity('waterUsePackaging')?.toFixed(2);
				if (res) value = parseFloat(res);
				break;

			default:
				res = productModel.getIntensity('gesPackaging')?.toFixed(2);
				if (res) value = parseFloat(res);
				break;
		}

		return value;
	}, [data, indicator, theme]);

	const indicatorConfigItems: Record<string, { label: string; unit: ReactNode; unit2: ReactNode; color: string }> = {
		[CARBONE_INDICATOR]: {
			// eslint-disable-next-line quotes
			label: 'product-allocation-per-kilo',
			unit: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} />,
			unit2: <Unit measure="carbonIntensityKg" color={theme.palette.primary.main} />,
			color: theme.palette.primary.main,
		},
		[WATER_INDICATOR]: {
			// eslint-disable-next-line quotes
			label: "Répartition de l'eau pondérée",
			unit: <Unit measure="waterIntensity" color={siteColors.water500} />,
			unit2: <Unit measure="waterIntensity" color={siteColors.water500} />,
			color: siteColors.water500,
		},
	};

	return (
		<>
			<BlockTitle>
				{t(indicatorConfigItems[indicator].label, {
					product: t('product'),
					product_lowercase: toLowerCase(t('product')),
					per_kilo: toLowerCase(t('per_kilo')),
					of_product: t('of_product'),
				})}
			</BlockTitle>
			<BlockCard
				sx={{ p: important(pxToRem(0)), paddingTop: important(pxToRem(6)), paddingBottom: important(pxToRem(18)) }}
			>
				<Box /*  bgcolor="red" */ display="flex" justifyContent="center" paddingTop={pxToRem(20)}>
					<Box width="168px">
						<DonutChart
							width={150}
							innerRadius={30}
							color={'#5e993e'}
							data={formattedData}
							indicator={'undefined'}
							onClick={function (item: any): void {
								// throw new Error('Function not implemented.');
							}}
							// renderContent={() => {
							// 	return <></>;
							// }}
							renderTooltip={() => {
								return null;
							}}
							content={
								<Box display="flex" flexDirection="column" alignItems="center">
									<Typography
										variant="h2"
										fontWeight="normal"
										color={indicatorConfigItems[indicator].color}
										className="number"
									>
										{formatNumber(data2, undefined, 2)}
									</Typography>
									<Box lineHeight={1} color={indicatorConfigItems[indicator].color}>
										{indicatorConfigItems[indicator].unit2}
									</Box>
								</Box>
							}
						/>
					</Box>
				</Box>
				{/* Legend */}
				<Box paddingLeft={pxToRem(22)} mt={pxToRem(8)}>
					{formattedData.map((e: any) => {
						return (
							<Box display="flex" alignItems="center" mb={pxToRem(3)}>
								<Box width={pxToRem(16)} height={pxToRem(16)} bgcolor={e.color} borderRadius={300} />
								<Typography marginLeft={pxToRem(8)}>
									<Box
										width={pxToRem(55)}
										component="span"
										display="inline-block"
										sx={{ fontWeight: 600 }}
										className="number textNoWrap"
									>
										{formatNumber((e.y * 100) / total, undefined, 2)} %{' '}
									</Box>{' '}
									{e.categoryValue}
								</Typography>
							</Box>
						);
					})}
				</Box>
			</BlockCard>
		</>
	);
};

export default CarbonImpactBlock;
