import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { getGlossariesAction, saveGlossaryAction } from '@carbonmaps/ui/actions/glossaries.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';

const styles = {
	container: css({
		paddingTop: 16,
		gap: 10,
	}),
	selectContainer: css({
		minWidth: '150px',
		minHeight: '42px',
		backgroundColor: 'rgb(236, 233, 242) !important',
		'.MuiInputBase-root': {
			width: '250px',
			minHeight: '42px',
			backgroundColor: 'rgb(236, 233, 242) !important',
		},
	}),
	text: css({
		fontSize: '16px !important',
		fontWeight: '500 !important',
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
			padding: '5px!important',
		},

		// '.bordered tr td:first-of-type,.bordered tr th:first-of-type,.bordered tr th:first-of-type div,.bordered tr td:first-of-type div': {
		// 	width: '50px !important',
		// },
		'.bordered tr td': {
			width: '20%',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

const GlossaryTable = () => {
	// ---- theme ---- //
	const theme = useTheme();
	// ---- translation ---- //
	const { t } = useTranslation();
	// ---- styles ---- //
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);
	// ---- fetch data ref ---- //
	const fetchIdRef = useRef(0);
	// ---- company selected ---- //
	const queryParams = useQueryParams();

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState({
		input: '',
		page: 1,
		size: 100,
	});

	const [dataTable, setDataTable] = useState<any>([]);
	const queryClient = useQueryClient();
	const [selectedPage, setSelectedPage] = useState<any>('-');

	// ---- save translation ---- //
	const { mutate: saveGlossary } = useMutation({
		mutationKey: ['saveGlossary'],
		mutationFn: saveGlossaryAction,
		onSuccess: async () => {
			// handleCloseModal();
			queryClient.invalidateQueries({ queryKey: ['getGlossaries'] });
		},
		onError: (error: any) => {
			console.log('error', error);
		},
	});

	const handleSaveGlossary = useCallback(
		(data: any) => {
			saveGlossary({ ...data });
		},
		[saveGlossary],
	);

	const updateMyData = (rowIndex: number, columnId: string, value: any) => {
		const newData = dataTable?.map((row: any, index: number) => {
			if (index === rowIndex) {
				const newValue = {
					...dataTable[rowIndex],
					[columnId]: value,
				};

				handleSaveGlossary({
					glossaryId: newValue?.glossaryId,
					text_fr: newValue?.text_fr,
					text_en: newValue?.text_en,
					key: newValue?.key,
					term: newValue?.term,
					[columnId]: value,
				});

				return newValue;
			}

			return row;
		});
		// We also turn on the flag to not reset the page
		setDataTable(newData);
	};

	// ---- columns ---- //
	const tableColumns = useMemo(() => {
		return [
			// {
			// 	Header: (props: any) => {
			// 		return <TableHeaderCell valueType="string" column={props.column} label={t('key')} />;
			// 	},
			// 	accessor: 'key',
			// 	editable: true,
			// 	sortDescFirst: true,
			// 	component: 'SimpleCell',
			// 	type: 'string',
			// },
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('termes')} />;
				},
				accessor: 'term',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('fr_text')} />;
				},
				accessor: 'text_fr',
				editable: true,
				sortDescFirst: true,
				component: 'EditableInput',
				type: 'string',
				props: {
					inputComponent: 'textarea',
				},
				disableSortBy: true,
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('en_text')} />;
				},
				accessor: 'text_en',
				editable: true,
				sortDescFirst: true,
				component: 'EditableInput',
				type: 'string',
				props: {
					inputComponent: 'textarea',
				},
				disableSortBy: true,

			},
		];
	}, [t]);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- fetch data version ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getGlossaries',
			{
				...paramsTable,
			},
		],
		queryFn: getGlossariesAction,
	} as any);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
			});
		},
		[updateTableParams],
	);

	useEffect(() => {
		if (!(data as any)?.data) return;

		setDataTable((data as any)?.data ?? []);
	}, [data]);

	console.log(dataTable);
	return (
		<div className={cx('flexColumn', styles.container)}>
			<TableComponent
				tableName={t('glossary-title')}
				skeleton={isLoading}
				loading={false}
				fetchData={updateTableParams}
				pageCount={(data as any)?.meta?.last_page || 0}
				noDataMessage={t('empty-data-table')}
				pageSize={paramsTable.size}
				columns={tableColumns}
				data={dataTable ?? []}
				addStyles={stylesTable}
				manualSortBy
				onSortingColumn={handleSortingTable}
				isSelectable={false}
				updateMyData={updateMyData}
			/>
		</div>
	);
};

export default GlossaryTable;
