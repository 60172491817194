import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import SEO from '../../components/SEO';
import MetaDataCard from '../../components/card/MetaDataCard';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

import GlobalIndicator from './GlobalIndicator';
import LifeCycleQuote from './LifeCycleQuote';
import QuoteSheetContent from './QuoteSheetContent';
import QuoteSheetHeader from './QuoteSheetHeader';
import RepartitionGraph from './RepartitionGraph';
import { useQuoteSheet, useQuoteStore } from './quote.hooks';
const styles = {
	grid: css({
		display: 'grid',

		gridTemplateColumns: '7fr 5fr',
		'@media screen and (min-width: 1380px)': {
			gridTemplateColumns: '8fr 4fr',
		},
	}),
	rightContainer: css({
		padding: '32px 26px',
	}),
};

const QuoteSheet = () => {
	const { setBreadcrumbs } = useApp();
	const params = useParams();
	const { data, isLoading } = useQuoteSheet({
		quoteId: params.quoteId || '',
	});

	// translation
	const { t } = useTranslation();

	const { quote, setQuote } = useQuoteStore();

	useEffect(() => {
		setBreadcrumbs([
			{ text: t('Devis carbone'), link: FRONT_PATH_NAMES.quote },
			{ text: t('quote-sheet-breadcrumbs'), isBeta: false },
		]);
	}, [setBreadcrumbs, t]);

	// ---- update zustand store  ---- //
	useEffect(() => {
		if (!data) return;
		setQuote(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div>
			<SEO title={`${t('Devis carbone')} - CarbonMaps`} />
			<QuoteSheetHeader />
			<div className={cx(styles.grid)}>
				<div
					css={{
						maxHeight: '84.5vh',
						overflowY: 'scroll',
					}}
				>
					<QuoteSheetContent />
				</div>
				<div
					className={cx('bgGrey300', styles.rightContainer)}
					css={{
						maxHeight: '84.5vh',
						overflowY: 'scroll',
					}}
				>
					<GlobalIndicator />
					<LifeCycleQuote />
					<RepartitionGraph />
					<MetaDataCard
						createdBy={data?.user ? `${data?.user?.get('firstName')} ${data?.user?.get('lastName')}` : null}
						createdAt={data?.createdAt}
						updatedAt={data?.updatedAt}
					/>
				</div>
			</div>
		</div>
	);
};

export default QuoteSheet;
