import { ReactNode } from 'react';

import { cx } from '@emotion/css';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';

import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { siteColors } from '../lib/colors';
import { TOP_BAR_HEIGHT } from '../utils/constants';

type Props = {
	onLeft: ReactNode;
	onRight: ReactNode;
	className?: string;
	isSticky?: boolean;
};

const ActionsBar = ({ onLeft, onRight, className = '', isSticky = true }: Props) => {
	const theme = useTheme();

	return (
		<Grid
			className={cx('actionsBar-root flexRow noWrap', className)}
			container
			// padding={showSearchField ? '24px 40px 24px 40px' : 0}
			padding={'24px 32px 24px 32px'}
			bgcolor="white"
			// borderBottom={`1px solid ${theme.palette.grey[400]}`}
			sx={{
				// position: showSearchField ? 'sticky' : 'auto',
				position: isSticky ? 'sticky' : 'auto',
				top: TOP_BAR_HEIGHT,
				// zIndex: showSearchField ? 100 : 1,
				zIndex: 100,
				[theme.breakpoints.up('xl')]: {
					justifyContent: 'space-between',
				},
				flexWrap: 'nowrap',
				alignItems: 'center',
				// bgcolor: 'red',
			}}
		>
			<Grid
				item
				gap={pxToRem(16)}
				className="flexRow"
				sx={{
					width: '100%',
					// mb: pxToRem(16),
					[theme.breakpoints.up('xl')]: {
						width: 'unset',
						mb: 0,
					},
					flexWrap: 'wrap',
					// alignItems: 'center'
				}}
			>
				{onLeft}
			</Grid>

			<Grid
				className="flexRow"
				item
				flexWrap={'nowrap'}
				sx={{
					// width: '100%',
					[theme.breakpoints.up('xl')]: {
						width: 'unset',
					},
					borderRadius: '8px',
					background: siteColors.common.white,
					alignItems: 'end',
					padding: '2px 4px 2px 4px',
				}}
			>
				{onRight}
			</Grid>
		</Grid>
	);
};

export default ActionsBar;
