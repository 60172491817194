import { useEffect } from 'react';

import { css, cx } from '@emotion/css';

import { MESSAGE_KEY_APBO } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import ApboCard from '../../components/apbo/card/ApboCard';

import ApboFilter from './ApboFilter';
import CarbonGraph from './CarbonGraph';
import CarbonLabelView from './CarbonLabelView';
import DiagStatView from './DiagStatView';
import Formation from './Formation';
import GlobalIndicator from './GlobalIndicator';
import Header from './Header';
import IndicatorView from './IndicatorView';
import LeverActionsView from './leverActions/LeverActionsView';
import MethaneIndicator from './MethaneIndicator';

const styles = {
	header: css({
		height: 212,
		backgroundImage: 'url(/cap2er.svg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	}),
	container: css({
		width: 'calc(100% - 184px)',
		margin: '0 auto',
		paddingBottom: 80,
	}),
};

const ApboDashboard = () => {
	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([
			{
				text: 'CAP’2ER',
			},
		]);
	}, [setBreadcrumbs]);
	return (
		<div className="flexColumn width100">
			<div className={cx('width100', styles.header)}></div>
			<div className={cx('flexColumn gap40', styles.container)}>
				<Header />
				<div className="flexColumn width100 gap56">
					<ApboFilter />
					<DiagStatView />
					<GlobalIndicator />
					<IndicatorView />
					<LeverActionsView />
					<Formation />
					<CarbonLabelView />
				</div>
			</div>
		</div>
	);
};

export default ApboDashboard;
