import { type QueryFunctionContext } from '@tanstack/react-query';
import dayjs from 'dayjs';
import _ from 'lodash';
import { utils, writeFile } from 'xlsx';

import { type IIngredient } from '@carbonmaps/shared/types/ingredient.types';
import { functionName } from '@carbonmaps/shared/utils/constants';

import { ExportFormat } from '../../../../components/dialogs/ExportDialog';
import IngredientModel from '../../../../models/Ingredient.model';


export const findIngredientFamilyAction = async (context: QueryFunctionContext<readonly ['findIngredientFamily', Record<string, any>]>) => {
	try {
		const params = context.queryKey[1];

		const data = await Parse.Cloud.run(functionName.temporality.findIngredient, { view: 'general-view-if-ingredients->ingredients-table', ...params }) as {
			ingredients: any[];
			meta: {
				last_page: number;
				count: number;
			}
		};
		return data;
	} catch (error) {
		console.log('---- findIngredientFamilyAction-----------', error);
		return Promise.reject(error);
	}
};

export const findIngredientFamilyTradeoffAction = async (context: QueryFunctionContext<readonly ['findIngredientFamily', Record<string, any>]>) => {
	try {
		const params = context.queryKey[1];

		const data = await Parse.Cloud.run(functionName.temporality.findIngredientFamilyTradeoff, { view: 'general-view-if-ingredients->ingredients-tradeoff', ...params }) as any[];
		return data;
	} catch (error) {
		console.log('---- findIngredientFamilyTradeoffAction-----------', error);
		return Promise.reject(error);
	}
};


//--------------------------------------------------------------------------------------//
//                                                                                      //
//                                      Export                                       //
//                                                                                      //
//--------------------------------------------------------------------------------------//
export type GetParamsExportIngredient = {
	ids?: string[];
	facetFilters?: any[];
	input?: string;
	supplierIds: any[];
	format: ExportFormat;
	// ingredientId?: string;
	matchField?: string;
	productId?: string;
	productUid?: string;
	t: any;
	selectedYear?: number;
	companyName: string | undefined;
	exportKey?: string;
};

export type ExportIngredientQueryKey = readonly ['exportIngredients', GetParamsExportIngredient];

export async function exportIngredientsAction(context: QueryFunctionContext<ExportIngredientQueryKey>) {
	try {
		const {
			queryKey: [, params],
		} = context;

		// const {
		// 	data: ingredients,
		// 	categoryFieldName: categoriesFieldName,
		// }: { data: IIngredient[]; categoryFieldName: string } = await Parse.Cloud.run('getAllIngredient', {
		// 	isExport: true,
		// 	ids: params?.ids,
		// 	facetFilters: params.facetFilters,
		// 	input: params?.input,
		// 	supplierIds: params?.supplierIds,
		// 	matchField: params?.matchField,
		// 	productId: params?.productId,
		// });
		// const {
		// 	data: ingredients,
		// 	categoryFieldName: categoriesFieldName,
		// }: { data: IIngredient[]; categoryFieldName: string }
		const categoriesFieldName = 'null';

		const ingredients = await Parse.Cloud.run(functionName.ingredient.exportIngredients, {
			isExport: true,
			ids: params?.ids,
			facetFilters: params.facetFilters,
			input: params?.input,
			supplierIds: params?.supplierIds,
			matchField: params?.matchField,
			productId: params?.productId,
			view: 'general-export-ingredient',
			selectedYear: params?.selectedYear,
			exportKey: params?.exportKey,
		});

		const t = params.t;
		// const companyCode = categoriesFieldName?.replace('Categories', '');
		const facetHeaders = new Set<string>();

		const ROWS = ingredients.map((row: any) => {
			const rowCategories = (row as any)[categoriesFieldName] ?? {};

			Object.keys(rowCategories).forEach((key) => {
				facetHeaders.add(key);
			});

			const ingredientObject = new IngredientModel(row);

			//important For the column names to be in sync with the values, please respect the order in the header and categories
			return {
				'ID produit fini': row.uidProduct,
				// Label_Produit: row.labelProduct,
				'ID ingrédient': row.uid,
				'Label ingrédient': row.label,
				Composition: row.composition,
				'Label ingrédient CMAPS': row.labelCmaps,
				'BDD de référence': row.bddIcv || row.bddRef,
				Origine: row.labelOrigine,
				'Origine de référence CMAPS': row.labelOrigineCmaps,
				// ! ===============	// ! ===============	// ! ===============

				'ICV spécifique': _.toLower(row.tagAdvanced) === 'yes' ? 'Oui' : 'Non',
				// ! ===============	// ! ===============	// ! ===============
				GES_agriculture_N1_ING:
					row.tagAdvanced === 'yes' ? row?.gesAgricultureN1 : row?.gesAgricultureN1 || row?.gesAgriculture,
				GES_agriculture_N2_ING: row.tagAdvanced === 'yes' ? row?.gesAgricultureN2 || row?.gesAgriculture : '',

				GES_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'ges',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				GES_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'ges',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				GES_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport('ges', 'Agriculture', true, false, 'N1'),
				GES_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport('ges', 'Agriculture', true, false, 'N2'),

				GES_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'ges',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				GES_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'ges',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				Ozone_1_agriculture_N1_ING:
					row.tagAdvanced === 'yes' ? row?.ozone1AgricultureN1 : row?.ozone1AgricultureN1 || row?.ozone1Agriculture,
				Ozone_1_agriculture_N2_ING: row.tagAdvanced === 'yes' ? row?.ozone1AgricultureN2 || row?.ozone1Agriculture : '',

				Ozone_1_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'ozone1',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Ozone_1_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'ozone1',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Ozone_1_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'ozone1',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Ozone_1_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'ozone1',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Ozone_1_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'ozone1',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Ozone_1_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'ozone1',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				Rayonnements_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.rayonnementsAgricultureN1
						: row?.rayonnementsAgricultureN1 || row?.rayonnementsAgriculture,
				Rayonnements_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.rayonnementsAgricultureN2 || row?.rayonnementsAgriculture : '',

				Rayonnements_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'rayonnements',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Rayonnements_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'rayonnements',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Rayonnements_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'rayonnements',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Rayonnements_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'rayonnements',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				Rayonnements_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'rayonnements',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Rayonnements_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'rayonnements',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Ozone_2_agriculture_N1_ING:
					row.tagAdvanced === 'yes' ? row?.ozone2AgricultureN1 : row?.ozone2AgricultureN1 || row?.ozone2Agriculture,
				Ozone_2_agriculture_N2_ING: row.tagAdvanced === 'yes' ? row?.ozone2AgricultureN2 || row?.ozone2Agriculture : '',

				Ozone_2_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'ozone2',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Ozone_2_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'ozone2',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Ozone_2_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'ozone2',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Ozone_2_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'ozone2',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Ozone_2_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'ozone2',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Ozone_2_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'ozone2',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============

				Particules_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.particulesAgricultureN1
						: row?.particulesAgricultureN1 || row?.particulesAgriculture,
				Particules_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.particulesAgricultureN2 || row?.particulesAgriculture : '',

				Particules_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'particules',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Particules_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'particules',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Particules_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'particules',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Particules_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'particules',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Particules_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'particules',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Particules_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'particules',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============

				Toxicite_1_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.toxicite1AgricultureN1
						: row?.toxicite1AgricultureN1 || row?.toxicite1Agriculture,
				Toxicite_1_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.toxicite1AgricultureN2 || row?.toxicite1Agriculture : '',

				Toxicite_1_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'toxicite1',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Toxicite_1_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'toxicite1',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Toxicite_1_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'toxicite1',
					'Agriculture',
					true,
					false,
					'N1',
				),

				Toxicite_1_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'toxicite1',
					'Agriculture',
					true,
					false,
					'N1',
				),

				Toxicite_1_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'toxicite1',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Toxicite_1_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'toxicite1',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),
				Toxicite_2_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.toxicite2AgricultureN1
						: row?.toxicite2AgricultureN1 || row?.toxicite2Agriculture,
				Toxicite_2_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.toxicite2AgricultureN2 || row?.toxicite2Agriculture : '',

				Toxicite_2_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'toxicite2',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),

				Toxicite_2_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'toxicite2',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Toxicite_2_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'toxicite2',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Toxicite_2_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'toxicite2',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Toxicite_2_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'toxicite2',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Toxicite_2_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'toxicite2',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============

				Acidification_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.acidificationAgricultureN1
						: row?.acidificationAgricultureN1 || row?.acidificationAgriculture,
				Acidification_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.acidificationAgricultureN2 || row?.acidificationAgriculture : '',

				Acidification_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'acidification',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Acidification_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'acidification',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Acidification_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'acidification',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Acidification_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'acidification',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Acidification_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'acidification',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Acidification_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'acidification',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============

				Eutrophisation_1_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.eutrophisation1AgricultureN1
						: row?.eutrophisation1AgricultureN1 || row?.eutrophisation1Agriculture,
				Eutrophisation_1_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.eutrophisation1AgricultureN2 || row?.eutrophisation1Agriculture : '',

				Eutrophisation_1_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'eutrophisation1',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),

				Eutrophisation_1_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'eutrophisation1',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Eutrophisation_1_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'eutrophisation1',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Eutrophisation_1_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'eutrophisation1',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Eutrophisation_1_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'eutrophisation1',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Eutrophisation_1_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'eutrophisation1',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============
				Eutrophisation_2_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.eutrophisation2AgricultureN1
						: row?.eutrophisation2AgricultureN1 || row?.eutrophisation2Agriculture,
				Eutrophisation_2_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.eutrophisation2AgricultureN2 || row?.eutrophisation2Agriculture : '',

				Eutrophisation_2_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'eutrophisation2',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Eutrophisation_2_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'eutrophisation2',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Eutrophisation_2_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'eutrophisation2',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Eutrophisation_2_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'eutrophisation2',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Eutrophisation_2_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'eutrophisation2',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Eutrophisation_2_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'eutrophisation2',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============
				Eutrophisation_3_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.eutrophisation3AgricultureN1
						: row?.eutrophisation3AgricultureN1 || row?.eutrophisation3Agriculture,
				Eutrophisation_3_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.eutrophisation3AgricultureN2 || row?.eutrophisation3Agriculture : '',

				Eutrophisation_3_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'eutrophisation3',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),

				Eutrophisation_3_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'eutrophisation3',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Eutrophisation_3_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'eutrophisation3',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Eutrophisation_3_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'eutrophisation3',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Eutrophisation_3_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'eutrophisation3',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Eutrophisation_3_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'eutrophisation3',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============
				Ecotox_agriculture_N1_ING:
					row.tagAdvanced === 'yes' ? row?.ecotoxAgricultureN1 : row?.ecotoxAgricultureN1 || row?.ecotoxAgriculture,
				Ecotox_agriculture_N2_ING: row.tagAdvanced === 'yes' ? row?.ecotoxAgricultureN2 || row?.ecotoxAgriculture : '',

				Ecotox_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'ecotox',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),

				Ecotox_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'ecotox',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Ecotox_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'ecotox',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Ecotox_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'ecotox',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Ecotox_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'ecotox',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Ecotox_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'ecotox',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============

				Land_use_agriculture_N1_ING:
					row.tagAdvanced === 'yes' ? row?.landUseAgricultureN1 : row?.landUseAgricultureN1 || row?.landUseAgriculture,
				Land_use_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.landUseAgricultureN2 || row?.landUseAgriculture : '',

				Land_use_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'landUse',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Land_use_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'landUse',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Land_use_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'landUse',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Land_use_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'landUse',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Land_use_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'landUse',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Land_use_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'landUse',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============

				Water_use_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.waterUseAgricultureN1
						: row?.waterUseAgricultureN1 || row?.waterUseAgriculture,
				Water_use_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.waterUseAgricultureN2 || row?.waterUseAgriculture : '',

				Water_use_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'waterUse',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Water_use_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'waterUse',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				// ! ===============
				Water_use_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'waterUse',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Water_use_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'waterUse',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Water_use_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'waterUse',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Water_use_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'waterUse',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============

				Fossil_use_agriculture_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.fossilUseAgricultureN1
						: row?.fossilUseAgricultureN1 || row?.fossilUseAgriculture,
				Fossil_use_agriculture_N2_ING:
					row.tagAdvanced === 'yes' ? row?.fossilUseAgricultureN2 || row?.fossilUseAgriculture : '',

				Fossil_use_transformation_amont_N1_ING: ingredientObject.getRowValueExport(
					'fossilUse',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Fossil_use_transformation_amont_N2_ING: ingredientObject.getRowValueExport(
					'fossilUse',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Fossil_use_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'fossilUse',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Fossil_use_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'fossilUse',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Fossil_use_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'fossilUse',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Fossil_use_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'fossilUse',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				//  ===============
				Mineral_agriculture_use_N1_ING:
					row.tagAdvanced === 'yes'
						? row?.mineralUseAgricultureN1
						: row?.mineralUseAgricultureN1 || row?.mineralUseAgriculture,
				Mineral_agriculture_use_N2_ING:
					row.tagAdvanced === 'yes' ? row?.mineralUseAgricultureN2 || row?.mineralUseAgriculture : '',

				Mineral_transformation_amont_use_N1_ING: ingredientObject.getRowValueExport(
					'mineralUse',
					'TransformationUpstream',
					false,
					true,
					'N1',
				),
				Mineral_transformation_amont_use_N2_ING: ingredientObject.getRowValueExport(
					'mineralUse',
					'TransformationUpstream',
					false,
					true,
					'N2',
				),

				Mineral_use_agriculture_weighted_N1_ING: ingredientObject.getRowValueExport(
					'mineralUse',
					'Agriculture',
					true,
					false,
					'N1',
				),
				Mineral_use_agriculture_weighted_N2_ING: ingredientObject.getRowValueExport(
					'mineralUse',
					'Agriculture',
					true,
					false,
					'N2',
				),

				Mineral_use_transformation_amont_weighted_N1_ING: ingredientObject.getRowValueExport(
					'mineralUse',
					'TransformationUpstream',
					true,
					true,
					'N1',
				),
				Mineral_use_transformation_amont_weighted_N2_ING: ingredientObject.getRowValueExport(
					'mineralUse',
					'TransformationUpstream',
					true,
					true,
					'N2',
				),

				// ! ===============	// ! ===============	// ! ===============

				...rowCategories,
			};
		});

		const columnNames = Object.keys(ROWS[0] || {}).map((key) => {
			return t(key, {
				products: t('products'),
				recipe: t('recipe'),
				recipe_lowercase: t('recipe'),
				ingredient: t('ingredient'),
				an_ingredient: t('an_ingredient'),
				ingredient_lowercase: _.toLower(t('ingredient')),
				product: t('product'),
				of_product: t('of_product'),
				ingredients: t('ingredients'),
				produits_finis: t('produits_finis'),
			});
		});

		// generate worksheet and workbook
		const worksheet = utils.json_to_sheet(ROWS);
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Data');

		// fix headers
		utils.sheet_add_aoa(worksheet, [columnNames], { origin: 'A1' });

		const nameSheet = `${params.companyName}_${t('ingredients')}${params.productUid ? `_${t('pour')}_${t('product')}_${String(params.productUid).replaceAll(' ', '_')}` : ''
			}_${dayjs(new Date())?.format('DDMMYYYY')}.${params.format}`;
		// create an XLSX file and try to save
		writeFile(workbook, nameSheet, { compression: true });
	} catch (error) {
		console.log(error);
		return Promise.reject(error);
	}
}
