import { useEffect } from 'react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useTranslation } from '../../../hooks/useTranslation';

import CompanyHeader from './CompanyHeader';
import CompanyTable from './CompanyTable';
import CreateCompany from './CreateCompany';

const Company = (/* props: Props */) => {
	const { setBreadcrumbs } = useApp();
	const { t } = useTranslation();

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('company-breadcrumbs') }]);
	}, [setBreadcrumbs, t]);

	return (
		<Container header={<CompanyHeader />} seoText={`${t('organisation')} - Carbon Maps`}>
			<CreateCompany />
			<CompanyTable />
		</Container>
	);
};

export default Company;
