import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import Chip from '@mui/material/Chip';
import _ from 'lodash';

import { STATUS_SIMULATION } from '@carbonmaps/shared/utils/simulation';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';

type Props = {
	callback: any;
	row: any;
	column?: any;
	isLoading?: boolean;
	t?: any;
	isSelect?: boolean;
	statusDictionary?: any;
};
const chipStyles = css({
	// minWidth: '100px',
	// textTransform: 'none!important',
	// padding: '8px!important',
	textTransform: 'none',
	background: 'var(--color-background)!important',
	uppercase: 'none',
	borderRadius: '8px !important',
	border: '1px solid rgba(0, 0, 0, 0.10)!important',
	span: {
		color: cn(siteColors.grey900, '!important'),
		fontSize: '1rem !important',
		padding: '8px 8px !important',
	},
});

const selectStyles = css({
	'& fieldset': {
		border: 'none',
	},
	'& .MuiSelect-select': {
		paddingLeft: '0!important',
	},
});

const ChipsStatus = ({
	column,
	isLoading,
	row,
	callback,
	t,
	isSelect = true,
	statusDictionary = STATUS_SIMULATION,
}: Props) => {
	const status = row?.status || 'IN_PROGRESS';
	const [selectedValue, setSelectedValue] = useState(statusDictionary[status]?.code);

	const handleChange = (event: any) => {
		const selected = event.target.value;
		setSelectedValue(selected);
		return callback(row.objectId, selected);
	};

	useEffect(() => {
		setSelectedValue(statusDictionary[status]?.code);
	}, [row, status]);

	if (!isSelect) {
		return Object.keys(statusDictionary).map((key, index) => {
			return (
				<Chip
					key={statusDictionary[key]?.code}
					//	label={<>{statusDictionary[key]?.label} {isLoading && <CircularProgress size={20} />}</>}
					label={_.capitalize(t(statusDictionary[key]?.label))}
					className={cx(chipStyles)}
					style={
						{
							['--color-background' as any]: statusDictionary[key]?.color,
							display: selectedValue === statusDictionary[key]?.code ? 'block' : 'none',
						} as any
					}
				/>
			);
		});
	}

	return (
		<FormControl>
			<Select
				value={selectedValue}
				onChange={handleChange}
				className={cx(selectStyles)}
				// disabled={isLoading}
				sx={{
					'& svg.MuiSelect-icon': {
						display: 'none!important',
					},
				}}
			>
				{Object.keys(statusDictionary).map((key, index) => {
					return (
						<MenuItem value={statusDictionary[key]?.code} key={statusDictionary[key]?.code}>
							<Chip
								//	label={<>{statusDictionary[key]?.label} {isLoading && <CircularProgress size={20} />}</>}
								label={_.capitalize(t(statusDictionary[key]?.label))}
								className={cx(chipStyles)}
								style={{ ['--color-background' as any]: statusDictionary[key]?.color }}
							/>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default ChipsStatus;
