import _ from 'lodash';

/**
 * Take a series of number and convert it into percentages
 * @example
 * toPercentages([600, 600]) // [50, 50]
 */
export const toPercentages = (series: number[]) => {
	const sum = _.sum(series);

	const percentages = series.map((e) => {
		return (e / sum) * 100;
	});

	return percentages;
};

export const compactNumberFormat = (number: number, unit = '') => {
	if (!number) return '';
	const options = {
		notation: 'compact',
		compactDisplay: 'short',
	} as any;
	const format = Intl.NumberFormat('fr-FR', options);
	return `${format.format(number)}${unit}`;
};
