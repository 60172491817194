//--------------------------------------------------------------------------------------//
//                                    css utilities                                     //
//--------------------------------------------------------------------------------------//
export const remToPx = (value: number) => {
	return Math.round(parseFloat(String(value)) * 16);
};

export const pxToRem = (value: number) => {
	return `${value / 16}rem`;
};

export const important = (value: string) => {
	return `${value}!important`;
};

// export const composePx = (a: number, b?: number, c?: number, d?: number) => {
// 	return `${(a && pxToRem(a)) ?? 0} ${(b && pxToRem(b)) || 0} ${(c && pxToRem(c)) ?? 0} ${(d && pxToRem(d)) ?? 0}`;
// };

// console.log('====================================');
// console.log('sdfhlsdfhlsdhflsfazeaze_', composePx(0, 2));
// console.log('====================================');
