import { css, cx } from '@emotion/css';
import { Trash } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import { siteColors } from '../../../../lib/colors';

type DeleteButtonProps = {
	DeleteButtonProps?: any;
	onClick: () => void;
};

const styles = {
	button: css({
		background: 'transparent',
		padding: '0px !important',
		height: 'auto !important',

		width: '50px !important',
	}),
};

const DeleteButton = ({ onClick }: DeleteButtonProps) => {
	return (
		<div className={cx('flexCenter')}>
			<BButton
				label=""
				iconLeft={<Trash color={siteColors.grey700} size={20} />}
				variant="tertiary"
				className={styles.button}
				onClick={onClick}
			/>
		</div>
	);
};

export default DeleteButton;
