import { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { eachOfLimit } from 'async';
import { useParams } from 'react-router-dom';

import { getConfigFacets, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getCategoriesProduct } from '@carbonmaps/ui/actions/product.actions';
import { getCompanySuppliers } from '@carbonmaps/ui/actions/supplier.actions';

import { IFilterFolder } from '../../../../components/Filter';
import { Option } from '../../../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../../../components/layout/list/FilterContainer';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { getLabelNodeFacet } from '../../../../utils/utils';
const suppliersFolderLabel = 'Fournisseur';
const styles = {
	container: css({
		marginBottom: '-4px ',
		top: '118px !important',

		paddingLeft: '0px !important',
		paddingRight: '0px !important',
	}),
	searchContainer: css({
		'& svg': {
			marginLeft: '16px !important',
		},
		'& .MuiOutlinedInput-root': {
			width: '365px !important',
			'& .MuiAutocomplete-input': {
				paddingLeft: '0px !important',
			},
		},
	}),
};

const ProductAcvFilter = () => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const params = useParams();
	const searchParams = searchQueryParams?.facetFilters ?? [];
	// custom facets suppliers
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);

	// translation
	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const dataFacets = useMemo(() => {
		return [...getConfigFacets(authData?.session.company, true, 'Product')];
	}, [authData?.session.company]);

	// filters values
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	// ---- fetch suppliers products ----- //
	const { data: suppliersData, isLoading: isSUppliersLoading } = useQuery({
		queryKey: ['getAllSuppliersForCompany', { itemType: 'product', isN2: true, isFilter: true }],
		queryFn: getCompanySuppliers,
	});

	const { data: facets, isLoading: isFacetsLoading } = useQuery({
		queryKey: [
			'getCategory',
			{
				input: searchQueryParams.input,
				facetFilters: searchQueryParams?.facetFilters, //searchQueryParams.input
				itemType: params?.itemType,
				path: 'acv',
			},
		],
		queryFn: getCategoriesProduct,
	});

	// ---- create custom facet for supplier ----
	useEffect(() => {
		if (!suppliersData) return;

		const suppliersFolder: IFilterFolder = {
			label: suppliersFolderLabel,
			isFacet: false,
			showCount: false,
			options: suppliersData.map((sup) => {
				return {
					label: sup.name,
					value: sup.id,
					folderLabel: suppliersFolderLabel,
				};
			}),
		};

		setFilterFolders((folders) => {
			const supplierFolderIndex = folders.findIndex((e) => {
				return e.label === suppliersFolderLabel;
			});

			if (supplierFolderIndex === -1) {
				const n2FolderIndex = folders.findIndex((e) => {
					if (e.isFacet) {
						return e.path === 'tagAdvancedModelization';
					}

					return false;
				});

				const n2Folder = folders[n2FolderIndex];
				return [...(n2Folder ? [n2Folder] : []), suppliersFolder, ...folders];
			}

			folders[supplierFolderIndex] = suppliersFolder;
			return folders;
		});
	}, [suppliersData]);

	useEffect(() => {
		//	if there are filters in the url
		const supplierIdsParams = searchQueryParams?.supplierIds ?? [];

		if (searchParams && searchParams.length) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			searchParams.forEach((item: any) => {
				const config = dataFacets.find((f: any) => {
					return f.field === item.path;
				});

				const arrayValues = item.values.map((v: any) => {
					const value = {
						facetPath: item.path,
						value: v,
						folderLabel: config?.label ?? item.path,
						label: getFacetLabel(item.path, authData?.session.company),
						isFacetBucket: true,
						type: typeof v,
						global: item.global === true ? true : false,
					};

					currentSelectedValues.push(value);
					return value;
				});

				currentAppliedValuesFolders.push({
					isFacet: true,
					label: config?.label ?? item.path,
					options: arrayValues,
				});
			});

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);

			//for supplier
			if (supplierIdsParams && supplierIdsParams.length) {
				if (!suppliersData) return;
				const values = suppliersData
					.filter((item: any) => {
						return supplierIdsParams.includes(item.id);
					})
					.map((sup) => {
						return {
							label: sup.name,
							value: sup.id,
							folderLabel: suppliersFolderLabel,
						};
					});
				const suppliersFolder: IFilterFolder = {
					label: suppliersFolderLabel,
					isFacet: false,
					showCount: false,
					options: values,
				};
				currentAppliedValuesFolders.push(suppliersFolder); // each one filter
				currentSelectedValues.push(...values); // in the base filter
			}

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchParams, suppliersData]);

	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facets) return;

			const facetFilterFolders: IFilterFolder[] = [];

			for await (const entry of Object.entries(facets.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;

				if (key !== 'tagAdvancedModelization') {
					const config = dataFacets.find((f: any) => {
						return f.field === key;
					});

					const facetLabel = config?.label || key;
					const currentFacetFolder: IFilterFolder = {
						label: facetLabel,
						options: [],
						isFacet: true,
						path: key,
						showCount: true,
					};

					await eachOfLimit(buckets as any[], 100, async (bucket, k) => {
						if (!bucket.count) {
							return;
						}

						const config = dataFacets.find((f: any) => {
							return f.field === key;
						});

						currentFacetFolder.options.push({
							value: bucket._id,
							label: getLabelValue(key, bucket._id),
							labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
								? getLabelNodeFacet(key, bucket._id, t)
								: undefined,
							count: bucket.count,
							folderLabel: facetLabel,
							isFacetBucket: true,
							facetPath: key,
							type: config?.type ?? 'hardcoded-undefined-facet-type',
							global: config?.global,
						});

						return;
					});

					facetFilterFolders.push(currentFacetFolder);
				}
			}

			setFilterFolders((folders) => {
				const notFacetFolders = folders.filter((e) => {
					return !e.isFacet;
				});

				return [...notFacetFolders, ...facetFilterFolders];
			});
		};

		asyncWrapper();
	}, [facets, authData?.session.company]);

	return (
		<FilterContainer
			skeleton={isFacetsLoading}
			isLoading={false}
			appliedValuesByFolders={appliedValuesByFolders}
			setAppliedValuesByFolders={setAppliedValuesByFolders}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			folders={filterFolders}
			setSearchQueryParams={setSearchQueryParams}
			suppliersFolderLabel={suppliersFolderLabel}
			isTableLoading={false}
			isExportLoading={false}
			searchPlaceholder={t('audit-search-placeholder')}
			searchClassName={styles.searchContainer}
			className={styles.container}
			withMore={false}
		/>
	);
};

export default ProductAcvFilter;
