import { useCallback, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { ClipboardCheck } from 'lucide-react';
import { createSearchParams, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import SEO from '../../../components/SEO';
import ScrollTop from '../../../components/ScrollTop';
import SheetPageLayout from '../../../components/layout/page/SheetPageLayout';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetOneProductModelization } from '../../../lib/react-query/features/modelization/modelization.hooks';
import ProductModel from '../../../models/Product.model';

import { siteColors } from '../../../lib/colors';
import EmissionBlock from './EmissionBlock';
import IngredientBlock from './IngredientBlock';
import LifeCycleBlock from './LifeCycleBlock';
import ModelizationBlock from './ModelizationBlock';
import PackagingBlock from './PackagingBlock';
import ReferenceBlock from './ReferenceBlock';

const styles = {
	content: css({
		width: '100%',
		marginBottom: 100,
	}),
};

const ModelizationSheet = () => {
	const params = useParams();
	const { setBreadcrumbs } = useApp();
	const { indicator } = useApp();
	const queryParams = useQueryParams();

	const searchParams = createSearchParams(queryParams as any)?.toString();

	const [notFound, setNotFound] = useState<boolean>(false);

	// translation
	const { t } = useTranslation();

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Modelization' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		trackEvent('Modelization');
	}, [trackEvent]);

	const { data, isLoading, isError, error } = useGetOneProductModelization({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		productId: params.modelizationId!,
		viewMode: indicator,
	});

	useEffect(() => {
		if (!data) {
			setBreadcrumbs([{ text: t('Modelizations') }]);
			return;
		}

		if (!isLoading && !data) {
			setNotFound(true);
		}

		setBreadcrumbs([
			{ text: t('Modelizations'), link: `${FRONT_PATH_NAMES.audit}/modelization?${searchParams}` },
			{ text: _.capitalize(data?.label) },
		]);
	}, [data, setBreadcrumbs, isLoading, t]);

	return (
		<>
			<SEO
				title={
					data?.label ? `${t('modelizations-title-module')} - ${_.capitalize(data?.label)} - CarbonMaps` : 'CarbonMaps'
				}
			/>
			<ScrollTop key={params.tab} behavior="auto" />
			<SheetPageLayout
				loading={isLoading}
				currentTab={params.tab}
				headerProps={{
					variant: 'fixed',
					icon: <ClipboardCheck size={40} color={siteColors.grey700} />,
					isBlueCheck: new ProductModel(data)?.isN2ModelizationType() ?? false,
					title: data?.label || '',
					subtitle: data?.uid || '',
					sx: { background: siteColors.blueGray10 },
				}}
				contentSingle={
					<div className={cx('flexColumn gap32', styles.content)}>
						<ModelizationBlock />
						<ReferenceBlock />
						<EmissionBlock />
						<LifeCycleBlock />
						<IngredientBlock />
						<PackagingBlock />
					</div>
				}
			/>
		</>
	);
};

export default ModelizationSheet;
