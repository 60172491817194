import React from 'react';

import ClickAwayListener, { type ClickAwayListenerProps } from '@mui/material/ClickAwayListener';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import type { Theme } from '@mui/material/styles/createTheme';
import useTheme from '@mui/material/styles/useTheme';

const classes = (theme: Theme) => {
	return {
		container: {
			display: 'flex',
			flexDirection: 'column',
			'& .Mui-checked': {
				color: theme.palette.primary.main,
			},

			label: {
				fontWeight: 600,
				fontSize: 16,
			},
			span: {
				textTransform: 'capitalize',
				textWrap: 'nowrap',
			},
		},
	};
};

interface BRadioGroupProps {
	value: string;
	options: {
		value: string;
		label: string;
	}[];
	onClickAway: ClickAwayListenerProps['onClickAway'];
	handleChange?: (event: any) => void;
	disabledValue?: string;
}

const BRadioGroup = ({ value, handleChange, options, disabledValue, onClickAway }: BRadioGroupProps) => {
	const theme = useTheme();
	const styles = classes(theme);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleChange && handleChange(event.target.value);
	};

	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<div css={styles.container as any}>
				<RadioGroup
					aria-labelledby="controlled-radio-buttons-group"
					name="controlled-radio-buttons-group"
					value={value}
					onChange={onChange}
				>
					{options.map((option, index) => {
						return (
							<FormControlLabel
								disabled={option.value === disabledValue}
								key={`radio-${index}`}
								value={option.value}
								control={<Radio />}
								label={option.label}
							/>
						);
					})}
				</RadioGroup>
			</div>
		</ClickAwayListener>
	);
};

export default BRadioGroup;
