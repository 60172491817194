import { css, cx } from '@emotion/css';
import { Divider } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { MoreHorizontal } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import BDropdown from '@carbonmaps/ui/components/saas/BDropdown';
import { cn, siteColors } from '../../../lib/colors';

const styles = {
	container: css({
		padding: '0px !important',
		minWidth: '20px !important',
		height: '20px !important',
		color: cn(siteColors.grey700, '!important'),
		paddingLeft: '16px !important',
	}),

	itemList: css({
		padding: '12px 16px !important',
		color: cn(siteColors.text, '!important'),
		fontSize: '14px !important',
	}),
};

type MoreButtonProps = {
	options: any;
};

const MoreButton = ({ options }: MoreButtonProps) => {
	return (
		<BDropdown
			menus={options}
			renderItem={(menu: any, index?: number) => {
				const IconComponent = (menu?.icon || 'div') as any;
				return (
					<>
						{index && index > 0 ? <Divider /> : null}
						<ListItemButton
							className={cx('flexRow alignCenter gap8', styles.itemList)}
							onClick={() => {
								return menu?.onClick();
							}}
						>
							<IconComponent size={20} color={siteColors.grey700} />
							{menu?.title}
						</ListItemButton>
					</>
				);
			}}
		>
			<BButton iconLeft={<MoreHorizontal />} variant="tertiary" label="" className={styles.container} />
		</BDropdown>
	);
};

export default MoreButton;
