import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';

type Props = {
	value: any;
};
const styles = {
	content: css({
		//color: 'var(--color)',
		fontSize: 24,
		fontWeight: 400,
		lineHeight: '82.4%', /* 19.776px */
		letterSpacing: '-0.48px',
		color: siteColors.green500,
		height: 24,
	}),
};

const Percent = ({ value }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<div className={cx(styles.content)}>
			{value === 100 ? 100 : displayValue(value, undefined, 2)} %
		</div>
	);
};

export default Percent;
