// import { type Interpolation, type Theme } from '@emotion/react';
import { type CSSProperties } from '@emotion/serialize';

type Props = {
	url: string;
	title?: string;
	style?: CSSProperties /* Interpolation<Theme> */;
};

const EmbedNotion = ({ title = 'carbonmaps-notion-page', url, style = {} }: Props) => {
	return (
		<iframe
			title={title}
			src={url}
			css={{ width: '100%', height: '100%', flex: 1, border: 'none', borderRadius: 'none', padding: 'none', ...style }}
		></iframe>
	);
};

export default EmbedNotion;
