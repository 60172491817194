import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';

import { getObjScoring } from '@carbonmaps/shared/utils/scoring';

const styles = {
	container: css({
		padding: '8px 10px 8px 10px',
		borderRadius: '24px',
		width: '42px',
	}),
	text: css({
		color: '#FFF !important',
		fontSize: '15px !important',
		fontWeight: 600,
	}),
};

const Scoring = (props: any) => {
	const { value, sx = {} } = props;

	const { color, label } = getObjScoring(value);
	return (
		<div className={cx('textAlignCenter', styles.container, css(sx))} style={{ background: color }}>
			<Typography className={styles.text}>{label}</Typography>
		</div>
	);
};

export default Scoring;
