import { useEffect, useState } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { BarChart2, HelpCircle, Minus } from 'lucide-react';
import { useParams } from 'react-router-dom';

import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import IndicatorsBlockLight, { indicatorConfigItems } from '../../../components/synthese/IndicatorsBlockLight';
import Popover from '../../../components/table/popover/Popover';
import Card from '../../../components/widget/Card';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetPackagingSheetTimePeriod } from '../../../lib/react-query/features/packaging/packaging.hooks';
import PackagingModel from '../../../models/Packaging.model';

const PackagingIndicatorsBlock = () => {
	const { indicator } = useApp();
	const params = useParams();
	const [packagingObject, setPackagingObject] = useState<PackagingModel>();
	const theme = useTheme();

	const { data, isLoading, isError, error } = useGetPackagingSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.packagingId!,
		// period: 2023,
	});

	console.log('🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢', data);

	useEffect(() => {
		if (!data) return;

		setPackagingObject(new PackagingModel(data));
	}, [data]);

	const { t } = useTranslation();
	const intensityOriginal = packagingObject?.getIntensityOriginalByIndicator(indicator);
	const volume = data?.volume;
	const impactIndicator = packagingObject?.getImpactIndicatorSynthesis(indicator);
	const impactPortionValue =
		packagingObject?.packaging[indicator === CARBONE_INDICATOR ? 'carbonImpactPerUnit' : 'waterImpactPerUnit'];
	let displayImpactPortionValue = displayValue(
		// packagingObject?.packaging[indicator === CARBONE_INDICATOR ? 'carbonImpactPerUnit' : 'waterImpactPerUnit'],
		impactPortionValue,
	);
	// let impactPortionValue = displayValue(packagingObject?.getImpactPerUnityByIndicator(indicator));

	displayImpactPortionValue = displayImpactPortionValue.endsWith(',00')
		? displayImpactPortionValue.slice(0, -3)
		: displayImpactPortionValue;

	return (
		<Card
			title={
				indicatorConfigItems.get(indicator)?.indicatorTitle
					? t(indicatorConfigItems.get(indicator)?.indicatorTitle as any)
					: t('Indicateurs carbone')
			}
			icon={<BarChart2 color={siteColors.grey700} size={20} />}
			sx={{ padding: 0, marginBottom: pxToRem(32), flexWrap: 'nowrap', width: '100%' }}
			withBorder={false}
			center={false}
		>
			<IndicatorsBlockLight
				withPopover={false}
				indicator={indicator}
				indicatorDatas={{
					impact: {
						value: impactIndicator,
						isUndefinedValue: !intensityOriginal || volume < 0 ? true : false,
						noValue: (
							<BPopover
								addStyles={{ padding: 0 }}
								content={() => {
									return (
										<Popover>
											<div css={{ fontWeight: '600' }}>
												{!intensityOriginal
													? // eslint-disable-next-line quotes
													  "Facteur d'émission non renseigné"
													: volume < 0
													? 'Volume incorrect'
													: 'Aucun produit associé'}
											</div>
										</Popover>
									);
								}}
								modeHover
							>
								<HelpCircle color={theme.palette.indicator[indicator].main} />
							</BPopover>
						),
					},
					intensity: {
						isUndefinedValue: !intensityOriginal ? true : false,
						value:
							packagingObject?.getIntensityByIndicator(indicator) === '0,00'
								? '< 0,01'
								: packagingObject?.getIntensityByIndicator(indicator),
						noValue: <Minus color={theme.palette.indicator[indicator].main} />,
					},
					impactPortion: {
						// isUndefinedValue: !intensityOriginal ? true : false,
						isUndefinedValue: _.isNil(impactPortionValue),
						value: displayImpactPortionValue,
						// packagingObject?.getIntensityByIndicator(indicator) === '0,00'
						// 	? '< 0,01'
						// 	: packagingObject?.getIntensityByIndicator(indicator),
						noValue: <Minus color={theme.palette.indicator[indicator].main} />,
					},
				}}
			/>
		</Card>
	);
};

export default PackagingIndicatorsBlock;
