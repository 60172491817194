import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import useHasRoles from '../../hooks/useHasRoles';

const Root = () => {
	const hasRoles = useHasRoles();
	const location = useLocation();

	return (
		<Navigate
			to={hasRoles(['super_admin']) ? FRONT_PATH_NAMES.superAdminSettings : FRONT_PATH_NAMES.home}
			replace
			state={{ from: location }}
		/>
	);
};

export default Root;
