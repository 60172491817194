import { css, cx } from '@emotion/css';

import Legend from '../../components/dashboard/Legend';

const styles = {
	container: css({
		display: 'grid',
		gridTemplateColumns: 'auto auto auto auto',

		gridColumnGap: 8,
		gridRowGap: 16,
	}),
	grid4: css({
		gridTemplateColumns: 'auto auto auto auto',
	}),
	grid3: css({
		gridTemplateColumns: 'auto auto auto',
		gridColumnGap: 16,
		gridRowGap: 16,
	}),
} as any;
type LegendGraphProps = {
	data: any;
	limit?: number;
	column?: number;
	renderItem?: (item: any, index: number) => React.ReactNode;
};

const LegendGraph = ({ data, limit = 8, column = 4, renderItem }: LegendGraphProps) => {
	return (
		<div className={cx('width100', styles.container, styles[`grid${column}`])}>
			{data
				.filter((o: any) => {
					return !o?.isEmpty;
				})
				.slice(0, limit)
				.map((item: any, index: number) => {
					if (renderItem) {
						return renderItem(item, index);
					}

					return (
						<Legend key={index} color={item.color} label={item?.label} value={item?.percentValue} path={item?.path} />
					);
				})}
		</div>
	);
};

export default LegendGraph;
