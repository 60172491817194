import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import SupplierCard from '../../../../components/supplier/SupplierCard';
import SupplyTable from '../../../../components/supplier/SupplyTable';
import { useGlossary } from '../../../../hooks/useGlossary';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useSupplierSetup } from '../../supplier.hooks';

const formatDataTable = (data: any, indicator: string) => {
	if (!data?.length) return [];
	return data.map((item: any) => {
		return {
			...item,
			percentage: indicator === CARBONE_INDICATOR ? item.carbonIntensityPercent : item.waterIntensityPercent,
		};
	});
};

const Supply = () => {
	const { indicator } = useApp();
	const { productsSupplier, supplierObject } = useSupplierSetup();
	const { t } = useTranslation();
	const { g } = useGlossary();
	return (
		<div css={{ marginTop: '32px' }}>
			<SupplierCard
				title={t('Approvisionnements issus de ce fournisseur')}
				glossary={g('approvisionnements')}
				center={false}
				sx={{ width: '100%' }}
			>
				<SupplyTable
					//data={productsSupplier}
					data={formatDataTable(productsSupplier || [], indicator)}
					indicator={indicator}
					withPercent
					withImpactPortion={supplierObject?.supplier.iTypeItems?.includes('product')}
				/>
			</SupplierCard>
		</div>
	);
};

export default Supply;
