import { css, cx } from '@emotion/css';

import DashboardLifeCycleGraph from '../DashboardLifeCycleGraph';
import Segmentation from '../Segmentation';

import { cn, siteColors } from '../../../lib/colors';
import Temporality from './Temporality';

const styles = {
	container: css({
		border: cn('1px solid', siteColors.grey500),
		borderRadius: 16,
		minHeight: 304,
		//height: 304,
		padding: 0,
	}),
};

const GlobalImpact = () => {
	return (
		<div className={cx('flexRow width100 backgroundWhite nowrap stretch', styles.container)}>
			<DashboardLifeCycleGraph />
			<Temporality />
		</div>
	);
};

export default GlobalImpact;
