import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getCountAuditSheetError, getSheetDataAudit, getSheetDataAuditTable } from '@carbonmaps/ui/actions/audit.actions';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import Skeleton from '../../components/Skeleton';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { HEADER_CONFIG_MAP } from '../../utils/audit.utils';

const styles = {
	container: css({
		padding: 24,
		gap: 24,
		borderRadius: '16px',
		background: 'var(--background)',
	}),
	boxContainer: css({
		width: 52,
		height: 52,
		//padding: 12,
		borderRadius: '16px',
		border: '1px solid transparent',
		background: 'var(--box-icon-color)',
	}),

	content: css({
		gap: 3,
	}),

	count: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
		lineHeight: '1 !important',
	}),
};

const HeaderPage = () => {
	const params = useParams();
	const config = HEADER_CONFIG_MAP.get(params.itemType);
	const Icon = config.icon;
	const queryParams = useQueryParams();
	const typeError = queryParams?.type_error?.[0];

	// translation
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();

	const [searchQueryParams] = useSearchQueryParams();
	// ---- fetch data audit ---- //
	const { data: errorCount, isLoading } = useQuery({
		queryKey: [
			'getCountAuditSheetError',
			{
				itemType: params.itemType,
				period: selectedYear,
				//	isErrorVolume: typeError === 'volume' ? true : false,
			},
		],
		queryFn: getCountAuditSheetError,
	} as any);

	// ---- fetch data audit ---- //
	// const { data, isLoading: isLoadingData } = useQuery({
	// 	queryKey: [
	// 		'getSheetDataAudit',
	// 		{
	// 			itemType: params.itemType,
	// 			input: searchQueryParams?.input,
	// 			facetFilters: (searchQueryParams?.facetFilters || [])
	// 				.filter((item: any) => {
	// 					return item?.path !== 'type_error';
	// 				}),

	// 			//searchQueryParams.input
	// 			typeErrors: (searchQueryParams?.facetFilters || []).find((item: any) => {
	// 				return item?.path === 'type_errorv'; //= ommit v if need
	// 			})?.values,

	// 			ids: searchQueryParams?.productId ? [searchQueryParams?.productId] : null,
	// 			period: selectedYear,
	// 			isCount: true,
	// 		},
	// 	],
	// 	queryFn: getSheetDataAudit,
	// } as any);


	// ---- fetch data audit ---- //
	const { data, isLoading: isLoadingData } = useQuery({
		queryKey: [
			'getSheetDataAuditTable',
			{
				itemType: params.itemType,
				input: searchQueryParams?.input,
				facetFilters: (searchQueryParams?.facetFilters || []).filter((item: any) => {
					return item?.path !== 'type_error';
				}), //searchQueryParams.input
				// typeErrors: (searchQueryParams?.facetFilters || []).find((item: any) => {
				// 	return item?.path === 'type_error';
				// })?.values,
				typeErrors: typeError === 'volume' ? ['Erreur volume'] :
					(searchQueryParams?.facetFilters || []).find((item: any) => {
						return item?.path === 'type_error';
					})?.values,
				ids: searchQueryParams?.productId ? [searchQueryParams?.productId] : null,
				period: selectedYear,
				page: 1,
				size: 100,
			},
		],
		queryFn: getSheetDataAuditTable,
	} as any);



	const elem = useMemo(() => {
		return (
			<div
				className={cx(' flexRow alignCenter', styles.container)}
				style={{ ['--background' as any]: config.background }}
			>
				<div
					className={cx('flexColumn alignCenter justifyCenter', styles.boxContainer)}
					style={{ ['--box-icon-color' as any]: config.color }}
				>
					<Icon size={28} color={siteColors.common.white} />
				</div>
				<div className={cx('flexColumn', styles.content)}>
					<Typography>
						{t(config.text, {
							ingredient: t('ingredient'),
							an_ingredient: t('an_ingredient'),
							ingredients: toLowerCase(t('ingredients')),
							product: t('product'),
							of_product: t('of_product'),
							products: t('products'),
							product_lowercase: toLowerCase(t('product')),
							products_lowercase: toLowerCase(t('products')),
						})}
					</Typography>
					<Typography className={styles.count}>
						{((data as any)?.meta as any)?.count || 0}	{t('sur')} 	{errorCount}
					</Typography>
				</div>
			</div>
		);
	}, [errorCount, data, t]);

	return isLoading || isLoadingData ? <Skeleton>{elem}</Skeleton> : elem;
};

export default HeaderPage;
