import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import ErrorIcon from '@mui/icons-material/Error';
import { AlertCircle, X } from 'lucide-react';

import DeleteConfirm from '@carbonmaps/ui/components/saas/DeleteConfirm';
import {
	Autocomplete,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	// FormControl,
	FormControlLabel,
	// FormLabel,
	IconButton,
	// Radio,
	// RadioGroup,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import z from 'zod';
import AlertStatic from '../../../../components/Toast/AlertStatic';
import { cn, siteColors } from '../../../../lib/colors';
import {
	useDeleteQuestionFormQuestion,
	useFindQuestionSettings,
	useRetrieveFormFromApi,
	useSaveQuestionFormSettings,
} from '../hooks';
import QuestionMasterPoints from './QuestionMasterPoints';

const styles = {
	displayNone: css({
		display: 'none',
	}),
	marginTop4: css({
		marginTop: 24,
	}),
	gap3: css({
		gap: 18,
	}),

	optionLabel: css({
		padding: 12,
		cursor: 'pointer',
	}),
	optionHover: css({
		'&:hover': {
			backgroundColor: siteColors.grey200,
		},
	}),
	optionSelected: css({
		backgroundColor: siteColors.carbon100,
	}),

	multipleChoiceItem: css({
		padding: 6,
		marginBottom: 12,
	}),
};

const QuestionFormsSettings = () => {
	const params = useParams();
	const { data: settingsData } = useFindQuestionSettings({ questionFormId: _.toString(params.questionFormId) });

	return (
		// <Container header={<QuestionFormsHeader />} seoText={`${t('Questionnaire')} - Carbon Maps`}>
		<div>
			<div className={cx('mt-3')} css={{ marginBottom: 24 }}>
				<AddFormDialog />
			</div>
			<div>
				{(settingsData?.questionSettings || [])?.map((item: Record<string, any>) => {
					return (
						<div
							key={nanoid()}
							className={cx('flexRow alignCenter', 'justifyBetween')}
							css={{
								marginBottom: 24,
								border: cn('1px solid', siteColors.grey700),
								borderRadius: 8,
								padding: 12,
							}}
						>
							<div /* className={cx('')} */>
								{/* <Typography variant="body2">XXX: {_.get(item, 'questionName', '---')}</Typography> */}
								<Typography variant="body2">FR: {_.get(item, 'fr.questionName', '---')}</Typography>
								{/* <br /> */}
								<Typography variant="body2">EN: {_.get(item, 'en.questionName', '---')}</Typography>
								<Typography variant="body2">IT: {_.get(item, 'it.questionName', '---')}</Typography>
								<Typography variant="body2">ES: {_.get(item, 'es.questionName', '---')}</Typography>
								{/* <Typography variant="body1">{_.get(item, 'type', '---')}</Typography>) */}
							</div>

							<div className={cx('flexRow', styles.gap3)}>
								<UpdateFormDialog item={item} />
								<DeleteQuestionButton item={item} />
							</div>
							{/* AAA */}
							{/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
							{/* <Divider /> */}
						</div>
					);
				})}
			</div>
		</div>
		// </Container>
	);
};

const DeleteQuestionButton = ({ item }: { item: Record<string, any> }) => {
	const { mutate: deleteQuestion, isLoading: isDeleteQuestionLoading } = useDeleteQuestionFormQuestion();
	const params = useParams();

	return (
		<DeleteConfirm
			title="Supprimer ?"
			onClick={(close?: VoidFunction): void => {
				// throw new Error("Function not implemented.");
				deleteQuestion({ questionFormId: _.toString(params.questionFormId), localId: _.get(item, 'localId') });
			}}
		>
			<div>
				<BButton
					variant="secondary"
					label={isDeleteQuestionLoading ? <CircularProgress size={20} /> : 'Supprimer'}
					// onClick={() => {
					// 	// setOpen(true);
					// 	// onSubmit();
					// }}
					addStyles={{ marginLeft: 12 }}
				/>
			</div>
		</DeleteConfirm>
	);
};

const baseSchema = z.object({
	questionName: z.string().min(1),
	sectionCode: z.string().min(1),
	questionId: z.string().min(1),
	masterQuestion: z.boolean().default(false),
	choiceIds: z.array(z.string()).optional(),
});

const pointMasterSchema = z.object({
	default: z.any().default(0),
	'A+': z.any().default(0),
	A: z.any().default(0),
	B: z.any().default(0),
	C: z.any().default(0),
	D: z.any().default(0),
	E: z.any().default(0),
});

export const _unionSchema = z.discriminatedUnion('type', [
	z.object({
		type: z.literal('yes_no'),
		yes_no_answers: z.object({
			yes: z.object({
				// TODO: label
				answerLabel: z.string().optional(),

				points: pointMasterSchema,
			}),
			no: z.object({
				// TODO: label
				answerLabel: z.string().optional(),

				points: pointMasterSchema,
			}),
		}),
	}),
	z.object({
		type: z.literal('multiple_choice'),
		isExclusive: z.boolean(),
		multiple_choice_answers: z.array(
			z.object({
				answerId: z.string().min(1),
				answerLabel: z.string().optional(),

				points: pointMasterSchema,
			}),
		),
	}),
	z.object({
		type: z.literal('statement'),
		statement_answer: z.object({
			answerId: z.string().min(1),
			answerLabel: z.string().optional(),

			points: pointMasterSchema,
		}),
	}),
	z.object({
		type: z.literal('number'),
		number_answer: z.object({
			answerId: z.string().min(1),
			answerLabel: z.string().optional(),

			points: pointMasterSchema,
		}),
	}),
]);
export const unionSchema = baseSchema.and(_unionSchema);

type Prettify<T> = {
	[K in keyof T]: T[K];
} & {};

type UnionType = Prettify<z.infer<typeof unionSchema>>;

const AddFormDialog = () => {
	const [open, setOpen] = useState(false);

	const [displayLang, setDisplayLang] = useState<'en' | 'fr' | 'it' | 'es'>('fr');

	const localIdRef = useRef(nanoid());
	const localId = localIdRef.current;

	const _setOpen = (v: any) => {
		setOpen(v);
		localIdRef.current = nanoid();
	};

	return (
		<div>
			<BButton
				variant="primary"
				label="Ajouter un paramètre"
				onClick={() => {
					setOpen(true);
				}}
			/>

			<Dialog
				fullWidth
				maxWidth="xl"
				open={open}
				PaperProps={{
					sx: {
						height: '80vh',
					},
				}}
			>
				<DialogTitle
					component="div"
					sx={{
						position: 'relative',
					}}
				>
					<IconButton
						onClick={() => {
							setOpen(false);
							localIdRef.current = nanoid();
						}}
						sx={{ position: 'absolute', right: 5, top: 5 }}
					>
						<X size={20} />
					</IconButton>

					<Typography variant="h6">Ajouter un paramètre</Typography>

					<Typography className="flexRow alignCenter">
						-&nbsp;&nbsp; <AlertCircle color={siteColors.grey700} size={20} />
						&nbsp; Les valeurs par différentes langues doivent être sauvegardées séparément
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Tabs
						value={displayLang}
						onChange={(_e, value) => {
							setDisplayLang(value as 'en' | 'fr' | 'it' | 'es');
						}}
						sx={{
							mb: 3,
						}}
					>
						<Tab value="fr" label="FR" />
						<Tab value="en" label="EN" />
						<Tab value="it" label="IT" />
						<Tab value="es" label="ES" />
					</Tabs>

					{/* <FormControl>
						<FormLabel>Language</FormLabel>
						<RadioGroup
							defaultValue="fr"
							row
							onChange={(_e, value) => {
								setDisplayLang(value as 'en' | 'fr');
							}}
						>
							<FormControlLabel value="fr" control={<Radio />} label="FR" />
							<FormControlLabel value="en" control={<Radio />} label="EN" />
						</RadioGroup>
					</FormControl> */}

					{/* By language */}
					<div className={cx('width100', displayLang !== 'fr' ? styles.displayNone : '')}>
						<FormByLang lang="fr" /* data={data} */ localId={localId} setOpen={_setOpen} />
					</div>

					<div className={cx('width100', displayLang !== 'en' ? styles.displayNone : '')}>
						<FormByLang lang="en" /* data={data} */ localId={localId} setOpen={_setOpen} />
					</div>

					<div className={cx('width100', displayLang !== 'it' ? styles.displayNone : '')}>
						<FormByLang lang="it" /* data={data} */ localId={localId} setOpen={_setOpen} />
					</div>

					<div className={cx('width100', displayLang !== 'es' ? styles.displayNone : '')}>
						<FormByLang lang="es" /* data={data} */ localId={localId} setOpen={_setOpen} />
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};

const Statement = ({ form, masterQuestion }: { form: UseFormReturn<UnionType>; masterQuestion: boolean }) => {
	return (
		<div css={{ paddingTop: 12 }}>
			<div css={{ marginBottom: 18 }}>
				<div className={cx('flexRow', styles.gap3)}>
					{/* <BInput
						label="Nom Réponse"
						{...form.register('statement_answer.name')}
						error={_.get(form.formState.errors, 'statement_answer.name')}
						errorMessage={_.get(form.formState.errors, 'statement_answer.name.message')}
					/> */}

					<QuestionMasterPoints
						type={'statement'}
						index={0}
						form={form}
						masterQuestion={masterQuestion}
						typeResponse={'statement_answer'}
					/>
				</div>
			</div>
		</div>
	);
};

const NumberResponse = ({ form, masterQuestion }: { form: UseFormReturn<UnionType>; masterQuestion: boolean }) => {
	return (
		<div css={{ paddingTop: 12 }}>
			<div css={{ marginBottom: 18 }}>
				<div className={cx('flexRow', styles.gap3)}>
					{/* <BInput
						label="Nom Réponse"
						{...form.register('number_answer.name')}
						error={_.get(form.formState.errors, 'number_answer.name')}
						errorMessage={_.get(form.formState.errors, 'number_answer.name.message')}
					/> */}

					<QuestionMasterPoints
						type={'number'}
						index={0}
						form={form}
						masterQuestion={masterQuestion}
						typeResponse={'number_answer'}
					/>
				</div>
			</div>
		</div>
	);
};

const YesOrNo = ({ form, masterQuestion }: { form: UseFormReturn<UnionType>; masterQuestion: boolean }) => {
	return (
		<div css={{ paddingTop: 12 }}>
			<div css={{ marginBottom: 18 }}>
				<Typography fontWeight="bold" mb={1}>
					Yes
				</Typography>
				<div className={cx('flexRow', styles.gap3)}>
					{/* <BInput
						label="Answer label (optionnel / utile si master question)"
						{...form.register('yes_no_answers.yes.answerLabel')}
						error={_.get(form.formState.errors, 'yes_no_answers.yes.answerLabel')}
						errorMessage={_.get(form.formState.errors, 'yes_no_answers.yes.answerLabel.message')}
					/> */}

					<QuestionMasterPoints
						type={'yes'}
						index={0}
						form={form}
						masterQuestion={masterQuestion}
						typeResponse={'yes_no_answers'}
					/>

					{/* <BInput
						label="Points"
						type="number"
						{...form.register('yes_no_answers.yes.points', {
							valueAsNumber: true,
						})}
						error={_.get(form.formState.errors, 'yes_no_answers.yes.points')}
						errorMessage={_.get(form.formState.errors, 'yes_no_answers.yes.points.message')}
					/>					 */}
				</div>
			</div>

			<div css={{ marginBottom: 18 }}>
				<Typography fontWeight="bold" mb={1}>
					No
				</Typography>
				<div className={cx('flexRow', styles.gap3)}>
					{/* <BInput
						label="Answer label (optionnel)"
						{...form.register('yes_no_answers.no.answerLabel')}
						error={_.get(form.formState.errors, 'yes_no_answers.no.answerLabel')}
						errorMessage={_.get(form.formState.errors, 'yes_no_answers.no.answerLabel.message')}
					/> */}

					<QuestionMasterPoints
						type={'no'}
						index={0}
						form={form}
						masterQuestion={masterQuestion}
						typeResponse={'yes_no_answers'}
					/>
				</div>
			</div>
		</div>
	);
};

const MultipleChoice = ({
	question,
	form,
	masterQuestion,
}: {
	question: Record<string, any> | undefined;
	form: UseFormReturn<UnionType>;
	masterQuestion?: boolean;
}) => {
	const isValidQuestionProp = _.isObject(question) && _.has(question, 'type') && question.type === 'multiple_choice';

	if (!isValidQuestionProp) {
		return <div css={{ color: 'red' }}>question of type 'multiple_choice' needed</div>;
	}

	const choices = _.get(question, 'properties.choices', []);

	const choicesIds = choices.map((choice: Record<string, any>) => {
		return _.get(choice, 'id', '---');
	});

	form.setValue('choiceIds' as any, choicesIds);

	const isExclusive = !_.get(question, 'properties.allow_multiple_selection');
	form.setValue('isExclusive', isExclusive);

	const renderMap = choices.map((choice: Record<string, any>, index: number) => {
		const answerId = _.get(choice, 'id', '---');
		const answerLabel = _.get(choice, 'label', '---');

		form.setValue(`multiple_choice_answers.${index}.answerLabel` as const, answerLabel);
		form.setValue(`multiple_choice_answers.${index}.answerId` as const, answerId);

		return (
			<div key={choice.id} className={cx('', styles.multipleChoiceItem)}>
				<div css={{ marginBottom: 10 }}>
					<span css={{ fontWeight: 'bold' }}>{answerLabel}</span>{' '}
				</div>

				<div className={cx('flexRow', styles.gap3)}>
					{/* ! Answer label input is hidden  */}
					<div
						css={{
							display: 'none',
						}}
					>
						<BInput label="Answer label" value={answerLabel} disabled />
					</div>

					<BInput label="ID Réponse" value={answerId} disabled />
					<QuestionMasterPoints
						type={'yes'}
						form={form}
						index={index}
						masterQuestion={masterQuestion}
						typeResponse={'multiple_choice_answers'}
					/>
				</div>
			</div>
		);
	});

	return (
		<div>
			<div>
				<div>----------------------</div>
				<span css={{ fontWeight: 'bold' }}>Is Exclusive ?:</span>
				&nbsp;{_.toString(isExclusive)}
			</div>
			<div>----------------------</div>
			{renderMap}
		</div>
	);
};

const getSectionCurrentOption = (options: any[], sectionCode: string) => {
	return options.find((e: any) => {
		return e.code === sectionCode;
	});
};

const FormByLang = ({
	// data,
	lang,
	localId,
	initialData,
	setOpen,
}: // setInputErrors,
// questionForm,
{
	initialData?: Record<string, any>;
	lang: 'en' | 'fr' | 'it' | 'es';
	localId: string;
	// setInputErrors: Dispatch<SetStateAction<Record<string, string>>>;
	// questionForm: UseFormReturn<UnionType>;
	setOpen: Function;
}) => {
	const params = useParams();
	const [selectedQuestion, setSelectedQuestion] = useState<Record<string, any>>();
	const [masterQuestion, setMasterQuestion] = useState<boolean>(false);
	// const [errorBo, setErrorBo] = useState('');

	const { data: formFromApiData } = useRetrieveFormFromApi({ questionFormId: _.toString(params.questionFormId) });
	const { data: questionSettings } = useFindQuestionSettings({ questionFormId: _.toString(params.questionFormId) });

	useEffect(() => {
		if (!initialData) {
			return;
		}
		const newQuestion = _.get(formFromApiData, `${lang}.fields`, []).find((e: any) => {
			return e.id === _.get(initialData, `${lang}.questionId`);
		});
		setMasterQuestion(_.get(initialData, 'masterQuestion', false));

		setSelectedQuestion(newQuestion);
	}, [initialData, formFromApiData]);

	const {
		mutate: saveSettings,
		isLoading: isSaveQuestionLoading,
		isError,
		error,
	} = useSaveQuestionFormSettings({
		onSuccess: () => {
			setOpen(false);
		},
	});

	const questionForm = useForm<UnionType>({
		resolver: zodResolver(unionSchema),
		...(initialData
			? {
					defaultValues: {
						sectionCode: _.get(
							getSectionCurrentOption(
								_.get(questionSettings, 'questionSections', []) as any[],
								_.get(initialData, 'sectionCode', undefined),
							),
							'code',
							undefined,
						),
						masterQuestion: _.get(initialData, 'masterQuestion', false),
						questionName: _.get(initialData, `${lang}.questionName`, '---'),
						questionId: _.get(initialData, `${lang}.questionId`, '---'),
						type: _.get(initialData, `${lang}.type`, '---'),
						statement_answer: _.get(initialData, `${lang}.statement_answer`, undefined),
						number_answer: _.get(initialData, `${lang}.number_answer`, undefined),
						yes_no_answers: _.get(initialData, `${lang}.yes_no_answers`, undefined),

						multiple_choice_answers: _.get(initialData, `${lang}.multiple_choice_answers`, []),
						isExclusive:
							_.get(initialData, `${lang}.type`) === 'multiple_choice'
								? _.get(initialData, `${lang}.isExclusive`)
								: undefined,
					},
			  }
			: {}),
	});

	const onSubmit = questionForm.handleSubmit(
		(data: any) => {
			console.log('+++++++++++++++++++++++', data);

			// return;

			const dataForLang = _.assign({}, _.omit(data, [/* 'questionName', */ 'masterQuestion', 'sectionCode']));

			console.log('before --------------------------', dataForLang);

			// return;

			const qType = _.get(dataForLang, 'type');
			if (qType === 'yes_no') {
				_.unset(dataForLang, ['multiple_choice_answers', 'statement_answer', 'number_answer', 'isExclusive']);
			}
			if (qType === 'multiple_choice') {
				_.unset(dataForLang, ['yes_no_answers', 'statement_answer', 'number_answer']);

				const filtered = _.get(dataForLang, 'multiple_choice_answers', []).filter((e: any) => {
					return _.get(data, 'choiceIds')?.includes(_.get(e, 'answerId'));
				});

				_.set(dataForLang, 'multiple_choice_answers', filtered);
			}

			_.unset(dataForLang, 'choiceIds');
			console.log('after --------------------------', dataForLang);

			saveSettings({
				localId,
				questionFormId: _.toString(params.questionFormId),
				settings: {
					masterQuestion: _.get(data, 'masterQuestion'),
					questionName: _.get(data, 'questionName'),
					sectionCode: _.get(data, 'sectionCode'),
					[lang]: dataForLang,
				},
			});
		},
		(errors) => {
			console.log('--------------------------', errors);
		},
	);

	// Fonction pour changer la valeur de masterQuestion
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setMasterQuestion(event.target.checked);
		questionForm.setValue('masterQuestion', event.target.checked);
	};

	return (
		<>
			<BInput label="Local ID" type="text" addStyles={{ marginBottom: 12 }} defaultValue={localId} disabled />

			<Autocomplete
				fullWidth
				onChange={(_e, value) => {
					questionForm.setValue('sectionCode', _.get(value, 'code', '----' as any));
				}}
				defaultValue={
					initialData
						? getSectionCurrentOption(
								_.get(questionSettings, 'questionSections', []) as any[],
								_.get(initialData, 'sectionCode', undefined),
						  )
						: undefined
				}
				options={_.get(questionSettings, 'questionSections', []) as any[]}
				noOptionsText={
					<div css={{ color: 'red' }}>
						Aucune section disponible: veuillez créer une section dans l'onglet "sections"
					</div>
				}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Section"
						error={!!_.get(questionForm.formState.errors, 'sectionCode')}
						helperText={_.get(questionForm.formState.errors, 'sectionCode.message')}
					/>
				)}
				renderOption={(props, option, state) => {
					return (
						<li
							{...props}
							className={cx('', styles.optionLabel, state.selected ? styles.optionSelected : styles.optionHover)}
						>
							<Typography variant="body1">
								<span css={{ fontWeight: 'bold' }}>code:</span> {_.get(option, 'code', '---')}
							</Typography>
							<Typography variant="body1">
								<span css={{ fontWeight: 'bold' }}>labelFr:</span> {_.get(option, 'labelFr', '---')}
							</Typography>
							<Typography variant="body1">
								<span css={{ fontWeight: 'bold' }}>labelEn:</span> {_.get(option, 'labelEn', '---')}
							</Typography>
						</li>
					);
				}}
				getOptionLabel={(option) => {
					return `code: ${_.get(option, 'code', '---')} - labelFr: ${_.get(
						option,
						'labelFr',
						'---',
					)} - labelEn: ${_.get(option, 'labelEn', '---')}`;
				}}
				sx={{ mb: 2 }}
			/>

			<Autocomplete
				fullWidth
				onChange={(_e, value) => {
					setSelectedQuestion(value as any);
					questionForm.setValue('type', _.get(value, 'type', '----' as any));
					questionForm.setValue('questionId', _.get(value, 'id', '----' as any));
					questionForm.setValue('questionName', _.get(value, 'title', '----' as any));
				}}
				defaultValue={
					initialData ? selectedQuestion ?? _.get(formFromApiData, `${lang}.fields`, [])[0] ?? {} : undefined
				}
				value={initialData ? selectedQuestion ?? _.get(formFromApiData, `${lang}.fields`, [])[0] ?? {} : undefined}
				options={(_.get(formFromApiData, `${lang}.fields`, []) as any).filter((e: any) => {
					return (
						e.type === 'yes_no' || e.type === 'multiple_choice' /* || e.type === 'statement' || e.type === 'number' */
					);
				})}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Question"
						error={
							!!_.get(questionForm.formState.errors, 'type') || !!_.get(questionForm.formState.errors, 'questionId')
						}
						helperText={
							_.get(questionForm.formState.errors, 'type.message') ||
							_.get(questionForm.formState.errors, 'questionId.message')
								? 'Required'
								: undefined
						}
					/>
				)}
				renderOption={(props, option, state) => {
					return (
						<li
							{...props}
							className={cx('', styles.optionLabel, state.selected ? styles.optionSelected : styles.optionHover)}
						>
							<Typography variant="body1" fontWeight="bold">
								{_.get(option, 'title', '---')}
							</Typography>
							<Typography variant="body1">
								<span css={{ fontWeight: 'bold' }}>id:</span> {_.get(option, 'id', '---')}
							</Typography>
							<Typography variant="body1">
								<span css={{ fontWeight: 'bold' }}>type:</span> {_.get(option, 'type', '---')}
							</Typography>
						</li>
					);
				}}
				getOptionLabel={(option) => {
					return `[${_.get(option, 'type', '---')}] ${_.get(option, 'title', '---')}`;
				}}
			/>

			<div className={cx('', styles.marginTop4)}>
				<FormControlLabel
					control={<Checkbox checked={masterQuestion} onChange={handleChange} />}
					label="Master Question ?"
				/>
			</div>

			<div className={cx('', styles.marginTop4)}>
				<Divider textAlign="left">
					<Typography variant="body1" fontWeight="bold">
						Réponses possibles
					</Typography>
				</Divider>

				{!['yes_no', 'multiple_choice'].includes(_.get(selectedQuestion, 'type')) ? (
					// <YesOrNo form={questionForm} masterQuestion={masterQuestion} />
					<h2>Seules les questions de type: 'yes_no', 'multiple_choice' supportées</h2>
				) : null}
				{masterQuestion && _.get(selectedQuestion, 'type') !== 'multiple_choice' ? (
					// <YesOrNo form={questionForm} masterQuestion={masterQuestion} />
					<h2>Seules les questions de type: 'multiple_choice' peuvent être une "master question"</h2>
				) : null}
				{_.get(selectedQuestion, 'type') === 'yes_no' && !masterQuestion ? (
					<YesOrNo form={questionForm} masterQuestion={masterQuestion} />
				) : null}
				{_.get(selectedQuestion, 'type') === 'multiple_choice' ? (
					<MultipleChoice question={selectedQuestion} form={questionForm} masterQuestion={masterQuestion} />
				) : null}

				{_.get(selectedQuestion, 'type') === 'statement' ? (
					<Statement form={questionForm} masterQuestion={masterQuestion} />
				) : null}

				{_.get(selectedQuestion, 'type') === 'number' ? (
					<NumberResponse form={questionForm} masterQuestion={masterQuestion} />
				) : null}

				{isError && (
					<AlertStatic text={(error as any)?.message} type={'error'} icon={<ErrorIcon css={{ color: '#C74600' }} />} />
				)}

				<BButton
					variant="primary"
					label={isSaveQuestionLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Sauvegarder'}
					onClick={() => {
						onSubmit();
					}}
				/>
			</div>
		</>
	);
};

const UpdateFormDialog = ({ item }: { item: Record<string, any> }) => {
	const [open, setOpen] = useState(false);

	return (
		<div className={cx('flexRow')}>
			<BButton
				variant="secondary"
				label="Modifier"
				onClick={() => {
					setOpen(true);
					// onSubmit();
				}}
			/>

			<FormDialog
				open={open}
				onClose={() => {
					setOpen(false);
				}}
				initialData={item}
				localId={_.get(item, 'localId', '---')}
				title="Modifier un paramètre"
				setOpen={setOpen}
			/>
		</div>
	);
};

const FormDialog = ({
	open,
	onClose,
	initialData,
	localId,
	title,
	setOpen,
}: {
	open: boolean;
	onClose: () => void;
	initialData: Record<string, any>;
	localId: string;
	title: string;
	setOpen: Function;
}) => {
	const [displayLang, setDisplayLang] = useState<'en' | 'fr' | 'it' | 'es'>('fr');

	return (
		<Dialog
			fullWidth
			maxWidth="xl"
			open={open}
			PaperProps={{
				sx: {
					height: '80vh',
				},
			}}
		>
			<DialogTitle
				component="div"
				sx={{
					position: 'relative',
				}}
			>
				<IconButton
					onClick={() => {
						onClose?.();
					}}
					sx={{ position: 'absolute', right: 5, top: 5 }}
				>
					<X size={20} />
				</IconButton>

				<Typography variant="h6">{title}</Typography>

				<Typography className="flexRow alignCenter">
					-&nbsp;&nbsp; <AlertCircle color={siteColors.grey700} size={20} />
					&nbsp; Les valeurs par différentes langues doivent être sauvegardées séparément
				</Typography>
			</DialogTitle>

			<DialogContent>
				<Tabs
					value={displayLang}
					onChange={(_e, value) => {
						setDisplayLang(value as 'en' | 'fr' | 'it' | 'es');
					}}
					sx={{
						mb: 3,
					}}
				>
					<Tab value="fr" label="FR" />
					<Tab value="en" label="EN" />
					<Tab value="it" label="IT" />
					<Tab value="es" label="ES" />
				</Tabs>

				{/* By language */}
				<div className={cx('width100', displayLang !== 'fr' ? styles.displayNone : '')}>
					<FormByLang lang="fr" initialData={initialData} localId={localId} setOpen={setOpen} />
				</div>

				<div className={cx('width100', displayLang !== 'en' ? styles.displayNone : '')}>
					<FormByLang lang="en" initialData={initialData} localId={localId} setOpen={setOpen} />
				</div>

				<div className={cx('width100', displayLang !== 'it' ? styles.displayNone : '')}>
					<FormByLang lang="it" initialData={initialData} localId={localId} setOpen={setOpen} />
				</div>

				<div className={cx('width100', displayLang !== 'es' ? styles.displayNone : '')}>
					<FormByLang lang="es" initialData={initialData} localId={localId} setOpen={setOpen} />
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default QuestionFormsSettings;
