import { useCallback, useEffect, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler } from 'react-hook-form';

import { ClientFormInput } from '@carbonmaps/shared/validations/client.validations';
import { QuoteFormInput } from '@carbonmaps/shared/validations/quote.validations';
import { createQuoteAction, getQuoteDataAction } from '@carbonmaps/ui/actions/quote.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import {
	createClientAction,
	getQuoteOptionsAutocompleteClientAction,
} from '../../../lib/react-query/features/client/client.actions';
import { CARBONE_INDICATOR } from '../../../utils/constants';

import ClientList from './ClientList';
import DevisCarboneFilter from './DevisCarboneFilter';
import DevisCarboneHeader from './DevisCarboneHeader';
// import DevisCarboneTable from './DevisCarboneTable';
// import DevisModal from './DevisModal';
// import { useQuoteFormStep } from './useDevisFormStore';

const DevisCarbone = (/* props: Props */) => {
	// translation
	const { t } = useTranslation();

	const [searchQueryParams] = useSearchQueryParams();
	const [openModal, setOpenModal] = useState(false);
	const [isNew, setIsNew] = useState(true);
	const [options, setOptions] = useState([]);
	// const [message, setMessage] = useState('');
	const [search, setSearch] = useState<any>();
	const { indicator, setIndicator, setBreadcrumbs } = useApp();
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);
	const [selectedOption, setSelectedOption] = useState({}) as any;
	// const [selectedSimulation, setSelectedSimulation] = useState({}) as any;

	// const navigate = useNavigate();

	// const analytics = useJuneTrack();
	// ---- track event ---- //
	// const trackEvent = useCallback(
	// 	(eventName: string, options?: any) => {
	// 		if (!analytics) return;
	// 		analytics.track(eventName, options, {
	// 			context: { category: 'Simulate' },
	// 		});
	// 	},
	// 	[analytics],
	// );

	const queryClient = useQueryClient();
	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('Devis carbone'), isBeta: false }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	// ---- invalidateQueries getQuoteDataAction at first  ---- //
	useEffect(() => {
		return () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
		};
	}, []);

	// ---- init switch mode with carbon ---- //
	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBONE_INDICATOR);
	}, [setIndicator, indicator]);

	//----- fetch data for autocomplete ----//
	const {
		data: dataOptions,
		isLoading: isLoadingData,
		isSuccess: isSuccessOptions,
	} = useQuery({
		queryKey: [
			'getOptionsAutocompleteClient',
			{
				...paramsTable,
				input: search?.input ?? '',
			},
		] as any,
		queryFn: getQuoteOptionsAutocompleteClientAction,
	});

	const {
		data: dataSimulation,
		isLoading: isLoadingDataOption,
		isSuccess: isSuccessData,
	} = useQuery({
		queryKey: [
			'getQuoteDataAction',
			{
				...paramsTable,
				input: search?.input ?? '',
				viewMode: indicator,
			},
		],
		queryFn: getQuoteDataAction,
	} as any);

	useEffect(() => {
		const _options: any = [];

		if (isSuccessOptions && dataOptions?.length) {
			_options.push(...dataOptions);
		}

		setOptions(_options);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccessOptions, isSuccessData, dataOptions, /* dataSimulation, */ search, setSelectedOption, selectedOption]);

	//----- mutation to create the quote ----//

	// const {
	// 	// mutate: createQuote,
	// 	// isError,
	// 	// isSuccess,
	// 	// isLoading: isLoadingCreateQuote,
	// } = useMutation({
	// 	mutationKey: ['createQuote'],
	// 	mutationFn: createQuoteAction,
	// 	onSuccess: async (returnedData: any) => {
	// 		setOpenModal(false);
	// 		queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
	// 		queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });

	// 		// setMessage('Informations mises à jour');
	// 		// navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
	// 	},
	// 	onError: (error: any) => {
	// 		if (error.message) {
	// 			setMessage(error.message);
	// 		}
	// 	},
	// });

	// const { setStep } = useQuoteFormStep();

	//----- mutation to create the client ----//
	// const {
	// 	mutate: createClient,
	// 	isError: isErrorClient,
	// 	isSuccess: isSuccessClient,
	// 	isLoading: isLoadingCreateClient,
	// } = useMutation({
	// 	mutationKey: ['createClient'],
	// 	mutationFn: createClientAction,
	// 	onSuccess: async (returnedData: any) => {
	// 		// setOpenModal(false);
	// 		setStep(1);

	// 		queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
	// 		queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });

	// 		if (returnedData?.isUpdate) {
	// 			// setMessage('Informations mises à jour');
	// 			setSelectedOption({
	// 				label: returnedData?.client?.get('name'),
	// 				...returnedData?.client?.toJSON(),
	// 			});
	// 		}
	// 		// navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
	// 	},
	// 	onError: (error: any) => {
	// 		if (error.message) {
	// 			setMessage(error.message);
	// 		}
	// 	},
	// });

	//----- action when submit quote form ----//
	// const onSubmit: SubmitHandler<QuoteFormInput> = async (values) => {
	// 	createQuote({
	// 		...values,
	// 		clientId: selectedOption?.objectId,
	// 	} as any);
	// };

	//----- action when submit client form  ----//
	// const onSubmitClient: SubmitHandler<ClientFormInput> = async (values) => {
	// 	createClient({
	// 		...values,
	// 	} as any);

	// 	trackEvent('Simulate Carbon Quote Create Client', {
	// 		ClientName: values?.name,
	// 	});
	// };

	// return null;

	return (
		<Container
			header={<DevisCarboneHeader />}
			actionFilter={
				<DevisCarboneFilter
					selectedOption={selectedOption}
					setSearch={setSearch}
					options={options}
					isLoadingDataOption={isLoadingDataOption}
					setOpenModal={setOpenModal}
					setSelectedOption={setSelectedOption}
					setIsNew={setIsNew}
				/>
			}
			seoText={`${t('Devis Carbone')} - Carbon Maps`}
		>
			{/* <DevisCarboneTable
				setIsNew={setIsNew}
				setOpenModal={setOpenModal}
				setSelectedOption={setSelectedOption}
				setSelectedSimulation={setSelectedSimulation}
			/> */}
			<ClientList />
			{/* <DevisModal
				// eslint-disable-next-line quotes
				title={isNew === true ? t('form-create-quote-title') : t('form-update-quote-title')}
				open={openModal}
				onClose={() => {
					setSelectedSimulation({});
					// setMessage('');
					return setOpenModal(false);
				}}
				isLoading={isLoadingCreateQuote}
				onSubmit={onSubmit}
				onSubmitClient={onSubmitClient}
				options={options}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				message={message}
				isError={isError}
				setSearch={setSearch}
				// isSuccess={isSuccess}
				isLoadingClient={isLoadingCreateClient}
				selectedSimulation={selectedSimulation}
				setSelectedSimulation={setSelectedSimulation}
				isNew={isNew}
			/> */}
		</Container>
	);
};

export default DevisCarbone;
