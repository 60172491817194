import { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useMutation } from '@tanstack/react-query';
import { AlertCircle } from 'lucide-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { roleLabels } from '@carbonmaps/shared/utils/constants';
import { SendUpdateEmailFormInput, sendEmailUpdateEmailSchema } from '@carbonmaps/shared/validations/auth.validations';
import { UpdateCurrentUserEmailActionValues, updateCurrentUserEmailAction } from '@carbonmaps/ui/actions/auth.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import { classes } from '@carbonmaps/ui/components/saas/Form/FormStyle';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import AlertStatic from '../../components/Toast/AlertStatic';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

const stylesForm = {
	triggerContainer: css({
		color: siteColors.text,
		textDecoration: 'none!important',
		cursor: 'pointer',
		'&:hover': {
			// background: siteColors.grey300,
		},
	}),
	text: css({
		cursor: 'pointer !important',
	}),
};

const EmailForm = () => {
	const theme = useTheme();
	const styles = classes(theme);
	const [isChanged, setIsChanged] = useState(false);
	const [message, setMessage] = useState('');

	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const hasPermission = useMemo(() => {
		const roleFromUserLabels: any = authData?.roles.map((role) => {
			return role?.label;
		});

		return roleFromUserLabels?.includes(roleLabels.ADMIN) || roleFromUserLabels?.includes(roleLabels.EDITOR);
	}, [authData]);

	const {
		mutate: updateCurrentUserEmail,
		isLoading,
		isError,
		// isSuccess,
	} = useMutation<void, any, UpdateCurrentUserEmailActionValues, string>({
		mutationKey: ['updateCurrentUserEmail'],
		mutationFn: updateCurrentUserEmailAction,
		onError: (error, variables, context) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<SendUpdateEmailFormInput> = async (input) => {
		updateCurrentUserEmail({ ...input, email: authData?.user.email || '', languageCode });
		if (!isError) setIsChanged(true);
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
		getValues,
	} = useForm<SendUpdateEmailFormInput>({
		mode: 'onChange',
		resolver: zodResolver(sendEmailUpdateEmailSchema),
	});

	if (!hasPermission) {
		return (
			<>
				<div className={cx('flexColumn gap16')}>
					<div className="flexRow">
						<Typography variant="h3" className="grey900 fontWeight500">
							{t('Votre adresse e-mail actuelle est')} <strong>{authData?.user.email}</strong>{' '}
						</Typography>
					</div>

					<Typography variant="h3" className="grey900 fontWeight500">
						{t('Vous ne pouvez pas changer cette adresse e-mail pour des questions de sécurité')}.
						<a href="mailto:support@carbonmaps.io" className={stylesForm.triggerContainer}>
							{' '}
							<strong>{t('Contactez le support')}.</strong>
						</a>
					</Typography>
				</div>
			</>
		);
	}

	return (
		<div
			css={
				{
					/* ...styles.container */
				}
			}
		>
			<h3 css={styles.title}>{t('input-email-label')}</h3>

			<Typography css={styles.infosTitle}>
				{t('user-mail-description')} <strong>{authData?.user.email}</strong>
			</Typography>

			{errors.newEmail && errors?.newEmail?.message && (
				<AlertStatic
					text={errors.newEmail ? errors?.newEmail?.message : ''}
					type="error"
					icon={<AlertCircle css={{ color: '#C74600' }} />}
				/>
			)}

			{isError && <AlertStatic text={message} type="error" icon={<AlertCircle css={{ color: '#C74600' }} />} />}

			<p css={styles.subtitle}>{t('user-mail-form-title')}</p>

			{isChanged && !isLoading && !isError && (
				<div
					css={{
						display: 'flex',
						flexDirection: 'row',
						border: `1px solid ${theme.palette.primary.main}`,
						boxShadow: `inset 4px 0px 0px ${theme.palette.primary.main}`,
						borderRadius: '0px 8px 8px 0px',
						marginBottom: 25,
						alignItems: 'center',
						padding: 25,
						gap: 8,
					}}
				>
					<div
						css={{
							width: '504px',
						}}
					>
						<p
							css={{
								fontWeight: '600',
								fontSize: '20px',
								margin: '0 0 0 2',
							}}
						>
							{t('user-mail-error-message-title')}
						</p>

						<p
							css={{
								margin: '8px 0px 16px 2px',
							}}
						>
							{t('user-mail-resend-subtitle1')} <strong>{getValues('newEmail')}</strong>{' '}
							{t('user-mail-resend-subtitle2')}
						</p>

						<BButton
							isDisabled={!isDirty && !isValid}
							onClick={handleSubmit(onSubmit)}
							addStyles={{
								margin: 0,
								padding: 0,
								fontWeight: 600,
								backgroundColor: 'transparent',
								fontSize: '16px',
								color: `${theme.palette.primary.main}`,
							}}
							label={
								isLoading ? (
									<CircularProgress size={20} css={{ color: theme.palette.common.white }} />
								) : (
									t('user-mail-resend-button')
								)
							}
							variant="tertiary"
						/>
					</div>
					<IconButton
						color="inherit"
						aria-label="visible"
						onClick={async () => {
							setIsChanged(false);
						}}
						css={{
							background: 'transparent',
							':hover': {
								background: 'transparent',
							},
							'& path': {
								fill: 'black',
							},
						}}
					>
						<CloseIcon />
					</IconButton>
				</div>
			)}

			<div
				className="flexColumn width100"
				css={{
					maxWidth: 350,
					gap: 24,
					'.input-container': {
						width: '100%',
					},
				}}
			>
				<BInput
					type="text"
					required
					id="newEmail"
					disabled={isLoading}
					label={t('user-mail-add')}
					fullWidth
					margin="dense"
					{...register('newEmail')}
					name="newEmail"
					error={errors.newEmail ? true : false}
				/>
			</div>

			{/* <span css={{ cursor: 'pointer' }}> */}
			<BButton
				isDisabled={(!isDirty && !isValid) || !hasPermission}
				onClick={handleSubmit(onSubmit)}
				addStyles={{ marginTop: 20, fontWeight: 600, fontSize: 16, marginBottom: 28 }}
				label={
					// eslint-disable-next-line quotes
					isLoading ? (
						<CircularProgress size={20} css={{ color: theme.palette.common.white }} />
					) : (
						// eslint-disable-next-line quotes
						t('user-mail-add-button')
					)
				}
				variant={hasPermission ? 'primary' : 'secondary'}
				sx={
					!hasPermission
						? {
								color: `${theme.palette.grey[700]}!important`,
								pointerEvents: 'auto!important',
								cursor: 'not-allowed!important',
						  }
						: {}
				}
			/>
		</div>
	);
};

export default EmailForm;
