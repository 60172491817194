import { UseFormReturn } from 'react-hook-form';

import BInput from '@carbonmaps/ui/components/saas/BInput';

const QuestionTranslation = ({
	lng,
	questionForm,
}: {
	lng: string;
	questionForm: UseFormReturn;
}) => {

	return (
		<div>
			<BInput
				label={`Nom ${lng}`}
				type="text"
				{...questionForm.register(`name_${lng}`)}
				addStyles={{ marginBottom: 24 }}
			/>

			<BInput
				label={`TypeForm Id ${lng}`}
				{...questionForm.register(`typeFormId_${lng}`)}
				error={Boolean(questionForm.formState.errors.typeFormId?.message)}
				addStyles={{
					marginBottom: 20,
				}}
			/>

			<BInput
				label={`Url ${lng}`}
				{...questionForm.register(`url_${lng}`)}
				error={Boolean(questionForm.formState.errors.url?.message)}
				addStyles={{
					marginBottom: 20,
				}}
			/>

		</div>
	);
};

export default QuestionTranslation;
