import { useQuery } from '@tanstack/react-query';

import { GetImgProxyActionParams, getImgProxyAction } from './img.actions';

type UseGetImgProxyProps = {
	params: GetImgProxyActionParams;
	options?: {
		enabled?: boolean;
	};
};

export const useGetImgProxy = ({ params, options = {} }: UseGetImgProxyProps) => {
	const key = ['getImgProxy', params] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: getImgProxyAction,
		enabled: options.enabled,
	});

	return { key, result };
};
