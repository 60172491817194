// import { type Interpolation, type Theme } from '@emotion/react';
import { css, cx } from '@emotion/css';
import { type CSSProperties } from '@emotion/serialize';
import { ListItemButton, ListItemText } from '@mui/material';

import { useTranslation } from '../hooks/useTranslation';
import { FlagEn, FlagFr } from '../layouts/_common/SideBar';

import Popover from './Popover';

type Props = {
	t: any;
};

const styles = {
	container: css({
		paddingLeft: 0,
		paddingTop: '20',
		maxWidth: '386px',
	}),
	flagContainer: css({
		gap: '8px',
		marginBottom: '32px',
		cursor: 'pointer',
	}),
};

const SwitchLanguage = () => {
	const { t, i18n } = useTranslation();
	const languageOptions = [
		{
			label: t('fr'),
			icon: <FlagFr />,
			onClick: () => {
				i18n.changeLanguage('fr');
			},
		},
		{
			label: t('en'),
			icon: <FlagEn />,
			onClick: () => {
				i18n.changeLanguage('en');
			},
		},
	];

	return (
		<Popover
			anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
			trigger={
				<div className={cx('flexRow alignCenter', styles.flagContainer)}>
					{i18n?.language === 'en' ? <FlagEn /> : <FlagFr />}
					<div>{t('switch-language')}</div>
				</div>
			}
			content={languageOptions.map((e, index) => {
				return (
					<ListItemButton
						key={index}
						onClick={() => {
							// action.onClick();
							e.onClick();
							//close();
						}}
					>
						{e.icon}
						<ListItemText sx={{ marginLeft: '8px', minWidth: '200px' }} primary={e.label} />
					</ListItemButton>
				);
			})}
		></Popover>
	);
};

export default SwitchLanguage;
