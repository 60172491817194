import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as BluecheckIconNok } from '@carbonmaps/media/icons/bluechecknok.svg';
import { ReactComponent as BluecheckIconOk } from '@carbonmaps/media/icons/bluecheckok.svg';

import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import BPopover from '../BPopover';

const styles = {
	emptyContainer: css({
		gap: 8,
	}),
	emptyText: css({
		paddingRight: '25px !important',
	}),
	popoverContainer: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		width: '338px',
		padding: '24px',
		gap: '24px',
	}),
	checkContainer: css({
		gap: 8,
	}),
	icon: css({
		'& svg': { width: '100%', height: '100%' },
	}),
	popupText: css({
		maxWidth: '250px',
		fontSize: '14px !important',
	}),
	gap: css({
		gap: 8,
	}),
	text: css({
		fontSize: '14px !important',
	}),

	textCount: css({
		color: siteColors.water500,
		textAlign: 'right !important',
		fontWeight: 400,
		lineHeight: '60% !important',
		fontSize: '14px !important',
		marginRight: '2px !important',
	} as any),
	content: css({
		width: '50px',
		justifyContent: 'flex-end',
		gap: '2px',
	}),
	textContent: css({
		color: cn(siteColors.grey700, '!important'),
		textAlign: 'right !important',
		fontWeight: 400,
		lineHeight: '60% !important',
		fontSize: '14px !important',
		marginRight: '2px !important',
	} as any),
};

const Supplier = (props: any) => {
	const { value, row, column } = props;

	if (!value || value.length === 0) {
		return (
			<div className={cx('flewRow alignItemRight', styles.emptyContainer)}>
				<Typography className={styles.emptyText}>-</Typography>
			</div>
		);
	}

	const numberSupplierFullfilled = row.original?.numberSupplierFullfilled ?? 0;
	const numberSupplierNotFullfilled = row.original?.numberSupplierNotFullfilled ?? 0;

	return (
		<MUILink component={RouterLink} to={row.original.linkSupplier ? row.original.linkSupplier : '#'}>
			<div className="flexRow width100 justifyCenter ">
				<BPopover
					anchorOriginHorizontal={'right'}
					transformOriginVertical={'top'}
					transformOriginHorizontal={'right'}
					anchorOriginVertical={'bottom'}
					modeHover
					content={(onClose) => {
						return (
							<div className={cx('flexColumn', styles.popoverContainer)}>
								<div className={cx('flexRow alignCenter nowrap', styles.checkContainer)}>
									<div className={styles.icon}>
										<BluecheckIconOk />
									</div>
									<Box>
										<Typography className={styles.popupText} noWrap variant="h2">
											{numberSupplierFullfilled} fournisseur{numberSupplierFullfilled > 1 ? 's' : ''}
										</Typography>
										<Typography className={styles.popupText} noWrap>
											{numberSupplierFullfilled > 1 ? 'ont transmis leurs données' : 'a transmis ses données'}
										</Typography>
									</Box>
								</div>
								<Divider flexItem />

								<div className={cx('flexRow alignCenter nowrap', styles.gap)}>
									<div className={styles.icon}>
										<BluecheckIconNok />
									</div>
									<div className="flexColumn">
										<Typography variant="h2" className={styles.text}>
											{numberSupplierNotFullfilled} fournisseur{numberSupplierNotFullfilled > 1 ? 's' : ''}
										</Typography>
										<Typography className={styles.text}>
											{numberSupplierNotFullfilled > 1
												? // eslint-disable-next-line quotes
												  "n'ont pas été contactés ou n'ont pas fourni leurs données"
												: // eslint-disable-next-line quotes
												  "n'a pas été contacté ou n'a pas fourni ses données"}
										</Typography>
									</div>
								</div>
							</div>
						);
					}}
				>
					<div className={cx('flexRow alignCenter nowrap', styles.gap)}>
						{numberSupplierFullfilled > 0 && (
							<div className={cx('flexRow alignCenter nowrap', styles.content)}>
								<Typography className={styles.textCount}>{numberSupplierFullfilled}</Typography>
								<BluecheckIconOk />
							</div>
						)}

						<div className={cx('flexRow alignCenter nowrap', styles.content)}>
							<Typography className={styles.textContent}>{numberSupplierNotFullfilled}</Typography>
							<BluecheckIconNok />
						</div>
					</div>
				</BPopover>
			</div>
		</MUILink>
	);
};

export default Supplier;
