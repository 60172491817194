
import { darken, emphasize, lighten } from '@mui/system/colorManipulator';
import { extendWith } from 'lodash';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { randomColor } from '../utils/color.utils';

export default class PackagingModel {
	packaging: any;

	constructor(packaging: any) {
		this.packaging = packaging || {};
	}

	getLabel() {
		return this.packaging?.label;
	}

	getCarboneIntensity() {
		return this.packaging?.ges;
	}

	getWaterIntensity() {
		return this.packaging?.waterUse;
	}

	getCarboneImpact(precision = 0) {
		if (!this.packaging.carbonImpact || this.packaging.carbonImpact < 0) return this.packaging.carbonImpact;
		return formatNumber(this.packaging.carbonImpact, undefined, precision);
	}
	getWaterImpact(precision = 0) {
		if (!this.packaging.waterImpact || this.packaging.waterImpact < 0) return this.packaging.waterImpact;
		return formatNumber(this.packaging.waterImpact, undefined, precision);
	}

	getImpactByIndicator(indicator: 'carbon' | 'water', precision = 0) {
		if (indicator === 'carbon') {
			return this.getCarboneImpact(precision);
		}

		return this.getWaterImpact(precision);
	}

	getImpactOriginalByIndicator(indicator: 'carbon' | 'water', precision = 0) {
		if (indicator === 'carbon') {
			return this.packaging.carbonImpact || this.packaging.carbonImpact;
		}

		return this.packaging.waterImpact;
	}

	getImpactIndicatorSynthesis(indicator: 'carbon' | 'water', abbreviatedNumbers = false) {
		let impact;

		if (indicator === 'carbon') {
			impact = this.packaging.carbonImpact;
		}
		else {
			impact = this.packaging.waterImpact;
		}

		if (!impact || impact < 0) return impact;

		if (abbreviatedNumbers === true) {
			if (impact > 999) {
				return formatNumber(impact, undefined, 0, true, '-', true);
			}
		}

		if (impact > 1) {
			return formatNumber(impact, undefined, 0);
		}

		if (impact <= 1 && impact >= 0.01) {
			return formatNumber(impact, undefined, 2);
		}

		if (impact < 0.01) {
			return '< 0,01';
		}
	}



	getIntensityByIndicator(indicator: 'carbon' | 'water') {
		if (indicator === 'carbon') {
			return formatNumber(parseFloat(this.packaging.carbonIntensity), undefined, 2);
		}

		return formatNumber(parseFloat(this.packaging.waterIntensity), undefined, 2);
	}

	getIntensityOriginalByIndicator(indicator: 'carbon' | 'water') {
		if (indicator === 'carbon') {
			return this.packaging.carbonIntensity;
		}

		return this.packaging.waterIntensity;
	}

	getVolume(unity = 1) {
		// default T
		return this.packaging.volume * unity;
	}

	getIntensity(field = 'gesWeightedAgriculture') {
		return this?.packaging[`${field}`];
	}

	getProducts() {
		return this?.packaging?.products || [];
	}

	getImpactTotal(indicator = 'carbon') {
		let total = 0;
		const groups = this.getProducts();

		groups.map((item: any) => {
			total = total + (indicator === 'carbon' ? item?.carbonImpact : item?.waterImpact);
		});
		return total;
	}



	getDataPieChart(withColor = false, indicator = 'carbon') {
		const impactTotal = this.getImpactTotal(indicator);
		const data = [] as any;
		const products = this.getProducts();
		const index = 0;
		products.map((p: any, index: number) => {
			data.push({
				name: p?.label,
				value: indicator === 'carbon' ? p?.carbonImpactPercent : p?.waterImpactPercent,
				y: indicator === 'carbon' ? p?.carbonImpactPercent : p?.waterImpactPercent,
				isBlueCheck: p.tagAdvancedModelization === 'yes',
			});
			index += 1;
		});

		return data;
	}

	// getImpactPerUnityByIndicator(indicator: 'carbon' | 'water'/* , precision = 0 */) {
	// 	if (indicator === 'carbon') {
	// 		return parseFloat(this.packaging.carbonIntensity) * parseFloat(this.packaging.volume)/*  * 1000 */ / this.packaging.countUse;
	// 	}

	// 	return parseFloat(this.packaging.waterIntensity) * parseFloat(this.packaging.volume)/*  * 1000 */ / this.packaging.countUse;
	// }
}

