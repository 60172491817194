import { css, cx } from '@emotion/css';
// import QuestionFormTabs from '../../../suppliers/tabs/rse/blocks/GeneralQuestionFormTabs';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../lib/colors';
import ContentRseDetails from './blocks/ContentRseDetails';
import DetailsQuestionFormTabs from './blocks/DetailsQuestionFormTabs';
import { useFindSupplierQuestionFormsTabs } from './hooks/rse.hooks';

const styles = {
	emptyBlock: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFF',

		padding: 24,
		gap: '16px',
	}),
};

const RseDetails = () => {
	const params = useParams();

	const { data: tabsData, isLoading: isLoadingTabsData } = useFindSupplierQuestionFormsTabs({
		supplierId: _.toString(_.get(params, 'supplierId')),
	});

	// const analytics = useJuneTrack();

	//---- track event page ---- //
	// const trackEvent = useCallback((evenName: string, options?: any) => {
	// 	if (!analytics) return;
	// 	console.log('****************');
	// 	analytics.track(evenName, options, {
	// 		context: { category: 'Explore' },
	// 	});
	// }, []);

	// useEffect(
	// 	() => {
	// 		trackEvent?.('Supplier Performance Land', { SupplierLabel: _.get(params, 'supplierId') });
	// 	},
	// 	[
	// 		/* params, trackEvent */
	// 	],
	// );

	if (!isLoadingTabsData && _.isEmpty(tabsData)) {
		return <EmptyBlock />;
	}

	return (
		<div className={cx('flexColumn width100 gap32' /* , styles.container */)}>
			<DetailsQuestionFormTabs />
			<ContentRseDetails />
		</div>
	);
};

export default RseDetails;

const EmptyBlock = () => {
	const { t } = useTranslation();

	return (
		<div className={cx('width100 flexColumn alignCenter', styles.emptyBlock)}>
			La maturité RSE de votre fournisseur n'a pas encore été calculée
		</div>
	);
};
