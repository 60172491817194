import { useQuery } from '@tanstack/react-query';

import { classNames } from '@carbonmaps/shared/utils/constants';

// const today = new Date();

export const useFindCampaign = ({ companyId }: { companyId: string }) => {
	return useQuery({
		queryKey: ['findCampaign', { companyId }] as const,
		queryFn: async (ctx) => {
			try {
				const { companyId } = ctx.queryKey[1];

				// const qFormQuery = new Parse.Query(classNames.QUESTION_FORM).equalTo('published', true);
				const campaignQuery = new Parse.Query(classNames.QUESTION_CAMPAIGN)
					.include('questionForm')
					.descending('createdAt')
					.equalTo('company', `Company$${companyId}`);
				// .matchesQuery('questionForm', qFormQuery);

				const campaigns = await campaignQuery.find({ json: true });
				return campaigns as unknown as Record<string, any>[];
			} catch (error) {
				console.log('--- findCampaign action error -----------------', error);
				return Promise.reject(error);
			}
		},
	});
};
