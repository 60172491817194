import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';
import { getInfoGeneralAction } from '../actions/dashboard.actions';

export const useGetInfoGeneral = () => {
	const { selectedYear } = useYearSelection();
	const queryResult = useQuery({
		queryKey: ['getInfoGeneral', { selectedYear }],
		queryFn: getInfoGeneralAction,
	});

	return queryResult;
};
