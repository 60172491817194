import { type QueryFunctionContext } from '@tanstack/react-query';

export type GetImgProxyActionParams = {
	filename: string;
};

export const getImgProxyAction = async (
	context: QueryFunctionContext<readonly ['getImgProxy', GetImgProxyActionParams]>,
) => {
	try {
		const {
			queryKey: {
				1: { filename },
			},
		} = context;

		const url: string = await Parse.Cloud.run('getImgProxy', { filename });
		return url;
	} catch (error) {
		console.log(' ------ getImgProxyAction error: ', error);
		return Promise.reject(error);
	}
};
