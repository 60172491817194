import { useEffect } from 'react';

import { css, cx } from '@emotion/css';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useTranslation } from '../../../../hooks/useTranslation';

import ProductAcvFilter from './ProductAcvFilter';
import ProductAcvTable from './ProductAcvTable';

const ProductAcv = () => {
	const { setBreadcrumbs } = useApp();

	// translation
	const { t } = useTranslation();
	// ---- breadcrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('audit-title') }, { text: t('acv-breadcrumbs') }]);
	}, [t]);

	return (
		<div className={cx('flexColumn')}>
			<ProductAcvFilter />
			<ProductAcvTable />
		</div>
	);
};

export default ProductAcv;
