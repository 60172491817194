import { useCallback, useState } from 'react';

import _ from 'lodash';

import SearchAutocomplete from '../../../../../../components/SearchAutocompleteV2';
import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';

const QuoteSearch = () => {
	const { t } = useTranslation();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();

	// // --- ref for fetch data table ---- //
	// const fetchIdRef = useRef(0);

	// ---- table params ( pagination, sort , size ) ---- //
	// const [paramsTable, setTableParams] = useState<any>(searchQueryParams);

	// ----  when change params react table ---- //
	// const updateTableParams = useCallback(
	// 	(data: any) => {
	// 		const { pageIndex, pageSize, ...rest } = data;
	// 		// Give this fetch an ID
	// 		// const fetchId = ++fetchIdRef.current;

	// 		// Only update the data if this is the latest fetch
	// 		// if (fetchId === fetchIdRef.current) {
	// 		// 	setTableParams({
	// 		// 		...paramsTable,
	// 		// 		...(pageIndex && { page: pageIndex + 1 }),
	// 		// 		...(pageSize && { size: pageSize }),
	// 		// 		...rest,
	// 		// 	});
	// 		// }
	// 	},
	// 	[paramsTable],
	// );

	return (
		<SearchAutocomplete
			// remove skeleton in input search
			//skeleton={skeleton}
			size={20}
			setParamsAutocomplete={setSearchQueryParams && _.debounce(setSearchQueryParams, 500)}
			isLoading={false}
			// placeholder={t(searchPlaceholder, { product: toLowerCase(t('product')), of_product: t('of_product') })}
			placeholder={t('search')}
			options={[]}
			// isClearInputValue={isClearInputValue}
			// setIsClearInputValue={setIsClearInputValue}
			// className={searchClassName}
		/>
	);
};

export default QuoteSearch;
