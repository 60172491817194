import { z } from 'zod';

export const CompanyFormValidationSchema = z.object({
	name: z
		.string({ required_error: 'Nom obligatoire' })
		.min(1, 'Nom obligatoire')
		.max(255, 'Nom doit avoir 255 caractères au maximum'),
	code: z
		.string({ required_error: 'code obligatoire' })
		.min(1, 'code obligatoire')
		.max(255, 'Code doit avoir 255 caractères au maximum')
		// .refine((value) => { return value === value.toLowerCase(); }, {
		// 	message: 'Code doit être en minuscules.',
		// })
		.refine(
			(value) => {
				return /^[a-zA-Z0-9]+$/.test(value);
			},
			{
				message: 'Code ne doit contenir que des caractères alphanumériques (sans espaces ni caractères spéciaux).',
			},
		),
	// eslint-disable-next-line quotes
	maxUsers: z.number({ required_error: "Nombre maximale d'utilisateur obligatoire" }),
	hasSupplierProduct: z.boolean(),
	hasSupplierIng: z.boolean(),
	objectId: z.string().optional(),
	fileLogo: z.any({}).optional(),
	// fileLogo: z.object({
	// 	name: z.string(),
	// 	type: z.string(),
	// 	size: z.number(),
	// 	lastModified: z.number(),
	// 	webkitRelativePath: z.string(),
	// 	lastModifiedDate: z.date(),
	// }),
});

export type CompanyFormInput = z.infer<typeof CompanyFormValidationSchema>;

export const ConfigFacetsValidationSchema = z.object({
	category: z.string({ required_error: 'Le statut est obligatoire' }),
});

export type ConfigFacetFormInput = z.infer<typeof ConfigFacetsValidationSchema>;
