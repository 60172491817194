import React from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
// import { HelpCircle } from 'lucide-react';

import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { siteColors } from '../../lib/colors';

// import BPopover from '../BPopover';

// import EmptyValue from './EmptyValue';

const styles = {
	container: css({
		height: 16,
		// width: '45%',
		width: '100%',
		minWidth: 100,
		borderRadius: 500,
		overflow: 'hidden',
	}),
	innerContainer: css({
		height: '100%',
		borderRadius: 6,
		width: '100%',
	}),
	// text: css({
	// 	marginRight: '8px',
	// 	fontWeight: 600,
	// 	fontSize: '16px ! important',
	// }),
	// percent: css({
	// 	marginRight: '8px !important',
	// }),
};

type Props = {
	percentValue: number;
	start?: number;
	color?: string;
};

const PercentBar = ({ percentValue, start = 0, color = siteColors.primary }: Props) => {
	// const theme = useTheme();

	// const currentIndex = (sortedRows as any[]).findIndex((e: any) => {
	// 	return e.index === row.index;
	// });

	// let start =
	// 	row.original?.previousMetaData && row.original?.previousMetaData[`${column.id}LastPosition`]
	// 		? row.original?.previousMetaData[`${column.id}LastPosition`]
	// 		: 0;

	// for (let index = 0; index < currentIndex /* row.index */; index++) {
	// 	start = start + sortedRows[index]['values'][column.id];
	// }

	return (
		<div className={cx('bgGrey500', styles.container)}>
			<div
				className={cx('percent-progress', styles.innerContainer)}
				style={{
					['--width-box' as never]: `${percentValue}%`,
					['--background-color-box' as never]: color,
					['--margin-left-box' as never]: `${start}%`,
				}}
			/>
		</div>
	);
};

export default PercentBar;
