import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Hidden,
	Radio,
	RadioGroup,
	Switch,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { AlertCircle } from 'lucide-react';
import { nanoid } from 'nanoid';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import z from 'zod';

import { classNames } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInputFile from '@carbonmaps/ui/components/saas/BInputFile';

import { useGetImgProxy } from '../../../lib/react-query/features/img/img.hooks';
import { TOP_BAR_HEIGHT } from '../../../utils/constants';
import { getServerUrl } from '../../../utils/utils';
import { SheetImage } from '../../sectorSheet/SectorSheetList';

import AlertStatic from '../../../components/Toast/AlertStatic';
import { siteColors } from '../../../lib/colors';
import SectorSheetTranslation from './SectorSheetTranslation';
import { useGetSectorSheetById, useGlobalLoading } from './sectorSheet.hooks';

const styles = {
	hidden: css({
		display: 'none',
	}),

	globalDisabled: css({
		'& *': {
			cursor: 'not-allowed',
			pointerEvents: 'none',
			opacity: 0.9,
		},
	}),

	stickyToolbar: css({
		'.mdxeditor [role=toolbar]': {
			position: 'sticky',
			top: TOP_BAR_HEIGHT,
			// backgroundColor: 'white',
			// zIndex: 100,
		},
	}),
};

const sheetFormZodSchema = z.object({
	title_en: z.string().min(1),
	content_en: z.string().min(1),
	title_fr: z.string().min(1),
	content_fr: z.string().min(1),
	published: z.boolean(),
});

const SectorSheet = () => {
	// const [lng, setLng] = useState('fr');
	const params = useParams<{ sheetId?: string }>();

	const {
		result: { data: sheetData, refetch },
	} = useGetSectorSheetById({ sheetId: params.sheetId || '' });

	const queryClient = useQueryClient();

	const { mutate: saveSheet, isLoading: isSaveLoading } = useMutation({
		mutationKey: ['saveSectorSheet'],
		mutationFn: async (p: {
			sheetId: string;
			title_en: string;
			content_en: string;
			title_fr: string;
			content_fr: string;
			published?: boolean;
		}) => {
			try {
				const sheet = new Parse.Object(classNames.SECTOR_SHEET);

				sheet.set('objectId', p.sheetId);

				!_.isNil(p.title_en) && sheet.set('translation.en.title', p.title_en);
				!_.isNil(p.content_en) && sheet.set('translation.en.content', p.content_en);
				!_.isNil(p.title_fr) && sheet.set('translation.fr.title', p.title_fr);
				!_.isNil(p.content_fr) && sheet.set('translation.fr.content', p.content_fr);
				!_.isNil(p.published) && sheet.set('published', p.published);

				return (await sheet.save()).toJSON();
			} catch (error) {
				console.log('----- saveSectorSheet error', error);
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries(['getSectorSheetById']);
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
		onError(error, variables, context) {
			setGlobalLoading(false);
		},
	});

	const sheetForm = useForm({
		// mode: "onBlur",
		defaultValues: async () => {
			const { data: sheetData } = await refetch();
			return {
				title_en: sheetData?.translation?.['en']?.title || '',
				content_en: sheetData?.translation?.['en']?.content || '',
				title_fr: sheetData?.translation?.['fr']?.title || '',
				content_fr: sheetData?.translation?.['fr']?.content || '',
				published: sheetData?.published || false,
				// language: lng,
			};
		},
		resolver: zodResolver(sheetFormZodSchema),
		// values: {
		// 	title_en: sheetData?.translation?.['en']?.title || 'Default title',
		// 	content_en: sheetData?.translation?.['en']?.content || '## Default content',
		// 	title_fr: sheetData?.translation?.['fr']?.title || 'Titre par défaut',
		// 	content_fr: sheetData?.translation?.['fr']?.content || '## Contenu par défaut',
		// 	// language: lng,
		// },
		// values: {
		// 	title: sheetData?.translation?.[lng]?.title || 'Default title',
		// 	content: sheetData?.translation?.[lng]?.content || '## Default content',
		// 	language: lng,
		// },
	});

	const { isGlobalLoading, setGlobalLoading } = useGlobalLoading();

	const [renderKey, setRenderKey] = useState<string>(nanoid());

	useEffect(() => {
		if (!sheetData) {
			return;
		}

		setRenderKey(nanoid());
	}, [sheetData]);

	const [displayLang, setDisplayLang] = useState('fr');

	if (sheetData === null) {
		return <div>not found</div>;
	}

	return (
		<>
			<div
				// className="flexColumn"
				css={{
					padding: '100px',
					gap: 24,
					'& > *': {
						marginBottom: 24,
					},
					// backgroundColor: 'red',
				}}
				className={cx('', isGlobalLoading ? styles.globalDisabled : '')}
			>
				{!_.isEmpty(sheetForm.formState.errors) && (
					<AlertStatic
						icon={<AlertCircle color="#C74600" />}
						type="error"
						text={
							<>
								{Object.entries(sheetForm.formState.errors).map(([key, e]) => {
									return (
										<>
											[{key}]: {e.message}
											<br />
										</>
									);
								})}
							</>
							// .join(',\n')
						}
					/>
				)}
				<SheetImageWithUpload />

				<RadioGroup
					row
					/* {...field} */ defaultValue="fr"
					onChange={(_, val) => {
						setDisplayLang(val);
					}}
				>
					<FormControlLabel value="fr" control={<Radio />} label="Français" />
					<FormControlLabel value="en" control={<Radio />} label="Anglais" />
				</RadioGroup>

				<div>
					<Controller
						key={renderKey}
						name="published"
						control={sheetForm.control}
						render={({ field, fieldState: { error } }) => {
							// eslint-disable-next-line react-hooks/rules-of-hooks
							const defaultChecked = useMemo(() => {
								return field.value;
								// eslint-disable-next-line react-hooks/exhaustive-deps
							}, []);

							return (
								<div>
									<FormControlLabel
										control={
											<Switch
												{...field}
												// // ! I want an uncontrolled field
												// checked={_.isBoolean(field.value) ? field.value : false} // opt out of uncontrolled behavior
												checked={undefined}
												defaultChecked={defaultChecked}
												onChange={(e) => {
													return field.onChange(e.target.checked);
												}}
											/>
										}
										label={'Published'} // {...other}
									/>

									{!!error /* || helperText */ && (
										<FormHelperText error={!!error}>
											{error ? error?.message : /* helperText */ 'unknown error'}
										</FormHelperText>
									)}
								</div>
							);
						}}
					/>
				</div>

				{/* <FormControl>
				<FormLabel id="demo-row-radio-buttons-group-label">Language</FormLabel>
				<Controller
					control={sheetForm.control}
					name="language"
					render={({ field }) => {
						return (
							<RadioGroup row {...field}>
								<FormControlLabel value={true} control={<Radio />} label="Français" />
								<FormControlLabel value={false} control={<Radio />} label="Anglais" />
							</RadioGroup>
						);
					}}
				/>
			</FormControl> */}

				<div className={cx('', styles.stickyToolbar, displayLang !== 'fr' ? styles.hidden : '')}>
					<h2>Fiche en Français</h2>
					<SectorSheetTranslation sheetForm={sheetForm as never} lng="fr" sheetData={sheetData} />
				</div>
				<div className={cx('', styles.stickyToolbar, displayLang !== 'en' ? styles.hidden : '')}>
					<h2>Fiche en Anglais</h2>
					<SectorSheetTranslation sheetForm={sheetForm as never} lng="en" sheetData={sheetData} />
				</div>
			</div>

			<div
				// className="bgGrey100"
				css={{
					// float: '',
					borderTop: '1px solid #E0E0E0',
					backgroundColor: siteColors.grey100,
					position: 'fixed',
					bottom: 0,
					padding: '32px 24px 32px 108px',
					width: '100%',
				}}
			>
				<BButton
					label={isSaveLoading ? <CircularProgress size={24} sx={{ color: siteColors.common.white }} /> : 'Sauvegarder'}
					variant="primary"
					onClick={sheetForm.handleSubmit(
						async (data) => {
							// console.log('data', data);
							setGlobalLoading(true);
							saveSheet({ sheetId: params.sheetId || '', ...data });
						},
						(errors) => {
							console.log(errors);
						},
					)}
				/>
			</div>
		</>
	);
};

export default SectorSheet;

const SheetImageWithUpload = () => {
	// const [file, setFile] = useState<File>();
	const params = useParams<{ sheetId?: string }>();
	const queryClient = useQueryClient();

	const {
		result: { data: sheetData },
		key: [strKey],
	} = useGetSectorSheetById({ sheetId: params.sheetId || '' });

	const { mutateAsync: uploadSheetImage } = useMutation({
		mutationKey: ['uploadSheetImage'],
		mutationFn: async (values: { file: File; sheetId: string }) => {
			try {
				const { file, sheetId } = values;
				const form = new FormData();
				form.append('file', file);

				const result = await fetch(`${getServerUrl()}uploadFile`, {
					method: 'POST',
					body: form,
				});

				const { file: filename } = await result.json();

				const obj = new Parse.Object(classNames.SECTOR_SHEET);
				obj.set('objectId', sheetId);
				obj.set('imageUrl', filename);

				const saved = await obj.save();

				return saved.toJSON();

				// return (await Parse.Cloud.run('updateCompany', { ...restValues, logo: filename })) as any;
			} catch (error) {
				console.log('---- uploadSheetImage error ----------', error);
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries([strKey]);
		},
	});

	return (
		<div>
			<div>
				<SheetImage imageUrl={sheetData?.imageUrl} marginBottom={24} />
			</div>
			<BInputFile
				accept="image/png, image/jpeg"
				onChange={(file) => {
					console.log(file);

					if (file) {
						uploadSheetImage({ file, sheetId: params.sheetId || '' });
					}
				}}
				value={undefined}
			/>
		</div>
	);
};
