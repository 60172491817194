import { css } from '@emotion/css';

import Typography from '../Typography';

const styles = {
	circle: css({
		width: 10,
		height: 10,
		borderRadius: '50%',
		background: 'var(--background)',
	}),
	label: css({
		minWidth: 160,
	}),
};

type LegendProps = {
	label: string;
	color: string;
	value: number;
};

const Legend = ({ label, color, value }: LegendProps) => {
	return (
		<div className="flexRow alignCenter gap7">
			<div className={styles.circle} style={{ ['--background' as any]: color }} />
			<Typography className={styles.label} variant="caption">
				{label}
			</Typography>
			<Typography className="number grey700" variant="caption">
				{value}
			</Typography>
		</div>
	);
};

export default Legend;
