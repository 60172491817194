import { ReactNode } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { RoleLabel } from '@carbonmaps/shared/types/role.types';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import UnAuthorized from '../containers/unAuthorized/UnAuthorized';
import useHasRoles from '../hooks/useHasRoles';
import { useGetClientAuthQuery } from '../lib/react-query/features/auth/auth.hooks';

import { useQueryParams } from '../hooks/useSearchParams';
import SplashScreen from './SplashScreen';

type Props = {
	allowedRolesLabels?: RoleLabel[] | 'all';
	children?: ReactNode;
	fallback?: ReactNode;
};

const AuthGuard = ({ allowedRolesLabels, children, fallback }: Props) => {
	const location = useLocation();
	const hasRoles = useHasRoles();

	const queryParams = useQueryParams();

	const {
		result: { data: authData, isLoading },
	} = useGetClientAuthQuery();

	if (isLoading) {
		// return <h1>Verifying your rights...</h1>;
		return <SplashScreen />;
	}

	if (!authData) {
		return (
			<Navigate
				replace
				state={{ from: location }}
				to={`${FRONT_PATH_NAMES.logIn}?isFromLogin=${queryParams?.isFromLogin || false}`}
			/>
		);
	}

	if (allowedRolesLabels === 'all') {
		return children ? children : <Outlet />;
	}

	if (!hasRoles(allowedRolesLabels || [], { strictCompany: false })) {
		return fallback ? fallback : <UnAuthorized />;
	}

	return children ? children : <Outlet />;
};

export default AuthGuard;
