import { AlertCircle, AlertTriangle, Box, Carrot, ClipboardCheck, PackageOpen } from 'lucide-react';

import {
	COMPOSITION_ERROR,
	ERROR_COMPOSITION_COLOR,
	ERROR_UID_LABEL_COLOR,
	ERROR_VOLUME_COLOR,
	INGREDIENT,
	PACKAGING,
	PRODUCT,
	UID_LABEL_ERROR,
	VOLUME_ERROR,
} from '@carbonmaps/shared/utils/audit';
import { displayAuditValue, formatNumber, truncNumberFormat } from '@carbonmaps/ui/utils/numberFormat';

import { siteColors } from '../lib/colors';
import { orderBy } from './array.utils';

//----  config header audit details ---- //
export const HEADER_CONFIG_MAP = new Map();

// ---- breadcrumbs ---- //
export const BREADCRUMBS_MAP = new Map();
BREADCRUMBS_MAP.set(PRODUCT, 'Détail produits');
BREADCRUMBS_MAP.set(INGREDIENT, 'audit-ingredient-detail');
BREADCRUMBS_MAP.set(PACKAGING, 'Détail emballages');

HEADER_CONFIG_MAP.set(PRODUCT, {
	icon: Box,
	color: siteColors.primary,
	text: 'product_data_to_improve',
	background: 'linear-gradient(90deg, #EEF1FF 0.53%, #EAE5FF 27.26%)',
});

HEADER_CONFIG_MAP.set(INGREDIENT, {
	icon: Carrot,
	color: siteColors.green500,
	text: 'ingredient_data_to_improve',
	background: 'linear-gradient(90deg, #E4F3E3 0.53%, #E0F0F3 99.99%)',
});

HEADER_CONFIG_MAP.set(PACKAGING, {
	icon: PackageOpen,
	color: siteColors.brown500,
	text: 'packaging_data_to_improve',
	background: '#FDF8E7',
});

// mapping error data config
export const AUDIT_CONFIG_ITEMS_MAP = new Map();

//---- uid or label error ---- //
const label_uid_error_key = 'uid_label_error_percent';
const label_uid_error = {
	label: UID_LABEL_ERROR,
	color: ERROR_UID_LABEL_COLOR,
	key: label_uid_error_key,
	order: 2,
	icon: <AlertCircle color={ERROR_UID_LABEL_COLOR} />,
};

AUDIT_CONFIG_ITEMS_MAP.set(label_uid_error_key, label_uid_error);

//---- volume error ---- //
const volume_error_key = 'tonnage_error_percent';
const volume_error = {
	label: VOLUME_ERROR,
	// color: '#C70000',
	color: ERROR_VOLUME_COLOR,
	key: volume_error_key,
	order: 3,
	icon: <AlertCircle /* color={'#C70000'} */ color={ERROR_VOLUME_COLOR} />,
};
AUDIT_CONFIG_ITEMS_MAP.set(volume_error_key, volume_error);

//---- composition error ---- //
const composition_error_key = 'composition_error_percent';
const composition_error = {
	label: COMPOSITION_ERROR,
	color: ERROR_COMPOSITION_COLOR,
	key: composition_error_key,
	order: 3,
	icon: <AlertTriangle color={ERROR_COMPOSITION_COLOR} />,
};
AUDIT_CONFIG_ITEMS_MAP.set(composition_error_key, composition_error);

// ---- valid data ---- //
const valid_data_key = 'valid_data_percent';
const valid_data = {
	label: 'Donnée valide',
	color: siteColors.green500,
	key: valid_data_key,
	order: 1,
	icon: <ClipboardCheck color={siteColors.green500} />,
};
AUDIT_CONFIG_ITEMS_MAP.set(valid_data_key, valid_data);

// -------------------------------- //
// -------- legend audit card ----- //
// -------------------------------- //

const translateKeyToText = (key: string, suffix = '') => {
	console.log('key', key);
};

// ---- GLOBAL CONFIG ---- //

const GLOBAL_CONFIG_MAP = new Map();

const FULL_FILLED_DATA = 'FULL_FILLED_DATA';
const PARTIAL_FULL_FILLED_DATA = 'PARTIAL_FULL_FILLED_DATA';
const IMPROVED_DATA = 'IMPROVED_DATA';

GLOBAL_CONFIG_MAP.set(IMPROVED_DATA, {
	text: 'Le jeu de données peut être amélioré.',
	color: '#ED9D38',
});

GLOBAL_CONFIG_MAP.set(PARTIAL_FULL_FILLED_DATA, {
	text: 'Le jeu de données est presque complet',
	color: '#F0BF41',
});

GLOBAL_CONFIG_MAP.set(FULL_FILLED_DATA, {
	text: 'Le jeu de données n’a aucun problème.',
	color: '#95BF3C',
});

type auditResult = {
	dataAuditProducts: any;
	dataAuditPackaging: any;
	dataAuditIngredients: any;
};

export const dataAudit = (data: any, isProduct = false) => {
	if (!data) return [];

	const dataError = [] as any;

	const keyValues = isProduct
		? Object.keys(data)?.filter((key: string) => key !== 'tonnage_error' && key !== 'tonnage_error_percent')
		: Object.keys(data);

	keyValues.forEach((item: string) => {
		const configItem = AUDIT_CONFIG_ITEMS_MAP.get(item);

		// display only data in config and value > 0
		if (configItem && data[item]) {
			const value = data[item] || 0;
			dataError.push({
				...configItem,
				y: data[item],
				value: displayAuditValue(data[item]),
				valueAsNumber: data[item],

				// formatNumber(data[item], undefined, (value < 100 && value > 99) || value < 1 ? 2 : 0),
			});
		}
	});

	return orderBy(dataError, 'order', 'asc');
};

export const getAuditProduct = (data?: auditResult) => {
	return dataAudit(data?.dataAuditProducts, true);
};

export const getDataAuditIngredient = (data?: auditResult) => {
	return dataAudit(data?.dataAuditIngredients);
};

export const getDataAuditPackaging = (data?: auditResult) => {
	return dataAudit(data?.dataAuditPackaging);
};

export const getProductValid = (data?: auditResult) => {
	return data?.dataAuditProducts[valid_data_key] || 0;
};

export const getIngredientValid = (data?: auditResult) => {
	return data?.dataAuditIngredients[valid_data_key] || 0;
};

export const getPackagingValid = (data?: auditResult) => {
	return data?.dataAuditPackaging[valid_data_key] || 0;
};

export const getGlobalData = (data?: auditResult) => {
	const globalPercent = (getProductValid(data) + getIngredientValid(data) + getPackagingValid(data)) / 3;
	return globalPercent;
};

export const isValidData = (data?: auditResult, itemType?: string) => {
	if (itemType === PRODUCT) {
		const validPercent = getProductValid(data);
		return validPercent === 100;
	}

	if (itemType === INGREDIENT) {
		const validPercent = getIngredientValid(data);
		return validPercent === 100;
	}

	if (itemType === PACKAGING) {
		const validPercent = getPackagingValid(data);
		return validPercent === 100;
	}

	return false;
};

export const getGlobalConfigValue = (value: number) => {
	let key_data = FULL_FILLED_DATA;

	if (value < 100) {
		key_data = PARTIAL_FULL_FILLED_DATA;
	}

	if (value < 70) {
		key_data = IMPROVED_DATA;
	}

	return GLOBAL_CONFIG_MAP.get(key_data);
};
