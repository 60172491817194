import _ from 'lodash';

import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';

import PackagingKPIs from './PackagingKPIs';
import PackagingsFilter from './PackagingsFilter';
import PackagingTable from './PackagingTable';

const PackagingsDefault = () => {
	// init filters values
	const [searchQueryParams] = useSearchQueryParams();

	return (
		<div
			css={{
				'.filterContainer-wrapper': {
					// paddingTop: 0,
					paddingLeft: 0,
					paddingRight: 0,
					top: `${_.toString(58 * 2 + 3)}px`,
				},
			}}
		>
			<PackagingsFilter withMore={false} />
			{/* <PackagingRepartition filterOptions={searchQueryParams} /> */}
			<div className="flexColumn width100 gap24">
				<PackagingKPIs filterOptions={searchQueryParams} />
				<PackagingTable filterOptions={searchQueryParams} manualSortBy />
			</div>
		</div>
	);
};

export default PackagingsDefault;
