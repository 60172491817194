import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';
import { css, cx } from '@emotion/css';
import { Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import Skeleton from '../../../../../components/Skeleton';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';
import { useFindQuestionFormsTabs, useRseQFormMasterCategoryParam, useRseQFormTabQueryParam } from '../hooks/rse.hooks';
import { geTallCategoriesOption } from './MasterCategorySelect';

const styles = {
	tabs: css({
		'.MuiTabs-flexContainer': {
			borderBottom: cn('1px solid', siteColors.grey500),
			// padding: '24 0',
			display: 'flex',
			gap: 16,
			// marginBottom: '24px',
		},

		'.MuiButtonBase-root': {
			textTransform: 'none',
			fontSize: '14px !important',
			fontWeight: '400',
			color: cn(siteColors.grey700, '!important'),
			paddingTop: '22px',
			paddingBottom: '20px',
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
			minWidth: '0px !important',
		},
		'.Mui-selected': {
			color: cn(siteColors.primary, '!important'),
		},
		'.MuiTab-textColorPrimary': {},
		'.MuiTabs-indicator': {
			backgroundColor: siteColors.primary,
		},
	}),

	tabsSelected: css({
		'.MuiTabs-indicator': {
			backgroundColor: `${siteColors.primary} !important`,
		},
	}),
};

const QuestionFormTabs = () => {
	const { i18n } = useTranslation();
	const [qFormId, setQFormId] = useRseQFormTabQueryParam();

	const { data: tabsData, isLoading: isLoadingTabsData } = useFindQuestionFormsTabs();

	useEffect(() => {
		if (tabsData) {
			if (qFormId && tabsData[qFormId]) {
				setQFormId(qFormId);
				return;
			}

			const qFormIdZero = _.get(_.entries(tabsData), '0.0');
			setQFormId(qFormIdZero);
		}
	}, [tabsData]);

	const [, setMasterCategory] = useRseQFormMasterCategoryParam();

	const lang = getIsoCountryCode(i18n.language);
	const allCategoriesOption = geTallCategoriesOption(lang);

	const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
		// setTabValue(newValue);
		setMasterCategory(allCategoriesOption);
		setQFormId(newValue);
	};

	if (isLoadingTabsData) {
		return <Skeleton width={400} height={48} sx={{ mb: '24px' }} />;
	}

	if (_.isEmpty(tabsData) || _.entries(tabsData).length < 2) {
		return <div className="width100" css={{ height: 24 /* , background: 'red' */ }}></div>;
	}

	return (
		<div css={{ marginBottom: 32 }}>
			<Tabs
				// value={tabValue}
				value={qFormId}
				onChange={handleChange}
				className={cx(styles.tabs, styles.tabsSelected)}
			>
				{_.entries(tabsData || {}).map(([key, value]) => {
					return (
						<Tab
							key={nanoid()}
							value={key}
							label={_.get(value, 'displayName')}
							// sx={
							// 	{
							// 		// padding: '24px 0px'
							// 	}
							// }
						/>
					);
				})}
			</Tabs>
		</div>
	);
};

export default QuestionFormTabs;
