import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier.svg';
import { useGetSupplierSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetSupplierSheetTimePeriod';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import BlueCheck from '../../components/BlueCheck';
import LightTooltip from '../../components/LightTooltip';
import SheetPageHeader from '../../components/layout/page/SheetPageHeader';
import Modelization from '../../components/supplier/Modelization';
import { useTranslation } from '../../hooks/useTranslation';
import SupplierModel from '../../models/Supplier.model';

const SupplierHeader = () => {
	const { t } = useTranslation();
	const params = useParams();
	const [supplierObject, setSupplierObject] = useState<SupplierModel>();
	const { data, isLoading /* , isError, error */ } = useGetSupplierSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.supplierId!,
		// period: 2023,
	});

	useEffect(() => {
		if (!data) return;

		const { supplier } = data;
		setSupplierObject(new SupplierModel(supplier));
	}, [data]);

	const theme = useTheme();

	if (isLoading) {
		return <Skeleton variant="rectangular" height="120px" sx={{ mb: '16px', borderRadius: '0px 0px 16px 16px' }} />;
	}

	return (
		<SheetPageHeader
			icon={<SupplierIcon css={{ '& path': { fill: theme.palette.common.black } }} width={32} height={32} />}
			sx={_.merge({
				background: 'linear-gradient(90deg, #EEF2FB 0.53%, #E9EEF9 99.99%)',
			})}
			leftElement={
				<Box ml="24px">
					<Box className="flexRow alignCenter">
						<Typography variant="h3" fontWeight={500}>
							{t('Supplier')}
						</Typography>
					</Box>
					<Box className="flexRow alignCenter" mb={pxToRem(8)}>
						{/* <LightTooltip title={supplierObject?.getName()}>
							<Typography variant="h2" maxWidth="550px" noWrap width="fit-content">
								{supplierObject?.getName()}
							</Typography>
						</LightTooltip> */}
						<BlueCheck
							title={supplierObject?.getName()}
							variant="h2"
							withIcon={_.get(data, 'supplier.isBlueCheck', false)}
						/>
					</Box>

					{/* <Box className="flexRow alignCenter">
						<Modelization value={supplierObject?.getModelizationType() ?? 1} />
					</Box> */}
				</Box>
			}
			variant="flexible"
		/>
	);
};

export default SupplierHeader;
