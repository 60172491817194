import { IUser } from '@carbonmaps/shared/types/user.types';
import { LogInInput, ResetPasswordInput, SendUpdateEmailFormInput } from '@carbonmaps/shared/validations/auth.validations';

//--------------------------------------------------------------------------------------//
//                                                                                      //
//                                       Queries                                        //
//                                                                                      //
//--------------------------------------------------------------------------------------//

export const hasUserConnectedAction = async () => {
	try {
		const user = await Parse.User.currentAsync();

		if (!user) {
			return false;
		}

		if (!user.getSessionToken()) {
			return false;
		}

		return true;
	} catch (error) {
		console.log(' ------ hasUserConnected error: ', error);
		return false;
	}
};

export const getAuthedUserAction = async () => {
	try {
		const user = await Parse.User.currentAsync();

		if (!user) {
			throw new Error('No user found');
		}

		console.log('current user: ', user?.toJSON());

		return user.toJSON() as unknown as IUser;
	} catch (error) {
		console.log(' ------ getCurrentUser error: ', error);
		return Promise.reject(error);
	}
};


//--------------------------------------------------------------------------------------//
//                                                                                      //
//                                      Mutations                                       //
//                                                                                      //
//--------------------------------------------------------------------------------------//

export const logInAction = async (values: LogInInput): Promise<void> => {
	try {
		const loggedInUser = await Parse.User.logIn(values.email, values.password);

		if (!loggedInUser) {
			throw new Error('No account found');
		}

		const currentUser = await Parse.User.currentAsync<Parse.User<IUser>>();

		if (!currentUser || (currentUser && !currentUser.getSessionToken())) {
			throw new Error('Login failed');
		}

		console.log('logged in currentUser: ', currentUser.toJSON());

		// return currentUser;
	} catch (error: any) {
		console.log(' ------ login error: ', error);
		return Promise.reject(error);
	}
};

export const logOutAction = async (): Promise<void> => {
	try {
		await Parse.User.logOut();
		console.log('logged Out');
	} catch (error) {
		console.log(' ------ logout error: ', error);
		return Promise.reject(error);
	}
};

export const updateCurrentUserPasswordAction = async (values: ResetPasswordInput) => {
	try {

		const result = await Parse.Cloud.run('updateCurrentUserPassword', values) as Parse.User;

		return result.toJSON() as unknown as IUser;

	} catch (error) {
		console.log(' ------ updateCurrentUserPassword error: ', error);
		return Promise.reject(error);
	}
};

export type UpdateCurrentUserEmailActionValues = SendUpdateEmailFormInput & { email: string, languageCode?: string };

export const updateCurrentUserEmailAction = async (values: UpdateCurrentUserEmailActionValues) => {
	try {
		const data = {
			newEmail: values.newEmail,
			tokenChangeEmail: encodeURIComponent(new Date().getTime() + 30 * 60000),
			email: values.email,
			languageCode: values.languageCode,
		};

		await Parse.Cloud.run('updateCurrentUser', data);
		await Parse.Cloud.run('sendEmailVerification', data);
	} catch (error) {
		console.log(' ------ updateCurrentUserEmailAction error: ', error);
		return Promise.reject(error);
	}
};

