import _ from 'lodash';

import { IRole } from '../types/role.types';

import { SUPER_ROLE_NAME, roleLabels, roleSuffixName } from './constants';

type GetUserRolesOptions = {
	json?: boolean;
}

export function getUserRoles(user: Parse.User, options?: { json?: false }): Promise<Parse.Role[]>;
export function getUserRoles(user: Parse.User, options: { json: true }): Promise<IRole[]>;

export async function getUserRoles(user: Parse.User, { json }: GetUserRolesOptions = { json: false }) {
	const roleQuery = new Parse.Query(Parse.Role).equalTo('users', user);
	const roles: any = await roleQuery.find({ json });
	return roles;
}

export async function isSuperAdmin(user: Parse.User) {
	const roles = await getUserRoles(user);
	const roleNames: string[] = roles.map((role) => {
		return role.get('name');
	});

	const isSuperAdmin = roleNames.includes(SUPER_ROLE_NAME);
	return isSuperAdmin;
}

export async function getUserHighestRole(user: Parse.User, companyCode: string | null) {
	const roleQuery = new Parse.Query(Parse.Role).equalTo('users', user);
	const _roles = await roleQuery.findAll();

	const roles = companyCode ? _roles.filter((e) => {
		return e.get('name')?.startsWith(`${companyCode}_`);
	}) : _roles;

	const roleNames: object[] = roles.map((role) => {
		return { id: role.id, name: role.get('name'), label: role.get('label'), userId: user.id, userEmail: user.get('email') };
	});

	let result = _.find(roleNames, function (element: any) {
		return element.label === roleLabels.SUPER_ADMIN;
	});

	if (result) return result;
	result = _.find(roleNames, function (element: any) {
		return element.label === roleLabels.ADMIN;
	});

	if (result) return result;

	result = _.find(roleNames, function (element: any) {
		return element.label === roleLabels.EDITOR;
	});

	if (result) return result;

	result = _.find(roleNames, function (element: any) {
		return element.label === roleLabels.USER;
	});

	if (result) return result;

	result = _.find(roleNames, function (element: any) {
		return element.label === roleLabels.CONTRIBUTOR;
	});

	if (result) return result;

	return {};
}

export async function getUsersFromRoles(roleNames = ['admin']) {
	const userIds = new Set();
	await new Parse.Query(Parse.Role)
		.containedIn('name', roleNames)
		.each(async role => {
			await role.getUsers()
				.query()
				.each(userRelation => {
					userIds.add(userRelation.id);
				}, { useMasterKey: true });
		}, { useMasterKey: true });

	return await new Parse.Query(Parse.User)
		.containedIn('objectId', Array.from(userIds))
		.select('objectId')
		.find({ useMasterKey: true });
}

export function isContributorByAuthData(authData: any) {
	// return authData?.roles?.[0]?.name === `${authData?.session?.company?.code}_${roleSuffixName.CONTRIBUTOR}` ? true : false;
	const hasContributorRole = authData?.roles?.some((role: any) => {
		return role.name === `${authData?.session?.company?.code}_${roleSuffixName.CONTRIBUTOR}`;
	});

	const isMoreThanContributor = authData?.roles?.some((role: any) => {
		return role.name === `${authData?.session?.company?.code}_${roleSuffixName.USER}` || role.name === `${authData?.session?.company?.code}_${roleSuffixName.EDITOR}` || role.name === `${authData?.session?.company?.code}_${roleSuffixName.ADMIN}`;
	});

	if (isMoreThanContributor) { return false; }

	return hasContributorRole;
}
