import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { Carrot } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useTable } from 'react-table';

import { TM_COMPO_CUSTOM, TM_COMPO_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import PointListLabel from '../../../components/modelization/PointListLabel';
import { useGlossary } from '../../../hooks/useGlossary';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import Difference from '../../scenario/Difference';

const styles = {
	padding16: css({
		padding: '16px',
	}),

	skeletonTd: css({
		borderBottom: 'none!important',
		padding: '8px 0 0 0 !important',
	}),

	skeleton: css({
		height: '175px !important',
		width: '100% !important',
	} as any),

	title: css({
		// textDecorationLine: 'underline',
		padding: '16px',
		fontSize: '14px',
		fontWeight: 400,
		color: siteColors.grey900,
		background: siteColors.grey200,
	} as any),

	item: css({
		gap: '8px',
		padding: '16px',
		fontSize: '14px',
		fontWeight: '400',
		color: siteColors.grey900,
	} as any),

	value: css({
		padding: '16px',
		fontSize: '14px',
		fontWeight: 400,
		color: siteColors.grey800,
		textAlign: 'end',
		marginRight: '16px!important',
	} as any),
	largeColumn: css({
		// width: '80%',
	} as any),
	smallColumn: css({
		// width: '10%',
	} as any),
	title1: css({
		// textDecorationLine: 'underline',
		color: siteColors.grey900,
		background: siteColors.grey200,
		borderRadius: '16px 16px 0  0',
		textAlign: 'center',
		alignItems: 'center!important',
	}),

	fitContent: css({
		width: 'fit-content',
		// justifySelf: 'flex-end',
		// alignSelf: 'flex-end',
	}),
	w: css({
		flexWrap: 'nowrap !important',
		'& *': {
			whiteSpace: 'nowrap',
		},
	} as any),
	noBackground: css({
		background: siteColors.common.white,
	}),
};

const CustomTable = ({
	data,
	difference,
	icon,
	withPercent = true,
}: {
	data: any;
	difference?: number;
	icon?: React.ReactNode;
	withPercent?: boolean;
}) => {
	const theme = useTheme();
	// translation
	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();
	const params = useParams();
	const { indicator } = useApp();
	const columns = useMemo(() => {
		return [
			{
				accessor: 'label', // accessor is the "key" in the data
				Header: (props: any) => {
					return (
						<div className={cx(styles.title)}>
							<div className={cx('flewRow', styles.title1)}>
								<PointListLabel
									value={t('tm_custom')}
									color={TM_COMPO_INGREDIENT.get(TM_COMPO_CUSTOM)?.color}
									backgroundColor={TM_COMPO_INGREDIENT.get(TM_COMPO_CUSTOM)?.backgroundColor}
									borderColor={TM_COMPO_INGREDIENT.get(TM_COMPO_CUSTOM)?.borderColor}
									keyGlossary={TM_COMPO_INGREDIENT.get(TM_COMPO_CUSTOM)?.keyGlossary}
								/>
							</div>
						</div>
					);
				},
				Cell: (props: any) => {
					return (
						<div className={cx('flexRow nowrap', styles.item)}>
							{icon ? icon : <Carrot color={theme.palette.grey[700]} />}
							<div css={{ whiteSpace: 'nowrap', maxWidth: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{props.value}
							</div>
						</div>
					);
				},
			},
			// {
			// 	accessor: 'weight', // accessor is the "key" in the data
			// 	Header: (props: any) => {
			// 		return (
			// 			<div></div>
			// 		);
			// 	},
			// 	Cell: (props: any) => {

			// 		return (
			// 			<div className={cx(styles.value)}>
			// 				{formatNumber(props.value, undefined, 2)}
			// 			</div>
			// 		);
			// 	},
			// },

			{
				accessor: 'composition', // accessor is the "key" in the data
				Header: (props: any) => {
					return (
						<div className={cx('flexRow  nowrap', styles.value)}>
							{difference === 0 ? (
								''
							) : (
								<>
									{difference && (
										<Difference
											value={difference}
											decimal={2}
											diffMainClass={cx(styles.fitContent, styles.w)}
											showZero={true}
											// withPercent={withPercent}
											withPercent={true}
										// className={cx(styles.noBackground)}
										/>
									)}
								</>
							)}
						</div>
					);
				},
				Cell: (props: any) => {
					return (
						<div className={cx('flexRow nowrap', styles.value)}>
							<div>{formatNumber(props.value, '%', 2)}</div>
							<div>%</div>
						</div>
					);
				},
			},
		];
	}, [theme.palette.grey, t, indicator]);

	const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
		columns,
		data: data ?? [],
	});

	return (
		<>
			<div className="flex1">
				<table
					{...getTableProps()}
					css={{
						borderCollapse: 'separate',
						borderSpacing: '0px',
						//	border: `1px solid ${theme.palette.grey[500]}`,
						borderRadius: '16px',
						overflow: 'hidden',
						'th, td': {
							border: `1px solid ${theme.palette.grey[500]}`,
							borderTop: 'unset',
							borderRight: 'unset',
							padding: 0,
						},
						'thead th:first-of-type, tr td:first-of-type': {
							borderLeft: 'unset',
							borderRight: 'unset',
						},
						'tr:last-of-type td': {
							borderBottom: 'unset',
							borderLeft: 'unset',
						},
						'tr td,tr th': {
							borderLeft: 'unset',
							borderRight: 'unset',
						},
						'tr td:nth-of-type(2) div,tr th:nth-of-type(2) div': {
							justifyContent: 'flex-end!important',
							paddingRight: '0!important',
						},
						'tr th:nth-of-type(1) div': {
							justifyContent: 'flex-start!important',
							textAlign: 'left',
						},
						// ==========
						width: '100%',
						'tr th:nth-of-type(1)': {
							// width: '80%',
						},
						'tr th:nth-of-type(2), tr th:nth-of-type(3)': {
							// width: '10%',
						},
						'tr th': {
							background: cn(siteColors.grey200, '!important'),
						},
					}}
				>
					<thead>
						{headerGroups.map((headerGroup: any) => {
							return (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column: any) => {
										return <th {...column.getHeaderProps()}>{column.render('Header')}</th>;
									})}
								</tr>
							);
						})}
					</thead>

					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default CustomTable;
