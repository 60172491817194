import { Clock, GraduationCap, MapPin, Users } from 'lucide-react';

import { MESSAGE_KEY_APBO } from '@carbonmaps/shared/utils/constants';

import ApboCard from '../../components/apbo/card/ApboCard';
import BigIndicatorCard from '../../components/apbo/card/BigIndicatorCard';
import Comment from '../../components/apbo/Comment';
import Card from '../../components/widget/Card';
import { siteColors } from '../../lib/colors';

const Formation = () => {
	return (
		<ApboCard messageKey={MESSAGE_KEY_APBO.breederTraining}>
			<Card
				title="Formation des éleveurs"
				icon={<GraduationCap size={20} color={siteColors.grey700} />}
				center={false}
				sx={{
					padding: '0px !important',
					width: '100%',
					gap: 0,
				}}
				rightContent={<Comment messageKey={MESSAGE_KEY_APBO.breederTraining} />}
				withBorder={false}
			>
				<div className="flexRow alignCenter width100 gap16">
					<BigIndicatorCard
						label="Élevages concernés"
						value={43}
						icon={<MapPin size={30} color={siteColors.primary} />}
						className="flex1"
					/>
					<BigIndicatorCard
						label="Groupes formés"
						value={6}
						icon={<Users size={30} color={siteColors.primary} />}
						className="flex1"
					/>
					<BigIndicatorCard
						label="Heures de formation"
						value={1920}
						icon={<Clock size={30} color={siteColors.primary} />}
						className="flex1"
					/>
				</div>
			</Card>
		</ApboCard>
	);
};

export default Formation;
