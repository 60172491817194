import { css, cx } from '@emotion/css';
import { Lock as LockIcon } from 'lucide-react';
import { siteColors } from '../../lib/colors';

const styles = {
	container: css({
		alignItems: 'center',
		fontSize: '12px',
		fontFamily: 'Inter',
	}),
};

const Lock = () => {
	return (
		<div className={cx('flexRow alignCenter', styles.container)}>
			<LockIcon color={siteColors.primary} width={15} />
		</div>
	);
};

export default Lock;
