import { css } from '@emotion/css';
import { siteColors } from '../../lib/colors';

const styles = {
	popoverContent: css({
		padding: 24,
		minWidth: 150,

		'& p': {
			color: siteColors.text,
			fontSize: 14,
			fontFamily: 'Inter',
			lineHeight: '25px',
			margin: 0,
		},
	}),
} as any;

type ProductTypePopoverProps = {
	title?: string;
	content?: React.ReactNode;
};

const ProductTypePopover = ({ title, content }: ProductTypePopoverProps) => {
	return (
		<div className={styles.popoverContent}>
			<p className="fontWeight700">{title}</p>
			<p>{content}</p>
		</div>
	);
};

export default ProductTypePopover;
