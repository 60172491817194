import { ReactNode } from 'react';

import Box, { type BoxProps } from '@mui/material/Box';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import { pxToRem } from '@carbonmaps/ui/utils/styles';

type Props = TypographyProps & {
	icon?: ReactNode;
	className?: string;
};

const BlockTitle = ({ children, icon, className, ...props }: Props) => {
	const theme = useTheme();

	return (
		/* icon ? */ <Box display="flex" alignItems="center" mb={pxToRem(16)} className={className}>
			{icon}
			{/* {renderText} */}
			<Typography
				variant="h3"
				color={theme.palette.common.black}
				ml={icon ? '8px' : 0}
				/* mb={pxToRem(16)} */ {...props}
			>
				{children}
			</Typography>
		</Box>
	);
};

export default BlockTitle;
