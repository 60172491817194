import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../../hooks/useImpactYear';

import { findIngredientFamilyAction, findIngredientFamilyTradeoffAction } from './ingredient.actions';

export const useFindIngredientFamily = ({ params }: { params: any }) => {
	const { selectedYear } = useYearSelection();
	const key = ['findIngredientFamily', { selectedYear, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findIngredientFamilyAction,
	});

	return {
		key,
		result,
	};
};

export const useFindIngredientFamilyTradeoff = ({ params }: { params: any }) => {
	const { selectedYear } = useYearSelection();
	const key = ['findIngredientFamily', { selectedYear, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findIngredientFamilyTradeoffAction,
	});

	return {
		key,
		result,
	};
};
