import { COLOR_SCORING } from '@carbonmaps/shared/utils/scoring';
import { css, cx } from '@emotion/css';

type SupplierScoreProps = {
	score: string;
	// color: string;
	width?: number;
	height?: number;
	radius?: number;
	fontSize?: number;
};

const styles = {
	container: css({
		// width: 25,
		// height: 16,
		// borderRadius: 100,
		// background: 'var(--backgroundColor)',
		// fontSize: '11.25 px !important',
		// color: '#FFF !important',
		// padding: '6px 7.5px 6px 6px',
		// fontWeight: 600,

		display: 'flex',
		padding: '6px 7.5px 6px 6px',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '6px',
		// borderRadius: '18px',
		border: '0.75px solid rgba(0, 0, 0, 0.10)',
		background: 'var(--backgroundColor)',
		color: '#FFF',
		// fontSize: '11.25px',
		fontWeight: 700,
		textAlign: 'center',
	}),
};

const SupplierScore = ({ score, width = 36, height = 24, radius = 18, fontSize = 11.25 }: SupplierScoreProps) => {
	const color = (COLOR_SCORING as any)[score];
	return (
		<div
			className={cx(styles.container)}
			style={{ ['--backgroundColor' as any]: color, width, height, borderRadius: radius, fontSize }}
		>
			{score}
		</div>
	);
};

export default SupplierScore;
