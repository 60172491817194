import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { HelpCircle } from 'lucide-react';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BPopover from '../BPopover';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
	noValue?: string;
};

const styles = {
	popoverContainer: css({
		padding: '24px',
		gap: 12,
	}),
	emptyContent: css({
		padding: '8px',
		fontSize: '16px',
		fontStyle: 'italic',
	}),
	content: css({
		padding: '8px',
		fontSize: '16px',
	}),
};

const SoldItemsField = ({
	value,
	row,
	column,
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const theme = useTheme();

	if (!value || value <= 0) {
		return (
			<div>
				<BPopover
					anchorOriginHorizontal={'right'}
					transformOriginVertical={'top'}
					transformOriginHorizontal={'right'}
					anchorOriginVertical={'bottom'}
					modeHover
					content={(onClose) => {
						return (
							<div className={cx('flexColumn', styles.popoverContainer)}>
								<Typography className="fontWeight700">
									{value && value < 0 ? 'Volume incorrect' : 'Volume non renseigné'}
								</Typography>
							</div>
						);
					}}
					addStyles={{
						minWidth: '30px',
					}}
				>
					<HelpCircle color={theme.palette.grey[900]} />
				</BPopover>
			</div>
		);
	}

	return (
		<>
			{column.noValueMessage && value === '-' ? (
				<div className={cx('grey700', styles.emptyContent)}>{column.noValueMessage}</div>
			) : (
				<div className={cx('grey900', styles.content, css(column?.props?.style))}>
					{typeof value === 'number' ? formatNumber(value, '', 0) : value}
				</div>
			)}
		</>
	);
};

export default SoldItemsField;
