import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { G } from '@react-pdf/renderer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, Eye, FilePen, FilePlus, MoreHorizontal } from 'lucide-react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { MOD_ECO_CONCEPTION } from '@carbonmaps/shared/utils/module';
import { SimulationFormInput } from '@carbonmaps/shared/validations/simulation.validations';
import { getEcoDesignProducts } from '@carbonmaps/ui/actions/dashboard.actions';
import { createSimulationAction } from '@carbonmaps/ui/actions/simulation.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import LightTooltipGlossary from '../../../../components/LightTooltipGlossary';
import Typography from '../../../../components/Typography';
import CardContent from '../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../components/dashboard/v2/CardTitle';
import DashboardButton from '../../../../components/dashboard/v2/DashboardButton';
import DashboardCard from '../../../../components/dashboard/v2/DashboardCard';
import DashboardTable from '../../../../components/dashboard/v2/DashboardTable';
import DashboardTooltip from '../../../../components/dashboard/v2/DashboardTooltip';
import Icon from '../../../../components/dashboard/v2/Icon';
import MoreButton from '../../../../components/dashboard/v2/MoreButton';
import TagLabel from '../../../../components/dashboard/v2/TagLabel';
import VsGammeField from '../../../../components/dashboard/v2/VsGammeField';
import CreateScenarioModal from '../../../../components/simulation/CreateScenarioModal';
import { useGlossary } from '../../../../hooks/useGlossary';
import { useImpactYear, useYearSelection } from '../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';

const styles = {
	content: css({
		minHeight: 396,
	}),
};

const EcoDesignProduct = () => {
	const [searchQueryParams] = useSearchQueryParams();

	const [isModuleActive, setIsModuleActive] = useState<any>(true);

	const [openModal, setOpenModal] = useState(false);
	const [selectedOption, setSelectedOption] = useState({}) as any;
	const [message, setMessage] = useState('');
	const [options, setOptions] = useState([]);
	const [search, setSearch] = useState<any>();
	const { selectedYear } = useYearSelection();

	const { indicator } = useApp();
	const { t } = useTranslation();
	const navigate = useNavigate();
	// ---- fetch products eco design ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getEcoDesignProducts',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				viewMode: indicator,
				period: selectedYear,
			},
		],
		queryFn: getEcoDesignProducts,
	});

	const {
		mutate: createSimulation,
		isError,
		isSuccess,
		isLoading: isLoadingCreateSimulation,
	} = useMutation({
		mutationKey: ['createSimulation'],
		mutationFn: createSimulationAction,
		onSuccess: async (value: any) => {
			// setMessage('Informations mises à jour');
			// queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			setOpenModal(false);
			navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<SimulationFormInput> = async (values) => {
		createSimulation({
			...values,
			productId: selectedOption?._id,
			selectedSimulationId: null,
		} as any);
	};

	const columns = useMemo(() => {
		return [
			{
				header: t('Les plus émissifs au kilo'),
				component: TagLabel,
			},
			{
				header: (
					<DashboardTooltip
						label={t('vs-reference', {
							product: toLowerCase(t('product')),
						})}
						content={'vs-gamme'}
					/>
				),
				component: VsGammeField,
			},
			{
				header: '',
				component: ({ item }: any) => {
					const tooltipOptions = [
						{
							title: t('Voir la fiche'),
							value: '',
							icon: Eye,
							customComponent: true,
							onClick: () => {
								console.log('item._id', item);
								navigate(`${FRONT_PATH_NAMES.products}/${item.originalId}/synthesis?uid=${item.uid}`);
							},
						},
						...(isModuleActive
							? [
									{
										title: t('Nouveau scénario d’éco-conception'),
										value: '',
										icon: FilePlus,
										customComponent: true,
										onClick: () => {
											setOpenModal(true);
											setSelectedOption({ ...item, objectId: item._id });
										},
									},
							  ]
							: []),
					];
					return <MoreButton options={tooltipOptions} />;
				},
			},
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, isModuleActive]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	useEffect(() => {
		setIsModuleActive((authData?.session.company?.modules || []).includes(MOD_ECO_CONCEPTION));
	}, [authData?.session.company]);
	return (
		<DashboardCard
			title={
				<CardTitle
					title={t('dashboard-eco-design-card-title', {
						products: t('products'),
						products_lowercase: toLowerCase(t('products')),
					})}
					subtitle={`${data?.metaData[0]?.count || ' '} ${t('products')}`}
					icon={<Icon element={Box} color={siteColors.primary} background={siteColors.carbon100} />}
				/>
			}
			className="flex1"
		>
			<CardContent
				className={cx('flex1', styles.content)}
				button={
					<DashboardButton
						label={t('dashboard-eco-design-card-button', {
							products: toLowerCase(t('products')),
						})}
						path={FRONT_PATH_NAMES.products}
						orderBy="VSReference"
						direction="desc"
						withFilter
						addEcoDesignFilter
						eventName="Dashboard See impact products"
					/>
				}
				loading={isLoading}
			>
				<DashboardTable columns={columns} data={data?.data || []} emptyText={t('simulation-table-empty-data')} />
				<CreateScenarioModal
					title={t('create-scenario-form-title')}
					open={openModal}
					onClose={() => {
						return setOpenModal(false);
					}}
					isLoading={isLoadingCreateSimulation}
					onSubmit={onSubmit}
					options={options}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					message={message}
					isError={isError}
					setSearch={setSearch}
					isSuccess={isSuccess}
				/>
			</CardContent>
		</DashboardCard>
	);
};

export default EcoDesignProduct;
