import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Box as BoxIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getConfigFacets } from '@carbonmaps/shared/utils/utils';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetProductSheetTimePeriod, useProductInfo } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import Card from '../../../components/widget/Card';
import CategoryItem from '../../../components/widget/CategoryItem';
import LineDisplay from '../../../components/widget/LineDisplay';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';

const ProductCategoriesBlock = () => {
	// const theme = useTheme();

	const params = useParams();

	const { t } = useTranslation();
	const { indicator } = useApp();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const [facetsConfigItems, setFacetsConfigItems] = useState<any>([]);

	const { data } = useProductInfo({ objectId: params.productId!, viewMode: indicator });

	useEffect(() => {
		const items = getConfigFacets(authData?.session.company, false, 'Product');
		setFacetsConfigItems(items);
	}, [authData?.session.company]);

	return (
		<Card
			title={t('product_data', {
				product: t('product'),
				of_product: t('of_product'),
				product_lowercase: toLowerCase(t('product')),
			})}
			icon={<BoxIcon color={siteColors.grey700} size={20} />}
			center={false}
			marginBottom="32px"
			sx={{ width: '100%' }}
		>
			<Box className="flexColumn" sx={{ width: '100%' }}>
				{facetsConfigItems?.length ? (
					facetsConfigItems.map((category: any, index: number, arr: any) => {
						if (!data) return null;
						const leclercCategory = (data as any)[`${authData?.session.company?.code}Categories`];
						const iCategory = leclercCategory?.[category.field];

						return category ? (
							<LineDisplay
								path={
									iCategory && !Array.isArray(iCategory)
										? `${FRONT_PATH_NAMES.analyse}/${category.field}/${encodeURIComponent(iCategory)}`
										: null
								}
								content={
									<CategoryItem
										title={category.label}
										content={iCategory}
										basePath={`${FRONT_PATH_NAMES.analyse}/${category.field}/`}
									/>
								}
							/>
						) : null;
					})
				) : (
					<Typography>{t('Aucune')}</Typography>
				)}
			</Box>
		</Card>
	);
};

export default ProductCategoriesBlock;
