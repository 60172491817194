export const TABLET_BREAKPOINT = 960;
export const XL_BREAKPOINT = 1560;

export const MEDIUM_DESKTOP_BREAKPOINT = 1480;
export const SMALL_DESKTOP_BREAKPOINT = 1380;

export const DESKTOP_BREAKPOINT = 1280;

export const mobileTablet = `@media (max-width: ${TABLET_BREAKPOINT}px)`;
export const tablet = `@media (max-width: ${DESKTOP_BREAKPOINT}px)`;

export const SMALL_DESKTOP = `@media (min-width: ${DESKTOP_BREAKPOINT + 1}px) and  (max-width: ${XL_BREAKPOINT}px)`;

export const XL_UP = `@media (min-width: ${XL_BREAKPOINT}px)`;
export const XL_DESKTOP_DOWN = `@media (max-width: ${XL_BREAKPOINT}px)`;

export const MEDIUM_DESKTOP_DOWN = `@media (max-width: ${MEDIUM_DESKTOP_BREAKPOINT}px)`;

export const SMALL_DESKTOP_DOWN = `@media (max-width: ${SMALL_DESKTOP_BREAKPOINT}px)`;
