import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { Container, Typography } from '@mui/material';
import { template } from 'lodash';
import { Link } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { DESKTOP_BREAKPOINT } from '@carbonmaps/ui/utils/Responsive';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import Skeleton from '../../components/Skeleton';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetImgProxy } from '../../lib/react-query/features/img/img.hooks';

import { siteColors } from '../../lib/colors';
import { useFindSectorSheetGroups } from './hooks';

const styles = {
	container: css({
		backgroundPositionX: 'center',
		backgroundImage: 'url(/dashboard-bg.svg)',
		minHeight: '100vh',
		backgroundRepeat: 'no-repeat',
		margin: '0 auto',
		paddingTop: 52,
		paddingBottom: 62,
		//backgroundSize: '90%',
		// background: 'red',
		// [`@media (min-width: ${DESKTOP_BREAKPOINT}px)`]: {
		// 	width: 'calc(100% - 500px)',
		// 	// paddingTop: 0,
		// 	// paddingBottom: 0,
		// },
	}),

	grandTitle: css({
		color: '#000',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '32px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
	}),

	title2: css({
		color: '#000',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
	}),

	// sheetImageContainer: css({
	// 	// display: 'flex',
	// 	// width: '130px',
	// 	// height: '130px',
	// 	// padding: '0px 45px',
	// 	// justifyContent: 'center',
	// 	// alignItems: 'center',
	// 	// background: 'red',
	// }),
	sheetTitle: css({
		color: '#000',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: 'normal',
	}),
};

const SectorSheetList = () => {
	const {
		result: { data: sheetData, isLoading },
	} = useFindSectorSheetGroups();
	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([{ text: 'Fiches filières' }]);
	}, [setBreadcrumbs]);

	const { t } = useTranslation();

	return (
		<div className={cx('flexColumn', styles.container)}>
			<Container
				sx={{
					maxWidth: '720px!important',
				}}
			>
				<div css={{ marginBottom: 56 }}>
					<h1 className={cx('', styles.grandTitle)}>{t('Fiches filières')}</h1>
					<p>
						{/* L'accès à ces documents est couvert par votre licence Plateforme. Aucune partie de ces documents ne peut
						être reproduite, stockée ou transmise sous quelque forme que ce soit sans l'autorisation préalable écrite de
						Carbon Maps */}
						{t('sectorSheetListDisclaimer')}
					</p>
					<p>
						{/* ©️ Carbon Maps 2024. Tous droits réservés */}
						{t('copyRight', { year: new Date().getFullYear() })}
					</p>
				</div>

				<div>
					{/* {isLoading ? (
						Array.from({ length: 5 }).map((_, index) => <SheetItem key={index} isLoading />)
					) : null} */}
					{isLoading ? (
						<SheetGroup isLoading group={undefined} />
					) : (
						sheetData?.map((group: any, index: any) => {
							return <SheetGroup key={index} group={group} />;
						})
					)}
				</div>
			</Container>
		</div>
	);
};

export default SectorSheetList;

const SheetGroup = ({ group, isLoading }: { group: any; isLoading?: boolean }) => {
	const { i18n } = useTranslation();
	const lng = getIsoCountryCode(i18n.language);

	// if (isLoading) {
	// 	return Array.from({ length: 5 }).map((_, index) => {
	// 		return <SheetItem key={index} isLoading sheet={undefined} />;
	// 	});
	// }

	return (
		<div
			css={{
				marginBottom: 56,
			}}
		>
			{isLoading ? (
				<Skeleton variant="text" height="30px" width="200px" css={{ borderRadius: '8px', marginBottom: 16 }} />
			) : (
				<h3 className={cx('', styles.title2)} css={{ margin: 0, marginBottom: 16 }}>
					{group.translation[lng]?.name || '-----------------' /*  || group.translation['fr']?.name */}
				</h3>
			)}
			<div
				css={{
					display: 'grid',
					gap: '16px',
					gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
				}}
			>
				{isLoading
					? Array.from({ length: 7 }).map((_, index) => {
							return <SheetItem key={index} isLoading sheet={undefined} />;
					  })
					: group.sheets.map((sheet: any, index: any) => {
							return <SheetItem key={index} sheet={sheet} />;
					  })}
			</div>
		</div>
	);
};

const SheetItem = ({ sheet, isLoading = false }: { sheet: any; isLoading?: boolean }) => {
	const { i18n } = useTranslation();
	const lng = getIsoCountryCode(i18n.language);

	if (isLoading) {
		return <Skeleton width={'130px'} height={'130px'} />;
	}

	return (
		<Link to={sheet.objectId} css={{ textDecoration: 'none' }}>
			<div>
				<SheetImage imageUrl={sheet.imageUrl} />
				<p className={cx('', styles.sheetTitle)} css={{ margin: 0 }}>
					{sheet.translation[lng]?.title || '--------------'}
				</p>
			</div>
		</Link>
	);
};

export const SheetImage = ({
	imageUrl,
	marginBottom = 16,
	isLoading = false,
}: {
	imageUrl?: string;
	marginBottom?: number;
	isLoading?: boolean;
}) => {
	const {
		key,
		result: { data: dataImageUrl },
	} = useGetImgProxy({ params: { filename: imageUrl || '' } });

	const innerElement = (
		<div
			className={cx('flexCenter')}
			css={{
				width: 130,
				height: 130,
				borderRadius: 'var(--16, 16px)',
				border: '1px solid rgba(0, 0, 0, 0.10)',
				marginBottom,
			}}
			style={{
				...(imageUrl && dataImageUrl
					? {
							backgroundImage: `url(${dataImageUrl})`,
							backgroundPosition: 'center',
							backgroundSize: 'cover',
					  }
					: { backgroundColor: siteColors.grey300 }),
			}}
		>
			{/* {imageUrl ? <img src={imageUrl} alt={sheet.translation.fr.title} /> : null} */}
		</div>
	);

	return isLoading ? <Skeleton>{innerElement}</Skeleton> : innerElement;
};
