import { css, cx } from '@emotion/css';
import { Plus as PlusIcon } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import Skeleton from '../../../components/Skeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

type CreateScenarioProps = {
	isLoading: boolean;
	setOpenModal: any;
	setSelectedOption: any;
	setIsNew: any;
	setSearch: any;
	iconLeft?: React.ReactNode;
	disabled?: boolean;
};

const styles = {
	disabled: css({
		opacity: 0.7,
		pointerEvents: 'auto!important',
		cursor: 'not-allowed!important',
	} as any),
};

const CreateScenario = ({
	iconLeft,
	setSearch,
	isLoading,
	setOpenModal,
	setSelectedOption,
	setIsNew,
	disabled = false,
}: CreateScenarioProps) => {
	const { t } = useTranslation();
	const elem = (
		<>
			<BButton
				isDisabled={disabled}
				variant="secondary"
				label={t('create-scenario-button')}
				onClick={() => {
					setSearch({ input: '', page: 1 });
					setIsNew(true);
					setSelectedOption(null);
					return setOpenModal(true);
				}}
				iconLeft={iconLeft ?? <PlusIcon color={siteColors.grey700} />}
				className={cx('width100', disabled ? styles.disabled : '')}
			/>
		</>
	);

	return isLoading ? <Skeleton>{elem}</Skeleton> : elem;
};

export default CreateScenario;
