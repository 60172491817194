import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getGlobalAuditPercent } from '@carbonmaps/ui/actions/audit.actions';
import { displayAuditValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Typography from '../../../../components/Typography';
import CircularProgress from '../../../../components/card/CircularProgress';
import CardContent from '../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../components/dashboard/v2/CardTitle';
import DashboardButton from '../../../../components/dashboard/v2/DashboardButton';
import DashboardCard from '../../../../components/dashboard/v2/DashboardCard';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../hooks/useTranslation';
import { getGlobalConfigValue } from '../../../../utils/audit.utils';

const styles = {
	content: css({ minHeight: 224, gap: '34px !important' }),
	unit: css({
		fontSize: '14px !important',
	}),
};

const AuditInfoCard = () => {
	const { selectedYear } = useYearSelection();

	// ---- translation ---- //
	const { t } = useTranslation();
	// ---- fetch global percent value ---- //
	const { data: percent, isLoading } = useQuery({
		queryKey: ['getGlobalAuditPercent', { selectedYear }],
		queryFn: getGlobalAuditPercent,
	});

	const globalConfig = getGlobalConfigValue((percent as any) || 0);
	return (
		<DashboardCard title={<CardTitle title={t('dashboard-audit-card-title')} />} className="flex1">
			<CardContent
				className={cx('flexCenter justifyCenter gap34', styles.content)}
				button={
					<DashboardButton
						label={t('dashboard-audit-card-button')}
						path={`${FRONT_PATH_NAMES.audit}`}
						eventName="Dashboard Access your data audit"
					/>
				}
				loading={isLoading}
			>
				<div className="flexRow alignCenter gap24">
					<CircularProgress
						width={1}
						innerRadius={41}
						containerWidth="81px"
						fillColor={globalConfig?.color}
						percent={percent as any}
					/>

					<div className="flexColumn ">
						<Typography variant="subtitle2">
							{displayAuditValue(percent as any)}
							{/* {formatNumber(percent, undefined, 0)} */}
							<span className={cx(styles.unit)}> %</span>
						</Typography>
						<Typography>{t(globalConfig?.text)}</Typography>
					</div>
				</div>
			</CardContent>
		</DashboardCard>
	);
};

export default AuditInfoCard;
