import { z } from 'zod';

export const userAccountFormValidationSchema = z.object({
	firstName: z.string({ required_error: 'Prénom obligatoire' }).min(1, 'Prénom obligatoire'),
	lastName: z.string({ required_error: 'Nom obligatoire' }).min(1, 'Nom obligatoire'),
	position: z.string({ required_error: 'Poste obligatoire' }).min(1, 'Poste obligatoire'),
});

export type UserAccountFormInput = z.infer<typeof userAccountFormValidationSchema>


export const userAccountFormSuperAdminValidationSchema = z.object({
	firstName: z.string({ required_error: 'Prénom obligatoire' }).min(3, 'Prénom obligatoire'),
	lastName: z.string({ required_error: 'Nom obligatoire' }).min(3, 'Nom obligatoire'),
	position: z.string({ required_error: 'Poste obligatoire' }),
	roleCode: z.string({ required_error: 'Role obligatoire' }),
	// password: z.string({ required_error: 'Mot de passe obligatoire' }).min(8, 'Minimum 8 caractères')
	// 	.regex(
	// 		/^(?=.*[@#$%^&+=!]).*$/,
	// 		'Le mot de passe doit contenir au moins un caractère special (@#$%^&+=!)',
	// 	),
	email: z.string({ required_error: 'email obligatoire' }).email('Mettre une adresse email valide').min(6, 'email obligatoire'),
	companyId: z.string({ required_error: 'Company obligatoire' }).min(4, 'Company obligatoire'),
});

export type UserAccountFormSuperAdminInput = z.infer<typeof userAccountFormSuperAdminValidationSchema>






export const userSupplierValidationSchema = z.object({
	suppliersUid: z.array(z.string().optional()),
	userId: z.string({ required_error: 'user obligatoire' }).min(4, 'user obligatoire'),
	companyId: z.string({ required_error: 'user obligatoire' }).min(4, 'user obligatoire'),
});

export type UserSupplierValidationSchemaInput = z.infer<typeof userSupplierValidationSchema>
