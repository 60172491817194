import _ from 'lodash';
import { siteColors } from "../lib/colors";

const COLORS = /* _.shuffle( */[
	siteColors.primary,
	'#FFB900',
	siteColors.green500,
	'#744DA9',
	'#038387',
	'#A6D3FC',
	'#6510FF',
	'#8E8CD8',
	'#A878FF',
	'#AA23DE',

	'#00CC6A',
	'#AB80F8',
	'#E81123',
	'#DE7621',
	'#EA005E',
	'#DFCCFF',
	'#00B294',
	'#E3008C',
	'#803AFF',
]/* ) */;

export const randomColor = (index: number/* , shuffle = false */) => {
	// const randomIndex = Math.floor(Math.random() * 11);
	const tabColors = COLORS;

	// const tabColors = shuffle ? _.shuffle(_tabColors) : _tabColors;

	// return tabColors[index % 10] ? tabColors[index % 10] : tabColors[randomIndex];
	return tabColors[index % COLORS.length /* % index */];
};
