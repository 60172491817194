import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';

import LightTooltip from './LightTooltip';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	text: css({
		maxWidth: 100,
		textAlign: 'right',
		fontWeight: 400,
		fontSize: 16,
		fontFamily: 'Fira Mono',
	}),
};

const Reference = ({
	value,
	row: { index },
	column,
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	return (
		<div className="flexRow alignCenter justifyCenter">
			<LightTooltip title={value}>
				<Typography
					maxWidth="100px"
					noWrap
					width="fit-content"
					textAlign="right"
					className={cx('grey700', styles.text, css(column?.props?.style))}
				>
					{value}
				</Typography>
			</LightTooltip>
		</div>
	);
};

export default Reference;
