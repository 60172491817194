import { classNames, functionName } from "@carbonmaps/shared/utils/constants";
import { useQuery } from "@tanstack/react-query";
import { useYearSelection } from "../../hooks/useImpactYear";

export const useFindPackagingDetailRecycled = ({ packagingId }: { packagingId: string; }) => {
	const { selectedYear } = useYearSelection();

	return useQuery({
		queryKey: ['FindPackagingDetailRecycled', { packagingId, selectedYear }] as const,
		queryFn: async (ctx) => {
			try {
				const { packagingId, selectedYear } = ctx.queryKey[1];

				const result = await Parse.Cloud.run(functionName.temporality.finPackagingDetailRecycled, { packagingId, selectedYear });

				return result;
			} catch (error) {
				console.log('--- FindPackagingDetailRecycled Error -----------------', error);
				return Promise.reject(error)
			}
		},
	});
}

export const useFindPackagingDetailEndOfLife = ({ packagingId }: { packagingId: string; }) => {
	const { selectedYear } = useYearSelection();

	return useQuery({
		queryKey: ['FindPackagingDetailEndOfLife', { packagingId, selectedYear }] as const,
		queryFn: async (ctx) => {
			try {
				const { packagingId, selectedYear } = ctx.queryKey[1];

				const result = await Parse.Cloud.run(functionName.temporality.findPackagingDetailEndOfLife, { packagingId, selectedYear });

				return result;
			} catch (error) {
				console.log('--- FindPackagingDetailEndOfLife Error -----------------', error);
				return Promise.reject(error)
			}
		},
	});
}
