import { useState } from 'react';

import { css, cx } from '@emotion/css';

import { usePeriodOptions } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';

import { cn, siteColors } from '../../../lib/colors';
import TemporalityModal from './modal/TemporalityModal';

const styles = {
	container: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
		fontSize: '12px',
		color: cn(siteColors.text, '!important'),
		width: '90px',
		maxWidth: '100%',
		cursor: 'pointer',
	}),
};

type EstimatedAddButtonProps = {
	period: any;
};

const EstimatedAddButton = ({ period }: EstimatedAddButtonProps) => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const { addEstimatedImpactOption } = usePeriodOptions();

	/**
	 * Add a new year to the estimated impact
	 */
	const onAddYear = () => {
		const option = {
			value: period,
			label: `${period}`,
			data: {
				value: 0,
				estimatedValue: 0,
			},
			isNew: true,
		};
		addEstimatedImpactOption(option);
		setOpen(true);
	};

	return (
		<>
			<div className={cx(' textAlignCenter', styles.container)} onClick={onAddYear}>
				{t('Ajoutez un objectif')}
			</div>
			<TemporalityModal
				open={open}
				onClose={() => {
					return setOpen(false);
				}}
				currentSelected={parseInt(period)}
			/>
		</>
	);
};

export default EstimatedAddButton;
