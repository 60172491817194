export function useJuneTrack() {
	if (window.LOCAL) {
		return {
			load: (...args: any[]) => {
				console.log('load', args);
			},
			track: (...args: any[]) => {
				console.log('track', args);
			},
			identify: (...args: any[]) => {
				console.log('identify', args);
			},
		};
	}

	return window.analytics;
}
