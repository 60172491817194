import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { HelpCircle } from 'lucide-react';
import { createSearchParams } from 'react-router-dom';

import { ReactComponent as CarrotsIcon } from '@carbonmaps/media/icons/carrots2.svg';
import { FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { GetParams } from '@carbonmaps/ui/actions/ingredient.actions';
import { TableComponent, getStickyHeaderStyle } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TableHeaderCell from '../../components/table/TableHeaderCell';
import ImpactCarbonePopover from '../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../components/table/popover/WaterImpactPopover';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useSkeleton } from '../../hooks/useSkeleton';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useFindIngredientFamily } from '../../lib/react-query/features/ingredient/ingredient.hooks';
import { CARBONE_INDICATOR } from '../../utils/constants';
import { supplierCheckedCount } from '../../utils/supplier';

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},
		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},

		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div ,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) p':
			{
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
	};
};

const formatDataTable = (data: any, { paramsUrlString }: { paramsUrlString?: string }) => {
	if (!data?.length) return [];

	return data.map((item: any) => {
		const groupCount = supplierCheckedCount(item?.suppliers);

		return {
			...item,
			numberSupplierFullfilled: groupCount.yes,
			numberSupplierNotFullfilled: groupCount.no,
			link: `${FRONT_PATH_NAMES.ingredients}/${item.linkId}/synthesis${
				paramsUrlString ? `${paramsUrlString}&codeIngCmaps=${item?.codeCmaps}` : `?codeIngCmaps=${item?.codeCmaps}`
			}`,
		};
	});
};

type IngredientTableProps = {
	filterOptions: any;
};

const IngredientTable = ({ filterOptions }: IngredientTableProps) => {
	// query params
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	// translation
	const { t } = useTranslation();

	const { selectedYear } = useYearSelection();

	// theme
	const theme = useTheme();
	// switch value ( carbon or water )
	const { indicator } = useApp();
	// custom style tab
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	//--------------------------------------------------------------------------------------//
	//                                        State                                         //
	//--------------------------------------------------------------------------------------//

	// ---- table params ---- //

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<GetParams>({
		input: '',
		page: 1,
		size: 100,
		facetFilters: [],
		supplierIds: [], // supplier filters
		direction: 0,
		sortingBy: null,
		...(filterOptions?.orderBy && {
			sortingBy: {
				id: filterOptions?.orderBy,
				desc: filterOptions?.direction === 'desc',
			},
		}),
		...filterOptions,
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);
	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- table columns
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							valueType="string"
							label={t('reference_ingredient', { ingredient: t('ingredient') })}
						/>
					);
				},
				accessor: 'label',
				component: 'TagLabel',
				editable: true,
				sortDescFirst: true,
				type: 'string',
				icon: <CarrotsIcon width={20} height={20} />,
				props: {
					gap: 8,
					style: {
						color: theme.palette.common.black,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
						textTransform: 'uppercase !important',
					},

					onClick: (row: any) => {
						trackEvent('Explore Click Ingredient', {
							IngredientLabel: row?.label,
						});
					},
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('related_ingredients', { ingredients: t('ingredients') })}
						/>
					);
				},
				accessor: 'countUid',
				component: 'SimpleCell',
				type: 'number',
				editable: true,
				sortDescFirst: true,
				props: {
					style: {
						fontSize: '14px',
					},
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('suppliers')} />;
				},
				accessor: 'suppliers',
				component: 'Supplier',
				editable: true,
				sortDescFirst: true,
				type: 'number',
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })}
							//label={indicator === CARBONE_INDICATOR ? WORDING_TERMS.IMPACT : 'Impact eau'}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
						/>
					);
				},
				accessor: indicator === CARBONE_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				type: 'percent',
				props: {
					...(indicator === CARBONE_INDICATOR
						? {
								popoverComponent: ImpactCarbonePopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={theme.palette.indicator.carbon.main} />,
						  }
						: {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
								popoverComponent: WaterImpactPopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={theme.palette.indicator.water.main} />,
						  }),
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							//label={indicator === CARBONE_INDICATOR ? WORDING_TERMS.INTENSITY : 'Intensité eau'}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBONE_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBONE_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
				},
			},

			{
				// Header: 'UTILISATIONS',
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('uses')} /* measure="carbonIntensity" */ />;
				},
				accessor: 'countUse',
				component: 'SimpleCell',
				type: 'number',
				editable: true,
				sortDescFirst: true,
				props: {
					style: {
						fontSize: '14px',
					},
				},
			},
		];
	}, [theme, indicator, t, selectedYear]);

	// ---- fetch data ---- //
	const {
		result: { data: dataResult, isLoading },
	} = useFindIngredientFamily({
		params: {
			...paramsTable,
			viewMode: indicator,
			ingredientId: null, // to avoid the search params object id // TODO: ?? yes
		},
	});

	const { skeleton } = useSkeleton({ condition: isLoading });

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		// redirect to first page
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions]);
	let paramsUrlString = '';
	if (searchParams) paramsUrlString = `?${searchParams}`;

	// return null;

	return (
		<TableComponent
			skeleton={skeleton}
			loading={false}
			fetchData={updateTableParams}
			pageCount={dataResult?.meta?.last_page || 0}
			noDataMessage={t('ingredient-table-empty-data', { ingredient: toLowerCase(t('ingredient')) })}
			pageSize={paramsTable.size}
			columns={tableColumns}
			resetPage={resetPage}
			// data={formatThenSetData(
			// 	dataResult?.data || [],
			// 	indicator,
			// 	dataResult?.maxCarboneIntensity || 1,
			// 	dataResult?.maxWaterIntensity || 1,
			// 	paramsUrlString,
			// )}
			data={formatDataTable(dataResult?.ingredients || [], { paramsUrlString })}
			onSortingColumn={handleSortingTable}
			addStyles={stylesTable}
			manualSortBy
			stickyHeader
			stickyHeaderStyles={stickyHeaderStyles}
			transFunc={t}
			onNextClick={() => {
				return trackEvent('Explore Ingredients Next Page');
			}}
		/>
	);
};

const stickyHeaderStyles = getStickyHeaderStyle(1638);

export default IngredientTable;
