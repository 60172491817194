import { SyntheticEvent, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { UserSquare2 } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, classNames } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import SheetPageLayout from '../../../../components/layout/page/SheetPageLayout';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';

import { siteColors } from '../../../../lib/colors';
import ClientInfos from './tabs/infos/ClientInfos';
import DevisList from './tabs/quote/DevisList';

// type Props = {};

const ClientDevisList = (/* props: Props */) => {
	const { t } = useTranslation();
	const { breadcrumbs, setBreadcrumbs } = useApp();

	const tabs = {
		devis: {
			title: t('Devis carbone'),
			element: <DevisList />,
		},
		clientForm: {
			title: t('Informations du client'),
			element: <ClientInfos />,
		},
	};
	const { indicator } = useApp();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const navigate = useNavigate();
	const params = useParams();

	const handleTabChange = (_: SyntheticEvent, value: string) => {
		navigate(`${FRONT_PATH_NAMES.quote}/client/${params.clientId}/${value}`);
	};

	const clientId = params.clientId!;

	const { data: clientData } = useQuery({
		queryKey: ['getClientSheetById', { clientId }] as const,
		queryFn: async (ctx) => {
			const {
				queryKey: {
					1: { clientId },
				},
			} = ctx;

			const client = await new Parse.Query(classNames.CLIENT).equalTo('objectId', clientId).first();

			return client?.toJSON();
		},
		// enabled: false,
	});

	const { data: devisData } = useQuery({
		queryKey: ['findDevisForClientTable', { clientId, input: searchQueryParams.input, indicator }] as const,
		queryFn: async ({ queryKey }) => {
			const { clientId, input, indicator } = queryKey[1];

			const result = await Parse.Cloud.run('findDevis', { clientId, input, indicator });
			return result.data[0].quotes;
		},
	});

	useEffect(() => {
		setBreadcrumbs([
			{ text: t('Devis carbone'), link: FRONT_PATH_NAMES.quote },
			{
				text: clientData?.name || '',
				// link: `${FRONT_PATH_NAMES.quote}/client/${clientId}`,
			},
			{
				// text: 'Devis carbone',
				text: !params.tab || params.tab === 'devis' ? t('Devis carbone') : t('Informations du client'),
				isBeta: false,
			},
		]);
	}, [setBreadcrumbs]);

	return (
		<SheetPageLayout
			loading={false}
			headerProps={{
				variant: 'fixed',
				icon: <UserSquare2 size={40} color={siteColors.text} />,
				title: clientData?.name,
				subtitle: `${devisData?.length || 0} devis carbone`,
				sx: {
					// background: '#F0F4F4',
					// background: 'linear-gradient(90deg, #e3f5f7 0%, #e1e8e8 100%) !important',
					background: siteColors.teal10,
				},
			}}
			currentTab={params.tab || _.keys(tabs)[0]}
			tabs={tabs}
			onTabChange={handleTabChange}
		/>
	);
};

export default ClientDevisList;
