import React from 'react';

import Box, { type BoxProps } from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MUIPopper, { type PopperPlacementType, type PopperProps } from '@mui/material/Popper';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';

type RenderContentProps = {
	close: () => void;
};

type Props = {
	enableTrigger?: boolean;
	trigger: React.ReactElement<{ onclick?: React.MouseEventHandler } & Record<string, unknown>>;
	anchorEl?: Element;
	// anchorOrigin?: PopoverOrigin;
	// transformOrigin?: PopoverOrigin;
	// disableScrollLock?: boolean;
	placement?: PopperPlacementType;
	onClose?: () => void;
	boxContentContainerProps?: BoxProps;
	popperProps?: Omit<PopperProps, 'open'>;
} & (
	| {
			// variant: 'simple'
			content: React.ReactNode;
			renderContent?: undefined;
	  }
	| {
			// variant: 'composed'
			renderContent: (props: RenderContentProps) => React.ReactNode;
			content?: undefined;
	  }
);

const Popper = ({
	enableTrigger = true,
	trigger,
	anchorEl,
	// transformOrigin,
	// anchorOrigin,
	// disableScrollLock = true,
	onClose,
	placement = 'bottom-start',
	boxContentContainerProps = {},
	popperProps = {},
	...props
}: Props) => {
	const [anchorElState, setAnchorEl] = React.useState<Element | null>(null);
	// const containerRef = useRef<HTMLElement>(null);
	const theme = useTheme();

	const handleOpen = (event: React.MouseEvent) => {
		setAnchorEl(anchorEl ?? event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		onClose?.();
	};

	const open = Boolean(anchorElState);

	const newTrigger = React.cloneElement(trigger, {
		onClick: (e: React.MouseEvent) => {
			if (enableTrigger) {
				trigger.props.onclick && trigger.props.onclick(e);
				handleOpen(e);
			}
		},
	});

	return (
		<>
			{/* <Box ref={containerRef}> */}
			{newTrigger}
			<MUIPopper
				open={open}
				// container={containerRef.current}
				anchorEl={anchorElState}
				// onClose={handleClose}
				// transformOrigin={transformOrigin}
				// anchorOrigin={anchorOrigin}
				placement={placement}
				transition
				{...popperProps}
				sx={_.merge(
					{
						zIndex: theme.zIndex.tooltip,
					},
					popperProps.sx || {},
				)}
			>
				{({ TransitionProps }) => {
					return (
						<ClickAwayListener onClickAway={handleClose}>
							<Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }} timeout={150}>
								<Box
									bgcolor={theme.palette.common.white}
									boxShadow={theme.shadows[10]}
									borderRadius={theme.shape.borderRadius * 0.5}
									{...boxContentContainerProps}
								>
									{props.renderContent && props.renderContent({ close: handleClose })}
									{props.content && props.content}
								</Box>
							</Grow>
						</ClickAwayListener>
					);
				}}
			</MUIPopper>
			{/* </Box> */}
		</>
	);
};

export default Popper;
