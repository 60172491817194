import { functionName } from '@carbonmaps/shared/utils/constants';
import { type QueryFunctionContext } from '@tanstack/react-query';

type getExportLogType = {
	uid: string;
	type: string;
};
export const getExportLogAction = async (
	context: QueryFunctionContext<readonly ['getExportLog', getExportLogType]>,
) => {
	try {
		const {
			queryKey: {
				1: { uid, type },
			},
		} = context;

		const result = await Parse.Cloud.run(functionName.log.getExportLog, {
			uid,
			type,
		});

		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};
