import { Recycle } from 'lucide-react';

import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useRecycledPercent } from '../packagings.hooks';

import KPICard from './KPICard';
import KpiValue from './KpiValue';

const RecycledKpi = () => {
	// ---- translate ---- //
	const { t } = useTranslation();
	const [searchQueryParams] = useSearchQueryParams();

	// ---- fetch data  ---- //
	const { data, isLoading } = useRecycledPercent({
		inputSearch: searchQueryParams.input,
		facetFilters: searchQueryParams?.facetFilters || [],

	});
	return (
		<KPICard
			className="flex1"
			label={t('Matériaux recyclé (tonnes)')}
			value={<KpiValue value={data as any} unit={'%'} withHelp={!data as any} />}
			icon={<Recycle size={30} color={siteColors.recycledIcon} />}
			isLoading={isLoading}
		/>
	);
};

export default RecycledKpi;
