import { WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { siteColors } from '../../../../../apps/front/src/lib/colors';
import { useApp } from '../../../hooks/useApp';
import { displayValue, formatNumber } from '../../../utils/numberFormat';
import BPopover from '../BPopover';

type Props = {
	value: number;
	row: any;
	column: any;
};

const styles = {
	aaa: css({
		'& .MuiButtonBase-root': {
			width: '100%',
			justifyContent: 'var(--content-alignment, flex-end)',
		},
	}),
	progressContainer: css({
		width: 'var(--width)',
		height: 8,
		background: 'var(--background)',
		borderRadius: 2,
		'.MuiButtonBase-root': {
			width: '100% !important',
			// gap: '12px ! important',
		},
	}),
	text: css({
		color: siteColors.grey8,
		whiteSpace: 'nowrap',
	}),

	// popoverContent: css({
	// 	padding: '16px',
	// 	minWidth: '120px !important',
	// }),
	label: css({
		fontWeight: '600 !important',
		lineHeight: '1 !important',
		fontSize: '14px !important',
	}),
	value: css({
		lineHeight: '1 !important',
	}),

	mr: css({
		marginRight: '8px !important',
	}),
	ml: css({
		marginLeft: '8px !important',
	}),
	fontBold: css({
		fontWeight: 'bold !important',
	}),
};

const RecycledPercentField = ({ value, row, column }: Props) => {
	// const { indicator } = useApp();
	const contentAlignment = !['flex-end', 'flex-start'].includes(column?.props?.contentAlignment)
		? 'flex-end'
		: column?.props?.contentAlignment;
	const popoverContent = column?.props?.popoverContent?.(row) || null;
	const anchorOriginHorizontal = column?.props?.anchorOriginHorizontal;

	const renderText = (
		<Typography
			className={cx(
				'number',
				styles.text,
				contentAlignment === 'flex-end' ? styles.mr : '',
				contentAlignment === 'flex-start' ? styles.ml : '',
			)}
		>
			{formatNumber(value, undefined, value > 1 ? 0 : 2)} %
		</Typography>
	);

	return (
		<div className={cx('flexRow width100 nowrap alignCenter gap8', column?.props?.className)}>
			{/* <Typography className={cx('number ', styles.text)}>
				{formatNumber(value, undefined, value > 1 ? 0 : 2)} %
			</Typography> */}

			<BPopover
				modeHover
				content={() => {
					return popoverContent;
				}}
				transformOriginHorizontal={'left'}
				transformOriginVertical={'top'}
				anchorOriginHorizontal={anchorOriginHorizontal || 'center'}
				anchorOriginVertical={'bottom'}
				containerClass={cx('width100', styles.aaa)}
				// containerClass={styles.progressContainer}
				style={{
					['--content-alignment' as any]: contentAlignment,
					// ['--width' as any]: `calc(${value}% - ${value === 100 ? '52px' : '0px'})`,
					// ['--background' as any]: column?.props?.color,
				}}
			>
				{contentAlignment === 'flex-end' ? renderText : null}
				<div
					className={styles.progressContainer}
					style={{
						['--width' as any]: `calc(${value}% - ${value === 100 ? '52px' : '0px'})`,
						['--background' as any]: column?.props?.color,
					}}
				></div>
				{contentAlignment === 'flex-start' ? renderText : null}
			</BPopover>
		</div>
	);
};

export default RecycledPercentField;
