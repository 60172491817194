import Typography from '@mui/material/Typography';

import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import EmptyValue from './EmptyValue';

const Percentage = (props: any) => {
	const { value, column } = props;

	const { precision, sx, className } = column?.props || {};

	return !isNaN(value) && value !== null && value !== '' && value ? (
		<Typography sx={sx} className="number">
			{/* {formatNumber(value, undefined, precision !== undefined ? precision : 1)} */}
			{displayValue(value, undefined, precision !== undefined ? precision : 1)} %
		</Typography>
	) : (
		<EmptyValue className={className} />
	);
};

export default Percentage;
