import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const InvalidLink = () => {
	return (
		<Box px={0} py={2} maxWidth={'386px'} minHeight={'300px'} /*  display="flex" alignItems="center" */>
			<Typography variant="h3" marginTop="32px">
				Le lien est expiré. Veuillez renvoyer un nouvel email.
			</Typography>
		</Box>
	);
};

export default InvalidLink;
