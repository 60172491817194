import useTheme from '@mui/material/styles/useTheme';
import { useTable } from 'react-table';

type Props = {
	columns: any;
	data: any;
};

const OtherIndicatorsTable = ({ columns, data }: Props) => {
	const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
		columns,
		data,
	});
	const theme = useTheme();

	return (
		<table
			{...getTableProps()}
			css={{
				borderCollapse: 'separate',
				borderSpacing: '0px',
				border: `1px solid ${theme.palette.grey[500]}`,
				borderRadius: '10px',
				overflow: 'hidden',
				'th, td': {
					border: `1px solid ${theme.palette.grey[500]}`,
					borderTop: 'unset',
					borderRight: 'unset',
					padding: 0,
				},
				'thead th:first-of-type, tr td:first-of-type': {
					borderLeft: 'unset',
				},
				'tr:last-of-type td': {
					borderBottom: 'unset',
				},
				// ==========
				width: '100%',
			}}
		>
			<thead>
				{headerGroups.map((headerGroup: any) => {
					return (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column: any) => {
								return <th {...column.getHeaderProps()}>{column.render('Header')}</th>;
							})}
						</tr>
					);
				})}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => {
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default OtherIndicatorsTable;
