import { useEffect, useState } from 'react';

import { closestCenter, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, CircularProgress, IconButton, TextField, useTheme } from '@mui/material';
import { BookOpenText, Eye, EyeOff, GripVertical, Pencil, Plus } from 'lucide-react';
import { nanoid } from 'nanoid';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import z from 'zod';

import Container from '../../../components/layout/list/Container';
import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../hooks/useTranslation';
import { queryClient } from '../../../providers/QueryClientProvider';
import { useFindSectorSheetGroups } from '../../sectorSheet/hooks';

import { cn, siteColors } from '../../../lib/colors';
import {
	useGlobalLoading,
	useMoveGroupOrder,
	useMoveSectorSheetOrder,
	useSaveSectorSheet,
	useSaveSheetGroup,
	useUpdateGroupPublishStatus,
	useUpdateSheetPublishStatus,
} from './sectorSheet.hooks';

const styles = {
	hidden: css({
		display: 'none',
	}),

	globalDisabled: css({
		'& *': {
			cursor: 'not-allowed',
			pointerEvents: 'none',
			opacity: 0.9,
		},
	}),
};

const saveGroupZodSchema = z.object({
	name_fr: z.string().min(1),
	name_en: z.string().min(1),
});

const SectorSheetList = () => {
	const {
		result: { data: sheetData, isSuccess },
	} = useFindSectorSheetGroups({ fromBo: true });
	const { isGlobalLoading } = useGlobalLoading();

	// const sensors = useSensors(
	// 	useSensor(PointerSensor),
	// 	useSensor(KeyboardSensor, {
	// 		coordinateGetter: sortableKeyboardCoordinates,
	// 	}),
	// );

	const [groups, setGroups] = useState<any[]>([]);
	const [renderKey, setRenderKey] = useState(nanoid());

	useEffect(() => {
		if (!sheetData) {
			return;
		}

		const newGroups = sheetData.map((group: any) => {
			return {
				id: group.objectId,
				...group,
			};
		});

		setGroups(newGroups);
		setRenderKey(nanoid());
	}, [sheetData]);

	const {
		result: { mutate: moveGroupOrder },
	} = useMoveGroupOrder();

	const onDragEnd = (event: any) => {
		const { active, over } = event;

		if (active.id === over.id) {
			return;
		}

		setGroups((users: any) => {
			const oldIndex = users.findIndex((user: any) => {
				return user.id === active.id;
			});
			const newIndex = users.findIndex((user: any) => {
				return user.id === over.id;
			});
			return arrayMove(users, oldIndex, newIndex);
		});

		if (groups.length > 0) {
			setGlobalLoading(true);
			moveGroupOrder({
				categoryId: active.id,
				oldIndex: groups.findIndex((group: any) => {
					return group.id === active.id;
				}),
				newIndex: groups.findIndex((group: any) => {
					return group.id === over.id;
				}),
			});
			// moveSectorSheetOrder({
			// 	newIndex: groups.findIndex((group: any) => {
			// 		return group.id === over.id;
			// 	}),
			// 	oldIndex: groups.findIndex((group: any) => {
			// 		return group.id === active.id;
			// 	}),
			// 	sheetIdFrom: active.id,
			// 	sheetIdTo: over.id,
			// });
		}
	};

	const addGroupForm = useForm({
		defaultValues: {
			name_en: '',
			name_fr: '',
		},
		resolver: zodResolver(saveGroupZodSchema),
	});

	const [openAddGroupRow, setOpenAddGroupRow] = useState(false);
	const { setGlobalLoading } = useGlobalLoading();

	const {
		result: { mutateAsync: saveSheetGroup },
	} = useSaveSheetGroup({
		onSuccess: () => {
			setOpenAddGroupRow(false);
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
	});

	// const isGlobalDisabled = isSaveSheetGroupLoading || isSwapSheetLoading;

	return (
		<Container header={<SectorSheetHeader />}>
			<div className={cx('', isGlobalLoading ? styles.globalDisabled : '')}>
				<div
					className="flexRow alignCenter"
					css={{
						marginTop: '32px',
						marginBottom: '32px',
					}}
				>
					<Button
						variant="contained"
						onClick={() => {
							setOpenAddGroupRow(true);
						}}
					>
						Add a group
					</Button>
					<CircularProgress
						//
						style={{ display: isGlobalLoading ? 'block' : 'none' }}
						size={24}
						sx={{ ml: 3 }}
					/>
				</div>
				<div>
					<div className={cx('', !openAddGroupRow ? styles.hidden : '')} css={{ marginBottom: '8px' }}>
						<TextField
							label="name fr"
							{...addGroupForm.register('name_fr')}
							error={!!addGroupForm.formState.errors.name_fr?.message}
							helperText={addGroupForm.formState.errors.name_fr?.message}
						/>
						<TextField
							label="name en"
							{...addGroupForm.register('name_en')}
							sx={{ ml: 2 }}
							error={!!addGroupForm.formState.errors.name_en?.message}
							helperText={addGroupForm.formState.errors.name_en?.message}
						/>
						<Button
							variant="contained"
							onClick={addGroupForm.handleSubmit(async (data) => {
								// await saveSectorSheet({ categoryId: group.objectId, title: data.title });
								setGlobalLoading(true);
								await saveSheetGroup(data);
							})}
							sx={{
								// bgcolor: '#645c5c',
								ml: 2,
							}}
						>
							save
						</Button>
						<Button
							variant="contained"
							onClick={() => {
								setOpenAddGroupRow(false);
								addGroupForm.reset();
							}}
							sx={{
								bgcolor: '#645c5c',
								ml: 2,
								'&:hover': {
									bgcolor: '#645c5c',
								},
							}}
						>
							cancel
						</Button>
					</div>
					<DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
						<SortableContext key={renderKey} items={groups} strategy={verticalListSortingStrategy}>
							{groups.map((group: any) => {
								return <SheetGroup key={group.objectId} group={group} />;
							})}
						</SortableContext>
					</DndContext>
				</div>
			</div>
		</Container>
	);
};

export default SectorSheetList;

const saveSheetZodSchema = z.object({
	title_fr: z.string().min(1),
	title_en: z.string().min(1),
});

const SectorSheetHeader = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<ListHeader
			title={t('sector-sheet')}
			subtitle={undefined}
			icon={
				<IconContainer
					element={<BookOpenText size={40} color={siteColors.common.white} />}
					color={theme.palette.grey[900]}
				/>
			}
			className="bgGrey500"
		/>
	);
};

const SheetGroup = ({ group }: { group: any }) => {
	const [openAddRow, setOpenAddRow] = useState(false);

	const addSheetForm = useForm({
		defaultValues: {
			title_fr: '',
			title_en: '',
		},
		resolver: zodResolver(saveSheetZodSchema),
	});

	const editGroupForm = useForm({
		defaultValues: {
			name_fr: group.translation.fr?.name || '',
			name_en: group.translation.en?.name || '',
		},
		resolver: zodResolver(saveGroupZodSchema),
	});

	const { setGlobalLoading } = useGlobalLoading();

	const {
		result: { mutateAsync: saveSectorSheet },
	} = useSaveSectorSheet({
		onSuccess: () => {
			setOpenAddRow(false);
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
	});

	const [sheets, setSheets] = useState(
		(group.sheets || []).map((sheet: any) => {
			return {
				id: sheet.objectId,
				...sheet,
			};
		}),
	);

	const {
		result: { mutate: moveSectorSheetOrder },
	} = useMoveSectorSheetOrder();

	const onDragEnd = (event: any) => {
		const { active, over } = event;

		if (active.id === over.id) {
			return;
		}

		setSheets((users: any) => {
			const oldIndex = users.findIndex((user: any) => {
				return user.id === active.id;
			});
			const newIndex = users.findIndex((user: any) => {
				return user.id === over.id;
			});
			return arrayMove(users, oldIndex, newIndex);
		});

		if (sheets.length > 0) {
			setGlobalLoading(true);
			moveSectorSheetOrder({
				newIndex: sheets.findIndex((sheet: any) => {
					return sheet.id === over.id;
				}),
				oldIndex: sheets.findIndex((sheet: any) => {
					return sheet.id === active.id;
				}),
				sheetIdFrom: active.id,
				sheetIdTo: over.id,
				categoryId: group.objectId,
			});
		}
	};

	const { setNodeRef, attributes, listeners, setActivatorNodeRef, transform, transition } = useSortable({
		id: group.objectId,
	});
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const [openEditGroupRow, setOpenEditGroupRow] = useState(false);

	const {
		result: { mutateAsync: saveSheetGroup },
	} = useSaveSheetGroup({
		onSuccess: () => {
			setOpenEditGroupRow(false);
			queryClient.invalidateQueries(['findSectorSheetGroups']);
			setGlobalLoading(false);
		},
	});

	const {
		result: { mutate: updateGroupPublishStatus },
	} = useUpdateGroupPublishStatus();

	return (
		<div
			ref={setNodeRef}
			style={style}
			css={{
				borderBottom: cn('1px solid', siteColors.grey500),
				// marginBottom: '16px',
				paddingBottom: '24px',
				paddingTop: '16px',
				// padding: '30px',
				// boxShadow:
				// backgroundColor: siteColors.grey200,
			}}
		>
			<div className="flexRow alignCenter" css={{ marginBottom: 12 }}>
				<IconButton {...attributes} {...listeners}>
					<GripVertical size={20} />
				</IconButton>

				<div className={cx(openEditGroupRow ? styles.hidden : 'flexRow')} /* css={{ background: 'red' }} */>
					<h2
						css={{
							padding: 0,
							margin: 0,
							marginRight: 32,
						}}
					>
						{group.translation.fr?.name} <span css={{ color: siteColors.grey600 }}>/</span>{' '}
						{group.translation.en?.name || <span css={{ color: siteColors.grey600 }}>not translated</span>}
					</h2>

					<div className="flexRow" css={{ gap: 12 }}>
						<IconButton
							onClick={() => {
								setOpenEditGroupRow(true);
							}}
						>
							<Pencil size={20} />
						</IconButton>
						<IconButton
							onClick={() => {
								setGlobalLoading(true);
								// saveSheetGroup({ categoryId: group.objectId, published: !group.published });
								updateGroupPublishStatus({ categoryId: group.objectId, published: !group.published });
							}}
						>
							{group.published ? <Eye size={20} /> : <EyeOff size={20} />}
						</IconButton>
						{/* Add button */}
						{/* <button
					className={cx('', openAddRow ? styles.hidden : '')}
					onClick={() => {
						setOpenAddRow(true);
					}}
				>
					Add a sheet
				</button> */}
						<IconButton
							onClick={() => {
								setOpenAddRow(true);
							}}
						>
							<Plus size={20} />
						</IconButton>
					</div>
				</div>

				<div className={cx('', !openEditGroupRow ? styles.hidden : '')} css={{ marginBottom: '8px' }}>
					<TextField
						label="name fr"
						{...editGroupForm.register('name_fr')}
						error={!!editGroupForm.formState.errors.name_fr?.message}
						helperText={editGroupForm.formState.errors.name_fr?.message as any}
					/>
					<TextField
						label="name en"
						{...editGroupForm.register('name_en')}
						sx={{ ml: 2 }}
						error={!!editGroupForm.formState.errors.name_en?.message}
						helperText={editGroupForm.formState.errors.name_en?.message as any}
					/>
					<Button
						variant="contained"
						onClick={editGroupForm.handleSubmit(async (data) => {
							setGlobalLoading(true);
							await saveSheetGroup({ categoryId: group.objectId, ...data });
						})}
						sx={{
							// bgcolor: '#645c5c',
							ml: 2,
						}}
					>
						save
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							setOpenEditGroupRow(false);
							editGroupForm.reset();
						}}
						sx={{
							bgcolor: '#645c5c',
							ml: 2,
							'&:hover': {
								bgcolor: '#645c5c',
							},
						}}
					>
						cancel
					</Button>
				</div>
			</div>

			<div
				css={{
					paddingLeft: '32px',
				}}
			>
				{/* Row form */}
				<div className={cx('', !openAddRow ? styles.hidden : '')} css={{ marginBottom: '8px' }}>
					<TextField
						{...addSheetForm.register('title_fr')}
						label="title fr"
						error={!!addSheetForm.formState.errors.title_fr?.message}
						helperText={addSheetForm.formState.errors.title_fr?.message}
					/>
					<TextField
						{...addSheetForm.register('title_en')}
						sx={{ marginLeft: 2 }}
						label="title en"
						error={!!addSheetForm.formState.errors.title_en?.message}
						helperText={addSheetForm.formState.errors.title_en?.message}
					/>
					<Button
						variant="contained"
						onClick={addSheetForm.handleSubmit(async (data) => {
							setGlobalLoading(true);
							await saveSectorSheet({ categoryId: group.objectId, ...data });
						})}
						sx={{
							// bgcolor: '#645c5c',
							ml: 2,
						}}
					>
						save
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							setOpenAddRow(false);
							addSheetForm.reset();
						}}
						sx={{
							bgcolor: '#645c5c',
							ml: 2,
							'&:hover': {
								bgcolor: '#645c5c',
							},
						}}
					>
						cancel
					</Button>
				</div>

				<DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
					<SortableContext items={sheets} strategy={verticalListSortingStrategy}>
						{sheets.map((sheet: any) => {
							return <SheetItem key={sheet.objectId} sheet={sheet} />;
						})}
					</SortableContext>
				</DndContext>
			</div>
		</div>
	);
};

const SheetItem = ({ sheet }: { sheet: any }) => {
	const { setNodeRef, attributes, listeners, setActivatorNodeRef, transform, transition } = useSortable({
		id: sheet.objectId,
	});
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const {
		result: { mutate: updateSheetPublishStatus },
	} = useUpdateSheetPublishStatus();

	const { setGlobalLoading } = useGlobalLoading();

	return (
		<div
			ref={setNodeRef}
			className={cx('flexRow alignCenter')}
			css={{
				borderBottom: cn('1px solid', siteColors.grey300),
				paddingTop: 4,
				paddingBottom: 4,
			}}
			style={style}
		>
			<IconButton {...attributes} {...listeners}>
				<GripVertical size={18} />
			</IconButton>
			<div
				css={{
					flex: 1,
				}}
			>
				<Link to={sheet.objectId} css={{ textDecoration: 'none', color: 'unset' }}>
					<h3
						css={{
							padding: 0,
							margin: 0,
						}}
					>
						{sheet.translation.fr?.title} <span css={{ color: siteColors.grey600 }}>/</span>{' '}
						{sheet.translation.en?.title || <span css={{ color: siteColors.grey600 }}>not translated</span>}
					</h3>
				</Link>
			</div>
			<div className="flexRow" css={{ gap: 8 }}>
				{/* <IconButton>
					<Pencil size={18} />
				</IconButton> */}
				<IconButton
					onClick={() => {
						setGlobalLoading(true);
						updateSheetPublishStatus({ published: !sheet.published, sheetId: sheet.objectId });
						// saveSheetGroup({ categoryId: group.objectId, published: !group.published });
					}}
				>
					{sheet.published ? <Eye size={18} /> : <EyeOff size={18} />}
				</IconButton>
				{/* Add button */}
				{/* <button
					className={cx('', openAddRow ? styles.hidden : '')}
					onClick={() => {
						setOpenAddRow(true);
					}}
				>
					Add a sheet
				</button> */}
				{/* <IconButton
					onClick={() => {
						// setOpenAddRow(true);
					}}
				>
					<Plus size={18} />
				</IconButton> */}
			</div>
		</div>
	);
};
