import { useMemo } from 'react';

import { css, cx } from '@emotion/css';

import { useComments } from '../../containers/apbo/useApbo.hook';
import Typography from '../Typography';

import { siteColors } from '../../lib/colors';
import DeletedMessageButton from './DeletedMessageButton';

const styles = {
	container: css({
		padding: '26px 32px',
		background: siteColors.grey200,
		borderRadius: 16,
	}),
};

type CommentCardProps = {
	messageKey: string;
};

const CommentCard = ({ messageKey }: CommentCardProps) => {
	const { data } = useComments();

	const comments = useMemo(() => {
		if (!data) return [];

		return data.filter((item: any) => {
			return item?.key === messageKey;
		});
	}, [data, messageKey]);

	return comments?.length ? (
		<div className={cx('flexColumn  width100', styles.container)}>
			<div className="flexRow alignCenter spaceBetween width100">
				<Typography className="fontWeight600">Commentaire</Typography>
				<DeletedMessageButton messageKey={messageKey} />
			</div>

			{comments.map((item: any, index: number) => {
				return <Typography key={index}>{item?.message}</Typography>;
			})}
		</div>
	) : null;
};

export default CommentCard;
