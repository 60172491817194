import { css, cx } from '@emotion/css';
import { Info } from 'lucide-react';

const styles = {
	container: css({
		fontSize: 12,
		color: 'var(--color)',
		gap: 4,

		'& span': {
			color: 'var(--color)',
		},
		'& svg': {
			color: 'var(--color)',
		},
	}),
};
type ErrorDataProps = {
	text: React.ReactNode;
	withIcon?: boolean;
	color?: string;
};

const ErrorData = ({ text, withIcon = false, color = '#C74600' }: ErrorDataProps) => {
	return (
		<div className={cx('flexRow alignCenter number', styles.container)} style={{ ['--color' as any]: `${color}` }}>
			{withIcon && <Info size={14} />}
			<span>{text}</span>
		</div>
	);
};

export default ErrorData;
