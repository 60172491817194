import { css, cx } from '@emotion/css';
import Typography from '../../../components/Typography';
import { siteColors } from '../../../lib/colors';

type DisplayItemProps = {
	label: string;
	color: string;
};

const styles = {
	square: css({
		height: 8,
		width: 8,
		borderRadius: '50%',
		background: 'var(--background)',
	}),
	label: css({
		textTransform: 'none !important',
		color: `${siteColors.grey7} !important`,
	} as any),
};
const EndOfLifeItem = ({ label, color }: DisplayItemProps) => {
	return (
		<div className={cx('flexRow alignCenter gap8 nowrap')}>
			<div className={styles.square} style={{ ['--background' as any]: color }} />
			<Typography className={cx('lineHeight1', styles.label)}>{label}</Typography>
		</div>
	);
};

export default EndOfLifeItem;
