import React from 'react';

import { cx } from '@emotion/css';

type Props = {
	title: React.ReactNode;
	children: React.ReactNode;
	className?: string;
};

const CardSupplier = ({ title, children, className }: Props) => {
	return (
		<div className={cx('flexColumn width100 gap16', className)}>
			{title}
			{children}
		</div>
	);
};

export default CardSupplier;
