import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier-small.svg';
import { CARBONE_INDICATOR, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { COLOR_SCORING, getObjScoring } from '@carbonmaps/shared/utils/scoring';
import { getFacetLabel } from '@carbonmaps/shared/utils/utils';
import { getTopSupplier } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import DashboardSelect from '../../../../components/dashboard/DashboardSelect';
import Legend from '../../../../components/dashboard/Legend';
import CardContent from '../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../components/dashboard/v2/CardTitle';
import DashboardButton from '../../../../components/dashboard/v2/DashboardButton';
import DashboardCard from '../../../../components/dashboard/v2/DashboardCard';
import DashboardGraph from '../../../../components/dashboard/v2/DashboardGraph';
import Icon from '../../../../components/dashboard/v2/Icon';
import SupplierScore from '../../../../components/dashboard/v2/SupplierScore';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { FULFILLED_MODELIZATION_TYPE, PARTIAL_DATA_MODELIZATION_TYPE } from '../../../../utils/supplier';

const styles = {
	supplierIcon: css({
		path: {
			fill: siteColors.blueGray500,
		},
	}),
	content: css({
		minHeight: 321,
	}),
};

const toDataIndicatorGraph = (data: any, category: string, indicatorField: string, color: string, company?: any) => {
	const orderData = data;
	//const orderData = orderBy(data, indicatorField);
	// const total = _.sumBy(data, indicatorField);

	// display only 20 first element + others
	const firstData = orderData.slice(0, 19);
	const othersData = orderData?.length > 20 ? [orderData[20]] : [];

	const result = firstData.map((item: any, index: number) => {
		// const { color, label } = getObjScoring(item?.scoring);
		const color = _.get(COLOR_SCORING, _.get(item, 'note'), siteColors.grey400);
		return {
			...item,
			category: getFacetLabel(category, company),
			categoryValue: item?._id,
			path: item?.path || `${FRONT_PATH_NAMES.analyse}/${category}/${item?._id}`,
			label: item?._id,
			y: item[indicatorField],
			percentValue: item[indicatorField] || 0,
			color: color,
			// score: label,
		};
	});

	// add other element when exist
	if (othersData?.length) {
		const otherValue = _.sumBy(othersData, indicatorField);
		const otherElement = {
			category: 'Autres',
			categoryValue: 'Autres',
			y: otherValue,
			percentValue: otherValue,
			color: siteColors.grey400,
			label: 'Autres',
			score: 'Autres',
			path: null,
		};

		result.push(otherElement);
	}

	return result;
};

const Supplier = () => {
	// params filters
	const [searchQueryParams] = useSearchQueryParams();
	const { indicator } = useApp();
	const theme = useTheme();
	const navigate = useNavigate();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();
	const { t } = useTranslation();

	// value filter
	const [currentSegment, setCurrentSegment] = useState('perSupplier');
	const { selectedYear } = useYearSelection();

	// ---- fetch data suppliers ---- //
	const { data: dataSuppliers, isLoading: isLoadingSuppliers } = useQuery({
		queryKey: [
			'getTopSupplier',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
				viewMode: indicator,
				currentSegment,
			},
		],
		queryFn: getTopSupplier,
	});

	// ---- repartition graph data ---- //
	const dataGraph = useMemo(() => {
		if (!dataSuppliers || !currentSegment) return [];

		const color = theme.palette.indicator[indicator].main;

		let allSuppliers = dataSuppliers;
		console.log(allSuppliers);

		// ---- only display suppliers with specific lca ----//
		if (currentSegment === 'perAcv') {
			allSuppliers = dataSuppliers.filter((o: any) => {
				return o.onboarding === PARTIAL_DATA_MODELIZATION_TYPE || o.onboarding === FULFILLED_MODELIZATION_TYPE;
			});
		}

		const data = allSuppliers.map((obj: any) => {
			return {
				...obj,
				_id: obj?.name,
				label: obj?.name,
				path: `${FRONT_PATH_NAMES.suppliers.details}/${obj?.objectId}/synthesis`,
			};
		});

		// ---- format graph data ---- //
		const dataSegment = toDataIndicatorGraph(
			data,
			currentSegment,
			indicator === CARBONE_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
			color,
			authData?.session.company,
		) as any;

		if (!dataSegment?.length) {
			return [{ y: 1, color: siteColors.grey400, isEmpty: true }];
		}

		return dataSegment;
	}, [dataSuppliers, currentSegment, theme.palette.indicator, indicator, authData?.session.company]);

	// ---- go to supplier page ---- //
	const goToSupplier = useCallback((item: any) => {
		if (item?.path) {
			navigate(item?.path);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if (!dataSuppliers) return;

	// 	setOptions([
	// 		{ value: 'perSupplier', label: t('supplier-label') },
	// 		{ value: 'perAcv', label: t('per-specific-acv') },
	// 	]);
	// 	//setCurrentSegment('perSupplier');
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [dataSuppliers]);

	return (
		<DashboardCard
			title={
				<CardTitle
					icon={
						<Icon
							element={() => {
								return <SupplierIcon className={styles.supplierIcon} />;
							}}
							color={siteColors.primary}
							background="#E4E9F2"
						/>
					}
					title={t('dashboard-supplier-card-title')}
					subtitle={t('dashboard-supplier-card-subtitle', { period: selectedYear || t('period') })}
				/>
			}
			className="flex1"
		>
			<CardContent
				button={
					<DashboardButton
						label={t('dashboard-supplier-card-button')}
						path={FRONT_PATH_NAMES.suppliers.general}
						withFilter={false}
						eventName="Dashboard Suppliers See All"
					/>
				}
				className={cx(styles.content, 'flex1')}
				loading={isLoadingSuppliers}
			>
				<DashboardGraph
					filter={
						<DashboardSelect
							options={
								dataSuppliers
									? [
											{ value: 'perSupplier', label: t('supplier-label') },
											{ value: 'perAcv', label: t('per-specific-acv') },
									  ]
									: []
							}
							onChange={(v: any) => {
								setCurrentSegment(v);
							}}
							value={currentSegment as any}
						/>
					}
					data={dataGraph}
					renderItem={(item: any, index: number) => {
						return (
							<Legend
								label={item?.label}
								value={item?.percentValue}
								path={item?.path}
								key={index}
								icon={<SupplierScore color={item?.color} score={_.get(item, 'note', '-')} />}
							/>
						);
					}}
					onClick={goToSupplier}
				/>
			</CardContent>
		</DashboardCard>
	);
};

export default Supplier;
