import { Fragment, SyntheticEvent, useCallback, useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import TabsBar, { TabItem } from '../../../components/TabsBar';
import Container from '../../../components/layout/list/Container';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';
import AuditHeader from '../AuditHeader';

import IngredientAcv from './ingredient/IngredientAcv';
import ProductAcv from './product/ProductAcv';

const tabs = {
	product: {
		title: 'products',
		element: <ProductAcv />,
	},
	ingredient: {
		title: 'ingredients',
		element: <IngredientAcv />,
	},
};

const styles = {
	tabContainer: css({
		top: 58,
		margin: '0 auto',
		marginTop: '17px !important',
		position: 'sticky',
		// width: 'calc(100% - 64px)',
		'&.sticky': { width: '100%', borderRadius: 0 },
	}),

	contentContainerClassName: css({
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	}),

	// placeSelf: css({
	// 	placeSelf: '',
	// 	alignSelf: 'center!important',
	// 	justifySelf: 'center!important',
	// }),
};

const Acv = () => {
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();

	// translation
	const { t } = useTranslation();

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Audit' },
			});
		},
		[analytics],
	);

	// ---- handler on tab change ---- //
	const onTabChange = (_: SyntheticEvent, value: string) => {
		navigate(`${FRONT_PATH_NAMES.audit}/acv/${value}`);
	};

	useEffect(() => {
		trackEvent('Audit Specific LCA');
	}, [trackEvent]);

	// ---- navigate to product tab to first enter in acv ---- //
	if (!params.tab) {
		return <Navigate replace state={{ from: location }} to={`${FRONT_PATH_NAMES.audit}/acv/${Object.keys(tabs)[0]}`} />;
	}

	return (
		<Container
			header={<AuditHeader title={'acv-breadcrumbs'} />}
			seoText={`${t('audit-title')} - Carbon Maps`}
			containedContent={false}
			contentContainerClassName={styles.contentContainerClassName}
		>
			<TabsBar value={params.tab} onChange={onTabChange} className={cx('widthContainer', styles.tabContainer)}>
				{Object.entries(tabs).map(([tabName, { title, disabled }]: any) => {
					return <TabItem key={tabName} label={t(title)} value={tabName} disabled={disabled} />;
				})}
			</TabsBar>
			<div className={cx('widthContainer')}>
				{Object.entries(tabs).map(([tabName, { element }]: any) => {
					return tabName === params.tab && <Fragment key={tabName}>{element}</Fragment>;
				})}
			</div>
		</Container>
	);
};

export default Acv;
