import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { Tab, Tabs } from '@mui/material';
import { Database, Leaf, Wallet } from 'lucide-react';

import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';

import { siteColors } from '../../../lib/colors';
import AuditTab from './auditTab/AuditTab';
import EcoDesignTab from './ecoDesignTab/EcoDesignTab';
import ImpactTab from './impactTab/ImpactTab';

const items = [
	{
		label: 'dashboard-impact-tab',
		value: 'impact-tab',
		icon: Wallet,
		element: <ImpactTab />,
		tabName: 'Dashboard Reduce your impact Switch Tab',
	},
	{
		label: 'dashboard-eco-design-tab',
		value: 'eco-design-tab',
		icon: Leaf,
		element: <EcoDesignTab />,
		tabName: 'Dashboard Eco-design your products Switch Tab',
	},
	{
		label: 'dashboard-acv-tab',
		value: 'audit',
		icon: Database,
		element: <AuditTab />,
		tabName: 'Dashboard Improve your data Switch Tab',
	},
];

const styles = {
	container: css({
		width: '100%',
		background: siteColors.grey300,
		borderRadius: '16px',
		padding: '8px',
		'& .MuiTabs-flexContainer': {
			gap: 8,
			justifyContent: 'center !important',
		},
		'& .MuiButtonBase-root': {
			textTransform: 'none',
			display: 'flex',
			flex: '1 0 0 ',
			alignItems: 'center',
			minHeight: '52px !important',
			gap: 8,
			fontSize: '16px !important',
		},
		'& .MuiTabs-scroller': {
			overflow: 'initial !important',
		},
		'& .Mui-selected': {
			borderRadius: '8px',
			background: siteColors.common.white,
			boxShadow: '0px 2px 1px 0px rgba(93, 17, 230, 0.18), 0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
		},
		'& .MuiTabs-indicator': {
			background: 'none !important',
			//height: '2px !important',
		},
	} as any),
};

const DashboardTabs = () => {
	const { t } = useTranslation();

	const [currentTab, setCurrentTab] = useState('impact-tab');

	const analytics = useJuneTrack();

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		const tab = items.find((item) => {
			return item.value === newValue;
		});

		analytics.track(
			tab?.tabName || 'Dashboard Switch Tab',
			{},
			{
				// Add the GROUP_ID here to track this event on behalf of a workspace
				context: { category: 'Dashboard' },
			},
		);

		setCurrentTab(newValue);
	};

	return (
		<div className="flexColumn width100 gap32">
			<Tabs
				onChange={handleTabChange}
				aria-label="lab API tabs example"
				value={currentTab}
				className={cx(styles.container)}
			>
				{items.map((item: any, index: number) => {
					const IconComponent = item?.icon;
					const iconColor = currentTab === item.value ? siteColors.primary : siteColors.grey700;
					return (
						<Tab
							label={
								<div className="flexRow alignCenter gap8 nowrap textAlignLeft">
									<IconComponent color={iconColor} size={20} />
									{t(item.label, {
										products: toLowerCase(t('products')),
									})}
								</div>
							}
							value={item.value}
							key={index}
						/>
					);
				})}
			</Tabs>

			{items?.map((item: any) => {
				return item.value === currentTab ? item.element : null;
			})}
		</div>
	);
};

export default DashboardTabs;
