import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { LayoutGrid } from 'lucide-react';

import { getFacetLabel } from '@carbonmaps/shared/utils/utils';

import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

import { siteColors } from '../../lib/colors';
import { useAnalyseSetup } from './analyse.hooks';

const styles = {
	bgGradient: css({
		// background:
		// 	'linear-gradient(90deg, #F6EDFD 0.53%, #F8E7FE 99.99%), linear-gradient(90deg, #F0F6FD 0.53%, #E9F5FC 27.26%) !important',
		background: siteColors.purple10,
	}),
};
type Props = {
	totalCount?: number;
};

const AnalyseHeader = ({ totalCount }: Props) => {
	const theme = useTheme();

	// translation
	const { t } = useTranslation();

	const { categorySelected } = useAnalyseSetup();

	// ---- user info to specify facets ---- //
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	return (
		<ListHeader
			title={getFacetLabel(categorySelected, authData?.session.company)}
			subtitle={`${totalCount} ${t('éléments')}`}
			icon={
				<IconContainer
					color={siteColors.purple500}
					element={<LayoutGrid color={theme.palette.common.white} size={32} />}
				/>
			}
			className={styles.bgGradient}
		/>
	);
};

export default AnalyseHeader;
