import _, { forEach } from 'lodash';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { orderBy } from '../utils/array.utils';

import ProductModel from './Product.model';

export const requiredSupplierFields = [
	{
		id: 'gesScoring',
		label: 'Réalisation d’un bilan GES',
		questionCategory: 'maturity',
	},
	{
		id: 'acvScoring',
		label: 'Réalisation d’un ACV',
		questionCategory: 'maturity',
	},
	{
		id: 'emissionFactorCalculation',
		label: 'Modélisation de ses propres FE',
		questionCategory: 'maturity',
	},
	{
		id: 'traceabilityProducts',
		label: 'Traçabilité de ses flux matières',
		questionCategory: 'maturity',
	},
	{
		id: 'traceabilityCoProducts',
		label: 'Traçabilité de ses flux de co-produits',
		questionCategory: 'maturity',
	},
	{
		id: 'definedTargets',
		label: 'Objectifs de réduction définis ',
		questionCategory: 'engagement',
	},
	{
		id: 'sbtiTargets',
		label: 'Engagé SBTi',
		questionCategory: 'engagement',
	},
	{
		id: 'actionPlan',
		label: 'Plans d’action pour la réduction',
		questionCategory: 'engagement',
	},
	{
		id: 'ecofriendlyLabels',
		label: 'Produits avec labels environnementaux',
		questionCategory: 'engagement',
	},
];

const FULL_FIELD = 3;

export default class SupplierModel {
	supplier: any;

	constructor(supplier: any) {
		this.supplier = supplier;
	}

	getId() {
		return this.supplier.objectId || this.supplier.id;
	}

	getName() {
		return this.supplier?.name;
	}

	getModelizationType() {
		return this.supplier?.onboarding;
	}

	getCarboneImpact() {
		if (!this.supplier.carbonImpact || this.supplier.carbonImpact < 0) return this.supplier.carbonImpact;
		return formatNumber(this.supplier.carbonImpact, undefined, 0);
	}

	isCarboneImpactValid() {
		return this.supplier.carbonImpact && this.supplier.carbonImpact > 0;
	}
	isWaterImpactValid() {
		return this.supplier.waterImpact && this.supplier.waterImpact > 0;
	}

	getWaterImpact() {
		if (!this.supplier.waterImpact || this.supplier.waterImpact < 0) return this.supplier.waterImpact;
		return formatNumber(this.supplier.waterImpact, undefined, 0);
	}

	getCarboneIntensity() {
		if (!this.supplier.carbonIntensity) return '-';
		return formatNumber(this.supplier.carbonIntensity, undefined, 2);
	}

	getWaterIntensity() {
		if (!this.supplier.waterIntensity) return '-';
		return formatNumber(this.supplier.waterIntensity, undefined, 2);
	}

	getImpactByIndicator(indicator: 'carbon' | 'water') {
		if (indicator === 'carbon') {
			return this.getCarboneImpact();
		}

		return this.getWaterImpact();
	}

	getIntensityByIndicator(indicator: 'carbon' | 'water') {
		if (indicator === 'carbon') {
			return this.getCarboneIntensity();
		}

		return this.getWaterIntensity();
	}

	getAnsweredPercent() {
		// const products = this.supplier.products;
		// const total = products?.length;
		// let blueCheckCount = 0;
		// (products || []).forEach((item: any) => {
		// 	const object = new ProductModel(item);
		// 	if (object.isN2ModelizationType()) blueCheckCount = blueCheckCount + 1;
		// });
		// return (blueCheckCount * 100) / total;

		let count = 0;
		requiredSupplierFields.forEach((field) => {
			if (this.supplier[field.id]) count = count + 1;
		});
		return (100 * count) / requiredSupplierFields.length;
	}

	getScore() {
		return this.supplier.scoring;
	}

	getScoreText() {
		const score = this.getScore();
		if (score > 89) return 'A+';
		if (score > 69) return 'A';
		if (score > 49) return 'B';
		if (score > 29) return 'C';
		if (score > 9) return 'D';
		if (score > 0) return 'E';
		return 'default';
	}

	getNote() {
		const score = this.supplier?.note;
		if (score) return score;
		return 'default';
	}

	getValueByField(field: string) {
		return this.supplier[field];
	}

	getRequiredFieldsValues() {
		return orderBy(
			requiredSupplierFields.map((item) => {
				const value = this.getValueByField(item.id);
				return {
					...item,
					value,
					checked: value === 'yes' ? 1 : value === 'no' ? 0 : -1,
				};
			}) as any,
			'checked',
		);
	}
	getUpdatedAt() {
		return this.supplier.updatedAt;
	}
	getCreatedAt() {
		return this.supplier.createdAt;
	}
	isChecked() {
		return this.supplier?.isChecked === 'yes' || this.supplier?.isChecked === true;
	}

	isBlueCheck() {
		return this.getModelizationType() === FULL_FIELD || this.getModelizationType() === 2.5;
	}

	getSoldItemsProducts() {
		return _.sumBy(this.supplier?.products, 'soldItems');
	}

	getTonnage() {
		return this.supplier?.tonnage;
	}
}
