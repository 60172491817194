import { useCallback, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Box as BoxIcon, HelpCircle } from 'lucide-react';
import { createSearchParams, useParams } from 'react-router-dom';

import { ReactComponent as ExternalIcon } from '@carbonmaps/media/icons/external-link.svg';
import { FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { getConfigFacets } from '@carbonmaps/shared/utils/utils';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import Card from '../../../../components/Card';
import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import ImpactCarbonePopover from '../../../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../../../components/table/popover/WaterImpactPopover';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useQueryParams } from '../../../../hooks/useSearchParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import ProductModel from '../../../../models/Product.model';
import { orderBy } from '../../../../utils/array.utils';
import { useDataProducts, useProductTable } from '../../analyse.hooks';
const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const formatToView = (data = []) => {
	const finalData = data?.map((item: any) => {
		return {
			...item,
			isChecked: new ProductModel(item)?.isN2ModelizationType(),
			link: `${FRONT_PATH_NAMES.products}/${item?.originalId}/synthesis`,
			volume: item.tonnage || 0,
			origin: item?.uid,
		};
	});

	return finalData;
};

const Product = ({ filterValues }: any) => {
	const { indicator } = useApp();
	const theme = useTheme();

	const { t } = useTranslation();

	const { selectedYear } = useYearSelection();

	// const { data, isLoading } = useDataProducts({});

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const fetchIdRef = useRef(0);
	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState({
		page: 1,
		size: 100,
		facetFilters: [],
	});

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	const { data, isLoading } = useProductTable({
		viewMode: indicator,
		tableOptions: paramsTable,
	});

	const tableColumns = useMemo(() => {
		return [
			{
				// Header: 'Produit fini',
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('products')} itemCount={data?.meta?.count} />;
				},
				accessor: 'label', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				icon: <BoxIcon color={theme.palette.common.black} />,
			},
			...(indicator === CARBON_INDICATOR
				? [
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })}
										variant="measure"
										measure="carbonImpactTon"
									/>
								);
							},
							accessor: 'carbonImpactPercent',
							editable: true,
							sortDescFirst: true,
							component: 'PercentField',
							type: 'percent',
							props: {
								popoverComponent: ImpactCarbonePopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={theme.palette.indicator.carbon.main} />,
							},
						},
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.INTENSITY)}
										variant="measure"
										measure="carbonIntensityKg"
									/>
								);
							},
							accessor: 'carbonIntensity', // accessor is the "key" in the data
							editable: true,
							sortDescFirst: true,
							component: 'IntensityField',
							type: 'number',
						},
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.IMPACT_PORTION)}
										variant="measure"
										measure="carbonImpactPortion"
									/>
								);
							},
							accessor: 'carbonImpactPortion', // accessor is the "key" in the data
							editable: true,
							sortDescFirst: true,
							component: 'ImpactPortion',
							type: 'number',
						},
				  ]
				: [
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })}
										variant="measure"
										measure="waterImpact"
									/>
								);
							},
							accessor: 'waterImpactPercent',
							editable: true,
							sortDescFirst: true,
							component: 'PercentField',
							type: 'percent',
							props: {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
								popoverComponent: WaterImpactPopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={theme.palette.indicator.water.main} />,
							},
						},
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.INTENSITY)}
										variant="measure"
										measure="waterIntensity"
									/>
								);
							},
							accessor: 'waterIntensity',
							component: 'IntensityField',
							editable: true,
							sortDescFirst: true,
							type: 'number',
							props: {
								color: siteColors.water500,
							},
						},
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.IMPACT_PORTION)}
										variant="measure"
										measure="waterImpactPortion"
									/>
								);
							},
							accessor: 'waterImpactPortion', // accessor is the "key" in the data
							editable: true,

							sortDescFirst: true,
							component: 'ImpactPortion',
							type: 'number',
						},
				  ]),
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [theme.palette.common.black, selectedYear, indicator, t]);

	// insert segment facet in the url as facet
	const params = useParams();
	const queryParams = useQueryParams();
	const filters = JSON.parse(queryParams?.facetFilters?.[0] || '[]');
	const currentConfigFacet = useMemo(() => {
		const items = getConfigFacets(authData?.session.company, false, 'Product');
		return items.find((f) => {
			return f.field === params.segment && f.collection?.includes('Product');
		});
	}, [authData?.session.company, params.segment]);

	filters.push({
		path: params.segment,
		type: typeof params.value,
		values: [params.value || ''],
		global: currentConfigFacet?.global || false,
		itemType: 'product',
		folderLabel: currentConfigFacet.label || params.segment,
	});
	const stringFilters = JSON.stringify(filters);

	return (
		<Card title={''} sx={{ overflow: 'hidden' }}>
			<Box>
				<Box padding="16px" display="flex" sx={{ justifyContent: 'flex-end', background: siteColors.grey200 }}>
					<BButton
						variant="secondary"
						label={t('analyze-product-see-all', { 'finished products': toLowerCase(t('products')) })}
						onClick={() => {
							window.open(`${FRONT_PATH_NAMES.products}?facetFilters=${stringFilters || '[]'}`);
						}}
						iconRight={<ExternalIcon />}
					/>
				</Box>
				<Box paddingLeft="16px" paddingRight="16px">
					<TableComponent
						pageCount={(data as any)?.meta?.last_page || 0}
						noDataMessage={t('product-table-empty-data', {
							product: toLowerCase(t('product')),
							of_product: t('of_product'),
						})}
						pageSize={100}
						columns={tableColumns}
						data={formatToView((data as any)?.products)}
						// manualPagination={false}
						addStyles={{
							table: {
								border: 'none!important',
							},
							'.bordered td, .bordered th': {
								borderLeft: 'none!important',
								background: `${theme.palette.common.white} !important`,
								borderBottom: `1px solid ${theme.palette.grey[400]}`,
							},
							'.bordered tr th:not(:nth-of-type(1)) > div,.bordered tr td:not(:nth-of-type(1)) div ,.bordered tr td:not(:nth-of-type(1)) button':
								{
									justifyContent: 'flex-end!important',
									paddingRight: '0!important',
								},
						}}
						fetchData={updateTableParams}
						loading={false}
						isSelectable={false}
						transFunc={t}
						skeleton={isLoading}
						onSortingColumn={handleSortingTable}
					/>
				</Box>
			</Box>
		</Card>
	);
};

export default Product;
