import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import { ListItemButton, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import Popover from '../../components/Popover';
import { useTranslation } from '../../hooks/useTranslation';
import { setUserLanguageAction } from '../../lib/react-query/features/user/user.actions';

const sendEmailResetPasswordSchema = Yup.object().shape({
	email: Yup.string().email('Entrez un adresse e-mail valide').required('Entrez votre adresse e-mail'),
});

type SendResetEmailFormInput = Yup.InferType<typeof sendEmailResetPasswordSchema>;

const SendResetEmailForm = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isPasswordRequestComplete, setIsPasswordRequestComplete] = useState<boolean>(false);

	const { t, i18n } = useTranslation();
	const theme = useTheme();

	// const languageOptions = [
	// 	{
	// 		label: t('fr'),
	// 		icon: <FlagFr />,
	// 		onClick: () => {
	// 			i18n.changeLanguage('fr');
	// 		},
	// 	},
	// 	{
	// 		label: t('en'),
	// 		icon: <FlagEn />,
	// 		onClick: () => {
	// 			i18n.changeLanguage('en');
	// 		},
	// 	},
	// ];

	const {
		mutate: setUserLanguage,
		isError,
		isSuccess,
		isLoading: isLoadingSetUserLanguage,
	} = useMutation({
		mutationKey: ['setUserLanguage'],
		mutationFn: setUserLanguageAction,

		onSuccess: async (data: any, variables) => {
			console.log(variables?.email);
			await Parse.User.requestPasswordReset(variables?.email);
		},
		onError: (error: any) => {
			if (error.message) {
				//setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<SendResetEmailFormInput> = async (values) => {
		setIsPasswordRequestComplete(false);
		setIsLoading(true);
		await setUserLanguage({ lang: getIsoCountryCode(i18n.language), email: values.email } as any);
		setIsLoading(false);
		setIsPasswordRequestComplete(true);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<SendResetEmailFormInput>({
		resolver: yupResolver(sendEmailResetPasswordSchema),
	});

	return (
		<Box px={0} py={2} maxWidth={'400px'}>
			<Typography
				variant="inherit"
				css={{
					fontWeight: 400,
					fontSize: 24,
				}}
			>
				{t('password-form-title')}
			</Typography>

			<Typography mb="32px">{t('password-form-description')}</Typography>

			<div css={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
				<BInput
					type="text"
					required
					id="email"
					label={t('input-mail-label')}
					fullWidth
					margin="dense"
					{...register('email')}
					name="email"
					error={errors.email ? true : false}
					errorMessage={errors?.email?.message}
				/>
			</div>

			<Box mt={3} css={{ marginBottom: 48 }}>
				<BButton
					variant="primary"
					label={
						isLoading ? (
							<CircularProgress size={20} css={{ color: theme.palette.common.white }} />
						) : (
							t('password-form-button')
						)
					}
					onClick={handleSubmit(onSubmit)}
					sx={{ minWidth: '400px' }}
				/>
			</Box>

			{isPasswordRequestComplete && (
				<Typography
					variant="inherit"
					css={{
						background: '#F5F9EC',
						border: '1px solid #009F36',
						boxShadow: 'inset 4px 0px 0px #009F36',
						borderRadius: '0px 8px 8px 0px',
						display: 'flex',
						alignItems: 'center',
						gap: 26,
						padding: 26,
						marginBlock: 32,
					}}
				>
					<CheckIcon css={{ color: '#009F36' }} />
					<Box>
						<Typography mb="16px">{t('success-password-form-title')}</Typography>
						<MUILink
							onClick={(e) => {
								e.preventDefault();
								// handleSubmit(onSubmit);
								window.location.reload();
							}}
							sx={{
								color: theme.palette.primary.main,
								textDecoration: 'none',
								cursor: 'pointer',
							}}
						>
							{t('send-mail-button')}
						</MUILink>
					</Box>
				</Typography>
			)}

			<Link
				to={FRONT_PATH_NAMES.logIn}
				css={{
					textDecoration: 'none',
					color: theme.palette.primary.main,
				}}
			>
				{t('login-button')}
			</Link>
		</Box>
	);
};

export default SendResetEmailForm;
