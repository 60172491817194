import styled from '@mui/material/styles/styled';
import Tooltip, { tooltipClasses, type TooltipProps } from '@mui/material/Tooltip';

import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { cn, siteColors } from '../lib/colors';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
	return <Tooltip {...props} classes={{ popper: className }} enterDelay={200} />;
})(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			color: siteColors.text,
			fontWeight: 400,
			fontSize: pxToRem(14),
			borderRadius: '8px',
			border: cn('1px solid', siteColors.grey500),
			background: siteColors.common.white,
			boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
			padding: '24px',
			gap: '24px',
			minWidth: '200px',
			maxWidth: '250px',
		},
	};
});

export default LightTooltip;
