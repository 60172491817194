import { css, cx } from '@emotion/css';

import { cn, siteColors } from '../../../lib/colors';
import Typography from '../../Typography';

type LabelAxisProps = {
	data?: any;
	gap?: number;
};

const styles = {
	container: css({
		gap: 'var(--gap)',
	}),

	label: css({
		width: 'var(--width)',
		textAlign: 'center',
		color: cn(siteColors.grey900, '!important'),
	}),
};

const LabelAxis = ({ data, gap }: LabelAxisProps) => {
	const barNumber = data?.length;
	return (
		<div
			className={cx('flexRow nowrap width100', styles.container)}
			style={{
				['--gap' as any]: `${gap}px`,
			}}
		>
			{data?.map((item: any, index: number) => {
				return (
					<Typography
						className={styles.label}
						style={{
							['--width' as any]: `calc(${100 / barNumber}% - ${gap}px)`,
						}}
						variant="caption"
						key={index}
					>
						{item?.label}
					</Typography>
				);
			})}
		</div>
	);
};

export default LabelAxis;
