import { ConfigFacetFormInput, ConfigFacetsValidationSchema } from '@carbonmaps/shared/validations/company.validations';
import { saveConfigFacetAction } from '@carbonmaps/ui/actions/company.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormSelect from '../../components/form/fields/FormSelect';
import AlertStatic from '../../components/Toast/AlertStatic';
import Typography from '../../components/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

const styles = {
	container: css({
		width: 560,
		maxWidth: '100%',
		gap: 48,
	}),
	subtitle: css({
		paddingBottom: '14px',
		borderBottom: `1px solid ${siteColors.grey500}`,
	}),
	button: css({
		marginTop: '0px !important',
		padding: '16px 24px !important',
		minHeight: '56px !important',
		background: `${siteColors.primary2} !important`,
	}),
	selectContainer: css({
		width: '100%',
		'.MuiFormControl-root': {
			width: '386px !important',
			maxWidth: '100% !important',
		},
	}),
};

const Config = () => {
	const { t } = useTranslation();

	const {
		result: { data: authData },
		key: getClientAuthKey,
	} = useGetClientAuthQuery();
	console.log(' authDataauthData', authData);

	const options = useMemo(() => {
		if (!authData?.session?.company) return [];

		const company = authData?.session?.company;
		const facets = (company?.facets || [])?.find(
			(item: any) => item?.indexName?.includes('facets') && item?.collection?.includes('Product'),
		);
		if (!facets?.fields?.length) return [];

		return facets?.fields
			?.filter((i: any) => !i?.global && i?.isVisibleInFront)
			.map((item: any) => {
				return {
					value: item?.field,
					label: item?.label,
				};
			});
	}, [authData?.session?.company]);

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<ConfigFacetFormInput>({
		resolver: zodResolver(ConfigFacetsValidationSchema),
		defaultValues: async () => {
			return {
				category: authData?.session?.company?.category || '',
			};
		},
		mode: 'onChange',
	});

	const queryClient = useQueryClient();

	const [message, setMessage] = useState<String>('');

	// ---- update product ---- //
	const {
		mutate: saveCurrentFacet,
		isLoading,
		isSuccess,
	} = useMutation({
		mutationKey: ['setCategory'],
		mutationFn: saveConfigFacetAction,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: ['getCurrentCategory'] });
			// setOpen(false);
		},
		onError: (error: any) => {
			// queryClient.invalidateQueries({ queryKey: ['getScenario'] });
		},
	});

	const handleSubmitForm = (data: any) => {
		console.log('data', data);

		saveCurrentFacet({
			...data,
			companyId: authData?.session?.company?.objectId,
		});
	};

	const renderValue = useCallback(
		(v: any) => {
			const option = (options || []).find((item: any) => item?.value === v);

			return option?.label;
		},
		[options],
	);

	return (
		<>
			<div className="flexCenter">
				<form className={cx('flexColumn', styles.container)}>
					<Typography variant="h1">{t('settings')}</Typography>
					{isSuccess ? (
						<AlertStatic
							text={message}
							type={'success'}
							icon={<CheckIcon css={{ color: siteColors.green500 }} />}
							className="width100"
						/>
					) : null}
					<div className="flexRow gap24 width100">
						<Typography variant="h3" className={cx('width100', styles.subtitle)}>
							{t('Categories')}
						</Typography>
						<FormSelect
							className={styles.selectContainer}
							control={control}
							{...register('category')}
							label={t('catégorie à afficher par défaut')}
							renderValue={(v: string) => <Typography>{renderValue(v)}</Typography>}
							options={options}
							defaultValue={authData?.session?.company?.category}
							isOptions
						/>
					</div>
					<BButton
						onClick={handleSubmit(handleSubmitForm)}
						className={styles.button}
						label={t('button-save-default')}
						variant="primary"
					/>
				</form>
			</div>
		</>
	);
};

export default Config;
