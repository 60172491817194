import dayjs from 'dayjs';
import { Workbook } from 'exceljs';

import {
	AUDIT_CONFIG,
	COMPOSITION_ERROR,
	INGREDIENT,
	PACKAGING,
	PRODUCT,
	UID_LABEL_ERROR,
	VOLUME_ERROR,
} from '@carbonmaps/shared/utils/audit';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';
//--------------------------------------------------------------------------------------//
//                                                                                      //
//                                       Export                                        //
//                                                                                      //
//--------------------------------------------------------------------------------------//

const HEADING_PRODUCTS = {
	// eslint-disable-next-line quotes
	id: "ID d'erreur",
	contentText: 'Erreur détaillée',
	uid: 'ID produit fini',
	label: 'Libellé produit fini',
	labelPortfolio: 'Libellé gamme produit',
	soldItems: 'Quantité vendue',
	netWeight: 'Poids net',
	grossWeight: 'Poids brut',
	tonnage: 'Tonnage',
};

const HEADING_INGREDIENTS = {
	// eslint-disable-next-line quotes
	id: "ID d'erreur",
	contentText: 'Erreur détaillée',
	uid: 'ID produit fini',
	label: 'Libellé produit fini',
	uidIngredient: 'ID ingredient',
	labelIngredient: 'Libellé ingredient',
	composition: 'Composition (en %)',
};

const HEADING_PACKAGING = {
	// eslint-disable-next-line quotes
	id: "ID d'erreur",
	contentText: 'Erreur détaillée',
	uid: 'ID produit fini',
	label: 'Libellé produit fini',
	uidPackaging: 'ID emballage',
	labelPackaging: 'Libellé emballage',
	composition: 'Poids',
};

const CONFIG_ITEMS = new Map();

CONFIG_ITEMS.set(PRODUCT, {
	headers: HEADING_PRODUCTS,
	cell_tonnage_number: 9,
	cell_uid_number: 3,
	cell_label_number: 4,
	mergeCell: 'A1:I1',
	uidKey: 'uid',
	labelKey: 'label',
});
CONFIG_ITEMS.set(INGREDIENT, {
	headers: HEADING_INGREDIENTS,
	cell_tonnage_number: 9,
	cell_uid_number: 5,
	cell_label_number: 6,
	cell_composition: 7,
	mergeCell: 'A1:G1',
	uidKey: 'uidIngredient',
	labelKey: 'labelIngredient',
});

CONFIG_ITEMS.set(PACKAGING, {
	headers: HEADING_PACKAGING,
	cell_tonnage_number: 9,
	cell_uid_number: 5,
	cell_label_number: 6,
	cell_composition: 7,
	mergeCell: 'A1:G1',
	uidKey: 'uidPackaging',
	labelKey: 'labelPackaging',
});

const getRowsValues = (item: any, config: any, t: any) => {
	return Object.keys(config.headers).map((key: string) => {
		return (
			(t &&
				t(item[key], {
					product: t('product'),
					ingredient: t('ingredient'),
					an_ingredient: t('an_ingredient'),
					ingredient_lowercase: toLowerCase(t('ingredient')),

					ingredients: t('ingredients'),
					of_product: t('of_product'),
					products: t('products'),
					recipe: t('recipe'),
					recipe_lowercase: t('recipe'),
				})) ||
			item[key] ||
			''
		);
	});
};

const isEmptyValue = (row: any, field: string) => {
	return !row[field] || row[field] === '' || row[field] === 'nan' || row[field] === 'null';
};

const getCellWithError = (item: any, config: any) => {
	const cellErrors = [];

	if (item?.errorType === VOLUME_ERROR) {
		cellErrors.push(config?.cell_tonnage_number);
	}

	if (item?.errorType === UID_LABEL_ERROR) {
		// check error uid
		if (isEmptyValue(item, config?.uidKey)) cellErrors.push(config?.cell_uid_number);
		// check error label
		if (isEmptyValue(item, config?.labelKey)) cellErrors.push(config?.cell_label_number);
	}

	if (item?.errorType === COMPOSITION_ERROR) cellErrors.push(config?.cell_composition);

	return cellErrors;
};

/**
 *
 * @param params
 * @returns
 */
export async function exportAuditAction(params: any) {
	const { itemType, t } = params;

	const configByItemType = CONFIG_ITEMS.get(itemType);

	// uid with errors

	try {
		// ---- fetch data ---- //
		const data = await Parse.Cloud.run('getSheetDataAudit', params);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('My Worksheet');

		worksheet.addRow([t('Envoyez votre fichier corrigé à support@carbonmaps.io')]);
		const columnsName = Object.values(configByItemType.headers).map((key) => {
			return t(key, {
				product: t('product'),
				ingredient: t('ingredient'),
				an_ingredient: t('an_ingredient'),
				ingredient_lowercase: toLowerCase(t('ingredient')),

				ingredients: t('ingredients'),
				of_product: t('of_product'),
				products: t('products'),
				recipe: t('recipe'),
				recipe_lowercase: t('recipe'),
			});
		});

		worksheet.addRow(columnsName);
		data.forEach((item: any) => {
			const worksheetRow = worksheet.addRow(getRowsValues(item, configByItemType, t));
			// Cycle through the cells in the row and add a red background if the cell is empty

			const cellErrors = getCellWithError(item, configByItemType);
			worksheetRow.eachCell((cell, colNumber) => {
				if (cellErrors.includes(colNumber)) {
					cell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: 'FFFF0000' },
					};
				}
			});
		});

		// Merge the first row of titles
		worksheet.mergeCells(configByItemType?.mergeCell);

		// Change the height of the first row (column A)
		worksheet.getRow(1).height = 30;
		worksheet.getRow(2).height = 25;

		// Change the column width
		worksheet.getColumn(1).width = 25; // column A
		worksheet.getColumn(2).width = 35; // column B
		worksheet.getColumn(3).width = 25; // column C
		worksheet.getColumn(4).width = 50; // column D
		worksheet.getColumn(5).width = 30; // column E
		worksheet.getColumn(6).width = 20; // column F
		worksheet.getColumn(7).width = 20; // column G
		worksheet.getColumn(8).width = 20; // column H
		worksheet.getColumn(9).width = 20; // column I

		worksheet.eachRow((row, rowNumber) => {
			row.eachCell((cell, colNumber) => {
				if (rowNumber === 1) {
					cell.font = { bold: true, italic: true };
					cell.alignment = { horizontal: 'center', vertical: 'middle' };
				}

				if (rowNumber === 2) {
					cell.alignment = { vertical: 'middle' };
					cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'A4C2F4' } };
				}
			});
		});

		const nameSheet = `${params.companyCode ?? ''}_${t(AUDIT_CONFIG.get(itemType)?.fileName)}_${dayjs(
			new Date(),
		)?.format('DDMMYYYY')}.xlsx`;

		// Create a Blob to save the workbook
		workbook.xlsx.writeBuffer().then((buffer) => {
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = nameSheet;
			a.click();
			window.URL.revokeObjectURL(url);
		});
	} catch (error) {
		console.log(error);
		return Promise.reject(error);
	}
}
