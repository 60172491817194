import { css, cx } from '@emotion/css';
import { Beef } from 'lucide-react';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Icon from '../../dashboard/v2/Icon';
import Typography from '../../Typography';

const styles = {
	unit: css({
		fontWeight: '400 !important',
	}),
};

type IndicatorCardProps = {
	title: string;
	value: number;
	icon: any;
	color: string;
	background: string;
	className?: string;
	unit?: string;
};

const IndicatorCard = ({ title, value, icon, color, background, className, unit = '%' }: IndicatorCardProps) => {
	return (
		<div className={cx('flexRow alignCenter gap12 nowrap width100', className)}>
			<Icon
				element={icon}
				iconSize={24}
				size={56}
				color={color}
				background={background}
				borderColor="rgba(0, 0, 0, 0.08)"
			/>
			<div className="flexColumn gap6 flex1">
				<Typography className="fontWeight600 number">
					{value ? formatNumber(value, undefined, 1) : 0}
					<span className={styles.unit}>{unit}</span>
				</Typography>
				<Typography className="grey800">{title}</Typography>
			</div>
		</div>
	);
};

export default IndicatorCard;
