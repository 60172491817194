import { useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { Link as RouterLink } from 'react-router-dom';

import { MEDIUM_DESKTOP_DOWN, mobileTablet, XL_DESKTOP_DOWN } from '@carbonmaps/ui/utils/Responsive';

import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import LightTooltip from '../LightTooltip';

const styles = {
	link: css({
		textDecoration: 'none !important',
	}),
	label: css({
		maxWidth: '100px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		[XL_DESKTOP_DOWN]: {
			maxWidth: '90px',
		},
		[MEDIUM_DESKTOP_DOWN]: {
			maxWidth: '58px',
		},
		[mobileTablet]: {
			maxWidth: '20px',
		},
	}),
	value: css({
		maxWidth: '150px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		[XL_DESKTOP_DOWN]: {
			maxWidth: '135px',
		},
		[MEDIUM_DESKTOP_DOWN]: {
			maxWidth: '110x',
		},
		[mobileTablet]: {
			maxWidth: '25px',
		},
	}),

	container: css({
		// display: 'flex',
		gap: '8px',
	}),
};

type CategoryItemProps = {
	title: string;
	content: React.ReactNode;
	basePath?: any;
};

const CategoryItem = ({ title, content, basePath }: CategoryItemProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	const isArray = Array.isArray(content);

	const [isReadMore, setIsReadMore] = useState(true);
	const [dataContent, setDataContent] = useState<any>([]);

	const toggleReadMore = () => {
		setIsReadMore((prev) => {
			return !prev;
		});
	};

	useEffect(() => {
		if (!isArray) return;
		if (isReadMore) setDataContent((content as any)?.slice(0, 3));

		if (!isReadMore) setDataContent(content);
	}, [isReadMore, content, isArray]);

	return (
		<div className={cx('flexRow', styles.container)}>
			<LightTooltip title={title}>
				<Typography fontWeight={600} color={theme.palette.grey[800]} className={styles.label}>
					{title}
				</Typography>
			</LightTooltip>
			{isArray ? (
				<div className="flexColumn">
					<div className="flexRow">
						{dataContent.map((item: any, index: number) => {
							return (
								<MUILink component={RouterLink} to={`${basePath}${encodeURIComponent(item)}`} className={styles.link}>
									<Typography>
										{item}
										{index !== dataContent?.length - 1 ? ',' : ''}{' '}
									</Typography>
								</MUILink>
							);
						})}
					</div>
					{content?.length > 3 && (
						<div
							style={{ color: siteColors.grey800, textDecorationColor: siteColors.grey800, cursor: 'pointer' }}
							onClick={toggleReadMore}
						>
							{t('see-button-text')} {isReadMore ? t('see-more') : t('see-less')}
						</div>
					)}
				</div>
			) : (
				<LightTooltip title={content}>
					<div className={cx('nowrap', styles.value)}>{content}</div>
				</LightTooltip>
			)}
		</div>
	);
};

export default CategoryItem;
