import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { Info } from 'lucide-react';

import { displayValue } from '@carbonmaps/ui/utils/numberFormat';

import Unit from '../../../../../apps/front/src/components/units/Unit';
import Difference from '../../../../../apps/front/src/containers/scenario/Difference';
import { useTranslation } from '../../../../../apps/front/src/hooks/useTranslation';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import BPopover from '../BPopover';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	content: css({
		gap: 0,
		color: 'var(--color)',
		fontSize: 14,
	}),
	innerContent: css({
		width: 16,
		height: 16,
		borderRadius: 16,
		background: 'var(--background-round)',
	}),
	popoverContainer: css({
		padding: 24,
		minWidth: 280,
	}),

	itemContainer: css({
		gap: 16,
		color: 'var(--color)',
	}),

	popoverContent: css({
		gap: 16,
		paddingBottom: 16,
		borderBottom: cn('2px solid', siteColors.text),
	}),
	popoverFooter: css({
		paddingTop: 16,
		paddingLeft: 38,
	}),

	width10: css({
		width: 10,
	}),
	width20: css({
		width: 50,
	}),

	popoverTable: css({
		borderCollapse: 'collapse',
		'& thead:first-of-type > tr > th': {
			borderBottom: cn('1px solid', siteColors.grey500),
			paddingBottom: 12,
		},
		'& tbody > tr:first-of-type > td': {
			paddingTop: '15px',
			paddingBottom: '10px',
		},
		'& tbody > tr:nth-of-type(2) > td': {
			paddingBottom: '15px',
			borderBottom: cn('1px solid', siteColors.grey500),
		},
		'& tbody > tr:last-of-type > td': {
			paddingTop: '12px',
		},
		'& tr > th:nth-of-type(2), & tr > td:nth-of-type(2)': {
			paddingRight: '25px',
			paddingLeft: '25px',
		},

		'& .thText': {
			fontFamily: 'Inter',
			fontSize: '12px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: 'normal',
			color: siteColors.grey800,
			textAlign: 'right',
			leadingTrim: 'both',
			textEdge: 'cap',
		},
		'& .firstColText': {
			color: siteColors.grey800,
			leadingTrim: 'both',
			textEdge: 'cap',
			fontVariantNumeric: 'lining-nums tabular-nums',
			fontFamily: 'Inter',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: 'normal',
		},
		'& .percentDiffText': {
			color: 'var(--color)',
			textAlign: 'right',
			leadingTrim: 'both',
			textEdge: 'cap',
			fontVariantNumeric: 'lining-nums tabular-nums',
			fontFamily: 'Inter',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 600,
			lineHeight: 'normal',
		},
		'& .diffText': {
			color: siteColors.grey800,
			textAlign: 'right',
			leadingTrim: 'both',
			textEdge: 'cap',
			fontVariantNumeric: 'lining-nums tabular-nums',
			fontFamily: 'Inter',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: 'normal',
		},
	}),
};

const AcvPopoverField = ({
	value,
	row,
	column,
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	// total
	const diff = row.original?.diff;
	const diffPercent = row.original?.diffPercent;

	const gesAgriculturesDiff = row.original?.gesAgriculturesDiff;
	const gesAgriculturesDiffPercent = row.original?.gesAgriculturesDiffPercent;

	const gesTransformationDiff = row.original?.gesTransformationDiff;
	const gesTransformationDiffPercent = row.original?.gesTransformationDiffPercent;

	return (
		<div className="flexRow width100 justifyCenter">
			<BPopover
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				modeHover
				content={(onClose) => {
					return (
						<div className={styles.popoverContainer}>
							{/* <div className={cx('flexColumn', styles.popoverContent)}>
								<div
									className={cx('flexRow alignCenter', styles.itemContainer)}
									style={{ ['--color' as any]: gesAgriculturesDiff >= 0 ? siteColors.grey800 : '#009F36' }}
								>
									<Wheat />
									<div className="flexColumn">
										<span className="fontWeight700">
											{gesAgriculturesDiffPercent >= 0 ? '+' : '-'}
											{formatNumber(Math.abs(gesAgriculturesDiffPercent), undefined, 2)}%
										</span>
										<span>
											{gesAgriculturesDiff >= 0 ? '+' : '-'}
											{formatNumber(Math.abs(gesAgriculturesDiff), undefined, 2)} kg<sub>eq</sub>CO₂ / kg
										</span>
									</div>
								</div>
								<div
									className={cx('flexRow alignCenter', styles.itemContainer)}
									style={{ ['--color' as any]: gesTransformationDiff > 0 ? siteColors.grey800 : '#009F36' }}
								>
									<Factory />
									<div className="flexColumn">
										<span className="fontWeight700">
											{gesTransformationDiffPercent >= 0 ? '+' : '-'}
											{formatNumber(Math.abs(gesTransformationDiffPercent), undefined, 2)}%
										</span>
										<span>
											{gesTransformationDiff >= 0 ? '+' : '-'}
											{formatNumber(Math.abs(gesTransformationDiff), undefined, 2)} kg<sub>eq</sub>CO₂ / kg
										</span>
									</div>
								</div>
							</div>
							<div
								className={cx('flexRow alignCenter', styles.itemContainer, styles.popoverFooter)}
								style={{ ['--color' as any]: diff >= 0 ? siteColors.grey800 : '#009F36' }}
							>
								<div className="flexColumn">
									<span className="fontWeight700">
										{diffPercent >= 0 ? '+' : '-'}
										{formatNumber(Math.abs(diffPercent), undefined, 2)}%
									</span>
									<span>
										{diff >= 0 ? '+' : '-'}
										{formatNumber(Math.abs(diff), undefined, 2)} kg<sub>eq</sub>CO₂ / kg
									</span>
								</div>
							</div> */}
							<table className={styles.popoverTable}>
								<thead>
									<tr>
										<th>&nbsp;</th>
										<th>
											<div className="thText">{t('Différence en %')}</div>
										</th>
										<th>
											<Unit measure="carbonIntensityKg" className="thText" />
										</th>
									</tr>
								</thead>

								<tbody>
									<tr>
										<td>
											<div className="firstColText">{t('agriculture')}</div>
										</td>
										<td>
											<div
												className="percentDiffText"
												style={{ ['--color' as any]: gesAgriculturesDiffPercent >= 0 ? siteColors.grey900 : '#009F36' }}
											>
												{/* {displayValue(gesAgriculturesDiffPercent)} % */}
												<Difference value={gesAgriculturesDiffPercent} />
											</div>
										</td>
										<td>
											<div className="diffText">{displayValue(gesAgriculturesDiff)}</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className="firstColText">{t('transformation')}</div>
										</td>
										<td>
											<div
												className="percentDiffText"
												style={{
													['--color' as any]: gesTransformationDiffPercent >= 0 ? siteColors.grey900 : '#009F36',
												}}
											>
												{/* {displayValue(gesTransformationDiffPercent)} % */}
												<Difference value={gesTransformationDiffPercent} />
											</div>
										</td>
										<td>
											<div className="diffText">{displayValue(gesTransformationDiff)}</div>
										</td>
									</tr>
									<tr>
										<td>
											<div className="firstColText">{t('Total')}</div>
										</td>
										<td>
											<div
												className="percentDiffText"
												style={{ ['--color' as any]: diffPercent >= 0 ? siteColors.grey900 : '#009F36' }}
											>
												{/* {displayValue(diffPercent)} % */}
												<Difference value={diffPercent} />
											</div>
										</td>
										<td>
											<div className="diffText">{displayValue(diff)}</div>
										</td>
									</tr>
									{/* <tr>
										<td>Data 1, Row 4</td>
										<td>Data 2, Row 4</td>
										<td>Data 3, Row 4</td>
									</tr> */}
								</tbody>
							</table>
						</div>
					);
				}}
			>
				<Info color={siteColors.grey700} />
			</BPopover>
		</div>
	);
};

export default AcvPopoverField;
