import React, { ReactNode, useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import type { FormGroupProps } from '@mui/material/FormGroup';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import _ from 'lodash';

import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { useTranslation } from '../../../hooks/useTranslation';

// import { Search } from 'lucide-react';

export type Option = {
	value: string | number;
	label: React.ReactNode;
	folderLabel: string;
	// isFacetBucket?: boolean;
	// isFacet: boolean;
	count?: number;
	labelNode?: ReactNode;
} & (
		| {
			isFacetBucket: true;
			facetPath: string;
			type: string;
			global?: boolean;
			itemType?: string;
			// ... etc
		}
		| {
			isFacetBucket?: false | undefined;
		}
	);

export type MultiSelectCheckboxFieldProps = {
	name?: string;
	// handleChange?: AutocompleteProps['onChange'];
	handleChange?: (event: any, value: string | number | any, reason: string, option: any) => void;
	options: Option[];
	selectedValues: Option[];
	defaultValue?: Option[];
	showOptionListDefault?: boolean;
	handleFilterClick: any;

	// filterName?: {
	// 	name: string;
	// 	type: string;
	// 	global: any;
	// };
	showCount?: boolean;
	customSx?: any;
	customPopper?: React.ComponentType<any>;
	customPaperComponent?: React.ComponentType<any>

} & FormGroupProps;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MyPopper = (props: any) => {
	return <Popper {...props} style={{ width: 300, borderRadius: 0, border: 'none' }} placement="bottom-start" />;
};

const MultiSelectCheckboxField = ({
	name,
	handleChange,
	options,
	defaultValue,
	selectedValues,
	showOptionListDefault = true,
	handleFilterClick,
	// filterName,
	showCount = true,
	customSx,
	customPopper,
	customPaperComponent,
	...rest
}: MultiSelectCheckboxFieldProps) => {
	const [open, setOpen] = useState(showOptionListDefault);

	const { t } = useTranslation();
	return (
		<Autocomplete
			PopperComponent={customPopper ?? MyPopper}
			PaperComponent={customPaperComponent ?? undefined}
			multiple
			// limitTags={2}
			disablePortal={true}
			// defaultValue={defaultValue}
			onChange={handleChange}
			value={selectedValues?.map((v: any) => {
				return {
					...v,
					label: t(v.label, {
						products: t('products'),
						recipe: t('recipe'),
						recipe_lowercase: t('recipe'),
						ingredient: t('ingredient'),
						an_ingredient: t('an_ingredient'),
						ingredient_lowercase: toLowerCase(t('ingredient')),
						product: t('product'),
						of_product: t('of_product'),
						ingredients: t('ingredients'),
					}),
				};
			}) || []}
			id="checkboxes-mui-carbo"
			open={open}
			onOpen={() => {
				return setOpen(!open);
			}}
			onClose={() => {
				handleFilterClick();
				return setOpen(!open);
			}}
			ListboxProps={{ style: { maxHeight: '326px', borderRadius: 0 } }}
			options={options}
			disableCloseOnSelect
			getOptionLabel={(option: any) => {
				// if (option.labelNode) return option.labelNode;
				return String(option.label);
			}}
			clearIcon={''}
			renderOption={(props, option, { selected }) => {
				const inSelectedValues = _.find(selectedValues, ['value', option.value]);

				return (
					<li {...props}>
						<Checkbox icon={icon} checkedIcon={checkedIcon} checked={_.isObject(inSelectedValues) ? true : false} />
						{option.labelNode ||
							t(option.label, {
								ingredient: t('ingredient'),
								an_ingredient: t('an_ingredient'),
								ingredient_lowercase: toLowerCase(t('ingredient')),
								product: t('product'),
								of_product: t('of_product'),
								ingredients: t('ingredients'),
								products: t('products'),
								recipe: t('recipe'),
								recipe_lowercase: t('recipe'),
							})}{' '}
						{(showCount && option?.count !== -1 && option?.count) ? `(${option.count})` : ''}
					</li>
				);
			}}
			sx={{
				width: 300,
				'& .MuiAutocomplete-endAdornment': {
					display: 'none',
				},
				'& .MuiInputBase-root': {
					borderRadius: 0,
					// background: 'red',
					border: 'none',
					'&:hover': { background: 'none' },
				},
				'& .MuiPaper-elevation,& .MuiPaper-listBox': {
					borderRadius: 0,
					border: 'none',
					marginTop: '2px',
					'&:hover': { background: 'none' },
				},
				'& .MuiAutocomplete-inputRoot': {
					border: 'hidden',
					'&:hover': { border: 'none' },
					'&:focus': { border: 'none' },
				},
				...customSx,

			}}
			renderInput={(params) => {
				return <TextField {...params} placeholder={t('search')} />;
			}}

		/>
	);
};

export default MultiSelectCheckboxField;
