import { Helmet } from 'react-helmet-async';

type Props = { version: string };

const Version = ({ version }: Props) => {
	return (
		<Helmet>
			<meta name="version" content={version} />
		</Helmet>
	);
};

export default Version;
