import useTheme from '@mui/material/styles/useTheme';

import { ReactComponent as Impact_EnvironnementalIcon } from '@carbonmaps/media/icons/Impact_Environnemental.svg';
import { ReactComponent as Impact_Environnemental_1_Icon } from '@carbonmaps/media/icons/Impact_Environnemental_1.svg';
import { ReactComponent as Impact_Environnemental_2_Icon } from '@carbonmaps/media/icons/Impact_Environnemental_2.svg';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const ImpactEnv = ({
	value,
	row: { index },
	column: { id },
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const theme = useTheme();
	return (
		<div>
			{value >= 6 && <Impact_Environnemental_2_Icon />}
			{value < 6 && value >= 5 && <Impact_Environnemental_1_Icon />}
			{value < 5 && <Impact_EnvironnementalIcon />}
		</div>
	);
};

export default ImpactEnv;
