import { css, cx } from '@emotion/css';
import { Box } from 'lucide-react';
import { Link } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '../../Typography';

import { cn, siteColors } from '../../../lib/colors';
import Icon from './Icon';

const styles = {
	label: css({
		color: cn(siteColors.text, '!important'),
		maxWidth: '250px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		textWrap: 'nowrap',
	}),

	caption: css({
		color: cn(siteColors.grey800, '!important'),
	}),
	link: css({
		textDecoration: 'none',
	}),
};

const TagLabel = ({ item }: any) => {
	const { t } = useTranslation();
	return (
		<Link
			to={`${FRONT_PATH_NAMES.products}/${item?.originalId || item?._id}/synthesis?uid=${item?.uid}&fromPath=`}
			className={styles.link}
			//target={column?.link?.target ? '_blank' : '_self'}
		>
			<div className="flexRow alignCenter gap16 nowrap">
				<Icon element={Box} color={siteColors.grey700} background={siteColors.grey300} />
				<div className="flexColumn gap8">
					<Typography className={cx('lineHeight1', styles.label)}>{item?.label}</Typography>
					<div className="flexRow alignCenter gap8">
						<Typography variant="caption" className={cx('lineHeight1', styles.caption)}>
							{item?.ingredientCount || item?.ingredients?.length} {t('ingredients')}
						</Typography>
						<Typography variant="caption" className={cx('lineHeight1', styles.caption)}>
							{item?.scenarioCount} {t('éco-conception')}(s)
						</Typography>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default TagLabel;
