import { useState } from 'react';

import { css, cx } from '@emotion/css';
import Check from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { useToggle } from 'react-use';

import Popover from '../../../../../apps/front/src/components/Popover';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import BButton from '../BButton';

const styles = {
	check: css({
		color: siteColors.green500,
	}),
	popoverContainer: css({
		padding: '24px',
		width: '294px',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFFFFF',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
	}),

	titlePopover: css({
		textAlign: 'left !important',
		fontSize: '16px !important',
		fontWeight: '600 !important',
	} as any),

	content: css({
		gap: 20,
		marginTop: '16px',
	}),
	button: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',
		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			// color: '#C74600',
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),
};

const DeleteButton = (props: any) => {
	const [isLoading, setIsLoading] = useState(false);
	const { value, row, column } = props;
	const [isClose, toggleClose] = useToggle(true);

	return (
		<Popover
			isClose={isClose}
			trigger={
				<div>
					<BButton
						variant="tertiary"
						iconLeft={column?.icon || <Check className={styles.check} />}
						label=""
						className={cx(props.buttonClassName)}
					/>
				</div>
			}
			content={
				<div className={styles.popoverContainer}>
					<Typography className={styles.titlePopover}>
						{row?.original?.deleted === true
							? column?.messageConfirmDelete
							: column?.messageConfirm
							? column.messageConfirm
							: `Supprimer l'utilisateur ${value?.firstName ?? ''} ${value?.lastName ?? ''} ?`}
					</Typography>
					{(row?.original?.deleted === true || !column?.messageConfirmDelete) && (
						<Typography className="alignLeft">
							{column?.subMessageConfirm ?? 'Cette action est irréversible'}
						</Typography>
					)}

					<div className={cx('flexColumn width100', styles.content)}>
						<BButton
							className={cx(styles.button)}
							variant="tertiary"
							label={
								isLoading
									? column?.messageConfirmDelete
										? 'Confirmer'
										: 'Confirmer la suppression'
									: column?.messageConfirmDelete
									? 'Confirmer'
									: 'Confirmer la suppression'
							}
							onClick={() => {
								setIsLoading(true);

								if (column?.messageConfirmDelete) {
									column?.callback(row?.original);
								} else {
									column?.callback(row?.original?.objectId || row?.original);
								}

								setTimeout(() => {
									toggleClose();
								}, 500);
							}}
						/>
					</div>
				</div>
			}
		/>
	);
};

export default DeleteButton;
