import { z } from 'zod';

export const ClientFormValidationSchema = z.object({
	name: z
		.string({ required_error: 'Raison sociale obligatoire' })
		.min(1, 'Raison social  obligatoire')
		.max(255, 'Raison social  doit avoir 255 caractères au maximum'),
	siretNumber: z.string().min(1, 'Numéro du SIRET  obligatoire')/* .optional() */,
	address: z.string().min(1, 'Adresse obligatoire')/* .optional() */,
	postalCode: z.string().optional(),
	objectId: z.string().optional(),
	city: z.string().optional(),
	interlocutorName: z.string().optional(),
	interlocutorFirstName: z.string().optional(),
	interlocutorEmail: z.string({ required_error: 'email obligatoire' }).email('Mettre une adresse email valide').optional(),

});

export type ClientFormInput = z.infer<typeof ClientFormValidationSchema>;
