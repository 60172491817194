import { css, cx } from '@emotion/css';
import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import { X } from 'lucide-react';
import React from 'react';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../lib/colors';
import PackagingsFilter from '../../../../packagings/_/PackagingsFilter';
// import IngredientFilter from '../../../../Packagings/IngredientFilter';

type Props = {
	open: DialogProps['open'];
	onClose?: DialogProps['onClose'];
	children: React.ReactNode;
	// onScroll?: DialogContentProps['onScroll'];
	title: string;
	subTitle: string;
};

const filterContainerStyle = css({
	// padding: '0!important',
	// flexGrow: 1,
	// marginBottom: 18,
	'& > div': {
		padding: '0!important',
		// width: '90%',
		// width: 'unset',
	},
});

const PackagingsModalSelect = ({ open, onClose, children, title, subTitle }: Props) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={onClose}
			scroll="paper"
			// aria-labelledby="scroll-dialog-title"
			// aria-describedby="scroll-dialog-description"
			PaperProps={{
				sx: {
					// background: 'blue',
					// padding: 0,
					borderRadius: 3,
				},
			}}
			// onScroll={(e) => {
			// 	console.log('-----------------------');
			// }}
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle id="scroll-dialog-title" component="div" sx={{ padding: '32px', position: 'relative' }}>
				{/* <Typography variant="h2" sx={{ color: siteColors.primary, mb: 3 }}>
					{t('add-custom-button', {
						ingredient: _.toLower(t('ingredient')),
						an_ingredient: t('an_ingredient'),
					})}
				</Typography> */}
				<div css={{ marginBottom: 16 }}>
					<Typography variant="h2" sx={{ color: siteColors.primary }}>
						{title}
						{/* {t('add-custom-button', {
						ingredient: _.toLower(t('ingredient')),
						an_ingredient: t('an_ingredient'),
					})} */}
					</Typography>

					<Typography>{subTitle}</Typography>
				</div>

				<div className={cx('flexRow alignCenter', filterContainerStyle)}>
					{/* <IngredientFilter withMore={false} /> */}
					<PackagingsFilter withMore={false} /* showFacetFilter={false} */ />
				</div>
				{/* <div className={cx('flexRow alignCenter', filterContainerStyle)}>
					<ProductsFilter withMore={false} />
					<SelectYear />
				</div> */}
				<IconButton
					sx={{ position: 'absolute', right: 10, top: 10 }}
					onClick={(e) => {
						onClose?.(e, 'backdropClick');
					}}
				>
					<X size={20} />
				</IconButton>
			</DialogTitle>
			<DialogContent
				dividers={/* scroll === 'paper' */ true}
				sx={{
					paddingTop: 0,
				}}
				// sx={{ padding: 0 /* , overflowY: 'scroll', height: '100px' */ }}
				// onScroll={onscroll as never}
			>
				{children}
				{/* <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
					{[...new Array(50)]
						.map(() => {
							return `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`;
						})
						.join('\n')}
				</DialogContentText> */}
			</DialogContent>
			{/* <DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={onClose}>Subscribe</Button>
			</DialogActions> */}
		</Dialog>
	);
};

export default PackagingsModalSelect;
