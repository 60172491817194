import React, { ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles/createTheme';
import useTheme from '@mui/material/styles/useTheme';
import { siteColors } from '../../lib/colors';

const classes = (type: 'info' | 'success' | 'error' | 'warning', theme: Theme) => {
	const { palette } = theme;

	return {
		container: {
			background: type === 'error' ? '#FFE4CB' : palette.common.white,
			border: `1px solid ${siteColors.status[type]}`,
			boxShadow: `inset 4px 0px 0px ${siteColors.status[type]}`,
			borderRadius: '0px 8px 8px 0px',
			display: 'flex',
			alignItems: 'center',
			gap: 26,
			padding: 25,
			fontWeight: 500,
			fontSize: 16,
			justifyContent: 'space-between',
		},
	};
};

interface AlertStaticProps {
	icon?: React.ReactNode;
	type: 'info' | 'success' | 'error';
	text: ReactNode;
	className?: string;
}

const AlertStatic = ({ icon, type, text, className }: AlertStaticProps) => {
	const theme = useTheme();
	const styles = classes(type, theme);
	const [isChanged, setIsChanged] = React.useState(true);

	return (
		<>
			{isChanged && (
				<Box /* variant="inherit" */ css={styles.container} className={className}>
					<Typography className="flexRow noWrap alignCenter " css={{ gap: 20, display: 'flex', flexWrap: 'nowrap' }}>
						{icon}
						{text}
					</Typography>

					<IconButton
						onClick={() => {
							return setIsChanged(false);
						}}
						color="inherit"
						aria-label="visible"
						css={{
							background: 'transparent',
							':hover': {
								background: 'transparent',
							},
							'& path': {
								fill: 'black',
							},
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			)}
		</>
	);
};

export default AlertStatic;
