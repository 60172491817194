import { css, cx } from '@emotion/css';
import { HelpCircle, Recycle } from 'lucide-react';

import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import Skeleton from '../../../components/Skeleton';
import Typography from '../../../components/Typography';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

const styles = {
	container: css({
		padding: 24,
		border: `1px solid ${siteColors.grey5}`,
		borderRadius: 16,
		minHeight: 50,
	}),
	value: css({
		fontSize: '24px !important',
		lineHeight: '1.2 !important',
		color: `${siteColors.grey8} !important`,
	}),
	label: css({
		lineHeight: '1.2 !important',
		color: `${siteColors.grey8} !important`,
	}),
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		minHeight: 50,
		flex: 1,
	} as any),
	popoverContent: css({
		padding: '16px',
		width: '220px !important',
	}),
};

type KPICardProps = {
	icon: React.ReactNode;
	className?: string;
	label?: string;
	value: React.ReactNode;
	isLoading?: boolean;
	code?: string;
};

const KPICard = ({ className, icon, value, label, isLoading, code }: KPICardProps) => {
	const { t } = useTranslation();
	return (
		<div className={cx('flexRow alignCenter gap16', styles.container, className)}>
			{isLoading ? (
				<Skeleton width="100%!important" className={styles.skeleton}></Skeleton>
			) : (
				<>
					{icon}
					<div className="flexColumn gap4">
						{value}
						<Typography className={cx(styles.label)}>{label}</Typography>
					</div>{' '}
				</>
			)}
		</div>
	);
};

export default KPICard;
