import { useEffect, useState } from 'react';

type Props = {
	condition: boolean;
	delay?: number;
	// additionalEffectParams?: any[];
}

export const useInitialSkeleton = ({ condition, delay = 500 }: Props) => {
	const [initial, setSkeletonInitial] = useState<boolean>(true);

	useEffect(() => {
		if (!condition) {
			setTimeout(() => {
				setSkeletonInitial(false);
			}, delay);
			return;
		}
	}, [condition, delay]);

	return { initial, setSkeleton: setSkeletonInitial };
};

export const useSkeleton = ({ condition, delay = 500 }: Props) => {
	const { initial, setSkeleton } = useInitialSkeleton({ condition, delay });

	return { skeleton: initial || condition, setSkeleton };
};
