import { ArrayParam, JsonParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

export const useSearchQueryParams = () => {
	return useQueryParams({
		input: withDefault(StringParam, ''),
		page: withDefault(NumberParam, 1),
		size: withDefault(NumberParam, 100),
		facetFilters: JsonParam,
		//direction: NumberParam, // @todo what is it for ?
		supplierIds: JsonParam,
		categoryFilters: JsonParam,
		objectId: withDefault(StringParam, ''),
		groupField: withDefault(StringParam, ''),
		codeIngCmaps: withDefault(StringParam, ''),
		company: withDefault(StringParam, ''),
		ingredientId: withDefault(StringParam, ''),
		productId: withDefault(StringParam, ''),
		packagingId: withDefault(StringParam, ''),
		direction: withDefault(StringParam, ''),
		orderBy: withDefault(StringParam, ''),
		fromPath: withDefault(StringParam, ''),
		uid: withDefault(StringParam, ''),
		codeEmbCmapsList: JsonParam,
	});
};
