import { useEffect, useState } from 'react';

import { BarChart2 } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetIngredientSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetIngredientSheet';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import IndicatorsBlockLight, { indicatorConfigItems } from '../../../components/synthese/IndicatorsBlockLight';
import Card from '../../../components/widget/Card';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import IngredientModel from '../../../models/Ingredient.model';
import { FULFILLED_MODELIZATION_TYPE } from '../../../utils/supplier';

const IngredientIndicatorsBlock = () => {
	const { indicator } = useApp();
	const params = useParams();
	const { t } = useTranslation();
	const [ingredientObject, setIngredientObject] = useState<IngredientModel>();

	const { data, isLoading, isError, error } = useGetIngredientSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.ingredientId!,
		// period: 2023,
	});

	useEffect(() => {
		if (!data) return;
		const { ingredient } = data;
		console.log('ingredient', ingredient);

		setIngredientObject(new IngredientModel(ingredient));
	}, [data]);

	return (
		<Card
			title={
				indicatorConfigItems.get(indicator)?.indicatorTitle
					? t(indicatorConfigItems.get(indicator)?.indicatorTitle as any)
					: t('Indicateurs carbone')
			}
			icon={<BarChart2 color={siteColors.grey700} size={20} />}
			sx={{ padding: 0, marginBottom: pxToRem(32), flexWrap: 'nowrap', width: '100%' }}
			withBorder={false}
			center={false}
		>
			<IndicatorsBlockLight
				indicator={indicator}
				indicatorDatas={{
					impact: {
						// value: 2340,
						value: displayValue(ingredientObject?.getImpactByIndicator(indicator, true) as number, undefined, 0),
						fullFilledPercent: ingredientObject?.getSupplierFullFilledPercent(),
						suppliersCount: {
							fulfilled: ingredientObject?.getSuppliersCount(FULFILLED_MODELIZATION_TYPE),
							total: ingredientObject?.getSuppliersCount(),
						},
					},
					intensity: {
						value: displayValue(ingredientObject?.getIntensityByIndicator(indicator, true) as number),
						fullFilledPercent: ingredientObject?.getSupplierFullFilledPercent(),
						suppliersCount: {
							fulfilled: ingredientObject?.getSuppliersCount(FULFILLED_MODELIZATION_TYPE),
							total: ingredientObject?.getSuppliersCount(),
						},
					},
				}}
			/>
		</Card>
	);
};

export default IngredientIndicatorsBlock;
