import { z } from 'zod';

export const QuoteFormValidationSchema = z.object({
	name: z
		.string({ required_error: 'Nom du devis obligatoire' })
		.min(1, 'Nom du devis obligatoire')
		.max(255, 'Le nom du devis doit avoir 255 caractères au maximum'),
	clientId: z.string({ required_error: 'Client obligatoire' }),
	toDuplicateQuoteId: z.string().optional(),
});

export type QuoteFormInput = z.infer<typeof QuoteFormValidationSchema>;
