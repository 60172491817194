import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import 'dayjs/locale/fr';

import MetaDataCard from '../../../components/card/MetaDataCard';
import { useGetPackagingSheetTimePeriod } from '../../../lib/react-query/features/packaging/packaging.hooks';

dayjs.locale('fr');

const PackagingMetaDataBlock = (/* props: Props */) => {
	const params = useParams();

	const { data, isLoading, isError, error } = useGetPackagingSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.packagingId!,
		// period: 2023,
	});

	return <MetaDataCard importedOn={data?.createdAt} updatedAt={data?.updatedAt} />;
};

export default PackagingMetaDataBlock;
