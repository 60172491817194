import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RotateCcw } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { resetScenarioAction } from '@carbonmaps/ui/actions/simulation.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import Popover from '../../../components/Popover';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';

const styles = {
	width300: css({
		width: 250,
	}),
	dropItemButton: css({
		gap: 8,
		fontSize: '14px',
		fontWeight: 400,
		cursor: 'pointer',
		padding: '8px 16px 8px 16px',
		// background: 'transparent!important',
		color: cn(siteColors.text, '!important'),
		paddingInline: 0,
	}),
	check: css({
		color: siteColors.green500,
	}),
	popoverContainer: css({
		padding: '24px',
		width: '294px',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFFFFF',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
	}),

	titlePopover: css({
		textAlign: 'left !important',
		fontSize: '16px !important',
		fontWeight: '600 !important',
	} as any),

	content: css({
		gap: 20,
		marginTop: '16px',
	}),
	button: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',

		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			// color: '#C74600',
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),
};

const ResetBlock = () => {
	const [isResetDialogOpen, toggleResetDialog] = useToggle(true);
	const params = useParams();
	const queryClient = useQueryClient();

	// translation
	const { t } = useTranslation();

	// ---- reset scenario ---- //
	const { mutate: resetScenario, isLoading: isLoadingUpdate } = useMutation({
		mutationKey: ['resetScenario'],
		mutationFn: resetScenarioAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
			toggleResetDialog();
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
		},
	});

	const resetData = () => {
		resetScenario({ simulationId: params.simulationId });
	};

	return (
		// <div>
		<Popover
			isClose={isResetDialogOpen}
			anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
			trigger={
				<div
				// className={styles.width300}
				>
					<BButton
						className={styles.dropItemButton}
						variant="secondary"
						iconLeft={<RotateCcw className="grey700" size={20} />}
						label={t('simulation-reset-all')}
					/>
				</div>
			}
			content={
				<div className={styles.popoverContainer}>
					<Typography
						textAlign={'left'}
						sx={{
							fontWeight: 600,
							fontSize: 16,
						}}
					>
						{/* Êtes-vous sûr de vouloir tout réinitialiser? */}
						{t('simulation-reset-all-confirm-message')}
					</Typography>

					{/* <Typography textAlign={'left'}>Cette action ne pas peut être annulée</Typography> */}

					<div className={cx('flexColumn width100', styles.content)}>
						<BButton
							className={styles.button}
							variant="tertiary"
							label={t('simulation-reset-all')}
							onClick={resetData}
						/>
					</div>
				</div>
			}
		/>
		// </div>
	);
};

export default ResetBlock;
