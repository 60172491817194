import { siteColors } from '../../../apps/front/src/lib/colors';

// ---- ERROR CODE PRODUCT ---- //
export const ERROR_GAMME = 'P001'; // error gamme
export const ERROR_EMPTY_TONNAGE = 'P002'; // tonnage not found
export const ERROR_TONNAGE_NULL = 'P003'; // tonnage invalid
export const UID_ERROR = 'P004';
export const LABEL_ERROR = 'P005';

// ---- audit type item ---- //
export const PRODUCT = 'product';
export const INGREDIENT = 'ingredient';
export const PACKAGING = 'packaging';

// ----  type error config ---- //
export const UID_LABEL_ERROR = 'Label manquant / ID manquant';
export const VOLUME_ERROR = 'Erreur volume';
export const COMPOSITION_ERROR = 'Erreur composition';
// ---- percent compostion ---- //
export const LESS_THAN_PERCENT_COMPOSITION = 98;
export const GREATER_THAN_PERCENT_COMPOSITION = 102;

export const ERROR_COLOR_MAP = new Map();
ERROR_COLOR_MAP.set(UID_LABEL_ERROR, siteColors.grey400);
ERROR_COLOR_MAP.set(VOLUME_ERROR, '#C70000');
ERROR_COLOR_MAP.set(COMPOSITION_ERROR, siteColors.primary);

// ---- config color error ---- //
export const ERROR_UID_LABEL_COLOR = '#ED9D38';
export const ERROR_VOLUME_COLOR = '#ED9D38';
export const ERROR_COMPOSITION_COLOR = '#C74600';

const label_uid_error_key = 'label_or_uid_error';
const volume_error_key = 'tonnage_error_percent';
const composition_error_key = 'composition_error_percent';

// ---- product config details error ---- //
export const ERROR_PRODUCT_CONFIG = new Map();

ERROR_PRODUCT_CONFIG.set(ERROR_GAMME, {
	content: 'product_range_missing',
	contentText: 'product_range_missing',
	id: ERROR_GAMME,
	tooltip: 'product_range_missing_tooltip',
	errorType: UID_LABEL_ERROR,
	color: ERROR_UID_LABEL_COLOR,
	errorKey: label_uid_error_key,
});

ERROR_PRODUCT_CONFIG.set(ERROR_EMPTY_TONNAGE, {
	content: 'product_tonnage_missing',
	contentText: 'product_tonnage_missing',
	id: ERROR_EMPTY_TONNAGE,
	tooltip: 'product_tonnage_missing',
	errorType: VOLUME_ERROR,
	color: ERROR_VOLUME_COLOR,
	errorKey: volume_error_key,
});

ERROR_PRODUCT_CONFIG.set(ERROR_TONNAGE_NULL, {
	content: 'product_tonnage_missing',
	contentText: 'product_tonnage_missing',
	id: ERROR_TONNAGE_NULL,
	tooltip: 'product_tonnage_missing',
	errorType: VOLUME_ERROR,
	color: ERROR_VOLUME_COLOR,
	errorKey: volume_error_key,
});

ERROR_PRODUCT_CONFIG.set(UID_ERROR, {
	content: 'product_id_error',
	contentText: 'product_id_error',
	id: UID_ERROR,
	tooltip: 'product_id_error_tooltip',
	errorType: UID_LABEL_ERROR,
	color: ERROR_UID_LABEL_COLOR,
	errorKey: label_uid_error_key,
});

ERROR_PRODUCT_CONFIG.set(LABEL_ERROR, {
	content: 'product_label_error',
	contentText: 'product_label_error',
	id: LABEL_ERROR,
	tooltip: 'product_label_error_tooltip',
	errorType: UID_LABEL_ERROR,
	color: ERROR_UID_LABEL_COLOR,
	errorKey: label_uid_error_key,
});

// --------------------------------------------------------------------- //
// ------------------- INGREDIENT CONFIG ------------------------------- //
// --------------------------------------------------------------------- //
export const PROPORTION_ERROR = 'I001';
export const COMPOSITION_98_ERROR = 'I002';
export const COMPOSITION_102_ERROR = 'I003';
export const PRODUCT_INGREDIENT_ERROR = 'I004';
export const UID_INGREDIENT_ERROR = 'I005';
export const LABEL_INGREDIENT_ERROR = 'I006';

export const INGREDIENT_ERROR_CONFIG = new Map();

INGREDIENT_ERROR_CONFIG.set(PROPORTION_ERROR, {
	id: PROPORTION_ERROR,
	content: 'empty_composition',
	contentText: 'empty_composition',
	tooltip: 'empty_composition_tooltip',
	errorType: COMPOSITION_ERROR,
	color: ERROR_COMPOSITION_COLOR,
	errorKey: composition_error_key,
});

INGREDIENT_ERROR_CONFIG.set(COMPOSITION_98_ERROR, {
	id: COMPOSITION_98_ERROR,
	content: 'composition_less_than_98_error',
	contentText: 'composition_less_than_98_error',
	tooltip: 'composition_less_than_98_error_tooltip',
	errorType: COMPOSITION_ERROR,
	color: ERROR_COMPOSITION_COLOR,
	errorKey: composition_error_key,
});

INGREDIENT_ERROR_CONFIG.set(COMPOSITION_102_ERROR, {
	id: COMPOSITION_102_ERROR,
	content: 'composition_greater_than_102_error',
	contentText: 'composition_greater_than_102_error',
	tooltip: 'composition_greater_than_102_error_tooltip',
	errorType: COMPOSITION_ERROR,
	color: ERROR_COMPOSITION_COLOR,
	errorKey: composition_error_key,
});

INGREDIENT_ERROR_CONFIG.set(PRODUCT_INGREDIENT_ERROR, {
	id: PRODUCT_INGREDIENT_ERROR,
	content: 'missing_ingredient_error',
	contentText: 'missing_ingredient_error',
	tooltip: 'missing_ingredient_error_tooltip',
	errorType: COMPOSITION_ERROR,
	color: ERROR_COMPOSITION_COLOR,
	errorKey: composition_error_key,
});

INGREDIENT_ERROR_CONFIG.set(UID_INGREDIENT_ERROR, {
	id: UID_INGREDIENT_ERROR,
	content: 'id_ingredient_error',
	contentText: 'id_ingredient_error',
	tooltip: 'id_ingredient_error_tooltip',
	errorType: UID_LABEL_ERROR,
	color: ERROR_UID_LABEL_COLOR,
	errorKey: label_uid_error_key,
});

INGREDIENT_ERROR_CONFIG.set(LABEL_INGREDIENT_ERROR, {
	id: LABEL_INGREDIENT_ERROR,
	content: 'missing_ingredient_label_error',
	contentText: 'missing_ingredient_label_error',
	tooltip: 'missing_ingredient_label_error_tooltip',
	errorType: UID_LABEL_ERROR,
	color: ERROR_UID_LABEL_COLOR,
	errorKey: label_uid_error_key,
});

// ----------------------------------------------------------------- //
// ------------------- PACKAGING CONFIG ---------------------------- //
// ----------------------------------------------------------------- //
export const ERROR_COMPOSITION = 'E001';
export const UID_PACKAGING_ERROR = 'E002';
export const LABEL_PACKAGING_ERROR = 'E003';

export const PACKAGING_ERROR_CONFIG = new Map();

PACKAGING_ERROR_CONFIG.set(UID_PACKAGING_ERROR, {
	id: UID_PACKAGING_ERROR,
	content: 'error_packaging_id',
	contentText: 'error_packaging_id',
	tooltip: 'error_packaging_id_tooltip',
	errorType: UID_LABEL_ERROR,
	color: ERROR_UID_LABEL_COLOR,
	errorKey: label_uid_error_key,
});

PACKAGING_ERROR_CONFIG.set(LABEL_INGREDIENT_ERROR, {
	id: LABEL_INGREDIENT_ERROR,
	content: 'error_packaging_label',
	contentText: 'error_packaging_label',
	tooltip: 'error_packaging_label_tooltip',
	errorType: UID_LABEL_ERROR,
	color: ERROR_UID_LABEL_COLOR,
	errorKey: label_uid_error_key,
});

PACKAGING_ERROR_CONFIG.set(ERROR_COMPOSITION, {
	id: ERROR_COMPOSITION,
	content: 'error_packaging_weight',
	contentText: 'error_packaging_weight',
	tooltip: 'error_packaging_weight_tooltip',
	errorType: COMPOSITION_ERROR,
	color: ERROR_COMPOSITION_COLOR,
	errorKey: composition_error_key,
});

// ---- config for mapping audit filename ----- //
export const AUDIT_CONFIG = new Map();
AUDIT_CONFIG.set(PRODUCT, {
	fileName: 'Erreurs_produits_finis',
	emptyData: 'product-table-empty-data',
});
AUDIT_CONFIG.set(INGREDIENT, {
	fileName: 'Erreurs_ingredients',
	emptyData: 'audit-ingredient-empty-data',
});

AUDIT_CONFIG.set(PACKAGING, {
	fileName: 'Erreurs_emballages',
	emptyData: 'audit-packaging-empty-data',
});

// ----- ingredient criteria ----- //
export const MODELIZATION_TYPES = [
	'N1_Inferred',
	'N1_Standard',
	'n1_inferred',
	'n1_standard',
	'Custom_Ingredients',
	'custom_ingredients',
];

// "typeModelizationIngredient" : "N1_Standard",
// "typeModelizationPackaging" : "N1_Typical_Packaging",
export const MODELIZATION_PACKAGING_TYPES = [
	'n1_standard_packaging',
	'N1_Standard_Packaging',
	'Custom_Packaging',
	'custom_packaging',
];
