import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { HelpCircle } from 'lucide-react';
import { createSearchParams } from 'react-router-dom';

import { ReactComponent as SegmentIcon } from '@carbonmaps/media/icons/analyse_grey.svg';
import { FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { getFacetLabel } from '@carbonmaps/shared/utils/utils';
import { TableComponent, getStickyHeaderStyle } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import TableHeaderCell from '../../components/table/TableHeaderCell';
import ImpactCarbonePopover from '../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../components/table/popover/WaterImpactPopover';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useSkeleton } from '../../hooks/useSkeleton';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { CARBONE_INDICATOR } from '../../utils/constants';

import { useYearSelection } from '../../hooks/useImpactYear';
import { cn, siteColors } from '../../lib/colors';
import { useAnalyseSetup, useFindAnalyseDataTable, useGetAnalyseData } from './analyse.hooks';

const styles = (theme: any) => {
	return {
		containerTable: {
			table: {
				border: 'none!important',
			},

			'.bordered td, .bordered th': {
				borderLeft: 'none!important',
				// background: theme.palette.common.white,
				borderBottom: `1px solid ${theme.palette.grey[400]}`,
			},
			'.bordered th': {
				background: theme.palette.common.white,
			},
			'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button':
				{
					justifyContent: 'flex-end!important',
					paddingRight: '0!important',
				},
		},
	};
};

const classNames = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),

	label: css({
		color: cn(siteColors.text, '!important'),
		fontWeight: '600 !important',
		fontSize: '14px !important',
		marginBottom: '0px !important',
	}),
};

const formatThenSetData = (res: any, categorySelected: string, paramsUrlString: any) => {
	if (!res?.length) return [];
	return res.map((item: any) => {
		return {
			...item,
			link: item?.label
				? `${FRONT_PATH_NAMES.analyse}/${categorySelected}/${item._id}/synthesis${paramsUrlString}`
				: `${FRONT_PATH_NAMES.analyse}/${categorySelected}/no-category/synthesis${paramsUrlString}`,
			// isDisabled: !item?.label,
		};
	});
};

const AnalyseTable = () => {
	const theme = useTheme();

	// translation

	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();

	// ---- user info to specify facets ---- //
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const className = useMemo(() => {
		return styles(theme);
	}, [theme]);

	const { indicator } = useApp();
	const { categorySelected } = useAnalyseSetup();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	// query params
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	const [resetPage, setResetPage] = useState(0);

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Analyse' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		setResetPage(1);
	}, [searchQueryParams.input, searchQueryParams.facetFilters]);

	const [sortingBy, setSortingBy] = useState<any>();

	// const { data: results, isLoading: isSearchLoading } = useGetAnalyseData({
	// 	searchQueryParams,
	// 	categorySelected,
	// 	indicator,
	// 	sortingBy,
	// });
	const {
		result: { data: results, isLoading: isSearchLoading },
	} = useFindAnalyseDataTable({
		searchQueryParams,
		categorySelected,
		indicator,
		sortingBy,
	});

	const { skeleton: tableSkeleton } = useSkeleton({ condition: isSearchLoading });

	const fetchIdRef = useRef(0);

	const updateQueryParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, sortingBy, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setSearchQueryParams({
					...searchQueryParams,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				} as any);
				setSortingBy(sortingBy);
			}
		},
		[searchQueryParams, setSearchQueryParams],
	);

	const onFetchData = useCallback(
		(params: any) => {
			updateQueryParams({
				...params,
				input: searchQueryParams.input,
				facetFilters: searchQueryParams.facetFilters,
				// sortingBy: searchQueryParams.sortingBy,
				sortingBy,
			});
		},
		[searchQueryParams.facetFilters, searchQueryParams.input, sortingBy, updateQueryParams],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			updateQueryParams({ ...searchQueryParams, sortingBy: value, withCount: false });
			// setSortingBy(value);
		},
		[searchQueryParams, updateQueryParams],
	);

	const columns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							valueType="string"
							label={getFacetLabel(categorySelected, authData?.session.company)}
						/>
					);
				},

				accessor: 'label',
				component: 'TagLabel',
				editable: true,
				sortDescFirst: true,
				type: 'string',
				icon: <SegmentIcon />,
				props: {
					gap: 8,
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},
					className: classNames.label,
					emptyValue: t('Sans catégorie'),
					onClick: (row: any) => {
						trackEvent('Analyse Click Segment', {
							Segment: categorySelected,
							Category: row?.label,
						});
					},
				},
			},

			...(indicator === CARBONE_INDICATOR
				? [
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })}
										variant="unit"
										unit={
											<Typography
												component="p"
												variant="caption"
												color={theme.palette.grey[700]}
												textTransform="uppercase"
												lineHeight={1.5}
											>
												%
											</Typography>
										}
									/>
								);
							},

							accessor: 'carbonImpactPercent',
							editable: true,
							sortDescFirst: true,
							component: 'PercentField',
							type: 'percent',
							props: {
								popoverComponent: ImpactCarbonePopover,
								popoverClassName: classNames.popoverImpactStyle,
								className: classNames.percent,
								emptyValue: <HelpCircle color={theme.palette.primary.main} />,
							},
						},
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.INTENSITY)}
										variant="measure"
										measure="carbonIntensityKg"
									/>
								);
							},
							accessor: 'carbonIntensity', // accessor is the "key" in the data
							editable: true,
							sortDescFirst: true,
							component: 'IntensityField',
							type: 'number',
						},
				  ]
				: [
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })}
										variant="unit"
										unit={
											<Typography
												component="p"
												variant="caption"
												color={theme.palette.grey[700]}
												textTransform="uppercase"
												lineHeight={1.5}
											>
												%
											</Typography>
										}
									/>
								);
							},
							accessor: 'waterImpactPercent',
							editable: true,
							sortDescFirst: true,
							component: 'PercentField',
							type: 'percent',
							props: {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
								popoverComponent: WaterImpactPopover,
								popoverClassName: classNames.popoverImpactStyle,
								className: classNames.percent,
								emptyValue: <HelpCircle color={theme.palette.indicator.water.main} />,
							},
						},
						{
							Header: (props: any) => {
								return (
									<TableHeaderCell
										column={props.column}
										label={t(WORDING_TERMS.INTENSITY)}
										variant="measure"
										measure="waterIntensity"
									/>
								);
							},
							accessor: 'waterIntensity',
							component: 'IntensityField',
							editable: true,
							sortDescFirst: true,
							type: 'number',
							props: {
								color: siteColors.water500,
							},
						},
				  ]),

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('soldItems')}
							variant="unit"
							unit={
								<Typography
									component="p"
									variant="caption"
									color={theme.palette.grey[700]}
									textTransform="uppercase"
									lineHeight={1.5}
								>
									UVC
								</Typography>
							}
						/>
					);
				},
				accessor: 'soldItems',
				editable: true,
				sortDescFirst: true,
				component: 'SoldItemsField',
				type: 'number',
			},
			{
				// Header: 'Produits',
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('products')} />;
				},
				accessor: 'productCount',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'number',
			},
			{
				// Header: 'Fournisseurs',
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('suppliers')} sortable={false} />;
				},
				accessor: 'supplierCount',
				editable: true,
				sortDescFirst: true,
				disableSortBy: true,
				component: 'SimpleCell',
				type: 'number',
			},
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		t,
		indicator,
		theme.palette.primary.main,
		theme.palette.indicator.water.main,
		theme.palette.grey,
		selectedYear,
		categorySelected,
		// authData?.session.company,
		// trackEvent,
	]);

	let paramsUrlString = '';
	if (searchParams) paramsUrlString = `?${searchParams}`;

	return (
		<TableComponent
			skeleton={tableSkeleton}
			loading={false}
			fetchData={onFetchData}
			pageCount={results?.meta?.last_page || 0}
			noDataMessage={t('analyze-table-empty-data')}
			pageSize={searchQueryParams.size}
			columns={columns}
			resetPage={resetPage}
			data={formatThenSetData(results?.analyseData || [], categorySelected, paramsUrlString)}
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			setSelectedRow={() => {}}
			onSortingColumn={handleSortingTable}
			addStyles={className.containerTable}
			manualSortBy
			stickyHeader
			stickyHeaderStyles={stickyHeaderStyle}
			transFunc={t}
			onNextClick={() => {
				return trackEvent('Analyse Next Page', {
					Segment: categorySelected,
				});
			}}
		/>
	);
};

const stickyHeaderStyle = getStickyHeaderStyle(1391);

export default AnalyseTable;
