import { css, cx } from '@emotion/css';
import _ from 'lodash';

import CardTitle from '../../../../../components/dashboard/v2/CardTitle';

import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';
import PolarChart from '../../../../../components/PolarChart';
import Skeleton from '../../../../../components/Skeleton';
import CardSupplier from '../../../../../components/supplier/CardSupplier';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';
import { useFindGeneralRSERadarChartData, useRseQFormTabQueryParam } from '../hooks/rse.hooks';
// import { useFindSuppliersPolarChart } from '../../../../../lib/react-query/features/supplier/supplier.hooks';
import { useParams } from 'react-router-dom';
import { useFindOneSupplierRSERadarChartData, useSupplierSetup } from '../../../../supplier/supplier.hooks';
import { useGetInfoNoteData } from '../../../../supplier/tabs/rse/hooks/rse.hooks';

const styles = {
	content: css({
		minHeight: 321,
		alignItems: 'center !important',
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
		padding: '24px',
	}),

	emptyText: css({
		color: cn(siteColors.grey700, '!important'),
		textAlign: 'center',
		width: '100%',
		height: '200px',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	}),
	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		flex: 1,
	} as any),
};

const Section = ({ isGeneralView = false, fullWidth = false }) => {
	// params filters
	// const [searchQueryParams] = useSearchQueryParams();
	const { t, i18n } = useTranslation();
	const [questionFormId] = useRseQFormTabQueryParam();

	const { supplierObject: supplier } = useSupplierSetup();
	const { data: rseObject, isLoading: isLoadingInfoNote } = useGetInfoNoteData({
		questionFormId: _.toString(questionFormId),
		enabled: !isGeneralView,
	});

	// ---- fetch section data ---- //
	const {
		// result: { data, isLoading },
		data: dataAvg,
		isLoading: isLoadingRadarChartData,
	} = useFindGeneralRSERadarChartData({
		questionFormId: _.toString(questionFormId),
	});

	const isLoading = (!isGeneralView && isLoadingInfoNote) || isLoadingRadarChartData;

	const params = useParams();
	const { data: dataDetails /* , isLoading: isLoadingDataPerformanceRse */ } = useFindOneSupplierRSERadarChartData({
		supplierId: params.supplierId || '',
		questionFormId: _.toString(questionFormId),
	});

	// const locale = getIsoCountryCode(i18n.language);
	const locale = isGeneralView ? getIsoCountryCode(i18n.language) : _.get(rseObject, 'languageCode', 'fr');

	// console.log('******', dataPerformanceRse?.sections);
	// Step 1: Create a map from code to index in dataAvg using a for loop
	const codeOrderMap: any = {};
	for (let i = 0; i < (dataAvg || []).length; i++) {
		codeOrderMap[dataAvg[i].code] = i;
	}

	// If you need to sort dataAvg too, but it looks like it's already sorted as needed.
	const sortedDataAvg = _.sortBy(dataAvg, (item) => codeOrderMap[item.code]);

	// Step 2: Sort data based on the order in dataAvg using the codeOrderMap
	const sortedDataDetailsArray = _.sortBy(dataDetails?.sections || [], (item) => codeOrderMap[item.code]);

	return (
		<CardSupplier
			title={<CardTitle title={t('section-supplier-card-title')} />}
			className={cx('flex1', fullWidth ? 'width100' : '')}
		>
			<div className={cx('flexColumn width100 gap24', styles.content)}>
				{isLoading && <Skeleton width="100%!important" className={styles.skeleton}></Skeleton>}

				{!isLoading && (
					<PolarChart
						dataAvg={formatData(sortedDataAvg || [], locale)}
						data={
							isGeneralView
								? formatData(sortedDataAvg || [], locale)
								: (formatData(sortedDataDetailsArray || [], locale) as any)
						}
						withAvg={!isGeneralView}
						withLegend={!isGeneralView}
						currentSupplierName={_.get(supplier, 'supplier.name', '---')}
					/>
				)}
			</div>
		</CardSupplier>
	);
};

export default Section;

const formatData = (data: any, locale: any) => {
	if (!data) return [];

	// const maxValue = (_.maxBy(data, 'value') as any)?.value;

	const iData = data.map((item: any) => {
		let label = item.labelFr;

		if (locale === 'en') {
			label = item.labelEn;
		} else if (locale === 'it') {
			label = item.labelIt;
		} else if (locale === 'es') {
			label = item.labelEs;
		}

		return {
			label,
			// value: (item.value / maxValue) * 100, //pour avoir un percent
			value: item.value,
		};
	});
	// .filter((item: any) => {
	// 	return item.label;
	// });

	return iData;
	// .filter((item: any) => item.value > 0);
};
