import _ from 'lodash';

export const getDataPieChart = (products: any, indicator = 'carbon') => {
	const data = [] as any;
	const totalCarboneImpact = _.sumBy(products, 'carbonImpact');
	const totalWaterImpact = _.sumBy(products, 'waterImpact');

	products?.map((p: any) => {
		const carbonImpactPercent = (p?.carbonImpact / totalCarboneImpact) * 100;
		const waterImpactPercent = (p?.waterImpact / totalWaterImpact) * 100;
		data.push({
			objectId: p?.objectId,
			name: p?.label,
			value: indicator === 'carbon' ? carbonImpactPercent : waterImpactPercent,
			y: indicator === 'carbon' ? carbonImpactPercent : waterImpactPercent,
			isBlueCheck: p.tagAdvancedModelization === 'yes',
		});
	});
	return data;
};
