import { MESSAGE_KEY_APBO } from '@carbonmaps/shared/utils/constants';

import ApboCard from '../../components/apbo/card/ApboCard';
import Comment from '../../components/apbo/Comment';
import LabelCarbonCard from '../../components/apbo/LabelCarbonCard';
import Card from '../../components/widget/Card';
import { siteColors } from '../../lib/colors';

const CarbonLabelView = () => {
	return (
		<ApboCard messageKey={MESSAGE_KEY_APBO.carbonLabel}>
			<Card
				title="Label bas carbone"
				icon={
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clip-path="url(#clip0_239_5107)">
							<path
								d="M7.49984 10.0001L9.1665 11.6667L12.4998 8.33341M9.99984 18.3334C14.6023 18.3334 18.3332 14.6026 18.3332 10.0001C18.3332 5.39758 14.6023 1.66675 9.99984 1.66675C5.39734 1.66675 1.6665 5.39758 1.6665 10.0001C1.6665 14.6026 5.39734 18.3334 9.99984 18.3334Z"
								stroke={siteColors.grey700}
								stroke-width="1.67"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</g>
						<defs>
							<clipPath id="clip0_239_5107">
								<rect width="20" height="20" fill="white" />
							</clipPath>
						</defs>
					</svg>
				}
				center={false}
				sx={{
					padding: '0px !important',
					width: '100%',
					gap: 0,
				}}
				rightContent={<Comment messageKey={MESSAGE_KEY_APBO.carbonLabel} />}
				withBorder={false}
			>
				<div className="flexColumn gap16 width100">
					<LabelCarbonCard
						item={{
							year: 2021,
							label: 'Eleveurs engagés sur le 2ème AAP',
							exploitation: 56,
							impact: 400,
						}}
					/>
					<LabelCarbonCard
						item={{
							year: 2023,
							label: 'Eleveurs engagés sur le 3ème AAP',
							exploitation: 23,
							impact: 534,
						}}
					/>
				</div>
			</Card>
		</ApboCard>
	);
};

export default CarbonLabelView;
