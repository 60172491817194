import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { TYPE_MODELIZATION_INGREDIENT, TYPE_MODELIZATION_PACKAGING } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import MetaDataCard from '../../components/card/MetaDataCard';
import SEO from '../../components/SEO';
import { useTranslation } from '../../hooks/useTranslation';

import ScenarioContent from './content/ScenarioContent';
import ScenarioHeader from './content/ScenarioHeader';
import IndicatorsBlock from './IndicatorsBlock';
import LifeCycleBlock from './LifeCycleBlock';
import RepartitionBlock from './RepartitionBlock';
import { useScenario } from './scenario.hooks';
import { useDataSimulation } from './useDataSimulation.hooks';

const styles = {
	button: css({
		padding: '18px 24px !important',
		borderRadius: '8px !important',
		textTransform: 'none !important',
	} as any),

	grid: css({
		display: 'grid',

		gridTemplateColumns: '7fr 5fr',
		'@media screen and (min-width: 1380px)': {
			gridTemplateColumns: '8fr 4fr',
		},
	}),

	sidebar: css({
		padding: '32px 26px',
	}),

	block: css({
		background: 'white',
		border: 'none!important',
	}),
};

const Scenario = () => {
	const { setBreadcrumbs } = useApp();
	const queryClient = useQueryClient();
	const { simulation } = useDataSimulation();

	// translation
	const { t } = useTranslation();

	// ---- invalidateQueries getScenario at first  ---- //
	useEffect(() => {
		return () => {
			queryClient.invalidateQueries({ queryKey: ['getScenario'] });
		};
	}, []);

	useEffect(() => {
		setBreadcrumbs([{ text: t('Éco-conception'), isBeta: false }]);
	}, [setBreadcrumbs, t]);

	const params = useParams();

	const { isLoading } = useScenario({
		simulationId: params.simulationId || '',
	});

	const typeModelizationIngredient = _.toLower((simulation as any)?.product?.get('typeModelizationIngredient'));
	const typeModelizationPackaging = _.toLower((simulation as any)?.product?.get('typeModelizationPackaging'));

	const isRecetteReel = typeModelizationIngredient === 'n1_standard';
	const iPackagingReel = typeModelizationPackaging === 'n1_standard_packaging';

	const isValidScenarioProductSource = isRecetteReel && iPackagingReel;

	const renderInvalidScenario = (
		<div
			css={{
				flexGrow: 1,
				// height: '100%',
				padding: '32px',
				// background: 'red',
			}}
		>
			<Typography variant="h3">{t('Produit invalide')}</Typography>
			<Typography variant="body1">
				{/* eslint-disable-next-line quotes */}
				{t("Le produit n'est pas valide pour l'éco-conception", {
					product: toLowerCase(t('product')),
					of_product: t('of_product'),
				})}
				.
				<br />
				<br />
				{t('Les données de votre produit :', { product: toLowerCase(t('product')), of_product: t('of_product') })}
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;-{' '}
				{t('Modélisation recette :', {
					recipe: t('recipe'),
					recipe_lowercase: toLowerCase(t('recipe')),
				})}{' '}
				{t((TYPE_MODELIZATION_INGREDIENT as any)[typeModelizationIngredient], {
					recipe: t('recipe'),
					recipe_lowercase: t('recipe'),
				})}{' '}
				{isRecetteReel ? '✅' : '❌'}
				<br />
				&nbsp;&nbsp;&nbsp;&nbsp;- {t('Modélisation emballage :')}{' '}
				{t((TYPE_MODELIZATION_PACKAGING as any)[typeModelizationPackaging], {
					recipe: t('recipe'),
					recipe_lowercase: toLowerCase(t('recipe')),
				})}{' '}
				{iPackagingReel ? '✅' : '❌'}
			</Typography>
		</div>
	);

	const renderValidScenario = (
		<div className={cx(styles.grid)}>
			<div
				css={{
					maxHeight: '84.5vh',
					// maxHeight: '100%',
					overflowY: 'scroll',
				}}
			>
				<ScenarioContent />
			</div>
			<div
				className={cx('bgGrey300', styles.sidebar)}
				css={{
					maxHeight: '84.5vh',
					// maxHeight: '100%',
					overflowY: 'scroll',
				}}
			>
				<IndicatorsBlock />
				<LifeCycleBlock />
				<RepartitionBlock />
				<MetaDataCard
					importedOn={(simulation as any)?.product?.createdAt} //date of import of product data
					updatedAt={(simulation as any)?.updatedAt} //date of the last modification of the simulation
					bdd={(simulation as any)?.product?.bddRefPortfolio || t('Non renseigné')}
				/>
			</div>
		</div>
	);

	return (
		<div>
			<SEO title={`${t('Éco-conception')} - CarbonMaps`} />
			<ScenarioHeader />
			{/* {isLoading ? renderValidScenario : isValidScenarioProductSource ? renderValidScenario : renderInvalidScenario} */}
			{renderValidScenario}
		</div>
	);
};

export default Scenario;
