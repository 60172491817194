import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

type Props = {
	children?: ReactNode;
	sidebar?: ReactNode;
};

const BaseLayout = ({ children, sidebar }: Props) => {
	return (
		<div className="flexRow">
			{sidebar}
			<Box
				component="main"
				minHeight="100vh"
				display="flex"
				flexDirection="column"
				sx={{ flexGrow: 1, width: { sm: 'calc(100% - 328px)' } }}
			>
				{children ? children : <Outlet />}
			</Box>
		</div>
	);
};

export default BaseLayout;
