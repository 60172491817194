import { css } from '@emotion/css';

import { capitalizeFirstLetter } from '@carbonmaps/ui/utils/utils';

import Typography from '../../../components/Typography';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';

const styles = {
	container: css({}),
};

const Welcome = () => {
	const {
		result: { data, isLoading },
	} = useGetClientAuthQuery();

	const { t } = useTranslation();

	return (
		<div className="flexColumn gap8">
			<Typography variant="subtitle2" className="lineHeight1">
				{t('welcome-dashboard', { user: capitalizeFirstLetter(data?.user?.firstName) })} 👋
			</Typography>
			<Typography color={siteColors.grey800}>{t('welcome-content')}</Typography>
		</div>
	);
};

export default Welcome;
