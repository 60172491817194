import { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Carrot, PackageOpen, Plus } from 'lucide-react';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { getIngredients } from '@carbonmaps/ui/actions/ingredient.actions';
import { searchPackagingAction } from '@carbonmaps/ui/actions/packaging.actions';
import {
	TableComponent,
	getStickyHeaderStyle,
	getStickyRowStyle,
} from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import SearchAutocomplete from '../../../../../components/SearchAutocompleteV2';
import LoadingDialog from '../../../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../../../components/table/TableHeaderCell';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';

const styles = {
	container: css({
		gap: 24,
	}),
	autoComplete: css({
		width: '100% !important',
		'& .MuiInputBase-root': {
			width: '100% !important',
		},
	}),

	initButton: css({
		border: cn('1px solid', siteColors.grey600, '!important'),
		background: '#fff !important',
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},
		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr th:nth-of-type(1), .bordered tr td:nth-of-type(1)': {
			width: 400,
			// background: 'red',
			// background: 'red!important',
		},
		'.bordered tr th:nth-of-type(2), .bordered tr td:nth-of-type(2)': {
			width: 350,
			// background: 'blue!important',
		},
		'.bordered tr td:nth-of-type(4) div,.bordered tr td:nth-of-type(6) div': {
			justifyContent: 'flex-end',
		},
		'.bordered tr th:not(:nth-of-type(2),:nth-of-type(2),:nth-of-type(7)) div': {
			justifyContent: 'right',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

const addSeparatorStyle = () => {
	return {
		'.bordered tr:nth-of-type(2) td': {
			borderTop: cn('11px solid', siteColors.grey200),
		},
	};
};

const formatThenSetData = (res: any, indicator: string, currentPackagingId: string) => {
	if (!res?.length) return [];
	const maxCarboneIntensity = _.maxBy(res, 'ges') as any;
	const maxWaterIntensity = _.maxBy(res, 'waterUse') as any;
	const stickyRowStyle = getStickyRowStyle(720, 59);
	return res.map((item: any) => {
		return {
			...item,
			isSticky: item.objectId === currentPackagingId ? true : false,
			stickyRowStyle: item.objectId === currentPackagingId ? stickyRowStyle : '',
			ref: item?.uid,
			percentage:
				(indicator === CARBONE_INDICATOR
					? (item.ges * 100) / maxCarboneIntensity?.ges || 1
					: (item.waterUse * 100) / maxWaterIntensity?.waterUse) || 1,
		};
	});
};

type CreationPackagingTableProps = {
	packaging?: any;
	emballages?: any;
	onSavePackaging: (currentId: any, replaceId: string) => void;
	loading?: boolean;
};

const CreationPackagingTable = ({
	packaging,
	emballages,
	onSavePackaging,
}: // loading = false,
CreationPackagingTableProps) => {
	const theme = useTheme();
	// const [search, setSearch] = useState<any>();
	// translation
	const { t } = useTranslation();

	const { indicator } = useApp();

	// custom style tab
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const currentPackagingId = packaging?.id;

	// ---- columns ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} valueType="string" label={t('Emballages')} />;
				},
				accessor: 'label',
				component: 'TagLabel',
				editable: true,
				sortDescFirst: true,
				type: 'string',
				icon: <PackageOpen size={20} color={siteColors.grey700} />,
				props: {
					gap: 8,
					style: {
						color: theme.palette.common.black,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
						textTransform: 'uppercase !important',
					},
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('Intensité par kilo d’emballage')}
							//label={indicator === CARBONE_INDICATOR ? WORDING_TERMS.INTENSITY : 'Intensité eau'}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBONE_INDICATOR ? 'ges' : 'waterUse',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBONE_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} valueType="string" label="" />;
				},
				accessor: 'objectID',
				disableSortBy: true,
				editable: true,
				component: 'Action',
				callBack: (row: any) => {
					onSavePackaging(row?.objectId, packaging?.id);
				},
				labelButton: packaging ? t('Remplacer') : t('Ajouter'),
				props: {
					...(!packaging && { icon: <Plus color={theme.palette.grey[700]} /> }),
					initLabel: t('reset-filter-button'),
					currentUid: packaging?.get('uid'),
					className: styles.initButton,
				},
			},
		];
	}, [theme.palette.common.black, theme.palette.grey, indicator, packaging, onSavePackaging]);

	const [searchQueryParams] = useSearchQueryParams();
	const { input, facetFilters, supplierIds } = searchQueryParams;

	// ---- fetch data ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'searchPackaging',
			{
				// input: search?.input,
				currentPackaging: packaging?.id,
				notInIds: emballages
					.filter((i: any) => {
						return i?.packaging?.id !== packaging?.id;
					})
					.map((item: any) => {
						return item?.packaging?.id;
					}),
				limit: 100,
				//isGraph: true,
				input,
				facetFilters,
				supplierIds,
			},
		],
		queryFn: searchPackagingAction,
	} as any);

	const stickyHeaderStyle = getStickyHeaderStyle(1391, 0);

	return (
		<div className={cx('flexColumn width100', styles.container)}>
			{/* <SearchAutocomplete
				setParamsAutocomplete={_.debounce(setSearch, 500)}
				placeholder={'Rechercher un emballage'}
				options={[]}
				className={styles.autoComplete}
			/> */}
			<div className="width100">
				<TableComponent
					skeleton={isLoading}
					loading={false}
					fetchData={(row: any) => {
						return console.log('row');
					}}
					pageCount={0}
					noDataMessage={t('packaging-table-empty-data')}
					pageSize={1000}
					columns={tableColumns}
					data={formatThenSetData(data, indicator, currentPackagingId)}
					manualSortBy={false}
					pagination={false}
					isSelectable={false}
					addStyles={{ ...stylesTable, ...(packaging && addSeparatorStyle()) }}
					transFunc={t}
					stickyHeader
					stickyHeaderStyles={stickyHeaderStyle}
				/>
			</div>
			{/* <LoadingDialog open={loading} content={'Modification en cours...'} /> */}
		</div>
	);
};

export default CreationPackagingTable;
