import { css, cx } from '@emotion/css';

import { cn, siteColors } from '../../../lib/colors';
import CircularProgress from '../../card/CircularProgress';
import Skeleton from '../../Skeleton';
import Typography from '../../Typography';
import Legend from '../Legend';

const styles = {
	container: css({
		borderRadius: 16,
		border: cn('1px solid', siteColors.grey500),
		padding: '24px 0px',
	}),

	content: css({
		fontSize: '48px !important',
		color: cn(siteColors.grey900, '!important'),
	}),
	skeleton: css({
		height: '170px !important',
		width: '100% !important',
	}),
};
type AnsweredCardProps = {
	item: {
		total: number;
		firstDiag: number;
		secondDiag: number;
	};
	title: string;
	className?: string;
	isLoading?: boolean;
};

const DiagStatCard = ({ item, className, title, isLoading }: AnsweredCardProps) => {
	return isLoading ? (
		<Skeleton className={styles.skeleton} />
	) : (
		<div className={cx('flexCenter', styles.container, className)}>
			<div className="flexRow alignCenter gap24">
				<CircularProgress
					percent={(item?.firstDiag * 100) / item?.total}
					otherColor="#5F80AD"
					fillColor="#95AB76"
					containerWidth={'120px'}
					innerRadius={34}
					content={<Typography className={styles.content}>{item?.total}</Typography>}
				/>
				<div className="flexColumn gap10">
					<Typography className="fontWeight600">{title}</Typography>
					<div className="flexColumn">
						<Legend label="1er diagnostic" color="#95AB76" value={item?.firstDiag} />
						<Legend label="2ème diagnostic ou plus" color="#5F80AD" value={item?.secondDiag} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default DiagStatCard;
