import { css } from '@emotion/css';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Pour les graphiques polaires
import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from '../hooks/useTranslation';
import { siteColors } from '../lib/colors';

const styles = {
	titlePoint: css({
		margin: 0,
		color: siteColors.grey900,
		fontSize: 12,
		fontWeight: 400,
		fontFamily: 'Inter, Helvetica, Arial,sans-serif !important',
	}),
};

const tooltipFormatter: any = function (this: any) {
	// eslint-disable-next-line @typescript-eslint/no-this-alias
	const tooltip = this;
	// eslint-disable-next-line
	if (this.series.name === 'A' || this.series.name === 'B') {
		return false;
	}

	return ReactDOMServer.renderToString(<h2 className={styles.titlePoint}></h2>);
};
export interface Idata {
	label: string;
	value: number;
}

const PolarChart = ({
	currentSupplierName,
	dataAvg,
	data,
	withLegend = true,
	withAvg = true,
}: {
	currentSupplierName?: string;
	dataAvg?: Idata[];
	data: Idata[];
	withLegend?: boolean;
	withAvg?: boolean;
}) => {
	// console.log('------------', data, dataAvg);
	const { t } = useTranslation();

	const dataCustom = data.filter((d) => {
		return d.label !== undefined;
	}); //don't order

	if (!dataCustom) return null;

	// Étape 1: Trouver la valeur maximale
	const maxValue = _.maxBy(dataCustom, 'value')?.value;
	if (!maxValue) return null;

	const finalData: any = dataCustom.map((category: Idata, index: number) => {
		return (category.value * 5) / 100;
	}); /* .map((n) => {
		return
	}); */

	// console.log('************', data /*  _.max(finalData), finalData */);

	const dataExternal = dataCustom.map((category: Idata, index: number) => {
		return {
			y: 5,
			name: category.label,
		};
	});

	const dataMin = dataCustom.map((category: Idata, index: number) => {
		// return _.min(_.filter(finalData, (n) => n !== 0));
		return 1;
	});

	const categories = dataCustom.map((category: Idata, index: number) => {
		return category.label;
	});

	// Configuration des intervalles
	const tickInterval = 360 / dataExternal.length;

	const mapCat = new Map();

	// Récupérer tous les intervalles pour ordonner les labels
	const intervals = [];
	for (let i = 0; i < categories.length; i++) {
		mapCat.set((i * tickInterval).toString(), categories[i]);
		intervals.push(i * tickInterval);
	}

	const series = [
		{
			type: 'line',
			name: '',
			data: dataExternal,
			// pointPlacement: 'off',
			color: '#DFDEE0',

			lineWidth: 1,
			marker: {
				enabled: false, // Désactiver les marqueurs de points
				states: {
					hover: {
						enabled: false, // Désactiver le point au survol
					},
				},
			},
			labels: {
				enabled: true, // Désactiver les labels de l'axe Y
			},
		},

		{
			type: withAvg ? 'line' : 'area',
			name: currentSupplierName,
			data: finalData,
			color: '#7176FF',
			// fillColor: '#E0E1FF',
			fillColor: 'rgba(113, 118, 255, 0.5)', // Couleur avec transparence
			lineWidth: 2,
			marker: {
				enabled: false, // Désactiver les marqueurs de points
				states: {
					hover: {
						enabled: false, // Désactiver le point au survol
					},
				},
			},

			plotOptions: {
				area: {
					fillOpacity: 0.9, // Opacité des zones
				},
			},
		},

		{
			type: 'line',
			name: '',
			data: dataMin,
			// pointPlacement: 'off',
			color: '#DFDEE0',

			lineWidth: 1,
			marker: {
				enabled: false, // Désactiver les marqueurs de points
				states: {
					hover: {
						enabled: false, // Désactiver le point au survol
					},
				},
			},
			labels: {
				enabled: true, // Désactiver les labels de l'axe Y
			},
		},
	];

	if (withAvg) {
		// const maxValue = _.maxBy(dataAvg, 'value')?.value || 1;
		const dataMoyenne = dataAvg
			?.filter((d) => {
				return d.label !== undefined;
			})
			?.map((category: Idata, index: number) => {
				// return (category.value / maxValue) * 5;
				return (category.value * 5) / 100;
			});
		// console.log(dataMoyenne, 'dataMoyenne');
		series.push({
			type: 'line',
			name: t('Moyenne dans votre catégorie'),
			data: dataMoyenne,
			color: '#5F80AD',
			// fillColor: '#D8E0F3',
			// fillColor: 'rgba(95, 128, 173, 0.5)', // Couleur avec transparence
			// fillOpacity: 0.78,
			lineWidth: 2,
			dashStyle: 'Dash',
			marker: {
				enabled: false, // Désactiver les marqueurs de points
				states: {
					hover: {
						enabled: false, // Désactiver le point au survol
					},
				},
			},
			// plotOptions: {
			// 	area: {
			// 		fillColor: 'rgba(255, 165, 0, 0.5)', // Couleur de base de la zone
			// 		states: {
			// 			hover: {
			// 				fillColor: 'rgba(255, 165, 0, 0.5)' // Même couleur que la couleur de base
			// 			}
			// 		}
			// 	}
			// },
		} as any);
	}
	const options = {
		chart: {
			polar: true,
			type: 'line',
			responsive: true,
		},
		title: {
			text: '',
		},
		plotOptions: {
			series: {
				states: {
					hover: {
						enabled: false, // Désactive le survol pour toutes les séries
					},
					inactive: {
						enabled: false, // Empêche de rendre les autres séries inactives au survol
					},
				},
				marker: {
					enabled: false, // Désactiver les marqueurs de points pour toutes les séries
				},
			},
			area: {
				states: {
					hover: {
						enabled: false, // Désactive l'état de survol
					},
				},
				fillOpacity: 0.9, // Opacité des zones
				marker: {
					enabled: false, // Désactiver les marqueurs de points
				},
			},
			line: {
				marker: {
					enabled: false, // Désactiver les marqueurs de points
				},
			},
		},
		tooltip: {
			enabled: false, // Désactive la tooltip
		},
		legend: {
			enabled: withLegend,
			align: 'left',
			verticalAlign: 'top',
			layout: 'horizontal',
		},
		pane: {
			size: '80%',
		},
		xAxis: {
			categories,
			// tickmarkPlacement: 'off',
			lineWidth: 0,
			gridLineWidth: 0, // Désactiver les lignes de grille
			// labels: {
			// 	formatter: firstLabelFormatter,
			// 	// distance: -12, // Ajuster la distance des labels de l'axe des x
			// },

			labels: {
				style: {
					color: '#3E3A45', // Changer la couleur des labels ici
					fontSize: '12px', // Optionnel: changer la taille de la police
					fontFamily: 'Inter', // Optionnel: changer la police
				},
			},
		},
		yAxis: {
			gridLineInterpolation: 'polygon',
			lineWidth: 0,
			min: 0,
			max: finalData.length,
			labels: {
				enabled: false,
			},
			gridLineWidth: 0, // Désactiver les lignes de la grille
		},
		series,
		credits: {
			enabled: false, // Désactiver le logo Highcharts
		},
	};

	HighchartsMore(Highcharts);

	return (
		<div>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};

export default PolarChart;
