import dayjs from 'dayjs';
import { Workbook } from 'exceljs';

import { functionName, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { getObjScoring } from '@carbonmaps/shared/utils/scoring';

import {
	FULFILLED_MODELIZATION_TYPE,
	MODELIZATION_CONFIG_ITEMS,
	PARTIAL_DATA_MODELIZATION_TYPE,
} from '../../../../utils/supplier';

const HEADING = {
	uid: 'ID supplier',
	name: 'Label supplier',
	typeModelization: 'Type de modélisation', //todo
	onboarding: 'Modélisation spécifique',
	carbonIntensity: WORDING_TERMS.INTENSITY,
	carbonImpact: WORDING_TERMS.IMPACT,
	volume: 'Tonnage',
	scoring: 'Score fournisseur',
};

const HEADING_INFO = {
	gesScoring: 'Réalisation d’un bilan GES',
	acvScoring: 'Réalisation d’un ACV',
	emissionFactorCalculation: 'Modélisation de ses propres FE',
	traceabilityProducts: 'Traçabilité de ses flux matières',
	traceabilityCoProducts: 'Traçabilité de ses flux de co-produits',
	definedTargets: 'Objectifs de réduction définis',
	sbtiTargets: 'Engagé SBTi',
	// eslint-disable-next-line quotes
	actionPlan: "Plans d'action pour la réduction",
	ecofriendlyLabels: 'Produits avec labels environnementaux',
};

const getRowsValues = (item: any, headers: any, t: any) => {
	return Object.keys(headers).map((key: string) => {
		// if (item[key] === 'no') return 'Non';
		// if (item[key] === 'yes') return 'Oui';
		const arrOtherField = Object.keys(HEADING_INFO);

		if (arrOtherField.includes(key)) {
			if (item[key] === 'no') return t('Non');
			if (item[key] === 'yes') return t('Oui');
			return t('Non renseigné');
		}

		return item[key] || '';
	});
};

export async function exportSupplierAction(params: any, t: any) {
	try {
		const data = await Parse.Cloud.run(functionName.suppliers.exportSuppliers, { ...params, supplierIds: params?.ids });
		console.log(data);
		const workbook: any = new Workbook();
		const worksheet = workbook.addWorksheet('My Worksheet');

		const columnsName = Object.values({ ...HEADING, ...HEADING_INFO }).map((key) => {
			return t(key);
		});

		worksheet.addRow(columnsName);
		data.forEach((item: any) => {
			const finalItem = {
				...item,
				carbonImpact: parseFloat(isNaN(item?.carbonImpact) ? 0 : item?.carbonImpact?.toFixed(2)),
				carbonIntensity: parseFloat(isNaN(item?.carbonIntensity) ? 0 : item?.carbonIntensity?.toFixed(2)),
				onboarding: MODELIZATION_CONFIG_ITEMS.get(item.onboarding)?.label,
				scoring: getObjScoring(item.scoring)?.label,
				typeModelization:
					item.onboarding === FULFILLED_MODELIZATION_TYPE || item.onboarding === PARTIAL_DATA_MODELIZATION_TYPE
						? 'Spécifique'
						: 'Générique',
			};
			worksheet.addRow(getRowsValues(finalItem, { ...HEADING, ...HEADING_INFO }, t));
		});

		// Change the height of the first row (column A)
		worksheet.getRow(1).height = 25;

		// Change the column width
		worksheet.getColumn(1).width = 25; // column A
		worksheet.getColumn(2).width = 35; // column B
		worksheet.getColumn(3).width = 25; // column C
		worksheet.getColumn(4).width = 50; // column D
		worksheet.getColumn(5).width = 30; // column E
		worksheet.getColumn(6).width = 20; // column F
		worksheet.getColumn(7).width = 20; // column G
		worksheet.getColumn(8).width = 20; // column H
		worksheet.getColumn(9).width = 25; // column I
		worksheet.getColumn(10).width = 20; // column J
		worksheet.getColumn(11).width = 32; // column K
		worksheet.getColumn(12).width = 30; // column L
		worksheet.getColumn(13).width = 35; // column M
		worksheet.getColumn(14).width = 30; // column N
		worksheet.getColumn(15).width = 30; // column O
		worksheet.getColumn(16).width = 40; // column P
		worksheet.getColumn(17).width = 40; // column Q

		worksheet.eachRow((row: any, rowNumber: any) => {
			row.eachCell((cell: any, colNumber: any) => {
				if (rowNumber === 1) {
					cell.alignment = { vertical: 'middle' };
					cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
					cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'A4C2F4' } };
				}
			});
		});

		const companyName = params?.companyName || '__';
		const nameSheet = `${companyName}_${t('fournisseur')}_${dayjs(new Date())?.format('DDMMYYYY')}.${params.format}`;

		// Create a Blob to save the workbook
		workbook[params.format ?? 'xlsx'].writeBuffer().then((buffer: any) => {
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = nameSheet;
			a.click();
			window.URL.revokeObjectURL(url);
		});
	} catch (error) {
		console.log(error);
		return Promise.reject(error);
	}
}
