import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { Box as BoxIcon } from 'lucide-react';
import { Navigate, createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useProductInfo } from '@carbonmaps/ui/hooks/useGetProductSheet';

import SEO from '../../components/SEO';
import ScrollTop from '../../components/ScrollTop';
import SheetPageLayout, { ITab } from '../../components/layout/page/SheetPageLayout';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';
import ProductModel from '../../models/Product.model';
import Page404 from '../404/Page404';

import Ingredients from './ingredients/ProductIngredients';
import ProductPackaging from './packaging/ProductPackaging';
import ProductSynthesis from './synthesis/ProductSynthesis';

const ProductSheet = () => {
	const { setBreadcrumbs, indicator } = useApp();
	const params = useParams(); // * { productId: string; tab: string }
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	const { t } = useTranslation();

	const analytics = useJuneTrack();

	const { data: productInfo, isLoading } = useProductInfo({ objectId: params.productId!, viewMode: indicator });

	console.log('ProductSheet -> productInfo', productInfo);

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Product Switch Tab', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		if (!productInfo) {
			setBreadcrumbs([{ text: t('products') }]);
			return;
		}

		setBreadcrumbs([
			{ text: t('products'), link: `${FRONT_PATH_NAMES.products}?${searchParams}` },
			{ text: _.capitalize(productInfo?.label) },
		]);
	}, [productInfo, setBreadcrumbs, searchParams, isLoading, t]);

	const tabs: Record<string, ITab> = useMemo(() => {
		return {
			synthesis: {
				title: t('synthesis-tab'),
				element: <ProductSynthesis />,
				tabName: 'Summary',
			},
			ingredients: {
				title: t('agriculture-transformation-tab'),
				element: <Ingredients />,
				// disabled: data?.product.typeModelization === 'N1_Gamme',
				tabName: 'Agriculture & upstream transformation',
			},
			packaging: {
				title: t('product-packaging-tab'),
				element: <ProductPackaging />,
				// disabled: data?.product.typeModelization === 'N1_Gamme',
				tabName: 'Packaging',
			},
			transformation: {
				title: t('product-transformation-tab'),
				element: <h1>Transformation</h1>,
				disabled: true,
			},
			distribution: {
				title: t('product-distribution-tab'),
				element: <h1>Distribution & Consommation</h1>,
				disabled: true,
			},
		};
	}, [t]);

	const handleTabChange = (_: SyntheticEvent, value: string) => {
		trackEvent({
			ProductTabTarget: (tabs[value] as any)?.tabName,
		});

		navigate(`${FRONT_PATH_NAMES.products}/${params.productId}/${value}?${searchParams}`);
	};

	if (!params.tab) {
		return (
			<Navigate
				replace
				state={{ from: location }}
				to={`${FRONT_PATH_NAMES.products}/${params.productId}/${Object.keys(tabs)[0]}?${searchParams}`}
			/>
		);
	}

	// if (notFound) {
	// 	// to do: redirect to not found page product
	// 	// return (
	// 	// 	<div>
	// 	// 		<Typography>l’ID du produit n’existe plus ou n’a pas été trouvé</Typography>
	// 	// 	</div>
	// 	// );
	// 	return (
	// 		<Navigate replace state={{ from: location }} to={`${FRONT_PATH_NAMES.products}/not-found/${params.productId}`} />
	// 	);
	// }
	if ((productInfo as never) === 'PRODUCT_NOT_FOUND') {
		return <Page404 message={<h2>Produit ({params.productId}) introuvable</h2>} />;
		// return <ProductNotFound />;
	}

	return (
		<>
			<SEO title={productInfo?.label ? `${_.capitalize(productInfo?.label)} - CarbonMaps` : 'CarbonMaps'} />
			<ScrollTop key={params.tab} behavior="auto" />
			<SheetPageLayout
				loading={isLoading}
				currentTab={params.tab}
				headerProps={{
					variant: 'fixed',
					icon: <BoxIcon size={40} color={theme.palette.common.black} />,
					isBlueCheck: new ProductModel(productInfo)?.isN2ModelizationType() ?? false,
					title: productInfo?.label || '',
					subtitle: productInfo?.uid || '',
				}}
				onTabChange={handleTabChange}
				tabs={tabs}
			/>
		</>
	);
};

export default ProductSheet;
