import { css, cx } from '@emotion/css';

import EcoDesignPotential from './EcoDesignPotential';
import EcoDesignProduct from './EcoDesignProduct';
import Scenario from './Scenario';

const styles = {
	container: css({
		maxWidth: '100%',
		overflow: 'auto',
		paddingBottom: 20,
	}),
};

const EcoDesignTab = () => {
	return (
		<div className="flexColumn width100 gap32">
			<EcoDesignPotential />
			<div className={cx('flexRow width100 gap32 stretch nowrap ', styles.container)}>
				<EcoDesignProduct />
				<Scenario />
			</div>
		</div>
	);
};

export default EcoDesignTab;
