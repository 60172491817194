import { ComponentProps, Dispatch, SetStateAction, useCallback, useState } from 'react';

import { css, cx } from '@emotion/css';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { FileUp, MoreVertical, Trash, Undo2 } from 'lucide-react';
import { useToggle } from 'react-use';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import BDropdown from '@carbonmaps/ui/components/saas/BDropdown';
import DeleteConfirm from '@carbonmaps/ui/components/saas/DeleteConfirm';
import { XL_UP } from '@carbonmaps/ui/utils/Responsive';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { TOP_BAR_HEIGHT } from '../../../utils/constants';
import Filter, { IFilterFolder } from '../../Filter';
import SearchAutocomplete from '../../SearchAutocompleteV2';
import ExportDialog from '../../dialogs/ExportDialog';
import LoadingDialog from '../../dialogs/LoadingDialog';
import { Option } from '../../form/fields/MultiSelectCheckboxField';

const styles = {
	container: css({
		padding: '24px 32px 24px 32px',
		background: 'white',
		position: 'sticky',
		top: TOP_BAR_HEIGHT,
		[XL_UP]: {
			justifyContent: 'space-between',
		},
		zIndex: 100,
	}),
	innerContainer: css({
		width: '100%',
		[XL_UP]: {
			width: 'unset',
			marginBottom: '0px !important',
		},
		flexWrap: 'wrap',
		gap: 16,
	}),

	actionsContainer: css({
		borderRadius: '8px',
		background: siteColors.common.white,
		alignItems: 'flex-end',
		padding: '2px 4px 2px 4px',
		[XL_UP]: {
			width: 'unset',
		},
	}),
	width300: css({
		width: 300,
	}),

	button: css({
		borderRadius: '8px',
		background: 'none',
		color: siteColors.text,
		padding: '8px 16px 8px 16px',
	}),
	dropItemButton: css({
		gap: 8,
		fontSize: '14px',
		fontWeight: 400,
		cursor: 'pointer',
		padding: '8px 16px 8px 16px',
		background: 'transparent!important',
		color: siteColors.text,
		paddingInline: 0,
	}),

	resetFilterButton: css({
		background: 'none',
		fontSize: '14px',
		fontWeight: 600,
	}),
	menuItem: css({
		width: '300px !important',
		paddingLeft: '9px !important',
	}),
	itemText: css({
		paddingLeft: '9px !important',
		textTransform: 'none !important',
	} as any),
};

type FilterContainerProps = {
	setSearchQueryParams: (v: any) => void;

	folders: IFilterFolder[];
	// onTriggerFilter: (params: OnTriggerFilterParams) => void;
	// isLoading: boolean;
	selectedValues: Option[];
	setSelectedValues: Dispatch<SetStateAction<Option[]>>;
	appliedValuesByFolders: IFilterFolder[];
	setAppliedValuesByFolders: Dispatch<SetStateAction<IFilterFolder[]>>;
	isLoading: boolean;
	suppliersFolderLabel: string;
	selectedRow?: any;
	count?: number;
	handleExport?: (param: any) => void;
	isTableLoading: boolean;
	isExportLoading?: boolean;
	skeleton?: boolean;
	showFacetFilter?: boolean;
	rightContent?: React.ReactNode;
	withSearch?: boolean;
	withMore?: boolean;
	showCount?: boolean;
	searchPlaceholder?: string;
	searchClassName?: string;
	className?: string;
	tag?: string;

	moreActionExport?: boolean;

	handleDelete?: (...args: any[]) => void;
	loadingDelete?: boolean;

	enableActionDelete?: boolean;

	messageConfirmDelete?: string;
	subMessageConfirmDelete?: string;
	searchTrackName?: string;
	trackCategory?: string;
	filterTrackName?: string;
	exportTrackName?: string;
	trackingOptions?: any;
	exportKey?: string;
	type?: string;
};

const FilterContainer = ({
	setSearchQueryParams,
	appliedValuesByFolders,
	setAppliedValuesByFolders,
	setSelectedValues,
	selectedValues,
	isLoading,
	folders,
	suppliersFolderLabel,
	selectedRow,
	count,
	handleExport,
	handleDelete,
	isTableLoading,
	isExportLoading = false,
	skeleton = false,
	rightContent,
	withSearch = true,
	showFacetFilter = true,
	withMore = true,
	showCount = true,
	searchPlaceholder = 'search_for_product_placeholder',
	searchClassName = '',
	className = '',
	tag = 'produit',
	moreActionExport = true,
	loadingDelete = false,
	enableActionDelete = true,
	messageConfirmDelete,
	subMessageConfirmDelete,
	searchTrackName,
	filterTrackName,
	trackCategory,
	exportTrackName,
	trackingOptions,
	exportKey,
	type,
}: FilterContainerProps) => {
	const [isClearInputValue, setIsClearInputValue] = useState(false);
	const [isExportDialogOpen, toggleExportDialog] = useToggle(false);
	const theme = useTheme();

	const { t } = useTranslation();
	const analytics = useJuneTrack();
	const onTrackFilter = useCallback(() => {
		analytics.track(filterTrackName, trackingOptions || {}, {
			context: { category: trackCategory },
		});
	}, [analytics, filterTrackName, trackCategory]);

	const onTriggerFilter: ComponentProps<typeof Filter>['onTriggerFilter'] = useCallback(
		({ allFoldersSelectedValues }) => {
			const _facetFiltersMap = new Map();
			const supplierIds: string[] = [];

			for (const e of allFoldersSelectedValues) {
				if (e.isFacetBucket) {
					const inMapFacetFilter = _facetFiltersMap.get(e.facetPath);

					if (!inMapFacetFilter) {
						_facetFiltersMap.set(e.facetPath, {
							path: e.facetPath,
							type: e.type,
							values: [encodeURIComponent(e.value)],
							global: e.global === true ? true : false,
							itemType: e.itemType,
							folderLabel: e.folderLabel,
						});
						continue;
					}

					inMapFacetFilter.values.push(encodeURIComponent(e.value));
					continue;
				}

				if (e.folderLabel === suppliersFolderLabel) {
					supplierIds.push(e.value as string);
					continue;
				}
			}

			setSearchQueryParams((oldValue: any) => {
				return {
					...oldValue,
					supplierIds,
					facetFilters: [..._facetFiltersMap.values()],
				};
			});
		},
		[setSearchQueryParams, suppliersFolderLabel],
	);

	const resetFilters = useCallback(() => {
		// setFilterFolders([]);
		setSelectedValues([]);
		setAppliedValuesByFolders([]);
		setIsClearInputValue(true);
		setSearchQueryParams((prev: any) => {
			return {
				...prev,
				input: '',
				supplierIds: [],
				facetFilters: [],
			};
		});
	}, [setAppliedValuesByFolders, setSearchQueryParams, setSelectedValues]);

	const renderWithMore = withMore ? (
		<BDropdown
			menus={[
				{
					title: t('delete-button'),
					value: '',
					icon: <Trash color={theme.palette.grey[700]} />,
					customComponent: 'Filtrer',
				},
			]}
			renderItem={(menu) => {
				return (
					<div className={styles.width300}>
						{moreActionExport ? (
							<BButton
								onClick={toggleExportDialog}
								iconLeft={<FileUp color={theme.palette.grey[700]} />}
								label={t('export-row-title')}
								variant="tertiary"
								className={styles.dropItemButton}
							/>
						) : null}

						{enableActionDelete ? (
							<>
								<Divider />
								<DeleteConfirm
									key={String(menu)}
									subMessageConfirmDelete={subMessageConfirmDelete}
									onClick={() => {
										handleDelete?.();
									}}
									title={`${t('suppression-message')} ${selectedRow?.length ? selectedRow?.length : ''} ${tag}(s)`}
									loadingDelete={loadingDelete}
								>
									<MenuItem className={styles.menuItem} key={menu.title}>
										{menu.icon} <Typography className={styles.itemText}>{menu.title}</Typography>
									</MenuItem>
								</DeleteConfirm>
							</>
						) : null}

						{handleExport && (
							<ExportDialog
								title={`${t('export-message-title')} ${selectedRow?.length ? selectedRow?.length : count}  ${tag}(s)`}
								open={isExportDialogOpen}
								toggle={toggleExportDialog}
								isLoading={isTableLoading}
								handleExport={handleExport}
								trackName={exportTrackName}
							/>
						)}
						<LoadingDialog
							open={isExportLoading}
							exportKey={exportKey}
							content={`${t('export-loading-message')}...`}
							type={type}
						/>
					</div>
				);
			}}
		>
			<BButton label={<MoreVertical />} variant="tertiary" className={styles.button} />
		</BDropdown>
	) : null;

	return (
		<Grid className={cx('filterContainer-wrapper alignCenter flexRow nowrap', styles.container, className)} container>
			<Grid item gap={pxToRem(16)} className={cx('flexRow alignCenter', styles.innerContainer)}>
				{withSearch && (
					<SearchAutocomplete
						size={20}
						setParamsAutocomplete={setSearchQueryParams && _.debounce(setSearchQueryParams, 500)}
						isLoading={false}
						placeholder={t(searchPlaceholder, { product: toLowerCase(t('product')), of_product: t('of_product') })}
						options={[]}
						isClearInputValue={isClearInputValue}
						setIsClearInputValue={setIsClearInputValue}
						className={searchClassName}
						trackName={searchTrackName}
						trackCategory={trackCategory}
						trackingOptions={trackingOptions}
					/>
				)}
				{showFacetFilter && (
					<Filter
						skeleton={skeleton}
						appliedValuesByFolders={appliedValuesByFolders}
						setAppliedValuesByFolders={setAppliedValuesByFolders}
						selectedValues={selectedValues}
						setSelectedValues={setSelectedValues}
						isLoading={isLoading}
						folders={folders.filter((e) => {
							// if (e.label === 'tagAdvancedModelization')
							return e.options.length > 0;
						})}
						onTriggerFilter={onTriggerFilter}
						showCount={showCount}
						onClick={onTrackFilter}
					/>
				)}
			</Grid>

			{rightContent ? (
				<div className="flexRow" css={{ flexShrink: 0 }}>
					{rightContent}
					{renderWithMore}
				</div>
			) : (
				<Grid className={cx('flexRow nowrap', styles.actionsContainer)} item>
					<>
						{appliedValuesByFolders.length > 0 && selectedValues.length > 0 && (
							<BButton
								onClick={resetFilters}
								iconLeft={<Undo2 color={theme.palette.jobs.welfare.main} />}
								label={t('reset-filter-button')}
								variant="tertiary"
								className={cx(styles.resetFilterButton, 'welfareColor')}
							/>
						)}
						{renderWithMore}
					</>
				</Grid>
			)}
		</Grid>
	);
};

export default FilterContainer;
