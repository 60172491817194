import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { siteColors } from '../../lib/colors';

type PercentBarProps = {
	value: number;
	color?: string;
};

const PercentBar = ({ value, color }: PercentBarProps) => {
	const theme = useTheme();
	return (
		<BPopover
			anchorOriginHorizontal={'left'}
			transformOriginVertical={'top'}
			transformOriginHorizontal={'left'}
			anchorOriginVertical={'bottom'}
			modeHover
			content={(onClose) => {
				return (
					<div css={{ padding: '8px 16px' }}>
						<Typography color={siteColors.grey900} fontWeight={500} className="number">
							{formatNumber(value, undefined, 2)} %
						</Typography>
					</div>
				);
			}}
			addStyles={{ width: '100%' }}
		>
			<Box
				bgcolor={theme.palette.grey[500]}
				height="10px"
				width="100%"
				display="flex"
				borderRadius="500px"
				overflow="hidden"
			>
				<Box
					height="100%"
					width={`${value}%`} // TODO: verify this shit
					bgcolor={color || theme.palette.primary.main}
					borderRadius="6px"
				/>
			</Box>
		</BPopover>
	);
};

export default PercentBar;
