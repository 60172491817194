import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { css, cx } from '@emotion/css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cn, siteColors } from '../../../../lib/colors';
import ContentRse from './blocks/ContentRse';
import GeneralQuestionFormTabs from './blocks/GeneralQuestionFormTabs';

const styles = {
	container: css({
		marginTop: '16px',
	}),

	textSelect: css({
		fontSize: '14px !important',
		fontWeight: '400 !important',
	}),

	selectedContainer: css({
		width: '100%',
		background: '#fff !important',
		color: cn(siteColors.grey700, '!important'),
		border: '1px solid rgb(228, 204, 247)',

		'.MuiInputBase-input': {
			paddingBottom: '0px !important',
			minHeight: '32px !important',
			paddingTop: '8px',
			minWidth: '386px !important',
			// padding: '8px 16px',
			// height: '42px !important',
			// display: 'flex',
			// flex: '1 0 0',
			// gap: '8px',
			// alignItems: 'center',
		},
		svg: {
			width: '20px',
			height: '20px',
			color: cn(siteColors.grey700, '!important'),
		},
	}),
};

const RsePerformance = ({ isGeneralView = false }) => {
	const { t } = useTranslation();
	const { setBreadcrumbs } = useApp();

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('suppliers') }, { text: t('rse-performance-tab') }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	return (
		<div className={cx('flexColumn width100', styles.container)}>
			<GeneralQuestionFormTabs />
			<ContentRse isGeneralView={isGeneralView} />
		</div>
	);
};

export default RsePerformance;
