import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';

type GetSheetDataAuditType = {
	itemType: string;
	facetFilters?: any;
	input?: string;
	typeErrors?: any;
	ids?: any;
	period?: number;
	isErrorVolume?: boolean;
};

/**
 *
 * @param context
 * @returns
 */
export const getDataAudit = async (
	context: QueryFunctionContext<readonly ['getDataAudit', Record<string, any>]>,
) /* : Promise<Parse.Object[]> */ => {
	try {
		const { selectedYear } = context.queryKey[1];

		const result = await Parse.Cloud.run('getDataAudit', { selectedYear });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 *
 * @param context
 * @returns
 */
export const getAllDataAudit = async (
	context: QueryFunctionContext<readonly ['getAllDataAudit', Record<string, any>]>,
) /* : Promise<Parse.Object[]> */ => {
	try {
		const { period } = context.queryKey[1];

		const result = await Parse.Cloud.run(functionName.audit.getAllDataAudit, { period });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getGlobalAuditPercent = async (
	context: QueryFunctionContext<readonly ['getGlobalAuditPercent', Record<string, any>]>,
): Promise<Parse.Object[]> => {
	try {
		const { selectedYear } = context.queryKey[1];
		const result = await Parse.Cloud.run('getGlobalAuditPercent', { selectedYear });
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 *
 * @param context
 * @returns
 */
export const getSheetDataAudit = async (
	context: QueryFunctionContext<readonly ['getSheetDataAudit', GetSheetDataAuditType & Record<string, any>]>,
) => {
	try {
		const {
			queryKey: {
				1: { itemType, facetFilters, input, typeErrors, ids, period, ...rest },
			},
		} = context;
		const result = await Parse.Cloud.run('getSheetDataAudit', {
			itemType,
			facetFilters,
			input,
			typeErrors,
			ids,
			period,
			...rest,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getSheetDataAuditTable = async (
	context: QueryFunctionContext<readonly ['getSheetDataAuditTable', GetSheetDataAuditType & Record<string, any>]>,
) => {
	try {
		const {
			queryKey: {
				1: { itemType, facetFilters, input, typeErrors, ids, period, size, page, ...rest },
			},
		} = context;
		const result = await Parse.Cloud.run('getSheetDataAudit', {
			itemType,
			facetFilters,
			input,
			typeErrors,
			ids,
			period,
			limit: size,
			skip: (page - 1) * size,
			...rest,
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * count data ( product, ingredient, packaging ) with error
 * @param context
 * @returns
 */
export const getCountAuditSheetError = async (
	context: QueryFunctionContext<readonly ['getCountAuditSheetError', GetSheetDataAuditType]>,
) => {
	try {
		const {
			queryKey: {
				1: { itemType, period, isErrorVolume },
			},
		} = context;
		const result = await Parse.Cloud.run('getCountAuditSheetError', {
			itemType,
			period,
			isErrorVolume
		});
		return result;
	} catch (error) {
		return Promise.reject(error);
	}
};
