import { useCallback } from 'react';

import { RoleLabel } from '@carbonmaps/shared/types/role.types';

import { useGetClientAuthQuery } from '../lib/react-query/features/auth/auth.hooks';

const useHasRoles = () => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const roles = authData?.roles;
	const companyCode = authData?.session.company?.code;

	const hasRoles = useCallback((allowedRoleLabels: RoleLabel[], { strictCompany }: { strictCompany?: boolean } = { strictCompany: true }) => {
		const roleLabels = roles
			?.map((role) => {
				if (strictCompany && role.label !== 'super_admin' && !role.name?.startsWith(`${companyCode}_`)) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					return null!;
				}

				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				return role.label!;
			})
			.filter(Boolean);

		const foundLabel = roleLabels?.find((label) => {
			return allowedRoleLabels.includes(label);
		});

		return !!foundLabel;
	}, [companyCode, roles]);

	return hasRoles;
};

export default useHasRoles;
