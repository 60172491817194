import { useCallback, useEffect } from 'react';

import { create } from 'zustand';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { CARBONE_INDICATOR } from '../../../utils/constants';

import ModelizationFilter from './ModelizationFilter';
import ModelizationHeader from './ModelizationHeader';
import ModelizationTable from './ModelizationTable';

// eslint-disable-next-line react-refresh/only-export-components
export const useStore = create((set: any) => {
	return {
		selectedRow: [] as any,
		onSelectionChange: (changedRows: any) => {
			return set((state: any) => {
				return {
					...state,
					selectedRow: changedRows,
				};
			});
		},
	};
});

const Modelization = (/* props: Props */) => {
	const { indicator, setIndicator, setBreadcrumbs } = useApp();
	const { t } = useTranslation();

	// init filters values
	const [searchQueryParams] = useSearchQueryParams();
	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Audit' },
			});
		},
		[analytics],
	);

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('Modelizations') }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	// ---- init switch mode with carbon ---- //
	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBONE_INDICATOR);
	}, [setIndicator, indicator]);

	// track event 	when the user lands on the Modelization listing page
	useEffect(() => {
		trackEvent('Audit Land Modelizations');
	}, []);

	return (
		<Container
			header={<><ModelizationHeader />
			</>
			}
			graph={
				null
			}
			actionFilter={<ModelizationFilter />}
			seoText={`${t('modelizations-title-module')} - Carbon Maps`}
		>
			<ModelizationTable filterOptions={searchQueryParams} manualSortBy />
		</Container>
	);
};

export default Modelization;
