import React from 'react';

import { css } from '@emotion/css';
import { OutlinedInput } from '@mui/material';

const styles = {
	container: css({
		width: '100%',
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent ',
		},

		':hover .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(106, 97, 138) !important',
		},
		'.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgb(106, 97, 138) !important',
		},
		textarea: {
			// height: '100px',
			height: 'var(--height-input)',
		},
	}),
};

export const EditableInput = ({
	value: initialValue,
	row: { index },
	column: { id, props },
	updateMyData, // This is a custom function that we supplied to our table instance
}: any) => {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = React.useState(initialValue);

	const onChange = (e: any) => {
		setValue(e.target.value);
	};

	// We'll only update the external data when the input is blurred
	const onBlur = () => {
		updateMyData(index, id, value);
	};

	// If the initialValue is changed external, sync it up with our state
	React.useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return <OutlinedInput inputComponent={props?.inputComponent === 'textarea' ? 'textarea' : 'input'}
		value={value} onChange={onChange} onBlur={onBlur} className={styles.container}
		style={{ ['--height-input' as any]: '100px' }}

	/>;
};
