import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { eachOfLimit } from 'async';

import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getFacetsSegment } from '@carbonmaps/ui/actions/analyse.actions';
import { getCompanySuppliers } from '@carbonmaps/ui/actions/supplier.actions';

import { IFilterFolder } from '../../../../components/Filter';
import { Option } from '../../../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../../../components/layout/list/FilterContainer';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { getLabelNodeFacet } from '../../../../utils/utils';

const styles = {
	a: css({
		paddingLeft: '0 !important',
	}),
};

const suppliersFolderLabel = 'Fournisseur';

const CategoryFilter = ({ segmentName, segmentValue }: any) => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const searchParams = searchQueryParams?.facetFilters ?? [];
	// custom facets suppliers
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);
	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// filters values
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	// -------------------------------------------------------------------------------------- //
	// -------------------------------- Use query ------------------------------------------- //
	// -------------------------------------------------------------------------------------- //
	// ---- fetch suppliers filter ---- //
	const { data: suppliersData, isLoading: isSUppliersLoading } = useQuery({
		queryKey: ['getAllSuppliersForCompany', { itemType: 'product', isFilter: true }],
		queryFn: getCompanySuppliers,
	});

	// ---- fetch facets ---- //

	// facets
	const {
		data: facets,
		isSuccess: isSuccessFetchFacets,
		isLoading: isFacetsLoading,
	} = useQuery({
		queryKey: [
			'getFacetsByCategoryValue',
			{
				categoryValue: segmentValue,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: async ({ queryKey }) => {
			const { 1: parameters } = queryKey;

			return await getFacetsSegment({ ...parameters, category: segmentName });
		},
	}) as any;

	// ---- create custom facet for supplier ----
	useEffect(() => {
		if (!suppliersData) return;

		const suppliersFolder: IFilterFolder = {
			label: suppliersFolderLabel,
			isFacet: false,
			showCount: false,
			options: suppliersData.map((sup) => {
				return {
					label: sup.name,
					value: sup.id,
					folderLabel: suppliersFolderLabel,
				};
			}),
		};

		setFilterFolders((folders) => {
			const foundIndex = folders.findIndex((e) => {
				return e.label === suppliersFolderLabel;
			});

			if (foundIndex === -1) {
				return [suppliersFolder, ...folders];
			}

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const newFolders = folders.with(foundIndex, suppliersFolder);
			return newFolders;
		});
	}, [suppliersData]);

	useEffect(() => {
		//	if there are filters in the url
		if (searchParams && searchParams.length) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			searchParams.forEach((item: any) => {
				const config = getFacetConfig(item.path, authData?.session.company);

				const arrayValues = item.values.map((v: any) => {
					const value = {
						facetPath: item.path,
						value: v,
						folderLabel: config?.label ?? item.path,
						label: getLabelValue(item.path, v),
						isFacetBucket: true,
						type: typeof v,
						global: item.global === true ? true : false,
						itemType: item.itemType,
					};

					currentSelectedValues.push(value);
					return value;
				});

				currentAppliedValuesFolders.push({
					isFacet: true,
					label: config?.label ?? item.path,
					options: arrayValues,
				});
			});

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchParams]);

	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facets) return;

			const facetFilterFolders: IFilterFolder[] = [];

			for await (const entry of Object.entries(facets.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;
				const { label, type, global, itemType } = getFacetConfig(key, authData?.session.company) ?? {};
				const facetLabel = label || getFacetLabel(key, authData?.session.company);
				const currentFacetFolder: IFilterFolder = {
					label: facetLabel,
					options: [],
					isFacet: true,
					path: key,
					showCount: true,
				};

				await eachOfLimit(buckets as any[], 100, async (bucket, k) => {
					if (!bucket.count) {
						return;
					}

					currentFacetFolder.options.push({
						value: bucket._id,
						label: getLabelValue(key, bucket._id),
						labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
							? getLabelNodeFacet(key, bucket._id, t)
							: undefined,
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: type ?? 'hardcoded-undefined-facet-type',
						global,
						itemType,
					});

					return;
				});

				facetFilterFolders.push(currentFacetFolder);
			}

			setFilterFolders((folders) => {
				const notFacetFolders = folders.filter((e) => {
					return !e.isFacet;
				});

				return [...notFacetFolders, ...facetFilterFolders];
			});
		};

		asyncWrapper();
	}, [facets, authData?.session.company]);

	return (
		<FilterContainer
			skeleton={isFacetsLoading || isSUppliersLoading}
			isLoading={false}
			appliedValuesByFolders={appliedValuesByFolders}
			setAppliedValuesByFolders={setAppliedValuesByFolders}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			folders={filterFolders}
			setSearchQueryParams={setSearchQueryParams}
			suppliersFolderLabel={suppliersFolderLabel}
			isTableLoading={false}
			isExportLoading={false}
			withSearch={false}
			withMore={false}
			className={styles.a}
		/>
	);
};

export default CategoryFilter;
