import { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useLocation, useParams } from 'react-router-dom';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import { useModelizationProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { useTranslation } from '../../../hooks/useTranslation';
import ProductModel from '../../../models/Product.model';

import _ from 'lodash';
import OtherIndicatorsTable from './OtherIndicatorsTable';

const toExponential = (num: number, digits = 2) => {
	return num.toExponential(digits);
};

const styles = {
	hidden: css({
		display: 'none',
	}),
};

const OtherIndicatorsBlock = () => {
	const theme = useTheme();
	const params = useParams();
	const location = useLocation();

	const { t } = useTranslation();

	const { data, isLoading } = useModelizationProduct({
		productId: params.productId!,
	});

	const otherIndicatorsColumns = useMemo(() => {
		return [
			{
				accessor: 'label', // accessor is the "key" in the data
				Header: (props: any) => {
					return (
						<Box padding="16px" bgcolor={theme.palette.grey[400]}>
							<Typography fontSize="14px" color={theme.palette.grey[900]} sx={{ opacity: 0 }}>
								invisible
							</Typography>
						</Box>
					);
				},
				Cell: (props: any) => {
					// console.log('====================================');
					// console.log('PROPS', props);
					// console.log('====================================');
					return (
						<Box padding="16px">
							<Typography fontSize="14px" color={theme.palette.grey[900]}>
								{props.value}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessor: 'measure', // accessor is the "key" in the data
				Header: (props: any) => {
					return (
						<Box padding="16px" bgcolor={theme.palette.grey[400]}>
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="right">
								{t('product-other-indicator-mesure')}
							</Typography>
						</Box>
					);
				},
				Cell: (props: any) => {
					// console.log('====================================');
					// console.log('PROPS', props);
					// console.log('====================================');
					return (
						<Box padding="16px">
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="right">
								{props.value.replace('.', ',')}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessor: 'unit', // accessor is the "key" in the data
				Header: (props: any) => {
					return (
						<Box padding="16px" bgcolor={theme.palette.grey[400]}>
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="left">
								{t('product-other-indicator-unit')}
							</Typography>
						</Box>
					);
				},
				Cell: (props: any) => {
					// console.log('====================================');
					// console.log('PROPS', props);
					// console.log('====================================');
					return (
						<Box padding="16px">
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="left">
								{props.value}
							</Typography>
						</Box>
					);
				},
			},
		];
	}, [theme.palette.grey, t]);

	const toValueIndicatorByView = (value: any) => {
		if (value === 0) {
			return '-';
		}

		return toExponential(value);
	};

	const otherIndicatorsData = useMemo(() => {
		if (!data) {
			return [];
		}

		if (_.isString(data)) {
			return [];
		}

		const productObject = new ProductModel(data);
		/* eslint-disable quotes */
		return [
			{
				label: t("Appauvrissement de la couche d'ozone"),
				measure: toValueIndicatorByView(productObject?.getIntensity('ozone1Total') || 0),
				unit: 'kg CFC11 eq/kg',
			},
			{
				label: t('Rayonnements ionisants'),
				measure: toValueIndicatorByView(productObject?.getIntensity('rayonnementsTotal') || 0),
				unit: 'kBq U235 eq/kg',
			},
			{
				label: t("Formation photochimique d'ozone"),
				measure: toValueIndicatorByView(productObject?.getIntensity('ozone2Total') || 0),
				unit: 'kg NMVOC eq/kg',
			},
			{
				label: t('Particules fines'),
				measure: toValueIndicatorByView(productObject?.getIntensity('particulesTotal') || 0),
				unit: 'disease increase/kg',
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances non-cancérogènes'),
				measure: toValueIndicatorByView(productObject?.getIntensity('toxicite1Total') || 0),
				unit: 'CTUh/kg',
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances cancérogènes'),
				measure: toValueIndicatorByView(productObject?.getIntensity('toxicite2Total') || 0),
				unit: 'CTUh/kg',
			},
			{
				label: t('Acidification terrestre et eau douce'),
				measure: toValueIndicatorByView(productObject?.getIntensity('acidificationTotal') || 0),
				unit: 'mol H+ eq/kg',
			},
			{
				label: t('Eutrophisation eau douce'),
				measure: toValueIndicatorByView(productObject?.getIntensity('eutrophisation1Total') || 0),
				unit: 'kg P eq/kg',
			},
			{
				label: t('Eutrophisation marine'),
				measure: toValueIndicatorByView(productObject?.getIntensity('eutrophisation2Total') || 0),
				unit: 'kg N eq/kg',
			},
			{
				label: t('Eutrophisation terrestre'),
				measure: toValueIndicatorByView(productObject?.getIntensity('eutrophisation3Total') || 0),
				unit: 'mol N eq/kg',
			},
			{
				label: t("Ecotoxicité pour écosystèmes aquatiques d'eau douce"),
				measure: toValueIndicatorByView(productObject?.getIntensity('ecotoxTotal') || 0),
				unit: 'CTUe/kg',
			},
			{
				label: t('Utilisation du sol'),
				measure: toValueIndicatorByView(productObject?.getIntensity('landUseTotal') || 0),
				unit: 'Pt/kg',
			},
			{
				label: t('Epuisement des ressources énergétiques'),
				measure: toValueIndicatorByView(productObject?.getIntensity('fossilUseTotal') || 0),
				unit: 'MJ/kg',
			},
			{
				label: t('Epuisement des ressources minéraux'),
				measure: toValueIndicatorByView(productObject?.getIntensity('mineralUseTotal') || 0),
				unit: 'kg Sb eq/kg',
			},
		];
		/* eslint-enable quotes */
	}, [data, t]);

	const [hidden, setHidden] = useState(true);

	return (
		<Box mb="40px" /* height="100%" */>
			{/*  eslint-disable-next-line quotes */}
			<BButton
				addStyles={{ width: '100%', marginBottom: '8px' }}
				variant="secondary"
				// eslint-disable-next-line quotes
				label={
					hidden ? (
						<>
							{/* eslint-disable-next-line quotes */}
							{t("Voir plus d'indicateurs")}
							<ChevronDown size={18} />
						</>
					) : (
						<>
							{t('Voir moins')}
							<ChevronUp size={18} />
						</>
					)
				}
				onClick={() => {
					return setHidden(!hidden);
				}}
			/>
			{/* <Box mb="16px">
				<BlockTitle>{t('product-other-indicator')}</BlockTitle>
			</Box> */}
			<Box mb={pxToRem(100)}>
				{/* <TableComponent columns={otherIndicatorsColumns} loading={false} fetchData={() => {}} data={[]} pageCount={0} /> */}
				<div className={cx(hidden ? styles.hidden : '')}>
					<OtherIndicatorsTable columns={otherIndicatorsColumns} data={otherIndicatorsData} />
				</div>
			</Box>
		</Box>
	);
};

export default OtherIndicatorsBlock;
