import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

// import { siteColors } from '../../../lib/colors';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import QuoteModel from '../../../models/Quote.model';

Font.register({
	family: 'Inter',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
			fontWeight: 100,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
			fontWeight: 200,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
			fontWeight: 300,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
			fontWeight: 400,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
			fontWeight: 600,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
			fontWeight: 600,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
			fontWeight: 800,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
			fontWeight: 900,
		},
	],
});

// Create styles
const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	topView: {
		// backgroundColor: 'red',
		display: 'flex',
		alignItems: 'flex-end',
	},
	topViewRight: {
		// backgroundColor: 'blue',
		width: 140,
	},
	smallText: {
		color: '#474154',
		fontSize: 10,
		marginBottom: 4,
		fontFamily: 'Inter',
		textAlign: 'center',
	},
	smallTextTo: {
		fontSize: 10,
		marginBottom: 18,
		color: '#9587AE',
	},

	logoClientWrapper: {
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	logoClientWrapperLeft: {
		// backgroundColor: 'blue',
		width: '50%',
	},
	clientLogoContainer: {
		width: 80,
		height: 80,
		// backgroundColor: 'red',
	},

	infoRow: {
		flexDirection: 'row',
		marginBottom: 19,
		// backgroundColor: 'red',
	},
	infoRowLeft: {
		width: '50%',
	},
	companyName: {
		fontFamily: 'Inter',
		fontWeight: 600,
		fontSize: 12,
		lineHeight: 1.5,
	},
	infoText: {
		fontSize: 12,
		color: '#474154',
		lineHeight: 1.5,
	},
});

type Props = {
	// title: string;
	quote: QuoteModel | undefined;
	companyLogo: string | undefined;
	form: any | undefined;
	// name: string | undefined;
	indicator?: any;
	t: any;
};

// Create Document Component
export const MyDocument = ({ quote, companyLogo, form, indicator, t }: Props) => {
	let total = 0;
	let graphData: any[] = [
		// {
		// 	groupName: 'Lundi 18 décembre',
		// 	impactValue: 10, // number,
		// },
	];

	const getPrecision = (value: number) => {
		return value < 100 ? 2 : 0;
	};

	return (
		<Document>
			<Page style={styles.body} wrap={true}>
				<View style={styles.topView}>
					<View style={styles.topViewRight}>
						<Text style={styles.smallText}>{t('Devis carbone généré avec')}</Text>
						<Image src="/carbonmaps-logo-pdf.png" />
					</View>
				</View>

				<View style={styles.logoClientWrapper}>
					<View style={styles.logoClientWrapperLeft}>
						<View style={styles.clientLogoContainer}>
							<Image
								// src="http://localhost:5175/sample-logo.png"
								src={companyLogo}
								// src="https://carbonmaps-sckh45qrbq-od.a.run.app/FLMr1sSKA70pWoE40hd8ObW8jXOmWeuca7KiDlTYTNQ/s:600:600:0/g:ce/q:100/Z3M6Ly9jYXJib25tYXBzLXByZXByb2R1Y3Rpb24uYXBwc3BvdC5jb20vTG9nby1TQ0FNQVJLMjAxOS0xLlBORw.png"
							/>
						</View>
					</View>

					<Text style={styles.smallTextTo}>A l'attention de</Text>
				</View>

				<View style={styles.infoRow}>
					<View style={styles.infoRowLeft}>
						<Text style={styles.companyName}>
							{/* Sodexo */}
							{quote?.quote?.company?.get('name')}
						</Text>
						<Text style={styles.infoText}>{form?.getValues('interlocutorUserName')}</Text>
						<Text style={styles.infoText}>{form?.getValues('interlocutorUserEmail')}</Text>
						<Text style={styles.infoText}>{form?.getValues('interlocutorUserPhone')}</Text>
					</View>
					<View>
						<Text style={styles.companyName}>{form?.getValues('clientEnterpriseName')}</Text>
						<Text style={styles.infoText}>
							{`${form?.getValues('clientInterlocutorFirstName') || ''} ${
								form?.getValues('clientInterlocutorName') || ''
							}`}
						</Text>
						<Text style={styles.infoText}>{form?.getValues('clientInterlocutorEmail')}</Text>
					</View>
				</View>

				<Text
					style={{
						marginTop: 20,
						marginBottom: 20,
						fontFamily: 'Inter',
						fontWeight: 300,
						fontSize: 14,
						// backgroundColor: 'red',
					}}
				>
					{/* TITLE */}
					{form.getValues('name')}
				</Text>

				{form.getValues('customMessage') ? (
					<Text
						style={{
							fontFamily: 'Inter',
							fontSize: 10,
							marginBottom: 20,
						}}
					>
						{form.getValues('customMessage')}
					</Text>
				) : null}

				{quote?.quote?.groups?.map((group: any, index: number) => {
					let subTotal = 0;

					group.products?.map((product: any, index: number) => {
						const impactProduct = Number(
							indicator === CARBONE_INDICATOR
								? product.gesTotalImpact?.toFixed(0)
								: product.waterUseTotalImpact?.toFixed(0),
						);
						subTotal = subTotal + impactProduct;
					});
					graphData.push({
						groupName: group.name === ' ' || !group.name ? 'Prestation sans nom' : group.name,
						impactValue: subTotal,
					});
				})}
				{(() => {
					// graphData.sort((a, b) => {
					// 	return b.impactValue - a.impactValue;
					// });

					// const _dataGraph = graphData.slice(0, 10);
					const _dataGraph = graphData;
					const maxGroupImpact = _.maxBy(_dataGraph, 'impactValue')?.impactValue;

					graphData = _dataGraph.map((dataItem, index) => {
						const impactPercent = (dataItem.impactValue / (maxGroupImpact || 1)) * 100;
						return {
							...dataItem,
							impactPercent: _.isNaN(impactPercent) ? 0 : impactPercent,
						};
					});

					return null;
				})()}

				{/* Graph */}
				{graphData.length > 1 ? (
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'flex-end',
							gap: 10,
							justifyContent: 'center',
							height: 150,
							borderBottomColor: '#E0E0FE',
							borderBottomWidth: 1.5,
							borderBottomStyle: 'solid',
							marginTop: 30,
							marginBottom: '120px',
						}}
					>
						{graphData.map((dataItem, index) => {
							return (
								<View
									key={index}
									style={{
										// height: `calc(${dataItem.impactPercent}% + 10px)`,
										height: '100%',
										width: `calc(100% / ${graphData.length} - 6px)`,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'flex-end',
										position: 'relative',
									}}
								>
									{/* <Text style={{ fontSize: 10, textAlign: 'center' }}>{dataItem.impactValue}</Text> */}
									<Text style={{ fontSize: graphData.length > 15 ? 7 : 10, textAlign: 'center' }}>
										{dataItem.impactValue}
									</Text>{' '}
									<View
										style={{
											width: 'calc(100% - 10px)',
											backgroundColor: '#E0E0FE',
											// height: '90%',
											height: `${dataItem.impactPercent}%`,
										}}
									/>
									<View
										style={{
											position: 'absolute',
											width: 150,
											maxWidth: 150,
											// height: 200,
											right: '50%',
											top: '100%',
											transformOrigin: 'top right',
											transform: 'rotate(-70deg) translate(-10px, 0)',
											// overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										<Text style={{ marginBottom: '120px', fontSize: 10, textAlign: 'right' }}>
											{_.truncate(dataItem.groupName, { length: 20 })}
										</Text>
									</View>
								</View>
							);
						})}
					</View>
				) : null}

				{quote?.quote?.groups?.map((group: any, index: number) => {
					let subTotal = 0;

					return (
						/* Table */
						<View key={index}>
							{/* Table header */}
							<View
								style={{
									flexDirection: 'row',
									backgroundColor: '#F9F8FB',
									padding: '14 14 14 14',
									borderBottom: '2 solid #E0E0FE',
								}}
							>
								<View style={{ width: '64%' }}>
									<Text style={{ fontSize: 12, color: '#7176FF' }}>
										{/* Lundi 18 décembre */}
										{group.name === ' ' || !group.name ? 'Prestation sans nom' : group.name}
									</Text>
									<Text style={{ fontSize: 8, color: '#9587AE', marginTop: 4 }}>Unités</Text>
								</View>
								<View style={{ textAlign: 'right', width: '20%', alignItems: 'flex-end' }}>
									<Text style={{ fontSize: 9, color: '#9587AE' }}>IMPACT PAR UNITÉ</Text>

									{indicator === CARBONE_INDICATOR ? (
										<Image style={{ width: 60, marginTop: 4 }} src={'/unit-par-unite-pdf.png'} />
									) : (
										<Image style={{ width: 40, marginTop: 4 }} src={'/water_unity.png'} />
									)}
								</View>
								<View style={{ textAlign: 'right', flexGrow: 1, alignItems: 'flex-end' }}>
									<Text style={{ fontSize: 9, color: '#9587AE' }}>IMPACT TOTAL</Text>
									{/* <Text style={{ fontSize: 8 }}>kgₑCO₂</Text> */}
									{/* <UnitImpactGray style={{ width: 30 }} /> */}

									{indicator === CARBONE_INDICATOR ? (
										<View style={{ width: 35, marginTop: 4 }}>
											<Image src={'/unit-impact-tableau-pdf.png'} />
										</View>
									) : (
										<View style={{ width: 16 }}>
											<Image style={{ width: 16 }} src={'/water_impact_unity.png'} />
										</View>
									)}
								</View>
							</View>

							{/* Table row */}
							{group.products?.map((product: any, index: number) => {
								const impactProduct = Number(
									indicator === CARBONE_INDICATOR
										? product.gesTotalImpact?.toFixed(0)
										: product.waterUseTotalImpact?.toFixed(0),
								);
								subTotal = subTotal + impactProduct;

								return (
									<View
										key={index}
										style={{ flexDirection: 'row', padding: '10 14 10 14', borderTop: '1 solid #E0E0FE' }}
									>
										<View style={{ width: '64%', flexDirection: 'row' }}>
											<Text style={{ fontSize: 7, marginRight: 10, fontFamily: 'Inter' }}>
												{/* 14 */}
												{/* {product?.quantity ? formatNumber(product?.quantity || 0, undefined, 0) : ''} */}
												{product?.quantity || '-'}
											</Text>
											<Text style={{ fontSize: 8 }}>
												{/* PAIN DE CAMPAGNE TRANCHÉ 500G */}
												{product.label}
											</Text>
										</View>
										<View style={{ width: '20%' }}>
											<Text style={{ fontSize: 8, textAlign: 'right', fontFamily: 'Inter' }}>
												{formatNumber(
													indicator === CARBONE_INDICATOR
														? product?.gesImpactPortion || 0
														: product?.waterUseImpactPortion || 0,
												)}
											</Text>
										</View>
										<View style={{ flexGrow: 1, alignItems: 'flex-end' }}>
											<Text style={{ fontSize: 8, textAlign: 'right', fontFamily: 'Inter', fontWeight: 600 }}>
												{formatNumber(impactProduct, undefined, getPrecision(impactProduct))}
											</Text>
										</View>
									</View>
								);
							})}

							{/* {(total = total + subTotal)} */}
							{(() => {
								total = total + subTotal;
								// graphData.push({
								// 	groupName: group.name === ' ' || !group.name ? 'Prestation sans nom' : group.name,
								// 	impactValue: subTotal,
								// });

								return null;
							})()}

							{/* Table footer */}
							<View
								style={{
									flexDirection: 'row',
									padding: '16 16 16 16',
									justifyContent: 'space-between',
									alignItems: 'center',
									borderTop: '2.5 solid #E0E0FE',
								}}
							>
								<View style={{ flexDirection: 'row' }}>
									<Text style={{ fontSize: 9, marginRight: 6, color: '#9587AE' }}>TOTAL UNITÉS</Text>
									<Text style={{ fontSize: 9, fontFamily: 'Inter' }}>
										{/* 254 */}
										{(() => {
											let totalInGroup = 0;

											group.products?.forEach((product: any) => {
												totalInGroup = totalInGroup + Number(product?.quantity || 0);
											});

											return formatNumber(totalInGroup, undefined, 0);
										})()}
									</Text>
								</View>

								<View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
									<Text style={{ fontSize: 12, marginRight: 4, color: '#7176FF', fontFamily: 'Inter' }}>
										{formatNumber(subTotal, undefined, getPrecision(subTotal))}
									</Text>
									{indicator === CARBONE_INDICATOR ? (
										<UnitImpactPurple indicator={indicator} />
									) : (
										<View style={{ width: 16, marginTop: -10 }}>
											<Image style={{ width: 16 }} src={'/water_impact_unity.png'} />
										</View>
									)}
								</View>
							</View>
						</View>
					);
				})}
				{/* End of table */}

				{/* Doc Ending Quote */}
				<View wrap={false} style={{ marginTop: 32 }}>
					<View style={{ alignItems: 'center', paddingTop: 32, borderTop: '1.5 solid #E0E0FE' }}>
						<Text style={{ marginBottom: 8, fontSize: 10, fontFamily: 'Inter', fontWeight: 300 }}>
							{t(
								indicator === WATER_INDICATOR
									? 'Consommation en eau de la prestation'
									: 'Impact carbone de la prestation',
							)}
						</Text>
						<View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
							<Text style={{ fontSize: 28, color: '#7176FF', marginRight: 9, fontFamily: 'Inter', fontWeight: 600 }}>
								{formatNumber(total, undefined, getPrecision(total))}
							</Text>
						</View>
						<View style={{ marginTop: 5 }}>
							{indicator === CARBONE_INDICATOR ? (
								<Text style={{ fontSize: 12, color: '#7176FF' }}>
									kg<Text style={{ verticalAlign: 'sub' }}>eq</Text>CO<Text style={{ verticalAlign: 'sub' }}>2</Text>
								</Text>
							) : (
								<Text style={{ fontSize: 12, color: '#7176FF' }}>
									m<Text style={{ verticalAlign: 'super' }}>3</Text>
								</Text>
							)}
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

const UnitImpactPurple = ({ indicator }: any) => {
	return (
		<View style={{ width: 37 }}>
			<Image src={indicator === CARBONE_INDICATOR ? '/unit-impact-pdf-purple.png' : '/water_impact_unity.png'} />
		</View>
	);
};
