import { useQuery } from '@tanstack/react-query';
import { use } from 'echarts';
import { create } from 'zustand';

import {
	getCarbonTrajectoryGraphAction,
	getCommentsAction,
	getCountResultAction,
	getDataTableAction,
	getGlobalIndicatorAction,
	getIndicatorStatAction,
	getStatDiagAction,
	getTooltipGraphAction,
} from '@carbonmaps/ui/actions/apbo.actions';

/**
 * year options store
 */
export const useYearOptions = create((set: any) => {
	return {
		options: [],
		value: new Date().getFullYear(),
		// set options
		setOptions: (options: any) => {
			return set((state: any) => {
				return {
					options,
					value: state.value,
				};
			});
		},
		// set value
		setValue: (value: any) => {
			return set((state: any) => {
				return {
					options: state.options,
					value,
				};
			});
		},
	};
});

/**
 * global indicator
 * @returns
 */
export const useGlobalIndicator = () => {
	const { value } = useYearOptions();
	const queryResult = useQuery({
		queryKey: ['globalIndicatorData', { year: value }],
		queryFn: getGlobalIndicatorAction,
	});

	return queryResult;
};

/**
 * get count result
 * @returns
 */
export const useCountResult = () => {
	const queryResult = useQuery({
		queryKey: ['getCountResult'],
		queryFn: getCountResultAction,
	});

	return queryResult;
};

/**
 * useTrajectoryGraph
 * @returns
 */
export const useCarbonTrajectoryGraph = () => {
	const queryResult = useQuery({
		queryKey: ['getCarbonTrajectoryGraph'],
		queryFn: getCarbonTrajectoryGraphAction,
	});

	return queryResult;
};

export const useToolTip = (year: number, field: string) => {
	const queryResult = useQuery({
		queryKey: ['getTooltipGraph', { year, field }],
		queryFn: getTooltipGraphAction,
	});

	return queryResult;
};

export const useDataTable = (year: number) => {
	const queryResult = useQuery({
		queryKey: ['getDataTable', { year }],
		queryFn: getDataTableAction,
	});

	return queryResult;
};

export const useComments = () => {
	const queryResult = useQuery({
		queryKey: ['getComments'],
		queryFn: getCommentsAction,
	});

	return queryResult;
};

export const useDiagStat = () => {
	const queryResult = useQuery({
		queryKey: ['getStatDiag'],
		queryFn: getStatDiagAction,
	});

	return queryResult;
};

export const useIndicatorStat = () => {
	const queryResult = useQuery({
		queryKey: ['getIndicatorStat'],
		queryFn: getIndicatorStatAction,
	});

	return queryResult;
};
