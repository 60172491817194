import { ReactNode, useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import CircularProgress from '@mui/material/CircularProgress';
import useTheme from '@mui/material/styles/useTheme';

import { resizeFile } from '@carbonmaps/ui/utils/utils';

import { translation } from '../../../i18n/translation';
import AvatarContent from '../AvatarContent';
import BButton from '../BButton';
import BInputFile from '../BInputFile';

import { classes } from './FormStyle';

interface FormContainerProps {
	avatar?: boolean;
	isSuccess?: boolean;
	isError?: boolean;
	withSave?: boolean;
	defaultLogo?: any;
	onSubmit: (e?: any) => void;
	children: React.ReactNode;
	title?: string;
	subtitle?: string;
	setFile?: (e: any) => any;
	file?: any;
	buttonTitle?: string;
	addStyles?: any;
	alertSuccess?: React.ReactNode;
	alertError?: React.ReactNode;
	loading?: boolean;
	withCancel?: boolean;
	onCancel?: () => void;
	confirmButton?: ReactNode;
	t?: any;
}

const FormContainer = ({
	avatar,
	file,
	setFile,
	onSubmit,
	children,
	title,
	subtitle,
	defaultLogo,
	buttonTitle = 'button-save-default',
	addStyles,
	isSuccess,
	alertSuccess,
	alertError,
	isError,
	t,
	withSave = false,
	loading = false,
	withCancel = false,

	onCancel,
	confirmButton,
}: FormContainerProps) => {
	const theme = useTheme();
	const styles = classes(theme);
	const [showAlert, setshowAlert] = useState(false);
	const [fileUrl, setFileUrl] = useState(null);

	const trans = t || translation;

	const onResize = async (value: any) => {
		if (value) {
			const image: any = await resizeFile(value);
			setFileUrl(image);
			setFile && setFile(image);
		} else {
			setFile && setFile(null);
		}
	};

	useEffect(() => {
		if (isSuccess || isError) {
			setshowAlert(true);
			// setTimeout(() => {
			// 	setshowAlert(false);
			// }, 6000);
		}
	}, [isSuccess, isError]);

	return (
		<form css={{ ...addStyles } as any} encType="multipart/form-data">
			{title && <h3 css={styles.title}>{title}</h3>}
			<>
				{isSuccess && showAlert && alertSuccess}
				{isError && showAlert && alertError}
			</>
			{subtitle && <p css={styles.subtitle}>{subtitle}</p>}
			{avatar && (
				<AvatarContent url={fileUrl ? URL.createObjectURL(fileUrl) : defaultLogo} addStyle={addStyles?.logo}>
					<div css={{ maxWidth: 350 }}>
						<p>{trans('upload-photo-description')}</p>
						<div css={{ position: 'relative' }}>
							<BInputFile
								value={file}
								onChange={onResize}
								accept="image/png, image/gif, image/jpeg"
								loading={loading}
								label={trans('upload-photo-button-text') as any}
							/>
						</div>
					</div>
				</AvatarContent>
			)}
			{children}
			<div className={cx('flexRow  ')} css={styles.containerButton}>
				{confirmButton}
				{withSave && (
					<BButton
						onClick={() => {
							return onSubmit(file);
						}}
						addStyles={{ marginTop: 20, fontWeight: 600, fontSize: 16 }}
						label={
							loading ? <CircularProgress size={20} css={{ color: theme.palette.common.white }} /> : trans(buttonTitle)
						}
						variant="primary"
					/>
				)}

				{withCancel && onCancel && (
					<BButton
						onClick={() => {
							return onCancel();
						}}
						addStyles={{ marginTop: 20, fontWeight: 600, fontSize: 16 }}
						label={trans('cancel-button')}
						variant="secondary"
					/>
				)}
			</div>
		</form>
	);
};

export default FormContainer;
