// import React from 'react';

import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import { pxToRem } from '@carbonmaps/ui/utils/styles';

const BlockCard = styled(Box)(({ theme }) => {
	return {
		padding: pxToRem(24),
		border: `solid 1px ${theme.palette.grey[500]}`,
		borderRadius: pxToRem(16),
		marginBottom: pxToRem(32),
		width: '100%',
	};
});

export default BlockCard;
