import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses, type TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
	return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			// color: 'rgba(0, 0, 0, 0.87)',
			color: theme.palette.common.black,
			boxShadow: theme.shadows[2],
			fontSize: 12,
		},
	};
});

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const TruncatedSimpleCell = ({
	value,
	row: { index },
	column: { id },
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				padding: '8px',
				fontSize: '16px',
				fontWeight: 500,
				color: theme.palette.grey[900],
				display: 'flex',
			}}
		>
			<LightTooltip title={value}>
				<Typography maxWidth="300px" noWrap width="fit-content" textAlign="right">
					{value}
				</Typography>
			</LightTooltip>
		</Box>
	);
};

export default TruncatedSimpleCell;
