import { create } from 'zustand';

/**
 * use zustand
 * hooks to store rows table selected
 */
export const useDataSimulation = create((set: any) => {
	return {
		simulation: {},
		cloneSimulation: null,
		onDataChange: (simulation: any) => {
			return set((prevState: any) => {
				return {
					simulation: simulation,
					// clone simulation because we need to revert after change
					...(!prevState?.cloneSimulation &&
						(simulation?.ingredients?.length || simulation?.emballages?.length) && {
							cloneSimulation: formatterData(simulation),
						}),
				};
			});
		},

		onRowChange: (item: any, itemType: 'ingredient' | 'packaging' = 'ingredient') => {
			return set((prevState: any) => {
				const prevMap = prevState?.cloneSimulation[itemType];

				// set only when not exist in clone data
				if (!prevMap?.get(item?.objectId)) {
					prevMap?.set(item?.objectId, item?.value);
				}

				return {
					...prevState,
					cloneSimulation: {
						...prevState?.cloneSimulation,
						[itemType]: prevMap,
					},
				};
			});
		},

		onIngredientData: (ingredients: any) => {
			return set((prevState: any) => {
				return {
					simulation: {
						...prevState?.simulation,
						ingredients,
					},
				};
			});
		},

		onPackagingDataChange: (emballages: any) => {
			return set((prevState: any) => {
				return {
					simulation: {
						...prevState?.simulation,
						emballages,
					},
				};
			});
		},
	};
});

const formatterData = (simulation: any) => {
	const dataMapIngredient = new Map();
	const dataMapPackaging = new Map();

	// map composition ingredient
	(simulation?.ingredients || []).forEach((item: any) => {
		dataMapIngredient.set(item?.ingredient?.id, parseFloat(item?.composition || 0));
	});

	(simulation?.emballages || []).forEach((item: any) => {
		dataMapPackaging.set(item?.packaging?.id, parseFloat(item?.weight || 0));
	});

	return {
		ingredient: dataMapIngredient,
		packaging: dataMapPackaging,
	};
};
