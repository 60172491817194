import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getCompanySheetFacetAction } from '@carbonmaps/ui/actions/company.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';

import CreateUser from './CreateUser';
import UserHeader from './UserHeader';
import UsersTable from './UsersTable';

const Users = (/* props: Props */) => {
	const { setBreadcrumbs } = useApp();
	// translation
	const { t } = useTranslation();
	// init filters values
	const [searchQueryParams] = useSearchQueryParams();
	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([
			{ text: t('company-breadcrumbs'), link: `${FRONT_PATH_NAMES.superAdminSettings}/companies` },
			{ text: t('users-breadcrumbs') },
		]);
	}, [setBreadcrumbs, t]);

	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';

	// ---- fetch data --- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getCompanySheetFacet',
			{
				companyId,
			} as any,
		],
		queryFn: getCompanySheetFacetAction,
	});

	return (
		<Container header={<UserHeader subtitle={data?.name} />} seoText={`${t('users-breadcrumbs')} - Carbon Maps`}>
			{companyId && companyId !== '' && <CreateUser />}
			<UsersTable filterOptions={searchQueryParams} manualSortBy={false} isCompanyArchived={data?.deleted} />
		</Container>
	);
};

export default Users;
