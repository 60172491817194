import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SuppliersTable from '../../_/SuppliersTable';
import SuppliersFilter from './SuppliersFilter';
import TradeOffSupplier from './SupplierTradeoff';

const Impacts = () => {
	const { t } = useTranslation();
	const { setBreadcrumbs } = useApp();

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('suppliers') }, { text: 'Impacts' }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	return (
		<div
			css={{
				'.filterContainer-wrapper': {
					paddingLeft: 0,
					paddingRight: 0,
				},
			}}
		>
			<SuppliersFilter />
			<TradeOffSupplier />
			<SuppliersTable manualSortBy />
		</div>
	);
};

export default Impacts;
