import Typography from '@mui/material/Typography';

import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Unit from '../../units/Unit';

import Popover from './Popover';
import PopoverItem from './PopoverItem';

const ImpactCarbonePopover = ({ item }: any) => {
	return (
		<Popover>
			{item?.carbonImpact <= 0 ? (
				<Typography className="fontWeight600">Volume incorrect</Typography>
			) : (
				<PopoverItem
					label={item?.volume ? WORDING_TERMS.IMPACT : 'Volume non renseigné'}
					value={
						item?.volume ? (
							<>
								{formatNumber(item?.carbonImpact || 0, undefined, 0)}
								{' '}
								<Unit
									measure="carbonImpactTon"
								// sx={{ textTransform: 'uppercase' }} // don't need uppercase for unit
								/>
							</>
						) : (
							''
						)
					}
				/>
			)}
		</Popover>

	);
};

export default ImpactCarbonePopover;
