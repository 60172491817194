import { css } from '@emotion/css';
import MUILink from '@mui/material/Link';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Popover from '../../components/Popover';
import { useTranslation } from '../../hooks/useTranslation';
import Lock from '../../layouts/_common/Lock';
import { siteColors } from '../../lib/colors';
const styles = {
	triggerContainer: css({
		// width: 210,
		// marginLeft: 35,
		width: 220,
		marginLeft: 20,
		marginTop: 40,
		marginBottom: 32,
		cursor: 'pointer',
		borderRadius: 6,
		// background: 'red',
		// padding: '2px',
		padding: '16px',
		// marginLeft: '-16px',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
	text: css({
		cursor: 'pointer !important',
	}),
};

const LockPopover = () => {
	const { t } = useTranslation();
	return (
		<Popover
			disableScrollLock
			anchorOrigin={{
				horizontal: 'left',
				vertical: 'bottom',
			}}
			trigger={
				<div className={styles.text}>
					<Lock />
				</div>
			}
			renderContent={({ close }) => {
				return (
					<MUILink
						component={Link}
						to="mailto:support@carbonmaps.io"
						display="block"
						className={styles.triggerContainer}
					>
						{t('active-module-text')}
					</MUILink>
				);
			}}
		/>
	);
};

export default LockPopover;
