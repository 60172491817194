import React from 'react';

import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles/createTheme';
import useTheme from '@mui/material/styles/useTheme';

const classes = (theme: Theme) => {
	return {
		container: {
			display: 'flex',
			width: '100%',
			flexDirection: 'column',
			// width: '100%',
			gap: 8,
			'.MuiInputBase-root': {
				borderRadius: '10px!important',
			},
			'& .MuiInputBase-input': {
				padding: '10.5px 10px',
			},
			'& label.Mui-focused': {
				color: 'green',
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: 'green',
			},
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: theme.palette.grey[700],
				},
				'&:hover fieldset': {
					borderColor: theme.palette.primary.main,
				},
				'&.Mui-focused fieldset': {
					border: `1px solid ${theme.palette.primary.main}`,
					boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
				},
				'& .Mui-error': {
					borderColor: 'red',
				},
			},
			label: {
				fontWeight: 600,
				fontSize: 16,
			},
		},
		select: {
			height: 42,
			width: '100%',
			borderRadius: 8,
			fontSize: 16,
			paddingInline: 5,
			fontFamily: 'Inter,Helvetica,Arial,sans-serif',
			':hover': {
				border: `1px solid ${theme.palette.primary.main}`,
			},
			':focus': {
				boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
			},
		},
	};
};

interface BSelectNativeProps {
	options: { label: string; value: any }[];
	errorMessage?: any;
	label?: string;
	required?: boolean;
	name: string;
	id?: string;

	renderLabel?: (option: any) => React.ReactNode;
	error?: boolean;
}

const CustomSelect = ({ label, errorMessage, options, renderLabel, ...rest }: BSelectNativeProps, ref: any) => {
	const theme = useTheme();
	const styles = classes(theme);
	return (
		<div className="input-container" css={styles.container as any}>
			<label>
				{label} {rest.required && '*'}
			</label>
			{!!errorMessage && (
				<Typography variant="inherit" color={'red'}>
					{errorMessage}
				</Typography>
			)}
			<select {...rest} ref={ref} css={styles.select}>
				{options.map((option) => {
					return (
						<option key={option.value} value={option.value}>
							{renderLabel ? renderLabel(option) : option.label}
						</option>
					);
				})}
			</select>
		</div>
	);
};

const BSelectNative = React.forwardRef(CustomSelect);

export default BSelectNative;
