import { useCallback, useEffect, useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import fr from 'dayjs/locale/fr';
import { Box, Carrot, ListChecks, PackageOpen } from 'lucide-react';

import { INGREDIENT, PACKAGING, PRODUCT } from '@carbonmaps/shared/utils/audit';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useDataAudit } from '@carbonmaps/ui/hooks/useAudit';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockTitle from '../../components/BlockTitle';
import AuditCard from '../../components/audit/AuditCard';
import GlobalCard from '../../components/audit/GlobalCard';
import Container from '../../components/layout/list/Container';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import * as auditUtils from '../../utils/audit.utils';

import { siteColors } from '../../lib/colors';
import ErrorVolumeCard from '../dashboard/v2/auditTab/ErrorVolumeCard';
import AuditHeader from './AuditHeader';

const styles = {
	container: css({
		paddingTop: 32,
		gap: 42,
	}),

	errorVolumeCard: css({
		minHeight: '145px !important',
	}),

	content: css({
		// //display: 'grid !important',
		marginBottom: 48,
		// [XL_UP]: {
		// 	gap: 100,
		// 	justifyContent: 'center !important',
		// },
		// //gridTemplateColumns: '1fr 1fr 1fr 1fr',
		// gap: 80,
		// [SMALL_DESKTOP]: {
		// 	gridTemplateColumns: '1fr 1fr 1fr !important',
		// 	gap: 32,
		// },
		// [tablet]: {
		// 	//gridTemplateColumns: '1fr 1fr 1fr !important',
		// 	gap: 24,
		// },

		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		// background: 'red',
		gap: '4%',

		// width: '100%',
	}),
};

const Audit = () => {
	const { setBreadcrumbs } = useApp();
	const { t, i18n } = useTranslation();

	const { selectedYear } = useYearSelection();

	// // ---- fetch data ---- //
	// const { data: result, isLoading } = useQuery({
	// 	queryKey: ['getDataAudit', { selectedYear }],
	// 	queryFn: getDataAudit,
	// });

	const { data: result, isLoading } = useDataAudit({});

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Audit' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		setBreadcrumbs([/* { text: 'Auditer' },  */ { text: t('audit-breadcrumbs') }]);
	}, [setBreadcrumbs, t]);

	const theme = useTheme();

	const updateDate = useMemo(() => {
		if (!result) return undefined;

		return `${t('audit-update-date-at')} ${dayjs(result?.version?.updatedAt)
			.locale(i18n?.language)
			.format('D MMMM YYYY')}`;
	}, [result, t, i18n]);

	useEffect(() => {
		trackEvent('Audit Dataset');
	}, [trackEvent]);

	return (
		<Container header={<AuditHeader subtitle={updateDate} />} seoText="Auditer - Carbon Maps">
			<div className={cx('flexColumn width100', styles.container)}>
				<div className="flexRow gap24 width100">
					<GlobalCard />
					<ErrorVolumeCard isCenter={false} isBottomButton={false} className={styles.errorVolumeCard} gap={16} />
				</div>

				<div className="width100">
					<BlockTitle icon={<ListChecks color={theme.palette.grey[700]} />}>{t('audit-card-title')}</BlockTitle>

					<div className={cx(styles.content)}>
						<AuditCard
							title={t('audit-product-card-title')}
							icon={<Box color={siteColors.primary} />}
							color={siteColors.primary}
							data={auditUtils.getAuditProduct(result as any)}
							value={auditUtils.getProductValid(result as any)}
							isLoading={isLoading}
							itemType={PRODUCT}
							isValid={auditUtils.isValidData(result as any, PRODUCT)}
							content={t('valid_products_dataset', {
								products: t('products'),
								products_lowercase: toLowerCase(t('products')),
							})}
							buttonText={`${result?.dataAuditProducts.count ?? 0} ${t('products_concerned_dataset', {
								products: t('products'),
								products_lowercase: toLowerCase(t('products')),
							})}`}
						/>
						<AuditCard
							title={t('audit-ingredient-card-title', {
								recipes: toLowerCase(t('recipes')),
							})}
							icon={<Carrot color="#557A06" />}
							color="#557A06"
							data={auditUtils.getDataAuditIngredient(result as any)}
							value={auditUtils.getIngredientValid(result as any)}
							isLoading={isLoading}
							itemType={INGREDIENT}
							isValid={auditUtils.isValidData(result as any, INGREDIENT)}
							content={t('valid_recipes_dataset', {
								recipe: t('recipe'),
								recipe_lowercase: toLowerCase(t('recipe')),
							})}
							buttonText={`${result?.dataAuditIngredients.count ?? 0} ${t('ingredients_concerned_dataset', {
								ingredients: toLowerCase(t('ingredients')),
							})}`}
						/>
						<AuditCard
							title={t('audit-packaging-card-title')}
							icon={<PackageOpen color={siteColors.brown500} />}
							color={siteColors.brown500}
							data={auditUtils.getDataAuditPackaging(result as any)}
							value={auditUtils.getPackagingValid(result as any)}
							isLoading={isLoading}
							itemType={PACKAGING}
							isValid={auditUtils.isValidData(result as any, PACKAGING)}
							content={t('valid_packagings_dataset', {
								packaging: t('packaging'),
								packaging_lowercase: toLowerCase(t('packaging')),
							})}
							buttonText={`${result?.dataAuditPackaging.count ?? 0} ${t('products_concerned_dataset', {
								products: t('products'),
								products_lowercase: toLowerCase(t('products')),
							})}`}
						/>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Audit;
