import { useEffect } from 'react';

// type Props = {
// 	behavior?: ScrollToOptions['behavior'];
// 	top: ScrollToOptions['top']
// 	left: ScrollToOptions['left']
// };
type Props = Omit<ScrollToOptions, 'left'>;

const ScrollTop = ({ behavior = 'smooth', top = 0 }: Props) => {
	useEffect(() => {
		window.scrollTo({ behavior, top });
	}, [behavior, top]);
	return null;
};

export default ScrollTop;
