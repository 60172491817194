import React from 'react';

import { css, cx } from '@emotion/css';
import { PieChart } from 'lucide-react';

import { siteColors } from '../../lib/colors';
import Skeleton from '../Skeleton';
import Typography from '../Typography';

const styles = {
	container: css({
		padding: '24px 24px 24px 15px',
		borderRadius: 16,
		minHeight: 104,
	}),

	value: css({
		fontSize: '24px !important',
	}),

	skeleton: css({
		marginTop: 24,
		height: '117px !important',
		flex: 1,
	} as any),
};

type KpiCardProps = {
	title: string;
	value: string;
	icon: any;
	unit: React.ReactNode;
	isLoading?: boolean;
};

const KpiCard = ({ title, value, icon, unit, isLoading }: KpiCardProps) => {
	const IconComponent = icon;
	return isLoading ? (
		<Skeleton width="100%!important" className={styles.skeleton} />
	) : (
		<div className={cx('flexRow alignCenter borderGrey500 flex1 gap16 nowrap', styles.container)}>
			<IconComponent size={30} color={siteColors.primary} />

			<div className="flexColumn gap4 flex1">
				<Typography>{title}</Typography>
				<Typography className={cx('number fontWeight500 mainColor lineHeight1', styles.value)}>{value}</Typography>
				<Typography className="  grey900" variant="caption">
					{unit}
				</Typography>
			</div>
		</div>
	);
};

export default KpiCard;
