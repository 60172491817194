import { type QueryFunctionContext } from '@tanstack/react-query';

import { IPackaging } from '@carbonmaps/shared/types/packaging.types';
import { functionName } from '@carbonmaps/shared/utils/constants';

export type FindPackagingSheetActionParams = {
	objectId?: string;
};

//--------------------------------------------------------------------------------------//
//                get data for one packaging with time period                    //
//--------------------------------------------------------------------------------------//
export const getPackagingSheetTimePeriodAction = async (
	context: QueryFunctionContext<
		readonly ['getPackagingSheetTimePeriod', { objectId: string; period: string | number; }]
	>,
): Promise<any> => {
	try {
		const {
			queryKey: [, { objectId, period }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.temporality.findOnePackaging, {
			packagingId: objectId,
			period,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ getPackagingSheetTimePeriodAction error: ', error);
		return Promise.reject(error);
	}
};

export const findPackagingSheetAction = async (
	context: QueryFunctionContext<readonly ['findPackagingSheet', FindPackagingSheetActionParams]>,
): Promise<IPackaging> => {
	try {
		const {
			queryKey: {
				1: params,
			},
		} = context;

		const result = Parse.Cloud.run('getPackagingSheet', params);

		return result;
	} catch (error) {
		console.log(' ------ findPackagingSheetAction error: ', error);
		return Promise.reject(error);
	}
};

export async function findPackagingTableAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		// const result = await Parse.Cloud.run('findPackagingTable', params);
		const result = await Parse.Cloud.run(functionName.temporality.findPackaging, { view: 'general-view-of-packaging->packaging-table', ...params });
		return result;
	} catch (error) {
		console.log('--------- findPackagingTableAction error: ', error);
		return Promise.reject(error);
	}
}

export async function findPackagingFamilyRepartitionAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		// const result = await Parse.Cloud.run('findPackagingTable', params);
		const result = await Parse.Cloud.run(functionName.temporality.findPackaging, { view: 'general-view-of-packaging->packaging-family-repartition', ...params });
		return result;
	} catch (error) {
		console.log('------- findPackagingFamilyRepartitionAction error: ', error);
		return Promise.reject(error);
	}
}



//--------------------------------------------------------------------------------------//
//                              count product for company                               //
//--------------------------------------------------------------------------------------//
export const getPackagingCompanyCountAction = async (context: QueryFunctionContext<readonly ['getPackagingCount']>) => {
	try {
		const count = await Parse.Cloud.run('getPackagingCount');
		return count;
	} catch (error) {
		console.log('--- getPackagingCompanyCountAction error: ', error);
		return Promise.reject(error);
	}
};

// ---- 3 --------------------------------------------------------------------------------
export const getPackagingFamiliesAction = async (context: QueryFunctionContext<readonly ['getPackagingFamilies']>) => {
	try {
		const result = await Parse.Cloud.run('getPackagingFamilies');
		return result;
	} catch (error) {
		console.log('--- getPackagingFamiliesAction error: ', error);
		return Promise.reject(error);
	}
};
