import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';

type Props = {
	value: any;
};
const styles = {
	content: css({
		//color: 'var(--color)',
		fontSize: 14,
		fontWeight: 400,
		lineHeight: 'normal',
		color: siteColors.grey6,
		fontVariantNumeric: 'lining-nums tabular-nums',
		leadingTrim: 'both',
		textEdge: 'cap',
		textAlign: 'end'
	}),
};

const Lining = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<div className={cx(styles.content)}>––</div>
	);
};

export default Lining;
