import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import Lining from '../../../../../apps/front/src/components/sales/Lining';
import PopoverItem from '../../../../../apps/front/src/components/table/popover/PopoverItem';
import Unit from '../../../../../apps/front/src/components/units/Unit';
import { siteColors } from '../../../../../apps/front/src/lib/colors';
import { displayValue } from '../../../utils/numberFormat';
import BPopover from '../BPopover';
const styles = {
	label: css({
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: 'normal',
		color: siteColors.grey8,
	}),
	gap1: css({
		gap: '1px',
	}),

	displayBarContainer: css({
		height: '6px',
		width: '202px',
		borderRadius: '32px',
		background: siteColors.grey400,
	}),
	displayBarContent: css({
		height: '100%',
		borderRadius: '32px',
	}),

	lineValue: css({
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',
		width: '42px',
		marginRight: '10px',
	}),
	popoverContainer: css({
		padding: 24,
	}),

	barLine: css({
		background: 'var(--color) !important',
		height: '14px',
		width: 'var(--width)',
		borderRadius: '4px'
	}),


};

const LineBar = (props: any) => {
	const { row, column } = props;

	const percentageN1 = row?.original?.percentageN1 || 0;
	const percentageN2 = row?.original?.percentageN2 || 0;

	const colorN1 = row?.original?.colorN1 || siteColors.green500;
	const colorN2 = row?.original?.colorN2 || siteColors.grey3;

	const transFunction = () => {
		console.log('test');
	};

	const t = column?.props?.trans || transFunction;


	if (row.original.isFromQuote && column.props?.page === 'report-client') {
		return <Lining />
	}

	return (
		<div className={cx('flexRow nowrap gap8')}>
			<div className={cx(styles.label)}>
				{percentageN1 === 100 ? 100 : displayValue(percentageN1)} %
			</div>
			<div className={cx('flexRow nowrap gap5', styles.gap1)}>
				<div className={cx(styles.barLine)} style={{ ['--color' as any]: colorN1, ['--width' as any]: `${percentageN1 * 2}px` }}>
				</div>
				<div className={cx(styles.barLine)} style={{ ['--color' as any]: colorN2, ['--width' as any]: `${percentageN2 * 2}px` }}>
				</div>
			</div>
		</div>
	);
};

export default LineBar;

