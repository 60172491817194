import { useEffect, useState } from 'react';

import _ from 'lodash';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import BlockTitle from '../../../components/BlockTitle';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { CARBONE_INDICATOR } from '../../../utils/constants';

import FamilyFilter from './FamilyFilter';
import FamilyTable from './FamilyTable';

export interface IngredientItem {
	label: string;
	countUse: number;
	carbonIntensity?: number;
	water?: number;
	carbonImpact?: number;
	percentage?: number;
	labelOrigine?: string;
	uid?: string;
}

const IngredientUses = () => {
	// const params = useParams();
	// const [total, setTotal] = useState(0);
	const [loading] = useState(false);

	// translation
	const { t } = useTranslation();

	const { indicator, setIndicator } = useApp();

	const [searchQueryParams] = useSearchQueryParams();

	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBONE_INDICATOR);
	}, [setIndicator]);

	return (
		<div>
			<div className="flexRow" css={{ gap: 5 }}>
				<BlockTitle>{t('ingredient_family', { ingredients: t('ingredients') })} </BlockTitle>
				{/* <Typography>({total})</Typography> */}
			</div>
			<div className="flexColumn alignCenter" /* css={{}} */>
				<FamilyFilter />
				<FamilyTable filterOptions={searchQueryParams} />
			</div>
			<LoadingDialog open={loading} content={`${t('export-loading-message')}...`} />
		</div>
	);
};

export default IngredientUses;
