import { BarChart2, Cloud, Cloudy, Droplet, Droplets } from 'lucide-react';

import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import IndicatorsBlockLight from '../../../../components/synthese/IndicatorsBlockLight';
import Unit from '../../../../components/units/Unit';
import Card from '../../../../components/widget/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import { WATER_INDICATOR } from '../../../../utils/constants';
import {
	getCountSuppliersFullFilled,
	getDataKeysByIndicator,
	getSuppliersFullFilledPercent,
	getSuppliersPercent,
} from '../../../../utils/graphIndicator';
import { useGlobalIndicator } from '../../analyse.hooks';

// eslint-disable-next-line react-refresh/only-export-components
export const indicatorConfigItems = new Map([
	[
		CARBON_INDICATOR,
		{
			impactTitle: WORDING_TERMS.IMPACT,
			impactIcon: Cloudy,
			impactUnity: <Unit measure="carbonImpactTon" fontWeight={600} color={siteColors.text} />,
			intensityTitle: WORDING_TERMS.INTENSITY,
			intensityIcon: Cloud,
			intensityUnity: <Unit measure="carbonIntensityKg" fontWeight={600} color={siteColors.text} />,
			indicatorTitle: 'Indicateurs carbone',
		},
	],
	[
		WATER_INDICATOR,
		{
			impactTitle: WORDING_TERMS.IMPACT,
			impactIcon: Droplets,
			impactUnity: <Unit measure="waterImpact" fontWeight={600} color={siteColors.text} />,
			intensityTitle: WORDING_TERMS.INTENSITY,
			intensityIcon: Droplet,
			intensityUnity: <Unit measure="waterIntensity" fontWeight={600} color={siteColors.text} />,
			indicatorTitle: 'Indicateurs eau',
		},
	],
]);

const popoverIndicatorConfigItems = new Map();

popoverIndicatorConfigItems.set(CARBON_INDICATOR, {
	value: 'carbonImpact',
	label: 'Carbone',
	unit: (
		<Unit
			measure="carbonImpactTon"
			fontWeight={500}
			fontSize="12px"
			color={siteColors.grey700}
			lineHeight={1}
			// sx={{ textTransform: 'uppercase' }}
		/>
	),
	popover: [
		{
			label: WORDING_TERMS.IMPACT,
			key: 'carbonImpact',
			unit: (
				<Unit
					measure="carbonImpactTon"
					fontWeight={600}
					lineHeight={'16.8px'}
					fontSize="14px"
					color={siteColors.text}
					// sx={{ textTransform: 'capitalize' }}
				/>
			),
			precision: 0,
		},
		{
			label: 'Volume',
			key: 'tonnage',
			unit: (
				<Unit
					measure="massTon"
					fontWeight={600}
					lineHeight={'16.8px'}
					fontSize="14px"
					color={siteColors.text}
					// sx={{ textTransform: 'capitalize' }}
				/>
			),
			precision: 0,
		},
	],
});

popoverIndicatorConfigItems.set(WATER_INDICATOR, {
	value: 'waterImpact',
	label: 'Eau',
	unit: (
		<Unit
			measure="waterImpact"
			fontWeight={500}
			fontSize="12px"
			color={siteColors.grey700}
			lineHeight={1}
			// sx={{ textTransform: 'uppercase' }}
		/>
	),
	popover: [
		{
			label: WORDING_TERMS.IMPACT,
			key: 'waterImpact',
			unit: (
				<Unit
					measure="waterImpact"
					fontWeight={600}
					lineHeight={'16.8px'}
					fontSize="14px"
					color={siteColors.text}
					// sx={{ textTransform: 'capitalize' }}
				/>
			),
			precision: 2,
		},
		{
			label: 'Volume',
			key: 'tonnage',
			unit: (
				<Unit
					measure="massTon"
					fontWeight={600}
					lineHeight={'16.8px'}
					fontSize="14px"
					color={siteColors.text}
					// sx={{ textTransform: 'capitalize' }}
				/>
			),
			precision: 0,
		},
	],
});

type MainIndicatorBlockProps = {
	categoryData: any;
	suppliers: any;
};

const MainIndicatorBlock = ({ categoryData, suppliers }: MainIndicatorBlockProps) => {
	const { indicator } = useApp();

	const { t } = useTranslation();

	const { data, isLoading } = useGlobalIndicator({}) as any;

	return (
		<>
			<Card
				title={
					indicatorConfigItems.get(indicator)?.indicatorTitle
						? t(indicatorConfigItems.get(indicator)?.indicatorTitle as any)
						: t('Indicateurs carbone')
				}
				icon={<BarChart2 color={siteColors.grey700} size={20} />}
				sx={{ padding: 0, marginBottom: pxToRem(32), flexWrap: 'nowrap', width: '100%' }}
				withBorder={false}
				center={false}
			>
				<IndicatorsBlockLight
					indicator={indicator}
					indicatorDatas={{
						impact: {
							value: displayValue(data && data[getDataKeysByIndicator(indicator).impact], undefined, 0),
							fullFilledPercent: getSuppliersFullFilledPercent(suppliers, indicator),
							suppliersCount: {
								fulfilled: getCountSuppliersFullFilled(suppliers),
								total: suppliers.length,
							},
						},
						intensity: {
							value: displayValue(data && data[getDataKeysByIndicator(indicator).ges], undefined, 2),
							fullFilledPercent: getSuppliersPercent(suppliers),
							suppliersCount: {
								fulfilled: getCountSuppliersFullFilled(suppliers),
								total: suppliers.length,
							},
						},
					}}
					//TODO active after
					withPopover={false}
					isLoading={isLoading}
				/>
			</Card>
		</>
	);
};

export default MainIndicatorBlock;
