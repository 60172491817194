import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { useTranslation } from '../../hooks/useTranslation';

const styles = {
	container: css({
		'& td': {
			fontSize: 12,
		},
		'& td:nth-of-type(1)': {
			paddingLeft: '16px !important',
		},
	}),
	total: css({
		padding: '8px 0 !important',
		paddingLeft: '24px !important',
	}),
	td: css({
		padding: '0px 16px',
		textAlign: 'right !important',
	} as any),
};

type TotalRowProps = {
	data?: any;
};

const TotalRow = ({ data = [] }: TotalRowProps) => {
	const { t } = useTranslation();
	const { indicator } = useApp();

	const quantity = _.sumBy(
		_.map(data, (obj) => {
			return { quantity: _.isNumber(obj.quantity) ? obj.quantity : 0 };
		}),
		'quantity',
	);

	const impactTotal = _.sumBy(
		_.map(data, (obj) => {
			return {
				...obj,
				gesTotalImpact: Number(obj.gesTotalImpact?.toFixed(0)),
				waterUseTotalImpact: Number(obj.waterUseTotalImpact?.toFixed(0)),
			};
		}),
		indicator === CARBONE_INDICATOR ? 'gesTotalImpact' : 'waterUseTotalImpact',
	);

	return (
		<tr className={styles.container}>
			<td className={styles.total}>
				<span className={cx('grey700', styles.total)}>{t('total')}</span>
			</td>
			<td className={cx('grey700', styles.td, 'number')}>{formatNumber(quantity, undefined, 0)}</td>
			<td className={cx('grey700', styles.td)}></td>
			{/* <td className={cx('grey700', styles.td)}>{formatNumber(impactTotal, undefined, 1)}</td> */}
			<td className={cx('grey700', styles.td, 'number')}>
				{formatNumber(!isNaN(impactTotal) ? impactTotal : 0, undefined, 0)}
			</td>
			<td></td>
		</tr>
	);
};

export default TotalRow;
