import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import { Leaf } from 'lucide-react';

import { productCompanyCount } from '@carbonmaps/ui/actions/product.actions';

import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useTranslation } from '../../hooks/useTranslation';

const bg = css({
	background: '#F4F7F1!important',
});

const QuestionnaireHeader = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	const { selectedYear } = useYearSelection();

	return (
		<ListHeader
			title={t('questionnaire-performance-rse')}
			icon={<IconContainer element={<Leaf color={theme.palette.common.white} size={32} />} color={'#95AB76'} />}
			className={bg}
		/>
	);
};

export default QuestionnaireHeader;
