import { css, cx } from '@emotion/css';

type SupplierScoreProps = {
	score: string;
	color: string;
};

const styles = {
	container: css({
		width: 25,
		height: 16,
		borderRadius: 100,
		background: 'var(--backgroundColor)',
		fontSize: '11.25 px !important',
		color: '#FFF !important',
		padding: '6px 7.5px 6px 6px',
		fontWeight: 600,
	}),
};

const SupplierScore = ({ score, color }: SupplierScoreProps) => {
	return (
		<div className={cx('flexCenter', styles.container)} style={{ ['--backgroundColor' as any]: color }}>
			{score}
		</div>
	);
};

export default SupplierScore;
