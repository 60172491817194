import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

export const useQueryParams = (isMultiple = true) => {
	const [searchParams] = useSearchParams();

	const queryParams = useMemo(() => {
		if (!searchParams) return null;

		let queryParams: Record<string, [string]> = {};

		for (const entry of searchParams.entries()) {
			const [param, value] = entry;
			queryParams = {
				...queryParams,
				[param]: isMultiple ? [...(queryParams[param] || []), value] : value,
			} as Record<string, [string]>;
		}

		return queryParams;
	}, [searchParams]);

	return queryParams;
};
