import { css, cx } from '@emotion/css';
import Chip from '@mui/material/Chip';

import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import { MODELIZATION_CONFIG_ITEMS } from '../../utils/supplier';

type ModelizationProps = {
	value: number;
	gap?: number;
};

const styles = {
	container: css({
		gap: 8,
	}),
	content: css({
		maxWidth: 300,
	}),
	chipContainer: css({
		color: cn(siteColors.grey900, '!important'),
		fontSize: '14px !important',
		fontWeight: 500,
		padding: '8px !important',
		border: '1px solid rgba(0, 0, 0, 0.10) !important',
		height: '24px !important',
		borderRadius: '8px !important',
		'& .MuiChip-label': {
			paddingLeft: 0,
			paddingRight: 0,
		},
	}),
};

const Modelization = ({ value, gap }: ModelizationProps) => {
	const { t } = useTranslation();
	return (
		<div className={cx('flexRow nowrap alignCenter', styles.container)}>
			{MODELIZATION_CONFIG_ITEMS.get(value)?.icon}
			<div className={styles.content}>
				<Chip
					label={t(MODELIZATION_CONFIG_ITEMS.get(value)?.label)}
					className={styles.chipContainer}
					style={{
						backgroundColor: `${MODELIZATION_CONFIG_ITEMS.get(value)?.color || siteColors.common.white} !important`,
					}}
				/>
			</div>
		</div>
	);
};

export default Modelization;
