import { Tab, Tabs } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Container from '../../../../components/layout/list/Container';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useRetrieveFormFromApi } from '../hooks';
import QuestionFormsHeader from '../QuestionFormsHeader';
import PonderationsTab from './PonderationsTab';
import QuestionsTab from './QuestionsTab';
import SectionsTab from './SectionsTab';

const QuestionFormsSettings = () => {
	const [searchParams] = useSearchParams();
	const [value, setValue] = useState(() => {
		return searchParams.get('tab') || 'ponderations';
	});
	const { t } = useTranslation();

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const params = useParams();
	useRetrieveFormFromApi({ questionFormId: _.toString(params.questionFormId) });

	return (
		<Container header={<QuestionFormsHeader />} seoText={`${t('Questionnaire')} - Carbon Maps`}>
			<div>
				<Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
					<Tab value="ponderations" label="Pondérations des questions" />
					<Tab value="sections" label="Sections" />
					<Tab value="questions" label="Questions" />
				</Tabs>

				<div>
					{value === 'ponderations' && <PonderationsTab />}
					{value === 'sections' && <SectionsTab />}
					{value === 'questions' && <QuestionsTab />}
				</div>
			</div>
		</Container>
	);
};

export default QuestionFormsSettings;
