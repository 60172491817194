import React, { useState } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Plus } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import Dialog from '../../../components/dialogs/Dialog';
import { createClientAction } from '../../../lib/react-query/features/client/client.actions';

import DevisModal from './DevisModal';

const CreateClient = () => {
	const queryClient = useQueryClient();
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');

	//----- mutation to create the client ----//
	const {
		mutate: createClient,
		isError: isErrorClient,
		isSuccess: isSuccessClient,
		isLoading: isLoadingCreateClient,
	} = useMutation({
		mutationKey: ['createClient'],
		mutationFn: createClientAction,
		onSuccess: async (returnedData: any) => {
			// setOpenModal(false);
			// setStep(1);

			queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisClient'] });

			setOpen(false);

			// if (returnedData?.isUpdate) {
			// 	// setMessage('Informations mises à jour');
			// 	setSelectedOption({
			// 		label: returnedData?.client?.get('name'),
			// 		...returnedData?.client?.toJSON(),
			// 	});
			// }
			// navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	return (
		<div>
			<BButton
				iconLeft={<Plus size={20} />}
				onClick={() => {
					setOpen(true);
				}}
				label={'Nouveau client'}
				variant="secondary"
			/>

			<DevisModal
				title={undefined}
				open={open}
				isLoading={false}
				onSubmit={function (a?: any): void {
					// throw new Error('Function not implemented.');
					// createClient()
				}}
				onSubmitClient={(input) => {
					createClient(input);
				}}
				onClose={function (): void {
					setOpen(false);
					// throw new Error('Function not implemented.');
				}}
				isLoadingClient={isLoadingCreateClient}
				selectedSimulation={undefined}
				setSelectedSimulation={undefined}
				message={message}
				isError={isErrorClient}
				forceStep={2}
			/>
		</div>
	);
};

export default CreateClient;
