import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { GesUnit } from '../../utils/graphIndicator';
import PopoverItem from '../table/popover/PopoverItem';

type LifeCycleChartTooltipProps = {
	value: number;
	label?: string;
	unit?: React.ReactNode;
	precision?: number;
};

const LifeCycleChartTooltip = ({
	label = WORDING_TERMS.INTENSITY,
	value,
	unit = GesUnit,
	precision = 2,
}: LifeCycleChartTooltipProps) => {
	return (
		<PopoverItem
			label={label}
			value={
				<>
					{formatNumber(value, undefined, precision)} {unit}
				</>
			}
		/>
	);
};

export default LifeCycleChartTooltip;
