import { ReactNode } from 'react';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { type IColor } from '@mui/material';
import type { PaletteOptions } from '@mui/material/styles/createPalette';
import createTheme, { type ThemeOptions } from '@mui/material/styles/createTheme';
import { type TypographyOptions } from '@mui/material/styles/createTypography';

import { GlobalStyles } from '@carbonmaps/ui/styles/GlobalStyles';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import { siteColors } from '../lib/colors';

//--------------------------------------------------------------------------------------//
//                                      palette                                      //
//--------------------------------------------------------------------------------------//
// COLORS TO USE IN PALETTE FOR FUTURE
// color in design system
const rawColors = {
	// Brand: see the Figma
	cmBlack: '#140C27',
	cmPurple: siteColors.primary,
	// cmGreen: siteColors.green500,
	cmOrange: '#DE7621',
	// Couleurs métiers
};

const {
	palette: { augmentColor },
} = createTheme();

const palette: PaletteOptions = {
	primary: augmentColor({
		color: {
			main: rawColors.cmPurple,
			dark: siteColors.primaryHover,
		},
	}),
	text: {
		primary: rawColors.cmBlack,
	},
	common: {
		black: rawColors.cmBlack,
	},
	jobs: {
		// ---- indicators -----------------------------------------------------------------------
		water: augmentColor({ color: { main: siteColors.water500, light: '#F4FAFF' } }),
		biodiv: augmentColor({ color: { main: '#557A06', light: '#F5F9EC' } }),
		welfare: augmentColor({ color: { main: '#C74600', light: '#FEF7F1' } }),
		// ---- Couleurs pour les autres termes métiers. -----------------------------------------
		energy: augmentColor({ color: { main: '#E0AF00', light: '#FDF8E7', dark: '#AA8500' } }),
		gamme: augmentColor({ color: { main: '#7900D9' } }),
	},
	grey: {
		100: siteColors.grey100,
		200: siteColors.grey200,
		300: siteColors.grey300,
		400: siteColors.grey400,
		500: siteColors.grey500,
		600: siteColors.grey600,
		700: siteColors.grey700,
		800: siteColors.grey800,
		900: siteColors.grey900,
	},
	purple: augmentColor({
		color: {
			main: rawColors.cmPurple,
			900: '#0000e0',
			800: '#1900e4',
			700: '#3d00e9',
			600: rawColors.cmPurple,
			500: '#6207f7',
			400: '#8141fa',
			300: '#9b68fb',
			200: '#b996fb',
			100: '#d6c0fc',
			50: '#f0e5fe',
		},
	}) as IColor,
	// green: augmentColor({
	// 	color: {
	// 		main: rawColors.cmGreen,
	// 		50: '#e5f7ea',
	// 		100: '#c2ebcc',
	// 		200: '#99dfab',
	// 		300: '#6ad288',
	// 		400: rawColors.cmGreen,
	// 		500: '#00bd52',
	// 		600: '#00ae49',
	// 		700: '#009b3c',
	// 		800: '#008a31',
	// 		900: '#006a1d',
	// 	},
	// }) as IColor,
	// orange: augmentColor({
	// 	color: {
	// 		main: rawColors.cmOrange,
	// 		50: '#e5f7ea',
	// 		100: '#c2ebcc',
	// 		200: '#99dfab',
	// 		300: '#6ad288',
	// 		400: rawColors.cmGreen,
	// 		500: '#00bd52',
	// 		600: '#00ae49',
	// 		700: '#009b3c',
	// 		800: '#008a31',
	// 		900: '#006a1d',
	// 	},
	// }) as IColor,

	supplier: {
		'A+': augmentColor({ color: { main: '#2FBA90', light: '#2FBA90' } }),
		A: augmentColor({ color: { main: '#49B654', light: '#49B654' } }),
		B: augmentColor({ color: { main: '#95BF3C', light: '#95BF3C' } }),
		C: augmentColor({ color: { main: '#F0BF41', light: '#F0BF41' } }),
		D: augmentColor({ color: { main: '#ED9D38', light: '#ED9D38' } }),
		E: augmentColor({ color: { main: '#EA602C', light: '#EA602C' } }),
		default: augmentColor({ color: { main: siteColors.grey400, light: siteColors.grey400 } }),
	},
	indicator: {
		carbon: augmentColor({ color: { main: siteColors.carbon500, light: siteColors.carbon100 } }),
		water: augmentColor({ color: { main: siteColors.water500, light: siteColors.water100 } }),
	},
};

//--------------------------------------------------------------------------------------//
//                                      typography                                      //
//--------------------------------------------------------------------------------------//
const PRIMARY_FONT = ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(',');
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_SEMI_BOLD = 600;
const FONT_WEIGHT_BOLD = 600;

const defaultTypographyStyles = {
	fontWeight: 400,
	fontStyle: 'normal',
	fontFamily: PRIMARY_FONT,
	color: palette.text?.primary,
};

const titleTypographyStyles = {
	...defaultTypographyStyles,
	fontWeight: FONT_WEIGHT_BOLD,
};

const typography: TypographyOptions = {
	fontFamily: PRIMARY_FONT,
	fontWeightRegular: FONT_WEIGHT_REGULAR,
	fontWeightBold: FONT_WEIGHT_BOLD,
	h1: {
		...titleTypographyStyles,
		fontSize: pxToRem(32),
	},
	h2: {
		...titleTypographyStyles,
		fontSize: pxToRem(24),
	},
	h3: {
		...titleTypographyStyles,
		fontSize: pxToRem(16),
	},
	h4: {
		...titleTypographyStyles,
		fontSize: pxToRem(14),
	},
	h5: {
		...defaultTypographyStyles,
		fontSize: pxToRem(13),
	},
	subtitle1: {
		...defaultTypographyStyles,
		fontSize: pxToRem(48),
	},
	subtitle2: {
		...defaultTypographyStyles,
		fontSize: pxToRem(24),
	},
	body1: {
		...defaultTypographyStyles,
		fontSize: pxToRem(14),
	},
	body2: {
		...defaultTypographyStyles,
		fontSize: pxToRem(14),
		fontWeight: FONT_WEIGHT_BOLD,
	},
	caption: {
		...defaultTypographyStyles,
		fontSize: pxToRem(12),
	},
	button: {
		...defaultTypographyStyles,
		fontSize: pxToRem(14),
		fontWeight: FONT_WEIGHT_SEMI_BOLD,
	},
	overline: {
		...defaultTypographyStyles,
		fontSize: pxToRem(13),
		fontWeight: FONT_WEIGHT_BOLD,
		textTransform: 'uppercase',
	},
};

// const components: ThemeOptions['components'] = {
// 	MuiButton: {
// 		styleOverrides: {
// 			root: {
// 				'&:hover': ({ ownerState }: { ownerState: ButtonProps }) => {
// 					return [
// 						{
// 							'&:hover': {
// 								backgroundColor: 'red',
// 							},
// 						},
// 					];
// 				},
// 			},
// 		},
// 	},
// };

//--------------------------------------------------------------------------------------//
//                                     define theme                                     //
//--------------------------------------------------------------------------------------//
const themeOptions: ThemeOptions = {
	// palette,
	palette,
	typography,
	// components,
	// zIndex: {
	//   appBar: 1200,
	//   drawer: 1100,
	// },
};

type Props = {
	children: ReactNode;
};

const ThemeProvider = ({ children }: Props) => {
	const theme = createTheme(themeOptions);

	return (
		<EmotionThemeProvider theme={theme}>
			<GlobalStyles theme={theme} />
			{children}
		</EmotionThemeProvider>
	);
};

export default ThemeProvider;
