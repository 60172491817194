// import ScrollTop from '../../../components/ScrollTop';

import IngredientsList from './ProductIngredientsList';
import TopOverView from './ProductIngredientsTopOverView';

const Ingredients = () => {
	return (
		<>
			{/* <ScrollTop /> */}
			<TopOverView />
			<IngredientsList />
		</>
	);
};

export default Ingredients;
