import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { eachOfLimit } from 'async';

import { ReactComponent as BlueCheckOK } from '@carbonmaps/media/icons/bluecheckok.svg';
import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getCategoriesProduct } from '@carbonmaps/ui/actions/product.actions';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';

// import FilterContainer from '../../components/layout/list/FilterContainer';
import { IFilterFolder } from '../../../../components/Filter';
import { Option } from '../../../../components/form/fields/MultiSelectCheckboxField';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { exportProductAction } from '../../../../lib/react-query/features/export/product.actions';
import { getPackagingFamiliesAction } from '../../../../lib/react-query/features/packaging/packaging.actions';
import { getLabelNodeFacet } from '../../../../utils/utils';

import FilterContainer from './FilterContainer';

// eslint-disable-next-line quotes

const PackagingsFilter = ({
	withMore = true,
	showFacetFilter = true,
}: {
	withMore?: boolean;
	showFacetFilter?: boolean;
}) => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const { selectedYear } = useYearSelection();

	const [isExportLoading, setExportLoading] = useState(false);

	const { t } = useTranslation();

	const suppliersFolderLabel = useMemo(() => {
		return t('family-packaging');
	}, [t]);

	// custom facets suppliers
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// filters values
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	// -------------------------------------------------------------------------------------- //
	// -------------------------------- Use query ------------------------------------------- //
	// -------------------------------------------------------------------------------------- //
	// ---- fetch suppliers filter ---- //
	const { data: suppliersData, isLoading: isSUppliersLoading } = useQuery({
		queryKey: ['getPackagingFamilies'],
		queryFn: getPackagingFamiliesAction,
	});

	// ---- fetch facets ---- //
	const { data: facets, isLoading: isFacetsLoading } = useQuery({
		queryKey: [
			'getCategory',
			{
				input: searchQueryParams.input,
				facetFilters: searchQueryParams?.facetFilters || [],
				path: 'packaging',
				period: selectedYear,
			},
		],
		queryFn: getCategoriesProduct,
	});

	// ---- fetch data graph to count export---- //
	// const { data, isLoading } = useQuery({
	// 	queryKey: [
	// 		'getProductTradeoffData',
	// 		{
	// 			input: searchQueryParams.input,
	// 			facetFilters: searchQueryParams?.facetFilters || [],
	// 			supplierIds: searchQueryParams?.supplierIds || [],
	// 		},
	// 	],
	// 	queryFn: getProductTradeoffDataAction,
	// });

	// ---- create custom facet for supplier ----
	useEffect(() => {
		if (!suppliersData) return;

		const suppliersFolder: IFilterFolder = {
			label: suppliersFolderLabel,
			isFacet: false,
			showCount: false,
			options: suppliersData.map((sup: any) => {
				return {
					label: sup.labelCmaps,
					value: sup.codeEmbCmaps,
					folderLabel: suppliersFolderLabel,
				};
			}),
		};

		setFilterFolders((folders) => {
			const supplierFolderIndex = folders.findIndex((e) => {
				return e.label === suppliersFolderLabel;
			});

			if (supplierFolderIndex === -1) {
				const n2FolderIndex = folders.findIndex((e) => {
					if (e.isFacet) {
						return e.path === 'tagAdvancedModelization';
					}

					return false;
				});

				const n2Folder = folders[n2FolderIndex];
				return [...(n2Folder ? [n2Folder] : []), suppliersFolder, ...folders];
			}

			folders[supplierFolderIndex] = suppliersFolder;
			return folders;
		});
	}, [suppliersData]);

	// suppliersData
	// console.log('suppliersData: ', suppliersData);

	useEffect(() => {
		const searchParams = searchQueryParams?.facetFilters ?? [];
		const supplierIdsParams = searchQueryParams?.codeEmbCmapsList ?? [];

		//	if there are filters in the url
		if ((searchParams && searchParams.length) || (supplierIdsParams && supplierIdsParams.length)) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			// for facet
			if (searchParams && searchParams.length) {
				searchParams.forEach((item: any) => {
					const arrayValues = item.values.map((v: any) => {
						const value = {
							facetPath: item.path,
							value: v,
							folderLabel: item?.folderLabel ?? item.path,
							label: getLabelValue(item.path, v),
							isFacetBucket: true,
							type: typeof v,
							global: item.global === true ? true : false,
							itemType: item?.itemType?.toLowerCase() || 'product',
						};

						currentSelectedValues.push(value);
						return value;
					});

					currentAppliedValuesFolders.push({
						isFacet: true,
						label: item?.folderLabel ?? item.path,
						options: arrayValues,
						showCount: true,
					});
				});
			}

			//for supplier
			if (supplierIdsParams && supplierIdsParams.length) {
				if (!suppliersData) return;
				const values = suppliersData
					.filter((item: any) => {
						return supplierIdsParams.includes(item.codeEmbCmaps);
					})
					.map((sup: any) => {
						return {
							label: sup.labelCmaps,
							value: sup.codeEmbCmaps,
							folderLabel: suppliersFolderLabel,
						};
					});

				const suppliersFolder: IFilterFolder = {
					label: suppliersFolderLabel,
					isFacet: false,
					showCount: false,
					options: values,
				};

				currentAppliedValuesFolders.push(suppliersFolder); // each one filter
				currentSelectedValues.push(...values); // in the base filter
			}

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchQueryParams, suppliersData]);

	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facets) return;

			const otherFacetFilterFolders: IFilterFolder[] = [];
			let N2FacetFolder: IFilterFolder;

			for await (const entry of Object.entries(facets.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;
				const { label, type, global, itemType } = getFacetConfig(key, authData?.session.company) ?? {};

				const facetLabel = label || getFacetLabel(key, authData?.session.company);

				const currentFacetFolder: IFilterFolder = {
					label: facetLabel,
					options: [],
					isFacet: true,
					path: key,
					showCount: false,
					global,
					customIcon:
						key === 'tagAdvancedModelization' ? <BlueCheckOK css={{ 'path:first-of-type': { fill: 'grey' } }} /> : null,
				};

				await eachOfLimit(buckets as any[], 100, async (bucket, k) => {
					if (key !== 'tagAdvancedModelization' && !bucket.count) {
						return;
					}

					currentFacetFolder.options.push({
						value: bucket._id,
						label: getLabelValue(key, bucket._id),
						labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
							? getLabelNodeFacet(key, bucket._id, t)
							: undefined,
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: type ?? 'hardcoded-undefined-facet-type',
						global,
						itemType,
					});

					return;
				});

				if (currentFacetFolder.path === 'tagAdvancedModelization') {
					N2FacetFolder = currentFacetFolder;
				} else {
					otherFacetFilterFolders.push(currentFacetFolder);
				}
			}

			setFilterFolders((folders) => {
				const notFacetFolders = folders.filter((e) => {
					return !e.isFacet;
				});

				return [...(N2FacetFolder ? [N2FacetFolder] : []), ...notFacetFolders, ...otherFacetFilterFolders];
			});
		};

		asyncWrapper();
	}, [facets, authData?.session.company]);

	// ---- select rows selected in store ---- //
	const { selectedRows } = useRowTableSelection();

	// ---- handle export  data ---- //
	const handleExport = useCallback(
		async ({ exportFormat }: { exportFormat: string }) => {
			try {
				setExportLoading(true);
				await exportProductAction(
					{
						ids: selectedRows.map((e: any) => {
							return e.original.objectId;
						}),
						facetFilters: searchQueryParams.facetFilters,
						supplierIds: searchQueryParams?.supplierIds ?? [],
						input: searchQueryParams.input ?? '',
						format: exportFormat,
						companyCode: authData?.session.company?.code,
					},
					t,
				);
				setExportLoading(false);
			} catch (error) {
				setExportLoading(false);
				console.error(error);
			}
		},
		[
			authData?.session.company?.code,
			searchQueryParams.facetFilters,
			searchQueryParams.input,
			searchQueryParams?.supplierIds,
			selectedRows,
		],
	);

	return (
		<FilterContainer
			skeleton={isFacetsLoading || isSUppliersLoading}
			isLoading={false}
			appliedValuesByFolders={appliedValuesByFolders}
			setAppliedValuesByFolders={setAppliedValuesByFolders}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			folders={filterFolders}
			setSearchQueryParams={setSearchQueryParams}
			suppliersFolderLabel={suppliersFolderLabel}
			// codeEmbCmapsListFolderLabel={suppliersFolderLabel}
			selectedRow={selectedRows}
			handleExport={handleExport}
			isTableLoading={false}
			isExportLoading={isExportLoading}
			count={/* data?.length || */ 0}
			withMore={withMore}
			enableActionDelete={false}
			searchTrackName="Explore Packagings Search"
			filterTrackName="Explore Packagings Filter"
			trackCategory="Explore"
			exportTrackName="Explore Packagings Export"
			showFacetFilter={showFacetFilter}
			showCount={false}
		/>
	);
};

export default PackagingsFilter;
