import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertCircle, Check } from 'lucide-react';
import { useForm } from 'react-hook-form';

import {
	QuantityFormInput,
	QuantityFormValidationSchema,
} from '@carbonmaps/shared/validations/quoteClientEdit.validations';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';

import AlertStatic from '../../components/Toast/AlertStatic';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

const styles = {
	title: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
		color: siteColors.primary,
		margin: 0,
	}) as any,
	subTitle: css({
		marginTop: '0px',
		fontSize: '14px !important',
		fontWeight: '400 !important',
		width: '100% !important',
	}) as any,

	formContainer: css({
		maxWidth: 500,
		gap: '24px',
		'.input-container': {
			width: '100%',
		},
		marginBottom: '16px',
	}) as any,

	header: css({
		gap: '16px',
	}) as any,
	label1: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
		marginBottom: '8px',
	}) as any,
	label: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
	}) as any,
	searchClassName: css({
		width: '100%',
		'& .MuiOutlinedInput-root': {
			width: '100% !important',
			height: '42px!important',
			borderRadius: '11px !important',
		},
	}) as any,
	netWeightContainer: css({
		'& .MuiInputBase-root': {
			width: '120px !important',
		},
	}),

	chipStyles: css({
		textTransform: 'capitalize',
		background: 'var(--color-background)!important',
		uppercase: 'none',
		borderRadius: '8px !important',
		border: '1px solid rgba(0, 0, 0, 0.10)!important',
		span: {
			color: cn(siteColors.grey900, '!important'),
			fontSize: '1rem !important',
			padding: '8px 8px !important',
		},
	}),
	button: css({
		background: '#fff !important',
		minWidth: '36px !important',
		width: '36px !important',
		height: '36px !important',
		padding: '8px !important',
		border: cn('1px solid', siteColors.grey500, '!important'),
		borderRadius: '8px',
	}),
	actionContainer: css({
		padding: '0px 24px 16px 24px !important',
	}),
	confirmButton: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',
		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			// color: '#C74600',
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),
	dialogTitle: css({
		textTransform: 'capitalize',
	}),

	blocForm: css({
		gap: '16px',
	}) as any,

	renderOptionRoot: css({
		padding: '8px',
		cursor: 'pointer',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
	renderOptionLabel: css({
		fontWeight: 'bold',
	}),

	editAutocomplete: css({
		cursor: 'pointer',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey700),
		padding: '10.5px 10px',
		background: siteColors.common.white,
	}),

	titleAutocomplete: css({
		fontSize: '14px',
		color: siteColors.grey900,
		fontWeight: 600,
		border: 'none',
	}),
};

const QuantityForm = ({
	selectedOption,
	setSelectedOption,
	isLoading,
	isSuccess,
	isError,
	onSaveData,
	quote,
	title,
	setSearch,
	options,
	message,
	onClose,
}: any) => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const {
		register,
		control,
		handleSubmit,
		setValue,
		reset,
		getValues,
		formState: { errors },
	} = useForm<QuantityFormInput>({
		resolver: zodResolver(QuantityFormValidationSchema),
		defaultValues: async () => {
			return {
				quoteId: quote?.objectId,
				quantity: 0,
			};
		},
		mode: 'onChange',
	});

	// translation
	const { t } = useTranslation();

	const handleSubmitForm = (data: any) => {
		onSaveData(data);
	};

	return (
		<div css={{ marginBottom: 24 }}>
			<FormContainer
				loading={isLoading}
				title=""
				buttonTitle={t('save-button')}
				onSubmit={handleSubmit(handleSubmitForm)}
				isSuccess={isSuccess}
				isError={isError}
				withSave
				withCancel
				onCancel={() => {
					onClose();
				}}
				alertSuccess={<AlertStatic text={message} type={'success'} icon={<Check color={siteColors.green500} />} />}
				alertError={<AlertStatic text={message} type={'error'} icon={<AlertCircle color="#C74600" />} />}
				addStyles={{
					maxWidth: 528,
					margin: 'auto',
				}}
				t={t}
			>
				<div className={cx('flexColumn width100', styles.formContainer)}>
					<div className={cx('flexColumn width100', styles.header)}>
						<p className={cx(styles.title)}>{title}</p>
						<p className={cx(styles.subTitle)}>
							<div dangerouslySetInnerHTML={{ __html: t('quantity-form-subtitle') }} />
						</p>
					</div>

					<div className={cx('flexColumn width100', styles.blocForm)}>
						<BInput
							required
							id="quantity"
							label={<div className={cx(styles.label)}>{t('quantity')}</div>}
							fullWidth
							margin="dense"
							{...register('quantity')}
							type="number"
							error={errors.quantity ? true : false}
							errorMessage={errors?.quantity?.message}
							autoComplete="off"
						/>
					</div>
				</div>
			</FormContainer>
		</div>
	);
};

export default QuantityForm;
