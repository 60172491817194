import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

import carbonmapsIconLogo from '@carbonmaps/media/other/carbonmaps-logo.svg';

import DefaultLayout from '../layouts/_common/BaseLayout';

const SplashScreen = () => {
	return (
		<DefaultLayout>
			<Container>
				<div
					css={{
						margin: '0 auto',
						// flexGrow: 1,
						height: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						// background: 'red',
					}}
				>
					<div css={{ width: '250px', marginTop: '-128px' }}>
						<img
							src={carbonmapsIconLogo}
							alt=""
							width="250px"
							css={{ margin: '0 auto', display: 'block', marginBottom: '16px' }}
						/>
						<LinearProgress css={{ height: '6px', borderRadius: '4px' }} />
					</div>
				</div>
			</Container>
		</DefaultLayout>
	);
};

export default SplashScreen;
