import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../../hooks/useImpactYear';

import { findPackagingFamilyRepartitionAction, findPackagingSheetAction, findPackagingTableAction, getPackagingCompanyCountAction, getPackagingSheetTimePeriodAction } from './packaging.actions';

type useGetPackagingSheetProps = {
	params: any;
}

export const useGetPackagingSheet = ({
	params,
}: useGetPackagingSheetProps) => {
	const key = ['findPackagingSheet', params] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findPackagingSheetAction,
	});

	return { key, result };
};


//--------------------------------------------------------------------------------------//
//       HOOK FOR get data for one packaging with time period                    //
//--------------------------------------------------------------------------------------//

export const useGetPackagingSheetTimePeriod = ({
	objectId,
	onError,
}: {
	objectId: string;
	onError?: (err: unknown) => void;
}) => {

	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: ['getPackagingSheetTimePeriod', { objectId, period: selectedYear }],
		queryFn: getPackagingSheetTimePeriodAction,
		onError,
		retry: false,
	});

	return queryResult;
};





// ---- 2 --------------------------------------------------------------------------------
type UseFindPackagingsTableProps = {
	params: any
}

export const useFindPackagingsTable = ({
	params,
}: UseFindPackagingsTableProps) => {
	const { selectedYear } = useYearSelection();

	const key = ['findPackagingsTable', { selectedYear, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findPackagingTableAction,
	});

	return { key, result };
};

export const useFindPackagingsFamilyRepartition = ({
	params,
}: UseFindPackagingsTableProps) => {
	const { selectedYear } = useYearSelection();

	const key = ['findPackagingFamilyRepartition', { selectedYear, ...params }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findPackagingFamilyRepartitionAction,
	});

	return { key, result };
};

export const useGetPackagingCount = () => {
	const key = ['getPackagingCount'] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: getPackagingCompanyCountAction,
	});

	return { key, result };
};
