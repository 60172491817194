import { useEffect } from 'react';

import { cx } from '@emotion/css';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useTranslation } from '../../../../hooks/useTranslation';

import IngredientAcvFilter from './IngredientAcvFilter';
import IngredientAcvTable from './IngredientAcvTable';

const IngredientAcv = () => {
	const { setBreadcrumbs } = useApp();

	// translation
	const { t } = useTranslation();

	// ---- breadcrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('audit-title') }, { text: t('acv-breadcrumbs') }]);
	}, [t, setBreadcrumbs]);

	return (
		<div className={cx('flexColumn')}>
			<IngredientAcvFilter />
			<IngredientAcvTable />
		</div>
	);
};

export default IngredientAcv;
