import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { functionName } from '@carbonmaps/shared/utils/constants';
import {
	getAnalyseData,
	getBreakDownCategoriesDataAction,
	getDataAnalyzeIngredientTableAction,
	getDataAnalyzeProductTableAction,
	getDataAnalyzeSupplierTableAction,
	getDataBreakDownProductAction,
	getDataProductAnalyzeAction,
	getFacetsSegment,
	getGlobalIndicatorCategoryAction,
	getIngredientDonutAction,
	getLifeCycleDataAction,
	getOtherIndicatorAnalyzeAction,
	getSupplierDonutAction,
	getSupplierFirstElementsAction,
	getTradeOffAnalyseData,
	OtherIndicatorType,
	productsDataType,
	UseGetLifeCycleDataProps,
} from '@carbonmaps/ui/actions/analyse.actions';
import { Indicator } from '@carbonmaps/ui/contexts/AppProvider';

import { useYearSelection } from '../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';

type UseGetAnalyseDataProps = {
	indicator: Indicator;
	searchQueryParams: any;
	categorySelected: any;
	sortingBy: any;
	// resetFilter: any
	// fetchResetRef: any
};

export const useGetAnalyseData = ({
	indicator,
	searchQueryParams,
	sortingBy,
	categorySelected /* , resetFilter, fetchResetRef */,
}: UseGetAnalyseDataProps) => {
	// ------------------------------------------ //
	// ------------ query ----------------------- //
	// ------------------------------------------ //
	/* const {
		data: results,
		isLoading: isSearchLoading,
		isSuccess: isSearchSuccess,
	} */ const results = useQuery({
		queryKey: [
			'getAnalyseData',
			{
				viewMode: indicator,
				...searchQueryParams,
				sortingBy,
				facetFilters: searchQueryParams?.facetFilters || [],
				category: categorySelected,
				// facetFilters: fetchResetRef.current ? [] : searchQueryParams?.facetFilters || [],
				// resetFilter,
			},
		],
		queryFn: async ({ queryKey }) => {
			const { 1: parameters } = queryKey;

			return await getAnalyseData({ ...parameters /* , category: categorySelected */ });
		},
	}) as any;

	return results;
};

export const useFindAnalyseDataTable = ({
	indicator,
	searchQueryParams,
	sortingBy,
	categorySelected /* , resetFilter, fetchResetRef */,
}: UseGetAnalyseDataProps) => {
	const { selectedYear } = useYearSelection();

	const key = [
		'getAnalyseDataTable',
		{
			viewMode: indicator,
			...searchQueryParams,
			view: 'analyse-general-table',
			sortingBy,
			facetFilters: searchQueryParams?.facetFilters || [],
			category: categorySelected,
			selectedYear,
			// facetFilters: fetchResetRef.current ? [] : searchQueryParams?.facetFilters || [],
			// resetFilter,
		},
	] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async ({ queryKey }) => {
			try {
				const { 1: params } = queryKey;
				const { size = 100, page, input = '', facetFilters, sortingBy, category, viewMode, ...rest } = params;

				// return await getAnalyseData({ ...parameters /* , category: categorySelected */ });
				return await Parse.Cloud.run(functionName.temporality.findAnalyse, {
					category,
					viewMode,
					limit: size,
					skip: (page - 1) * size,
					pageIndex: page,
					input,
					facetFilters,
					sortingBy,
					...rest,
				});
			} catch (error) {
				console.log('------- getAnalyseDataTable error -------', error);
				return Promise.reject(error);
			}
		},
	});

	return { result, key };
};

export const useFindAnalyseDataTradeoff = () => {
	//
};

export const useAnalyseSetup = () => {
	const params = useParams();
	const [categorySelected, setCategorySelected] = useState<string>(params.segment ?? '');

	useEffect(() => {
		if (params.segment) {
			setCategorySelected(params.segment);
		}
	}, [params.segment]);

	return {
		categorySelected,
		setCategorySelected,
	};
};

type UseGetAnalyseFacets = {
	searchQueryParams: any;
	categorySelected: string;
};

export const useGetAnalyseFacets = ({
	categorySelected,
	searchQueryParams /* resetFilter, fetchResetRef,  */,
}: UseGetAnalyseFacets) => {
	/* const {
		data: facetsResult,
		isSuccess: isSuccessFetchFacets,
		isLoading: isFetchFacetLoading,
	} =  */
	return useQuery({
		queryKey: [
			'getAnalyseFacets',
			{
				input: searchQueryParams?.input || '',
				// facetFilters: fetchResetRef.current ? [] : searchQueryParams?.facetFilters || [],
				facetFilters: searchQueryParams?.facetFilters || [],
				category: categorySelected,
				// resetFilter,
			},
		] as any,
		queryFn: async ({ queryKey }) => {
			const { 1: parameters } = queryKey;

			return await getFacetsSegment({ ...parameters /* , category: categorySelected */ });
		},
	}) as any;
};

type UseGetTradeoffAnalyseDataProps = {
	searchQueryParams: any;
	categorySelected: string;
};

export const useGetTradeoffAnalyseData = ({ searchQueryParams, categorySelected }: UseGetTradeoffAnalyseDataProps) => {
	/* const {
		data: tradeOffData,
		isLoading: isDataTradeOffLoading,
		isSuccess: isDataTradeOffSuccess,
	} =  */
	const { selectedYear } = useYearSelection();
	return useQuery({
		queryKey: [
			'getTradeOffAnalyseData',
			{
				input: searchQueryParams.input,
				// facetFilters: fetchResetRef.current ? [] : searchQueryParams?.facetFilters || [],
				facetFilters: searchQueryParams?.facetFilters || [],
				category: categorySelected,
				view: 'analyse-general-tradeoff',
				selectedYear,
				// resetFilter,
			},
		] as const,
		queryFn: async ({ queryKey }) => {
			// const {
			// 	1: { input, facetFilters, category },
			// } = queryKey;
			const { input, facetFilters, category, view, selectedYear } = queryKey[1];

			// return await getTradeOffAnalyseData({ input, facetFilters, category } as any);
			return Parse.Cloud.run(functionName.temporality.getAnalyzeTradeoff, {
				input,
				facetFilters,
				category,
				view,
				selectedYear,
			});
		},
	});
};

export const useCountAnalyseSegment = ({
	categorySelected /* , resetFilter, fetchResetRef */,
}: {
	categorySelected: string | number;
}) => {
	const { selectedYear } = useYearSelection();

	const key = [
		'countAnalyseDataTable',
		{
			category: categorySelected,
			selectedYear,
		},
	] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async ({ queryKey }) => {
			try {
				const { 1: params } = queryKey;
				const { category, selectedYear } = params;

				return await Parse.Cloud.run(functionName.temporality.countAnalyseByPeriod, {
					category,
					period: selectedYear,
				});
			} catch (error) {
				console.log('------- countAnalyseDataTable error -------', error);
				return Promise.reject(error);
			}
		},
	});

	return { result, key };
};

export const useGetLifeCycleData = ({
	category,
	value,
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;

	viewMode?: any;

	onError?: (err: unknown) => void;
	onSuccess?: (data: UseGetLifeCycleDataProps) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getLifeCycleAnalyze',
			{
				category: params.segment,
				value: params.value,
				viewMode,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: getLifeCycleDataAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useBreakDownCategories = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;

	viewMode?: any;

	onError?: (err: unknown) => void;
	onSuccess?: (data: UseGetLifeCycleDataProps) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getBreakDownCategoriesData',
			{
				category: params.segment,
				value: params.value,
				viewMode,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: getBreakDownCategoriesDataAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useGlobalIndicator = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;

	viewMode?: any;

	onError?: (err: unknown) => void;
	onSuccess?: (data: UseGetLifeCycleDataProps) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getGlobalIndicatorCategoryAnalyze',
			{
				category: params.segment,
				value: params.value,
				viewMode,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: getGlobalIndicatorCategoryAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useDataProducts = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;

	viewMode?: any;

	onError?: (err: unknown) => void;
	onSuccess?: (data: productsDataType) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getDataProductAnalyze',
			{
				category: params.segment,
				value: params.value,
				viewMode,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: getDataProductAnalyzeAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useBreakDownProduct = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;

	viewMode?: any;

	onError?: (err: unknown) => void;
	onSuccess?: (data: productsDataType) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getDataBreakDownProduct',
			{
				category: params.segment,
				value: params.value,
				viewMode,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: getDataBreakDownProductAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useIngredientDonut = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;

	viewMode?: any;

	onError?: (err: unknown) => void;
	onSuccess?: (data: productsDataType) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getIngredientDonut',
			{
				category: params.segment,
				value: params.value,
				viewMode,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: getIngredientDonutAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useSupplierDonut = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: productsDataType) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getSupplierDonut',
			{
				category: params.segment,
				value: params.value,
				viewMode,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: getSupplierDonutAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useSupplierFirstElement = ({
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getSupplierFirstElements',
			{
				category: params.segment,
				value: params.value,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: getSupplierFirstElementsAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

/**
 *  product table analyze details
 * @param param0
 * @returns
 */
export const useProductTable = ({
	viewMode,
	tableOptions,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	tableOptions?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getDataAnalyzeProductTable',
			{
				viewMode,
				category: params.segment,
				value: params.value,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
				...tableOptions,
			},
		] as any,
		queryFn: getDataAnalyzeProductTableAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useIngredientTable = ({
	viewMode,
	tableOptions,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	tableOptions?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getDataAnalyzeIngredientTable',
			{
				viewMode,
				category: params.segment,
				value: params.value,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
				...tableOptions,
			},
		] as any,
		queryFn: getDataAnalyzeIngredientTableAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useSupplierTable = ({
	viewMode,
	tableOptions,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;
	viewMode?: any;
	tableOptions?: any;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getDataAnalyzeSupplierTable',
			{
				viewMode,
				category: params.segment,
				value: params.value,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
				...tableOptions,
			},
		] as any,
		queryFn: getDataAnalyzeSupplierTableAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

export const useOtherIndicator = ({
	viewMode,
	onError,
	onSuccess,
}: {
	category?: string;
	value?: string;

	viewMode?: any;

	onError?: (err: unknown) => void;
	onSuccess?: (data: OtherIndicatorType) => void;
}) => {
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const params = useParams();

	const queryResult = useQuery({
		queryKey: [
			'getOtherIndicatorAnalyze',
			{
				category: params.segment,
				value: params.value,
				viewMode,
				period: selectedYear,
				facetFilters: searchQueryParams?.facetFilters || [],
			},
		] as any,
		queryFn: getOtherIndicatorAnalyzeAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};
