import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Compass, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { FACET_STATUS, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { deleteFacetAction, getCompanySheetFacetAction } from '@carbonmaps/ui/actions/company.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import Dialog from '../../../components/dialogs/Dialog';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import OtherIndicatorsTable from '../../productSheet/synthesis/OtherIndicatorsTable';

import FacetForm from './FacetForm';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
			padding: '5px!important',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

type TableProps = {
	filterOptions?: any;
	manualSortBy?: boolean;
	resetPage?: number;
	onSelectedRow?: any; // use to update row selected in export data
	isOpenRowAdd?: boolean;
};

const formatThenSetData = (res: any, companyId: string) => {
	if (!res?.length) return [];
	return res.map((item: any) => {
		return {
			...item,
			link: `${FRONT_PATH_NAMES.superAdminSettings}/facets-fields?companyId=${companyId}&indexId=${item.indexID}&collection=${item.collection}`,
			status: FACET_STATUS?.[item.status] || '',
		};
	});
};

const FacetsTable = ({ filterOptions, manualSortBy, onSelectedRow }: TableProps) => {
	const queryParams = useQueryParams();
	const [isDialogFacetOpen, toggleFacetDialog] = useToggle(false);

	const { t } = useTranslation();

	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';
	const navigate = useNavigate();
	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState({
		input: '',
		page: 1,
		size: 100,
		companyId,
		...filterOptions,
	});

	const [message, setMessage] = useState('');

	const queryClient = useQueryClient();

	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	// ---- fetch data --- //
	const {
		data: tableResultData,
		isLoading: isTableLoading,
		refetch,
		isSuccess,
	} = useQuery({
		queryKey: [
			'getCompanySheetFacet',
			{
				companyId,
			} as any,
		],
		queryFn: getCompanySheetFacetAction,
		refetchInterval: 5000,
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ---- skeleton ---- //
	const { skeleton } = useSkeleton({ condition: isTableLoading });

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- action when click facet's column ---- //

	const handleClickEdit = useCallback((value: any) => {
		navigate(
			`${FRONT_PATH_NAMES.superAdminSettings}/facets-fields?companyId=${companyId}&indexId=${value.indexID}&collection=${value.collection}`,
		);
	}, []);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions.input]);

	// mutate action
	const confirmDelete = async (value: any) => {
		if (value.indexID) {
			await deleteSelectedRow({ indexId: value.indexID, companyId } as any);
		}
	};

	const {
		mutate: deleteSelectedRow,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteFacetSelectedRow'],
		mutationFn: deleteFacetAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getCompanySheetFacet'] });
			setMessage('');
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('status-label')} column={props.column} />;
				},
				accessor: 'status', // accessor is the "key" in the data
				editable: true,
				//	sortDescFirst: true,
				component: 'SimpleCell',
				type: 'fieldStatus',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('collection')} column={props.column} />;
				},
				accessor: 'collection', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('actions-column')} />;
				},
				accessor: 'objectId', // accessor is the "key" in the data
				editable: true,
				disableSortBy: true,
				component: 'Action',
				type: 'action',
				icon: <Trash2 color={theme.palette.grey[700]} />,
				// withConfirm: true,
				// messageConfirm: 'Supprimer cette ligne ? ',
				// callback: (value: any) => {
				// 	confirmDelete(value);
				// },

				edit: {
					showIcon: false,
					label: t('facet-update-button'),
					callback: (value: any) => {
						handleClickEdit(value);
					},
				},
			},
		];
	}, [theme, t]);

	return (
		<>
			<Dialog
				closeButton={true}
				isLoading={false}
				title={''}
				description={''}
				open={isDialogFacetOpen}
				toggle={toggleFacetDialog}
			>
				<FacetForm toggle={toggleFacetDialog} />
			</Dialog>

			<TableComponent
				tableName={'user'}
				skeleton={skeleton}
				loading={false}
				fetchData={updateTableParams}
				pageCount={1}
				noDataMessage="Aucun facette ne correspond à la sélection."
				pageSize={paramsTable.size}
				columns={tableColumns}
				resetPage={resetPage}
				data={formatThenSetData(tableResultData?.facets || [], companyId) || []}
				onSortingColumn={() => {
					return 1;
				}}
				addStyles={stylesTable}
				manualSortBy={false}
				pagination={false}
				setSelectedRow={() => {
					return 1;
				}}
			// confirmDelete={confirmDelete}
			/>
		</>
	);
};

export default FacetsTable;
