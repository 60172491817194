import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { ArrowRightLeft, Trash2, Undo2 } from 'lucide-react';

import Popover from '../../../../../apps/front/src/components/Popover';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import BButton from '../BButton';

const styles = {
	button: css({
		background: '#fff !important',
		minWidth: '36px !important',
		width: '36px !important',
		height: '36px !important',
		padding: '8px !important',
		border: cn('1px solid', siteColors.grey500, '!important'),
		borderRadius: '8px',
	}),
	actionContainer: css({
		padding: '0px 24px 16px 24px !important',
	}),
	confirmButton: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',
		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			// color: '#C74600',
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),
	dialogTitle: css({
		textTransform: 'capitalize',
	}),
	popoverContainer: css({
		padding: '24px',
		// width: '294px',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFFFFF',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
	}),

	titlePopover: css({
		textAlign: 'left !important',
		fontSize: '16px !important',
		fontWeight: '600 !important',
		lineHeight: '1 !important',
	} as any),
};

type TableActionsProps = {
	row?: any;
	onEditRow?: () => void;
	onDeleteRow?: () => void;
	onRestoreRow?: () => void;
	showRowEditButton?: boolean;
	showDeleteButton?: boolean;
	dialogTitle?: string;
};

const TableActions = ({
	onEditRow,
	onDeleteRow,
	onRestoreRow,
	showRowEditButton = true,
	showDeleteButton = true,
	row,
	dialogTitle = 'Supprimer le scénario',
}: TableActionsProps) => {
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleConfirm = () => {
		setOpen(false);
		onDeleteRow && onDeleteRow();
	};

	const isOriginalIngredientPresentCondition = row.original.isOriginal && row.original.isPresent;
	const isNotOriginalIngredientPresentCondition = !row.original.isOriginal;

	const renderWhenIsPresent = (
		<>
			{showRowEditButton && (
				<BButton
					label=""
					variant="secondary"
					className={styles.button}
					iconLeft={<ArrowRightLeft color={siteColors.grey700} size={20} />}
					onClick={onEditRow}
				/>
			)}

			{/* <Popover
				trigger={
					<div>
						<BButton
							label=""
							variant="secondary"
							className={styles.button}
							iconLeft={<Trash2 color={siteColors.grey700} size={20} />}
							// onClick={handleClickOpen}
						/>
					</div>
				}
				content={
					<div className={cx('flexColumn gap16', styles.popoverContainer)}>
						<div className="flexColumn gap8">
							<Typography className={styles.titlePopover}>{dialogTitle}</Typography>
							<Typography>Cette action ne peut pas être annulée</Typography>
						</div>
						<BButton
							className={styles.confirmButton}
							variant="tertiary"
							label={'Confirmer la suppression'}
							onClick={handleConfirm}
						/>
					</div>
				}
			/> */}
			<BButton
				label=""
				variant="secondary"
				className={styles.button}
				iconLeft={<Trash2 color={siteColors.grey700} size={20} />}
				// onClick={handleClickOpen}
				onClick={handleConfirm}
			/>
		</>
	);

	const renderWhenIsNotPresent = (
		<BButton
			label=""
			variant="secondary"
			className={styles.button}
			iconLeft={<Undo2 color={siteColors.grey700} size={20} />}
			onClick={onRestoreRow}
		/>
	);

	return (
		<div className={'flexColumn alignCenter justifyCenter  '} style={{ height: '100%' }}>
			<div className="flexRow relative" style={{ gap: 8 }}>
				{isNotOriginalIngredientPresentCondition || isOriginalIngredientPresentCondition
					? renderWhenIsPresent
					: renderWhenIsNotPresent}

				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Voulez vous supprimer "<span className={styles.dialogTitle}>{row?.original?.label}" ?</span>{' '}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">Cette action ne peut pas être annulée.</DialogContentText>
					</DialogContent>
					<DialogActions className={styles.actionContainer}>
						<BButton label="Confirmer" variant="tertiary" className={styles.confirmButton} onClick={handleConfirm} />
					</DialogActions>
				</Dialog>
			</div>
		</div>
	);
};

export default TableActions;
