import { css, cx } from '@emotion/css';
import { Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Trash2 } from 'lucide-react';

import Popover from '../../../../../apps/front/src/components/Popover';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import BButton from '../BButton';
type DeleteFieldProps = {
	value: any;
	row: any;
	column: any;
};

const styles = {
	link: css({
		cursor: 'pointer',
	}),
	popoverContainer: css({
		padding: 24,
		width: 294,
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFFFFF',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
	}),
	titlePopover: css({
		textAlign: 'left !important',
		fontSize: '16px !important',
		fontWeight: '600 !important',
	} as any),

	button: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',
		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			// color: '#C74600',
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),

	content: css({
		gap: 20,
		marginTop: '16px',
	}),
};

const DeleteVersionField = ({ value, row, column }: DeleteFieldProps) => {
	// theme
	const theme = useTheme();
	return !row?.original?.isActive && !column?.isDisabled ? (
		<Popover
			trigger={<Trash2 color={theme.palette.grey[700]} className={styles.link} />}
			content={
				<div className={styles.popoverContainer}>
					<Typography className={styles.titlePopover}>Suppression version</Typography>
					<Typography className="alignLeft">Cette action est irréversible</Typography>
					<div className={cx('flexColumn width100', styles.content)}>
						<BButton
							className={styles.button}
							variant="tertiary"
							label={'Confirmer'}
							onClick={() => {
								column?.callback(row?.original?.objectId);
							}}
						/>
					</div>
				</div>
			}
		/>
	) : null;
};

export default DeleteVersionField;
