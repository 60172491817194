import { css, cx } from '@emotion/css';
import MUILink from '@mui/material/Link';
import { ArrowUpRight } from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';
const styles = {
	link: css({
		textDecoration: 'none !important',
	}),
	container: css({
		gap: 8,
	}),
};

const LinkField = ({ value, row, column }: any) => {
	return (
		<MUILink
			component={RouterLink}
			to={`${row.original.link}/${row.original.objectId || row.original._id}/synthesis`}
			rel="noopener"
			target="_blank"
			className={styles.link}
		>
			<div className={cx('flexRow alignCenter nowrap', styles.container)}>
				{/* remove text in this feature */}
				{/* <span className="fontWeight700">{row.original.linkText || 'Fiche produit'}</span> */}
				<ArrowUpRight />
			</div>
		</MUILink>
	);
};

export default LinkField;
