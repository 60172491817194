import { useEffect } from 'react';

import qs from 'query-string';
import { Outlet, RouterProvider, createBrowserRouter, type RouteObject } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';

import AuthGuard from '../components/AuthGuard';
import { DefaultSEO } from '../components/SEO';
import Page404 from '../containers/404/Page404';
import Root from '../containers/root/Root';
import UnAuthorized from '../containers/unAuthorized/UnAuthorized';
import { useJuneTrack } from '../hooks/useJuneTrack';
import { useGetClientAuthQuery } from '../lib/react-query/features/auth/auth.hooks';

import authRoutes from './_authRoutes';
import boRoutes from './_boRoutes';
import userRoutes from './_userRoutes';

const otherRoutes: RouteObject[] = [
	{
		path: FRONT_PATH_NAMES.unauthorized,
		element: <UnAuthorized />,
	},
	{
		path: '*',
		element: <Page404 />,
	},
];

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<QueryParamProvider
				adapter={ReactRouter6Adapter}
				options={{
					searchStringToObject: qs.parse,
					objectToSearchString: (encodedParam) => {
						return qs.stringify(encodedParam, { arrayFormat: 'bracket', encode: false });
					},
				}}
			>
				<Outlet />
				<DefaultSEO />
			</QueryParamProvider>
		),
		children: [
			{
				path: '/',
				index: true,
				element: (
					<AuthGuard allowedRolesLabels="all">
						<Root />
					</AuthGuard>
				),
			},
			...userRoutes,
			...boRoutes,
			...authRoutes,
			...otherRoutes,
		],
	},
]);

const Routes = () => {
	const {
		result: { data, isLoading },
	} = useGetClientAuthQuery();
	const analytics = useJuneTrack();

	useEffect(() => {
		if (!data?.userFrom) return;

		if (!analytics) return;

		analytics?.identify(data?.userFrom?.objectId, {
			email: data?.userFrom?.email,
			role: 'Super Admin',
			name: `${data?.userFrom?.firstName} ${data?.userFrom?.lastName}`,
		});
	}, [data, analytics]);
	return <RouterProvider router={router} />;
};

export default Routes;
