import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { Atom, PackageOpen } from 'lucide-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import TableHeaderCell from '../../../../components/table/TableHeaderCell';
import Card from '../../../../components/widget/Card';
import { useTranslation } from '../../../../hooks/useTranslation';
import { siteColors } from '../../../../lib/colors';
import MaterialTableHeader from '../../components/MaterialTableHeader';
import { useEndOfLifeMaterialPercent, useMaterialTable } from '../../packagings.hooks';

const styles = {
	headerImpact: css({
		flexDirection: 'row-reverse !important',
		gap: '4px',
	} as any),
	impactContainer: css({
		flexDirection: 'row-reverse !important',
		justifyContent: 'flex-end',
	} as any),
	field: css({
		maxWidth: '200px !important',
	}),
};

const MaterialEndOfLifeTable = () => {
	// ---- define hooks ---- //
	const { t } = useTranslation();
	const { indicator } = useApp();
	// --- ref for fetch data table
	const fetchIdRef = useRef(0);
	const theme = useTheme();
	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // Suppliers filter
			direction: 0,
		},
	);

	const { data: items } = useEndOfLifeMaterialPercent({ viewMode: indicator });

	// ---- define columns table ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				// Header: 'PRODUIT FINI',
				Header: (props: any) => {
					return (
						<TableHeaderCell valueType="string" column={props.column} label={<MaterialTableHeader items={items} />} />
					);
				},
				accessor: 'label', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'EndOfLIfeMaterialDisplay',
				type: 'string',
				disableSortBy: true,
				icon: <PackageOpen size={20} color={siteColors.grey700} />,

				props: {
					className: styles.field,
				},
			},
		];
	}, [indicator, items]);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	const { data, isLoading } = useMaterialTable({
		viewMode: indicator,
		tableOptions: paramsTable,
	});

	return (
		<Card
			title={t('Par matériaux')}
			icon={<Atom color={siteColors.grey7} size={20} />}
			sx={{ padding: 0, flexWrap: 'nowrap', width: '100%' }}
			withBorder={false}
			center={false}
		>
			<TableComponent
				skeleton={isLoading}
				loading={false}
				fetchData={updateTableParams}
				pageCount={1}
				noDataMessage={t('Vous devez passer à la nouvelle méthodologie pour avoir cette information')}
				pageSize={paramsTable.size}
				columns={tableColumns}
				data={data || []}
				onSortingColumn={handleSortingTable}
				stickyHeader
				transFunc={t}
				isSelectable={false}
				addStyles={{
					' .bordered th': {
						borderLeft: 'none!important',
						background: `${theme.palette.common.white} !important`,
					},
					'.bordered th >div': {
						paddingTop: '0px !important',
						paddingLeft: '0px !important',
					},
					'.bordered td': {
						borderLeft: 'none!important',
						background: `${theme.palette.common.white} !important`,
						// borderBottom: `1px solid ${theme.palette.grey[400]}`,
					},
					'.bordered th:last-child': {
						borderRadius: '6px !important',
					},
					// '.bordered tr th:not(:nth-of-type(1)) > div,.bordered tr td:not(:nth-of-type(1)) div ,.bordered tr td:not(:nth-of-type(1)) button':
					// 	{
					// 		justifyContent: 'flex-end!important',
					// 		paddingRight: '0!important',
					// 	},
				}}
				pagination={false}
			/>
		</Card>
	);
};

export default MaterialEndOfLifeTable;
