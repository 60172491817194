import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import 'dayjs/locale/fr';

import { useGetIngredientSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetIngredientSheet';

import MetaDataCard from '../../../components/card/MetaDataCard';

dayjs.locale('fr');

const IngredientMetaDataBlock = (/* props: Props */) => {
	const params = useParams();

	const { data, isLoading, isError, error } = useGetIngredientSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.ingredientId!,
		// period: 2023,
	});

	return <MetaDataCard importedOn={data?.ingredient.createdAt} updatedAt={data?.ingredient.updatedAt} />;
};

export default IngredientMetaDataBlock;
