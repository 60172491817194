import { classNames, functionName } from "@carbonmaps/shared/utils/constants";
import { useQuery } from "@tanstack/react-query";
import { SortingState } from "@tanstack/react-table";
import _ from "lodash";
import { JsonParam, StringParam, useQueryParam } from "use-query-params";

export const useRseQFormTabQueryParam = () => {
	return useQueryParam('rse:questionFormId', StringParam);
}

export const useRseQFormMasterCategoryParam = () => {
	return useQueryParam('rse:questionFormMasterCategory', JsonParam);
}

// const formMasterCategorySelectOptions = ({ questionForm }: { questionForm: Record<string, any> }) => {
// 	// const questionSettings = questionForm.get('questionSettings') || [];
// 	const questionSettings = _.get(questionForm, 'questionSettings', []);

// 	// find master question
// 	const masterQuestion = questionSettings.find((setting: Record<string, any>) => {
// 		return _.get(setting, 'masterQuestion') === true;
// 	});

// 	if (!masterQuestion) {
// 		return [];
// 	}

// 	const questionType = _.get(masterQuestion, 'fr.type') || _.get(masterQuestion, 'en.type');

// 	// TODO: remove and handle type: 'yes_no'
// 	if (questionType !== 'multiple_choice') {
// 		return [];
// 	}

// 	const arrayEn = _.get(masterQuestion, 'en.multiple_choice_answers', []);
// 	const arrayFr = _.get(masterQuestion, 'fr.multiple_choice_answers', []);
// 	const length = _.max([arrayEn.length, arrayFr.length]);

// 	const categories: any[] = [];

// 	Array.from({ length }).forEach((_void, index) => {
// 		const en = arrayEn[index];
// 		const fr = arrayFr[index];

// 		const category = {
// 			enId: _.get(en, 'answerId'),
// 			frId: _.get(fr, 'answerId'),

// 			label: _.get(fr, 'answerLabel') || _.get(en, 'answerLabel'),
// 		};

// 		categories.push(category);
// 	});

// 	return categories.filter(category => category.label);
// };


export const useFindQuestionFormsTabs = () => {
	return useQuery({
		queryKey: ['FindQuestionFormsTabs'] as const,
		queryFn: async () => {
			try {
				const results = await Parse.Cloud.run(functionName.questions.findQuestionFormsTabs);
				return results
			} catch (error) {

				return Promise.reject(error);
			}
		},
	})
}

export const useFindRseTableData = ({ questionFormId, sorting, page, pageSize }: { questionFormId: string; sorting: SortingState; page: number; pageSize: number; }) => {
	const [_masterCategory] = useRseQFormMasterCategoryParam();

	let masterCategory: Record<string, any> | undefined

	if (_masterCategory) {
		masterCategory = _.pick(_masterCategory, ['enId', 'frId', 'itId', 'esId']);
	}

	return useQuery({
		queryKey: ['FindRSETableData', { questionFormId, masterCategory, sorting, page, pageSize }] as const,
		queryFn: async ({ queryKey }) => {
			const { questionFormId, masterCategory, sorting, page, pageSize } = queryKey[1];

			try {
				const result = await Parse.Cloud.run(functionName.questions.findRseTableData, { questionFormId, masterCategory, sorting, page, pageSize });

				return result;
			} catch (error) {

				return Promise.reject(error);
			}
		}
	})
}

export const useFindGeneralRSERadarChartData = ({ questionFormId }: { questionFormId: string }) => {
	const [_masterCategory] = useRseQFormMasterCategoryParam();

	let masterCategory: Record<string, any> | undefined

	if (_masterCategory) {
		masterCategory = _.pick(_masterCategory, ['enId', 'frId', 'itId', 'esId']);
	}

	return useQuery({
		queryKey: ['FindGeneralRSERadarChartData', { questionFormId, masterCategory }] as const,
		queryFn: async (ctx) => {
			const { questionFormId, masterCategory } = ctx.queryKey[1];

			try {
				const result = await Parse.Cloud.run(functionName.suppliers.rse.findGeneralRSERadarChartData, { questionFormId, masterCategory });
				return result;
			} catch (error) {

				return Promise.reject(error);
			}
		}
	})
}

export const useFindGeneralRSESuppliersRepartitionChart = ({ questionFormId }: { questionFormId: string }) => {
	const [_masterCategory] = useRseQFormMasterCategoryParam();

	let masterCategory: Record<string, any> | undefined

	if (_masterCategory) {
		masterCategory = _.pick(_masterCategory, ['enId', 'frId', 'itId', 'esId']);
	}

	const key = ['FindGeneralRSESuppliersRepartitionChart', { questionFormId, masterCategory }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async (context) => {
			const { questionFormId, masterCategory } = context.queryKey[1];
			return Parse.Cloud.run(functionName.suppliers.rse.findGeneralRSESuppliersRepartitionChart, { questionFormId, masterCategory });
		},
	});

	return { result, key };
};
