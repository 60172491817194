import _ from 'lodash';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';

import { randomColor } from '../utils/color.utils';

import ProductModel from './Product.model';

export default class QuoteModel {
	quote: any;

	constructor(quote: any) {
		this.quote = quote || {};
	}

	getName() {
		return this.quote?.name;
	}

	getClient() {
		return this?.quote?.client;
	}

	getGroup() {
		return this?.quote?.groups ?? []; // don't order because it is already order in database
		// return _.orderBy(this?.quote?.groups, ['createdAt'], ['asc']) ?? [];
	}

	getEstimatedImpact(indicator: any, product?: any) {
		let total = 0;
		const groups = this.getGroup();

		groups.map((item: any) => {
			const products = item?.products || [];
			products.map((p: any) => {
				const fieldKey = indicator === CARBONE_INDICATOR ? 'gesTotalImpact' : 'waterUseTotalImpact';

				// check when product is change
				const isChange = product ? product?.productId === p?.objectId && product?.groupId === item?.objectId : false;
				total = total + Number(isChange ? product[fieldKey]?.toFixed(0) : p[fieldKey]?.toFixed(0));
			});
		});

		return total;
	}

	getAverageIntensity(indicator: any, product?: any) {
		let productsCount = 0;
		let total = 0;
		const groups = this.getGroup();

		groups.map((item: any) => {
			const products = item?.products || [];
			products.map((p: any) => {
				productsCount = productsCount + 1;
				const fieldKey = indicator === CARBONE_INDICATOR ? 'gesTotal' : 'waterUseTotal';

				// check when product is change
				const isChange = product ? product?.productId === p?.objectId && product?.groupId === item?.objectId : false;
				total = total + Number(isChange ? product[fieldKey]/* ?.toFixed(0) */ : p[fieldKey]/* ?.toFixed(0) */);
			});
		});

		return total / (productsCount || 1);
	}

	getTotalImpactPerGroup(indicator: any) {
		const impactPerGroup = [] as any;
		const groups = this.getGroup();
		groups.map((item: any) => {
			let total = 0;
			const products = item?.products || [];

			products.map((p: any) => {
				const fieldKey = indicator === CARBONE_INDICATOR ? 'gesTotalImpact' : 'waterUseTotalImpact';
				total = total + p[fieldKey];
			});

			if (products?.length) {
				impactPerGroup.push(total);
			}
		});

		// mean per group

		const total = _.mean(impactPerGroup);

		return total;
	}

	getGesTransport() {
		let total = 0;
		const groups = this.getGroup();
		groups.map((item: any) => {
			const products = item?.products || [];

			products.map((p: any) => {
				total = total + p.gesTransport;
			});
		});
		return total;

	}

	getQuantityTotal() {
		let total = 0;
		const groups = this.getGroup();
		groups.map((item: any) => {
			const products = item?.products || [];

			products.map((p: any) => {
				total = total + p.quantity;
			});
		});
		return total;
	}

	getImpactTotal(indicator = 'carbon') {
		let total = 0;
		const groups = this.getGroup();
		groups.map((item: any) => {
			const products = item?.products || [];

			products.map((p: any) => {
				total = total + p.quantity * (indicator === 'carbon' ? (p?.carbonImpactPortion || p?.gesImpactPortion) : (p?.waterImpactPortion || p?.waterUseImpactPortion));
			});
		});
		return total;
	}

	getDataPieChart(withColor = false, indicator = 'carbon') {
		const quantityTotal = this.getQuantityTotal();
		const impactTotal = this.getImpactTotal(indicator);

		const data = [] as any;
		const groups = this.getGroup();
		let index = 0;
		groups.map((item: any) => {
			const products = item?.products || [];

			products.map((p: any) => {

				data.push({
					// value: ((p?.quantity || 0) * 100) / quantityTotal,
					value:
						((p?.quantity * (indicator === 'carbon' ? (p?.carbonImpactPortion || p?.gesImpactPortion) : (p?.waterImpactPortion || p?.waterUseImpactPortion)) || 0) * 100) /
						impactTotal,
					name: p?.label,
					//	y: (p?.quantity || 0 * 100) / quantityTotal,
					y:
						((p?.quantity * (indicator === 'carbon' ? (p?.carbonImpactPortion || p?.gesImpactPortion) : p?.waterImpactPortion) || 0) * 100) /
						impactTotal,
					isBlueCheck: p.product?.tagAdvancedModelization === 'yes',
					color: withColor ? randomColor(index) : undefined,
				});
				index += 1;
			});
		});
		return data;
	}

	getIntensityValue(field: string) {
		let totalIntensity = 0;
		const groups = this.getGroup();
		groups.map((item: any) => {
			const products = item?.products || [];
			products.map((p: any) => {
				const productObject = new ProductModel(p);
				const value = p?.[field] ?? (productObject?.getIntensity(field) || 0);
				//ATTENTION : Impact à la portion = intensité * poids du produit
				//ATTENTION : netWeight en g => convert to kg
				totalIntensity = (totalIntensity || 0) + value * (parseFloat(p?.netWeight || 0) / 1000) * parseFloat(p?.quantity || 0);

			});
		});
		return totalIntensity;
	}

	getDataLifeCycle(indicator: any) {
		const dataMap = new Map();

		//const gesTotal = this.getIntensityValue(indicator === CARBONE_INDICATOR ? 'gesTotal' : 'waterUseTotal');

		const gesAgriculture = this.getIntensityValue(
			indicator === CARBONE_INDICATOR ? 'gesAgricultures' : 'waterUseAgricultures',
		);
		const gesTransformation = this.getIntensityValue(
			indicator === CARBONE_INDICATOR ? 'gesTransformation' : 'waterUseTransformation',
		);
		const gesPackaging = this.getIntensityValue(indicator === CARBONE_INDICATOR ? 'gesPackaging' : 'waterUsePackaging');
		const gesTransport = this.getIntensityValue(indicator === CARBONE_INDICATOR ? 'gesTransport' : 'waterUseTransport');
		const gesDistribution = this.getIntensityValue(
			indicator === CARBONE_INDICATOR ? 'gesDistribution' : 'waterUseDistribution',
		);
		const gesConsumption = this.getIntensityValue(
			indicator === CARBONE_INDICATOR ? 'gesConsumption' : 'waterUseConsumption',
		);

		const gesTotal = parseFloat(gesAgriculture as any) +
			parseFloat(gesTransformation as any)
			+ parseFloat(gesPackaging as any)
			+ parseFloat(gesTransport as any)
			+ parseFloat(gesDistribution as any)
			+ parseFloat(gesConsumption as any);

		dataMap.set('gesAgriculture', {
			value: gesAgriculture,
			percentValue: (gesAgriculture * 100) / gesTotal,
		});


		dataMap.set('gesTransformation', {
			value: gesTransformation,
			percentValue: (gesTransformation * 100) / gesTotal,
		});


		dataMap.set('gesPackaging', {
			value: gesPackaging,
			percentValue: (gesPackaging * 100) / gesTotal,
		});

		dataMap.set('gesTransport', {
			value: gesTransport,
			percentValue: (gesTransport * 100) / gesTotal,
		});


		dataMap.set('gesDistribution', {
			value: gesDistribution,
			percentValue: (gesDistribution * 100) / gesTotal,
		});


		dataMap.set('gesConsumption', {
			value: gesConsumption,
			percentValue: (gesConsumption * 100) / gesTotal,
		});

		return dataMap;
	}
}
