import { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useParams } from 'react-router-dom';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import BlockTitle from '../../../components/BlockTitle';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetPackagingSheetTimePeriod } from '../../../lib/react-query/features/packaging/packaging.hooks';
import PackagingModel from '../../../models/Packaging.model';

import OtherIndicatorsTable from './OtherIndicatorsTable';

const toExponential = (num: number, digits = 2) => {
	return num.toExponential(digits);
};

const styles = {
	hidden: css({
		display: 'none',
	}),
};

const PackagingOtherIndicatorsBlock = () => {
	const theme = useTheme();
	const params = useParams();

	// translation
	const { t } = useTranslation();

	const { data, isLoading, isError, error } = useGetPackagingSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.packagingId!,
		// period: 2023,
	});

	const otherIndicatorsColumns = useMemo(() => {
		return [
			{
				accessor: 'label', // accessor is the "key" in the data
				Header: (props: any) => {
					return (
						<Box padding="16px" bgcolor={theme.palette.grey[400]}>
							<Typography fontSize="14px" color={theme.palette.grey[900]} sx={{ opacity: 0 }}>
								invisible
							</Typography>
						</Box>
					);
				},
				Cell: (props: any) => {
					// console.log('====================================');
					// console.log('PROPS', props);
					// console.log('====================================');
					return (
						<Box padding="16px">
							<Typography fontSize="14px" color={theme.palette.grey[900]}>
								{props.value}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessor: 'measure', // accessor is the "key" in the data
				Header: (props: any) => {
					return (
						<Box padding="16px" bgcolor={theme.palette.grey[400]}>
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="right">
								{t('per_kilo')}
							</Typography>
						</Box>
					);
				},
				Cell: (props: any) => {
					// console.log('====================================');
					// console.log('PROPS', props);
					// console.log('====================================');
					return (
						<Box padding="16px">
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="right">
								{props.value.replace('.', ',')}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessor: 'unit', // accessor is the "key" in the data
				Header: (props: any) => {
					return (
						<Box padding="16px" bgcolor={theme.palette.grey[400]}>
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="left">
								{t('product-other-indicator-mesure')}
							</Typography>
						</Box>
					);
				},
				Cell: (props: any) => {
					// console.log('====================================');
					// console.log('PROPS', props);
					// console.log('====================================');
					return (
						<Box padding="16px">
							<Typography fontSize="14px" color={theme.palette.grey[900]} textAlign="left">
								{props.value}
							</Typography>
						</Box>
					);
				},
			},
		];
	}, [theme.palette.grey, t]);

	const toValueIndicatorByView = (value: any) => {
		if (value === 0) {
			return '-';
		}

		return toExponential(value);
	};

	const otherIndicatorsData = useMemo(() => {
		const packagingObject = new PackagingModel(data);

		/* eslint-disable quotes */
		return [
			{
				label: t("Appauvrissement de la couche d'ozone"),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('ozone1') ?? 0),

				unit: 'kg CFC11 eq/kg',
			},
			{
				label: t('Rayonnements ionisants'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('rayonnements') ?? 0),
				unit: 'kBq U235 eq/kg',
			},
			{
				label: t("Formation photochimique d'ozone"),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('ozone2') ?? 0),
				unit: 'kg NMVOC eq/kg',
			},
			{
				label: t('Particules fines'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('particules') ?? 0),
				unit: 'disease increase/kg',
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances non-cancérogènes'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('toxicite1') ?? 0),
				unit: 'CTUh/kg',
			},
			{
				label: t('Effets toxicologiques sur la santé humaine : substances cancérogènes'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('toxicite2') ?? 0),
				unit: 'CTUh/kg',
			},
			{
				label: t('Acidification terrestre et eau douce'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('acidification') ?? 0),
				unit: 'mol H+ eq/kg',
			},
			{
				label: t('Eutrophisation eau douce'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('eutrophisation1') ?? 0),
				unit: 'kg P eq/kg',
			},
			{
				label: t('Eutrophisation marine'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('eutrophisation2') ?? 0),
				unit: 'kg N eq/kg',
			},
			{
				label: t('Eutrophisation terrestre'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('eutrophisation3') ?? 0),
				unit: 'mol N eq/kg',
			},
			{
				label: t("Ecotoxicité pour écosystèmes aquatiques d'eau douce"),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('ecotox') ?? 0),
				unit: 'CTUe/kg',
			},
			{
				label: t('Utilisation du sol'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('landUse') ?? 0),
				unit: 'Pt/kg',
			},
			{
				label: t('Epuisement des ressources énergétiques'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('fossilUse') ?? 0),
				unit: 'MJ/kg',
			},
			{
				label: t('Epuisement des ressources minéraux'),
				measure: toValueIndicatorByView(packagingObject?.getIntensity('mineralUse') ?? 0),
				unit: 'kg Sb eq/kg',
			},
		];
		/* eslint-enable quotes */
	}, [data, t]);

	const [hidden, setHidden] = useState(true);

	return (
		<>
			<BButton
				addStyles={{ width: '100%', marginBottom: '8px' }}
				variant="secondary"
				// eslint-disable-next-line quotes
				label={
					hidden ? (
						<>
							{/* eslint-disable-next-line quotes */}
							{t("Voir plus d'indicateurs")}
							<ChevronDown size={18} />
						</>
					) : (
						<>
							{t('Voir moins')}
							<ChevronUp size={18} />
						</>
					)
				}
				onClick={() => {
					return setHidden(!hidden);
				}}
			/>
			{/* <BlockTitle variant="h3" color={theme.palette.common.black}>
				{t('product-other-indicator')}
			</BlockTitle> */}
			<Box mb={pxToRem(100)}>
				{/* <TableComponent columns={otherIndicatorsColumns} loading={false} fetchData={() => {}} data={[]} pageCount={0} /> */}
				<div className={cx(hidden ? styles.hidden : '')}>
					<OtherIndicatorsTable columns={otherIndicatorsColumns} data={otherIndicatorsData} />
				</div>
			</Box>
		</>
	);
};

export default PackagingOtherIndicatorsBlock;
