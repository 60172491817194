import { css, cx } from '@emotion/css';

import { useTranslation } from '../../hooks/useTranslation';

const styles = {
	container: css({
		padding: '0px 8px',
		alignItems: 'center',
		borderRadius: '8px',
		border: '1px solid rgba(0, 0, 0, 0.10)',
		height: '22px',
		fontSize: '12px',
		fontFamily: 'Inter',
	}),
};

const Beta = () => {
	const { t } = useTranslation();
	return <div className={cx('flexRow alignCenter', styles.container)}>{t('beta')}</div>;
};

export default Beta;
