import { useEffect, useRef } from 'react';

import { type MDXEditorMethods } from '@mdxeditor/editor';
import { FormHelperText } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { classNames } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';

import MdxEditor from '../../../components/MdxEditor';

import { useGetSectorSheetById } from './sectorSheet.hooks';

const SectorSheetTranslation = ({
	lng,
	sheetForm,
	sheetData,
}: {
	lng: string;
	sheetForm: UseFormReturn;
	sheetData: any | undefined;
}) => {
	const editorRef = useRef<MDXEditorMethods>(null);

	useEffect(() => {
		if (!sheetData) {
			return;
		}

		editorRef.current?.setMarkdown(sheetData.translation[lng].content || '');
	}, [lng, sheetData]);

	return (
		<div>
			{/* <FormControl>
					<FormLabel id="demo-row-radio-buttons-group-label">Language</FormLabel>
					<Controller
						control={sheetForm.control}
						name="language"
						render={({ field }) => {
							return (
								<RadioGroup row {...field}>
									<FormControlLabel value="fr" control={<Radio />} label="Français" />
									<FormControlLabel value="en" control={<Radio />} label="Anglais" />
								</RadioGroup>
							);
						}}
					/>
				</FormControl> */}

			<BInput
				label={`title ${lng}`}
				type="text"
				{...sheetForm.register(`title_${lng}`)}
				addStyles={{ marginBottom: 24 }}
			/>

			<Controller
				// key={sheetData ? 'undefined' : 'defined'}
				name={`content_${lng}`}
				control={sheetForm.control}
				render={({ field, fieldState: { error } }) => {
					return (
						<>
							<div>Content {lng}</div>
							<MdxEditor
								editorRef={editorRef}
								id={`content_${lng}`}
								markdown={field.value}
								onChange={field.onChange}
								error={!!error}
								disabled={field.disabled}
								helperText={
									!!error /* || helperText */ && (
										<FormHelperText error={!!error} sx={{ px: 2 }}>
											{/* {error ? error?.message : helperText} */}
											{error?.message}
										</FormHelperText>
									)
								}
								// {...other}
							/>
						</>
					);
				}}
			/>
			{/* <MdxEditor markdown="# Hello" /> */}
		</div>
	);
};

export default SectorSheetTranslation;
