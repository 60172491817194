import { useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles/createTheme';
import useTheme from '@mui/material/styles/useTheme';
import { AlertCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { ReactComponent as EditIcon } from '@carbonmaps/media/icons/edit.svg';
import { ReactComponent as HourglassIcon } from '@carbonmaps/media/icons/hourglass.svg';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import AlertStatic from '../../../../../apps/front/src/components/Toast/AlertStatic';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import BButton from '../BButton';
import BInput from '../BInput';

const styles = {
	boxContainer: css({
		padding: '8px 0 8px 8px',
		flex: '2 1 auto',
		gap: '4px',
	}),
	boxHourGlass: css({
		padding: '24px',
		width: '294px',
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: '#FFFFFF',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
		gap: '16px',
	}),
	textPopup: css({
		fontWeight: 600,
		fontSize: '16px !important',
	}),

	contentPopup: css({
		marginTop: '12px',
	}),
	buttonPopover: css({
		'&:hover': { backgroundColor: 'unset !important' },
	}),

	iconHourGlass: css({
		height: '18px',
		width: '18px',
		'& path': {
			// fill: palette.grey[700],
			fill: siteColors.grey700,
		},
	}),
	errorContent: css({
		bottom: '30px',
		zIndex: 10000,
	}),
	alert: css({
		color: '#C74600 !important',
	}),
};

const classes = (theme: Theme) => {
	return {
		popupHourGlass: css({
			fontSize: '16px',
			fontWeight: 600,
			textTransform: 'none',
			gap: 10,
			color: theme.palette.common.white,
			background: siteColors.primary,
			borderRadius: '8px',
			padding: '8px 16px',
			':hover': {
				background: siteColors.primaryHover,
			},
			'&.Mui-disabled': {
				color: theme.palette.common.white,
				background: siteColors.primary,
			},
		}),
	};
};

const sendEmailUpdateEmailSchema = Yup.object().shape({
	email: Yup.string().email('Entrez un adresse e-mail valide').required('Entrez votre adresse e-mail'),
	currentEmail: Yup.string(),
});

type SendUpdateEmailFormInput = Yup.InferType<typeof sendEmailUpdateEmailSchema>;

const EditableEmailInput = ({ value: initialValue, row, callback }: any) => {
	const theme = useTheme();
	const stylesTheme = useMemo(() => {
		return classes(theme);
	}, [theme]);
	const [isSelected, setSelected] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue] = useState(initialValue);
	const [currentEmail, setCurrentEmail] = useState(row.original.email);
	const [toggle, setToggle] = useState(true);

	const handleClick = () => {
		setToggle(!toggle);
	};

	// If the initialValue is changed external, sync it up with our state
	useEffect(() => {
		const a = row.original.newEmail ? row.original.newEmail : row.original.email;
		setValue(a);
		setCurrentEmail(row.original.email);
	}, [row.original]);

	const onSubmit = async (values: any) => {
		setValue(values.email);
		setCurrentEmail(values.currentEmail);
		await callback({ currentEmail: row.original.email, email: values.email, type: 'changeEmail' });
		setToggle(!toggle);
	};

	const resendEmailNewAccount = async (values: any) => {
		await callback({
			currentEmail: row.original.email,
			email: row.original.newEmail,
			type: 'sendEmailNewAccount',
			roleLabel: row.original?.role?.label

		});
	};

	const resendEmailNewEmail = async (values: any) => {
		await callback({ currentEmail: row.original.email, email: row.original.newEmail, type: 'sendEmailNewEmail' });
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
		getValues,
	} = useForm<SendUpdateEmailFormInput>({
		defaultValues: {
			email: '',
		},
		mode: 'onChange',
		resolver: yupResolver(sendEmailUpdateEmailSchema) as any,
	});

	const handleBlur = () => {
		setSelected(false);
	};

	return (
		<form>
			{toggle ? (
				<div className={cx('flexRow alignCenter spaceBetween', styles.boxContainer)}>
					{row.original.email}

					<div className="flexRow">
						{row.original.isActive === true &&
							row.original.newEmail &&
							row.original.email &&
							row.original.newEmail !== '' &&
							row.original.newEmail !== row.original.email && (
								<>
									<BPopover
										anchorOriginVertical={'bottom'}
										anchorOriginHorizontal={'right'}
										transformOriginVertical={'top'}
										transformOriginHorizontal={'right'}
										content={(onClose) => {
											return (
												<div className={styles.boxHourGlass}>
													<Typography className={cx('alignLeft', styles.textPopup)}>Changement en attente</Typography>

													<Typography className="alignLeft">Lien de validation envoyé à {value}</Typography>

													<div className={styles.textPopup}>
														<Button onClick={resendEmailNewEmail} className={stylesTheme.popupHourGlass}>
															{isLoading && (
																<CircularProgress size={20} style={{ color: theme.palette.common.white }} />
															)}
															Renvoyer l'email
														</Button>
													</div>
												</div>
											);
										}}
									>
										<Button className={cx('width100', styles.buttonPopover)}>
											<HourglassIcon className={styles.iconHourGlass} />
										</Button>
									</BPopover>
								</>
							)}
						{row.original.isActive === false && (
							<>
								<BPopover
									anchorOriginVertical={'bottom'}
									anchorOriginHorizontal={'right'}
									transformOriginVertical={'top'}
									transformOriginHorizontal={'right'}
									content={(onClose) => {
										return (
											<div className={styles.boxHourGlass}>
												<Typography className={cx('alignLeft', styles.textPopup)}>
													Création du compte en attente
												</Typography>

												<Typography className="alignLeft">Lien de validation envoyé à {row.original.email}</Typography>

												<div className={styles.contentPopup}>
													<Button onClick={resendEmailNewAccount} className={stylesTheme.popupHourGlass}>
														{isLoading && <CircularProgress size={20} style={{ color: theme.palette.common.white }} />}
														Renvoyer l'email
													</Button>
												</div>
											</div>
										);
									}}
								>
									<Button className={cx('width100', styles.buttonPopover)}>
										<HourglassIcon className={styles.iconHourGlass} />
									</Button>
								</BPopover>
							</>
						)}
						<Button disableRipple className={styles.buttonPopover} onClick={handleClick}>
							<EditIcon
								css={{
									height: '18px',
									width: '18px',
									'& path': {
										fill: theme.palette.grey[700],
									},
								}}
							/>
						</Button>
					</div>
				</div>
			) : (
				<ClickAwayListener
					onClickAway={() => {
						setToggle(!toggle);
					}}
				>
					<div className={cx('flexRow alignCenter spaceBetween', styles.boxContainer)}>
						<BInput
							type="text"
							required
							id="email"
							disabled={isLoading}
							placeholder={value}
							label=""
							{...register('email')}
							name="email"
							error={errors.email ? true : false}
							onBlur={handleBlur}
							addStyles={{
								minWidth: '344px',
							}}
						/>

						<BButton
							onClick={handleSubmit(onSubmit)}
							addStyles={{
								minWidth: '44px',
								width: '44px',
								height: '44px',
							}}
							label={
								isLoading ? (
									<CircularProgress size={20} className="whiteColor" />
								) : (
									<CheckIcon sx={{ color: 'white' }} />
								)
							}
							variant="primary"
							variantDisabled="secondary"
						/>
					</div>
				</ClickAwayListener>
			)}

			{errors.email && errors?.email?.message && (
				<div className={cx('fixed', styles.errorContent)}>
					<AlertStatic
						text={errors.email ? errors?.email?.message : ''}
						type="error"
						icon={<AlertCircle className={styles.alert} />}
					/>
				</div>
			)}
		</form>
	);
};

export default EditableEmailInput;
