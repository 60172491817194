import { useCallback, useEffect, useState } from 'react';

import { css, cx } from '@emotion/css';
import { TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CopyPlus, Trash2 } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { CARBONE_INDICATOR, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { deleteGroupAction, duplicateGroupAction, saveQuoteGroupAction } from '@carbonmaps/ui/actions/quote.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import DeleteButton from '@carbonmaps/ui/components/saas/Table/DeleteButton';
import { useDebounce } from '@carbonmaps/ui/hooks/useDebounce';

import Popover from '../../components/Popover';
import LoadingDialog from '../../components/dialogs/LoadingDialog';
import { useTranslation } from '../../hooks/useTranslation';
import QuoteModel from '../../models/Quote.model';
import { WATER_INDICATOR } from '../../utils/constants';

import { cn, siteColors } from '../../lib/colors';
import { useAddGroupQuote, useQuoteStore } from './quote.hooks';

const styles = {
	container: css({
		gap: 16,
	}),
	copyButton: css({
		background: cn(siteColors.grey400, '!important'),
		color: cn(siteColors.text, '!important'),
		fontSize: '14px !important',
	}),
	textField: css({
		'& .MuiInputBase-root': {
			height: '42px',
			borderRadius: '8px',
		},
		'& fieldset': {
			borderColor: cn(siteColors.grey700, '!important'),
			borderWidth: '1px !important',
		},
	}),
	actionContainer: css({
		gap: 8,
	}),
};

type QuoteGroupHeaderProps = {
	group?: {
		objectId?: string;
		name: string;
	};
};

const QuoteGroupHeader = ({ group }: QuoteGroupHeaderProps) => {
	const [groupName, setGroupName] = useState(group?.name);
	const queryClient = useQueryClient();
	const params = useParams();

	// translation
	const { t } = useTranslation();

	const debounceValue = useDebounce(groupName, 1500);

	const [isChanged, setIsChanged] = useState(false);

	const handleChange = (e: any) => {
		setGroupName(e.target.value);
	};

	const handleStopEvent = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const onKeyUp = (evt: any) => {
		setIsChanged(true);
		setGroupName(evt.target.value);
	};

	// ---- action when delete row ---- //
	const handleClickDelete = useCallback(async (value: any) => {
		await deleteGroup({ objectId: value } as any);
	}, []);

	// ----------------------------------------------------------------- //
	// ------------------------ saved quote group ---------------------- //
	// ----------------------------------------------------------------- //
	// const { mutate: saveQuoteGroup } = useMutation({
	// 	mutationKey: ['saveQuoteGroup'],
	// 	mutationFn: saveQuoteGroupAction,
	// 	onSuccess: async () => {
	// 		queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
	// 	},
	// 	onError: (error: any) => {
	// 		queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
	// 	},
	// });
	const { mutate: saveQuoteGroup } = useAddGroupQuote();

	// ------------------------------------------------------ //
	// ----------------- duplicate group -------------------- //
	// ------------------------------------------------------ //

	const { mutate: duplicateGroup, isLoading: isLoadingUpdate } = useMutation({
		mutationKey: ['duplicateGroup'],
		mutationFn: duplicateGroupAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
	});

	// ------------------------------------------------------ //
	// ----------------- delete group ----------------------- //
	// ------------------------------------------------------ //
	const navigate = useNavigate();

	const {
		mutate: deleteGroup,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteGroup'],
		mutationFn: deleteGroupAction,
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			setTimeout(() => {
				navigate(`${FRONT_PATH_NAMES.quote}/${params?.quoteId}`);
			}, 700);
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
	});

	const { quote } = useQuoteStore();

	// ----- add group ------ //
	useEffect(() => {
		// update group Name
		if (!groupName) return;

		if (!isChanged) return;

		saveQuoteGroup({
			quoteId: params?.quoteId,
			group: { objectId: group?.objectId, name: groupName },
			estimatedImpactCarbon: (quote as QuoteModel | null)?.getEstimatedImpact(CARBONE_INDICATOR),
			estimatedImpactWater: (quote as QuoteModel | null)?.getEstimatedImpact(WATER_INDICATOR),
			averageCarbonIntensity: (quote as QuoteModel | null)?.getAverageIntensity(CARBONE_INDICATOR),
			averageWaterIntensity: (quote as QuoteModel | null)?.getAverageIntensity(WATER_INDICATOR),
		});
	}, [debounceValue]);

	useEffect(() => {
		setGroupName(group?.name);
	}, [group]);

	return (
		<div className={cx('flexRow spaceBetween alignCenter width100', styles.container)} onClick={handleStopEvent}>
			<TextField
				className={cx('flex1', styles.textField)}
				// value={groupName}
				value={!/^\s*$/.test(groupName || '') ? groupName : ''}
				defaultValue={groupName || t('Sans nom')}
				onChange={handleChange}
				placeholder={t('Sous-partie du devis')}
				// onBlur={handleStopEvent}
				// onFocus={handleStopEvent}
				onKeyUp={onKeyUp}
			/>
			<div className={cx('flexRow alignCenter', styles.actionContainer)}>
				<BButton
					label={t('duplicate-button')}
					iconLeft={<CopyPlus size={20} color={siteColors.grey700} />}
					variant="tertiary"
					onClick={() => {
						duplicateGroup({ quoteId: params?.quoteId, groupId: group?.objectId });
					}}
					className={cx('', styles.copyButton)}
				/>

				<DeleteButton
					row={{
						original: group,
					}}
					column={{
						icon: <Trash2 color={siteColors.grey700} />,
						withConfirm: true,
						callback: (value: any) => {
							handleClickDelete(value);
						},
						messageConfirm: t('delete-quote-product-title'),
						subMessageConfirm: t('delete-quote-product-subtitle'),
					}}
				/>
			</div>
			<LoadingDialog open={isLoadingUpdate} content={`${t('Modification en cours')}...`} />
		</div>
	);
};

export default QuoteGroupHeader;
