import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { Presentation, Wheat } from 'lucide-react';

import { getDataSBTiFlagAction } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import CircularProgress from '../../../../components/card/CircularProgress';
import CardContent from '../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../components/dashboard/v2/CardTitle';
import DashboardCard from '../../../../components/dashboard/v2/DashboardCard';
import Icon from '../../../../components/dashboard/v2/Icon';
import Typography from '../../../../components/Typography';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
const styles = {
	container: css({
		flex: '1 1 50%',
	}),
	innerContainer: css({}),
	content: css({
		padding: '24px !important',
		minHeight: 130,
	}),
	circle: css({
		width: 8,
		height: 8,
		background: 'var(--background)',
		borderRadius: '50%',
	}),
};

const SbtiFlag = () => {
	const { t } = useTranslation();

	const { indicator } = useApp();

	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	// ---- fetch data graph for this period selected ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getDataSBTiFlag',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
			},
		],
		queryFn: getDataSBTiFlagAction,
	});

	// define: sbtiFlagPercent = percent of agriculture (gesAgriculture)
	const sbtiFlagPercent = useMemo(() => {
		if (!data) return 0;

		const gesAgricultureImpact =
			indicator === 'carbon' ? data.gesAgricultureImpactTotal : data.waterAgricultureImpactTotal;
		const totalImpact = indicator === 'carbon' ? data.carbonImpactTotal : data.waterImpactTotal;

		return (gesAgricultureImpact * 100) / totalImpact;
	}, [data]);

	return (
		<DashboardCard
			title={
				<CardTitle
					icon={<Icon element={Wheat} color="#95AB76" background="#F0F3EB" />}
					title={t('SBTi FLAG')}
					subtitle={t('sbti-flag-description')}
				/>
			}
			className={styles.container}
		>
			<CardContent className={cx(styles.content, 'flex1')} loading={isLoading}>
				<div className={cx('flexColumn width100 spaceBetween gap24', styles.innerContainer)}>
					<div className={cx('flexRow alignCenter nowrap width100 gap16')}>
						<CircularProgress
							width={150}
							percent={sbtiFlagPercent}
							otherColor="#EEF1E9"
							fillColor="#95AB76"
							containerWidth={'106px'}
							innerRadius={40}
							content={<div></div>}
							withBorder
						/>
						<div className="flexColumn width100 gap6">
							<LegendSBTIFlag label={t('emission-flag')} value={sbtiFlagPercent} color="#95AB76" />
							<LegendSBTIFlag label={t('emission-non-flag')} value={100 - sbtiFlagPercent} color="#EEF1E9" />
						</div>
					</div>
					<Typography className="grey800">{t('sbti-flag-content')}</Typography>
				</div>
			</CardContent>
		</DashboardCard>
	);
};

export default SbtiFlag;

type LegendSBTIFlagProps = {
	label: string;
	value: number;
	color: string;
};

export const LegendSBTIFlag = ({ label, value, color }: LegendSBTIFlagProps) => {
	return (
		<div className="flex1 flexRow spaceBetween gap24 alignCenter width100">
			<div className="flexRow alignCenter gap8">
				<div className={styles.circle} style={{ ['--background' as any]: color }} />
				<Typography className="grey900">{label}</Typography>
			</div>
			<Typography className="number grey800" variant="caption">
				{formatNumber(value, undefined, 0)}%
			</Typography>
		</div>
	);
};
