import Box from '@mui/material/Box';

import CarbonImpactBlock from './CarbonImpactBlock';
import ProductPackagingList from './ProductPackagingList';
import TopOverView from './ProductPackagingTopOverView';

export interface PackagingItem {
	weightedCarbonIntensity: string;
	name: number;
	composition: string;
	waterWeighted: string;
}

export interface ProductSingle {
	gesPackaging: number | undefined;
	gesTotal: number | undefined;
}

const ProductPackaging = () => {
	return (
		<>
			{/* <ScrollTop /> */}
			<TopOverView />
			<Box sx={{ display: 'flex', marginTop: '33px' }}>
				<Box
					sx={{
						flexGrow: 3,
						marginRight: '24px',
					}}
				>
					<ProductPackagingList />
				</Box>
				<Box
					sx={{
						//	flexGrow: 1,
						minWidth: '300px',
					}}
				>
					<CarbonImpactBlock />
				</Box>
			</Box>
		</>
	);
};

export default ProductPackaging;
