import { useQuery } from '@tanstack/react-query';

import { useYearSelection } from '../../../apps/front/src/hooks/useImpactYear';
import { getSupplierSheetTimePeriodAction } from '../actions/supplier.actions';


export const useGetSupplierSheetTimePeriod = ({
	objectId,
	fromPath,
	onError,
	onSuccess,
}: {
	objectId: string;
	fromPath?: string;
	onError?: (err: unknown) => void;
	onSuccess?: (data: any) => void;
}) => {

	const { selectedYear } = useYearSelection();

	const queryResult = useQuery({
		queryKey: ['getSupplierSheetTimePeriod', { objectId, fromPath, period: selectedYear }],
		queryFn: getSupplierSheetTimePeriodAction,
		onError,
		onSuccess,
		retry: false,
	});

	return queryResult;
};

