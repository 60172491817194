import { zodResolver } from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { AlertCircle, Moon } from 'lucide-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as LogoutIcon } from '@carbonmaps/media/icons/log-out-blue.svg';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { LogInInput, logInSchema } from '@carbonmaps/shared/validations/auth.validations';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BCheckbox from '@carbonmaps/ui/components/saas/BCheckbox';
import BInput from '@carbonmaps/ui/components/saas/BInput';

import _ from 'lodash';
import AlertStatic from '../../components/Toast/AlertStatic';
import useHasRoles from '../../hooks/useHasRoles';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery, useLogInMutation } from '../../lib/react-query/features/auth/auth.hooks';

const LogInForm = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const hasRoles = useHasRoles();
	// ---- company selected ---- //
	const queryParams = useQueryParams();

	const isLogout = Boolean(queryParams?.isLogout);

	const isFromLogin = _.isArray(queryParams?.isFromLogin)
		? queryParams?.isFromLogin[0]
		: (queryParams?.isFromLogin as any);

	const varEmail = queryParams?.email;

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// translation
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LogInInput>({
		resolver: zodResolver(logInSchema),
	});

	const {
		result: { isError, error, isLoading, mutate: logIn },
	} = useLogInMutation({
		onSuccess(data, variables, context) {
			if (hasRoles(['super_admin'])) {
				navigate(FRONT_PATH_NAMES.chooseCompany);
				return;
			}

			navigate(`${FRONT_PATH_NAMES.superAdminSettings}?isFromLogin=${!!data}`);
		},
	});

	const onSubmit: SubmitHandler<LogInInput> = async (values) => {
		logIn(values);
	};

	return (
		<Box px={0} py={2} className="flexColumn" css={{ gap: 16 }}>
			{' '}
			<Typography
				variant="inherit"
				css={{
					fontWeight: 400,
					fontSize: 24,
				}}
			>
				{t('login-form-title-long')}
			</Typography>
			<form css={{ display: 'flex', flexDirection: 'column', gap: 20, width: '100%' }}>
				{isError && (
					<AlertStatic
						text={
							(error as any)?.message === 'nocompany'
								? // eslint-disable-next-line quotes
								  "Le compte n'est associé à aucune organisation"
								: 'E-mail ou mot de passe incorrect'
						}
						// text={error?.message}
						type="error"
						icon={<AlertCircle css={{ color: '#C74600' }} />}
					/>
				)}
				{isLogout && <AlertStatic text={t('login-form-description')} type="info" icon={<LogoutIcon />} />}

				{(isFromLogin === 'true' || isFromLogin === true) && (
					<AlertStatic text={t('disabled-message-company')} type="info" icon={<Moon />} />
				)}

				{varEmail && (
					<Box sx={{ maxWidth: '400px' }}>
						<AlertStatic text={t('varEmail-exists', { varEmail })} type="info" />
					</Box>
				)}

				<BInput
					required
					id="email"
					label={t('input-mail-label')}
					fullWidth
					margin="dense"
					{...register('email')}
					name="email"
					error={errors.email ? true : false}
					errorMessage={errors?.email?.message}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSubmit(onSubmit)();
						}
					}}
				/>
				<BInput
					required
					id="password"
					label={t('input-password-label')}
					type="password"
					fullWidth
					margin="dense"
					{...register('password')}
					name="password"
					error={errors.password ? true : false}
					errorMessage={errors?.password?.message}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSubmit(onSubmit)();
						}
					}}
				/>
				<BCheckbox
					id="remember"
					label={t('remember-button')}
					// {...register('remember')}
					name="remember"
					// errorMessage={errors?.remember?.message}
					control={<Checkbox defaultChecked={true} />}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSubmit(onSubmit)();
						}
					}}
				/>
			</form>
			<Box>
				<BButton
					variant="primary"
					label={
						isLoading ? <CircularProgress size={20} css={{ color: theme.palette.common.white }} /> : t('login-button')
					}
					onClick={handleSubmit(onSubmit)}
					sx={{ minWidth: '400px' }}
				/>
			</Box>
			<Link
				// to='#'
				to="/changer-mot-de-passe"
				css={{
					textDecoration: 'none',
					color: theme.palette.primary.main,
				}}
			>
				{t('change-password-link')} ?
			</Link>
		</Box>
	);
};

export default LogInForm;
