import { css, cx } from '@emotion/css';
import Button from '@mui/material/Button';

const styles = {
	button: css({
		padding: '12px 16px !important',
		borderRadius: '8px !important',
		textTransform: 'none !important',
		lineHeight: '1.3 !important',
		minWidth: '180px !important',
		minHeight: '48px !important',
	} as any),
};

type AuditButtonProps = {
	text: React.ReactNode;
	onClick: (e?: any) => void;
	icon?: React.ReactNode;
	className?: string;
};

const AuditButton = ({ text, onClick, icon, className }: AuditButtonProps) => {
	return (
		<Button className={cx(className, styles.button, 'bgGrey400 black')} onClick={onClick}>
			{text}
			{icon}
		</Button>
	);
};

export default AuditButton;
