import { useQuery } from '@tanstack/react-query';
import { create } from 'zustand';

import { getSimulationAction } from '@carbonmaps/ui/actions/simulation.actions';

type GetScenarioType = {
	simulationId: string;
	queryName?: string;
};

/**
 *  use this hooks to retrieve simulation detail
 * @param param0
 * @returns
 */
export const useScenario = ({ simulationId }: GetScenarioType) => {
	const queryResult = useQuery({
		queryKey: ['getScenario', { simulationId }],
		queryFn: getSimulationAction,
	});

	return queryResult;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useShowDeleted = create((set: any) => {
	return {
		showDeleted: true,
		setShowDeleted: (newValue: boolean) => {
			return set((state: any) => {
				return {
					...state,
					showDeleted: newValue,
				};
			});
		},
	};
});
