import { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import { deleteDevisAction } from '@carbonmaps/ui/actions/quote.actions';
import { deleteSimulationAction } from '@carbonmaps/ui/actions/simulation.actions';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';

import { Option } from '../../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../../components/layout/list/FilterContainer';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { queryClient } from '../../../providers/QueryClientProvider';

import CreateClient from './CreateClient';
import CreateDevisCarbone from './CreateDevisCarbone';

const suppliersFolderLabel = 'Fournisseur';

type FilterProps = {
	setOpenModal: any;
	setSelectedOption: any;
	setIsNew: any;
	options: any[];
	isLoadingDataOption: boolean;
	setSearch: any;
	selectedOption: any;
};

const DevisCarboneFilter = ({
	selectedOption,
	setSearch,
	options,
	setOpenModal,
	setSelectedOption,
	setIsNew,
	isLoadingDataOption,
}: FilterProps) => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();

	const { t } = useTranslation();

	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	// ---- select rows selected in store ---- //
	const { selectedRows } = useRowTableSelection();

	const _selectedSubRows = useMemo(() => {
		return selectedRows.filter((row: any) => {
			return row.depth === 1;
		});
	}, [selectedRows]);

	const {
		mutate: deleteDevis,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteQuoteSelectedRows'],
		mutationFn: deleteDevisAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
		},
		onError: (error: any) => {
			console.log(error);
		},
	});

	const handleDeleteMany = useCallback(() => {
		const clientIds: string[] = [];
		const quoteIds: string[] = [];

		selectedRows.forEach((row: any) => {
			if (row.depth === 0) {
				clientIds.push(row.original.objectId);
				return;
			}

			if (row.depth === 1) {
				quoteIds.push(row.original.objectId);
			}
		});

		deleteDevis({ /* clientIds, */ ids: quoteIds });
	}, [deleteDevis, selectedRows]);

	return (
		<FilterContainer
			showFacetFilter={false}
			searchPlaceholder={t('search')}
			skeleton={false}
			isLoading={false}
			appliedValuesByFolders={[]}
			setAppliedValuesByFolders={() => {
				return 1;
			}}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			folders={[]}
			setSearchQueryParams={setSearchQueryParams}
			suppliersFolderLabel={suppliersFolderLabel}
			selectedRow={_selectedSubRows}
			handleExport={() => {
				return 1;
			}}
			isTableLoading={false}
			withMore={false}
			tag={'devis'}
			subMessageConfirmDelete={t('confirm-message-delete-quote')}
			count={options?.length || 0}
			rightContent={
				// <CreateDevisCarbone
				// 	setSearch={setSearch}
				// 	isLoading={isLoadingDataOption}
				// 	setOpenModal={setOpenModal}
				// 	setSelectedOption={setSelectedOption}
				// 	setIsNew={setIsNew}
				// />
				// null
				// eslint-disable-next-line react/jsx-no-undef
				<CreateClient />
			}
			moreActionExport={false}
			handleDelete={handleDeleteMany}
			loadingDelete={isLoadingDelete}
		/>
	);
};

export default DevisCarboneFilter;
