import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BPopover from '../BPopover';

import EmptyValue from './EmptyValue';

const styles = {
	popoverContainer: css({ padding: '24px' }),
	titleContainer: css({
		marginBottom: '12px',
	}),
};

const WaterPercentagePopOver = (props: any) => {
	const { value, row /* callback, rows, sortedRows  */ } = props;
	const theme = useTheme();

	return row.original.waterRaw ? (
		!isNaN(value) ? (
			<BPopover
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				modeHover
				content={(onClose) => {
					return (
						<div className={cx('flexColumn', styles.popoverContainer)}>
							<div className={styles.titleContainer}>
								<Typography className="fontWeight700">Intensité eau pondérée</Typography>
								<Typography>
									{formatNumber(row.original.waterWeightedRaw || 0, undefined, 2)} m<sup>3</sup>/kg
								</Typography>
							</div>
							<div>
								<Typography className="fontWeight700">Intensité eau</Typography>
								<Typography>
									{formatNumber(row.original.waterRaw || 0, undefined, 2)} m<sup>3</sup>/kg
								</Typography>
							</div>
						</div>
					);
				}}
			>
				<Typography>{formatNumber(value, undefined, 1)}%</Typography>
			</BPopover>
		) : (
			<EmptyValue />
		)
	) : (
		<Typography>Non pris en compte</Typography>
	);
};

export default WaterPercentagePopOver;
