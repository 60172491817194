import BButton from '@carbonmaps/ui/components/saas/BButton';
import { css, cx } from '@emotion/css';
import { CircleDashed } from 'lucide-react';
import { useTranslation } from '../hooks/useTranslation';
import { cn, siteColors } from '../lib/colors';

const styles3 = {
	text1: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',

		marginBottom: 12,
	}),
	box: css({
		padding: '32px 24px',
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
		gap: 24,
	}),
};

const GenericEmptyBlock = ({ text1, text2 }: { text1: string; text2: string }) => {
	const { t } = useTranslation();

	return (
		<div className={cx('flexCenter width100', styles3.box)}>
			<CircleDashed color={siteColors.grey700} size={40} /*  css={{ marginBottom: 20 }} */ />
			<div css={{ textAlign: 'center' }}>
				<div className={cx('', styles3.text1)}>{text1}</div>
				<div>{text2}</div>
			</div>
			<a href="mailto:support@carbonmaps.io" css={{ textDecoration: 'none' }}>
				<BButton label={t('contact-verb')} variant="secondary" />
			</a>
		</div>
	);
};

export default GenericEmptyBlock;
