import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { Link as RouterLink } from 'react-router-dom';

import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';

const styles = {
	link: css({
		textDecoration: 'none !important',
	}),
};

type ScenarioItemProps = {
	title: string;
	content: React.ReactNode;
	basePath?: any;
};

const ScenarioItem = ({ title, content, basePath }: ScenarioItemProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	const isArray = Array.isArray(content);

	const [isReadMore, setIsReadMore] = useState(true);
	const [dataContent, setDataContent] = useState<any>([]);

	const toggleReadMore = () => {
		setIsReadMore((prev) => {
			return !prev;
		});
	};

	useEffect(() => {
		if (!isArray) return;
		if (isReadMore) setDataContent((content as any)?.slice(0, 3));

		if (!isReadMore) setDataContent(content);
	}, [isReadMore, content, isArray]);

	return (
		<Box display="flex" sx={{ gap: '8px' }}>
			<Typography fontWeight={600} color={theme.palette.grey[800]}>
				{title}
			</Typography>
			{isArray ? (
				<div className="flexColumn">
					<div className="flexRow">
						{dataContent.map((item: any, index: number) => {
							return (
								<MUILink component={RouterLink} to={`${basePath}${encodeURIComponent(item)}`} className={styles.link}>
									<Typography>
										{item}
										{index !== dataContent?.length - 1 ? ',' : ''}{' '}
									</Typography>
								</MUILink>
							);
						})}
					</div>
					{content?.length > 3 && (
						<div
							style={{ color: siteColors.grey800, textDecorationColor: siteColors.grey800, cursor: 'pointer' }}
							onClick={toggleReadMore}
						>
							{t('see-button-text')} {isReadMore ? t('see-more') : t('see-less')}
						</div>
					)}
				</div>
			) : (
				<Typography>{content}</Typography>
			)}
		</Box>
	);
};

export default ScenarioItem;
