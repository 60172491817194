import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

type Props = {
	title?: string;
	description?: string;
	name?: string;
	type?: string;
	image?: string;
	url?: string;
};

const SEO = ({ title, description, name, type, image, url }: Props) => {
	return (
		<Helmet>
			{/* Standard metadata tags */}
			{title && <title>{title}</title>}
			{description && <meta name="description" content={description} />}
			{/* End standard metadata tags */}
			{/* Facebook tags */}
			{type && <meta property="og:type" content={type} />}
			{title && <meta property="og:title" content={title} />}
			{description && <meta property="og:description" content={description} />}
			{image && <meta property="og:image" content={image} />}
			{url && <meta property="og:url" content={url} />}
			{/* End Facebook tags */}
			{/* Twitter tags */}
			{name && <meta name="twitter:creator" content={name} />}
			{type && <meta name="twitter:card" content={type} />}
			{title && <meta name="twitter:title" content={title} />}
			{description && <meta name="twitter:description" content={description} />}
			{image && <meta property="twitter:image" content={image} />}
			{/* End Twitter tags */}
		</Helmet>
	);
};

// eslint-disable-next-line react-refresh/only-export-components
export default SEO;

export const DefaultSEO = () => {
	const location = useLocation();
	return (
		<SEO
			title="Carbon Maps"
			description="Plateforme de comptabilité climat dédiée à l'agroalimentaire"
			name="CarbonMaps"
			type="article"
			image={`${window.location.origin}/thumbnail.png`}
			url={window.location.origin + location.pathname}
		/>
	);
};
