import { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { type TFunction } from 'i18next';
import _ from 'lodash';
import { Check, Edit2, Trash2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import z from 'zod';

import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';

import { SupplierIcon } from '../../../components/_comp/ItemIcon';
import Table from '../../../components/_comp/Table';
import TableHeadCell from '../../../components/_comp/TableHeadCell';
import LabelLogo from '../../../components/question/LabelLogo';
import { useTranslation } from '../../../hooks/useTranslation';

import { useQuestionFormStore } from './hooks';
import { type RemoveSupplierContactById } from './QuestionFormSuppliersTableSelect';

export type SupplierContactRowData = {
	id: string;
	supplierName: string;
	supplierUid: string;

	contactFirstName?: string;
	contactLastName?: string;
	contactEmail?: string;
	contactLanguage?: string;

	isEdited?: boolean;
};

// eslint-disable-next-line react-refresh/only-export-components
export const isWhitespace = (str: string) => {
	return /^\s*$/.test(str);
};

const tableStyles = {
	main: css({
		width: '100%',
		borderCollapse: 'collapse',
		'& tr': {
			height: '60px',
			alignSelf: 'stretch',
		},
		'& tbody tr': {
			borderTop: '1px solid #E2DDEB',
		},
	}),

	hiddenVisibility: css({
		visibility: 'hidden',
	}),
};

const styles = {
	rowSubmitButton: css({
	}),

	inputDisabled: css({
		'.MuiInputBase-root': {
			borderRadius: '10px!important',
			overflow: 'hidden',
		},
		'& .MuiInputBase-input': {
			padding: '10.5px 10px',
			background: '#F4F2F7!important',
		},
		fieldset: {
			borderRadius: '8px',
			border: '1px solid #F4F2F7!important',
		},
		'& .MuiInputBase-input.Mui-disabled': {
			WebkitTextFillColor: '#474154',
		},
	}),

	rowFromContainer: css({
		gap: 8,
	}),
	select: css({
		fieldset: {
			border: '1px solid #9587AE',
			borderRadius: '8px!important',
			// background: 'red',
		},
		'.MuiOutlinedInput-root': {
			padding: '3px 22px 3px 12px !important',
		},
	}),
	selectDisabled: css({
		pointerEvents: 'none',
		'.MuiAutocomplete-inputRoot': {
			// background: 'red!important',
			background: '#F4F2F7!important',
			borderRadius: '8px!important',
		},
		fieldset: {
			border: '1px solid #F4F2F7',
			borderRadius: '8px!important',
			// opacity: 0.5,
		},
		'.MuiOutlinedInput-root': {
			padding: '3px 22px 3px 12px !important',
			// background: 'red!important',
		},
	}),
};

const contactSchema = z.object({
	contactFirstName: z.string().min(1),
	contactLastName: z.string().min(1),
	contactEmail: z.string().email(),
	contactLanguage: z.string().min(1),
});

const columnHelper = createColumnHelper<SupplierContactRowData>();

export type UpdateSupplierDataById = (
	supplierUid: string,
	data: Omit<SupplierContactRowData, 'supplierName' | 'supplierUid' | 'id'>,
) => void;

type GetColumnsProps = {
	updateSupplierDataById: UpdateSupplierDataById;
	removeSupplerContactById: RemoveSupplierContactById;
	t: TFunction;
};

// eslint-disable-next-line react-refresh/only-export-components
export const getContactStringValue = (row: SupplierContactRowData) => {
	return `${row.contactFirstName || ''} ${row.contactLastName || ''} ${row.contactEmail || ''}`;
};

const getColumns = ({ updateSupplierDataById, removeSupplerContactById, t }: GetColumnsProps) => {
	return [
		columnHelper.accessor('supplierName', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('supplier-label')} valueType="string" />;
			},
			cell: (info) => {
				return (
					<LabelLogo
						label={info.row.original.supplierName}
						uid={info.row.original.supplierUid}
						icon={<SupplierIcon />}
					/>
				);
			},
		}),
		columnHelper.accessor(
			(row) => {
				return getContactStringValue(row);
			},
			{
				id: 'contactInfos',
				cell: (info) => {
					// eslint-disable-next-line react-hooks/rules-of-hooks
					const [editMode, setEditMode] = useState(() => {
						return Boolean(info.row.original.isEdited);
					});
					const value = info.getValue();

					// eslint-disable-next-line react-hooks/rules-of-hooks
					const form = useForm({
						resolver: zodResolver(contactSchema),
						defaultValues: {
							..._.pick(info.row.original, ['contactFirstName', 'contactLastName', 'contactEmail', 'contactLanguage']),
							contactLanguage: info.row.original.contactLanguage || 'en',
						},
					});

					const onSubmit = form.handleSubmit(
						async (values) => {
							//console.log('success', values);
							updateSupplierDataById(info.row.original.id, { ...values, isEdited: false });
						},
						async (values) => {
							console.log('error', values);
						},
					);

					if (isWhitespace(value) && !editMode) {
						return (
							<div
								className={cx('pointer')}
								onClick={() => {
									setEditMode(true);
								}}
							>
								+ {t('Ajouter les informations du contact')}
							</div>
						);
					}

					return (
						<div className={cx('flexRow alignCenter', styles.rowFromContainer)}>
							<BInput
								disabled={!editMode}
								placeholder={t('first-name-label')}
								{...form.register('contactFirstName')}
								className={cx('', !editMode ? styles.inputDisabled : '')}
							/>
							<BInput
								disabled={!editMode}
								placeholder={t('last-name-label')}
								{...form.register('contactLastName')}
								className={cx('', !editMode ? styles.inputDisabled : '')}
							/>
							<BInput
								disabled={!editMode}
								placeholder={`${t('input-email-label')} (${t('obligatoire')})`}
								{...form.register('contactEmail')}
								className={cx('', !editMode ? styles.inputDisabled : '')}
							/>

							<Autocomplete
								className={cx('', editMode ? styles.select : styles.selectDisabled)}
								options={['en', 'fr', 'es', 'it']}
								// getOptionLabel={(key) => `${key.substring(0, 2)}-${key.substring(2, 4)}`}
								{...form.register('contactLanguage')}
								defaultValue={form.control._defaultValues.contactLanguage || 'en'}
								disableClearable
								onChange={(event: any, newValue: string | null) => {
									// setLocale(newValue as SupportedLocales);
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									form.setValue('contactLanguage', newValue!);
								}}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											fullWidth
										/>
									);
								}}
							/>

							{editMode ? (
								<BButton
									label={<Check /* size={18} */ />}
									variant={'primary'}
									onClick={onSubmit}
									className={cx('', styles.rowSubmitButton)}
								/>
							) : null}
						</div>
					);
				},
				header: (info) => {
					return <TableHeadCell info={info} label="Contact" valueType="string" />;
				},
			},
		),
		columnHelper.display({
			id: 'actions',
			cell: (info) => {
				const isEdited = info.row.original.isEdited;
				return (
					<div className={cx('flexRow')}>
						<div
							className={cx(
								'',
								isEdited || isWhitespace(getContactStringValue(info.row.original)) ? tableStyles.hiddenVisibility : '',
							)}
						>
							<IconButton
								onClick={() => {
									updateSupplierDataById(info.row.original.id, {
										isEdited: true,
									});
								}}
							>
								<Edit2 size={20} color="#9587AE" />
							</IconButton>
						</div>
						<IconButton
							onClick={() => {
								removeSupplerContactById(info.row.original.id);
							}}
						>
							<Trash2 size={20} color="#9587AE" />
						</IconButton>
					</div>
				);
			},
		}),
	];
};

const QuestionFormSuppliersTable = () => {
	const { updateSupplierContactDataById, removeSupplierContactById, supplierContacts: data } = useQuestionFormStore();
	const { t } = useTranslation();

	const columns = useMemo(() => {
		return getColumns({
			updateSupplierDataById: updateSupplierContactDataById,
			removeSupplerContactById: removeSupplierContactById,
			t: t as never,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removeSupplierContactById, updateSupplierContactDataById, t, data]); // data in the array deps is important

	return data.length > 0 ? (
		<Table<SupplierContactRowData>
			columns={columns}
			data={data}
			tableProps={{
				className: tableStyles.main,
			}}
		/>
	) : null;
};

export default QuestionFormSuppliersTable;
