import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import { createSearchParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, TM_COMPO_AGRIBALYSE, TM_COMPO_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import { TableComponent, getStickyHeaderStyle } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useGlossary } from '../../../hooks/useGlossary';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { useFindModelizationTable } from '../../../lib/react-query/features/modelization/modelization.hooks';

const stickyHeaderStyle = getStickyHeaderStyle(/* 1499 */);

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:(:nth-of-type(2)) > div,.bordered tr td:not(:nth-of-type(2)) div ,.bordered tr td:not(:nth-of-type(2)) button':
			{
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
				textAlign: 'right!important',
			},
		'.bordered tr th:nth-of-type(2) > div,.bordered tr td:nth-child(2) div': {
			justifyContent: 'flex-start!important',
			paddingLeft: '0!important',
			textAlign: 'left!important',
		},
		'.bordered tr th:nth-of-type(3) > div,.bordered tr td:nth-child(3) div': {
			justifyContent: 'flex-end!important',
			//paddingRight: '0!important',
			textAlign: 'right!important',
		},
		'.bordered tr th:nth-of-type(3)': {
			width: '200px',
			height: '60px', // to fix the height of the first row in table
		},
	};
};

const formatThenSetData = (res: any, paramsTable: any, t: any) => {
	if (!res?.length) return [];
	let results = res.map((item: any) => {
		const detailLink = `${FRONT_PATH_NAMES.audit}/modelization/${item.id}`;
		console.log(' item?.typeModelization item?.typeModelization', item?.typeModelization);

		return {
			...item,
			linkModelization: detailLink,
			color: TM_COMPO_INGREDIENT.get(item?.typeModelization?.toLowerCase())?.color,
			borderColor: TM_COMPO_INGREDIENT.get(item?.typeModelization?.toLowerCase())?.borderColor,
			backgroundColor: TM_COMPO_INGREDIENT.get(item?.typeModelization?.toLowerCase())?.backgroundColor,
			typeModelization: item?.typeModelization ? t(`tm_${item.typeModelization?.toLowerCase()}`) : null,
			// link: item?.typeModelization?.toLowerCase() === TM_COMPO_AGRIBALYSE ? null : detailLink,
			link: detailLink,
			keyGlossary: TM_COMPO_INGREDIENT.get(item?.typeModelization?.toLowerCase())?.keyGlossary,
		};
	});
	console.log('results', results);

	if (paramsTable?.input) {
		results = results.sort((a: any, b: any) => {
			// Vérifie si 'nom' est présent dans a et b
			const aContainsNom =
				a.label.startsWith(paramsTable?.input[0].toLowerCase()) ||
				a.label.startsWith(paramsTable.input[0].toUpperCase());
			const bContainsNom =
				b.label.startsWith(paramsTable?.input[0].toLowerCase()) ||
				b.label.startsWith(paramsTable.input[0].toUpperCase());

			// Si a contient 'nom' mais b ne le contient pas, a vient avant b
			if (aContainsNom && !bContainsNom) {
				return -1;
			}
			// Si b contient 'nom' mais a ne le contient pas, b vient avant a
			else if (!aContainsNom && bContainsNom) {
				return 1;
			}
			// Si les deux contiennent ou ne contiennent pas 'nom', l'ordre reste inchangé
			else {
				return 0;
			}
		});
	}

	return results;
};

type ModelizationTableProps = {
	filterOptions?: any;
	manualSortBy?: boolean;
	resetPage?: number;
	// use to update row selected in export data
};

const ModelizationTable = ({ filterOptions, manualSortBy }: ModelizationTableProps) => {
	const analytics = useJuneTrack();
	// ---- hooks ---- //
	// get current switch mode value ( carbon or water )
	const { indicator } = useApp();
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// translation

	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();
	// query params
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	//---- track event page ---- //
	const trackEvent = useCallback(
		(evenName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(evenName, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- track next page ---- //
	const trackNextPage = useCallback(() => {
		trackEvent('Explore Products Next Page');
	}, [trackEvent]);

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('product')} />;
				},
				accessor: 'label', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'FinalProduct',
				type: 'string',
				props: {
					page: 'view-modelization',
					onClick: (p: any) => {
						trackEvent('Audit Land Modelization', {
							ProductLabel: p?.label,
							uidProduct: p?.uid,
						});
					},
				},
				disableSortBy: filterOptions?.facetFilters?.length || filterOptions?.input?.length,
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('type-de-modelisation')} />;
				},

				accessor: 'typeModelization',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'ListCell',
				props: {
					page: 'view-modelization',
					onClick: (p: any) => {
						trackEvent('Audit Land Modelization', {
							ProductLabel: p?.label,
							uidProduct: p?.uid,
						});
					},
				},
				disableSortBy: filterOptions?.facetFilters?.length || filterOptions?.input?.length,
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							sx={{
								'& .table-header-component-label': { whiteSpace: 'nowrap' },
							}}
							column={props.column}
							// css={{ '& .table-header-component-label': { whiteSpace: 'nowrap' } }}
							label={
								// <LightTooltipGlossary title={g('vs-agribalyse')}>
								<span
								// className={'underline'}
								>
									{t('vs.Externe')}
								</span>
								// </LightTooltipGlossary>
							}
						/>
					);
				},
				accessor: 'VSExternePercent', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'AcvDiff',
				type: 'number',
				props: {
					t,
					vs: TM_COMPO_AGRIBALYSE,
					page: 'view-modelization',
					onClick: (p: any) => {
						trackEvent('Audit Land Modelization', {
							ProductLabel: p?.label,
							uidProduct: p?.uid,
						});
					},
				},
				disableSortBy: filterOptions?.facetFilters?.length || filterOptions?.input?.length,
			},
		];
	}, [t, filterOptions?.facetFilters?.length, trackEvent, g, indicator]);

	// -------------------------------------------------------------------------------------- //
	// --------------------------------- State definition ----------------------------------- //
	// -------------------------------------------------------------------------------------- //

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // Suppliers filter
			direction: 0,
			...(filterOptions?.orderBy && {
				sortingBy: {
					id: filterOptions?.orderBy,
					desc: filterOptions?.direction === 'desc',
				},
			}),
			...filterOptions,
		},
	);

	// ---- fetch data --- //
	const {
		key,
		result: { data: tableResultData, isLoading: isTableLoading },
	} = useFindModelizationTable({
		params: {
			...paramsTable,
			viewMode: indicator,
			indicator,
		},
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions]);

	let paramsUrlString = '';
	if (searchParams) paramsUrlString = `?${searchParams}`;

	return (
		<>
			<TableComponent
				skeleton={isTableLoading}
				loading={false}
				fetchData={updateTableParams}
				pageCount={tableResultData?.meta?.last_page || 0}
				noDataMessage={t('product-table-empty-data', {
					product: toLowerCase(t('product')),
					of_product: t('of_product'),
				})}
				pageSize={paramsTable.size}
				columns={tableColumns}
				resetPage={resetPage}
				data={formatThenSetData(tableResultData?.data || [], paramsTable, t)}
				onSortingColumn={handleSortingTable}
				addStyles={stylesTable}
				manualSortBy={manualSortBy}
				stickyHeader
				stickyHeaderStyles={stickyHeaderStyle}
				transFunc={t}
				onNextClick={trackNextPage}
				isSelectable={false}
			/>
		</>
	);
};

export default ModelizationTable;
