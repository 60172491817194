import { useEffect } from 'react';

import { css, cx } from '@emotion/css';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useGetInfoGeneral } from '@carbonmaps/ui/hooks/useGetInfoGeneral';

import SEO from '../../components/SEO';
import { useTranslation } from '../../hooks/useTranslation';

import LoadingData from './LoadingData';
import NavBar from './NavBar';
import DashboardTab from './v2/DashboardTabs';
import GlobalImpact from './v2/GlobalImpact';
import Welcome from './v2/Welcome';

const styles = {
	container: css({
		backgroundPositionX: 'center',
		backgroundImage: 'url(/dashboard-bg.svg)',
		minHeight: '100vh',
		width: 'calc(100% - 184px)',
		backgroundRepeat: 'no-repeat',
		margin: '0 auto',
		paddingTop: 52,
		paddingBottom: 62,
		//backgroundSize: '90%',
	}),
};

const Dashboard = () => {
	const { data, isLoading } = useGetInfoGeneral();
	const { t } = useTranslation();

	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([{ text: t('dashboard-title') }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	return (
		<>
			<SEO title={`${t('dashboard-title')} - Carbon Maps`} />
			{!isLoading && !data?.product ? (
				<LoadingData />
			) : (
				<div className={cx('flexColumn gap32', styles.container)}>
					<Welcome />
					<GlobalImpact />
					<DashboardTab />
				</div>
			)}
		</>
	);
};

export default Dashboard;
