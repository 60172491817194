import { css, cx } from '@emotion/css';

type ContentGridProps = {
	children: React.ReactNode;
	column?: number;
	gap?: number;
};

const styles = {
	container: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		gridColumnGap: 24,
		gridRowGap: 24,
	}),
};

const ContentGrid = ({ children, column = 3, gap = 24 }: ContentGridProps) => {
	return <div className={cx('width100', styles.container)}>{children}</div>;
};

export default ContentGrid;
