import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getProductInAllVersionAction } from '@carbonmaps/ui/actions/product.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Skeleton from '../../components/Skeleton';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';

const styles = {
	container: css({
		width: '90%',
		margin: 'auto',
		paddingTop: 50,
	}),
	skeletonContainer: css({
		width: 300,
		height: 50,
	}),
};

const ProductNotFound = () => {
	const params = useParams();
	const { setBreadcrumbs } = useApp();

	const { t } = useTranslation();

	//---- check if previous version ---- //
	const { data, isLoading } = useQuery({
		queryKey: ['getProductInAllVersion', { productId: params.uid }],
		queryFn: getProductInAllVersionAction,
	} as any);

	// ---- breadcrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('products') }]);
	}, [t]);

	return (
		<div className={styles.container}>
			{isLoading && <Skeleton className={styles.skeletonContainer}></Skeleton>}
			{!isLoading && (
				<Typography color={siteColors.grey700} fontWeight={600}>
					l’{data && (data as any)?.id ? (data as any)?.get('uid') : params.uid} du produit n’existe plus ou n’a pas été
					trouvé
				</Typography>
			)}
		</div>
	);
};

export default ProductNotFound;
