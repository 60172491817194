import { useEffect, useState } from 'react';

import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Undo2 } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { ITEM_TYPE_INGREDIENT, ITEM_TYPE_PRODUCT } from '@carbonmaps/shared/utils/constants';
import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getCategoriesProduct } from '@carbonmaps/ui/actions/product.actions';
import { getCompanySuppliers } from '@carbonmaps/ui/actions/supplier.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import ActionsBar from '../../../components/ActionsBar';
import Filter, { IFilterFolder } from '../../../components/Filter';
import SearchAutocomplete from '../../../components/SearchAutocompleteV2';
import { Option } from '../../../components/form/fields/MultiSelectCheckboxField';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { getLabelNodeFacet } from '../../../utils/utils';

const suppliersFolderLabel = 'Fournisseur';

const PackagingUsesFilter = ({ packagingId }: any) => {
	const theme = useTheme();
	const params = useParams();

	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	//--------------------------------------------------------------------------------------//
	//                                        State                                         //
	//--------------------------------------------------------------------------------------//
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();

	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);

	const [isClearInputValue, setIsClearInputValue] = useState(false);
	const { selectedYear } = useYearSelection();

	const searchParams = searchQueryParams?.facetFilters ?? [];

	const {
		data: facets,
		isSuccess: isSuccessFetchFacets,
		isLoading: isFetchFacetsLoading,
	} = useQuery({
		queryKey: [
			'getCategory',
			{
				input: searchQueryParams?.input || '',
				categoryFilters: searchQueryParams?.categoryFilters || null,
				facetFilters: searchQueryParams?.facetFilters || [],
				packagingId,
				period: selectedYear,
			} as any,
		],
		queryFn: getCategoriesProduct,
	});

	const { data: suppliersData, isLoading: isSuppliersLoading } = useQuery({
		queryKey: ['getAllSuppliersForCompany', { itemType: ITEM_TYPE_INGREDIENT, isFilter: true }],
		queryFn: getCompanySuppliers,
	});

	useEffect(() => {
		if (!searchQueryParams) return;
		setSearchQueryParams({
			...searchQueryParams,
			packagingId: params.packagingId,
		});
	}, [searchQueryParams, params.packagingId]);

	useEffect(() => {
		if (!suppliersData) return;
		setFilterFolders((folders) => {
			return folders;
		});
	}, [suppliersData]);

	useEffect(() => {
		// don't change current filter
		if (!facets) return;
		const facetFilterFolders: IFilterFolder[] = Object.entries(facets.facet ?? {}).map(([key, value]: [any, any]) => {
			const { index, label, type, global, itemType /* , width, */ } =
				getFacetConfig(key, authData?.session.company) ?? {};
			const facetLabel = label || getFacetLabel(key, authData?.session.company);

			const options: Option[] = value.buckets.map((bucket: any) => {
				return {
					value: bucket._id,
					label: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
						? getLabelValue(key, String(bucket._id))
						: String(bucket._id),
					// label: String(bucket._id),
					labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
						? getLabelNodeFacet(key, bucket._id, t)
						: undefined,
					count: bucket.count,
					folderLabel: facetLabel,
					isFacetBucket: true,
					facetPath: key,
					type: type ?? 'hardcoded-undefined-facet-type',
					global,
					itemType,
				};
			});

			return {
				label: facetLabel,
				options,
				isFacet: true,
				path: key,
				showCount: false,
			};
		});

		setFilterFolders((folders) => {
			const notFacetFolders = folders.filter((e) => {
				return !e.isFacet;
			});

			return [...notFacetFolders, ...facetFilterFolders];
		});
	}, [isSuccessFetchFacets, facets]);

	useEffect(() => {
		//	if there are filters in the url
		if (searchParams && searchParams.length) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			searchParams.forEach((item: any) => {
				const config = getFacetConfig(item.path, authData?.session.company);
				let arrayValues = item.values.map((v: any) => {
					if (item.itemType === ITEM_TYPE_PRODUCT) {
						const value = {
							facetPath: item.path,
							value: v,
							folderLabel: config?.label ?? item.path,
							label: getLabelValue(item.path, v),
							isFacetBucket: true,
							type: typeof v,
							global: item.global === true ? true : false,
							itemType: item.itemType,
						};

						currentSelectedValues.push(value);
						return value;
					}

					return null;
				});
				arrayValues = _.compact(arrayValues);

				if (arrayValues.length) {
					currentAppliedValuesFolders.push({
						isFacet: true,
						label: config?.label ?? item.path,
						options: arrayValues,
					});
				}
			});
			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchParams]);

	// ---- define loading skeleton page ---- //
	const { skeleton } = useSkeleton({
		condition: isFetchFacetsLoading || isSuppliersLoading,
	});

	return (
		<ActionsBar
			isSticky={false}
			onLeft={
				<div className="flexRow alignCenter" css={{ gap: '1rem' }} /* bgcolor="red" */>
					<SearchAutocomplete
						skeleton={skeleton}
						isLoading={false}
						size={20}
						setParamsAutocomplete={setSearchQueryParams && _.debounce(setSearchQueryParams, 500)}
						placeholder={t('search_for_product_placeholder', {
							product: toLowerCase(t('product')),

							of_product: t('of_product'),
						})}
						options={[]}
						isClearInputValue={isClearInputValue}
						setIsClearInputValue={setIsClearInputValue}
					/>
					<Filter
						// skeleton={isSearchLoading}
						// isLoading={isSearchLoading || isDataTradeOffLoading || isFetchFacetsLoading || isSUppliersLoading}
						skeleton={skeleton}
						isLoading={false}
						appliedValuesByFolders={appliedValuesByFolders}
						setAppliedValuesByFolders={setAppliedValuesByFolders}
						selectedValues={selectedValues}
						setSelectedValues={setSelectedValues}
						folders={filterFolders?.map((f: any) => {
							return { ...f, showCount: true };
						})}
						onTriggerFilter={({ allFoldersSelectedValues }) => {
							const facetBuckets = allFoldersSelectedValues.filter((e) => {
								return e.isFacetBucket;
							});
							// * for supplier filters
							const supplierIds = allFoldersSelectedValues
								.filter((e) => {
									return !e.isFacetBucket && e.folderLabel === suppliersFolderLabel;
								})
								.map((e) => {
									return e.value;
								}) as string[];

							setSearchQueryParams((oldValue: any) => {
								const newFacetFiltersMap = new Map<string, /* IFacetFilter */ any>();
								facetBuckets.forEach((e) => {
									if (!e.isFacetBucket) return;
									const foundFacetFilter = newFacetFiltersMap.get(e.facetPath);

									if (!foundFacetFilter) {
										newFacetFiltersMap.set(e.facetPath, {
											path: e.facetPath,
											type: e.type,
											values: [e.value],
											global: e.global,
											itemType: e.itemType,
										});
										return;
									}

									foundFacetFilter.values.push(e.value);
								});
								return {
									...oldValue,
									supplierIds,
									facetFilters: [...newFacetFiltersMap.values()],
								};
							});
						}}
					/>
				</div>
			}
			onRight={
				<>
					{((appliedValuesByFolders.length > 0 && selectedValues.length > 0) || searchQueryParams.input !== '') && (
						<BButton
							onClick={() => {
								setSelectedValues([]);
								setAppliedValuesByFolders([]);
								setSearchQueryParams({
									...searchQueryParams,
									supplierIds: [],
									facetFilters: [],
									input: '',
								});
								setIsClearInputValue(true);
							}}
							iconLeft={<Undo2 color={theme.palette.jobs.welfare.main} />}
							label={t('reset-filter-button')}
							variant="tertiary"
							addStyles={{
								color: theme.palette.jobs.welfare.main,
								background: 'none',
								fontSize: '14px',
								fontWeight: 600,
							}}
						/>
					)}
				</>
			}
		/>
	);
};

export default PackagingUsesFilter;
