import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BlueCheck from '../../../../../components/BlueCheck';
import { useYearSelection } from '../../../../../hooks/useImpactYear';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';
import ProductModel from '../../../../../models/Product.model';

const TooltipProductChart = ({ item, indicator, viewMode }: any) => {
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	if (!item) return null;

	// console.log('********', t('Quantité vendue'), 'xx', t(indicator?.popover[0].label, { period: selectedYear }));

	return (
		<Box
			className="flexColumn"
			sx={{
				borderRadius: '8px',
				border: cn('1px solid', siteColors.grey500),
				background: siteColors.common.white,
				boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
				padding: '24px',
				gap: '24px',
				minWidth: '343px',
			}}
		>
			<Box>
				<Typography fontSize="14px" lineHeight={'16.8px'} color={siteColors.text}>
					{t(item?.category)}
				</Typography>
				<BlueCheck
					title={item?.categoryValue}
					fontSize={14}
					iconSize={16}
					withIcon={new ProductModel(item).isN2ModelizationType()}
				/>
			</Box>
			<Box sx={{ width: '100%' }}>
				<Box
					className="flexRow spaceBetween nowrap"
					sx={{ width: '100%', paddingBottom: '8px', gap: '24px', borderBottom: cn('1px solid', siteColors.grey500) }}
				>
					<Typography fontSize="14px" lineHeight={'16.8px'} color={siteColors.grey900}>
						{indicator?.popover[0].key === 'volume'
							? t('Quantité vendue')
							: t(indicator?.popover[0].label, { period: selectedYear })}
					</Typography>
					<Typography fontWeight={600} lineHeight={'16.8px'} color={siteColors.text} className="number">
						{formatNumber(
							item[indicator?.popover[0].key] || item[viewMode] || '-',
							undefined,
							indicator?.popover[0].precision,
						)}{' '}
						{indicator?.popover[0].key === 'volume' ? t('units') : indicator?.popover[0].unit}
					</Typography>
				</Box>

				<Box className="flexRow spaceBetween nowrap" sx={{ width: '100%', paddingTop: '8px' }}>
					<Typography fontSize="14px" lineHeight={'16.8px'} color={siteColors.grey900}>
						{indicator?.popover[1].key === 'volume'
							? t('Quantité vendue')
							: t(indicator?.popover[1].label, { period: selectedYear })}
					</Typography>
					<Typography fontWeight={600} lineHeight={'16.8px'} color={siteColors.text} className="number">
						{formatNumber(item[indicator?.popover[1].key] || '-', undefined, indicator?.popover[1].precision)}{' '}
						{indicator?.popover[1].key === 'volume' ? t('units') : indicator?.popover[1].unit}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default TooltipProductChart;
