import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { css, cx } from '@emotion/css';

import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import _ from 'lodash';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import Typography from '../Typography';

const styles = {
	circle: css({
		width: 8,
		height: 8,
		borderRadius: '50%',
		border: '1px solid transparent',
	}),

	content: css({
		width: 110,
		textDecoration: 'none',
	}),

	primary: css({
		background: siteColors.primary,
	}),
	primaryText: css({
		color: cn(siteColors.primary, '#!important'),
	}),
	secondary: css({
		borderColor: siteColors.grey800,
	}),
	popover: css({
		textTransform: 'none !important',
		padding: '0!important',
	} as any),

	popoverContent: css({
		padding: 24,
		minWidth: 150,

		'& p': {
			color: siteColors.text,
			fontSize: 14,
			fontFamily: 'Inter',
			lineHeight: '25px',
			margin: 0,
		},
	}),
} as any;

type ExternalItemProps = {
	label?: any;
	value?: string;
	variant?: string;
	popoverContent?: React.ReactNode;
};

const ExternalItem = ({ label, value, popoverContent, variant = 'primary' }: ExternalItemProps) => {
	const { t } = useTranslation();

	const params = useParams();
	const productId = _.toString(params.productId);

	return (
		<div className="width100 flexRow spaceBetween gap16 alignCenter">
			<Typography className={cx('flex1 grey800 fontWeight600 lineHeight1')}>{t(label)}</Typography>

			<BPopover
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				modeHover
				content={(onClose) => {
					return <>{popoverContent}</>;
				}}
				className={styles.popover}
			>
				{' '}
				<Link
					to={FRONT_PATH_NAMES.audit + '/modelization/' + productId}
					className={cx('flexRow gap8 nowrap alignCenter', styles.content)}
				>
					<div className={cx(styles.circle, styles[variant])} />
					<Typography className={cx('lineHeight1', styles[`${variant}Text`])}>{value}</Typography>
				</Link>
			</BPopover>
		</div>
	);
};

export default ExternalItem;
