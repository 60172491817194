import { useLayoutEffect, useState } from 'react';

import useResizeObserver from '@react-hook/resize-observer';

export const useSize = (target: any) => {
	const [size, setSize] = useState();

	useLayoutEffect(() => {
		setSize(target.current.getBoundingClientRect());
	}, [target]);

	// Where the magic happens
	useResizeObserver(target, (entry: any) => {
		return setSize(entry.contentRect);
	});
	return size;
};
