import { useEffect } from 'react';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useTranslation } from '../../../hooks/useTranslation';

import TranslationsHeader from './TranslationsHeader';
import TranslationsTable from './TranslationsTable';

const Translations = () => {
	const { t } = useTranslation();
	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([
			{ text: t('company-breadcrumbs'), link: `${FRONT_PATH_NAMES.superAdminSettings}/companies` },
			{ text: t('translations') },
		]);
	}, [setBreadcrumbs, t]);

	return (
		<Container header={<TranslationsHeader />} seoText={`${t('Translation')} - Carbon Maps`}>
			<TranslationsTable />
		</Container>
	);
};

export default Translations;
