import { css, cx } from '@emotion/css';

import Typography from '../../components/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

const styles = {
	container: css({
		paddingTop: 72,
		paddingLeft: 72,
	}),
	title: css({
		fontSize: '24px !important',
	}),
};

const LoadingData = () => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { t } = useTranslation();

	return (
		<div className={cx('flexColumn', styles.container)}>
			<Typography className={cx('fontWeight600', styles.title)}>
				{t('welcome')} {authData?.user.firstName}
			</Typography>
			<Typography>{t('empty-product-message')}</Typography>
		</div>
	);
};

export default LoadingData;
