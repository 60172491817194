import { useEffect } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';

import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import { useLogOutMutation } from '../../lib/react-query/features/auth/auth.hooks';

const ChangeEmailValid = () => {
	const {
		result: { mutate: logOut },
	} = useLogOutMutation();
	const navigate = useNavigate();

	const { t } = useTranslation();

	useEffect(() => {
		logOut();
	}, [logOut]);

	return (
		<Box px={0} py={2} maxWidth={'386px'}>
			<div
				className="flexRow alignCenter spaceBetween"
				css={{
					background: '#F5F9EC',
					border: cn('1px solid', siteColors.green500),
					boxShadow: cn('inset 4px 0px 0px', siteColors.green500),
					borderRadius: '0px 8px 8px 0px',
					maxWidth: 560,
					padding: 25,
					marginBottom: 25,
				}}
			>
				<div className="flexRow alignCenter" css={{ gap: 25 }}>
					<CheckIcon />
					<span
						css={{
							fontWeight: 500,
							fontSize: 16,
						}}
					>
						{t('user-mail-verify-title')}
					</span>
				</div>
			</div>

			<BButton
				variant="primary"
				label={t('user-mail-verify-back-button')}
				onClick={() => {
					navigate(FRONT_PATH_NAMES.logIn);
				}}
			/>
		</Box>
	);
};

export default ChangeEmailValid;
