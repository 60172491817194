import { Typography } from '@mui/material';
import dayjs from 'dayjs';

type DateFieldProps = {
	value: any;
	row: any;
	column: any;
};

const DateField = ({ value, row, column }: DateFieldProps) => {
	const date = value ? `${dayjs(value).format('D MMMM YYYY')} à  ${dayjs(value).format('HH:mm')}` : '';
	return <Typography>{date}</Typography>;
};

export default DateField;
