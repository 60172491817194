import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { jobQueueStatus } from '@carbonmaps/shared/utils/constants';
import { applyVersionAction, deleteVersionAction, getDataVersionAction } from '@carbonmaps/ui/actions/version.actions';
import SwitchCell from '@carbonmaps/ui/components/saas/Table/SwitchCell';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';

import TableHeaderCell from '../../../components/table/TableHeaderCell';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

const styles = {
	container: css({
		paddingTop: 16,
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
			padding: '5px!important',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

const VersionTable = () => {
	// ---- theme ---- //
	const theme = useTheme();
	// ---- translation ---- //
	const { t } = useTranslation();
	// ---- styles ---- //
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);
	// ---- fetch data ref ---- //
	const fetchIdRef = useRef(0);
	// ---- company selected ---- //
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';
	const navigate = useNavigate();

	// query client
	const queryClient = useQueryClient();
	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState({
		input: '',
		page: 1,
		size: 100,
		companyId,
	});

	// ---- apply version ---- //
	const { mutate: applyVersion } = useMutation({
		mutationKey: ['applyVersion'],
		mutationFn: applyVersionAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getVersionData'] });
		},
		onError: (error: any) => {
			console.log('error', error);
		},
	});

	// ---- action when switch is visible in front  ---- //
	// const handleSwitchChange = async (row: any, status: boolean) => {
	// 	await applyVersion({ updateId: row?.original?.objectId, status, company: row?.original?.company?.id });
	// };

	// ---- action when switch is visible in front  ---- //
	const handleSwitchChange = useCallback(
		async (row: any, status: boolean) => {
			await applyVersion({ updateId: row?.original?.objectId, status, company: row?.original?.company?.id });
		},
		[applyVersion],
	);

	// ---- delete vesion ---- //
	const {
		mutate: deleteVersion,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteVersion'],
		mutationFn: deleteVersionAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getVersionData'] });
		},
		onError: (error: any) => {
			console.log('error', error);
		},
	});

	// ---- fetch data version ---- //
	const {
		data,
		isLoading,
		refetch: fetchVersionData,
	} = useQuery({
		queryKey: [
			'getVersionData',
			{
				...paramsTable,
			},
		],
		queryFn: getDataVersionAction,
	} as any);

	// ---- columns ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label="User" />;
				},
				accessor: 'user',
				editable: true,
				sortDescFirst: true,
				component: 'UserField',
				type: 'string',
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('import-date-label')} />;
				},
				accessor: 'createdAt',
				editable: true,
				sortDescFirst: true,
				component: 'DateField',
				type: 'string',
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell label={t('active')} />;
				},
				accessor: 'isActive', // accessor is the "key" in the data
				editable: true,
				//sortDescFirst: true,
				disableSortBy: true,
				component: 'SimpleCell',
				type: 'booleanStatus',

				Cell: ({ row }: any) => {
					return (
						<SwitchCell
							row={row}
							value={row?.original?.isActive}
							onDataUpdate={handleSwitchChange}
							withConfirm
							disabled={_.get(data, 'isRunning') ?? true}
						/>
					);
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('status')} />;
				},
				accessor: 'status',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
				props: {
					style: {
						textTransform: 'uppercase',
						uppercase: 'none',
						borderRadius: '16px !important',
						border: '1px solid rgba(0, 0, 0, 0.10)!important',
						color: siteColors.grey900,
						fontSize: '12px !important',
						padding: '4px 4px !important',
						textAlign: 'center',
					},
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('log')} />;
				},
				accessor: 'log',
				editable: true,
				sortDescFirst: true,
				component: 'SimpleCell',
				type: 'string',
				props: {
					style: {
						fontSize: '14px',
						width: '600px',
						maxHeight: '100px',
						overflowY: 'scroll',
						padding: '10px',

						wordWrap: 'break-word',
						overflowWrap: 'break-word',
						wordBreak: 'break-word',
					},
				},
				updateMyData: (index: any, id: any, value: any) => {
					console.log('no');
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label="" />;
				},

				accessor: 'objectId',
				editable: true,
				disableSortBy: true,
				component: 'DeleteVersionField',
				isDisabled: _.get(data, 'isRunning') ?? false,
				type: 'string',
				callback: (updateId: string) => {
					deleteVersion({ updateId });
				},
			},
		];
	}, [data, deleteVersion, handleSwitchChange, t]);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	useEffect(() => {
		const interval = setInterval(() => {
			const func = async () => {
				console.log('========== fetch data version ============');
				fetchVersionData?.();
			};

			func();
		}, 4 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [fetchVersionData]);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
			});
		},
		[updateTableParams],
	);

	return (
		<div className={cx('flexColumn', styles.container)}>
			<TableComponent
				tableName={t('version-title')}
				skeleton={isLoading}
				loading={false}
				fetchData={updateTableParams}
				pageCount={(data as any)?.meta?.last_page || 0}
				noDataMessage={t('empty-data-table-version')}
				pageSize={paramsTable.size}
				columns={tableColumns}
				data={(data as any)?.data || []}
				addStyles={stylesTable}
				manualSortBy
				onSortingColumn={handleSortingTable}
				isSelectable={false}
			/>
		</div>
	);
};

export default VersionTable;

// const format = (data: any) => {
// 	if (!data) {
// 		return [];
// 	}

// 	return data.map((item: any) => {
// 		return {
// 			...item,
// 			//
// 		};
// 	});
// };
