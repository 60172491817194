import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';

import { getTotalImpactPerPeriod } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import TemporalityGraph from '../../../components/dashboard/temporality/TemporalityGraph';
import TemporalityTitle from '../../../components/dashboard/temporality/TemporalityTitle';
import Skeleton from '../../../components/Skeleton';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { orderBy } from '../../../utils/array.utils';
import EmptyImpact from '../EmptyImpact';
const styles = {
	container: css({
		padding: 24,
		gap: 46,
	}),

	skeleton: css({
		marginTop: 24,
		height: '175px !important',
		width: '100% !important',
	} as any),
};

/**
 * getDataByIndicator
 * @param data
 * @param indicator
 * @returns
 */
const getDataByIndicator = (data: any, indicator: string) => {
	return data.filter((item: any) => {
		return item[`${indicator}Impact`] || item[`${indicator}ImpactEstimated`];
	});
};

const Temporality = () => {
	const { t } = useTranslation();
	const { selectedYear } = useYearSelection();
	const [searchQueryParams] = useSearchQueryParams();
	const { indicator } = useApp();
	const { data, isLoading } = useQuery({
		queryKey: [
			'getTotalImpactPerPeriod',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
			},
		],
		queryFn: getTotalImpactPerPeriod,
	});

	return (
		<div className={cx('flex1 flexColumn', styles.container, !isLoading && !data?.length ? 'gap16' : '')}>
			<TemporalityTitle />
			{isLoading ? (
				<Skeleton width="100%!important" className={styles.skeleton} />
			) : data?.length ? (
				<TemporalityGraph
					data={orderBy(
						getDataByIndicator(
							searchQueryParams?.facetFilters?.length
								? data.filter((item: any) => {
										return item[`${indicator}Impact`];
								  })
								: data,
							indicator,
						),
						'objectId',
						'asc',
					)}
					isFilter={searchQueryParams?.facetFilters?.length}
				/>
			) : (
				<EmptyImpact text={t('temporality-empty-text', { period: selectedYear || t('selectedYear') })} />
			)}
		</div>
	);
};

export default Temporality;
