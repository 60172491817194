import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { Database as DatabaseIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import { useGlossary } from '../../../hooks/useGlossary';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useGetPackagingSheetTimePeriod } from '../../../lib/react-query/features/packaging/packaging.hooks';
import PackagingModel from '../../../models/Packaging.model';

const PackagingCalculDataBlock = () => {
	const params = useParams(); // * { packagingId: string; tab: string }
	const theme = useTheme();

	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();

	const { data, isLoading, isError, error } = useGetPackagingSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.packagingId!,
		// period: 2023,
	});

	const packagingObject = new PackagingModel(data?.packaging);

	return (
		<>
			<Box className="flexRow" gap={'8px'}>
				<DatabaseIcon color={theme.palette.grey[700]} height={20} width={20} />
				<BlockTitle variant="h3" color={theme.palette.common.black}>
					{t('product-calculation-card-title')}
				</BlockTitle>
			</Box>
			<BlockCard>
				<Box mb="6px" display="flex" alignItems="center" justifyContent="space-between">
					<Box alignItems="center">
						<Typography mr="6px" color={siteColors.grey700} fontSize={'14px'} fontWeight={600}>
							<LightTooltipGlossary title={g('emballage-de-reference')}>
								<span className={'underline'}>{t('packaging-reference')}</span>
							</LightTooltipGlossary>{' '}
							:
						</Typography>
						<Box display="flex" gap="5px">
							<Typography fontSize={'14px'}> {data?.labelCmaps}</Typography>
							<Typography component={'span'} fontStyle={'italic'} color={theme.palette.grey[700]}>
								{' '}
								({data?.bddIcv || data?.bddRef})
							</Typography>
						</Box>
					</Box>
				</Box>
			</BlockCard>
		</>
	);
};

export default PackagingCalculDataBlock;
