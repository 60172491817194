import { useCallback, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Carrot, Plus } from 'lucide-react';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { getIngredients } from '@carbonmaps/ui/actions/ingredient.actions';
import {
	TableComponent,
	getStickyHeaderStyle,
	getStickyRowStyle,
} from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import SearchAutocomplete from '../../../../../components/SearchAutocompleteV2';
import LoadingDialog from '../../../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../../../components/table/TableHeaderCell';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';

const styles = {
	container: css({
		gap: 24,
	}),
	autoComplete: css({
		width: '100% !important',
		'& .MuiInputBase-root': {
			width: '100% !important',
		},
	}),

	initButton: css({
		border: cn('1px solid', siteColors.grey600, '!important'),
		background: '#fff !important',
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},
		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered tr th:nth-of-type(1), .bordered tr td:nth-of-type(1)': {
			width: 250,
		},
		'.bordered tr td:nth-of-type(4) div,.bordered tr td:nth-of-type(6) div': {
			justifyContent: 'flex-end',
		},
		'.bordered tr th:not(:nth-of-type(2),:nth-of-type(2),:nth-of-type(7)) div': {
			justifyContent: 'right',
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

const addSeparatorStyle = () => {
	return {
		'.bordered tr:nth-of-type(2) td': {
			borderTop: cn('11px solid', siteColors.grey200),
		},
	};
};

const formatThenSetData = (
	res: any,
	indicator: string,
	maxCarboneIntensity: number,
	maxWaterIntensity: number,
	currentIngredientId: string,
) => {
	if (!res?.length) return [];
	const stickyRowStyle = getStickyRowStyle(720, 59);
	return res.map((item: any, index: number) => {
		const showAlert =
			item.carbonImpact === 0 && item.carbonIntensity !== null && (item.sumUvc === 0 || item.volume === 0) ? 1 : 0;
		const showDataviz = item.carbonIntensity && item.carbonIntensity !== 0 ? 1 : 0;
		return {
			...item,
			origin: item.uid,
			isSticky: item.ingredientId === currentIngredientId ? true : false,
			//isN2ModelizationType: item.tagAdvancedModelization === 'yes',
			water: item.waterIntensity ? formatNumber(item.waterIntensity, undefined, 2) : '-', //to remove in refonte
			percentage:
				(indicator === CARBONE_INDICATOR
					? (item.carbonIntensity * 100) / maxCarboneIntensity
					: (item.waterIntensity * 100) / maxWaterIntensity) || 0,
			carboneImpactRaw: item.carbonImpact / 1000,
			ref: item.uid,
			// origin: item.labelOrigine || '',
			showDataviz,
			showAlert,
			uid: item.uid,
			stickyRowStyle: item.ingredientId === currentIngredientId ? stickyRowStyle : '',
		};
	});
};

type IngredientCreationTableProps = {
	ingredient?: any;
	ingredients?: any;
	onSaveIngredient: (currentId: string, prevId: string) => void;
	loading?: boolean;
};

const IngredientCreationTable = ({
	ingredient,
	ingredients,
	onSaveIngredient,
}: // loading = false,
IngredientCreationTableProps) => {
	const theme = useTheme();

	// translation
	const { t } = useTranslation();
	// const [search, setSearch] = useState<any>();

	const { indicator } = useApp();

	// custom style tab
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const saveIngredient = useCallback(
		(ingredientId: any) => {
			onSaveIngredient(ingredientId, ingredient?.id);
		},
		[ingredient?.id, onSaveIngredient],
	);

	// ---- columns ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} valueType="string" label={t('ingredient')} />;
				},
				accessor: 'label',
				component: 'TagLabel',
				editable: true,
				sortDescFirst: true,
				type: 'string',
				icon: <Carrot size={20} color={siteColors.grey700} />,
				props: {
					gap: 8,
					style: {
						color: theme.palette.common.black,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
						textTransform: 'uppercase !important',
					},
					currentUid: ingredient?.get('uid'),
					page: 'scenarioReplaceIngredient',
				},
				callBack: (row: any) => {
					saveIngredient(row?.ingredientId);
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('Intensité par kilo d’ingredient')}
							//label={indicator === CARBONE_INDICATOR ? WORDING_TERMS.INTENSITY : 'Intensité eau'}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBONE_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBONE_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} valueType="string" label="" />;
				},
				accessor: 'objectID',
				disableSortBy: true,
				editable: true,
				component: 'Action',
				callBack: (row: any) => {
					// ingredient
					saveIngredient(row?.ingredientId);
				},
				labelButton: ingredient ? t('Remplacer') : t('Ajouter'),
				props: {
					...(!ingredient && { icon: <Plus color={theme.palette.grey[700]} /> }),
					initLabel: t('reset-filter-button'),
					currentUid: ingredient?.get('uid'),
					className: styles.initButton,
				},
			},
		];
	}, [theme.palette.common.black, theme.palette.grey, indicator, ingredient, saveIngredient]);

	const [searchQueryParams] = useSearchQueryParams();
	const { input, facetFilters, supplierIds } = searchQueryParams;

	const { data, isLoading } = useQuery({
		queryKey: [
			'searchIngredients',
			{
				groupField: 'uid',
				// input: search?.input,
				searchFieldName: 'label',
				currentIngredient: ingredient?.id,
				notInIds: ingredients
					.filter((i: any) => {
						return i?.ingredient?.id !== ingredient?.id;
					})
					.map((item: any) => {
						return item?.ingredient?.id;
					}),
				limit: 100,
				//isGraph: true,
				input,
				facetFilters,
				supplierIds,
			},
		] as any,
		queryFn: async ({ queryKey }) => {
			const { 1: parameters } = queryKey;
			return await getIngredients(parameters);
		},
	});

	const stickyHeaderStyle = getStickyHeaderStyle(1391, 0);
	return (
		<div className={cx('flexColumn width100', styles.container)}>
			{/* <SearchAutocomplete
				setParamsAutocomplete={_.debounce(setSearch, 500)}
				placeholder={t('search_ingredient_placeholder', { ingredient: toLowerCase(t('ingredient')) })}
				options={[]}
				className={styles.autoComplete}
			/> */}
			{/* <div classname></div> */}
			<div className="width100">
				<TableComponent
					skeleton={isLoading}
					loading={false}
					fetchData={(row: any) => {
						return console.log('row');
					}}
					pageCount={0}
					noDataMessage={t('ingredient-table-empty-data', { ingredient: toLowerCase(t('ingredient')) })}
					pageSize={1000}
					columns={tableColumns}
					data={formatThenSetData(
						data?.data || [],
						indicator,
						data?.maxCarboneIntensity || 1,
						data?.maxWaterIntensity || 1,
						ingredient?.id,
					)}
					manualSortBy={false}
					pagination={false}
					isSelectable={false}
					addStyles={{ ...stylesTable, ...(ingredient && addSeparatorStyle()) }}
					transFunc={t}
					stickyHeader
					stickyHeaderStyles={stickyHeaderStyle}
				/>
			</div>
			{/* <LoadingDialog open={loading} content={'Modification en cours...'} /> */}
		</div>
	);
};

export default IngredientCreationTable;
