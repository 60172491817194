import { css, cx } from '@emotion/css';

import { CARBONE_INDICATOR } from '@carbonmaps/shared/utils/constants';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { capitalizeFirstLetter } from '@carbonmaps/ui/utils/utils';

import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import Typography from '../../Typography';
import Unit from '../../units/Unit';

const styles = {
	content: css({
		gap: 8,
		color: siteColors.text,
		fontSize: 14,
		fontFamily: 'Inter',
	}),
	innerContent: css({
		width: 16,
		height: 16,
		borderRadius: 16,
		background: 'var(--background-round)',
	}),
	popoverContainer: css({
		padding: 24,
		minWidth: 100,
	}),
	popoverContent: css({
		maxWidth: 200,
		'& p': {
			color: siteColors.text,
			fontSize: 14,
			fontFamily: 'Inter',
			lineHeight: 1.2,
			margin: 0,
		},
	}),
	iconContainer: css({
		gap: 8,
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		width: 40,
	}),
	width10: css({
		width: 10,
	}),
	width20: css({
		width: 20,
	}),
	text: css({
		color: 'var(--color) !important',
	}),
};

const VsGammeField = ({ item, viewMode }: any) => {
	const { indicator } = useApp();

	const value = item[`vsActive${capitalizeFirstLetter(indicator)}`];

	const { t } = useTranslation();

	return (
		<div className="flexRow width100 justifyEnd">
			<BPopover
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				modeHover
				content={(onClose) => {
					return (
						<div className={styles.popoverContainer}>
							<div className={cx('flexColumn gap4', styles.popoverContent)}>
								<div>
									<p className="fontWeight700">{t('gamme')}</p>
									<p className="">{capitalizeFirstLetter(item?.labelPortfolioCmaps)}</p>
								</div>
								<div>
									<p className="fontWeight700">{t('au kilo')}</p>
									<div className="flexRow alignCenter gap4">
										<p className="number lineHeight1">
											{formatNumber(indicator === CARBONE_INDICATOR ? item?.carbonIntensity : item?.waterIntensity)}
										</p>
										<Unit
											component="p"
											variant="caption"
											measure={indicator === CARBONE_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
										/>
									</div>
								</div>
							</div>
						</div>
					);
				}}
			>
				<div className={cx('flexRow alignCenter justifyCenter fontWeight400', styles.content)}>
					<Typography
						className={cx('fontWeight600 number textAlignRight', styles.text)}
						style={{
							['--color' as any]: value < 0 ? '#009F36' : value === 0 ? siteColors.grey700 : '#C70000',
						}}
					>
						{value > 0 ? '+' : value == 0 ? '=' : '-'} {value !== 0 && formatNumber(Math.abs(value), undefined, 2)} %
					</Typography>
				</div>
			</BPopover>
		</div>
	);
};

export default VsGammeField;
