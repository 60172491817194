import { css, cx } from '@emotion/css';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { cn, siteColors } from '../../../../../lib/colors';
import SectionRepartition from '../../../../suppliers/tabs/rse/blocks/SectionRepartition';
import SupplierRepartition from '../../../../suppliers/tabs/rse/blocks/SupplierRepartition';
import { useFindQuestionFormsTabs, useRseQFormTabQueryParam } from '../../../../suppliers/tabs/rse/hooks/rse.hooks';
import { useFindSupplierQuestionFormsTabs, useGetInfoNoteData } from '../hooks/rse.hooks';
import InfoNote from './InfoNote';
import SupplierAnswers from './SupplierAnswers';

const styles = {
	gridContainer: css({
		maxWidth: '100%',
		overflow: 'auto',
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridColumnGap: 32,
		gridRowGap: 32,
		paddingBottom: 20,
		marginBottom: 20,
	}),
};

const ContentRseDetails = () => {
	const [questionFormId] = useRseQFormTabQueryParam();
	const { data /* isError, error,  isLoading */ } = useGetInfoNoteData({ questionFormId: _.toString(questionFormId) });
	const params = useParams();

	// const { data: tabsData, isLoading: isLoadingTabsData } = useFindQuestionFormsTabs();
	const { /* data: tabsData, */ isLoading: isLoadingTabsData } = useFindSupplierQuestionFormsTabs({
		supplierId: _.toString(_.get(params, 'supplierId')),
	});

	// console.log('****', tabsData);
	// return <EmptyBlock />;

	// if (!isLoading && _.isEmpty(tabsData)) {
	// 	// return <div>RSE Data not Found</div>;
	// 	return <EmptyBlock />;
	// }

	return (
		<div className={cx('width100')}>
			<InfoNote />
			<div className={cx(styles.gridContainer, 'width100')}>
				{/* <SupplierRepartition isLoading={isLoadingTabsData} /> */}
				<SectionRepartition fullWidth />
			</div>
			<div className={cx(styles.gridContainer, 'width100')}>
				<SupplierRepartition isLoading={isLoadingTabsData} fullWidth highlightNote={_.get(data, 'note')} />
				{/* <SectionRepartition /> */}
			</div>
			<div>
				<SupplierAnswers />
			</div>
		</div>
	);
};

export default ContentRseDetails;
