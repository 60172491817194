/* eslint-disable @typescript-eslint/ban-ts-comment */
import { forwardRef, useEffect, useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { Container, Link as MUILink, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom'
import dayjs from 'dayjs';
import GithubSlugger from 'github-slugger';
import { headingRank } from 'hast-util-heading-rank';
import { toString } from 'hast-util-to-string';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { Link, type LinkProps, useLocation, useParams } from 'react-router-dom';
// import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';
import { visit } from 'unist-util-visit';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import Skeleton from '../../components/Skeleton';
import { useGetSectorSheetById } from '../superAdmin/sectorSheet/sectorSheet.hooks';

import { siteColors } from '../../lib/colors';
import ScrollToHashElement from './ScrollToHashElement';
import { SheetImage } from './SectorSheetList';

const styles = {
	container: css({
		backgroundPositionX: 'center',
		backgroundImage: 'url(/dashboard-bg.svg)',
		minHeight: '100vh',
		width: 'calc(100% - 184px)',
		backgroundRepeat: 'no-repeat',
		margin: '0 auto',
		// paddingTop: 52,
		// paddingBottom: 62,
		//backgroundSize: '90%',
		// background: 'red',
	}),

	sheetImageContainer: css({
		display: 'flex',
		width: '130px',
		height: '130px',
		padding: '0px 45px',
		justifyContent: 'center',
		alignItems: 'center',
		background: 'red',
	}),

	updateText: css({
		color: siteColors.grey800,
		// textAlign: 'center',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '20px',
	}),

	hidden: css({
		display: 'none',
	}),
};

const SectorSheet = () => {
	const params = useParams<{ sheetId: string }>();
	const { i18n, t } = useTranslation();
	const lng = getIsoCountryCode(i18n.language);
	const { setBreadcrumbs } = useApp();

	const {
		result: { data: sheetData, isLoading },
	} = useGetSectorSheetById({ sheetId: params.sheetId || '' });

	useEffect(() => {
		setBreadcrumbs([
			{ text: 'Fiches filières', link: FRONT_PATH_NAMES.sectorSheet },
			{ text: (sheetData as any)?.translation[lng]?.title },
		]);
	}, [lng, setBreadcrumbs, sheetData]);

	// const slugger = useMemo(() => {
	// 	return new GithubSlugger();
	// }, []);

	const location = useLocation();

	return (
		<>
			<ScrollToHashElement />
			<div
				className={cx('flexColumn', styles.container)}
				css={{
					paddingTop: 66,
					paddingBottom: 66,
				}}
			>
				<Container
					sx={{
						maxWidth: '1100px!important',
					}}
				>
					<div className={cx('flexRow alignCenter')} css={{ marginBottom: 64 }}>
						{/* <div className={cx('', styles.sheetImageContainer)}></div> */}
						<SheetImage imageUrl={sheetData?.imageUrl || ''} marginBottom={0} isLoading={isLoading} />
						<div
							css={{
								marginLeft: 40,
							}}
						>
							{isLoading ? (
								<Skeleton variant="text" sx={{ borderRadius: '6px' }}>
									<h1
										css={{
											margin: 0,
											marginBottom: 8,
										}}
									>
										Fixed Title Text
									</h1>
								</Skeleton>
							) : (
								<h1
									css={{
										margin: 0,
										marginBottom: 8,
									}}
								>
									{(sheetData as any)?.translation[lng]?.title}
								</h1>
							)}
							{isLoading ? (
								<Skeleton variant="text" sx={{ borderRadius: '4px' }}>
									<p
										className={cx('', styles.updateText)}
										css={{
											margin: 0,
										}}
									>
										{/* {t('meta-data-update-date')} {dayjs(sheetData?.updatedAt).locale(i18n?.language).format('D MMMM YYYY')} */}
										very very log placeholder text
									</p>
								</Skeleton>
							) : (
								<p
									className={cx('', styles.updateText)}
									css={{
										margin: 0,
									}}
								>
									{t('meta-data-update-date')}{' '}
									{dayjs(sheetData?.updatedAt).locale(i18n?.language).format('D MMMM YYYY')}
								</p>
							)}
						</div>
					</div>

					<div
						className="width100"
						css={{
							display: 'grid',
							gridTemplateColumns: '9fr 3fr',
							// background: 'red',
							gap: 32,
						}}
					>
						<div
							css={
								{
									//
									// background: 'red',
								}
							}
						>
							<Markdown
								remarkPlugins={[remarkGfm]}
								rehypePlugins={[/* rehypeSlug */ _rehypeSlug]}
								components={{
									table: ({ children, ...props }) => {
										// @ts-ignore
										return <Table {...props}>{children}</Table>;
									},
									thead: ({ children, ...props }) => {
										// @ts-ignore
										return <TableHead {...props}>{children}</TableHead>;
									},
									tbody: ({ children, ...props }) => {
										// @ts-ignore
										return <TableBody {...props}>{children}</TableBody>;
									},
									tr: ({ children, ...props }) => {
										// @ts-ignore
										return <TableRow {...props}>{children}</TableRow>;
									},
									td: ({ children, ...props }) => {
										// @ts-ignore
										return <TableCell {...props}>{children}</TableCell>;
									},
									th: ({ children, ...props }) => {
										// @ts-ignore
										return <TableCell {...props}>{children}</TableCell>;
									},

									a: ({ ...props }) => {
										const isHttp = props?.href?.includes('http');

										return isHttp ? (
											// @ts-ignore
											<MUILink target="_blank" rel="noopener" {...props} />
										) : (
											// @ts-ignore
											<MUILink component={Link} href={props.href} {...props}>
												{props.children}
											</MUILink>
										);
									},
									img: ({ ...props }) => {
										return <img alt={props.alt} css={{ borderRadius: 3, width: '100%' }} {...props} />;
									},
								}}
							>
								{sheetData?.translation[lng]?.content}
							</Markdown>
						</div>
						<div>
							<p
								className={cx('', isLoading ? styles.hidden : '')}
								css={{
									margin: '12px 0',

									color: siteColors.text,
									leadingTrim: 'both',
									textEdge: 'cap',
									fontVariantNumeric: 'lining-nums tabular-nums',
									fontFamily: 'Inter',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 600,
									lineHeight: 'normal',
								}}
							>
								{t('Sur cette page')}
							</p>

							<div>
								{sheetData?.translation[lng]?.toc.map((item: TocJSON, index: number) => {
									// let id = slugify(item.content);
									// let id = slugger.slug(item.content);

									// if (id.startsWith('-')) {
									// 	id = id.slice(1);
									// }
									let id = encodeURIComponent(item.slug).replace('%EF%B8%8F', '');

									if (id.startsWith('-')) {
										id = id.slice(1);
									}

									return (
										<Link
											/* to="#lolé" */ to={`${location.pathname}#${id}`}
											css={{ textDecoration: 'unset', color: 'none' }}
										>
											<p
												key={index}
												css={{
													margin: '12px 0',
													color: siteColors.grey800,
													leadingTrim: 'both',
													textEdge: 'cap',
													fontVariantNumeric: 'lining-nums tabular-nums',
													fontFamily: 'Inter',
													fontSize: '14px',
													fontStyle: 'normal',
													fontWeight: 400,
													lineHeight: 'normal',
													// whiteSpace: 'nowrap',
												}}
												style={{
													...(item.lvl > 2
														? {
																paddingLeft: `${(item.lvl - 2) * 22}px`,
														  }
														: {}),
												}}
											>
												{item.content}
											</p>
										</Link>
									);
								})}
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	);
};

export default SectorSheet;

type TocJSON = {
	content: string;
	slug: string;
	lvl: number;
	i: number;
	seen: number;
};

// // import React from 'react';

// const flatten = (text: string, child) => {
// 	return typeof child === 'string' ? text + child : React.Children.toArray(child.props.children).reduce(flatten, text);
// };

// /**
//  * HeadingRenderer is a custom renderer
//  * It parses the heading and attaches an id to it to be used as an anchor
//  */
// const HeadingRenderer = (props) => {
// 	const children = React.Children.toArray(props.children);
// 	const text = children.reduce(flatten, '');
// 	const slug = text.toLowerCase().replace(/\W/g, '-');
// 	return React.createElement(`h${props.level}`, { id: slug }, props.children);
// };

// // export default HeadingRenderer;

// function slugify(str: string) {
// 	return String(str)
// 		.normalize('NFKD') // split accented characters into their base characters and diacritical marks
// 		.replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
// 		.trim() // trim leading or trailing whitespace
// 		.toLowerCase() // convert to lowercase
// 		.replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
// 		.replace(/\s+/g, '-') // replace spaces with hyphens
// 		.replace(/-+/g, '-') // remove consecutive hyphens
// 		.replaceAll(
// 			/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
// 			'',
// 		);
// }

const emptyOptions = {};
const slugs = new GithubSlugger();
// const trackMap = new Map<string, number>();

function _rehypeSlug(options: any) {
	const settings = options || emptyOptions;
	const prefix = settings.prefix || '';

	/**
	 * @param {Root} tree
	 *   Tree.
	 * @returns {undefined}
	 *   Nothing.
	 */
	return function (tree: any) {
		slugs.reset();

		visit(tree, 'element', function (node: any) {
			if (headingRank(node) && !node.properties.id) {
				node.properties.id = prefix + slugs.slug(toString(node)).replace(/[^a-z0-9 -]/g, ''); // remove non-alphanumeric characters;
			}
		});
	};
}

// import { forwardRef } from 'react';
// import { Link, LinkProps } from 'react-router-dom';

// ----------------------------------------------------------------------

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
	href: string;
}

const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(({ href, ...other }, ref) => {
	return <Link ref={ref} to={href} {...other} />;
});

// export default RouterLink;
