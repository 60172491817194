import { css, cx } from '@emotion/css';
import MUILink from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import { ArrowUpRight } from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';

import LightTooltip from '../LightTooltip';


const styles = {
	isDisabled: css({
		cursor: 'not-allowed',
	}),

	container: css({
		marginBottom: '6px',
		justifyContent: 'space-between',
		width: '100%',
	}),
};

type LineDisplayProps = {
	content: React.ReactNode;
	afterContent?: React.ReactNode;
	path?: any;
	disabled?: any;
	disabledMessage?: string;
};

const LineDisplay = ({ disabled = false, content, path, afterContent, disabledMessage }: LineDisplayProps) => {
	const theme = useTheme();

	return (
		<div className={cx('flexRow alignCenter', styles.container)}>
			{content}
			<div className="flexRow alignCenter nowrap">
				{afterContent}
				{path ? (
					<MUILink component={RouterLink} to={path}>
						<ArrowUpRight
							size={25}
							color={theme.palette.grey[700]}
							css={{ '&:hover': { stroke: theme.palette.primary.main }, paddingTop: '5px' }}
						/>
					</MUILink>
				) : (disabled && disabledMessage !== '' ?
					<div className={cx(styles.isDisabled)}>
						<LightTooltip title={disabledMessage} className="flexRow alignCenter nowrap">
							<ArrowUpRight size={25} color={theme.palette.grey[700]} css={{ paddingTop: '5px' }} />
						</LightTooltip>
					</div>
					: null)}
			</div>
		</div>
	);
};

export default LineDisplay;
