import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import BPopover from '../BPopover';

import EmptyValue from './EmptyValue';

const styles = {
	popoverContent: css({
		padding: '24px !important',
	}),
	weightedCarbonContent: css({
		marginBottom: '12px !important',
	}),
	value: css({
		marginRight: '8px !important',
	}),

	lineContainer: css({
		height: '16px !important',
		width: '45%',
		flexDirection: 'row-reverse',
		borderRadius: '500px',
		overflow: 'hidden',
	}),
	lineContent: css({
		height: '100%',
		borderRadius: '6px',
	}),
};

const CursorComponent = (props: any) => {
	const { value, row, /*  callback, rows,  */ sortedRows } = props;

	const currentIndex = (sortedRows as any[]).findIndex((e: any) => {
		return e.index === row.index;
	});

	let start = 0;

	for (let index = 0; index < currentIndex /* row.index */; index++) {
		// const element = array[index];
		start = start + sortedRows[index].values.weightedCarbonIntensity;
	}

	return (
		<div className="flexRow alignCenter">
			{row.original.carbonIntensityRaw ? (
				!isNaN(value) ? (
					<BPopover
						addStyles={{ width: '100%' }}
						anchorOriginHorizontal={'right'}
						transformOriginVertical={'top'}
						transformOriginHorizontal={'right'}
						anchorOriginVertical={'bottom'}
						modeHover
						content={(onClose) => {
							return (
								<div className={styles.popoverContent}>
									{row.original.weightedCarbonIntensityRaw && (
										<div className={styles.weightedCarbonContent}>
											{/* weightedCarbonIntensityRaw: item.gesWeighted,
									carbonIntensityRaw: item.ges, */}
											<Typography className="fontWeight700">Intensité carbone pondérée</Typography>
											<Typography>
												{formatNumber(row.original.weightedCarbonIntensityRaw || 0, undefined, 2)} KG <sub>EQ</sub>CO
												<sub>2</sub>/KG
											</Typography>
										</div>
									)}
									{row.original.carbonIntensityRaw && (
										<div>
											<Typography className="fontWeight700">Intensité carbone</Typography>
											<Typography>
												{formatNumber(row.original.carbonIntensityRaw || 0, undefined, 2)} KG <sub>EQ</sub>CO
												<sub>2</sub>/KG
											</Typography>
										</div>
									)}
								</div>
							);
						}}
					>
						<div className="flexRow alignCenter width100">
							<Typography className={cx('mainColor', styles.value)}>{formatNumber(value, undefined, 1)}%</Typography>
							<div className={cx(styles.lineContainer, 'flexRow bgGrey500')}>
								<div
									className={cx('mainColor', styles.lineContent)}
									style={{
										width: `${(value as number)?.toExponential(1) || 0}%`,
										marginLeft: `${start}%`,
									}}
								/>
							</div>
						</div>
					</BPopover>
				) : (
					<EmptyValue />
				)
			) : (
				<Typography>Non pris en compte</Typography>
			)}
		</div>
	);
};

export default CursorComponent;
