import { useMemo, useRef } from 'react';

import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { alpha } from '@mui/system/colorManipulator';
import { RefreshCw } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useProductLifeChart } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import BlockCard from '../../../components/BlockCard';
import BlockTitle from '../../../components/BlockTitle';
import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import LifeCycleChart from '../../../components/charts/LifeCycleChart';
import LifeCycleChartTooltip from '../../../components/charts/LifeCycleChartTooltip';
import { useGlossary } from '../../../hooks/useGlossary';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useSize } from '../../../hooks/useSize';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { CARBONE_INDICATOR } from '../../../utils/constants';
import { WaterUnit } from '../../../utils/graphIndicator';

const LifeCycleBlock = () => {
	const { indicator } = useApp();
	const theme = useTheme();
	const params = useParams();

	const containerRef = useRef(null);
	const size = useSize(containerRef);
	const svgParentSize = useMemo(() => {
		if (!size)
			return {
				width: 730,
				height: 230,
			};

		// const height = (size as any)?.height > 195 ? 195 : (size as any)?.height;
		const height = 195;

		return {
			width: (size as any)?.width || 730,
			height,
		};
	}, [size]);

	// ---- fetch data chart ---- //
	const [searchQueryParams] = useSearchQueryParams();
	const { data, isLoading } = useProductLifeChart({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		productId: params.productId!,
		viewMode: indicator,
		uid: searchQueryParams?.uid,
	});

	// translation
	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();

	// get the values in percentage
	const getGESInPercent = () => {
		if (!data) return Array.from<{ value: number; link?: string }>({ length: 6 }).fill({ value: 0, link: '#' });

		const input = [
			// data.product.gesIngredients,
			{
				value: data.gesAgriculture,
				tooltip: <LifeCycleChartTooltip value={data.gesAgriculture} />,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				link: `${FRONT_PATH_NAMES.products}/${params.productId!}/ingredients`,
			},
			{
				value: data.gesTransformation,
				tooltip: <LifeCycleChartTooltip value={data.gesTransformation} />,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				link: `${FRONT_PATH_NAMES.products}/${params.productId!}/ingredients`,
			},
			{
				value: data.gesPackaging,
				tooltip: <LifeCycleChartTooltip value={data?.gesPackaging} />,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				link: `${FRONT_PATH_NAMES.products}/${params.productId!}/packaging`,
			},
			{
				tooltip: <LifeCycleChartTooltip value={data?.gesTransport} />,
				value: data.gesTransport,
			},
			{
				tooltip: <LifeCycleChartTooltip value={data?.gesDistribution} />,
				value: data.gesDistribution,
			},
			{
				tooltip: <LifeCycleChartTooltip value={data?.gesConsumption} />,
				value: data.gesConsumption,
			},
		];

		return input.map((e) => {
			return {
				...e,
				value: ((e.value || 0) * 100) / (data?.carbonIntensity || 1),
				link: e.link,
			};
		});
	};

	const getWaterUseInPercent = () => {
		if (!data) return Array.from<{ value: number; link?: string }>({ length: 6 }).fill({ value: 0, link: '#' });

		const input = [
			// data.product.gesIngredients,
			{
				value: data?.waterUseAgriculture,
				tooltip: (
					<LifeCycleChartTooltip value={data?.waterUseAgriculture} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				link: `${FRONT_PATH_NAMES.products}/${params.productId!}/ingredients`,
			},
			{
				value: data?.waterUseTransformation,
				tooltip: (
					<LifeCycleChartTooltip
						value={data?.waterUseTransformation}
						unit={WaterUnit}
						label={WORDING_TERMS.INTENSITY}
					/>
				),
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				link: `${FRONT_PATH_NAMES.products}/${params.productId!}/ingredients`,
			},
			{
				value: data?.waterUsePackaging,
				tooltip: (
					<LifeCycleChartTooltip value={data?.waterUsePackaging} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				link: `${FRONT_PATH_NAMES.products}/${params.productId!}/packaging`,
			},
			{
				tooltip: (
					<LifeCycleChartTooltip value={data?.waterUseTransport} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
				value: data?.waterUseTransport,
			},
			{
				tooltip: (
					<LifeCycleChartTooltip value={data.waterUseDistribution} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
				value: data?.waterUseDistribution,
			},
			{
				tooltip: (
					<LifeCycleChartTooltip value={data?.waterUseConsumption} unit={WaterUnit} label={WORDING_TERMS.INTENSITY} />
				),
				value: data?.waterUseConsumption,
			},
		];

		// console.log(input);

		return input.map((e) => {
			return {
				...e,
				value: ((e.value || 0) * 100) / (data?.waterIntensity || 1),
				link: e.link,
			};
		});
	};

	return (
		<>
			<BlockTitle icon={<RefreshCw color={siteColors.grey700} size={20} />}>
				<LightTooltipGlossary title={g('cycle-de-vie')}>
					<span className={'underline'}>{t('product-life-cycle-title')}</span>
				</LightTooltipGlossary>
				<Typography display="inline" ml={pxToRem(7)} fontWeight={500} color={theme.palette.grey[900]}>
					{t('product-life-cycle-subtitle')}
				</Typography>
			</BlockTitle>
			<div ref={containerRef}>
				<BlockCard>
					<LifeCycleChart
						datas={indicator === CARBONE_INDICATOR ? getGESInPercent() : getWaterUseInPercent()}
						/*colors={
							indicator === WATER_INDICATOR
								? {
									main: theme.palette.indicator.water.main,
									polygonGradient: [
										alpha(theme.palette.indicator.water.main, 0.7),
										alpha(theme.palette.indicator.water.main, 0.6),
									],
								}
								: undefined
						}*/
						viewBox={`0 0 ${svgParentSize.width} ${svgParentSize.height}`}
						withLabel={true}
						iconColor={siteColors.grey700}
						colors={
							indicator === WATER_INDICATOR
								? {
										main: theme.palette.indicator.water.main,
										polygonGradient: [
											siteColors.water100,
											siteColors.water100,
											// alpha(theme.palette.indicator.water.main, 0.7),
											// alpha(theme.palette.indicator.water.main, 0.6),
										],
								  }
								: undefined
						}
						areaHeight={svgParentSize.height}
						areaWidth={svgParentSize.width}
						iconSize={24}
						labelColor={theme.palette.indicator[indicator].main}
						//bottomPosition={40}
						strokeWidth={1.5}
						lineWidth={2}
						/*	fontStyles={{
								title: 20,
								subtitle: 16,
							}}*/
						fontStyles={{
							title: 12,
							subtitle: 12,
						}}
						topValueContainer={{
							width: 70,
							//	height: 40,
							height: 30,
						}}
					/>
				</BlockCard>
			</div>
		</>
	);
};

export default LifeCycleBlock;
