import { css, cx } from '@emotion/css';
import { siteColors } from '../../../lib/colors';

type IconContainerProps = {
	element: React.ReactNode;
	color?: string;
};

const styles = {
	container: css({
		width: 60,
		height: 60,
		borderRadius: 8,
		top: 0,
		background: 'var(--background-color)',
	}),
};

const IconContainer = ({ element, color = siteColors.primary }: IconContainerProps) => {
	return (
		<div
			className={cx('flexRow alignCenter justifyCenter', styles.container)}
			style={{ ['--background-color' as any]: color }}
		>
			{element}
		</div>
	);
};

export default IconContainer;
