import { z } from 'zod';

export const FacetFieldFormValidationSchema = z.object({
	label: z.string({ required_error: 'Libelle obligatoire' }).min(1, 'Libelle obligatoire').max(255, 'Libelle doit avoir 255 caractères au maximum'),
	field: z.string({ required_error: 'champ obligatoire' }).min(1, 'champ obligatoire')
		.max(255, 'champ doit avoir 255 caractères au maximum')
		// .refine((value) => { return value === value.toLowerCase(); }, {
		// 	message: 'champ doit être en minuscules.',
		// })
		.refine((value) => { return /^[a-zA-Z0-9_]+$/.test(value); }, {
			message: 'champ ne doit contenir que des caractères alphanumériques (sans espaces ni caractères spéciaux).',
		}),
	companyId: z.string({ required_error: 'Company obligatoire' }).min(4, 'Company obligatoire'),
	type: z.string().optional(),
	indexId: z.string({ required_error: 'indexId obligatoire' }).min(4, 'indexId obligatoire'),
	isVisibleInFront: z.boolean(),
	isNew: z.number(),
	global: z.boolean().optional(),
	collectionName: z.string({ required_error: 'collection obligatoire' }).min(4, 'collection obligatoire'),
});

export type FacetFieldFormInput = z.infer<typeof FacetFieldFormValidationSchema>;
