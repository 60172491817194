import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';

import { useYearSelection } from '../../../../hooks/useImpactYear';


export async function findModelizationTableAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		const result = await Parse.Cloud.run(functionName.temporality.findModelization, { view: 'general-view-of-modelization->modelization-table', ...params });
		return result;
	} catch (error) {
		console.log('--------- findModelizationTableAction error: ', error);
		return Promise.reject(error);
	}
}



export async function countModelizationAction(context: any) {
	try {
		const {
			queryKey: { 1: params },
		} = context;

		const result = await Parse.Cloud.run(functionName.temporality.countModelization, { view: 'general-view-of-modelization->modelization-table', ...params });
		return result;
	} catch (error) {
		console.log('--------- countModelizationAction error: ', error);
		return Promise.reject(error);
	}
}

export const findOneProductModelizationAction = async (
	context: QueryFunctionContext<
		readonly ['findOneProductModelization', { productId: string; period: string | number; viewMode: 'carbon' | 'water' }]
	>,
): Promise<any> => {
	try {
		const {
			queryKey: [, { productId, period, viewMode }],
		} = context;

		const res = (await Parse.Cloud.run(functionName.temporality.findOneProductModelization, {
			productId,
			period,
			viewMode,
		})) as any; // Everything must be already JSONs from the server.

		return res;
	} catch (error) {
		console.log(' ------ findOneProductModelizationAction error: ', error);
		return Promise.reject(error);
	}
};
