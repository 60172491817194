import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import IntensityChart from '../../charts/IntensityChart';

type Props = {
	value: any;
	row: any;
	column: any;
	updateMyData: (...params: any[]) => void;
};

const styles = {
	popoverContainer: css({
		padding: 24,
	}),
	innerPopoverContainer: css({ marginBottom: 12 }),

	width35: css({ width: 35, fontWeight: '400', fontSize: '14px !important' }),

	content: css({
		gap: 16,
		color: 'var(--color)',
		fontWeight: 'normal',
	}),
	typeModelizationText: css({
		width: 60,
		color: 'var(--color-text)',
	}),
};

const AcvIntensity = ({ value, row, column }: Props) => {
	const theme = useTheme();

	const color = column.props.color.primary;
	const secondary = column.props.color.secondary;
	const type = column.props.type;

	const diffPercent = row?.original?.diffPercent || 0;

	return (
		<div className="flexRow justifyEnd alignCenter width100 ">
			<div
				className={cx('flexRow alignCenter nowrap justifyCenter', styles.content)}
				style={{ ['--color' as any]: color }}
			>
				<span className={cx(styles.width35, 'number')}>{value ? formatNumber(value, undefined, 2) : '-'}</span>

				<IntensityChart
					percentage={type === 'N2' ? row?.original?.percentageN2 || 0 : row?.original?.percentageN1 || 0}
					diff={type === 'N2' && diffPercent}
					color={color}
					secondary={secondary}
				/>
			</div>
		</div>
	);
};

export default AcvIntensity;
