import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { darken, emphasize, lighten, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Box } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { CARBONE_INDICATOR, FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getFacetLabel } from '@carbonmaps/shared/utils/utils';
import { getCurrentCategoryAction, getSegmentationDataAction } from '@carbonmaps/ui/actions/dashboard.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import Typography from '../../../../components/Typography';
import DashboardSelect from '../../../../components/dashboard/DashboardSelect';
import CardContent from '../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../components/dashboard/v2/CardTitle';
import DashboardButton from '../../../../components/dashboard/v2/DashboardButton';
import DashboardCard from '../../../../components/dashboard/v2/DashboardCard';
import DashboardGraph from '../../../../components/dashboard/v2/DashboardGraph';
import Icon from '../../../../components/dashboard/v2/Icon';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../lib/colors';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { orderBy } from '../../../../utils/array.utils';

const styles = {
	content: css({ minHeight: 321 }),
	emptyText: css({
		color: cn(siteColors.grey700, '!important'),
		textAlign: 'center',
		width: '100%',
		height: '200px',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	}),
};

const toDataIndicatorGraph = (data: any, category: string, indicatorField: string, color: string, company?: any) => {
	const orderData = orderBy(data, indicatorField);
	const total = _.sumBy(data, indicatorField);

	// display only 20 first element + others
	const firstData = orderData.slice(0, 20);
	const othersData = orderData?.length > 20 ? orderData.slice(21, orderData?.length) : [];

	const result = firstData.map((item: any, index: number) => {
		return {
			...item,
			category: getFacetLabel(category, company),
			categoryValue: item?._id,
			path: item?.path || `${FRONT_PATH_NAMES.analyse}/${category}/${item?._id}`,
			label: item?._id,
			y: item[indicatorField],
			percentValue: ((item[indicatorField] || 0) * 100) / total,
			color:
				index === 0
					? darken(color, 0.1)
					: index < 3
					? emphasize(color, (index * 1.5) / 10)
					: index % 2 === 0
					? lighten(color, 0.5)
					: lighten(color, 0.8),
		};
	});

	// add other element when exist
	if (othersData?.length) {
		const otherValue = _.sumBy(othersData, indicatorField);
		const otherElement = {
			category: 'Autres',
			categoryValue: 'Autres',
			y: otherValue,
			percentValue: (otherValue * 100) / total,
			color: firstData?.length % 2 ? lighten(color, 0.5) : lighten(color, 0.8),
			label: 'Autres',
			path: null,
		};

		result.push(otherElement);
	}

	return result;
};

const Product = () => {
	// params filters
	const [searchQueryParams] = useSearchQueryParams();
	const { t } = useTranslation();
	const { indicator } = useApp();
	const navigate = useNavigate();
	const theme = useTheme();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// value filter
	const [currentSegment, setCurrentSegment] = useState(null);
	const [options, setOptions] = useState<any>([]);

	// period selected
	const { selectedYear } = useYearSelection();

	// ---- fetch segmentation data ---- //
	const { data: dataSegmentation, isLoading } = useQuery({
		queryKey: [
			'getSegmentationData',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
			},
		],
		queryFn: getSegmentationDataAction,
	});

	const { data: category, isLoading: isLoadingCategory } = useQuery({
		queryKey: ['getCurrentCategory'],
		queryFn: getCurrentCategoryAction,
	});

	useEffect(() => {
		if (category !== 'ok') {
			setCurrentSegment(category);
			return;
		}

		if (options?.length) {
			setCurrentSegment(options[0]?.value);
		}
	}, [category, options]);

	// ---- repartition graph data ---- //
	const dataGraph = useMemo(() => {
		if (!dataSegmentation || !currentSegment) return [];

		const color = theme.palette.indicator[indicator].main;

		const data = dataSegmentation[currentSegment] || [];

		// ---- format graph data ---- //
		const dataSegment = toDataIndicatorGraph(
			data,
			currentSegment,
			indicator === CARBONE_INDICATOR ? 'carbonImpact' : 'waterImpact',
			color,
			authData?.session.company,
		) as any;

		if (!dataSegment?.length) {
			return [{ y: 1, color: siteColors.grey400, isEmpty: true }];
		}

		return dataSegment;
	}, [dataSegmentation, currentSegment, theme.palette.indicator, indicator, authData?.session.company]);

	const toOptions = (object: any) => {
		if (!object) return object;
		const keys = Object.keys(object);

		const options = keys
			.filter((key: string) => {
				return object[key]?.length;
			})
			.map((value: string) => {
				return {
					value: value,
					label: getFacetLabel(value, authData?.session.company),
				};
			});

		return options;
	};

	// ---- go to analyze page ---- //
	const goToAnalyzePage = useCallback((item: any) => {
		if (item?.path) {
			navigate(item?.path);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!dataSegmentation) return;
		const dataOptions = toOptions(dataSegmentation);
		setOptions(dataOptions);
		setCurrentSegment(dataOptions[0]?.value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataSegmentation]);

	return (
		<DashboardCard
			title={
				<CardTitle
					icon={<Icon element={Box} color={siteColors.primary} background={siteColors.carbon100} />}
					title={t('dashboard-product-card-title', { products: toLowerCase(t('products')), product: t('product') })}
					subtitle={t('dashboard-product-card-subtitle', { period: selectedYear || t('period') })}
				/>
			}
			className="flex1"
		>
			<CardContent
				button={
					<DashboardButton
						// label={t('dashboard-product-card-button', {
						// 	products: toLowerCase(t('products')),
						// })}
						label={t('dashboard-category-card-button')}
						path={`${FRONT_PATH_NAMES.analyse}/${currentSegment}`}
						eventName="Dashboard Products Analyze"
					/>
				}
				className={cx(styles.content, 'flex1')}
				loading={isLoading}
			>
				{dataGraph?.length ? (
					<DashboardGraph
						filter={
							<DashboardSelect
								options={options}
								onChange={(v: any) => {
									setCurrentSegment(v);
								}}
								value={currentSegment as any}
							/>
						}
						data={dataGraph}
						onClick={goToAnalyzePage}
					/>
				) : null}
				{!isLoading && !dataGraph?.length && <Typography className={styles.emptyText}>{t('empty-segment')}</Typography>}
			</CardContent>
		</DashboardCard>
	);
};

export default Product;
