import { useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';
import { darken, emphasize, lighten } from '@mui/system/colorManipulator';

import { randomColor } from '../../utils/color.utils';
import { toPercentages } from '../../utils/num.utils';

import { siteColors } from '../../lib/colors';
import TooltipChart from './TooltipChart';

type Props = {
	title?: string;
	data: number[];
	width?: number;
	height?: number;
	indicator: any;
	onClick: (item: Record<string, string | number>) => void;
	isDisabled?: boolean;
	color?: string;
	count?: number;
	isDisabledLink?: boolean;
	sx?: SxProps;
};

const AnalysisHorizontalGraph = ({
	title,
	data,
	indicator,
	width = 724,
	height = 30,
	isDisabled,
	onClick,
	color,
	count,
	isDisabledLink = false,
	sx,
}: Props) => {
	const theme = useTheme();
	const viewBoxWidth = width;
	const viewBoxHeight = height;

	const tooltipRef = useRef<any>();
	const svgRef = useRef<any>();

	const [selectedItem, setSelectedItem] = useState(null);

	const [dataGraph, setDataGraph] = useState<any>([]);

	const percentages = useMemo(() => {
		setDataGraph(
			data.map((item: any, index: number) => {
				return {
					...item,
					color: isDisabled
						? siteColors.grey400
						: color
						? index === 0
							? darken(color, 0.1)
							: index < 3
							? // ? alpha(color, 1 - index / 10)
							  emphasize(color, (index * 1.5) / 10)
							: index % 2 === 0
							? lighten(color, 0.5)
							: lighten(color, 0.8)
						: randomColor(index),
				};
			}),
		);
		return toPercentages(
			data.map((item: any) => {
				return item?.y || 0;
			}),
		);
	}, [data]);

	let startPosition = 0;

	const onMouseEnter = (evt: any, data: any) => {
		const CTM = svgRef.current.getScreenCTM();
		const mouseX = (evt.clientX - CTM.e) / CTM.a;
		tooltipRef.current.setAttributeNS(null, 'visibility', 'visible');
		tooltipRef.current.setAttributeNS(null, 'top', 50);
		tooltipRef.current.setAttributeNS(
			null,
			'style',
			`bottom:120%;left: ${mouseX + 6 / CTM.a}px; position: absolute; display:none`,
		);
		setSelectedItem(data);
	};

	return !data?.length ? (
		<></>
	) : (
		<Box className="flexRow nowrap" sx={{ gap: '16px', alignItems: 'end', width: '100%' }}>
			<Box className="flexColumn" sx={{ width: '110px', gap: '4px', ...sx }}>
				<Typography fontSize="14px" sx={{ textTransform: 'capitalize', lineHeight: 1 }}>
					{title}
				</Typography>
				<Typography fontWeight={600} color={theme.palette.common.black} fontSize="24px" sx={{ lineHeight: 1 }}>
					{count}
				</Typography>
			</Box>

			<Box
				sx={{
					flex: 1,
					position: 'relative',
					'&:hover .custom-tooltip': { display: 'block !important' },
				}}
				onMouseOut={(e: any) => {
					return setSelectedItem(null);
				}}
				display="flex"
			>
				<svg ref={svgRef} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} width={'100%'}>
					<defs>
						<filter id="shadow">
							<feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" flood-color="red" />
						</filter>
						<filter id="shadow2">
							<feDropShadow dx="0" dy="0" stdDeviation="0.5" flood-color="green" />
						</filter>
					</defs>
					{dataGraph.map((item: any, index: number) => {
						const barWidth = (percentages[index] * viewBoxWidth) / 100;
						const currentStartPosition = startPosition;
						startPosition += barWidth;
						return (
							<rect
								key={index}
								height={viewBoxHeight}
								width={barWidth}
								x={currentStartPosition}
								fill={item.color}
								onMouseMove={(event: any) => {
									return onMouseEnter(event, item);
								}}
								strokeWidth={selectedItem === item ? 2 : 0}
								stroke={selectedItem === item ? 'none' : 'none'}
								rx={selectedItem === item ? 4 : 0}
								style={{
									cursor: 'pointer',
									...(isDisabledLink && { cursor: 'unset' }),
									...(isDisabled && { pointerEvents: 'none' }),
								}}
								onClick={() => {
									return onClick && onClick(item);
								}}
							/>
						);
					})}
				</svg>
				<Box className="custom-tooltip" ref={tooltipRef} style={{ display: 'none', position: 'absolute' }}>
					<TooltipChart item={selectedItem} indicator={indicator} />
				</Box>
			</Box>
		</Box>
	);
};

export default AnalysisHorizontalGraph;
