import { useQuery } from '@tanstack/react-query';
import { create } from 'zustand';

import { classNames, functionName } from '@carbonmaps/shared/utils/constants';

import { type SupplierContactRowData, type UpdateSupplierDataById } from './QuestionFormSuppliersTable';
import { RemoveSupplierContactById, type AddSupplierContact } from './QuestionFormSuppliersTableSelect';

type QuestionFormState = {
	supplierContacts: SupplierContactRowData[];
	setSuppliersContacts: (value: SupplierContactRowData[]) => void;
	updateSupplierContactDataById: UpdateSupplierDataById;
	addSupplierContact: AddSupplierContact;
	removeSupplierContactById: RemoveSupplierContactById;

	supplierErrorImportContacts: SupplierContactRowData[];
	addErrorSupplierImport: (value: SupplierContactRowData) => void;
};

export const useQuestionFormStore = create<QuestionFormState>((set) => {
	return {
		supplierContacts: [],
		supplierErrorImportContacts: [],
		updateSupplierContactDataById: (supplierId: string, newData: Parameters<UpdateSupplierDataById>[1]) => {
			return set((state) => {
				const supplierContacts = state.supplierContacts;
				const index = supplierContacts.findIndex((iData) => {
					return iData.id === supplierId;
				});

				if (index !== -1) {
					supplierContacts[index] = {
						...supplierContacts[index],
						...newData,
					};
				}

				return {
					supplierContacts: [...supplierContacts],
				};
			});
		},
		setSuppliersContacts: (value: SupplierContactRowData[]) => {
			return set({ supplierContacts: value });
		},
		addSupplierContact: (value: SupplierContactRowData) => {
			return set((state) => {
				return {
					supplierContacts: [...state.supplierContacts, value],
				};
			});
		},
		removeSupplierContactById: (supplierId: string) => {
			return set((state) => {
				const filtered = state.supplierContacts.filter((iData) => {
					return iData.id !== supplierId;
				});

				return {
					supplierContacts: filtered,
				};
			});
		},
		addErrorSupplierImport: (value: SupplierContactRowData) => {
			return set((state) => {
				return {
					supplierErrorImportContacts: [...state.supplierErrorImportContacts, value],
				};
			});
		},
	};
});

export const useGetQuestionFormById = ({ id }: { id: string }) => {
	return useQuery({
		queryKey: ['getQuestionFormById', { id }] as const,
		queryFn: async (context) => {
			try {
				const { id } = context.queryKey[1];
				//
				const questionForm = await new Parse.Query(classNames.QUESTION_FORM).get(id);
				return questionForm.toJSON();
			} catch (error) {
				console.log('---- getQuestionFormById action error ---------------', error);
				return Promise.reject(error);
			}
		},
	});
};

export const useGetSuppliersContacts = ({ supplierUids, enabled }: { supplierUids: string[], enabled: boolean }) => {
	return useQuery({
		queryKey: ['getSuppliersContacts', { supplierUids }] as const,
		queryFn: async (ctx) => {
			try {
				const { supplierUids } = ctx.queryKey[1];
				const results: Record<string, any> = await Parse.Cloud.run(functionName.getSuppliersContacts, { supplierUids });
				return results;
			} catch (error) {
				console.log('---- getSuppliersContacts action error ---------------', error);
				return Promise.reject(error);
			}
		},
		enabled,
	});
};

export const fetchSuppliersContacts = async ({ supplierUids }: { supplierUids: string[] }) => {
	try {
		// const { supplierUids } = ctx.queryKey[1];
		const results: Record<string, any> = await Parse.Cloud.run(functionName.getSuppliersContacts, { supplierUids });
		return results;
	} catch (error) {
		console.log('---- getSuppliersContacts action error ---------------', error);
		// return Promise.reject(error);
	}
};



export const fetchSuppliersByName = async ({ nameSuppliers }: { nameSuppliers: string[] }) => {
	try {
		const results: Record<string, any> = await Parse.Cloud.run(functionName.suppliers.getSuppliersByName, { nameSuppliers });
		return results;
	} catch (error) {
		console.log('---- nameSuppliers action error ---------------', error);
		// return Promise.reject(error);
	}
};


