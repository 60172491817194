import { css, cx } from '@emotion/css';
import MaterialEndOfLifeBlock from './MaterialEndOfLifeBlock';
import MaterialEndOfLifeTable from './MaterialEndOfLifeTable';
import RecycledPackagingBlock from './RecycledPackagingBlock';

const styles = {
	container: css({
		paddingBottom: 85,
	}),
};

const PackagingsEndOfLife = () => {
	return (
		<div className={cx('flexColumn width100 gap24', styles.container)}>
			<MaterialEndOfLifeBlock />
			<RecycledPackagingBlock />
			<MaterialEndOfLifeTable />
		</div>
	);
};

export default PackagingsEndOfLife;
