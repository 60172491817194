import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import MUILink from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { lighten } from '@mui/system/colorManipulator';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import {
	ArrowLeftRight,
	BoxIcon,
	ClipboardCheck,
	Contact2,
	Database,
	GraduationCap,
	Home,
	LayoutGrid,
	Leaf,
	LineChart,
	Megaphone,
	PackageOpen,
	Search,
} from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ReactComponent as BuildingIcon } from '@carbonmaps/media/icons/building.svg';
import { ReactComponent as Carrots } from '@carbonmaps/media/icons/carrots2.svg';
import { ReactComponent as EnIcon } from '@carbonmaps/media/icons/en.svg';
import { ReactComponent as FrIcon } from '@carbonmaps/media/icons/fr.svg';
import { ReactComponent as LogoutIcon } from '@carbonmaps/media/icons/log-out.svg';
import { ReactComponent as End } from '@carbonmaps/media/icons/name=end.svg';
import { ReactComponent as First } from '@carbonmaps/media/icons/name=first.svg';
import { ReactComponent as Middle } from '@carbonmaps/media/icons/name=middle.svg';
import { ReactComponent as OnlyOne } from '@carbonmaps/media/icons/name=one and only.svg';
import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier-small.svg';
import { ReactComponent as UserIcon } from '@carbonmaps/media/icons/user.svg';
import defaultLogo from '@carbonmaps/media/images/default-logo.png';
import { ReactComponent as CarbonMapsLogo } from '@carbonmaps/media/other/carbonmaps-logo.svg';
import { FRONT_PATH_NAMES, roleLabels } from '@carbonmaps/shared/utils/constants';
import {
	MOD_ANALYSE,
	MOD_AUDIT,
	MOD_CAMPAIGN,
	MOD_DASHBOARD,
	MOD_ECO_CONCEPTION,
	MOD_INGREDIENT,
	MOD_PACKAGING,
	MOD_PRODUCT,
	MOD_QUOTE,
	MOD_SALES,
	MOD_SUPPLIER,
	MOD_TRAIN,
	SUB_MOD_RELEASE_NOTE,
} from '@carbonmaps/shared/utils/module';
import { isContributorByAuthData } from '@carbonmaps/shared/utils/parseRole.utils';
import { getConfigFacets } from '@carbonmaps/shared/utils/utils';
import { checkAcvPermission } from '@carbonmaps/ui/actions/acv.actions';
import { getPluginMap } from '@carbonmaps/ui/utils/utils';

import Popover from '../../components/Popover';
import SidebarNav, { ItemButton } from '../../components/SidebarNav';
import useHasRoles from '../../hooks/useHasRoles';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import {
	useGetClientAuthQuery,
	useLogOutMutation,
	useUpdateSessionCompanyMutation,
} from '../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../lib/react-query/features/img/img.hooks';

import { siteColors } from '../../lib/colors';
import Beta from './Beta';
import Lock from './Lock';

type NavSubLisListItem = {
	label: string;
	link: string;
	isActive?: boolean;
	plugin?: boolean;
	isBeta?: boolean;
	external?: boolean;
	icon?: ReactNode;
};

type NavListItem = {
	icon: ReactNode;
	text: string;
	link?: string;
	external?: boolean;
	comingSoon?: boolean;
	initialCollapseOpen?: boolean;
	setFillPathOnHover?: boolean;
	subItems?: NavSubLisListItem[];
	isActive?: boolean;
	isEmpty?: boolean;
	code?: string;
};

const footerMenu = [
	{
		text: 'about-text',
		link: 'https://carbonmaps.io/en/home-en',
		external: true,
	},
	{
		text: 'blog-text',
		link: 'https://carbonmaps.io/blog',
		external: true,
	},
	{
		text: 'linkedIn-text',
		link: 'https://www.linkedin.com/company/carbon-maps/',
		external: true,
	},
];

const getBranch = (index: number, length: number) => {
	if (length > 1) {
		if (index === 0) {
			return <First css={{ position: 'absolute' }} />;
		}

		if (index === length - 1) {
			return <End css={{ position: 'absolute', top: '-14px' }} />;
		}

		return <Middle css={{ position: 'absolute', top: '-14px' }} />;
	}

	return <OnlyOne css={{ position: 'absolute' }} />;
};

type LayoutSideBarProps = {
	open: boolean;
};

const LayoutSideBar = ({ open = false }: LayoutSideBarProps) => {
	const location = useLocation();
	const { pathname } = useLocation();
	const theme = useTheme();
	const hasRoles = useHasRoles();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { t } = useTranslation();

	// ---- check acv module ---- //
	const { data: acv } = useQuery({
		queryKey: ['checkAcvPermission'],
		queryFn: checkAcvPermission,
	} as any);

	// ---- current modules for a company ---- //
	const plugins = useMemo(() => {
		return authData?.session.company?.plugins || [];
	}, [authData?.session.company]);

	// ---- all facets for company ---- //
	const configFacets = useMemo(() => {
		return getConfigFacets(authData?.session.company, false, 'Product');
	}, [authData?.session.company]);

	const navListItems = useMemo(() => {
		return [
			{
				text: getPluginMap(plugins).get(MOD_DASHBOARD)?.active ? t('Tableau de bord') : t('CAP’2ER'),
				icon: getPluginMap(plugins).get(MOD_DASHBOARD)?.active ? <Home size={20} /> : <Leaf size={20} />,
				link: getPluginMap(plugins).get(MOD_DASHBOARD)?.active ? FRONT_PATH_NAMES.home : FRONT_PATH_NAMES.cap2er,
				isActive: getPluginMap(plugins).get(MOD_DASHBOARD)?.active
					? pathname === FRONT_PATH_NAMES.home
					: FRONT_PATH_NAMES.cap2er,
				code: MOD_DASHBOARD,
			},

			...(hasRoles([roleLabels.CONTRIBUTOR]) &&
				!hasRoles([roleLabels.ADMIN, roleLabels.EDITOR, roleLabels.USER, roleLabels.SUPER_ADMIN])
				? [
					{
						text: t('supplier_page'),
						isActive: pathname === FRONT_PATH_NAMES.contributor,
						link: `${FRONT_PATH_NAMES.contributor}/${authData?.supplier}/synthesis`,
						icon: <SupplierIcon className={cx('sidebar-icon', listItemStyles.supplierIcon)} />,
						code: MOD_DASHBOARD,
					},
				]
				: []),

			...(getPluginMap(plugins).get(MOD_PRODUCT)?.show === true ||
				getPluginMap(plugins).get(MOD_INGREDIENT)?.show === true ||
				getPluginMap(plugins).get(MOD_SUPPLIER)?.show === true ||
				getPluginMap(plugins).get(MOD_PACKAGING)?.show === true
				? [
					{
						text: t('Explorer'),
						icon: <Search size={20} />,
						initialCollapseOpen: true,
						subItems: [
							...(getPluginMap(plugins).get(MOD_PRODUCT)?.show === true
								? [
									{
										label: t('products'),
										link: FRONT_PATH_NAMES.products,
										isActive: pathname.startsWith(FRONT_PATH_NAMES.products),
										icon: <BoxIcon className="sidebar-icon-lucide" size={20} color={theme.palette.grey[700]} />,
										plugin: getPluginMap(plugins).get(MOD_PRODUCT),
									},
								]
								: []),
							...(getPluginMap(plugins).get(MOD_INGREDIENT)?.show === true
								? [
									{
										label: t('ingredients'),
										link: FRONT_PATH_NAMES.ingredients,
										isActive: pathname.startsWith(FRONT_PATH_NAMES.ingredients),
										// isActive: true,
										icon: <Carrots className={cx('sidebar-icon-lucide' /* , listItemStyles.supplierIcon */)} />,
										plugin: getPluginMap(plugins).get(MOD_INGREDIENT),
									},
								]
								: []),
							...(getPluginMap(plugins).get(MOD_SUPPLIER)?.show === true
								? [
									{
										label: t('Fournisseurs'),
										link: FRONT_PATH_NAMES.suppliers.general,
										isActive: pathname.startsWith('/suppliers'),
										icon: <SupplierIcon className={cx('sidebar-icon', listItemStyles.supplierIcon)} />,
										plugin: getPluginMap(plugins).get(MOD_SUPPLIER),
									},
								]
								: []),
							...(getPluginMap(plugins).get(MOD_PACKAGING)?.show === true
								? [
									{
										label: t('Emballages'),
										link: FRONT_PATH_NAMES.packagings.general,
										isActive: pathname.startsWith('/packagings'),
										icon: (
											<PackageOpen
												className={cx('sidebar-icon-lucide')}
												size={20}
												color={theme.palette.grey[700]}
											/>
										),
										plugin: getPluginMap(plugins).get(MOD_PACKAGING),
									},
								]
								: []),
						],
					},
				]
				: []),

			...(getPluginMap(plugins).get(MOD_ANALYSE)?.show === true
				? [
					{
						icon: <LayoutGrid size={20} strokeWidth={2.05} />,
						text: t('Analyser'),
						initialCollapseOpen: location.pathname.startsWith(FRONT_PATH_NAMES.analyse),
						subItems: configFacets?.map((e: any) => {
							return {
								label: e.label,
								link: `${FRONT_PATH_NAMES.analyse}/${e.field}`,
								isActive: location.pathname.startsWith(`${FRONT_PATH_NAMES.analyse}/${e.field}`),
								plugin: getPluginMap(plugins).get(MOD_ANALYSE),
							};
						}),
						isEmpty: true,
					},
				]
				: []),

			...(getPluginMap(plugins).get(MOD_CAMPAIGN)?.show === true
				? [
					{
						text: t('Collecter'),
						icon: <Database size={20} />,
						subItems: [
							{
								label: t('Campagnes de collecte'),
								link: `${FRONT_PATH_NAMES.questionForms}/general`,
								isActive: pathname.startsWith(FRONT_PATH_NAMES.questionForms),
								isBeta: false,
								plugin: getPluginMap(plugins).get(MOD_CAMPAIGN),
							},
						],
					},
				]
				: []),

			...(getPluginMap(plugins).get(MOD_SALES)?.show === true
				? [{
					text: t('Valoriser'),
					icon: <Megaphone size={20} />,
					initialCollapseOpen: location.pathname.startsWith(FRONT_PATH_NAMES.sales),
					subItems: [
						{
							label: t('Rapports client'),
							link: FRONT_PATH_NAMES.sales,
							isActive: pathname.startsWith(FRONT_PATH_NAMES.sales),
							plugin: getPluginMap(plugins).get(MOD_SALES), //todo add mod client
							icon: <Contact2 size={20} />,
						},
					],
				},
				]
				: []),
			...(hasRoles([roleLabels.EDITOR, roleLabels.ADMIN]) &&
				(getPluginMap(plugins).get(MOD_ECO_CONCEPTION)?.show === true ||
					getPluginMap(plugins).get(MOD_QUOTE)?.show === true)
				? [
					{
						text: t('Simuler'),
						icon: <LineChart size={20} />,
						subItems: [
							...(getPluginMap(plugins).get(MOD_ECO_CONCEPTION)?.show === true
								? [
									{
										label: t('Éco-conception'),
										link: FRONT_PATH_NAMES.simulation,
										isActive: pathname.startsWith(FRONT_PATH_NAMES.simulation),
										isBeta: false,
										plugin: getPluginMap(plugins).get(MOD_ECO_CONCEPTION),
									},
								]
								: []),

							...(getPluginMap(plugins).get(MOD_QUOTE)?.show === true
								? [
									{
										label: t('Devis carbone'),
										link: FRONT_PATH_NAMES.quote,
										isActive: pathname.startsWith(FRONT_PATH_NAMES.quote),
										isBeta: false,
										plugin: getPluginMap(plugins).get(MOD_QUOTE),
									},
								]
								: []),
						],
					},
				]
				: []),

			...(getPluginMap(plugins).get(MOD_AUDIT)?.show === true
				? [
					{
						text: t('Auditer'),
						icon: <ClipboardCheck size={20} />,
						initialCollapseOpen: location.pathname.startsWith(FRONT_PATH_NAMES.audit),
						subItems: [
							{
								label: t('Jeu de données'),
								link: FRONT_PATH_NAMES.audit,
								isActive:
									pathname.startsWith(FRONT_PATH_NAMES.audit) &&
									!pathname.includes('acv') &&
									!pathname.includes('modelization'),
								plugin: getPluginMap(plugins).get(MOD_AUDIT),
							},

							{
								label: t('Modelizations'),
								link: `${FRONT_PATH_NAMES.audit}/modelization`,
								isActive: pathname.startsWith(FRONT_PATH_NAMES.audit) && pathname.includes('modelization'),
								plugin: getPluginMap(plugins).get(MOD_AUDIT),
							},

							// remove for this sprint
							...(acv
								? [
									{
										label: t('ACV spécifiques'),
										link: `${FRONT_PATH_NAMES.audit}/acv`,
										isActive: pathname.startsWith(`${FRONT_PATH_NAMES.audit}/acv`),
										plugin: getPluginMap(plugins).get(MOD_AUDIT),

										// glossary: t('acv-specifique'),
									},
								]
								: []),
						],
					},
				]
				: []),

			...(getPluginMap(plugins).get(MOD_TRAIN)?.show === true
				? [
					{
						text: t('Se former'),
						icon: <GraduationCap size={20} />,
						subItems: [
							{
								// icon: <HelpCircle size={20} />,
								label: t('Fiches filières'),
								// link: 'https://carbonmaps.notion.site/Fiches-fili-res-4f09bd1d0c014330b08b00f316a20d1f',
								link: FRONT_PATH_NAMES.sectorSheet,
								// external: true,
								isActive: pathname.startsWith(FRONT_PATH_NAMES.sectorSheet),
								plugin: getPluginMap(plugins).get(MOD_TRAIN),
							},
							// {
							// 	// icon: <FileText size={20} />,
							// 	label: 'Glossaire',
							// 	link: FRONT_PATH_NAMES.glossary,
							// 	isActive: pathname.startsWith(FRONT_PATH_NAMES.glossary),
							// },
							// !=========
							{
								// icon: <FileText size={20} />,
								label: t('Documentation'),
								link: FRONT_PATH_NAMES.documentation,
								isActive: pathname.startsWith(FRONT_PATH_NAMES.documentation),
								plugin: getPluginMap(plugins).get(MOD_TRAIN),
							},
							// {
							// 	// icon: <FileText size={20} />,
							// 	label: 'FAQ',
							// 	link: FRONT_PATH_NAMES.faq,
							// 	isActive: pathname.startsWith(FRONT_PATH_NAMES.faq),
							// },
							{
								// icon: <FileText size={20} />,
								label: t('Release Notes'),
								link: FRONT_PATH_NAMES.releaseNotes,
								isActive: pathname.startsWith(FRONT_PATH_NAMES.releaseNotes),
								plugin: { active: true, code: SUB_MOD_RELEASE_NOTE },
							},
						],
					},
				]
				: []),
			// {
			// 	icon: <LifeBuoy size={20} />,
			// 	text: t('Aide'),
			// 	link: 'mailto:support@carbonmaps.io',
			// },
		];
	}, [t, pathname, theme.palette.grey, location.pathname, configFacets, hasRoles, plugins, acv, authData]);

	return (
		<SidebarNav
			header={<Header />}
			footer={<Footer />}
			content={navListItems.map((item: any, index) => {
				return <NavList key={index} {...item} />;
			})}
			open={open}
		/>
	);
};

export default LayoutSideBar;

//--------------------------------------------------------------------------------------//
//                                        Header                                        //
//--------------------------------------------------------------------------------------//

const headerStyles = {
	triggerContainer: css({
		// width: 210,
		// marginLeft: 35,
		// width: 220,
		marginLeft: 20,
		marginRight: 20,
		marginTop: 16,
		marginBottom: 8,
		cursor: 'pointer',
		borderRadius: 6,
		// background: 'red',
		// padding: '2px',
		padding: '16px',
		// marginLeft: '-16px',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),

	triggerContent: css({
		// width: 80,
		// height: 80,
		width: 48,
		height: 48,
		borderRadius: 4,
		overflow: 'hidden',
		backgroundColor: siteColors.common.white,
		// backgroundColor: 'blue',
		boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.50)',
	}),
	img: css({
		objectFit: 'cover',
	}),
	skeleton: css({
		width: '100%',
		height: '100%',
	}),

	triggerInnerContent: css({
		marginLeft: 12,
		// background: 'red',
		flex: 1,
		overflow: 'hidden',
		// flexGrow: 1,
	}),

	title: css({
		textTransform: 'none !important',
		textAlign: 'left',
		// width: 90,
		// width: '100%!important',
		whiteSpace: 'nowrap',
		// textOverflow: 'hidden',
		// overflow: 'hidden',
		// background: 'blue',
	} as any),

	subtitle: css({
		// width: 100,
		whiteSpace: 'nowrap',
	}),
	p1Skeleton: css({
		width: 90,
		height: 16,
	}),
	p2Skeleton: css({
		width: 100,
		height: 12,
	}),

	listContainer: css({
		width: 250,
	}),

	enIcon: css({
		width: '24px',
		height: '16px',
		'& svg': {
			height: '100%',
			width: '100%',
		},
	}),
	frIcon: css({
		width: '24px',
		height: '16px',
	}),
};

export const FlagFr = () => {
	return (
		<div className={headerStyles.frIcon}>
			<FrIcon />
		</div>
	);
};

export const FlagEn = () => {
	return (
		<div className={headerStyles.enIcon}>
			<EnIcon />
		</div>
	);
};

const Header = () => {
	const hasRoles = useHasRoles();

	const {
		result: { data: authData, isLoading: isGetClientAuthLoading },
	} = useGetClientAuthQuery();

	const analytics = useJuneTrack();

	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [, setOpen] = useState(false);

	const onNavigate = (url: string) => {
		setOpen(false);
		navigate(url);
	};

	const {
		result: { mutate: updateSessionCompany },
	} = useUpdateSessionCompanyMutation({
		onSuccess(/* data, variables, context */) {
			navigate(FRONT_PATH_NAMES.home);
		},
	});

	const {
		result: { mutate: logOut },
	} = useLogOutMutation();

	const activeCompanies = _.get(authData, 'user.companies')?.filter((e) => {
		return _.get(e, 'company.deleted') !== true;
	});

	const listActions = [
		...(activeCompanies && _.isArray(activeCompanies) && activeCompanies.length > 1
			? [
				{
					icon: <ArrowLeftRight color={siteColors.grey700} />,
					// eslint-disable-next-line quotes
					title: t('switch-organisation'),
					onClick: () => {
						// do nothing
					},
					subMenu: activeCompanies.map((e) => {
						let company = {
							logo: '---',
							code: '---',
							name: '---',
							objectId: '---',
						};

						if (_.isObject(e.company)) {
							company = e.company;
						}

						return {
							// label: `Company: ${e.company.objectId}`,
							icon: <CompanyLogo logo={company.logo || '---'} />,
							label: company.name || company.code || '---',
							onClick: () => {
								updateSessionCompany({ companyId: e.company.objectId || '---' });
							},
						};
					}),
				},
			]
			: []),

		{
			icon: <UserIcon />,
			title: t('my-compte'),
			onClick: () => {
				return onNavigate(`${FRONT_PATH_NAMES.userSettings}`);
			},
		},
		...(hasRoles(['administrateur'])
			? [
				{
					icon: <BuildingIcon />,
					title: t('entreprise'),
					onClick: () => {
						return onNavigate(`${FRONT_PATH_NAMES.managerSettings}`);
					},
				},
			]
			: []),

		{
			icon: <LogoutIcon />,
			title: t('logout'),
			onClick: () => {
				return logOut();
			},
		},
		{
			icon: i18n?.language === 'en' ? <FlagEn /> : <FlagFr />,
			title: t('switch-language'),
			onClick: () => {
				// do nothing
			},
			subMenu: [
				{
					label: t('fr'),
					icon: <FlagFr />,
					onClick: () => {
						analytics.track('General Switch Language', { language: 'fr' }, { context: { category: 'General' } });
						i18n.changeLanguage('fr');
					},
				},
				{
					label: t('en'),
					icon: <FlagEn />,
					onClick: () => {
						i18n.changeLanguage('en');
					},
				},
			],
		},
	];

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: authData?.session.company?.logo || '' },
	});

	return (
		<Popover
			anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
			trigger={
				<div className={cx('flexRow alignCenter', headerStyles.triggerContainer)}>
					<div className={cx('flexRow alignCenter justifyCenter', headerStyles.triggerContent)}>
						{!isGetClientAuthLoading ? (
							<Box
								component="img"
								width="100%"
								className={headerStyles.img}
								src={authData?.session.company?.logo ? logoUrl : defaultLogo}
							/>
						) : (
							<Skeleton variant="rectangular" className={headerStyles.skeleton} />
						)}
					</div>
					<div className={headerStyles.triggerInnerContent}>
						{!isGetClientAuthLoading ? (
							<>
								<Typography
									variant="h3"
									className={headerStyles.title}
									noWrap
								// sx={{ /* width: '100%', */ bgcolor: 'blue' /* , flex: 1 */ }}
								>
									{authData?.session.company?.name}
								</Typography>
								<Typography variant="caption" display="block" className={cx('grey900', headerStyles.subtitle)} noWrap>
									{`${authData?.user.firstName ?? ''} ${authData?.user.lastName ?? ''}`}
									{/* fzefzfezfezfezfzefezfezfezfezfzefzefzefzezfefzefze */}
								</Typography>
							</>
						) : (
							<>
								<Skeleton variant="text" className={headerStyles.p1Skeleton} />
								<Skeleton variant="text" className={headerStyles.p2Skeleton} />
							</>
						)}
					</div>
				</div>
			}
			renderContent={({ close }) => {
				return (
					<List className={headerStyles.listContainer}>
						{listActions.map((action, index) => {
							return (
								<Fragment key={action.title + index}>
									{action.subMenu ? (
										<Popover
											anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
											trigger={
												<ListItemButton
													onClick={() => {
														action.onClick();
														close();
													}}
												>
													<ListItemIcon>{action.icon}</ListItemIcon>
													<ListItemText primary={action.title} />
												</ListItemButton>
											}
											content={action.subMenu.map((e, index) => {
												return (
													<ListItemButton
														key={index}
														onClick={() => {
															// action.onClick();
															e.onClick();
															close();
														}}
													>
														{/* <ListItemIcon>{action.icon}</ListItemIcon> */}
														{e.icon}
														<ListItemText sx={{ marginLeft: '8px' }} primary={e.label} />
													</ListItemButton>
												);
											})}
										></Popover>
									) : (
										<ListItemButton
											onClick={() => {
												action.onClick();
												close();
											}}
										>
											<ListItemIcon>{action.icon}</ListItemIcon>
											<ListItemText primary={action.title} />
										</ListItemButton>
									)}
									{index < listActions.length - 1 && <Divider />}
								</Fragment>
							);
						})}
					</List>
				);
			}}
		/>
	);
};

//--------------------------------------------------------------------------------------//
//                                        Footer                                        //
//--------------------------------------------------------------------------------------//

const footerStyles = {
	container: css({
		marginTop: 28,
		marginBottom: 16,
		marginLeft: 35,
	}),
	muiLink: css({
		marginBottom: '16px !important',
	}),
	logo: css({
		width: 129,
		height: 27,
		'& path': { fill: siteColors.grey700 },
	}),

	beforeText: css({
		fontSize: '0.75rem !important',
		marginLeft: '4px !important',
		marginRight: '4px !important',
	}),
	link: css({
		fontSize: '0.75rem !important',
		textDecoration: 'none !important',
	}),
};

const Footer = () => {
	const { t } = useTranslation();
	return (
		<div className={footerStyles.container}>
			<MUILink component={Link} to="/" display="block" className={footerStyles.muiLink}>
				<CarbonMapsLogo className={footerStyles.logo} />
			</MUILink>
			<Box>
				{footerMenu.map((e, index) => {
					return (
						<Fragment key={index}>
							{index > 0 && (
								<Typography component="span" className={cx('grey700', footerStyles.beforeText)}>
									&bull;
								</Typography>
							)}
							<MUILink
								component={Link}
								target={e.external ? '_blank' : ''}
								to={e.link}
								variant="body1"
								className={cx('grey700', footerStyles.link)}
							>
								{t(e.text)}
							</MUILink>
						</Fragment>
					);
				})}
			</Box>
		</div>
	);
};

// NavList

const NavList = ({
	text,
	icon,
	initialCollapseOpen,
	subItems,
	comingSoon,
	external,
	link,
	setFillPathOnHover,
	isActive,
	// isEmpty,
	code,
}: NavListItem) => {
	const findOut = useCallback(() => {
		const ok = subItems?.some((e) => {
			return e.isActive;
		});

		return initialCollapseOpen ?? Boolean(ok);
	}, [initialCollapseOpen, subItems]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const isContributor = useMemo(() => {
		return isContributorByAuthData(authData);
	}, [authData?.session.company]);

	const [openCollapse, toggleCollapse] = useToggle(findOut());

	const handleClick = () => {
		if (subItems) {
			toggleCollapse();
		}
	};

	useEffect(() => {
		toggleCollapse(findOut());
	}, [findOut, toggleCollapse]);

	return (
		<>
			{/* AAAAAAAA */}
			<ItemButton
				key={text}
				icon={icon}
				text={text}
				active={isActive ?? false}
				link={link}
				onClick={handleClick}
				external={external}
				comingSoon={comingSoon}
				setFillPathOnHover={setFillPathOnHover}
				collapsible={subItems && !_.isEmpty(subItems)}
				open={openCollapse}
				isEmpty={subItems && _.isEmpty(subItems)}
				isContributor={isContributor}
				code={code}
			/>
			{subItems ? (
				<Collapse in={openCollapse} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{subItems.map((e, index, array) => {
							return (
								<Fragment key={index}>
									<ListItem
										link={e.link}
										index={index}
										arrayLength={array.length}
										label={e.label}
										toggleCollapse={toggleCollapse}
										openCollapse={openCollapse}
										isActive={e.isActive ?? false}
										isBeta={e.isBeta ?? false}
										plugin={e?.plugin}
										icon={e.icon}
									/>
									{/* {e.icon ? <ListItemIcon>{e.icon}</ListItemIcon> : null} */}
								</Fragment>
							);
						})}
					</List>
				</Collapse>
			) : null}
		</>
	);
};

// ListItem
type ListItemProps = {
	link: string;
	index: number;
	arrayLength: number;
	label: string;
	toggleCollapse: () => void;
	openCollapse: boolean;
	isActive: boolean;
	isBeta?: boolean;
	icon?: ReactNode;
	plugin?: any;
};

const listItemStyles = {
	label: css({
		// backgroundColor: 'red',
		marginLeft: ' 16px !important',
		padding: '4px 8px !important',
		borderRadius: 12,
		'&:hover .sidebar-icon': {
			path: {
				fill: siteColors.primary,
			},
		},
		'&:hover .sidebar-icon-lucide': {
			stroke: siteColors.primary,
			'& path': {
				stroke: siteColors.primary,
			},
		},
	}),
	icon: css({
		marginRight: '6px',
	}),
	supplierIcon: css({
		path: {
			fill: siteColors.grey700,
		},
	}),
	activeIcon: css({
		'& .sidebar-icon': {
			path: {
				fill: siteColors.primary,
			},
		},
		'& .sidebar-icon-lucide': {
			stroke: siteColors.primary,
			'& path': {
				stroke: siteColors.primary,
			},
		},
	}),
	betaTextContainer: css({
		padding: '0px 8px',
		alignItems: 'center',
		borderRadius: '8px',
		border: '1px solid rgba(0, 0, 0, 0.10)',
		height: '22px',
		fontSize: '12px',
		fontFamily: 'Inter',
	}),
};

// ListItem
type LabeltemProps = {
	label: string;
	isActive: boolean;
	icon?: ReactNode;
	plugin?: any;
};

const LabelItem = ({ isActive, icon, label, plugin }: LabeltemProps) => {
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const isContributor = useMemo(() => {
		return isContributorByAuthData(authData);
	}, [authData?.session.company]);

	return (
		<Typography
			className={cx('branch-label', 'flexRow', listItemStyles.label, isActive ? listItemStyles.activeIcon : '')}
		>
			{icon ? <div className={cx('flexRow', listItemStyles.icon)}>{icon}</div> : null}
			{label}
			{plugin && (plugin?.active === false || (isContributor && plugin?.code !== SUB_MOD_RELEASE_NOTE)) && (
				<>
					&nbsp;&nbsp;
					<Lock />
				</>
			)}
		</Typography>
	);
};

const ListItem = ({ link, index, arrayLength, label, isActive, isBeta, plugin, icon }: ListItemProps) => {
	const theme = useTheme();
	const branch = getBranch(index, arrayLength);
	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const isContributor = useMemo(() => {
		return isContributorByAuthData(authData);
	}, [authData?.session.company]);

	return (
		<ListItemButton
			key={index}
			{...(link &&
				plugin?.active === true && {
				LinkComponent: Link,
				to: link,
			})}
			sx={{
				// bgcolor: 'red',
				pl: '42px',
				py: 0,
				position: 'relative',
				marginBottom: '4px',
				'&:hover': {
					bgcolor: 'unset',
					'.branch-label': {
						color: siteColors.primary,
						bgcolor: siteColors.primary10,
					},
				},
				'& .branch-label': {
					bgcolor: isActive ? siteColors.primary10 /* lighten(theme.palette.primary.main, 0.9) */ : 'none',
					color: isActive ? theme.palette.primary.main : 'auto',
				},
			}}
			disableRipple
			disableTouchRipple
		>
			{branch}

			{plugin?.active === false || (isContributor && plugin?.code !== SUB_MOD_RELEASE_NOTE) ? (
				<Popover
					disableScrollLock
					anchorOrigin={{
						horizontal: 'left',
						vertical: 'bottom',
					}}
					trigger={
						<div>
							<LabelItem isActive={isActive} icon={icon} label={label} plugin={plugin} />
						</div>
					}
					renderContent={({ close }) => {
						return (
							<MUILink
								component={Link}
								to="mailto:support@carbonmaps.io"
								display="block"
								className={headerStyles.triggerContainer}
							>
								{t('active-module-text')}
							</MUILink>
						);
					}}
				/>
			) : (
				<LabelItem isActive={isActive} icon={icon} label={label} plugin={plugin} />
			)}

			{isBeta && (
				<>
					&nbsp;&nbsp;
					<Beta />
				</>
			)}
		</ListItemButton>
	);
};

const CompanyLogo = ({ logo }: { logo: string }) => {
	// const theme = useTheme();

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: logo || '' },
	});

	return (
		<img
			// src={defaultLogo}
			src={logo ? logoUrl : defaultLogo}
			css={{
				width: '32px',
				height: '32px',
				borderRadius: '4px',
				// border: `1px solid ${theme.palette.grey[500]}`,
				boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.50)',
				objectFit: 'contain',
			}}
			alt=""
		/>
	);
};
