import { css, cx } from '@emotion/css';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar } from '@mui/material';
import { siteColors } from '../../lib/colors';
import Typography from '../Typography';
const styles = {
	container: css({
		background: '#fff',
		border: `1px solid ${siteColors.status.info}`,
		boxShadow: `inset 4px 0px 0px ${siteColors.status.info}`,
		borderRadius: '0px 8px 8px 0px',
		display: 'flex',
		alignItems: 'center',
		gap: 24,
		padding: 24,
		fontWeight: 500,
		fontSize: 16,
		justifyContent: 'space-between',
	}),
};

type FlashMessageProps = {
	open: boolean;
	onClose: (e?: any) => void;
	message: string;
	icon?: React.ReactNode;
};
const FlashMessage = ({ open, onClose, icon, message }: FlashMessageProps) => {
	return (
		<Snackbar
			open={open}
			autoHideDuration={3000}
			onClose={onClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			<div
				className={cx('flexRow alignCenter MuiPaper-root MuiAlert-root spaceBetween', styles.container)}
				role="alert"
			>
				<div className="flexRow alignCenter nowrap gap20">
					{icon}
					<Typography>{message}</Typography>
				</div>
				<IconButton
					onClick={onClose}
					color="inherit"
					aria-label="visible"
					css={{
						background: 'transparent',
						':hover': {
							background: 'transparent',
						},
						'& path': {
							fill: 'black',
						},
					}}
				>
					<CloseIcon />
				</IconButton>
			</div>
		</Snackbar>
	);
};

export default FlashMessage;
