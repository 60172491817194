import { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { eachOfLimit } from 'async';

import { ReactComponent as BlueCheckOK } from '@carbonmaps/media/icons/bluecheckok.svg';
import {
	FILTER_NAME_ECO_CONCEVABLE,
	FILTER_NAME_TYPE_MODELIZATION_ING,
	FOLDER_NAME_ECO_CONCEVABLE,
	FOLDER_NAME_TYPE_MODELIZATION_ING,
} from '@carbonmaps/shared/utils/constants';
import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getCategoriesProduct } from '@carbonmaps/ui/actions/product.actions';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { IFilterFolder } from '../../../components/Filter';
import { Option } from '../../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../../components/layout/list/FilterContainer';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../lib/react-query/features/auth/auth.hooks';
import { exportProductAction } from '../../../lib/react-query/features/export/product.actions';
import { getLabelNodeFacet, getLabelValueTranslate } from '../../../utils/utils';

const suppliersFolderLabel = 'Fournisseur';

const ModelizationFilter = () => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();

	// eslint-disable-next-line react-hooks/exhaustive-deps

	// translation
	const { t } = useTranslation();

	const [isExportLoading, setExportLoading] = useState(false);

	// custom facets suppliers
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// filters values
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	// -------------------------------------------------------------------------------------- //
	// -------------------------------- Use query ------------------------------------------- //
	// -------------------------------------------------------------------------------------- //
	// ---- fetch suppliers filter ---- //
	// const { data: suppliersData, isLoading: isSUppliersLoading } = useQuery({
	// 	queryKey: ['getAllSuppliersForCompany', { itemType: 'product', isFilter: true }],
	// 	queryFn: getCompanySuppliers,
	// });

	// ---- fetch facets ---- //
	const { data: facets, isLoading: isFacetsLoading } = useQuery({
		queryKey: [
			'getCategory',
			{
				input: searchQueryParams.input,
				facetFilters: searchQueryParams?.facetFilters || [],
				withEcoConcevable: true,
				period: selectedYear,
			},
		],
		queryFn: getCategoriesProduct,
	});

	useEffect(() => {
		const searchParams = searchQueryParams?.facetFilters ?? [];
		const supplierIdsParams = searchQueryParams?.supplierIds ?? [];

		//	if there are filters in the url
		if ((searchParams && searchParams.length) || (supplierIdsParams && supplierIdsParams.length)) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			// for facet
			if (searchParams && searchParams.length) {
				searchParams.forEach((item: any) => {
					const arrayValues = item.values.map((v: any) => {
						const value = {
							facetPath: item.path,
							value: v,
							folderLabel:
								item.path === FILTER_NAME_TYPE_MODELIZATION_ING
									? t(FOLDER_NAME_TYPE_MODELIZATION_ING, {
										recipe: t('recipe'),
										recipe_lowercase: toLowerCase(t('recipe')),
									})
									: item?.folderLabel ?? item.path,
							label:
								item.path === FILTER_NAME_ECO_CONCEVABLE || item.path === FILTER_NAME_TYPE_MODELIZATION_ING
									? getLabelValueTranslate(item.path, v, t)
									: getLabelValue(item.path, v),
							isFacetBucket: true,
							type: typeof v,
							global: item.global === true ? true : false,
							itemType: item?.itemType?.toLowerCase() || 'product',
						};

						currentSelectedValues.push(value);
						return value;
					});

					currentAppliedValuesFolders.push({
						isFacet: true,
						label:
							t(item?.folderLabel, {
								products: t('products'),
								recipe: t('recipe'),
								recipe_lowercase: toLowerCase(t('recipe')),
								ingredient: t('ingredient'),
								an_ingredient: t('an_ingredient'),
								ingredient_lowercase: toLowerCase(t('ingredient')),
								product: t('product'),
								of_product: t('of_product'),
								ingredients: t('ingredients'),
							}) ?? item.path,
						options: arrayValues,
						showCount: true,
					});
				});
			}

			//for supplier
			// if (supplierIdsParams && supplierIdsParams.length) {
			// 	if (!suppliersData) return;
			// 	const values = suppliersData
			// 		.filter((item: any) => {
			// 			return supplierIdsParams.includes(item.id);
			// 		})
			// 		.map((sup) => {
			// 			return {
			// 				label: sup.name,
			// 				value: sup.id,
			// 				folderLabel: suppliersFolderLabel,
			// 			};
			// 		});
			// 	const suppliersFolder: IFilterFolder = {
			// 		label: suppliersFolderLabel,
			// 		isFacet: false,
			// 		showCount: false,
			// 		options: values,
			// 	};
			// 	currentAppliedValuesFolders.push(suppliersFolder); // each one filter
			// 	currentSelectedValues.push(...values); // in the base filter
			// }

			setSelectedValues(currentSelectedValues);

			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchQueryParams, t]);

	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facets) return;
			const otherFacetFilterFolders: IFilterFolder[] = [];
			let N2FacetFolder: IFilterFolder;

			for await (const entry of Object.entries(facets.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;
				const { label, type, global, itemType } = getFacetConfig(key, authData?.session.company) ?? {};

				let facetLabel = label || getFacetLabel(key, authData?.session.company);

				if (key === FILTER_NAME_TYPE_MODELIZATION_ING || key === FILTER_NAME_ECO_CONCEVABLE) {
					facetLabel = t(facetLabel, {
						recipe: t('recipe'),
						recipe_lowercase: toLowerCase(t('recipe')),
					});
				}

				const currentFacetFolder: IFilterFolder = {
					label: facetLabel,
					options: [],
					isFacet: true,
					path: key,
					showCount: true,
					global,
					customIcon:
						key === 'tagAdvancedModelization' ? <BlueCheckOK css={{ 'path:first-of-type': { fill: 'grey' } }} /> : null,
				};

				await eachOfLimit(buckets as any[], 100, async (bucket, k) => {
					if (
						(key !== 'tagAdvancedModelization' && key !== FILTER_NAME_ECO_CONCEVABLE && !bucket.count) ||
						(key === FILTER_NAME_TYPE_MODELIZATION_ING && !bucket.count)
					) {
						return;
					}

					currentFacetFolder.options.push({
						value: bucket._id,
						label: [FILTER_NAME_ECO_CONCEVABLE, FILTER_NAME_TYPE_MODELIZATION_ING].includes(key)
							? getLabelValueTranslate(key, bucket._id, t)
							: getLabelValue(key, bucket._id),
						labelNode: [
							'tagAdvanced',
							'tagAdvancedModelization',
							FILTER_NAME_TYPE_MODELIZATION_ING,
							FILTER_NAME_ECO_CONCEVABLE,
						].includes(key)
							? getLabelNodeFacet(key, bucket._id, t)
							: undefined,
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: type ?? 'hardcoded-undefined-facet-type',
						global,
						itemType,
					});
					return;
				});

				if (currentFacetFolder.path === FILTER_NAME_ECO_CONCEVABLE) {
					currentFacetFolder.label = t(FOLDER_NAME_ECO_CONCEVABLE);
				}

				if (currentFacetFolder.path === FILTER_NAME_TYPE_MODELIZATION_ING) {
					currentFacetFolder.label = t(FOLDER_NAME_TYPE_MODELIZATION_ING, {
						recipe: t('recipe'),
						recipe_lowercase: toLowerCase(t('recipe')),
					});
				}

				if (currentFacetFolder.path === 'tagAdvancedModelization') {
					N2FacetFolder = currentFacetFolder;
				} else {
					otherFacetFilterFolders.push(currentFacetFolder);
				}
			}

			setFilterFolders((folders) => {
				const notFacetFolders = folders.filter((e) => {
					return !e.isFacet;
				});

				return [...(N2FacetFolder ? [N2FacetFolder] : []), ...notFacetFolders, ...otherFacetFilterFolders];
			});
		};

		asyncWrapper();
	}, [facets, authData?.session.company, t]);

	// ---- select rows selected in store ---- //
	const { selectedRows } = useRowTableSelection();

	// ---- handle export  data ---- //
	const handleExport = useCallback(
		async ({ exportFormat }: { exportFormat: string }) => {
			try {
				setExportLoading(true);
				await exportProductAction(
					{
						ids: selectedRows.map((e: any) => {
							return e.original.objectId;
						}),
						facetFilters: searchQueryParams.facetFilters,
						supplierIds: searchQueryParams?.supplierIds ?? [],
						input: searchQueryParams.input ?? '',
						format: exportFormat,
						companyCode: authData?.session.company?.name,
						selectedYear: selectedYear as any,
					},
					t,
				);
				setExportLoading(false);
			} catch (error) {
				setExportLoading(false);
				console.error(error);
			}
		},
		[
			authData?.session.company?.code,
			searchQueryParams.facetFilters,
			searchQueryParams.input,
			searchQueryParams?.supplierIds,
			selectedRows,
		],
	);
	return (
		<FilterContainer
			skeleton={isFacetsLoading}
			isLoading={false}
			appliedValuesByFolders={appliedValuesByFolders}
			setAppliedValuesByFolders={setAppliedValuesByFolders}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			folders={filterFolders}
			setSearchQueryParams={setSearchQueryParams}
			suppliersFolderLabel={suppliersFolderLabel}
			selectedRow={selectedRows}
			//handleExport={handleExport}
			isTableLoading={false}
			//isExportLoading={isExportLoading}
			//count={data || null}
			enableActionDelete={false}
			searchPlaceholder={t('search_for_product_placeholder', {
				product: toLowerCase(t('product')),
				of_product: t('of_product'),
			})}
			withMore={false}
		/>
	);
};

export default ModelizationFilter;
