import { useState } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import _ from 'lodash';
import { AlertCircle, Check } from 'lucide-react';
import { useForm } from 'react-hook-form';

import { ScenarioFormInput, ScenarioFormValidationSchema } from '@carbonmaps/shared/validations/simulation.validations';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';
import AlertStatic from '../../../components/Toast/AlertStatic';

import SearchAutocomplete from '../../../components/SearchAutocompleteV2';
import FormSelect from '../../../components/form/fields/FormSelect';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';

const styles = {
	title: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
		color: siteColors.primary,
		margin: 0,
	}) as any,
	subTitle: css({
		marginTop: '0px',
		fontSize: '14px !important',
		fontWeight: '400 !important',
		width: '400px',
	}) as any,

	formContainer: css({
		maxWidth: 500,
		gap: '24px',
		'.input-container': {
			width: '100%',
		},
		marginBottom: '16px',
	}) as any,

	header: css({
		gap: '16px',
	}) as any,
	label1: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
		marginBottom: '8px',
	}) as any,
	label: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
	}) as any,
	searchClassName: css({
		width: '100%',
		'& .MuiOutlinedInput-root': {
			width: '100% !important',
			height: '42px!important',
			borderRadius: '11px !important',
		},
		'& svg': {
			stroke: '#c4c2c2',
		},
	}) as any,
	netWeightContainer: css({
		'& .MuiInputBase-root': {
			width: '120px !important',
		},
	}),

	chipStyles: css({
		textTransform: 'capitalize',
		background: 'var(--color-background)!important',
		uppercase: 'none',
		borderRadius: '8px !important',
		border: '1px solid rgba(0, 0, 0, 0.10)!important',
		span: {
			color: cn(siteColors.grey900, '!important'),
			fontSize: '1rem !important',
			padding: '8px 8px !important',
		},
	}),
	button: css({
		background: '#fff !important',
		minWidth: '36px !important',
		width: '36px !important',
		height: '36px !important',
		padding: '8px !important',
		border: cn('1px solid', siteColors.grey500, '!important'),
		borderRadius: '8px',
	}),
	actionContainer: css({
		padding: '0px 24px 16px 24px !important',
	}),
	confirmButton: css({
		fontSize: '16px !important',
		fontWeight: 600,
		textTransform: 'none !important',
		gap: 10,
		color: '#C74600 !important',
		background: '#FFE4CB !important',
		'& :hover': {
			background: '#FFCA99 !important',
		},
		'& .Mui-disabled': {
			// color: '#C74600',
			color: '#C74600 !important',
			background: '#FFE4CB !important',
		},
		borderRadius: '8px !important',
	} as any),
	dialogTitle: css({
		textTransform: 'capitalize',
	}),

	selectOption: css({
		// textTransform: 'capitalize',
		width: '100% !important',
		height: '42px !important',
		borderRadius: '10px !important',
		border: cn('1px solid', siteColors.primary),
		padding: '10.5px 10px',
		color: siteColors.text,
		cursor: 'pointer',
		opacity: '23.1',
	}),
};

const ScenarioForm = ({
	selectedOption,
	setSelectedOption,
	isLoading,
	isSuccess,
	isError,
	onSaveData,
	simulation,
	title,
	setSearch,
	options,
	message,
	onClose,
	onlyNetWeight,
}: any) => {
	const {
		register,
		control,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<ScenarioFormInput>({
		resolver: zodResolver(ScenarioFormValidationSchema),
		defaultValues: async () => {
			return {
				name: simulation?.name,
				productId: simulation?.product?.id,
				netWeight: simulation?.netWeight,
				status: simulation?.status,
				comment: simulation?.comment,
			};
		},
		mode: 'onChange',
	});

	// translation
	const { t } = useTranslation();

	const [openConfirm, setOpenConfirm] = useState(false);

	const handleSubmitForm = (data: any) => {
		if (selectedOption?.objectId !== simulation?.product?.id) {
			setOpenConfirm(true);
			return;
		}

		onSaveData(data);
	};

	const handleConfirm = () => {
		const values = getValues();
		setOpenConfirm(false);

		onSaveData({ ...values, newProductId: selectedOption?.objectId });
	};

	return (
		<div css={{ marginBottom: 24 }}>
			<FormContainer
				loading={isLoading}
				title={''}
				buttonTitle={t('save-button')}
				onSubmit={handleSubmit(handleSubmitForm)}
				isSuccess={isSuccess}
				isError={isError}
				withSave
				withCancel
				onCancel={() => {
					onClose();
				}}
				alertSuccess={<AlertStatic text={message} type={'success'} icon={<Check color={siteColors.green500} />} />}
				alertError={<AlertStatic text={message} type={'error'} icon={<AlertCircle color="#C74600" />} />}
				addStyles={{
					maxWidth: 528,
					margin: 'auto',
				}}
				t={t}
			>
				<div className={cx('flexColumn width100', styles.formContainer)}>
					<div className={cx('flexColumn width100', styles.header)}>
						<p className={cx(styles.title)}>{title}</p>
					</div>

					{!onlyNetWeight && (
						<BInput
							required
							id="name"
							label={<div className={cx(styles.label)}>{t('scenario-name-field')}</div>}
							fullWidth
							margin="dense"
							{...register('name')}
							name="name"
							error={errors.name ? true : false}
							errorMessage={errors?.name?.message}
							autoComplete="off"
						/>
					)}
					{onlyNetWeight && (
						<BInput
							required
							id="netWeight"
							label={<div className={cx(styles.label)}>{t('netWeight-label')}</div>}
							fullWidth
							margin="dense"
							{...register('netWeight')}
							name="netWeight"
							error={errors.netWeight ? true : false}
							errorMessage={errors?.netWeight?.message}
							autoComplete="off"
							unity={'g'}
							customUnity={'g'}
							className={styles.netWeightContainer}
						/>
					)}

					{!onlyNetWeight && (
						<>
							<FormSelect
								control={control}
								{...register('status')}
								label={t('simulation-status')}
								defaultValue={simulation?.status}
							/>

							<div className="width100">
								{/* <div className={cx(styles.label1)}>{t('scenario-product-source-label')}</div>
								<div className={cx(styles.selectOption)}>{selectedOption?.label}</div> */}

								<BInput
									required
									value={selectedOption?.label}
									id="comment"
									label={<div className={cx(styles.label)}>{t('scenario-product-source-label')}</div>}
									fullWidth
									margin="dense"
									disabled
								/>

								{/* <SearchAutocomplete
									options={options}
									open={true}
									setSelectedOption={setSelectedOption}
									selectedOption={selectedOption}
									className={cx(styles.searchClassName)}
									setParamsAutocomplete={setSearch && _.debounce(setSearch, 500)}
									disabled
								/> */}
							</div>

							<BInput
								required
								id="comment"
								label={<div className={cx(styles.label)}>{t('simulation-comment')}</div>}
								fullWidth
								margin="dense"
								{...register('comment')}
								name="comment"
								error={errors.comment ? true : false}
								errorMessage={errors?.comment?.message}
								autoComplete="off"
								isTextarea={true}
							/>
						</>
					)}
				</div>
			</FormContainer>

			<Dialog
				open={openConfirm}
				onClose={() => {
					return setOpenConfirm(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Vos modifications seront perdues, souhaitez vous confirmer ?
					</DialogContentText>
				</DialogContent>
				<DialogActions className={styles.actionContainer}>
					<BButton label="Confirmer" variant="tertiary" className={styles.confirmButton} onClick={handleConfirm} />
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ScenarioForm;
