const classes = () => {
	return {
		avatar: {
			width: 130,
			height: 130,
			borderRadius: 130,
			left: 0,
			top: 0,
			objectFit: 'contain',
			border: '5px solid #fff',
			boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
		},
	};
};

interface AvatarContentProps {
	url?: string;
	children: any;
	addStyle?: any;
}

const AvatarContent = ({ url, addStyle, children }: AvatarContentProps) => {
	const styles = classes();

	return (
		<div className="flexRow alignCenter" css={{ gap: 28 }}>
			<img css={{ ...styles.avatar, ...addStyle } as any} src={url} alt="" />
			<div css={{ maxWidth: 350 }}>{children}</div>
		</div>
	);
};

export default AvatarContent;
