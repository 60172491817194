import { ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import BaseLayout from '../_common/BaseLayout';
import SideBar from '../_common/SideBar';

type Props = {
	children?: ReactNode;
};

const NotionLayout = ({ children }: Props) => {
	return (
		<BaseLayout sidebar={<SideBar open={true} />}>
			{/* The actual content */}
			<main css={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>{children ? children : <Outlet />}</main>
		</BaseLayout>
	);
};

export default NotionLayout;
