import { css, cx } from '@emotion/css';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { cn, siteColors } from '../../../lib/colors';
import Typography from '../../Typography';

const styles = {
	table: css({
		'.row-table td': {
			paddingTop: 0,
			paddingBottom: 16,
		},
		'.row-table:last-child td': {
			paddingBottom: '0px !important',
		},
	}),
	th: css({
		color: cn(siteColors.grey800, '!important'),
		fontSize: '12px !important',
		paddingBottom: '24px !important',
	}),

	emptyText: css({
		minHeight: '120px',
	}),
};

type DashboardTableProps = {
	columns: any;
	data: any;
	emptyText?: string;
};

const DashboardTable = ({ columns, data, emptyText }: DashboardTableProps) => {
	const { indicator } = useApp();

	if (!data?.length) {
		return (
			<div className={cx('flexCenter width100', styles.emptyText)}>
				<Typography>{emptyText} </Typography>
			</div>
		);
	}

	return (
		<table className={cx('width100', styles.table)}>
			<tr>
				{(columns || []).map((c: any, index: number) => {
					return <td className={styles.th}>{c.header}</td>;
				})}
			</tr>
			{data.map((v: any, index: number) => {
				return (
					<tr className="row-table" key={index}>
						{(columns || []).map((c: any, index: number) => {
							const Component = c?.component || 'div';
							return (
								<td key={index}>
									<Component item={v} />
								</td>
							);
						})}
					</tr>
				);
			})}
		</table>
	);
};

export default DashboardTable;
