import { Fragment, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { getCompanySheetFacetAction } from '@carbonmaps/ui/actions/company.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';

import CreateFacet from './CreateFacet';
import FacetHeader from './FacetHeader';
import FacetsTable from './FacetsTable';

const Facets = (/* props: Props */) => {
	const { setBreadcrumbs } = useApp();
	// init filters values
	const [searchQueryParams] = useSearchQueryParams();

	// translation
	const { t } = useTranslation();
	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([
			{ text: t('company-breadcrumbs'), link: `${FRONT_PATH_NAMES.superAdminSettings}/companies` },
			{ text: t('facettes') },
		]);
	}, [setBreadcrumbs, t]);

	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';
	const indexId = queryParams?.indexId ? queryParams?.indexId[0] : '';

	// ---- fetch data --- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getCompanySheetFacet',
			{
				companyId,
			} as any,
		],
		queryFn: getCompanySheetFacetAction,
	});

	return (
		<Container header={<FacetHeader subtitle={data?.name} />} seoText={`${t('facettes')} - Carbon Maps`}>
			{companyId && companyId !== '' && !indexId && (
				<Fragment key={'facets'}>
					<CreateFacet />
					<FacetsTable filterOptions={searchQueryParams} manualSortBy={false} />
				</Fragment>
			)}
		</Container>
	);
};

export default Facets;
