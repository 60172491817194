import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import FormControl from '@mui/material/FormControl';
import type { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system';
import { lighten } from '@mui/system/colorManipulator';
import _ from 'lodash';
import { ChevronDown } from 'lucide-react';

import { important, pxToRem } from '@carbonmaps/ui/utils/styles';

// import BlockTitle from '../../BlockTitle';
import { siteColors } from '../../../lib/colors';
import { ItemField } from '../../../types/field.type';

type DropdownFieldProps = {
	disabled?: boolean;
	value: string;
	onChange: (value: string) => void;
	ExpandMoreRoundedIcon?: React.ElementType;
	items: Array<ItemField>;
	renderItem?: (item: ItemField) => React.ReactNode;
	renderValue?: (selectedItem?: ItemField) => React.ReactNode;
	sx?: SxProps;
	// addStyles?: {
	// 	select?: any;
	// 	icon?: any;
	// 	formControl?: any;
	// };
	className?: string;
	iconComponent?: any;
	paddingRight?: number;
	iconColor?: string;
	MenuProps?: any;
};

const classes = () => {
	return {
		// formControl: {
		// 	// '& .MuiInputBase-root': {
		// 	// 	// color: 'rgba(121, 0, 217, 0.10)',
		// 	// 	// borderWidth: '1px',
		// 	// 	// borderStyle: 'solid',
		// 	// 	// minWidth: '120px',
		// 	// 	// justifyContent: 'center',
		// 	// 	// borderRadius: '8px',
		// 	// },
		// 	// '& .MuiSelect-select.MuiSelect-select': {
		// 	// 	paddingRight: '10px',
		// 	// },
		// },
		// select: {
		// 	width: 'auto',
		// 	padding: '4px 34px 4px 12px !important',
		// 	borderRadius: '8px !important',
		// 	background: 'rgba(121, 0, 217, 0.10)!important',

		// 	'&:focus': {
		// 		backgroundColor: 'transparent',
		// 	},
		// },
		// selectIcon: {
		// 	position: 'relative',
		// 	color: '#7900D9 !important',
		// 	fontSize: '24px !important',
		// },
		paper: {
			borderRadius: 12,
			marginTop: 8,
			minWidth: '212px !important',
		},
		list: {
			paddingTop: 0,
			paddingBottom: 0,
			'& li': {
				padding: '4px 16px',
				alignItems: 'center',
				gap: '8px',
				fontSize: '14px',
				fontStyle: 'normal',
				fontWeight: '400',
				color: siteColors.text,
				'& .MuiRadio-root': {
					padding: 4,
				},
			},
		},
		// labelSelected: {
		// 	fontSize: '2rem',
		// 	color: '#7900D9',
		// },
	};
};

const SelectField = ({
	disabled,
	value,
	items,
	ExpandMoreRoundedIcon,
	onChange,
	renderItem,
	renderValue,
	paddingRight = 34,
	// addStyles,
	sx = {},
	className,
	iconColor = '#7900D9',
	MenuProps,
}: DropdownFieldProps) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const styles = classes();

	const [selectedValue, setSelectValue] = useState(value);

	const handleChange = (event: SelectChangeEvent<string>) => {
		setSelectValue(event.target.value);
		onChange(event.target.value);
	};

	const menuProps = {
		disableScrollLock: true,
		classes: {
			list: css(styles.list),
			paper: css(styles.paper),
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'center',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'center',
		},
		getContentAnchorEl: null,
		// PaperProps: {
		// 	style: {
		// 		background: 'yellow',

		// 		width: '20px', // Vous pouvez ajuster la largeur ici
		// 	},
		// },
	} as Partial<MenuProps>;

	useEffect(() => {
		setSelectValue(value);
	}, [value]);

	return (
		<FormControl
		/* className={cx(css(styles.formControl), css(addStyles?.formControl))} */
		// className={cx(/* css(styles.formControl), */ css(addStyles?.formControl))}
		>
			<Select
				disabled={disabled}
				variant="standard"
				value={value}
				onChange={handleChange}
				disableUnderline
				IconComponent={ExpandMoreRoundedIcon || ChevronDown}
				MenuProps={{
					...menuProps,
					...MenuProps,
				}}
				// classes={{
				// 		select: css({ ...styles.select, ...addStyles?.select }),
				// 		icon: css({ ...styles.selectIcon, ...addStyles?.icon }),
				// }}
				renderValue={(value: string) => {
					const selectedItem = items.find((item) => {
						return item.value === value;
					});

					if (renderValue) {
						return renderValue(selectedItem);
					}

					return (
						<Typography
							variant="h1"
							color="#7900D9"
							textTransform="lowercase"
							// css={{ ...styles.labelSelected, /*  textTransform: 'lowercase', */ marginBottom: 2 }}
						>
							{selectedItem?.label}
						</Typography>
					);
				}}
				sx={_.merge(
					{
						bgcolor: lighten('#7900D9', 0.8),
						borderRadius: pxToRem(8),
						'& .MuiSelect-select': {
							paddingLeft: pxToRem(12),
							paddingRight: important(pxToRem(paddingRight)),
						},
						'& .MuiSelect-icon': { color: iconColor, fontSize: pxToRem(24), right: pxToRem(8) },
						'&.Mui-focused>.MuiSelect-select': { bgcolor: 'transparent' },
						// ...sx,
					},
					sx,
				)}
				className={className}
			>
				{items.map((item, index: number) => {
					return (
						<MenuItem key={index} value={item.value}>
							{renderItem ? (
								renderItem(item)
							) : (
								<>
									<Radio checked={item.value === selectedValue} />
									{item.label}
								</>
							)}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default SelectField;
