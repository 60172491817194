import { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ITEM_TYPE_INGREDIENT } from '@carbonmaps/shared/utils/constants';
import { getConfigFacets, getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';
import { getCategoriesIngredient } from '@carbonmaps/ui/actions/ingredient.actions';
import { getCompanySuppliers } from '@carbonmaps/ui/actions/supplier.actions';

import { IFilterFolder } from '../../../../components/Filter';
import { Option } from '../../../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../../../components/layout/list/FilterContainer';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useGetClientAuthQuery } from '../../../../lib/react-query/features/auth/auth.hooks';
import { getLabelNodeFacet } from '../../../../utils/utils';
const suppliersFolderLabel = 'Fournisseur';

const styles = {
	container: css({
		marginBottom: '-4px ',
		top: '118px !important',

		paddingLeft: '0px !important',
		paddingRight: '0px !important',
	}),
	searchContainer: css({
		'& svg': {
			marginLeft: '16px !important',
		},
		'& .MuiOutlinedInput-root': {
			width: '365px !important',
			'& .MuiAutocomplete-input': {
				paddingLeft: '0px !important',
			},
		},
	}),
};

const IngredientAcvFilter = () => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const params = useParams();
	const searchParams = searchQueryParams?.facetFilters ?? [];
	// custom facets suppliers
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);

	// translation
	const { t } = useTranslation();

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const dataFacets = useMemo(() => {
		return [...getConfigFacets(authData?.session.company, true, 'Product')];
	}, [authData?.session.company]);

	// filters values
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);

	const { data: facets, isLoading: isFacetsLoading } = useQuery({
		queryKey: [
			'getCategoryIngredient',
			{
				input: searchQueryParams.input,
				facetFilters: searchQueryParams?.facetFilters, //searchQueryParams.input

				path: 'acv',
			} as any,
		],
		queryFn: getCategoriesIngredient,
	});

	const { data: suppliersData, isLoading: isSuppliersLoading } = useQuery({
		queryKey: ['getAllSuppliersForCompany', { itemType: ITEM_TYPE_INGREDIENT, isN2: true, isFilter: true }],
		queryFn: getCompanySuppliers,
	});

	useEffect(() => {
		//	if there are filters in the url
		if (searchParams && searchParams.length) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			searchParams.forEach((item: any) => {
				const config = dataFacets.find((f: any) => {
					return f.field === item.path;
				});

				const arrayValues = item.values.map((v: any) => {
					const value = {
						facetPath: item.path,
						value: v,
						folderLabel: config?.label ?? item.path,
						label: getFacetLabel(item.path, authData?.session.company),
						isFacetBucket: true,
						type: typeof v,
						global: item.global === true ? true : false,
					};

					currentSelectedValues.push(value);
					return value;
				});

				currentAppliedValuesFolders.push({
					isFacet: true,
					label: config?.label ?? item.path,
					options: arrayValues,
				});
			});

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	useEffect(() => {
		// don't change current filter
		if (!facets) return;

		let N2FilterFolder: IFilterFolder;
		const facetFilterFolders: IFilterFolder[] = [];

		Object.entries(facets.facet ?? {}).forEach(([key, value]: [any, any]) => {
			if (key !== 'tagAdvanced') {
				const { label, type, global } = getFacetConfig(key, authData?.session.company, 'Ingredient') ?? {};
				const facetLabel = label || getFacetLabel(key, authData?.session.company);

				const options: Option[] = value.buckets.map((bucket: any) => {
					return {
						value: bucket._id,
						label: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
							? getLabelValue(key, String(bucket._id))
							: String(bucket._id),
						labelNode: ['tagAdvanced', 'tagAdvancedModelization'].includes(key)
							? getLabelNodeFacet(key, bucket._id, t)
							: undefined,
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: type ?? 'hardcoded-undefined-facet-type',
						global,
						itemType: ITEM_TYPE_INGREDIENT,
					};
				});

				const formedFacetFolder = {
					label: facetLabel,
					options,
					isFacet: true,
					path: key,
					global,

					showCount: false,
				};

				// return ;
				if (key === 'tagAdvanced') {
					N2FilterFolder = formedFacetFolder;
				} else {
					facetFilterFolders.push(formedFacetFolder);
				}
			}
		});

		setFilterFolders((folders) => {
			const notFacetFolders = folders.filter((e) => {
				return !e.isFacet;
			});

			return [...(N2FilterFolder ? [N2FilterFolder] : []), ...notFacetFolders, ...facetFilterFolders];
		});
	}, [facets]);

	useEffect(() => {
		if (!suppliersData) return;

		const suppliersFolder: IFilterFolder = {
			label: suppliersFolderLabel,
			isFacet: false,
			showCount: false,
			options: suppliersData.map((sup) => {
				return {
					label: sup.name,
					value: sup.id,
					folderLabel: suppliersFolderLabel,
				};
			}),
		};

		setFilterFolders((folders) => {
			const supplierFolderIndex = folders.findIndex((e) => {
				return e.label === suppliersFolderLabel;
			});

			if (supplierFolderIndex === -1) {
				const n2FolderIndex = folders.findIndex((e) => {
					if (e.isFacet) {
						return e.path === 'tagAdvancedModelization';
					}

					return false;
				});

				const n2Folder = folders[n2FolderIndex];
				return [...(n2Folder ? [n2Folder] : []), suppliersFolder, ...folders];
			}

			folders[supplierFolderIndex] = suppliersFolder;
			return folders;
		});
	}, [suppliersData]);

	return (
		<FilterContainer
			skeleton={isFacetsLoading}
			isLoading={false}
			appliedValuesByFolders={appliedValuesByFolders}
			setAppliedValuesByFolders={setAppliedValuesByFolders}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			folders={filterFolders}
			setSearchQueryParams={setSearchQueryParams}
			suppliersFolderLabel={suppliersFolderLabel}
			isTableLoading={false}
			isExportLoading={false}
			searchPlaceholder={t('audit-search-placeholder')}
			searchClassName={styles.searchContainer}
			className={styles.container}
			withMore={false}
		/>
	);
};

export default IngredientAcvFilter;
