import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation as useTranslationNext } from 'react-i18next';

import { getIsoCountryCode, isEmptyText } from '@carbonmaps/ui/utils/utils';

import { useGetClientAuthQuery } from '../lib/react-query/features/auth/auth.hooks';

export const useTranslation = () => {
	const { t, i18n } = useTranslationNext();

	const {
		result: { data },
	} = useGetClientAuthQuery();

	const dataTranslationsMap = useMemo(() => {
		const translationsMap = new Map();

		if (!data?.translations?.length) return translationsMap;

		data?.translations.forEach((translation: any) => {
			translationsMap.set(translation.key, translation);
		});

		return translationsMap;
	}, [data]);

	const customTranslate = useCallback(
		(key: string) => {
			const translation = dataTranslationsMap.get(key);
			if (!translation) return t(key);
			return !isEmptyText(translation[`text_${getIsoCountryCode(i18n.language)}`])
				? translation[`text_${getIsoCountryCode(i18n.language)}`]
				: t(key);
		},
		[dataTranslationsMap, i18n, t],
	);

	const translate = useCallback(
		(key: any, options?: any) => {
			try {
				return dataTranslationsMap?.has(key) ? customTranslate(key) : (t(key, options) as any);
			} catch (error) {
				// console.error('Error in translation', error);
				return key;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[customTranslate, t],
	);

	return {
		t: translate,
		i18n,
	};
};
