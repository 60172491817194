import { cx } from '@emotion/css';
import _ from 'lodash';

import { WATER_INDICATOR } from '@carbonmaps/shared/utils/constants';
import IntensityChart from '@carbonmaps/ui/components/charts/IntensityChart';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { siteColors } from '../../lib/colors';

type Props = {
	value?: number;
	percentage?: number;
	indicator?: string;
};

const Intensity = ({ value, percentage, indicator }: Props) => {
	return (
		<div className={cx('flexRow justifyEnd')}>
			<div css={{ width: 55 }}>{displayValue(value)}</div>
			<div>
				<IntensityChart
					percentage={_.toNumber(percentage)}
					color={indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500}
				/>
			</div>
		</div>
	);
};

export default Intensity;
