import { z } from 'zod';

export const FacetFormValidationSchema = z.object({
	collectionName: z.string({ required_error: 'collection obligatoire' }).min(4, 'collection obligatoire'),
	companyId: z.string({ required_error: 'Company obligatoire' }).min(4, 'Company obligatoire'),
	indexName: z.string({ required_error: 'Nom de l\'index obligatoire' }).min(5, 'Nom de l\'index minimum 5lettres')
		.max(255, 'Nom de l\'index doit avoir 255 caractères au maximum')
		.refine((value) => { return value === value.toLowerCase(); }, {
			message: 'Nom de l\'index doit être en minuscules.',
		})
		.refine((value) => { return /^[a-zA-Z0-9]+$/.test(value); }, {
			message: 'Nom de l\'index ne doit contenir que des caractères alphanumériques (sans espaces ni caractères spéciaux).',
		}),

});

export type FacetFormInput = z.infer<typeof FacetFormValidationSchema>;
