import { ComponentProps } from 'react';

import Box from '@mui/material/Box';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { ArrowUpRight, Box as BoxIcon } from 'lucide-react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useGetIngredientSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetIngredientSheet';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockCard from '../../../components/BlockCard';
import BlockListItem from '../../../components/BlockListItem';
import BlockTitle from '../../../components/BlockTitle';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import IngredientModel from '../../../models/Ingredient.model';

const IngredientDataBlock = () => {
	const params = useParams(); // * { ingredientId: string; tab: string }
	const theme = useTheme();

	const { t } = useTranslation();

	const { data, isLoading, isError, error } = useGetIngredientSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.ingredientId!,
		// period: 2023,
	});

	const ingredientObject = new IngredientModel(data?.ingredient);

	const formedData: ComponentProps<typeof BlockListItem>[] = [
		{
			label: 'uses',
			value: formatNumber(data?.ingredient.usageCount, undefined, 0),
			link: `${FRONT_PATH_NAMES.ingredients}/${params.ingredientId}/usage`,
		},
		{
			label: 'Volume',
			value: ingredientObject?.getVolume() !== 0 ? `${formatNumber(ingredientObject?.getVolume())}` : '-', // kg
			unity: ingredientObject?.getVolume() !== 0 ? 'T' : '',
		},
	];

	return (
		<>
			<Box className="flexRow" gap={'8px'}>
				<BoxIcon color={theme.palette.grey[700]} height={20} width={20} />
				<BlockTitle variant="h3" color={theme.palette.common.black}>
					{t('ingredient_data', {
						ingredient: t('ingredient'),
						ingredient_lowercase: toLowerCase(t('ingredient')),
						an_ingredient: t('an_ingredient'),
					})}
				</BlockTitle>
			</Box>
			<BlockCard>
				{formedData.map(({ label, value, unity, link }, index) => {
					return (
						<Box key={index} mb="6px" display="flex" alignItems="center" justifyContent="space-between">
							<Box display="flex" alignItems="center">
								<Typography mr="6px" color={siteColors.grey700} fontSize={'14px'} fontWeight={600}>
									{t(label as any)}:
								</Typography>
								<Typography mr="6px" className="number">
									{value} {unity ? unity : ''}
								</Typography>
							</Box>
							{link && (
								<Box display="flex" alignItems="center">
									<MUILink
										component={RouterLink}
										to={link}
										// sx={{ '&:hover': { color: theme.palette.primary.main } }}
									>
										<ArrowUpRight
											size={20}
											color={theme.palette.grey[700]}
											css={{ '&:hover': { stroke: theme.palette.primary.main } }}
										/>
									</MUILink>
								</Box>
							)}
						</Box>
					);
				})}
			</BlockCard>
		</>
	);
};

export default IngredientDataBlock;
