import { useCallback, useMemo, useRef, useState } from 'react';

import { cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Pencil, Trash2, Wallet } from 'lucide-react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { CARBONE_INDICATOR, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { STATUS_QUOTE } from '@carbonmaps/shared/utils/simulation';
import { ClientFormInput } from '@carbonmaps/shared/validations/client.validations';
import { QuoteFormInput } from '@carbonmaps/shared/validations/quote.validations';
import { createQuoteAction, deleteDevisAction } from '@carbonmaps/ui/actions/quote.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';

import LoadingDialog from '../../../../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../../../../components/table/TableHeaderCell';
import { useJuneTrack } from '../../../../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { siteColors } from '../../../../../../lib/colors';
import {
	createClientAction,
	getQuoteOptionsAutocompleteClientAction,
} from '../../../../../../lib/react-query/features/client/client.actions';
import QuoteEditModal from '../../../../../quoteSheet/QuoteEditModal';
import DevisModal from '../../../DevisModal';

// type Props = {};
const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},

		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(5)) > div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(5))  div ':
			{
				textAlign: 'right',
				// paddingLeft: '0px !important',
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
	};
};

const DevisTable = (/* props: Props */) => {
	const { t } = useTranslation();
	const { indicator } = useApp();
	const theme = useTheme();
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	const [message, setMessage] = useState('');
	const queryClient = useQueryClient();
	const [openModal, setOpenModal] = useState(false);
	const [selectedDevis, setSelectedDevis] = useState<any>({});
	const [isNew, setIsNew] = useState(true);

	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// --- ref for fetch data table ---- //
	const fetchIdRef = useRef(0);

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	// mutate action confirm to delete simulation
	const {
		mutate: deleteSelectedRow,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteFacetFieldSelectedRow'],
		mutationFn: deleteDevisAction,
		onSuccess: () => {
			console.log('quote DELETED');
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisClient'] });
			//setMessage('');
		},
		onError: (error: any) => {
			// if (error.message) {
			// 	setMessage(error.message);
			// }
		},
	});

	// ---- action when delete row ---- //
	const handleClickDelete = useCallback(async (value: any) => {
		await deleteSelectedRow({ objectId: value } as any);
	}, []);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					...(pageIndex && { page: pageIndex + 1 }),
					...(pageSize && { size: pageSize }),
					...rest,
				});
			}
		},
		[paramsTable],
	);

	const navigate = useNavigate();

	const { mutate: updateQuoteStatus } = useMutation({
		mutationKey: ['updateStatusQuote'] as const,
		mutationFn: async ({ objectId, status }: { objectId: string; status: string }) => {
			try {
				const quote = new Parse.Object('Quote');
				quote.id = objectId;

				quote.set('status', status);

				await quote.save();
			} catch (error) {
				return Promise.reject(error);
			}
		},
		onSuccess(data, variables, context) {
			queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });
		},
	});

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('quote')} />;
				},
				accessor: 'name', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				icon: <Wallet size={16} color={siteColors.grey700} />,
				props: {
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},
					page: 'devis-table',
					trans: t,
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('simulation-status')} />;
				},
				accessor: 'status',
				component: 'SimpleCell',
				editable: true,
				disableSortBy: true,
				type: 'status',
				callbackChangeStatus: (objectId: any, status: string) => {
					// handleClickUpdate({ objectId, status });
					// console.log('xxxxxxxxxxx - sattus update', objectId, status);
					updateQuoteStatus({ objectId, status });
				},
				props: {
					isLoading: /* isLoadingUpdateStatus */ false,
					page: 'devis-table',
					trans: t,
					statusDictionary: STATUS_QUOTE,
				},
				isLoading: /* isLoadingUpdateStatus */ false,
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							//label={indicator === CARBONE_INDICATOR ? WORDING_TERMS.INTENSITY : 'Intensité eau'}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBONE_INDICATOR ? 'averageCarbonIntensity' : 'averageWaterIntensity',
				// accessor: 'eutrophisation3',
				editable: true,
				sortDescFirst: true,
				disableSortBy: false,
				component: 'Intensity',
				type: 'number',
				props: {
					color: {
						primary: indicator === WATER_INDICATOR ? '#046ae6' : siteColors.primary,
					},
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t('impact-estimated')}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonImpact' : 'waterImpact'}
							// sx={{ display: 'flex', justifyContent: 'flex-end' }}
						/>
					);
				},
				// accessor: 'status',
				// accessor: 'impactEstimation',
				accessor: indicator === CARBONE_INDICATOR ? 'estimatedImpactCarbon' : 'estimatedImpactWater',
				// estimatedImpactCarbonTon
				// estimatedImpactWaterTon
				component: 'SimpleCell',
				editable: true,
				disableSortBy: false,
				props: {
					className: cx('flexRow justifyEnd' /* , styles.textColorCarbon */),
					nativeStyle: {
						color: indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
					},
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label="" />;
				},
				accessor: 'objectId', // accessor is the "key" in the data
				editable: true,
				disableSortBy: true,
				component: 'Action',
				type: 'action',
				icon: <Trash2 size={18} color={theme.palette.grey[700]} />,
				withConfirm: true,
				callback: (value: any) => {
					handleClickDelete(value);
				},
				editIcon: <Pencil size={18} color={theme.palette.grey[700]} />,
				callbackEdit: (value: any) => {
					navigate(value.subRowLink);
					// handleClickEdit(value);
				},
				callbackCopy: (value: any) => {
					handleClickAdd(value, 2);
				},
				// callbackAdd: (value: any) => {
				// 	handleClickAdd(value);
				// },
				props: {
					page: 'devisCarbone',
				},
				// eslint-disable-next-line quotes
				messageConfirm: t('delete-quote-confirm-modal-title'),
				subMessageConfirm: t('delete-quote-confirm-modal-subtitle'),
			},
		];
	}, [indicator, t, theme.palette.grey]);

	// ---- action when click add ---- //
	const handleClickAdd = useCallback(
		(value: any, level: number) => {
			setSelectedOption(value?.client);
			setSelectedDevis(value);
			setOpenModal(true);
			setIsNew(false);
		},
		[
			// data, setSelectedOption
		],
	);

	const params = useParams();

	const clientId = params.clientId || '---';

	const { data: devisData, isLoading: isTableLoading } = useQuery({
		queryKey: ['findDevisForClientTable', { clientId, input: searchQueryParams.input, indicator }] as const,
		queryFn: async ({ queryKey }) => {
			const { clientId, input, indicator } = queryKey[1];

			const result = await Parse.Cloud.run('findDevis', { clientId, input, indicator });
			return result.data[0].quotes;
		},
	});

	const [selectedOption, setSelectedOption] = useState<any>({});

	//----- action when submit quote form ----//
	const onSubmit: SubmitHandler<QuoteFormInput> = async (values) => {
		console.log('lol');
		createQuote({
			...values,
			clientId: selectedOption?.objectId,
		} as any);
	};

	//----- mutation to create the client ----//
	const {
		mutate: createClient,
		isError: isErrorClient,
		isSuccess: isSuccessClient,
		isLoading: isLoadingCreateClient,
	} = useMutation({
		mutationKey: ['createClient'],
		mutationFn: createClientAction,
		onSuccess: async (returnedData: any) => {
			// setOpenModal(false);
			// setStep(1);

			queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });

			if (returnedData?.isUpdate) {
				// setMessage('Informations mises à jour');
				setSelectedOption({
					label: returnedData?.client?.get('name'),
					...returnedData?.client?.toJSON(),
				});
			}
			// navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	//----- action when submit client form  ----//
	const onSubmitClient: SubmitHandler<ClientFormInput> = async (values) => {
		createClient({
			...values,
		} as any);
		trackEvent('Simulate Carbon Quote Create Client', {
			ClientName: values?.name,
		});
	};

	const [search, setSearch] = useState<any>();

	//----- fetch data for autocomplete ----//
	const {
		data: dataOptions,
		isLoading: isLoadingData,
		isSuccess: isSuccessOptions,
	} = useQuery({
		queryKey: [
			'getOptionsAutocompleteClient',
			{
				// ...paramsTable,
				input: search?.input ?? '',
			},
		] as any,
		queryFn: getQuoteOptionsAutocompleteClientAction,
	});

	const {
		mutate: createQuote,
		isError,
		isSuccess,
		isLoading: isLoadingCreateQuote,
	} = useMutation({
		mutationKey: ['createQuote'],
		mutationFn: createQuoteAction,
		onSuccess: async (returnedData: any) => {
			setOpenModal(false);
			queryClient.invalidateQueries({ queryKey: ['getOptionsAutocompleteClient'] });
			queryClient.invalidateQueries({ queryKey: ['getQuoteDataAction'] });
			queryClient.invalidateQueries({ queryKey: ['findDevisForClientTable'] });
			queryClient.invalidateQueries({ queryKey: ['getClientSheetById'] });

			// setMessage('Informations mises à jour');
			// navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const [openQuoteEditModal, setOpenQuoteEditModal] = useState(false);

	const handleClickEdit = useCallback((value: any) => {
		setSelectedOption(value?.client);
		setSelectedDevis(value);
		setOpenQuoteEditModal(true);
	}, []);

	return (
		<div css={{ marginBottom: 40 }}>
			<TableComponent
				isSelectable={false}
				skeleton={isTableLoading}
				loading={false}
				fetchData={updateTableParams}
				// pageCount={tableResultData?.meta?.last_page || 0}
				noDataMessage={t('Aucun devis ne correspond à la sélection')}
				// pageSize={paramsTable.size}
				columns={tableColumns}
				// resetPage={resetPage}
				data={
					devisData || []
					// []
				}
				// onSortingColumn={handleSortingTable}
				addStyles={stylesTable}
				// manualSortBy={manualSortBy}
				stickyHeader
				// stickyHeaderStyles={stickyHeaderStyle}
				transFunc={t}
				pageCount={undefined}
				pagination={false}
			/>

			<QuoteEditModal
				open={openQuoteEditModal}
				onClose={() => {
					return setOpenQuoteEditModal(false);
				}}
				quote={selectedDevis}
				options={dataOptions}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				onSaveData={() => {
					return setOpenQuoteEditModal(false);
				}}
			/>

			<DevisModal
				// eslint-disable-next-line quotes
				title={isNew === true ? t('form-create-quote-title') : t('form-update-quote-title')}
				// title={t('form-create-quote-title')}
				open={openModal}
				onClose={() => {
					// setSelectedSimulation({});
					// setMessage('');
					return setOpenModal(false);
				}}
				isLoading={isLoadingCreateQuote}
				onSubmit={onSubmit}
				onSubmitClient={onSubmitClient}
				options={/* options */ dataOptions}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				message={message}
				isError={isError}
				// setSearch={setSearch}
				setSearch={
					// setTableParams
					// 	? setTableParams
					// 	:
					// () => {
					// 	/*  */
					// }
					setSearch
				}
				// isSuccess={isSuccess}
				isLoadingClient={isLoadingCreateClient}
				selectedSimulation={selectedDevis}
				setSelectedSimulation={setSelectedDevis}
				isNew={isNew}
				// selectedSimulation={undefined}
				// setSelectedSimulation={undefined}
			/>
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />
		</div>
	);
};

export default DevisTable;
