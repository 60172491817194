import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '../../Typography';

import { cn, siteColors } from '../../../lib/colors';
import EstimatedAddButton from './EstimatedAddButton';
import Tooltip from './Tooltip';

const styles = {
	container: css({
		width: 'var(--width)',
		height: 'var(--height)',
		position: 'relative',
		cursor: 'pointer',
		'&:hover .custom-tooltip': { display: 'block !important' },
	}),

	estimateBar: css({
		background: siteColors.grey300,
		borderTopWidth: 1,
		borderTopStyle: 'solid',
		borderColor: siteColors.grey600,
		width: '100%',
		height: 'var(--height)',
	}),

	water: css({
		background: siteColors.water100,
		borderColor: siteColors.water500,
	}),
	carbon: css({
		background: siteColors.carbon100,
		borderColor: siteColors.primary,
	}),

	carbonBar: css({
		background: siteColors.carbon100,
		borderTopWidth: 1,
		borderTopStyle: 'solid',
		width: '100%',
		height: 'var(--height)',
	}),

	noBorder: css({
		border: 'none',
	}),

	cardNew: css({
		border: cn('1px dashed', siteColors.grey600, '!important'),
		background: '#FFF !important',
	}),

	label: css({
		width: '100%',
		maxWidth: '100%',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	}),

	estimatedLabel: css({
		color: cn(siteColors.grey800, '!important'),
	}),

	carbonLabel: css({
		//color: cx(siteColors.primary, '!important'),
		color: 'var(--color) !important',
	}),
	cardValue: css({
		zIndex: 'var(--zIndex)',
	}),
	estimatedCard: css({
		zIndex: 'var(--zIndex)',
	}),

	tooltipContainer: css({
		display: 'none',
		position: 'absolute',

		left: '50%',
		zIndex: 100,
	} as any),
} as any;

type BarItemProps = {
	item: any;
	width?: string;
	isFilter?: boolean;
};

const percent_for_value = 15;

// format compact number
const formatCompactNumber = (number: number, unit = '') => {
	if (!number) return '';
	const options = {
		notation: 'compact',
		compactDisplay: 'short',
	} as any;
	const format = Intl.NumberFormat('fr-FR', options);
	return `${format.format(number)}${unit}`;
};

const BarItem = ({ item, width, isFilter = false }: BarItemProps) => {
	const { indicator } = useApp();
	const { t } = useTranslation();
	const theme = useTheme();

	const isCardNew =
		!item?.estimatedValue && !item?.value && !item?.carbonImpactEstimated && !item?.waterImpactEstimated;

	// check when is equal
	const diffPercent = item.percentValue && Math.abs(item.percentValue - item.percentEstimatedValue) < 10;

	return (
		<div
			className={cx('flexColumn  relative flexEnd justifyEnd gap8', styles.container)}
			style={{
				['--width' as any]: width,
				['--height' as any]: '100%',
			}}
		>
			{item?.estimatedValue && item?.estimatedValue !== item?.value && !diffPercent && !isFilter ? (
				<div
					className={cx('absolute width100  height100', styles.estimatedCard)}
					style={{
						['zIndex' as any]: item?.estimatedValue > item?.value ? 10 : 20,
					}}
				>
					<div className="flexColumn gap8 width100 height100 justifyEnd">
						{item?.estimatedValue !== item?.value && (
							<Typography className={cx('number', styles.label, styles.estimatedLabel)} variant="caption">
								{formatCompactNumber(item?.estimatedValue)} ({t('objectif')})
							</Typography>
						)}
						<div
							className={styles.estimateBar}
							style={{
								['--height' as any]: `calc(${item.percentEstimatedValue}%)`,
							}}
						></div>
					</div>
				</div>
			) : null}
			<div
				className={cx('absolute width100 height100', styles.cardValue)}
				style={{ ['zIndex' as any]: item?.estimatedValue > item?.value ? 20 : 10 }}
			>
				<div className="flexColumn gap8 width100 height100 justifyEnd">
					{!isCardNew && item?.value ? (
						<Typography
							className={cx('number', styles.label, styles.carbonLabel)}
							style={{ ['--color' as any]: theme.palette.indicator[indicator].main }}
							variant="caption"
						>
							{formatCompactNumber(item?.value)}
						</Typography>
					) : null}

					<div
						className={cx(
							styles.carbonBar,
							isCardNew && styles.cardNew,
							isCardNew && 'flexCenter',
							item?.value ? styles[indicator] : styles.noBorder,
						)}
						style={{
							['--height' as any]: `calc(${item.percentValue}% ) `,
						}}
					>
						{isCardNew ? <EstimatedAddButton period={item?.year} /> : null}
					</div>
				</div>
			</div>

			{!isCardNew ? (
				<div
					className={cx('custom-tooltip', styles.tooltipContainer)}
					style={{ top: `${item.percentValue - percent_for_value}%` }}
				>
					<Tooltip item={item} />
				</div>
			) : null}
		</div>
	);
};

export default BarItem;
