import { css } from '@emotion/css';
import Box from '@mui/material/Box';
import MUILink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { ReactComponent as SupplierIcon } from '@carbonmaps/media/icons/supplier.svg';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useGetProductSheetTimePeriod } from '@carbonmaps/ui/hooks/useGetProductSheet';

import Skeleton from '../../../components/Skeleton';
import ScoringCard from '../../../components/card/ScoringCard';
import Card from '../../../components/widget/Card';
import LineDisplay from '../../../components/widget/LineDisplay';
import SupplierLineDisplay from '../../../components/widget/SupplierLineDisplay';
import { useTranslation } from '../../../hooks/useTranslation';
import ProductModel from '../../../models/Product.model';
import SupplierModel from '../../../models/Supplier.model';

const styles = {
	skeleton: css({
		width: '100% !important',
		height: '120px !important',
	}),
};

const ProductSuppliersBlock = () => {
	const theme = useTheme();
	const params = useParams();
	const { t } = useTranslation();

	const { data, isLoading } = useGetProductSheetTimePeriod({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.productId!,
		//period: 2023,
	});

	const productObject = data?.product ? new ProductModel(data?.product) : null;
	return (
		<Card
			title={t('product-suppliers-card-title')}
			icon={<SupplierIcon css={{ '& path': { fill: theme.palette.grey[700] } }} width={20} height={20} />}
			center={false}
			marginBottom="32px"
			sx={{ width: '100%' }}
		>
			{isLoading ? (
				<Skeleton className={styles.skeleton} />
			) : (
				<Box className="flexColumn" sx={{ width: '100%' }}>
					{(productObject?.getSuppliers() || []).length ? (
						(productObject?.getSuppliers() || []).map((supplier: SupplierModel, index: number) => {
							const path = `${FRONT_PATH_NAMES.suppliers.details}/${supplier.getId()}/synthesis`;
							return (
								<LineDisplay
									key={index}
									path={path}
									content={<SupplierLineDisplay supplier={supplier} />}
									afterContent={
										<MUILink component={RouterLink} to={path}>
											<ScoringCard
												value={supplier.getScoreText()}
												fontSize={12}
												sx={{ width: 36, height: 26, justifySelf: 'flex-end' }}
											/>
										</MUILink>
									}
								/>
							);
						})
					) : (
						<Typography>{t('product-suppliers-card-empty-data')}</Typography>
					)}
				</Box>
			)}
		</Card>
	);
};

export default ProductSuppliersBlock;
