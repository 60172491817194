import { css, cx } from '@emotion/css';
import { Info } from 'lucide-react';

import { translation } from '../../../i18n/translation';
import { toLowerCase } from '../../../utils/utils';
import BPopover from '../BPopover';

const styles = {
	container: css({ gap: 8 }),
	text: css({
		lineHeight: '1.3',
		fontSize: '14px!important',
		color: '#000 !important',
	}),
	tooltipContainer: css({
		padding: 24,
		maxWidth: 294,
	}),
};

const AuditContentField = (props: any) => {
	const { value, row } = props;
	const tooltip = row?.original?.tooltip;

	const t = props?.column?.props?.trans || translation;
	return (
		<div className={cx('flexRow nowrap alignCenter  width100 spaceBetween', styles.container)}>
			<span
				dangerouslySetInnerHTML={{
					__html: t(value, {
						ingredient: toLowerCase(t('ingredient')),
						product: toLowerCase(t('product')),
						ingredients: toLowerCase(t('ingredients')),
						products: toLowerCase(t('products')),
						ingredient_uppercase: t('ingredient'),
						product_uppercase: t('product'),
						of_product: t('of_product'),
						an_ingredient: t('an_ingredient'),
						agriculture_transformation_tab: toLowerCase(t('agriculture-transformation-tab')),
						product_lowercase: toLowerCase(t('product')),
					}),
				}}
				className={styles.text}
			></span>
			<BPopover
				className={cx('width100')}
				anchorOriginHorizontal={'right'}
				transformOriginVertical={'top'}
				transformOriginHorizontal={'right'}
				anchorOriginVertical={'bottom'}
				modeHover
				content={(onClose) => {
					return (
						<div className={styles.tooltipContainer}>
							<span
								dangerouslySetInnerHTML={{
									__html: t(tooltip, {
										ingredient: toLowerCase(t('ingredient')),
										product: toLowerCase(t('product')),
										ingredients: toLowerCase(t('ingredients')),
										products: toLowerCase(t('products')),
										ingredient_uppercase: t('ingredient'),
										product_uppercase: t('product'),
										agriculture_transformation_tab: toLowerCase(t('agriculture-transformation-tab')),
										of_product: t('of_product'),
										an_ingredient: t('an_ingredient'),
										product_lowercase: toLowerCase(t('product')),
									}),
								}}
								className={styles.text}
							></span>
						</div>
					);
				}}
			>
				<Info className="grey700" size={22} />
			</BPopover>
		</div>
	);
};

export default AuditContentField;
