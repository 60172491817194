import { css, cx } from '@emotion/css';
import Typography from '../../../components/Typography';
import { siteColors } from '../../../lib/colors';
const styles = {
	square: css({
		height: 8,
		width: 8,
		borderRadius: '50%',
		background: 'var(--background)',
	}),
	container: css({
		minWidth: 125,
	}),
	tonnage: css({
		color: `${siteColors.grey7} !important`,
		minWidth: 80,
		textAlign: 'right',
	}),
	percent: css({
		color: `${siteColors.grey7} !important`,
		minWidth: 55,
		textAlign: 'right',
	}),
};

const items = [
	{
		label: 'Recyclabilité',
		color: siteColors.green500,
	},
	{
		label: 'Enfouissement',
		color: siteColors.blue500,
	},
	{
		label: 'Incinération',
		color: siteColors.orange500,
	},
];
const DefaultLegend = () => {
	return (
		<div className="flexColumn gap16">
			{items.map((item: any, index: number) => {
				return <DefaultItemDisplay key={index} label={item.label} color={item.color} />;
			})}
		</div>
	);
};

type DefaultItemDisplay = {
	label: string;
	color: string;
};
const DefaultItemDisplay = ({ label, color }: DefaultItemDisplay) => {
	return (
		<div className={cx('flexRow alignCenter gap8', styles.container)}>
			<div className={styles.square} style={{ ['--background' as any]: color }} />
			<Typography className="lineHeight1">{label}</Typography>
		</div>
	);
};

export default DefaultLegend;
