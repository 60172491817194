import { useQuery } from '@tanstack/react-query';

import { countUsersContributeurAction, findUsersAction, FindUsersActionParams } from './user.actions';

// ---- 1 --------------------------------------------------------------------------------

// ---- 2 --------------------------------------------------------------------------------

type UseFindUsersProps = {
	params: FindUsersActionParams;
	options?: {
		enabled?: boolean;
	}
}

export const useFindUsers = ({
	params,
	options = {},
}: UseFindUsersProps) => {
	const key = ['findUsers', params] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: findUsersAction,
		enabled: options?.enabled,
	});

	return { key, result };
};

type UseFindUsersContributeurProps = {
	params: any;

}

export const useCountUsersContributeur = ({
	params,
}: UseFindUsersContributeurProps) => {
	const key = ['countUsersContributeur', params] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: countUsersContributeurAction,
	});

	return { key, result };
};
