import React from 'react';

import { css, cx } from '@emotion/css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel, { type FormControlLabelProps } from '@mui/material/FormControlLabel';
import { siteColors } from '../../../../apps/front/src/lib/colors';

const styles = {
	container: css({
		display: 'flex',
		flexDirection: 'column',
		'& .Mui-checked': {
			color: siteColors.primary,
		},

		label: {
			fontWeight: 600,
			fontSize: 16,
		},
	}),
};

interface BCheckboxProps {
	errorMessage?: any;
	startIcon?: string | React.ReactNode;
	label: string;
	control?: React.ReactNode;
	className?: string;
}

const Input = (
	{
		label,
		errorMessage,
		startIcon,
		control = <Checkbox />,
		className,
		...rest
	}: FormControlLabelProps & BCheckboxProps,
	ref: any,
) => {
	return (
		<div className={cx(styles.container, className)}>
			<FormControlLabel label={label} labelPlacement="end" ref={ref} {...rest} control={control} />
		</div>
	);
};

const BCheckbox = React.forwardRef(Input);

export default BCheckbox;
