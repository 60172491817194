import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { useQuery } from '@tanstack/react-query';

import { getEcoDesignIndicator } from '@carbonmaps/ui/actions/dashboard.actions';
import { capitalizeFirstLetter, toLowerCase } from '@carbonmaps/ui/utils/utils';

import CardContent from '../../../../components/dashboard/v2/CardContent';
import CardTitle from '../../../../components/dashboard/v2/CardTitle';
import DashboardCard from '../../../../components/dashboard/v2/DashboardCard';
import { useYearSelection } from '../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../hooks/useTranslation';

import { siteColors } from '../../../../lib/colors';
import EcoDesignGraph from './EcoDesignGraph';

const styles = {
	content: css({ minHeight: 175 }),
};

const EcoDesignPotential = () => {
	const { t } = useTranslation();
	// params filters
	const [searchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();
	// ---- fetch eco design global information ---- //
	const { data, isLoading } = useQuery({
		queryKey: [
			'getEcoDesignIndicator',
			{
				facetFilters: searchQueryParams?.facetFilters || [],
				period: selectedYear,
			},
		],
		queryFn: getEcoDesignIndicator,
	});

	const dataGraph = useMemo(() => {
		return [
			{
				y: data?.productsEcoDesigned,
				color: siteColors.green500,
				label: t('dashboard-product-eco-designed', {
					products: capitalizeFirstLetter(t('products')),
					products_lowercase: toLowerCase(t('products')),
				}),
			},

			{
				y: data?.productsEcoDesign,
				color: siteColors.primary,
				label: t('dashboard-product-eco-design', {
					products: capitalizeFirstLetter(t('products')),
					products_lowercase: toLowerCase(t('products')),
				}),
			},
			{
				y: data?.noRecipeProducts,
				scoring: '-',
				color: siteColors.grey400,
				label: t('dashboard-no-recipe', {
					products: capitalizeFirstLetter(t('products')),
					products_lowercase: toLowerCase(t('products')),
				}),
			},
		];
	}, [data, t]);

	return (
		<DashboardCard title={<CardTitle title={t('dashboard-eco-design-potential-card-title')} />} className="flex1">
			<CardContent className={cx('flexCenter justifyCenter', styles.content)} loading={isLoading}>
				<EcoDesignGraph data={dataGraph} />
			</CardContent>
		</DashboardCard>
	);
};

export default EcoDesignPotential;
