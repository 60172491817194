import { type QueryFunctionContext } from '@tanstack/react-query';

type getDataGlossaryType = {
	page: number;
	size: number;
};

/**
 *  get glossary
 * @param context
 * @returns
 */
export const getGlossariesAction = async (
	context: QueryFunctionContext<readonly ['getGlossariesAction', getDataGlossaryType]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, ...rest },
			},
		} = context;

		const { data, count } = await Parse.Cloud.run('findGlossaries', {
			limit: size,
			skip: (page - 1) * size,
			pageIndex: page,
			...rest,
		});

		return {
			data,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 *
 * @param data
 * @returns
 */
export const saveGlossaryAction = async (data: any) => {
	try {
		const res = await Parse.Cloud.run('saveGlossary', data);
		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};
