import Typography from '@mui/material/Typography';

const Text = (props: any) => {
	const {
		value,
		// column: { props: addProps },
	} = props;

	return (
		<>
			{/* {addProps?.icon} */}
			<Typography>{value}</Typography>
		</>
	);
};

export default Text;
