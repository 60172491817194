import { useCallback } from 'react';

import { css, cx } from '@emotion/css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { use } from 'i18next';
import { Share2 } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import Popover from '../Popover';

type Props = {
	anchorEl: Element | null;
};

const styles = {
	button: css({
		textTransform: 'unset !important',
		'&:hover': {
			background: 'unset !important',
		},
	} as any),
	shareButton: css({
		marginRight: '12px',
	}),

	popoverContent: css({
		padding: 24,
		width: 445,
	}),
	sharePage: css({
		fontWeight: 600,
		marginBottom: '16px',
	}),

	content: css({
		marginBottom: 16,
		padding: 16,
		borderRadius: '8px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	}),
	copyText: css({
		fontWeight: 600,
		color: '#FFF !important',
	}),
};

const ShareButton = ({ anchorEl }: Props) => {
	const { t } = useTranslation();
	const analytics = useJuneTrack();

	const track = useCallback(() => {
		analytics.track(
			'General Share',

			{
				// Add the GROUP_ID here to track this event on behalf of a workspace
				context: { category: 'General' },
			},
		);
	}, [analytics]);

	return (
		<Popover
			disableScrollLock
			anchorEl={anchorEl ?? undefined}
			anchorOrigin={{
				horizontal: 'right',
				vertical: 'bottom',
			}}
			trigger={
				<Button disableRipple className={styles.button}>
					<Share2 className={cx('grey700', styles.shareButton)} />
					<Typography className="fontWeight600 grey900">{t('share')}</Typography>
				</Button>
			}
			renderContent={({ close }) => {
				return (
					<div className={styles.popoverContent}>
						<Typography className={styles.sharePage}>{t('share-page-title')}</Typography>
						<div className={cx(styles.content, 'borderGrey700')}>{window.location.href}</div>
						<BButton
							label={<Typography className={styles.copyText}>{t('copy-link-text')}</Typography>}
							variant="primary"
							addStyles={{ borderRadius: '8px' }}
							onClick={() => {
								navigator.clipboard.writeText(window.location.href);
								close();
							}}
						/>
					</div>
				);
			}}
			onClick={track}
		/>
	);
};

export default ShareButton;
