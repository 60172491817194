import { useEffect, useState } from 'react';

import { eachOfLimit } from 'async';

import { getFacetConfig, getFacetLabel, getLabelValue } from '@carbonmaps/shared/utils/utils';

import { IFilterFolder } from '../../components/Filter';
import { Option } from '../../components/form/fields/MultiSelectCheckboxField';
import FilterContainer from '../../components/layout/list/FilterContainer';
import { useSearchQueryParams } from '../../hooks/useSearchQueryParams';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';

import { useAnalyseSetup, useGetAnalyseFacets } from './analyse.hooks';

const AnalyseFilter = () => {
	const [searchQueryParams, setSearchQueryParams] = useSearchQueryParams();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const searchParams = searchQueryParams?.facetFilters ?? [];
	const [appliedValuesByFolders, setAppliedValuesByFolders] = useState<IFilterFolder[]>([]);
	const [selectedValues, setSelectedValues] = useState<Option[]>([]);
	const [filterFolders, setFilterFolders] = useState<IFilterFolder[]>([]);

	// ---- user info to specify facets ---- //
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const { categorySelected } = useAnalyseSetup();

	useEffect(() => {
		setSelectedValues([]);
		setAppliedValuesByFolders([]);
	}, [categorySelected]);

	const {
		data: facetsResult,
		isSuccess: isSuccessFetchFacets,
		isLoading: isFetchFacetLoading,
	} = useGetAnalyseFacets({ searchQueryParams, categorySelected });

	useEffect(() => {
		const asyncWrapper = async () => {
			// don't change current filter
			if (!facetsResult) return;

			const facetFilterFolders: IFilterFolder[] = [];

			for await (const entry of Object.entries(facetsResult.facet)) {
				const [key, value] = entry;
				const { buckets } = value as any;

				if (key === categorySelected) {
					continue;
				}

				const { label, type, global, itemType } = getFacetConfig(key, authData?.session.company) ?? {};

				const facetLabel = label || getFacetLabel(key, authData?.session.company);

				const currentFacetFolder: IFilterFolder = {
					label: facetLabel,
					options: [],
					isFacet: true,
					path: key,
					showCount: false,
				};

				await eachOfLimit(buckets as any[], 100, async (bucket, k) => {
					if (!bucket.count) {
						return;
					}

					currentFacetFolder.options.push({
						value: bucket._id,
						label: getLabelValue(key, bucket._id),
						count: bucket.count,
						folderLabel: facetLabel,
						isFacetBucket: true,
						facetPath: key,
						type: type ?? 'hardcoded-undefined-facet-type',
						global,
						itemType,
					});

					return;
				});

				facetFilterFolders.push(currentFacetFolder);
			}

			setFilterFolders(facetFilterFolders);
		};

		asyncWrapper();
	}, [facetsResult, authData, categorySelected]);

	useEffect(() => {
		//	if there are filters in the url
		if (searchParams && searchParams.length) {
			const currentSelectedValues = [] as any;
			const currentAppliedValuesFolders = [] as any;

			searchParams.forEach((item: any) => {
				const config = getFacetConfig(item.path, authData?.session.company);
				const arrayValues = item.values.map((v: any) => {
					const value = {
						facetPath: item.path,
						value: v,
						folderLabel: item.folderLabel ?? item.path,
						label: getLabelValue(item.path, v),
						isFacetBucket: true,
						type: typeof v,
						global: item.global === true ? true : false,
						itemType: item.itemType,
					};

					currentSelectedValues.push(value);
					return value;
				});

				currentAppliedValuesFolders.push({
					isFacet: true,
					label: item.folderLabel ?? item.path,
					options: arrayValues,
				});
			});

			setSelectedValues(currentSelectedValues);
			setAppliedValuesByFolders(currentAppliedValuesFolders);
		}
	}, [searchParams, authData]);

	return (
		<FilterContainer
			skeleton={isFetchFacetLoading}
			isLoading={false}
			appliedValuesByFolders={appliedValuesByFolders}
			setAppliedValuesByFolders={setAppliedValuesByFolders}
			selectedValues={selectedValues}
			setSelectedValues={setSelectedValues}
			folders={filterFolders}
			setSearchQueryParams={setSearchQueryParams}
			// suppliersFolderLabel={suppliersFolderLabel}
			selectedRow={/* selectedRow */ null}
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			handleExport={/* handleExport */ () => {}}
			isTableLoading={false}
			isExportLoading={/* isExportLoading */ false}
			withMore={false}
			suppliersFolderLabel={'pgoihgpzoehgopgjofnpgnpzeo'}
			searchTrackName="Analyse Search"
			filterTrackName="Analyse Filter"
			trackCategory="Analyse"
			trackingOptions={{ Segment: categorySelected }}
		/>
	);
};

export default AnalyseFilter;
