import { useEffect } from 'react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import EmbedNotion from '../../components/EmbedNotion';
import SEO from '../../components/SEO';

const ReleaseNotes = () => {
	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([{ text: 'Release Notes' }]);
	}, [setBreadcrumbs]);

	return (
		<>
			<SEO title={'Release Notes - CarbonMaps'} />
			<EmbedNotion url="https://v2-embednotion.com/Release-Notes-3ec28a1d498041d783d16d7451741481" />
		</>
	);
};

export default ReleaseNotes;
