import { css, cx } from '@emotion/css';
import MUILink from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import { Link as RouterLink } from 'react-router-dom';

import PointListLabel from '../../../../../apps/front/src/components/modelization/PointListLabel';

type Props = {
	value: any;
	color?: string;
	borderColor?: string;
	backgroundColor?: string;
	column?: any;
	row?: any;
	g?: any
	keyGlossary?: string;
};


const styles = {
	container: css({
		alignContent: 'middle',
		gap: '8px',
	}),
	label: css({
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '150%',
		color: 'var(--color)',
	}),
	puce: css({
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		background: 'var(--backgroundColor)',
		marginTop: '8px',
		border: '1px solid var(--borderColor)',
	}),
	link: css({
		textDecoration: 'none !important',
	}),


};

const ListCell = ({ value, color, borderColor, backgroundColor, column, row, keyGlossary }: Props) => {

	const ComponentLink = MUILink;

	return (
		<ComponentLink
			component={RouterLink}
			to={row.original.link || null}
			onClick={(e) => {
				if (column?.props?.onClick) {
					//	e.preventDefault();
					column?.props?.onClick(row.original);
				}
			}}
			className={cx(styles.link)}
		>

			<PointListLabel value={value}
				color={color}
				backgroundColor={backgroundColor}
				borderColor={borderColor}
				keyGlossary={keyGlossary}
			/>
		</ComponentLink>
	);

};

export default ListCell;
