import { useTheme } from '@mui/material';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import LegendGraph from '../../../containers/dashboard/LegendGraph';
import HorizontalGraph from '../HorizontalGraph';

type DashboardGraphProps = {
	filter: React.ReactNode;
	data: any;
	onClick?: (e?: any) => void;
	renderItem?: (item: any, index: number) => React.ReactNode;
};

const DashboardGraph = ({ filter, data, onClick, renderItem }: DashboardGraphProps) => {
	const theme = useTheme();
	const { indicator } = useApp();
	return (
		<div className="flexColumn width100 gap12">
			{filter}
			<HorizontalGraph data={data} color={theme.palette.indicator[indicator].main} onClick={onClick} width={475} />
			<div className="width100" style={{ paddingTop: 24 }}>
				<LegendGraph data={data} column={3} limit={9} renderItem={renderItem} />
			</div>
		</div>
	);
};

export default DashboardGraph;
