import { useTheme } from '@mui/material';
import { Users } from 'lucide-react';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useTranslation } from '../../../hooks/useTranslation';

type AuditHeaderProps = {
	subtitle?: string;
};

const UserHeader = ({ subtitle }: AuditHeaderProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<ListHeader
			title={t('users-breadcrumbs')}
			subtitle={subtitle}
			icon={
				<IconContainer
					element={<Users size={40} color={theme.palette.common.white} />}
					color={theme.palette.grey[900]}
				/>
			}
			className="bgGrey500"
		/>
	);
};

export default UserHeader;
