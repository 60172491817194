import { useEffect } from 'react';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import Container from '../../../components/layout/list/Container';
import { useTranslation } from '../../../hooks/useTranslation';

import VersionHeader from './VersionHeader';
import VersionTable from './VersionTable';

const Version = () => {
	const { setBreadcrumbs } = useApp();

	const { t } = useTranslation();

	// ---- breadcrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([
			{ text: t('company-breadcrumbs'), link: `${FRONT_PATH_NAMES.superAdminSettings}/companies` },
			{ text: t('version-title') },
		]);
	}, [setBreadcrumbs, t]);

	return (
		<Container header={<VersionHeader subtitle={'test'} />} seoText={`${t('version-title')} - Carbon Maps`}>
			<VersionTable />
		</Container>
	);
};

export default Version;
