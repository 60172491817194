import { css, cx } from '@emotion/css';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Typography from '../../Typography';

import { cn, siteColors } from '../../../lib/colors';
import BarItem from './BarItem';
import LabelAxis from './LabelAxis';

type BarChartProps = {
	title: string;
	data: any;
	height?: number;
	maxWidth?: number;
	gap?: number;
	background?: string;
	className?: string;
};

const styles = {
	container: css({
		borderRadius: 16,
		background: siteColors.common.white,
		border: cn('1px solid', siteColors.grey500),
		padding: '32px 32px',
	}),
	containerChart: css({
		height: 'var(--height)',
		gap: 'var(--gap)',
	}),

	label: css({
		width: 60,
		fontSize: '12px !important',
	}),

	gap11: css({
		gap: 11,
	}),

	bar: css({
		background: siteColors.carbon100,
		width: 'var(--width)',
		height: 'var(--height)',
		borderRight: cn('1px solid', siteColors.primary),
	}),

	value: css({
		color: cn(siteColors.primary, '#!important'),
		textWrap: 'nowrap',
		minWidth: '36px',
	}),
};

const heightContainer = 184;

const HorizontalBarChart = ({
	title,
	data,
	height = 160,
	gap = 8,
	background = siteColors.carbon100,
	maxWidth = 94,
	className,
}: BarChartProps) => {
	const barNumber = data.length;

	const maxPercent = Math.max(
		...data.map((item: any) => {
			return item?.value;
		}),
	);

	return (
		<div className={cx('width100 flexColumn gap24', styles.container, className)}>
			<Typography className="fontWeight600">{title}</Typography>
			<div className="flexColumn width100 gap10">
				{data.map((item: any, index: number) => {
					return (
						<div className={cx('flexRow alignCenter width100 nowrap', styles.gap11)}>
							<Typography className={styles.label}>{item?.label}</Typography>
							<div className={cx('flex1 flexRow alignCenter nowrap', styles.gap11)}>
								<div
									className={styles.bar}
									style={{
										['--width' as any]: `calc(${(item?.value * 100) / maxPercent}% )`,
										['--height' as any]: `${heightContainer / data.length - gap}px`,
									}}
								/>
								<Typography variant="caption" className={styles.value}>
									{formatNumber(item?.value, undefined, 2)} %
								</Typography>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default HorizontalBarChart;
