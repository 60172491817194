import { type QueryFunctionContext } from '@tanstack/react-query';

import { UserAccountFormInput } from '@carbonmaps/shared/validations/user.validations';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { CompanyFormInput } from '../../shared/validations/company.validations';
import { getServerUrl } from '../utils/utils';

export const updateCurrentUserAccountAction = async (values: UserAccountFormInput): Promise<void> => {
	try {
		(await Parse.Cloud.run('updateCurrentUser', values)) as Parse.User;
	} catch (error) {
		console.log(' ------ updateCurrentUserAccountAction error: ', error);
		return Promise.reject(error);
	}
};

export const uploadUserAvatar = async (file: any) => {
	const form = new FormData();
	form.append('file', file);

	const result = await fetch(`${getServerUrl()}uploadFile`, {
		method: 'POST',
		body: form,
	});

	const { file: filename } = await result.json();

	await Parse.Cloud.run('updateCurrentUser', { avatar: filename });
};

export const uploadCompanyLogo = async (values: any) => {
	const { file, ...restValues } = values as any;
	const form = new FormData();
	form.append('file', file);

	const result = await fetch(`${getServerUrl()}uploadFile`, {
		method: 'POST',
		body: form,
	});

	const { file: filename } = await result.json();

	return (await Parse.Cloud.run('updateCompany', { ...restValues, logo: filename })) as any;
};

export const updateCurrentCompanyAction = async (values: CompanyFormInput): Promise<void> => {
	try {
		const { file } = values as any;
		let companyToDb = values as any;

		// upload file
		if (file) {
			const form = new FormData();
			form.append('file', file);
			const result = await fetch(`${getServerUrl()}uploadFile`, {
				method: 'POST',
				body: form,
			});

			const { file: filename } = await result.json();
			companyToDb = { ...companyToDb, logo: filename };
		}

		(await Parse.Cloud.run('updateCompany', companyToDb)) as Parse.User;
	} catch (error) {
		console.log(' ------ updateCurrentCompanyAction error: ', error);
		return Promise.reject(error);
	}
};

type SwitchUserCompanyInput = { company: Parse.Object, user: Parse.User };

export const switchUserCompany = async (input: SwitchUserCompanyInput) => {
	try {
		input.user.set('company', input.company);
		await input.user.save(null, { sessionToken: input.user.getSessionToken() });
	} catch (error) {
		console.log(' ------ switchUserCompany error: ', error);
		return Promise.reject(error);
	}
};



export const updateSupplierUserAction = async (values: any): Promise<void> => {
	try {
		await Parse.Cloud.run('updateSupplierUser', values);
	} catch (error) {
		console.log(' ------ updateSupplierUserAction error: ', error);
		return Promise.reject(error);
	}
};


