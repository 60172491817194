import { css, cx } from '@emotion/css';
import GlobalIndicatorBlock from './GlobalIndicatorBlock';
import MaterialTable from './MaterialTable';
import RecycledMaterialsBlock from './RecycledMaterialsBlock';
const styles = {
	container: css({
		paddingBottom: 85,
	}),
};

const PackagingsRecycled = () => {
	return (
		<div className={cx('flexColumn width100 gap24', styles.container)}>
			<GlobalIndicatorBlock />
			<RecycledMaterialsBlock />
			<MaterialTable />
		</div>
	);
};

export default PackagingsRecycled;
