import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { AlertCircle } from 'lucide-react';
import { nanoid } from 'nanoid';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import z from 'zod';
import { siteColors } from '../../../../lib/colors';
import { useFindQuestionSettings, useSaveDefaultPonderation } from '../hooks';

const TYPE_SCORE_MASTER = ['A+', 'A', 'B', 'C', 'D', 'E'];

const ponderationSchema = z.object({
	// default: z.number(),
	'A+': z.number().min(0),
	A: z.number().min(0),
	B: z.number().min(0),
	C: z.number().min(0),
	D: z.number().min(0),
	E: z.number().min(0),
});

const PonderationsTab = () => {
	const params = useParams();
	const { mutate: saveDefaultPonderation, isLoading } = useSaveDefaultPonderation();
	const queryClient = useQueryClient();

	const { /* data: qFrom, */ refetch } = useFindQuestionSettings({
		questionFormId: _.toString(params.questionFormId),
		enabled: false,
	});

	const form = useForm({
		resolver: zodResolver(ponderationSchema),
		defaultValues: async () => {
			// const data = queryClient.getQueryData([
			// 	'questionSettings',
			// 	{ questionFormId: _.toString(params.questionFormId) },
			// ]);

			// if (data) {
			// 	return _.get(data, 'defaultPonderation');
			// }

			const result = await refetch();

			const pnd = _.get(result.data, 'defaultPonderation');

			return pnd;
		},
	});

	const onSubmit = form.handleSubmit(
		(data) => {
			console.log('data', data);
			saveDefaultPonderation({ questionFormId: _.toString(params.questionFormId), ponderation: data });
		},
		(errors) => {
			console.log('errors', errors);
		},
	);

	return (
		<div className="mt-3">
			<div className="flexRow alignCenter">
				-&nbsp;&nbsp; <AlertCircle color={siteColors.grey700} size={20} />
				&nbsp; Si aucune <b>"master question"</b> n'est définie, les paramètres suivants seront utilisés pour la
				pondération des questions{' '}
			</div>
			<div className="mt-3">
				{TYPE_SCORE_MASTER.map((code) => {
					return (
						<div key={nanoid()} css={{ marginBottom: 16 }}>
							<PointForScore form={form} code={code} />
						</div>
					);
				})}
				<BButton
					variant="primary"
					label={isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Sauvegarder'}
					onClick={() => {
						// onSubmit();
						onSubmit();
					}}
					// onClick={form.handleSubmit((data) => {
					// 	console.log(data);
					// })}
				/>
			</div>
		</div>
	);
};

export default PonderationsTab;

const PointForScore = ({ form, code }: { form: any; code: any }) => {
	// const typeResponse = 'statement_answer';
	return (
		<BInput
			label={code === 'default' ? 'Points' : `Points ${code}`}
			type="number"
			{...form.register(`${code}` as const, { valueAsNumber: true })}
			error={!!_.get(form.formState.errors, code)}
			errorMessage={_.get(form.formState.errors, `${code}.message`)}
		/>
	);
};
