import { Fragment, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useQueryClient } from '@tanstack/react-query';
import { AlertCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ReactComponent as ArrowDownIcon } from '@carbonmaps/media/icons/chevron-1.svg';
import { ROLES_HIERARCHY, roleLabels } from '@carbonmaps/shared/utils/constants';
import { isSuperAdmin } from '@carbonmaps/shared/utils/parseRole.utils';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import AlertStatic from '../../../../../apps/front/src/components/Toast/AlertStatic';
import { useQueryParams } from '../../../../../apps/front/src/hooks/useSearchParams';
import { useGetClientAuthQuery } from '../../../../../apps/front/src/lib/react-query/features/auth/auth.hooks';
import useSaveUserMutation from '../../../hooks/useSaveUserMutation';
import { getIsoCountryCode } from '../../../utils/utils';
import BInput from '../BInput';

import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import ChipsRole from './ChipsRole';

type Props = {
	isOpenRowAdd: boolean;
	setIsOpenRowAdd?: any;
};

const CreateUserFormInputSchema = Yup.object().shape({
	email: Yup.string().email('Entrez un adresse e-mail valide').required('Entrez votre adresse e-mail'),
	firstName: Yup.string().required('Entrez votre prénom'),
	lastName: Yup.string().required('Entrez votre nom'),
	roleCode: Yup.string(),
	companyId: Yup.string().optional(),
});

type CreateUserFormInput = Yup.InferType<typeof CreateUserFormInputSchema>;

const RowUserForm = ({ isOpenRowAdd, setIsOpenRowAdd }: Props) => {
	const theme = useTheme();
	const { t, i18n } = useTranslation();
	const queryClient = useQueryClient();
	const [role, setRole] = useState({ label: roleLabels.USER, suffixName: 'user' } as any);
	const [message, setMessage] = useState('');
	const queryParams = useQueryParams();

	const initialeValue = {
		email: '',
		lastName: '',
		firstName: '',
		roleCode: roleLabels.USER,
		companyId: queryParams?.companyId ? queryParams?.companyId[0] : '',
	};

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const {
		register,
		handleSubmit,
		setValue,
		reset: resetReactForm,
		formState: { errors, isDirty, isValid },
	} = useForm<CreateUserFormInput>({
		defaultValues: initialeValue,
		mode: 'onChange',
		resolver: yupResolver(CreateUserFormInputSchema) as any,
	});

	const onSubmit = async (values: any) => {
		createUser(values);
	};

	const {
		result: { mutate: createUserRow, isLoading, isError, isSuccess, reset },
	} = useSaveUserMutation({
		onSuccess: () => {
			reset();
			queryClient.invalidateQueries({ queryKey: ['findUsers'] });
			setIsOpenRowAdd(false);
			resetReactForm();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const createUser = async (values: any) => {
		const { email, lastName, firstName } = values;

		if (email) {
			await createUserRow({
				email,
				lastName,
				firstName,
				roleCode: role.suffixName,
				companyId: queryParams?.companyId?.[0] ?? authData?.session.company?.objectId,
				position: 'Autre',
				languageCode: getIsoCountryCode(i18n.language),
			});
		}
	};

	if (isOpenRowAdd === false) {
		return null;
	}

	return (
		<>
			<tr>
				<td colSpan={2}>
					<Box sx={{ padding: '4px' }}>
						<BInput
							type="text"
							required
							id="email"
							disabled={isLoading}
							placeholder={'Email'}
							label=""
							{...register('email')}
							name="email"
							error={errors.email ? true : false}
							autoComplete="off"
						/>
					</Box>
				</td>
				<td className="rowFormUser">
					<Box className="flex " sx={{ gap: '4px', marginLeft: '4px' }}>
						<BInput
							type="text"
							required
							id="firstName"
							disabled={isLoading}
							placeholder={'Prénom'}
							label=""
							{...register('firstName')}
							name="firstName"
							error={errors.firstName ? true : false}
							autoComplete="off"
						/>
						<BInput
							type="text"
							required
							id="lastName"
							disabled={isLoading}
							placeholder={'Nom'}
							label=""
							{...register('lastName')}
							name="lastName"
							error={errors.lastName ? true : false}
							autoComplete="off"
						/>
					</Box>
				</td>
				<td>
					<BPopover
						anchorOriginVertical={'bottom'}
						anchorOriginHorizontal={'left'}
						transformOriginVertical={'top'}
						transformOriginHorizontal={'left'}
						content={(onClose) => {
							return (
								<List css={{ width: 250 }}>
									{ROLES_HIERARCHY.map((role, index) => {
										return (
											<Fragment key={role.label + index}>
												<ListItemButton
													onClick={() => {
														setRole(role);
														onClose();
													}}
												>
													<ChipsRole role={role} />
												</ListItemButton>
												{index < ROLES_HIERARCHY.length - 1 && <Divider />}
											</Fragment>
										);
									})}
								</List>
							);
						}}
					>
						<BButton
							iconRight={<ArrowDownIcon />}
							variant="secondary"
							label={<ChipsRole role={role} />}
							addStyles={{
								display: 'flex',
								justifyContent: 'space-between',
								width: '100%',
								background: 'none',
								padding: 0,
								'&:hover': { background: 'none' },
							}}
						/>
					</BPopover>
				</td>
				<td css={{ textAlign: 'center', height: 50 }}>
					<BPopover
						anchorOriginVertical={'bottom'}
						anchorOriginHorizontal={'right'}
						transformOriginVertical={'top'}
						transformOriginHorizontal={'right'}
						addStyles={{ height: '48px' }}
						content={(onClose) => {
							return (
								<Box
									sx={{
										p: '24px',
										width: '294px',
										borderRadius: '8px',
										border: cn('1px solid', siteColors.grey500),
										background: '#FFFFFF',
										boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
									}}
								>
									<Typography
										textAlign={'left'}
										sx={{
											fontWeight: 600,
											fontSize: 16,
										}}
									>
										Créer cet utilisateur?
									</Typography>
									{!isSuperAdmin && (
										<Typography textAlign={'left'}>
											Il recevra un e-mail avec ses identifiants pour se connecter
										</Typography>
									)}

									<div
										css={{
											display: 'flex',
											flexDirection: 'column',
											gap: 20,
											width: '100%',
											marginTop: '16px',
										}}
									>
										<BButton
											onClick={handleSubmit(onSubmit)}
											variant="primary"
											label={
												isLoading ? (
													<CircularProgress size={20} css={{ color: theme.palette.common.white }} />
												) : (
													<Typography color="white" fontWeight={600}>
														Valider
													</Typography>
												)
											}
											addStyles={{
												display: 'inline-flex',
												alignSelf: 'left',
												width: '52px',
												padding: '16px',
												fontWeight: 600,
												fontSize: '16px',
											}}
										/>
									</div>
								</Box>
							);
						}}
					>
						<BButton
							addStyles={{
								minWidth: '44px',
								width: '44px',
								height: '44px',
							}}
							label={<CheckIcon sx={{ color: 'white' }} />}
							variant="primary"
							variantDisabled="secondary"
						/>
					</BPopover>
				</td>
			</tr>

			{errors.email && errors?.email?.message && (
				<Box
					sx={{
						position: 'fixed',
						bottom: '30px',
						zIndex: 10000,
					}}
				>
					<AlertStatic
						text={errors.email ? errors?.email?.message : ''}
						type="error"
						icon={<AlertCircle css={{ color: '#C74600' }} />}
					/>
				</Box>
			)}

			{isError && (
				<Box
					sx={{
						position: 'fixed',
						bottom: '30px',
						zIndex: 10000,
					}}
				>
					<AlertStatic text={t(message)} type="error" icon={<AlertCircle css={{ color: '#C74600' }} />} />
				</Box>
			)}
		</>
	);
};

export default RowUserForm;
