import { css, cx } from '@emotion/css';

import { cn, siteColors } from '../../lib/colors';
import Typography from '../Typography';

const styles = {
	container: css({
		height: 142,
		justifyContent: 'center',
		paddingBottom: 10,
	}),
	barContainer: css({
		height: 13,
		background: siteColors.grey400,
		borderRadius: 32,
		position: 'relative',
	}),
	progress: css({
		position: 'absolute',
		width: 'var(--width)',
		background: siteColors.primary,
		borderRadius: 32,
		height: '100%',
	}),
	value: css({
		fontSize: '24px !important',
		lineHeight: '1 !important',
		color: cn(siteColors.primary, '#!important'),
	}),
	label: css({
		textTransform: 'uppercase',
	}),
};

const ProgressBar = ({ item }: any) => {
	return (
		<div className={cx(styles.container, 'flex1 flexColumn gap12')}>
			<div className="flexColumn gap4">
				<Typography className={cx('fontWeight500', styles.value)}>{item.exploitation}</Typography>
				<Typography className={cx('lineHeight1', styles.label)}>exploitations</Typography>
			</div>
			<div className={cx('width100', styles.barContainer)}>
				<div className={styles.progress} style={{ ['--width' as any]: `${item?.exploitation}%` }} />
			</div>
		</div>
	);
};

export default ProgressBar;
