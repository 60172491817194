import { ReactNode, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';
import { darken, lighten } from '@mui/system/colorManipulator';
import _ from 'lodash';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { toPercentages } from '../../utils/num.utils';

import { siteColors } from '../../lib/colors';
import TooltipChart from './TooltipChart';

type Props = {
	width: number;
	innerRadius?: number;
	color?: string;
	data: (Record<string, string | number> & { y: number; category: string; categoryValue: string | number })[];
	content?: ReactNode;
	indicator?: any;
	onClick?: (item: any) => void;
	disabled?: boolean;
	renderTooltip?: (item: any, indicator: any) => ReactNode;
	renderContent?: (indicator: any) => ReactNode;
	withSameColor?: boolean;
	sx?: SxProps;
	tooltipPosition?: any;
	radiusDonnut?: number;
	withTooltip?: boolean;
	topPosition?: number;
};

//--------------------------------------------------------------------------------------//
//                                      utilities                                       //
//--------------------------------------------------------------------------------------//
const calculateEndAngle = (startAngle: number, percentage: number) => {
	return startAngle + (percentage / 100) * (2 * Math.PI);
};

const DonutChart = ({
	color = siteColors.primary,
	data,
	content,
	width,
	onClick,
	innerRadius = 32,
	indicator,
	disabled = false,
	withSameColor = false,
	renderTooltip,
	renderContent,
	tooltipPosition,
	withTooltip = true,
	topPosition = 180,
	sx = {},
	radiusDonnut,
}: Props) => {
	const [dataGraph, setDataGraph] = useState<any>([]);
	const [selectedItem, setSelectedItem] = useState(null);

	const { indicator: viewModeSelected } = useApp();
	const tooltipRef = useRef<any>();
	const svgRef = useRef<any>();

	// const height = width;
	const viewBoxWidth = width;
	const viewBoxHeight = viewBoxWidth;
	const radius = radiusDonnut || viewBoxWidth / 2;
	const _innerRadius = (viewBoxWidth * innerRadius) / 100;
	const innerBoxWidth = _innerRadius * Math.sqrt(2); /* - 1 */
	const innerBoxHeight = innerBoxWidth;

	let percentages = useMemo(() => {
		setDataGraph(
			data.map((item: any, index: number) => {
				return {
					...item,
					color: item.color
						? item.color
						: withSameColor
						? color
						: index < 6
						? darken(color, (6 - index) / 10)
						: lighten(color, index < 8 ? index / 10 : index % 2 === 0 ? 9 / 10 : 8 / 10),
				};
			}),
		);

		return data.length < 2
			? [99.9]
			: toPercentages(
					data.map((item: any) => {
						return item?.y || 0;
					}),
			  );
	}, [color, data, withSameColor]);

	//to resolve probleme with percentage 100
	percentages = _.map(percentages, (element, index) => {
		if (element === 100) {
			return 99.9;
		}

		if (element === 0) {
			return 0.1;
		}

		return element;
	});

	let startAngle = -Math.PI / 2;

	const onMouseEnter = (evt: any, data: any) => {
		const CTM = svgRef.current.getScreenCTM();

		const mouseX = (evt.clientX - CTM.e) / CTM.a;
		const mouseY = (evt.clientY - CTM.f) / CTM.d;
		const top = tooltipPosition ? mouseY - tooltipPosition.y : mouseY - topPosition;

		tooltipRef.current.setAttributeNS(null, 'visibility', 'visible');

		tooltipRef.current.setAttributeNS(
			null,
			'style',
			`left: ${mouseX + 6 / CTM.a}px; top: ${top}px; position: absolute; zIndex: 100000; display:none`,
		);
		setSelectedItem(data);
	};

	const handleClick = (item: any) => {
		onClick && onClick(item);
	};

	return (
		<Box
			sx={{
				flex: 1,
				position: 'relative',
				'&:hover .custom-tooltip': { display: 'block !important', zIndex: 100000 },
				...sx,
			}}
		>
			{withTooltip && (
				<Box
					className="custom-tooltip"
					ref={tooltipRef}
					style={{ display: 'block', background: 'red', position: 'absolute', left: 100 }}
				>
					{renderTooltip ? (
						renderTooltip(selectedItem, indicator)
					) : (
						<TooltipChart item={selectedItem} indicator={indicator} viewModeSelected={viewModeSelected} />
					)}
				</Box>
			)}
			<svg
				ref={svgRef}
				viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
				width={'100%'}
				height={'auto'}
				xmlns="http://www.w3.org/2000/svg"
			>
				{percentages.map((percentage, index) => {
					const endAngle = calculateEndAngle(startAngle, percentage);
					const x1 = viewBoxWidth / 2 + radius * Math.cos(startAngle);
					const y1 = viewBoxHeight / 2 + radius * Math.sin(startAngle);
					const x2 = viewBoxWidth / 2 + radius * Math.cos(endAngle);
					const y2 = viewBoxHeight / 2 + radius * Math.sin(endAngle);
					const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';

					const path = `M${viewBoxWidth / 2},${
						viewBoxHeight / 2
					} L${x1},${y1} A${radius},${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`;

					startAngle = endAngle;

					return (
						<path
							stroke-width={dataGraph[index]?.strokeColor || 0}
							stroke={dataGraph[index]?.strokeColor || siteColors.common.white}
							key={index}
							d={path}
							fill={dataGraph[index]?.color || siteColors.grey400}
							onMouseEnter={(evt: any) => {
								onMouseEnter(evt, dataGraph[index]);
							}}
							style={{ cursor: 'pointer', ...(disabled && { cursor: 'default' }) }}
							onClick={(evt: any) => {
								handleClick(dataGraph[index]);
							}}
						/>
					);
				})}

				{dataGraph[0]?.strokeColor && (
					<circle cx={viewBoxWidth / 2} cy={viewBoxHeight / 2} r={_innerRadius + 1} fill="#DCDCDC" />
				)}

				<circle cx={viewBoxWidth / 2} cy={viewBoxHeight / 2} r={_innerRadius} fill="#ffffff" />
				<foreignObject
					fill="red"
					x={viewBoxWidth / 2 - innerBoxWidth / 2}
					y={viewBoxHeight / 2 - innerBoxHeight / 2}
					width={innerBoxWidth}
					height={innerBoxHeight}
				>
					<Box width="100%" height="100%" sx={{ display: 'grid', placeItems: 'center' }}>
						{renderContent ? renderContent(indicator) : <>{content}</>}
					</Box>
				</foreignObject>
			</svg>
		</Box>
	);
};

export default DonutChart;

type AnalysisDonutContentProps = {
	icon: ReactNode;
	value: number | string;
	label: string;
};

export const AnalysisDonutContent = ({ icon, value, label }: AnalysisDonutContentProps) => {
	const theme = useTheme();

	return (
		<Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
			<Box>
				<Box display="flex" alignItems="center" marginBottom={pxToRem(4)}>
					{icon}
					<Typography variant="h1" marginLeft={pxToRem(6)}>
						{value}
					</Typography>
				</Box>
				<Typography color={theme.palette.grey[700]} textAlign="center" fontWeight={500}>
					{label}
				</Typography>
			</Box>
		</Box>
	);
};
