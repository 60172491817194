import useTheme from '@mui/material/styles/useTheme';
import { Contact2 } from 'lucide-react';

import { css } from '@emotion/css';
import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useGetCountReportsClient } from '../../../lib/react-query/features/sales/sales.hooks';

const styles = {
	headerBg: css({
		background: cn(siteColors.water10, '!important'),
		// background: cn(siteColors.water500, '!important'),
	}),
};


const ClientReportsHeader = () => {
	const { t } = useTranslation();

	// ---- fetch count ---- //
	const {
		key,
		result: { data, isLoading: isTableLoading },
	} = useGetCountReportsClient();


	return (
		<ListHeader
			title={t('rapports-client')}
			subtitle={`${data?.data} ${t('clients')}`}

			icon={<IconContainer
				color={siteColors.water500}
				element={<Contact2 color={siteColors.common.white} size={32} />} />}
			className={styles.headerBg}
		/>
	);
};

export default ClientReportsHeader;
