import { css, cx } from '@emotion/css';
import Typography from '../../components/Typography';
import { useTranslation } from '../../hooks/useTranslation';

const styles = {
	container: css({
		height: '100%',
		textAlign: 'center',
	}),
};
type EmptyImpactProps = {
	text: string;
};

const EmptyImpact = ({ text }: EmptyImpactProps) => {
	const { t } = useTranslation();

	return (
		<div className={cx('flexCenter width100', styles.container)}>
			<Typography className="grey700">{text}</Typography>
		</div>
	);
};

export default EmptyImpact;
