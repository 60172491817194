import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { emphasize } from '@mui/material/styles';
import { ArrowDownRight, ArrowUpRight, BarChart2, Cloud, Edit2, FileEdit } from 'lucide-react';

import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Unit from '../../components/units/Unit';

import Difference from './Difference';
import IndicatorTag from './IndicatorTag';

type Props = {
	icon?: ReactNode;
	text: ReactNode;
	unit?: ReactNode;
	difference?: number;
	value?: number;
	withDifference?: boolean;
	precision?: number;
	abbreviatedNumbers?: boolean;
	showLte?: boolean;
	showZero?: boolean;
};

// const carbonColor = siteColors.primary;

const styles = {
	// top: css({
	// 	color: carbonColor,
	// 	marginBottom: '8px',
	// }),
	// value: css({
	// 	fontSize: '24px!important',
	// 	marginLeft: '7px',
	// }),
	unit: css({
		marginBottom: '12px',
	}),
};

const IndicatorItem = ({
	icon,
	text,
	value = 0,
	unit,
	difference = 0,
	withDifference = true,
	precision = 2,
	abbreviatedNumbers,
	showLte = false,
	showZero = false,
}: Props) => {
	return (
		<div>
			<IndicatorTag icon={icon} value={value} precision={precision} abbreviatedNumbers={abbreviatedNumbers} />
			<div className={cx(/* styles.text,  */ 'grey800 fontWeight600')}>{text}</div>
			<div className={cx(styles.unit)}>{unit || <Unit className={cx('grey700')} measure="carbonIntensityKg" />}</div>
			{withDifference && <Difference value={difference} withEquals={false} showZero={showZero} showLte={showLte} />}
		</div>
	);
};

export default IndicatorItem;
