import { css, cx } from '@emotion/css';
import KeyboardArrowRight from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { translation } from '../../../i18n/translation';

interface PaginationProps {
	gotoPage: (a?: any) => void;
	nextPage: (a?: any) => void;
	previousPage: (a?: any) => void;
	setPageSize: (a?: any) => void;
	canNextPage: boolean;
	canPreviousPage: boolean;
	pageCount: any;
	pageOptions: any;
	pageIndex: any;
	pageSize: any;
	isLoading: boolean;
	transFunc?: any;
	onNextClick?: () => void;
}

const styles = {
	container: css({
		justifyContent: 'flex-end',
		gap: 10,
		margin: 10,
	}),
	loadingContainer: css({
		padding: '12px !important',
	}),
};

export const Pagination = ({
	gotoPage,
	nextPage,
	setPageSize,
	previousPage,
	canNextPage,
	canPreviousPage,
	pageCount,
	pageOptions,
	pageIndex,
	pageSize,
	isLoading,
	transFunc,
	onNextClick,
}: PaginationProps) => {
	const t = transFunc || translation;
	return (
		<div className={cx('flexRow pagination', styles.container)}>
			{isLoading && (
				<span className={styles.loadingContainer}>
					<CircularProgress color="inherit" size={20} />
				</span>
			)}
			<Button
				onClick={() => {
					return gotoPage(1);
				}}
				disabled={!canPreviousPage}
			>
				{canPreviousPage ? (
					<KeyboardDoubleArrowLeft className="grey900" />
				) : (
					<KeyboardDoubleArrowLeft className="grey700" />
				)}
			</Button>
			<Button
				onClick={() => {
					return previousPage();
				}}
				disabled={!canPreviousPage}
			>
				{canPreviousPage ? <KeyboardArrowLeft className="grey900" /> : <KeyboardArrowLeft className="grey700" />}
			</Button>
			<Button
				onClick={() => {
					if (onNextClick) onNextClick();
					return nextPage();
				}}
				disabled={!canNextPage}
			>
				{canNextPage ? <KeyboardArrowRight className="grey900" /> : <KeyboardArrowRight className="grey700" />}
			</Button>
			<Button
				onClick={() => {
					return gotoPage(pageCount);
				}}
				disabled={!canNextPage}
			>
				{canNextPage ? (
					<KeyboardDoubleArrowRight className="grey900" />
				) : (
					<KeyboardDoubleArrowRight className="grey700" />
				)}
			</Button>{' '}
			<span className={styles.loadingContainer}>
				Page {pageIndex + 1} {t('sur')} {pageOptions?.length}
			</span>
			<Select
				value={pageSize}
				onChange={(e) => {
					setPageSize(Number(e.target.value));
				}}
			>
				{[10, 20, 30, 40, 50, 100, 500, 1000].map((pageSize) => {
					return (
						<MenuItem key={pageSize} value={pageSize}>
							{pageSize} {t('lignes')}
						</MenuItem>
					);
				})}
			</Select>
		</div>
	);
};
