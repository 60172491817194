import React from 'react';

import { css, cx } from '@emotion/css';

import { cn, siteColors } from '../../../lib/colors';
import Skeleton from '../../Skeleton';

type CardContentProps = {
	children: React.ReactNode;
	button?: React.ReactNode;
	className?: string;
	loading?: boolean;
	withButton?: boolean;
	onClick?: (e: any) => void;
};

const styles = {
	container: css({
		background: siteColors.common.white,
		border: cn('1px solid', siteColors.grey500),
		borderRadius: 16,
		padding: '24px 32px',
	}),

	skeleton: css({
		height: '100% !important',
		width: '100% !important',
		flex: 1,
	} as any),
};

const CardContent = ({ children, button, className, loading, onClick, withButton = true }: CardContentProps) => {
	return (
		<div className={cx('flexColumn width100 gap24 justifyBetween', styles.container, className)} onClick={onClick}>
			{loading && <Skeleton width="100%!important" className={styles.skeleton}></Skeleton>}
			{!loading && (
				<>
					{children}
					{withButton ? button : null}
				</>
			)}
		</div>
	);
};

export default CardContent;
