import { css, cx } from '@emotion/css';

import Typography from '../Typography';

import { cn, siteColors } from '../../lib/colors';
import LabelDiffCard from './LabelDiffCard';
import ProgressBar from './ProgressBar';

const styles = {
	container: css({
		borderRadius: 16,
		border: cn('1px solid', siteColors.grey500),

		overflow: 'hidden',
		gap: 38,
	}),

	titleContainer: css({
		padding: 'auto',
		width: 280,
		background: siteColors.carbon100,
		height: '100%',
	}),

	fontSize24: css({
		fontSize: '24px !important',
	}),
};

const LabelCarbonCard = ({ item }: any) => {
	return (
		<div className={cx('width100 flexRow alignCenter', styles.container)}>
			<div className={cx('flexCenter', styles.titleContainer)}>
				<Typography className={cx('fontWeight600', styles.fontSize24)}>{item?.year}</Typography>
				<Typography>{item?.label}</Typography>
			</div>
			<ProgressBar item={item} />
			<LabelDiffCard item={item} />
		</div>
	);
};

export default LabelCarbonCard;
