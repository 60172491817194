import { css, cx } from '@emotion/css';

import Difference from '../../containers/scenario/Difference';
import { cn, siteColors } from '../../lib/colors';
import Typography from '../Typography';

const styles = {
	value: css({
		fontSize: '24px !important',
		fontWeight: '500 !important',
		color: cn(siteColors.primary, '#!important'),
	}),
	caption: css({
		textTransform: 'uppercase',
		color: cn(siteColors.grey800, '!important'),
	}),
	diff: css({
		flexDirection: 'row-reverse',
		background: 'none',
		fontWeight: '700 !important',
	}),

	subtitle: css({
		color: cn(siteColors.grey800, '!important'),
		textTransform: 'uppercase',
	}),
};

const LabelDiffCard = ({ item }: any) => {
	return (
		<div className="flex1">
			<Typography className={cx('lineHeight1', styles.value)}>{item?.impact} T</Typography>
			<Typography className={styles.caption} variant="caption">
				eqCO2 économisées
			</Typography>
			{/* <div className="flexRow width100 alignCenter">
				<Difference value={item?.diff} className={styles.diff} decimal={1} />
				<Typography variant="caption" className={styles.subtitle}>
					par rapport au total
				</Typography>
			</div> */}
		</div>
	);
};

export default LabelDiffCard;
