import MUILink from '@mui/material/Link';
import { MouseEventHandler, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CircularProgress, Typography } from '@mui/material';
import MuiDialog, { type DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import { AlertCircle, FileDown, XCircle } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import { css, cx } from '@emotion/css';
import { cn, siteColors } from '../../lib/colors';
import AlertStatic from '../Toast/AlertStatic';


const styles = {
	link: css({
		fontSize: '14px !important',
		color: cn(siteColors.primary, '!important'),
	}),
	fileInput: css({
		fontSize: '14px !important',
		color: cn(siteColors.primary, '!important'),
		width: '402px',
		height: '200px',
		justifyContent: 'center !important',
		alignItems: 'center !important',
		textAlign: 'center',
		padding: '8px 16px',
		border: cn('1px dashed', siteColors.grey500),
		borderRadius: '8px',
	}),
};

type Props = {
	title?: ReactNode;
	description?: ReactNode;
	open?: boolean;
	toggle?: () => void;
	handlePrimaryButtonAction?: () => void;
	primaryButtonText?: ReactNode;
	secondaryButtonText?: string;
	onPrimaryButtonAction?: () => void;
	withCancelButton?: boolean;
	formId?: string;
	content?: ReactNode;
	children?: ReactNode;
	onClick?: MouseEventHandler<HTMLDivElement>;
	// =============
	isLoading?: boolean;
	paperProps?: any;
	actions?: any;
	closeButton?: boolean;
	className?: string;
	error: string | null;
} & DialogProps;

const ImportDialog = ({
	title,
	description,
	open,
	toggle,
	closeButton = true,
	primaryButtonText,
	secondaryButtonText,
	handlePrimaryButtonAction,
	withCancelButton = true,
	formId,
	content,
	children,
	onClick,
	// ============
	isLoading = false,
	actions,
	paperProps = {},
	className,
	error = null,
	...dialogProps
}: Props) => {


	return (
		<MuiDialog
			{...dialogProps}
			open={open}
			onClose={toggle}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{
				style: {
					borderBottom: cn('4px solid', siteColors.primary),
					borderRadius: '24px',
					padding: '40px',
					...paperProps,
				},
			}}
			className={className}
		>
			{closeButton && (
				<div>
					<IconButton
						aria-label="close"
						onClick={toggle}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => {
								return siteColors.grey500;
							},
						}}
					>
						<XCircle color={siteColors.grey700} />
					</IconButton>
				</div>)
			}

			<div className='flexColumn gap24'>
				<div className='flexColumn gap16'>
					<DialogTitle id="alert-dialog-title" variant="h1" textAlign="left"
						sx={{ margin: 0, padding: 0, color: siteColors.primary, fontSize: '24px', fontWeight: 600 }}
					>
						{title}
					</DialogTitle>
					{description}
				</div>

				{children}

				{error && <AlertStatic
					text={error}
					type="error"
					icon={<AlertCircle css={{ color: '#C74600' }} />}
				/>}


				<BButton
					variant="primary"
					isDisabled={isLoading}
					onClick={handlePrimaryButtonAction}
					label={
						isLoading ? <CircularProgress sx={{ color: siteColors.common.white }} size={20} /> : primaryButtonText
					}
					addStyles={{ display: 'inline-flex ', margin: 0 }}
				/>
			</div>

		</MuiDialog>
	);
};

export default ImportDialog;
