import * as React from 'react';

import { css, cx } from '@emotion/css';
import MuiAccordion, { type AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { cn, siteColors } from '../lib/colors';
type AccordionItemType = {
	title: React.ReactNode;
	content: React.ReactNode;
	expanded?: boolean;
	showHeader?: boolean;
};

const styles = {
	title: css({
		fontSize: '16px !important',
		fontWeight: '600 !important',
	}),
	summaryContainer: css({
		background: cx(siteColors.common.white, '!important'),
		'& .Mui-focusVisible': {
			background: cx(siteColors.common.white, '!important'),
		},
	}),
};

const AccordionContainer = ({ title, content, expanded = true, showHeader = true }: AccordionItemType) => {
	const [isExpanded, setIsExpanded] = React.useState(false);

	React.useEffect(() => {
		setIsExpanded(expanded);
	}, [expanded]);

	return (
		<Accordion
			// expanded={isExpanded}
			expanded={true}
			onChange={() => {
				return setIsExpanded((prev) => {
					return !prev;
				});
			}}
		>
			{/* <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={styles.summaryContainer}>
				<Typography className={cx('width100', styles.title)}>{title}</Typography>
			</AccordionSummary> */}
			{showHeader ? (
				<div
					css={{
						padding: '12px',
						borderTop: cn('1px solid', siteColors.grey500),
					}}
				>
					<Typography className={cx('width100', styles.title)}>{title}</Typography>
				</div>
			) : null}
			<AccordionDetails>{content}</AccordionDetails>
		</Accordion>
	);
};

const Accordion = styled((props: AccordionProps) => {
	return <MuiAccordion disableGutters elevation={0} square {...props} />;
})(({ theme }) => {
	return {
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
	};
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => {
	return {
		padding: '16px',
		paddingBottom: 0,
		borderTop: cn('1px solid', siteColors.grey500),
	};
});

export default AccordionContainer;
