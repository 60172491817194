import { END_OF_LIFE_COLORS } from '@carbonmaps/shared/utils/constants';
import { css, cx } from '@emotion/css';
import { useMemo } from 'react';
import Typography from '../../../components/Typography';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import EndOfLifeItem from './EndOfLifeItem';

const endOfLifeItems = [
	{ value: 20, color: '#95AB76', label: 'Recyclabilité' },
	{ value: 40, color: '#E47F5F', label: 'Enfoui' },
	{ value: 40, color: '#4B9FD2', label: 'Incinéré' },
];

const styles = {
	container: css({
		padding: '16px 16px',
		paddingLeft: '0px !important',
	}),
	label: css({
		fontWeight: '600 !important',
		color: `${siteColors.grey7} !important`,
	}),
};

type MaterialTableHeaderProps = {
	items: any;
};

const MaterialTableHeader = ({ items }: MaterialTableHeaderProps) => {
	const { t } = useTranslation();

	const itemsList = useMemo(() => {
		if (!items || !items?.length) return [];

		return items?.map((item: any) => {
			return {
				...item,
				label: item?.uid,
				color: (END_OF_LIFE_COLORS as any)[item?.uid] || siteColors.grey7,
			};
		});
	}, [items]);
	return (
		<div className={cx('flexRow alignCenter nowrap gap16', styles.container)}>
			<Typography className={styles.label}>{t('Matériaux')}</Typography>
			{itemsList.map((item: any) => {
				return <EndOfLifeItem key={item.label} label={t(item.label)} color={item.color} />;
			})}
		</div>
	);
};

export default MaterialTableHeader;
