import { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { max } from 'lodash';

import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { usePeriodOptions } from '../../../hooks/useImpactYear';

import BarItem from './BarItem';
import LabelAxis from './LabelAxis';

const styles = {
	container: css({
		height: 'var(--height)',
		gap: 'var(--gap)',
	}),
};

type TemporalityGraphProps = {
	data: any;
	height?: number;
	gap?: number;
	isFilter?: boolean;
};

const TemporalityGraph = ({ height = 160, data, gap = 12, isFilter = false }: TemporalityGraphProps) => {
	const { indicator } = useApp();
	const { setPeriodOptions } = usePeriodOptions();
	// calculate percent with each item
	const dataPercent = useMemo(() => {
		const maxValue = Math.max(
			...data.map((item: any) => {
				return item[`${indicator}Impact`] || 0;
			}),
			...data.map((item: any) => {
				return item[`${indicator}ImpactEstimated`] || 0;
			}),
		);

		const result = data.map((item: any) => {
			const value = item[`${indicator}Impact`];
			const estimatedValue = item[`${indicator}ImpactEstimated`];
			return {
				...item,
				label: item.objectId,
				value,
				estimatedValue,
				percentValue: value ? (value * 100) / maxValue : 0,
				percentEstimatedValue: estimatedValue ? (estimatedValue * 100) / maxValue : 0,
			};
		});

		// add new item for last year
		const lastYear = Math.max(
			...data.map((item: any) => {
				return parseInt(item?.objectId);
			}),
			new Date().getFullYear(),
		);

		result.push({
			percentValue: 55,
			percentEstimatedValue: 55,
			label: lastYear ? lastYear + 1 : new Date().getFullYear(),
			year: lastYear ? lastYear + 1 : new Date().getFullYear(),
			value: 0,
			estimatedValue: 0,
		});

		setPeriodOptions(
			result.map((item: any) => {
				return { data: item, value: item.objectId, label: item.objectId };
			}),
		);

		return result;
	}, [data, indicator]);

	const barNumber = useMemo(() => {
		return data?.length || 1;
	}, [data]);

	return (
		<div className="width100 flexColumn gap16 height100">
			<div
				className={cx('width100 flexRow flex1 nowrap flexEnd', styles.container)}
				style={{ ['--gap' as any]: `${gap}px` }}
			>
				{dataPercent.map((item: any, index: number) => {
					return <BarItem item={item} width={`calc(${100 / barNumber}% - ${gap}px)`} isFilter={isFilter} />;
				})}
			</div>
			<LabelAxis data={dataPercent} gap={gap} />
		</div>
	);
};

export default TemporalityGraph;
