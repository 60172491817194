import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { ArrowRight, CheckCircle2, Circle, Send } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import Skeleton from '../../../components/Skeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { createUsersForCampaignAction } from '../../../lib/react-query/features/question/question.actions';

import { cn, siteColors } from '../../../lib/colors';
import { useGetQuestionFormById, useQuestionFormStore } from './hooks';
import { getContactStringValue, isWhitespace } from './QuestionFormSuppliersTable';

const styles = {
	root: css({
		//
		width: 700,
		margin: '0 auto',
		padding: '48px 32px',

		gap: 32,
	}),

	text1: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',

		marginLeft: 16,
	}),

	top1: css({
		// marginBottom: 32,
		// gap: 32,
	}),

	// staticText: css({}),

	component1: css({
		// display: 'flex',
		// alignItems: 'center',
		height: '72px',
		padding: '24px',
		gap: '8px',
		alignSelf: 'stretch',

		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
		cursor: 'pointer',
	}),

	text2: css({
		flexGrow: 1,
	}),

	text3: css({
		marginLeft: 8,
	}),

	btnDisabled: css({
		// backgroundColor: siteColors.grey500,
		opacity: '0.5 !important',
		cursor: 'not-allowed !important',
	}),

	mr8: css({
		marginRight: 8,

		color: siteColors.grey800,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: '150%',
	}),
};

type Props = {
	setScreen: Dispatch<SetStateAction<number>>;
};

const PrepareQuestionFormSummary = ({ setScreen }: Props) => {
	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);
	const queryClient = useQueryClient();
	const [count, setCount] = useState(0);
	const params = useParams();
	const { setSuppliersContacts } = useQuestionFormStore();
	const navigate = useNavigate();

	const {
		mutate: createUsersForCampaign,
		// isError,
		isLoading,
		isSuccess,
	} = useMutation({
		mutationKey: ['createUsersForCampaign'],
		mutationFn: createUsersForCampaignAction,
		onSuccess: async (e) => {
			setSuppliersContacts([]);
			queryClient.invalidateQueries(['FindQuestionForm']);
			queryClient.invalidateQueries(['getQuestionFormById', { id: _.toString(params.questionFormId) }]);
			queryClient.invalidateQueries(['findCampaign']);
			setCount(e);
		},
		onError: (error: any) => {
			if (error.message) {
				//
			}
		},
	});

	const { data: questionForm } = useGetQuestionFormById({ id: _.toString(params.questionFormId) });

	const contacts = useQuestionFormStore((state) => {
		return state.supplierContacts;
	}).map((e) => {
		return {
			supplier: {
				objectId: e.id,
				uid: e.supplierUid,
				name: e.supplierName,
			},
			contact: {
				firstName: _.toString(e.contactFirstName),
				lastName: _.toString(e.contactLastName),
				email: _.toString(e.contactEmail),
				language: _.toString(e.contactLanguage),
				emailLanguage: _.toString(e.contactLanguage),
			},
			language: _.toString(e.contactLanguage),
		};
	});

	const onSubmit = async () => {
		createUsersForCampaign({ contacts, languageCode, questionFormId: _.toString(params.questionFormId) });
	};

	const { supplierContacts } = useQuestionFormStore();

	const notReadyContacts = useMemo(() => {
		// return !supplierContacts.some((contact) => {
		// 	return isWhitespace(getContactStringValue(contact));
		// });
		return supplierContacts.filter((contact) => {
			return isWhitespace(getContactStringValue(contact));
		});
	}, [supplierContacts]);
	const allContactsAreReady = notReadyContacts.length <= 0;

	const handleClick = useCallback(() => {
		setSuppliersContacts([]);
		navigate(`${FRONT_PATH_NAMES.questionForms}/general`);
	}, [navigate, setSuppliersContacts]);

	return (
		<>
			{isSuccess ? (
				<div className={cx('flexColumn', styles.root)}>
					<div className={cx('alignCenter width100 textAlignCenter')}>
						<CheckCircle2 size={33} color={siteColors.green500} />
					</div>
					<div className={cx('flexColumn alignCenter width100 textAlignCenter gap16')}>
						<div className={cx('', styles.text1)}>{t('Questionnaire envoyé')}</div>
						<div>
							{' '}
							{count} {t('questionnaire-info-send')}
						</div>
					</div>
					<div className={cx('textCenter width100')}>
						<BButton onClick={handleClick} label={t('Terminer')} variant="secondary" />
					</div>
				</div>
			) : (
				<div className={cx('flexColumn', styles.root)}>
					<div className={cx('flexRow alignCenter', styles.top1)}>
						<Icon />
						{questionForm ? (
							<div className={cx('', styles.text1)}>
								{/* {t('questionnaire-performance-rse')} */}
								{/* {questionForm?.name} */}
								{_.get(questionForm, `translation.${languageCode || 'fr'}.name`) || '---'}
							</div>
						) : (
							<Skeleton width={200} height={22} className={cx('', styles.text1)} />
						)}
					</div>

					<div
						// className={cx('', styles.staticText)}
						dangerouslySetInnerHTML={{
							__html: t('questionForms-prepare-summary-staticText'),
						}}
					></div>

					<div
						className={cx('flexRow', styles.component1)}
						onClick={() => {
							setScreen(1);
						}}
					>
						<div className={cx('flexRow', styles.text2)}>
							{_.isEmpty(supplierContacts) || !allContactsAreReady ? (
								<Circle size={24} color={siteColors.grey700} />
							) : (
								<CheckCircle2 size={24} color={siteColors.green500} />
							)}{' '}
							<div className={cx('', styles.text3)}>{t('Fournisseurs')}</div>
						</div>
						<div className={cx('flexRow')}>
							{supplierContacts.length && !allContactsAreReady ? (
								<div className={cx('', styles.mr8)}>
									{notReadyContacts.length} {t('informations de contact manquantes')}
								</div>
							) : null}
							<IconButton
								sx={{ padding: 0 }}
								disableRipple
								onClick={() => {
									setScreen(1);
								}}
							>
								<ArrowRight size={24} color={siteColors.grey700} />
							</IconButton>
						</div>
					</div>

					<div className={cx('textCenter width100')}>
						<div css={{ marginBottom: 16 }}>
							{!_.isEmpty(supplierContacts) && allContactsAreReady
								? t('ready-teo-send-to-x-suppliers', { x: supplierContacts.length })
								: t('complete-missing-contact-infos')}
							{/* t()`Tout est prêt, vous pouvez envoyer le questionnaire à ${supplierContacts.length} fournisseurs` */}
						</div>

						<BButton
							onClick={
								!allContactsAreReady
									? () => {
										/*  */
									}
									: onSubmit
							}
							label={t('Envoyer le questionnaire')}
							variant={!allContactsAreReady ? 'secondary' : 'primary'}
							className={cx('', _.isEmpty(supplierContacts) || !allContactsAreReady ? styles.btnDisabled : '')}
						/>

						<LoadingDialog open={isLoading} content={t('loading ...')} />
					</div>
				</div>
			)}
		</>
	);
};

export default PrepareQuestionFormSummary;

const iconStyles = {
	wrapper: css({
		width: 40,
		height: 40,
		padding: '8px',
		borderRadius: 8,
		backgroundColor: '#fae5df',
	}),
};

const Icon = () => {
	return (
		<div className={cx('', iconStyles.wrapper)}>
			<Send size={24} color={siteColors.orange500} />
		</div>
	);
};
