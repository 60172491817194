import { css, cx } from '@emotion/css';
import { Leaf } from 'lucide-react';

import Icon from '../../components/dashboard/v2/Icon';
import Typography from '../../components/Typography';

import { cn, siteColors } from '../../lib/colors';
import { useCountResult } from './useApbo.hook';

const styles = {
	container: css({
		paddingTop: 32,
		paddingBottom: 40,
		borderBottom: cn('1px solid', siteColors.grey400),
	}),

	subtitle: css({
		fontSize: '16px !important',
	}),
};

const Header = () => {
	// ---- fetch count data ---- //

	const { data } = useCountResult();
	return (
		<div className={cx('flexRow gap24 alignCenter width100', styles.container)}>
			<Icon element={Leaf} color={siteColors.primary} background={siteColors.carbon100} size={90} iconSize={40} />
			<div className="flexColumn gap8">
				<Typography variant="h2">CAP'2ER</Typography>
				<Typography className={cx('grey800 fontWeight500', styles.subtitle)}>
					Performance environnementale de vos adhérents
				</Typography>
			</div>
		</div>
	);
};

export default Header;
