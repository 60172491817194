import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import { Divider, Typography } from '@mui/material';
import _ from 'lodash';
import { AlertCircle, Check } from 'lucide-react';
import { useForm } from 'react-hook-form';

import { ClientFormInput, ClientFormValidationSchema } from '@carbonmaps/shared/validations/client.validations';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import FormContainer from '@carbonmaps/ui/components/saas/Form/FormContainer';

import { useTranslation } from '../../../hooks/useTranslation';

import AlertStatic from '../../../components/Toast/AlertStatic';
import { siteColors } from '../../../lib/colors';
import { useQuoteFormStep } from './useDevisFormStore';

const styles = {
	title: css({
		fontSize: '24px !important',
		fontWeight: '600 !important',
		color: siteColors.primary,
		margin: 0,
	}) as any,
	subTitle: css({
		marginTop: '0px',
		fontSize: '14px !important',
		fontWeight: '400 !important',
		width: '400px',
	}) as any,

	formContainer: css({
		maxWidth: 500,
		gap: '32px',
		'.input-container': {
			width: '100%',
		},
		marginBottom: '16px',
	}) as any,

	header: css({
		gap: '16px',
	}) as any,
	label1: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
		marginBottom: '8px',
	}) as any,
	label: css({
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: siteColors.grey900,
	}) as any,
	searchClassName: css({
		width: '400px',
		'& .MuiOutlinedInput-root': {
			width: '100% !important',
			height: '42px!important',
			borderRadius: '11px !important',
		},
	}) as any,
};

const ClientForm = ({
	isLoading,
	isSuccess,
	isError,
	onSubmit,
	toggle,
	title,
	subTitle,
	message,
	selectedOption,
	isAsync,
	getDefaultValuesAsync,
	withCancel = true,
	buttonTitle,
	toggleOnCancel,
}: any) => {
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<ClientFormInput>({
		resolver: zodResolver(ClientFormValidationSchema),
		defaultValues: isAsync ? getDefaultValuesAsync : selectedOption,
		// defaultValues(payload) {

		// },
		mode: 'onChange',
	});

	// translation
	const { t } = useTranslation();

	const { setStep } = useQuoteFormStep();

	return (
		<div css={{ marginBottom: 24 }}>
			<FormContainer
				withCancel={withCancel}
				loading={isLoading}
				title=""
				buttonTitle={buttonTitle || t('Suivant')}
				onSubmit={handleSubmit(onSubmit)}
				isSuccess={isSuccess}
				isError={isError}
				withSave
				onCancel={() => {
					// toggle();
					setStep(1);
					toggleOnCancel && toggle();
				}}
				alertSuccess={<AlertStatic text={message} type={'success'} icon={<Check color={siteColors.green500} />} />}
				alertError={<AlertStatic text={message} type={'error'} icon={<AlertCircle color="#C74600" />} />}
				addStyles={{
					maxWidth: 528,
					margin: 'auto',
				}}
				t={t}
				// showAlert={showAlert}
			>
				<div className={cx('width100', styles.formContainer)}>
					<div className={cx('flexColumn width100', styles.header)}>
						<p className={cx(styles.title)}>{title}</p>
						<p className={cx(styles.subTitle)}>{subTitle}</p>
					</div>

					<div /* css={{ marginTop: '-40px' }} */>
						<div css={{ marginBottom: '16px' }}>
							<Typography css={{ marginBottom: '8px' }} variant="h2">
								{t('quote-client-company')}
							</Typography>
							<Divider />
						</div>

						<BInput
							required
							id="name"
							label={<div className={cx(styles.label)}>{t('Raison sociale (obligatoire)')}</div>}
							fullWidth
							margin="dense"
							{...register('name')}
							name="name"
							error={errors.name ? true : false}
							errorMessage={errors?.name?.message}
							autoComplete="off"
						/>
						<BInput
							required
							id="siretNumber"
							label={<div className={cx(styles.label)}>{t('Numéro du siret')}</div>}
							fullWidth
							margin="dense"
							{...register('siretNumber')}
							error={errors.siretNumber ? true : false}
							errorMessage={errors?.siretNumber?.message}
							autoComplete="off"
						/>
						<BInput
							required
							id="address"
							label={<div className={cx(styles.label)}>{t('Adresse')}</div>}
							fullWidth
							margin="dense"
							{...register('address')}
							error={errors.address ? true : false}
							errorMessage={errors?.address?.message}
							autoComplete="off"
						/>
						<div css={{ display: 'grid', gridTemplateColumns: '1fr 2.5fr', gap: '8px' }}>
							<div>
								<BInput
									required
									id="postalCode"
									label={<div className={cx(styles.label)}>{t('client-postal-code')}</div>}
									fullWidth
									margin="dense"
									{...register('postalCode')}
									error={errors.postalCode ? true : false}
									errorMessage={errors?.postalCode?.message}
									autoComplete="off"
								/>
							</div>
							<div>
								<BInput
									required
									id="city"
									label={<div className={cx(styles.label)}>{t('client-city')}</div>}
									fullWidth
									margin="dense"
									{...register('city')}
									error={errors.city ? true : false}
									errorMessage={errors?.city?.message}
									autoComplete="off"
								/>
							</div>
						</div>

						<div css={{ marginBottom: '16px', marginTop: '16px' }}>
							<Typography css={{ marginBottom: '8px' }} variant="h2">
								{/* {t('Interlocuteur')} */}
								{t('Client')}
							</Typography>
							<Divider />
						</div>

						<BInput
							required
							id="interlocutorFirstName"
							label={<div className={cx(styles.label)}>{t('client-last-name')}</div>}
							fullWidth
							margin="dense"
							{...register('interlocutorFirstName')}
							error={errors.interlocutorFirstName ? true : false}
							errorMessage={errors?.interlocutorFirstName?.message}
							autoComplete="off"
						/>
						<BInput
							required
							id="interlocutorName"
							label={<div className={cx(styles.label)}>{t('client-name')}</div>}
							fullWidth
							margin="dense"
							{...register('interlocutorName')}
							error={errors.interlocutorName ? true : false}
							errorMessage={errors?.interlocutorName?.message}
							autoComplete="off"
						/>
						<BInput
							required
							id="interlocutorEmail"
							label={<div className={cx(styles.label)}>{t('client-email')}</div>}
							fullWidth
							margin="dense"
							{...register('interlocutorEmail')}
							error={errors.interlocutorEmail ? true : false}
							errorMessage={errors?.interlocutorEmail?.message}
							autoComplete="off"
						/>
					</div>
				</div>
			</FormContainer>
		</div>
	);
};

export default ClientForm;
