import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { HelpCircle } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import {
	getProductTableDataAction /* , GetProductTableQueryParams */,
	getProductTableDataActionPackagingUses,
} from '@carbonmaps/ui/actions/product.actions';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';

import TableHeaderCell from '../../../components/table/TableHeaderCell';
import ImpactCarbonePopover from '../../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../../components/table/popover/WaterImpactPopover';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import ProductModel from '../../../models/Product.model';
import { CARBONE_INDICATOR } from '../../../utils/constants';

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
		},
		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},

		'.bordered tr th:not(:nth-of-type(1),:nth-of-type(2)) div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) div,.bordered tr td:not(:nth-of-type(1),:nth-of-type(2)) button':
			{
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

const formatThenSetData = (res: any, indicator: any, packagingId: string) => {
	if (!res?.length) return [];
	const maxValue: any = _.maxBy(res, indicator === WATER_INDICATOR ? 'waterIntensity' : 'carbonIntensity');
	return res.map((item: any) => {
		// const productModel = new ProductModel(item);

		return {
			...item,
			reference: item.uid,
			percentage:
				indicator === WATER_INDICATOR
					? (item.waterIntensity * 100) / maxValue?.waterIntensity
					: (item.carbonIntensity * 100) / maxValue?.carbonIntensity /*  ?? 0 */,
			// packagingWeight: productModel?.getPackagingWeight(packagingId) ?? 0,
		};
	});
};

type PackagingTableProps = {
	filterOptions: any;
};

const PackagingUsesTable = ({ filterOptions }: PackagingTableProps) => {
	// query params
	const params = useParams();
	// theme
	const theme = useTheme();
	// switch value ( carbon or water )
	const { indicator } = useApp();
	// custom style tab
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	const { selectedYear } = useYearSelection();

	// translation
	const { t } = useTranslation();

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	const packagingId = params.packagingId;
	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // supplier filters
			direction: 0,
			...(filterOptions?.orderBy && {
				sortingBy: {
					id: filterOptions?.orderBy,
					desc: filterOptions?.direction === 'desc',
				},
			}),
			packagingId,
			...filterOptions,
		},
	);

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ---- table columns
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('product-label')} />;
				},
				accessor: 'label', // accessor is the "key" in the data
				editable: true,
				sortDescFirst: true,
				component: 'FinalProduct',
				type: 'string',
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.IMPACT, { period: selectedYear || t('period') })}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
						/>
					);
				},
				accessor: indicator === CARBONE_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				type: 'percent',
				props: {
					...(indicator === CARBONE_INDICATOR
						? {
								popoverComponent: ImpactCarbonePopover,

								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,

								emptyValue: <HelpCircle color={theme.palette.indicator.carbon.main} />,
						  }
						: {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
								popoverComponent: WaterImpactPopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={theme.palette.indicator.water.main} />,
						  }),
					decimal: 2,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBONE_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					...(indicator !== CARBONE_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell column={props.column} label={t('packaging-weight')} variant="measure" measure="weight" />
					);
				},
				accessor: 'packagingWeight', // accessor is the "key" in the data
				component: 'SimpleCell',
				editable: true,
				sortDescFirst: true,
				type: 'number',
				disableSortBy: true,
			},
		];
	}, [indicator, selectedYear, t]);

	// ---- fetch data --- //
	// const {
	// 	data: dataResult,
	// 	isLoading,
	// 	isSuccess: isSearchSuccess,
	// } = useQuery({
	// 	queryKey: [
	// 		'getProductTableData',
	// 		{
	// 			...paramsTable,
	// 			viewMode: indicator,
	// 			selectedYear,
	// 		},
	// 	],
	// 	queryFn: getProductTableDataAction,
	// });
	const {
		data: dataResult,
		isLoading,
		// isSuccess: isSearchSuccess,
	} = useQuery({
		queryKey: [
			'getProductTableDataPackagingUses',
			{
				...paramsTable,
				viewMode: indicator,
				selectedYear,
			},
		],
		queryFn: getProductTableDataActionPackagingUses,
	});

	const { skeleton } = useSkeleton({ condition: isLoading });

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		// redirect to first page
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions]);

	return (
		<TableComponent
			skeleton={skeleton}
			loading={false}
			fetchData={updateTableParams}
			pageCount={dataResult?.meta?.last_page || 0}
			noDataMessage={t('packaging-uses-table-empty-data')}
			pageSize={paramsTable.size}
			columns={tableColumns}
			resetPage={resetPage}
			data={formatThenSetData(dataResult?.data, indicator, packagingId ?? '') || []}
			onSortingColumn={handleSortingTable}
			addStyles={stylesTable}
			manualSortBy
			setSelectedRow={() => {
				return 1;
			}}
		/>
		// null
	);
};

export default PackagingUsesTable;
