import styled from '@mui/material/styles/styled';
import Tooltip, { tooltipClasses, type TooltipProps } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
	return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			// color: 'rgba(0, 0, 0, 0.87)',
			color: theme.palette.common.black,
			boxShadow: theme.shadows[2],
			fontSize: 12,
		},
	};
});

export default LightTooltip;
