import Grid from '@mui/material/Grid';

import IngredientCalculDataBlock from './IngredientCalculDataBlock';
import IngredientDataBlock from './IngredientDataBlock';
import IngredientIndicatorsBlock from './IngredientIndicatorsBlock';
import IngredientMetaDataBlock from './IngredientMetaDataBlock';
import IngredientOtherIndicatorsBlock from './IngredientOtherIndicatorsBlock';
import IngredientSegmentBlock from './IngredientSegmentBlock';
import IngredientSuppliersBlock from './IngredientSuppliersBlock';
import IngredientTradeOffBlock from './IngredientTradeOffBlock';

const IngredientSynthesis = () => {
	return (
		<Grid container gap="40px" sx={{ flexWrap: 'nowrap' }}>
			<Grid item xs={8.2}>
				<IngredientIndicatorsBlock />
				<IngredientTradeOffBlock />
				<IngredientSegmentBlock />
				<IngredientOtherIndicatorsBlock />
			</Grid>

			<Grid item flexGrow={1}>
				<IngredientDataBlock />
				<IngredientSuppliersBlock />
				<IngredientCalculDataBlock />
				<IngredientMetaDataBlock />
			</Grid>
		</Grid>
	);
};

export default IngredientSynthesis;
