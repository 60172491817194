import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';

import { CARBON_INDICATOR } from '../utils/constants';

export type BreadcrumbsItem = {
	text: string;
	link?: string;
	isBeta?: boolean;
};

export type Indicator = 'carbon' | 'water';

type AppContextType = {
	breadcrumbs: BreadcrumbsItem[];
	setBreadcrumbs: Dispatch<SetStateAction<BreadcrumbsItem[]>>;
	productFilters: Record<string, any>;
	setProductFilters: Dispatch<SetStateAction<Record<string, any>>>;
	productIngredientFilters: Record<string, any>;
	setProductIngredientFilters: Dispatch<SetStateAction<Record<string, any>>>;
	ingredientFilters: Record<string, any>;
	setIngredientFilters: Dispatch<SetStateAction<Record<string, any>>>;
	viewMode: any;
	setViewMode?: (v: any) => void;
	indicator: Indicator;
	setIndicator: (v: Indicator) => void;
};

export const AppContext = createContext<AppContextType>({
	breadcrumbs: [],
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setBreadcrumbs: () => {},
	productFilters: {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setProductFilters: () => {},
	productIngredientFilters: {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setProductIngredientFilters: () => {},
	viewMode: null,
	ingredientFilters: {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIngredientFilters: () => {},
	indicator: CARBON_INDICATOR as Indicator,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIndicator: () => {},
});

type Props = {
	children: ReactNode;
};

export const AppProvider = ({ children }: Props) => {
	const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsItem[]>([]);
	const [productFilters, setProductFilters] = useState<Record<string, any>>({});
	const [productIngredientFilters, setProductIngredientFilters] = useState<Record<string, any>>({});
	const [ingredientFilters, setIngredientFilters] = useState<Record<string, any>>({});

	const [viewMode, setViewMode] = useState(null);
	const [indicator, setIndicator] = useState(CARBON_INDICATOR as Indicator);

	const memoizedValue = useMemo<AppContextType>(() => {
		return {
			breadcrumbs,
			setBreadcrumbs,
			productFilters,
			productIngredientFilters,
			ingredientFilters,
			setProductFilters,
			setProductIngredientFilters,
			setIngredientFilters,
			viewMode,
			setViewMode,
			indicator,
			setIndicator,
		};
	}, [breadcrumbs, productFilters, productIngredientFilters, ingredientFilters, viewMode, indicator]);

	return <AppContext.Provider value={memoizedValue}>{children}</AppContext.Provider>;
};
