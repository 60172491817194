import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import _ from 'lodash';
import { PackageOpen } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { GetPackagingParams } from '@carbonmaps/ui/actions/packaging.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { usePackagingProduct } from '@carbonmaps/ui/hooks/useGetProductSheet';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import BlockTitle from '../../../components/BlockTitle';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import Unit from '../../../components/units/Unit';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { exportPackagingAction } from '../../../lib/react-query/features/export/packaging.actions';
import { CARBONE_INDICATOR } from '../../../utils/constants';

const classes = (theme: any) => {
	return {
		'.bordered td, .bordered th': {
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered': {
			border: `solid 1px ${theme.palette.grey[500]}`,
			borderRadius: '0px 0px 6px 6px',
		},

		'.bordered tr th:nth-of-type(2) div': {
			justifyContent: 'flex-start!important',
			paddingLeft: 0,
		},
		'.bordered tr th:nth-of-type(3) div,.bordered tr td:nth-of-type(3) div, .bordered tr th:nth-of-type(4) div, .bordered tr th:nth-of-type(5) div,.bordered tr td:nth-of-type(5) div,.bordered tr th:nth-of-type(6) div,.bordered tr td:nth-of-type(6) div':
			{
				justifyContent: 'flex-end!important',
				paddingRight: '0!important',
			},
		'.bordered tr td:nth-of-type(3) div,.bordered tr td:nth-of-type(4) p': {
			paddingRight: '0!important',
			textAlign: 'end',
		},
		'.bordered tr td:nth-of-type(4) div': {
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
			textAlign: 'end',
		},
	};
};

const ProductPackagingList = () => {
	const theme = useTheme();
	const { indicator, setIndicator } = useApp();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// translation
	const { t } = useTranslation();
	const params = useParams();
	const fetchIdRef = useRef(0);
	const [selectedRow, setSelectedRow] = useState<string[]>([]);
	const [, setQueryParams] = useState<GetPackagingParams>({ page: 1, size: 10, productId: '' });
	const [loading, setLoading] = useState(false);
	const [sortingBy, setSortingBy] = useState({
		id: 'carbonWeightedIntensity',
		desc: true,
	});

	const { data, isLoading, isSuccess } = usePackagingProduct({
		productId: params.productId!,
	});

	// ---- track event ---- //
	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	const PackagingTableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							valueType="string"
							column={props.column}
							label={t('Emballage')} /* measure="carbonIntensity" */
						/>
					);
				},
				accessor: 'name', // accessor is the "key" in the data
				editable: true,
				component: 'TagLabel',
				icon: <PackageOpen size={16} color={siteColors.grey700} />,
				props: {
					onClick: (row: any) => {
						trackEvent('Explore Click Packaging', { PackagingLabel: row.label });
					},
				},
				disableSortBy: true,
			},

			{
				Header: (props: any) => {
					const theme = useTheme();
					return <TableHeaderCell column={props.column} label={t('material')} />;
				},
				accessor: 'material',
				editable: true,
				component: 'SimpleCell',
			},
			{
				Header: (props: any) => {
					const theme = useTheme();
					return (
						<TableHeaderCell
							column={props.column}
							label={t('poids')}
							variant="unit"
							unit={<Unit measure="grammes" color={theme.palette.grey[700]} lineHeight={1.5} />}
						/>
					);
				},
				accessor: 'materialWeight',
				editable: true,
				component: 'SimpleCell',
				props: {
					page: 'presencePercent',
				},
			},
			{
				Header: (props: any) => {
					const theme = useTheme();
					return (
						<TableHeaderCell
							column={props.column}
							label={t('presence')}
							variant="unit"
							unit={<Unit measure="percent" color={theme.palette.grey[700]} lineHeight={1.5} />}
						/>
					);
				},
				accessor: 'composition',
				editable: true,
				type: 'number',
				component: 'SimpleCell',
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY_WEIGHTED_PRODUCT, {
								per_kilo: t('per_kilo'),
								product: toLowerCase(t('product')),
								of_product: t('of_product'),
							})}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBONE_INDICATOR ? 'carbonWeightedIntensity' : 'waterWeightedIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				props: {
					isWeighted: true,
					...(indicator !== CARBONE_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY_PACKAGING, { per_kilo: t('per_kilo') })}
							variant="measure"
							measure={indicator === CARBONE_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBONE_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				props: {
					...(indicator !== CARBONE_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
				},
			},
		];
	}, [indicator, sortingBy, t]);

	const updateQueryParams: ComponentProps<typeof TableComponent>['fetchData'] = useCallback(
		({ pageIndex, pageSize }: any) => {
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setQueryParams({
					page: pageIndex + 1,
					size: pageSize,
				});
			}
		},
		[],
	);

	const onSelectRows = (rows: unknown[]) => {
		const rowsIds = rows.map((r: any) => {
			return r.original.objectId;
		});
		setSelectedRow(rowsIds);
	};

	const handleExport = async () => {
		if (isSuccess) {
			setLoading(true);
			await exportPackagingAction({
				productId: params.productId!,
				ids: selectedRow!,
				productUid: data?.uidProduct,
				t,
			});
			setLoading(false);
		}
	};

	const dataTable = useMemo(() => {
		if (!data?.packaging) return [];

		const res = data.packaging;
		const maxCarboneIntensity: any = _.maxBy(res, 'carbonIntensity');
		const maxWaterIntensity: any = _.maxBy(res, 'waterIntensity');
		const maxWeightedCarboneIntensity: any = _.maxBy(res, 'carbonWeightedIntensity');
		const maxWeightedWaterIntensity: any = _.maxBy(res, 'waterWeightedIntensity');

		const emballages = data?.packaging.map((item: any) => {
			return {
				...item,
				name: item.packaging.get('label') || item.packaging.get('labelCmaps'),
				composition: displayValue(item.composition, undefined, 2),
				// in percentage
				percentage:
					(indicator === CARBONE_INDICATOR
						? (item.carbonIntensity * 100) / maxCarboneIntensity.carbonIntensity
						: (item.waterIntensity * 100) / maxWaterIntensity.waterIntensity) || 0,
				// in percentage Weighted
				percentageWeighted:
					indicator === CARBONE_INDICATOR
						? ((item?.carbonWeightedIntensity || 0) * 100) / (maxWeightedCarboneIntensity?.carbonWeightedIntensity || 1)
						: ((item?.waterWeightedIntensity || 0) * 100) / (maxWeightedWaterIntensity?.waterWeightedIntensity || 1),

				link: `${FRONT_PATH_NAMES.packagings.details}/${item?.packaging?.id}/synthesis`,
				origin: item.packaging.get('uid'),
				material: item?.packaging?.get('material'),
				materialWeight: item?.weight,
				presencePercent: !data?.product?.netWeight ? 0 : (item?.weight * 100) / (data?.product?.netWeight || 1),
			};
		});
		// for manual sorting

		const { id, desc } = sortingBy;
		const sort = desc === false ? 'asc' : 'desc';

		return _.orderBy(emballages, id, sort);
	}, [data, indicator, sortingBy]);
	return (
		<>
			<Box mb="40px">
				<BlockTitle>{t('Emballages')}</BlockTitle>
				<Box
					sx={{
						border: `solid 1px ${theme.palette.grey[500]}`,
						borderRadius: '16px 16px 0 0',
						padding: '16px',
						background: siteColors.grey200,
						borderBottom: 'none',
					}}
				>
					<BButton onClick={handleExport} variant="secondary" label={t('export-button')} />
				</Box>

				<TableComponent
					pagination={false}
					tableName={'packaging'}
					loading={isLoading}
					skeleton={isLoading}
					fetchData={updateQueryParams}
					noDataMessage={t('product-packaging-empty-data')}
					pageCount={1}
					isSelectable
					columns={PackagingTableColumns}
					data={dataTable}
					pageSize={10}
					transFunc={t}
					setSelectedRow={(rows) => {
						return onSelectRows(rows);
					}}
					onSortingColumn={(value: any) => {
						return setSortingBy(value);
					}}
					addStyles={stylesTable}
					manualSortBy
				/>
			</Box>
			<LoadingDialog open={loading} content={`${t('Export en cours')}...`} />
		</>
	);
};

export default ProductPackagingList;
