import { css, cx } from '@emotion/css';
import _ from 'lodash';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { displayValue, formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import { cn, siteColors } from '../../lib/colors';
import Typography from '../Typography';
import Unit from '../units/Unit';
const styles = {
	container: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
		padding: '16px',
		gap: 4,
		minWidth: '120px',
	}),
	label: css({
		fontWeight: '600 !important',
		lineHeight: '1 !important',
		fontSize: '14px !important',
	}),
	value: css({
		lineHeight: '1 !important',
	}),

	additional: css({
		whiteSpace: 'nowrap !important',
		flexWrap: 'nowrap !important',
	} as any),
};

type DashboardTooltipProps = {
	label?: string;
	value?: number | string;
	unit?: React.ReactNode;
};

const DashboardTooltip = ({ label, value, unit = '%' }: DashboardTooltipProps) => {
	return (
		<div className={cx('flexColumn  nowrap gap8', styles.container)}>
			<Typography className={styles.label}>{label}</Typography>
			<Typography className={cx(styles.value, 'number')}>
				{_.isString(value) ? value : `${/* formatNumber */ displayValue(value, undefined, 2)} ${unit}`}
			</Typography>
		</div>
	);
};

export default DashboardTooltip;

export const PackagingTooltip = ({
	label,
	valueImpact,
	percentValue,
}: {
	label: string;
	valueImpact: number;
	percentValue: number;
}) => {
	const { indicator } = useApp();

	return (
		<div className={cx('flexColumn  nowrap gap8', styles.container)}>
			<Typography className={styles.label}>{label}</Typography>
			<div className={cx('flexRow', styles.additional)}>
				<Typography className={cx(styles.value, 'number')}>{displayValue(valueImpact, undefined, 0)}</Typography>
				<Unit
					sx={{ ml: 1, color: siteColors.grey700 }}
					measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpactTon'}
				/>
			</div>
			<Typography className={cx(styles.value, 'number')}>{displayValue(percentValue)}%</Typography>
		</div>
	);
};
